import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { MouseEventHandler } from "react";

type Props = {
  isPairing: boolean;
  onPair: MouseEventHandler<HTMLButtonElement> | undefined;
};

/* Displays pairing button */
const PairingButton = ({ isPairing, onPair }: Props) => (
  <Button disabled={isPairing} onClick={onPair} variant="contained" color="secondary">
    {
      !isPairing && 'Begin pairing mode (30s)'
    }
    {
      isPairing && (
      <Stack direction="row">
        <CircularProgress
          size="1em"
          color="inherit"
          sx={{
            mr: 1,
            mt: 'auto',
            mb: 'auto',
            verticalAlign: 'bottom',
          }}
        />
        <Typography sx={{ verticalAlign: 'bottom' }}>Pairing</Typography>
      </Stack>
      )
    }
  </Button>
);

export default PairingButton;