import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { useScreenshotQuery } from '@features/support-screenshot/api';
import CloseIcon from '@mui/icons-material/Close';
import { Backdrop, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, IconButton, Paper, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { SupportCase } from '../types';
import CaseDevices from './CaseDevices';
import CaseNotes from './CaseNotes';
import SupportCaseEditor from './SupportCaseEditor';
import ThumbnailList from './ThumbnailList';


const SupportCaseDetails = () => {

  const supportCase = useAppSelector((state: RootState) => state.supportCase.case as SupportCase);
  const property = useAppSelector((state: RootState) => state.property);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const [screenshotId, setScreenshotId] = useState<string|null>(null);

  const {
    currentData: imgString,
    isFetching,
    refetch,
  } = useScreenshotQuery({
    userTenantId: currentTenant?.tenant_id || '',
    propertyId: property?.property_id || '',
    screenshotId: screenshotId || '',
  }, {
    skip: !currentTenant?.tenant_id || !property?.property_id || !screenshotId,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    refetch();
  }, [refetch, screenshotId]);

  const fullImage = useMemo(() => {
    if (isFetching || !imgString) return <CircularProgress />;
    return <img src={imgString} height="700" alt="screenshot-full" />;
  }, [imgString, isFetching]);

  if (!supportCase?.case_id) return <Backdrop open><CircularProgress /></Backdrop>;

  return (
    <>
      <Stack spacing={2}>
        <Typography variant="h4" sx={{ mb: '1em' }}>Support case {supportCase.case_id}</Typography>
        <Stack direction="row" sx={{ width: 'inherit' }} justifyContent="space-between">
          <Stack
            spacing={2}
            component={Paper}
            sx={{
              width: '70%',
              padding: 1,
              height: '50em',
            }}
          >
            <SupportCaseEditor />
            <Divider />
            <CaseNotes />
            <Divider />
            <CaseDevices />
            <Divider />
          </Stack>
          <ThumbnailList onClick={(id: string) => setScreenshotId(id)} />
        </Stack>
      </Stack>
      <Dialog
        open={!!screenshotId}
        onClose={() => setScreenshotId(null)}
        maxWidth={false}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            {screenshotId}
            <IconButton onClick={() => setScreenshotId(null)}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          {fullImage}
        </DialogContent>
      </Dialog>
    </>);
}

export default SupportCaseDetails;