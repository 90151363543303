import React from 'react';

import { TextField, TextFieldProps } from '@mui/material';
import { FastField, Field, FieldProps, getIn } from 'formik';

type Props = {
  /** If true, use the FastField (no cross field valiation) */
  fast?: boolean
} & TextFieldProps;

/**
 * Standard TextField that can be used in Formik forms
 *
 * Note: The onChange and onBlur handlers are defined by Formik.
 * You can overwrite them, but you need to explicitly call those handlers
 * yourself.
 *
 * For example:
 *   <AppTextField
 *     onChange={(e) => {
 *       # Do stuff
 *       ...
 *       # Let Formik do its thing
 *       formik.handleChange(e);
 *     }}
 *   />
 */
const AppTextField: React.FC<Props> = props => {
  const { fast = false, ...rest } = props;
  return (
    fast
      ? <FastField component={InnerField} {...rest} />
      : <Field component={InnerField} {...rest} />
  );
}

/**
 * Utility component for use with Formik <Field/> and <FastField/>
 *
 * For example:
 *
 *   <FastField name="title" component={InnerField} />
 *
 * See: https://firxworx.com/blog/coding/react/integrating-formik-with-react-material-ui-and-typescript/
 */
const InnerField: React.FC<FieldProps & TextFieldProps> = props => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)

  const { error, helperText, field, form, ...rest } = props
  return (
    <TextField
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
      {...field} // includes all props contributed by the Formik Field/FastField
      {...rest} // includes any Material-UI specific props
    />
  )
}

export default AppTextField;
