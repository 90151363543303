import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { ManifestDevice } from "@features/plan-manifest/types";
import { getUnitsByCategoryMeasure } from "@features/unit-settings/utils";
import { formatCategoryMeasurePair, formatReadingDetails } from "@lib/labels";
import { Typography } from "@mui/material";
import { useMemo } from "react";
import { CURRENT_TEMPERATURE_MEASURE, THERMOSTAT_CATEGORY, ThermostatAttributes } from "../types";

type Props = {
  entryId: string;
}

const ThermostatCurrentTemperature = ({ entryId }: Props) => {
  const property = useAppSelector((state: RootState) => state.property);
  const userPreferences = useAppSelector((state: RootState) => state.preferences);

  const devices = useMemo(() => property.devices || [], [property.devices]);
  const manifestEntries = useMemo(() => property.manifestEntries || [], [property.manifestEntries]);
  const thermostatEntry = useMemo(() => manifestEntries.find(me => me.manifest_entry_id === entryId), [manifestEntries, entryId]);

  const currentTemperatureSensor = useMemo(() => (thermostatEntry?.device as ManifestDevice).sensors
    .find(s => thermostatEntry?.sensor_map?.[s.sensor_id ?? s.friendly_name]?.split('#')?.[1] === ThermostatAttributes.currentTemperature), [thermostatEntry?.device, thermostatEntry?.sensor_map]);

  const currentTemperatureDevice = useMemo(() => devices
    .find(r => r.data.entity_id === thermostatEntry?.sensor_map?.[currentTemperatureSensor?.sensor_id ?? currentTemperatureSensor?.friendly_name ?? '']), [currentTemperatureSensor?.sensor_id, currentTemperatureSensor?.friendly_name, devices, thermostatEntry?.sensor_map]);

  const preferences = useMemo(() => userPreferences.preferences, [userPreferences.preferences]);
  const unitPreferences = useMemo(() => preferences.units || {}, [preferences.units]);
  const preferredUnit = useMemo(() => (
      unitPreferences?.[formatCategoryMeasurePair(THERMOSTAT_CATEGORY, CURRENT_TEMPERATURE_MEASURE)]
      || getUnitsByCategoryMeasure(THERMOSTAT_CATEGORY, CURRENT_TEMPERATURE_MEASURE)[0]
    ), [unitPreferences]);

  const currentTemperatureDetails = useMemo(() => formatReadingDetails(
    THERMOSTAT_CATEGORY,
    CURRENT_TEMPERATURE_MEASURE,
    preferredUnit,
    currentTemperatureDevice?.data.value,
  ), [currentTemperatureDevice?.data.value, preferredUnit]);

  return (
    <Typography variant="h3">{currentTemperatureDetails.value || ''}</Typography>
  );
}

export default ThermostatCurrentTemperature;