import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { Formik, FormikConfig, FormikHelpers, FormikProps, isFunction } from 'formik';
import React from 'react';


type Props<T> = {
  /** Is the dialog open? */
  open: boolean;
  /** The title for the dialog */
  title: React.ReactNode;
  /** Callback triggered when the dialog closes for any reason */
  onClose: () => any;
  /**
   * Callback triggered when the user clicks on the Save button
   *
   * @param values Updated values
   */
  onSave: (values: T) => Promise<boolean>;
} & Omit<FormikConfig<T>, 'onSubmit'>;

/**
 * A Formik form in a dialog.
 *
 * All the children and extra properties are passed to Formik.
 *
 * @example
 * <EditDialog
 *   open={editing}
 *   title="Edit Stuff"
 *   onClose={() => setEditing(false)}
 *   onSave={(stuff) => saveStuff(stuff)}
 *   initialValues={stuff}
 * >
 *   <Field name="example" label="Example" />
 * </EditDialog>
 *
 * The children can also be function that accepts the Formik form, which you may
 * need for more complicated forms:
 * @example
 *   <EditDialog ... >
 *     { f => (
 *        <CustomInput name="example" label="Example" value={f.values.example} onChange={f.handleChange} />
 *     )}
 *   </EditDialog>
 */
export default function EditDialog<T>(props: Props<T>) {
  const { open, title, onClose, onSave, children, ...formikProps } = props;

  if (!open) {
    return null;
  }

  const renderChildren = (f: FormikProps<T>) => {
    if (!children) return <></>;
    if (!isFunction(children)) return children;
    return (children as (bag: FormikProps<T>) => React.ReactNode)(f);
  }

  /** Submits form */
  const handleFormSubmit = async (
    values: T,
    helpers: FormikHelpers<T>,
  ) => {
    const result = await onSave(values);
    helpers.setSubmitting(false);
    if (result) {
      helpers.resetForm();
      onClose();
    }
  }

  return (
    <Formik
      {...formikProps}
      onSubmit={handleFormSubmit}
    >
      {f => (
        <Dialog
          open={open}
          scroll="paper"
          fullWidth
          maxWidth="lg"
          onClose={() => {
            f.resetForm();
            onClose();
          }}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <form noValidate onSubmit={f.handleSubmit}>
              <Box
                component="div"
                sx={{
                  '& > *': {
                    // Space the form elements out a bit
                    margin: theme => `${theme.spacing(1)} !important`,
                  }
              }}>
                {renderChildren(f) as JSX.Element}
              </Box>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onClose()}>
              Cancel
            </Button>
            <LoadingButton
              loading={f.isSubmitting}
              onClick={() => f.submitForm()}
              disabled={!f.dirty || !f.isValid || f.isSubmitting}
              variant="contained"
              color="primary"
            >
              Save
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}
