import * as yup from 'yup';

type EntityBase = {
  readonly created: string | number;
  readonly updated: string | number;
};

type HasTags = {
  tags?: Record<string, any> | null;
};

export type User = {
  username: string;
  enabled: boolean;
  email: string;
  given_name: string;
  family_name: string;
  address: string;
  name: string;
  is_internal: boolean;
} & EntityBase & HasTags;

export type RolePermission = {
  category: string;
  enabled: boolean;
  id: string;
};

export enum RoleType {
  SYSTEM = 'System',
  CUSTOM = 'Custom'
};

export type Role = {
  description: string;
  permissions: RolePermission[];
  name: string;
  type: RoleType;
  is_deleted: boolean;
} & EntityBase;

export type UserAccess = {
  group_name: string;
  is_deleted: boolean;
  roles: string[];
  tenant_id: string;
  username: string;
} & EntityBase;

/** Validation for Users */
export const USER_SCHEMA = yup.object({
  username: yup.string().required().label('Username'),
  email: yup.string().required().label('Email Address').email(),
});