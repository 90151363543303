import { riotApi } from "@features/aws-user/api";
import { HTTPMethodType } from "@lib/types";
import { U } from "@lib/utils";
import { UtilGroup, UtilGroupCreate, UtilGroupPropertyUpdate } from "./types";


export const utilgroupsApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      utilgroups: builder.query<UtilGroup[], {
        userTenantId: string,
        builderId?: string,
      }>({
        query: ({ userTenantId, builderId }) => ({
          url: `tenant/${U(userTenantId)}/groups`,
          params: {
            builder_id: builderId || userTenantId,
          },
        }),
      }),
      utilgroup: builder.query<UtilGroup, {
        userTenantId: string,
        groupId: string,
        builderId?: string,
      }>({
        query: ({ userTenantId, groupId, builderId }) => ({
          url: `tenant/${U(userTenantId)}/groups/${U(groupId)}`,
          params: {
            builder_id: builderId || userTenantId,
          },
        }),
      }),
      createUtilGroup: builder.mutation<UtilGroup, {
        userTenantId: string,
        utilGroup: UtilGroupCreate,
      }>({
        query: ({ userTenantId, utilGroup }) => ({
          url: `tenant/${U(userTenantId)}/groups`,
          method: HTTPMethodType.POST,
          body: utilGroup,
        }),
      }),
      updateUtilGroupHomes: builder.mutation<UtilGroup, {
        userTenantId: string,
        groupId: string,
        builderId?: string,
        updates: UtilGroupPropertyUpdate,
      }>({
        query: ({ userTenantId, groupId, builderId, updates }) => ({
          url: `tenant/${U(userTenantId)}/groups/${U(groupId)}`,
          method: HTTPMethodType.PATCH,
          params: {
            builder_id: builderId || userTenantId,
          },
          body: updates,
        }),
      }),
      deleteUtilGroup: builder.mutation<void, {
        userTenantId: string,
        groupId: string,
        builderId?: string,
      }>({
        query: ({ userTenantId, groupId, builderId }) => ({
          url: `tenant/${U(userTenantId)}/groups/${U(groupId)}`,
          method: HTTPMethodType.DELETE,
          params: {
            builder_id: builderId || userTenantId,
          },
        }),
      }),
    }
  },
});

export const {
  useCreateUtilGroupMutation,
  useDeleteUtilGroupMutation,
  useUpdateUtilGroupHomesMutation,
  useUtilgroupQuery,
  useUtilgroupsQuery,
} = utilgroupsApi;