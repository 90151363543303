import { ManifestDevice } from "@features/plan-manifest/types";
import { findCategoryLabel, findMeasureLabel, formatReadingDetails } from "@lib/labels";
import { formatDate } from "@lib/utils";
import { ButtonGroup, Divider, Stack, Tooltip } from "@mui/material";
import { useMemo } from "react";
import { NotificationRow } from '../types';

type Props = {
  notification: NotificationRow
}

/** Display a popover with device summary */
const DevicePopover = ({ notification }: Props) => {
  const { device, sensor, entry  } = useMemo(() =>notification, [notification]);

  if (!device || !sensor || !entry) return <></>;

  return (
    <ButtonGroup>
      <Tooltip
        placement="top"
        arrow
        title={
          <Stack>
            {(entry.device as ManifestDevice).friendly_name || ''} - {sensor.friendly_name || ''}
            <Divider variant="middle" />
            <Stack>
              <Stack direction="row" justifyContent="space-between">
                <span>Latest reading:</span>
                <span>
                  {formatReadingDetails(
                    sensor.sensor_category,
                    sensor.measure_name,
                    sensor.units,
                    device.data.value,
                  ).value}
                </span>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <span>Reading date:</span>
                <span>{formatDate(device.datetime).fromNow()}</span>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <span>Category:</span>
                <span>{findCategoryLabel(sensor?.sensor_category || '')}</span>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <span>Measure:</span>
                <span>{findMeasureLabel(sensor?.measure_name || '')}</span>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <span>Area:</span>
                <span>{entry.property_area || ''}</span>
              </Stack>
            </Stack>
          </Stack>
        }
      >
        <span>
          {(entry.device as ManifestDevice).friendly_name || ''}
        </span>
      </Tooltip>
    </ButtonGroup>
  );
}


export default DevicePopover;
