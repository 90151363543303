import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ConfirmDialog from "@features/Common/ConfirmDialog";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { PropertyManifestEntry } from "@features/home-manifest/types";
import { useGetPropertyManifestQuery } from "@features/home/api";
import { ManifestDevice } from "@features/plan-manifest/types";
import { SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useMemo } from "react";
import {
  useUpdatePropertyManifestEntryMutation
} from "../../api";

type Props = {
  device: PropertyManifestEntry | null;
  onSubmit: () => void;
}

const ManualUnlinkModal = ({ device, onSubmit }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const selectedTenant = useAppSelector((state: RootState) => state.tenant);
  const property = useAppSelector((state: RootState) => state.property);

  const currentTenant = useMemo(() => selectedTenant.currentTenant || userTenant, [selectedTenant.currentTenant, userTenant]);

  const [
    updatePropertyManifestEntry,
  ] = useUpdatePropertyManifestEntryMutation();

  const {
    refetch: refetchManifest,
  } = useGetPropertyManifestQuery({
    tenantId: currentTenant?.tenant_id || '',
    propertyId: property?.property_id || '',
    includeEntries: true,
  }, {
    skip: !currentTenant.tenant_id || !property?.property_id,
  });


  /* Function to handle manual unlink of device */
  const handleManualUnlink = useCallback(async () => {
    try {
      if (currentTenant.tenant_id && device) {
        const updatedEntry = await updatePropertyManifestEntry({
          userTenantId: currentTenant.tenant_id!,
          propertyId: property.property_id,
          entryId: device.manifest_entry_id,
          body: {
            gateway_device_id: '',
            provisioned: '',
            sensor_map: {
            },
          },
        });

        if ('error' in updatedEntry) {
          enqueueSnackbar("Couldn't unlink entry:", {
            key: "entry-error",
            content: (
              <ExpandableSnack
                id="entry-error"
                message={"Couldn't unlink entry:"}
                variant={SnackType.error}
                detail={getErrorMessage(updatedEntry.error)}
              />),
          });

        } else {
          enqueueSnackbar("Unlinked entry", {
            variant: "success",
          });

          refetchManifest();
        }
      }
    } finally {
      onSubmit();
    }
  }, [currentTenant.tenant_id, device, updatePropertyManifestEntry, property.property_id, enqueueSnackbar, refetchManifest, onSubmit]);

  return (
    <ConfirmDialog
      open={device !== null}
      onClose={onSubmit}
      onConfirm={handleManualUnlink}
      title="Confirm unlink"
      initialValues={{
        isConfirmed: false,
      }}
    >
      <Typography variant="body1">
        Are you sure you want to unlink
        {' '}
        {(device?.device as ManifestDevice).friendly_name || 'this device'}
        ?
      </Typography>
    </ConfirmDialog>
  );
}

export default ManualUnlinkModal;