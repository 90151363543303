import { PlanManifest } from "@features/plan-manifest/types";
import { FormGroup, RadioGroup } from "@mui/material";
import { ChangeEvent } from "react";
import PlanManifestSummary from "./PlanManifestSummary";

type Props = {
  options: PlanManifest[];
  selected: string;
  onChange: ((event: ChangeEvent<HTMLInputElement>, value: string) => void);
}

const PlanManifestSelector = ({ options, selected, onChange }: Props) => {

  if (!options.length) return <></>;
  return (
    <FormGroup>
      <RadioGroup
        name="manifest_id"
        onChange={onChange}
      >
        {
          options.map(m => (
            <PlanManifestSummary
              key={m.manifest_id}
              manifest={m}
              isSelected={m.manifest_id === selected}
            />))
        }
      </RadioGroup>
    </FormGroup>
  );
}

export default PlanManifestSelector;