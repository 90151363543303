import { Button, Divider } from "@aws-amplify/ui-react";
import { HealthCheckType } from "@features/home-health/types";
import { Dialog, DialogActions, DialogContent, DialogTitle, List } from "@mui/material";
import { useMemo } from "react";
import HealthCheckItem from "./HealthCheckItem";
import { NeighborhoodBase } from "../types";

type Props = {
  health: NeighborhoodBase['health_status'];
  homeId: string,
  open: boolean;
  onClose: () => void;
}
const HomeHealthDialog = ({ open, health, onClose, homeId }: Props) => {
  const status = useMemo(() => health?.[homeId!]?.checks || {}, [homeId, health]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Home's health</DialogTitle>
      <Divider />
      <DialogContent>
        <List sx={{
          overflowY: 'scroll',
          height: '24em',
        }}>
          {
            Object.keys(status)
              .map(ch => (
                <HealthCheckItem
                  key={`home-check-${homeId!}-${ch}`}
                  health={health}
                  homeId={homeId}
                  healthCheckType={ch as HealthCheckType}
                />
            ))
          }
        </List>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>

  );
}

export default HomeHealthDialog;