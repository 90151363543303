import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import ExpandableSnack from '@features/Common/ExpandableSnack';
import CategoryReadingIcon from '@features/home-status/components/CategoryReadingIcon';
import { ReadingPair } from '@features/home-status/types';
import { ManifestDevice } from '@features/plan-manifest/types';
import { ApiErrorResponse, SnackType } from '@lib/types';
import { getErrorMessage, getManifestEntryLabel } from '@lib/utils';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, IconButton, List, ListItem, ListItemText, Stack, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from "notistack";
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { RIOT_BLUE } from 'theme';
import { useAddManifestEntryMutation, useRemoveManifestEntryMutation, useSupportCaseQuery } from '../api';
import { SupportCase } from '../types';
import ManifestEntrySelector from './ManifestEntrySelector';

const CaseNotes = () => {
  const { enqueueSnackbar } = useSnackbar();

  const supportCase = useAppSelector((state: RootState) => state.supportCase.case as SupportCase);
  const property = useAppSelector((state: RootState) => state.property);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant.builder_color || RIOT_BLUE, [currentTenant.builder_color]);

  const manifestEntries = useMemo(() => property.manifestEntries || [], [property.manifestEntries]);
  const devices = useMemo(() => property.devices || [], [property.devices]);

  const involvedEntries = useMemo(() => manifestEntries
    .filter(ee => supportCase.manifest_entry_ids?.includes(ee.manifest_entry_id)), [manifestEntries, supportCase.manifest_entry_ids]);


  const [addingEntry, setAddingEntry] = useState<boolean>(false);
  const [entryId, setEntryId] = useState<string>('');
  const [savingCase, setSavingCase] = useState<boolean>(false);

  const entryOptions = useMemo(() => manifestEntries
    .filter(e => (
      e.manifest_entry_id !== entryId &&
      !supportCase?.manifest_entry_ids?.includes(e.manifest_entry_id)
    )), [entryId, manifestEntries, supportCase?.manifest_entry_ids]);

  const handleAddDeviceMode = useCallback(() => {
    setAddingEntry(!addingEntry);
  }, [addingEntry]);

  useEffect(() => {
    if (!addingEntry) setEntryId('');
  }, [addingEntry]);

  const [
    addManifestEntry,
  ] = useAddManifestEntryMutation();

  const [
    removeManifestEntry,
  ] = useRemoveManifestEntryMutation();

  const {
    refetch: refetchCase,
  } = useSupportCaseQuery({
    userTenantId: currentTenant?.tenant_id || '',
    propertyId: property?.property_id || '',
    caseId: supportCase?.case_id || '',
  }, {
    skip: !currentTenant?.tenant_id || !property?.property_id || !supportCase?.case_id,
  });

  const handleAddManifestEntry = useCallback(async () => {
    setSavingCase(true);
    const result = await addManifestEntry({
      userTenantId: currentTenant?.tenant_id || '',
      propertyId: property?.property_id || '',
      caseId: supportCase?.case_id,
      entryId,
    });

    const errorDetails = (result as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't add a related device to the case:", {
        key: "add-case-device-error",
        content: (
          <ExpandableSnack
            id="add-case-device-error"
            message={"Couldn't add a related device to the case:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("Updated case", {
        variant: "success",
      });
      refetchCase();
      setAddingEntry(false);
      setSavingCase(false);
    }
  }, [addManifestEntry, currentTenant?.tenant_id, enqueueSnackbar, entryId, property?.property_id, refetchCase, supportCase?.case_id]);


  const handleRemoveManifestEntry = useCallback(async (id: string) => {
    setSavingCase(true);
    const result = await removeManifestEntry({
      userTenantId: currentTenant?.tenant_id || '',
      propertyId: property?.property_id || '',
      caseId: supportCase?.case_id,
      entryId: id,
    });

    const errorDetails = (result as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't remove related device from the case:", {
        key: "remove-case-device-error",
        content: (
          <ExpandableSnack
            id="remove-case-device-error"
            message={"Couldn't remove related device from the case:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("Updated case", {
        variant: "success",
      });
      refetchCase();
      setAddingEntry(false);
      setSavingCase(false);
    }

  }, [currentTenant?.tenant_id, enqueueSnackbar, property?.property_id, refetchCase, removeManifestEntry, supportCase?.case_id]);

  return (
    <Stack spacing={1}>
      <Typography variant="h6">Related devices</Typography>
      {
        !addingEntry &&
        <List
          dense
          sx={{
            padding: 1,
            maxHeight: '6em',
            overflow: 'auto'
          }}
        >
          {
            involvedEntries.map((ee, idx) => (
              <Fragment key={`device-${idx}`}>
                <ListItem disableGutters>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      width: 'inherit',
                    }}
                  >
                    <Stack direction="row" spacing={1}>
                      <Tooltip title="Remove device from this support case" placement="top" arrow>
                        <span>
                          <IconButton disabled={savingCase} onClick={() => handleRemoveManifestEntry(ee.manifest_entry_id)}>
                            <DeleteIcon color={savingCase ? 'disabled' : 'error'} />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Stack>
                    <ListItemText>{getManifestEntryLabel(ee)}</ListItemText>
                    <Stack
                      direction="row"
                      justifyContent="end"
                      sx={{
                        flexWrap: 'wrap',
                        maxWidth: '70%',
                      }}
                    >
                      {
                        (ee.device as ManifestDevice).sensors.map(sensor => {
                          const kk = `reading-${ee.manifest_entry_id}-${ee.sensor_map?.[sensor.sensor_id ?? sensor.friendly_name]}`
                          const device = devices
                            .find(d => (
                              d.data.device_id === ee.gateway_device_id
                              && d.data.entity_id === ee.sensor_map?.[sensor.sensor_id ?? sensor.friendly_name]
                            ));

                          if (!device) return <Fragment key={kk}></Fragment>;

                          return (
                            <Box
                              component="div"
                              sx={{
                                border: `1px solid ${themeColor}`,
                                borderRadius: '4px',
                                padding: '2px 4px',
                              }}
                            >
                              <CategoryReadingIcon
                                key={kk}
                                category={sensor.sensor_category}
                                measure={sensor.measure_name}
                                readings={{
                                  device,
                                  sensor,
                                  deviceName: getManifestEntryLabel(ee),
                                  area: ee.property_area,
                                  entryId: ee.manifest_entry_id,
                                } as ReadingPair}
                              />
                            </Box>

                          );
                        })
                      }
                    </Stack>
                  </Stack>
                </ListItem>
                <Divider component="li" sx={{ borderColor: themeColor }} />
              </Fragment>
            ))
          }
        </List>
      }
      {
        addingEntry &&
        <ManifestEntrySelector
          options={entryOptions}
          onChange={(id) => setEntryId(id)}
        />
      }
      <Stack direction="row" spacing={1}>
        <Button size="small" variant="contained" onClick={handleAddDeviceMode} disabled={savingCase}>
          {addingEntry ? 'Cancel' : 'Add related device'}
        </Button>
        {
          addingEntry &&
          <LoadingButton
            size="small"
            variant="contained"
            onClick={handleAddManifestEntry}
            loading={savingCase}
          >
            Submit
          </LoadingButton>
        }
      </Stack>
    </Stack>
  );
}

export default CaseNotes;