import { useAppDispatch, useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { pollingInterval } from '@features/tenant-selector/types';
import { Box } from '@mui/material';
import { FC, useEffect, useMemo } from "react";
import { useNeighborhoodNotificationsQuery, useNotificationsQuery } from '../api';
import { setNotifications } from '../slice';


const NotificationProvider: FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const awsUser = useAppSelector((state: RootState) => state.awsUser);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const property = useAppSelector((state: RootState) => state.property);
  const neighborhood = useAppSelector((state: RootState) => state.neighborhood);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const {
    currentData: userHomeNotifications,
    isFetching: isFetchingHomeNotifs,
  } = useNotificationsQuery({
    userTenantId: currentTenant?.tenant_id || '',
    propertyId: property?.property_id || '',
    includeUserActions: true,
    username: awsUser.username || '',
  }, {
    skip: !currentTenant?.tenant_id || !property?.property_id,
    pollingInterval,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  const {
    currentData: userNeighborhoodNotifications,
    isFetching: isFetchingHoodNotifs,
  } = useNeighborhoodNotificationsQuery({
    userTenantId: currentTenant?.tenant_id || '',
    neighborhoodId: neighborhood?.neighborhood?.neighborhood_id || '',
    includeUserActions: true,
    username: awsUser.username,
  }, {
    skip: !currentTenant?.tenant_id || !neighborhood?.neighborhood?.neighborhood_id,
  });

  useEffect(() => {
    if (userHomeNotifications !== undefined) {
      dispatch(setNotifications(userHomeNotifications));
    }
  }, [dispatch, userHomeNotifications]);

  useEffect(() => {
    if (userNeighborhoodNotifications !== undefined) {
      dispatch(setNotifications(userNeighborhoodNotifications));
    }
  }, [dispatch, userNeighborhoodNotifications]);

  return (
    <Box component="div" sx={{ opacity: (isFetchingHomeNotifs || isFetchingHoodNotifs) ? 0.9 : 1 }}>
      {children as JSX.Element}
    </Box>
  );
}

export default NotificationProvider;