import AppTextField from "@features/home/components/AppTextField";
import { FormGroup } from "@mui/material";


type Props = {
  /** address attribute on form */
  attributeName?: string;
  /** width of the street address formgroup */
  width?: string;
  /** margins of the street address formgroup */
  margin?: string;
  /** indicates whether to render fields editable or read-only */
  readOnly?: boolean;
}

/*
* Legal address of the property
* Lot | Block | Section
* Sub-division | Community
* */
const LegalAddress = ({
  attributeName = 'physical_address',
  width = '49%',
  margin = '0 0 0 1%',
  readOnly,
}: Props) => (
  <FormGroup sx={{
    width,
    margin,
  }}>
    <FormGroup row sx={{
      width: '100%',
      margin: 0,
      justifyContent: 'space-between'
    }}>
      <AppTextField
        name={`${attributeName}.legal_address.lot`}
        label="Lot"
        type="text"
        margin="dense"
        size="small"
        sx={{
          width: '30%',
        }}
        disabled={readOnly}
      />
      <AppTextField
        name={`${attributeName}.legal_address.block`}
        label="Block"
        type="text"
        margin="dense"
        size="small"
        sx={{
          width: '30%',
        }}
        disabled={readOnly}
      />
      <AppTextField
        name={`${attributeName}.legal_address.section`}
        label="Section"
        type="text"
        margin="dense"
        size="small"
        sx={{
          width: '30%',
        }}
        disabled={readOnly}
      />
    </FormGroup>
    <FormGroup row sx={{
      width: '100%',
      margin: 0,
      justifyContent: 'space-between'
    }}>
      <AppTextField
        name={`${attributeName}.legal_address.subdivision`}
        label="Sub-division"
        type="text"
        margin="dense"
        size="small"
        sx={{
          width: '49%',
        }}
        disabled={readOnly}
      />
      <AppTextField
        name={`${attributeName}.legal_address.community`}
        label="Community"
        type="text"
        margin="dense"
        size="small"
        sx={{
          width: '49%',
        }}
        disabled={readOnly}
      />
    </FormGroup>
  </FormGroup>
);

export default LegalAddress;