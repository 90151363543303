import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { TenantType } from '@features/userTenant/types';
import { checkPermission } from '@features/userTenant/utils';
import AddIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import ViewIcon from '@mui/icons-material/PreviewOutlined';
import ShareIcon from '@mui/icons-material/Share';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import { FormikConfig, FormikProps } from 'formik';
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
  MUIDataTableProps
} from 'mui-datatables';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RIOT_BLUE } from "theme";
import EditDialog from './EditDialog';
import { getHeaderStyles, renderLite } from './tables';


const DEFAULT_TABLE_OPTIONS: MUIDataTableOptions = {
  // Don't allow selecting rows
  selectableRows: 'none',
  // Don't allow selection columns
  viewColumns: false,
  // Allow object paths using '.', like 'part.name'
  enableNestedDataAccess: '.',
}

// Helper type
type HasName = {
  /** Entity name */
  name?: string;
}

type Props<T extends HasName> = {
  /** Key for the the ID field (e.g. tenant_id) */
  id_key: keyof T;
  /** Label for the entity type (e.g. Customer) */
  label: string;
  /**
   * Callback triggered when preparing a new entity for the edit form
   * @returns initial form values for the new entity
   */
  makeNew?: () => Partial<T>;
  /**
   * Callback triggered when the entity should be saved
   *
   * @param row updated entity for the row
   * @returns saved entity, or null if it could not be saved
   */
  onSave?: (row: Partial<T>) => any;

  /** Callback triggered when the table should be refreshed */
  refresh: () => any;

  /**
   * Callback triggered when a new entity should be created.
   * If not provided, the New button will be hidden.
   *
   * @returns true if a new entry was creawted, false or null otherwise
   */
  onNew?: () => Promise<boolean | null>;

  /** Key for the name field (default: 'name') */
  name_key?: keyof T | 'name';
  /**
   * Callback triggered when an entity should be deleted.
   * If not provided, the Delete button will be hidden.
   *
   * @param row the entity for the row to be deleted
   * @returns true if deleted, false or null otherwise
   */
  onDelete?: (row: T) => Promise<boolean | null>;
  /**
   * Callback triggered when a new entity should be share.
   * If not provided, the Share button will be hidden.
   *
   * @returns true if a new entry was shared, false or null otherwise
   */
  onShare?: (row: T) => Promise<boolean | null>;
  /**
   * Callback triggered when constructing a path to the admin page for the entity.
   *
   * If provided, the Edit button will send the user to the admin page to edit the entity.
   * If not provided, the Edit button will open a form in a dialog.
   *
   * @param row the entity for the row
   * @returns path to admin page
   */
  makeEditPath?: (row: T) => string;

  /**
   * Callback triggered when constructing a path to the view page for the entity.
   *
   * If provided, the View button will send the user to the view page for the entity.
   * If not provided, the View button will be hidden
   *
   * @param row the entity for the row
   * @returns path to view page
   */
  makeViewPath?: (row: T) => string;

  /**
   * Function rendering optional elements in Actions column.
   *
   * @param row the row for which to render custom action buttons
   * @returns custom action elements as a React fragment
   */
  customActions?: (row: T) => React.ReactFragment;

  /** Permission key to edit entity (allowed if missing) */
  editPermission?: string;

  /** Permission key to delete entity (allowed if missing) */
  deletePermission?: string;

  /** Form elements as React children or child render callback */
  children?:
  | ((props: FormikProps<T>) => React.ReactNode)
  | React.ReactNode;

  /** Additional properties passed to Formik (e.g. validationSchema )*/
  FormikProps?: Partial<FormikConfig<T>>;
} & MUIDataTableProps;

/**
 * Standard table for editing entities in the Admin UI.
 *
 * Additional properties are passed to MUIDataTable.
 *
 * @example
 * <AdminTable<Property> title="Properties" label="Property"
 *   id_key="property_id"
 *   columns={columns}
 *   data={data}
 *   FormikProps={{
 *     validationSchema: PROPERTY_SCHEMA
 *   }}
 *   makeNew={() => ({
 *     ...
 *   })}
 *   makeViewPath={
 *     (row) => `/homes/${row.property_id}`
 *   }
 *   makeEditPath={
 *     (row) => `/admin/homes/${row.property_id}`
 *   }
 *   onSave={
 *     (row) => (row.property_id)
 *       ? this.props.api.updateProperty(row.property_id, row)
 *       : this.props.api.createProperty(row)
 *   }
 *   refresh={this.fetchProperties}
 * >
 *   <AppTextField
 *     name="name"
 *     label="Name"
 *     type="text"
 *     required
 *     fullWidth
 *   />
 * </AdminTable>
 *
 * If you need access to the Formik context, wrap the form fields in a function, like:
 * @example
 * <AdminTable>
 *   { f => (
 *     <AppTextField onChange={(e) => {
 *        doStuff(e.target.value);
 *        f.handleChange(e);
 *     }} />
 *   )}
 * </AdminTable>
 */
export default function AdminTable<T extends HasName>(props: Props<T>) {
  // NOTE: this is built as a function rather than class to help the linter with
  // the generic typing

  const {
    id_key,
    name_key = 'name',
    // AdminTable-specific options
    label,
    makeNew,
    makeEditPath,
    makeViewPath,
    onDelete,
    onNew,
    onSave,
    onShare,
    refresh,
    children,
    customActions,
    // MUIDataTable REQUIRED OPTIONS
    title,
    columns,
    data,
    options = {},
    // Permissions
    editPermission,
    deletePermission,
    // Formik options
    FormikProps = {},
    // Additional MUIDataTable options
    ...tableProps
  } = props;

  const fullOptions: MUIDataTableOptions = {
    // Start with the default options
    ...DEFAULT_TABLE_OPTIONS,
    // Hide the pagination unless it is needed
    pagination: data?.length > 10,
    // Merge in the caller options
    ...(options ?? {})
  }

  const navigate = useNavigate();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant?.builder_color || RIOT_BLUE, [currentTenant?.builder_color]);
  const userPermissions = useMemo(() => userTenant.userPermissions, [userTenant.userPermissions]);

  // Selected rows for the Edit and Delete dialogs
  const [editRow, setEditRow] = useState<T | null>(null);
  const [deleteRow, setDeleteRow] = useState<T | null>(null);

  // Check the permissions
  const canEdit = useMemo(() => {
    if (editPermission === 'admin-only') {
      return currentTenant?.tenant_type === TenantType.System;
    }
    return editPermission && checkPermission(userPermissions, editPermission);
  }, [currentTenant?.tenant_type, editPermission, userPermissions]);
  const canDelete = useMemo(() => deletePermission && checkPermission(userPermissions, deletePermission), [deletePermission, userPermissions]);

  /**
   * Navigates to the url created by running provided
   * function on the provided row
   */
  const navigateTo = useCallback((makePath: (row: T) => string, row: T) => {
    if (makePath) navigate(makePath(row));
  }, [navigate]);

  const renderedActionsColumns = useMemo(() => {
    if (!customActions && !onShare && !makeViewPath && !canEdit && !canDelete && !onDelete) {
      return [];
    }

    return ([{
      name: 'actions',
      label: 'Actions',
      options: {
        // Disable all the features
        filter: false,
        download: false,
        searchable: false,
        sort: false,
        // Keep the column small
        customBodyRenderLite: renderLite(data as T[], (row: T) => (
          <ButtonGroup>
            {
              customActions ? customActions(row) : false
            }
            {
              onShare &&
              <Tooltip title={`Share ${label}`} placement="top" arrow>
                <IconButton
                  sx={{
                    color: themeColor,
                  }}
                  onClick={() => onShare(row)}
                >
                  <ShareIcon />
                </IconButton>
              </Tooltip>
            }
            {
              makeViewPath &&
              <Tooltip title={`View ${label}`} placement="top" arrow>
                <IconButton color="success" onClick={() => navigateTo(makeViewPath,row)}>
                  <ViewIcon />
                </IconButton>
              </Tooltip>
            }
            {
              canEdit &&
              <Tooltip title={`Edit ${label}`} placement="top" arrow>
                <IconButton color="warning" onClick={() => makeEditPath ? navigateTo(makeEditPath, row) : setEditRow(row)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            }
            {
              canDelete && onDelete &&
              <Tooltip title={`Delete ${label}`} placement="top" arrow>
                <IconButton onClick={() => setDeleteRow(row)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            }
          </ButtonGroup>
        )),
      }
    } as MUIDataTableColumn]);
  }, [canDelete, canEdit, customActions, data, label, makeEditPath, makeViewPath, navigateTo, onDelete, onShare, themeColor]);

  /** ADMIN TABLE ACTION COLUMNS */
  const fullColumns = useMemo(() => [
    // Add in the Actions columns
    ...renderedActionsColumns,
    // Merge in the caller columns
    ...columns,
  ]
    .filter(e => e !== undefined)
    .map((cc) => ({
      ...cc as MUIDataTableColumn,
      options: {
        ...(cc as MUIDataTableColumn).options,
        setCellHeaderProps: () => getHeaderStyles(themeColor),
      },
    } as MUIDataTableColumn)), [columns, renderedActionsColumns, themeColor]);


  /** Handles "+" click */
  const onCreate = useCallback(() => {
    if (onNew) onNew();
    // Open dialog to Create a new record
    if(makeNew) setEditRow(makeNew() as T);

  }, [makeNew, onNew]);

  /** MAIN RENDER */
  return (
    <>
      <MUIDataTable {...tableProps}
        options={fullOptions}
        columns={fullColumns}
        data={data}
        title={(onNew || makeNew) &&
          <Typography variant="h6">
            <Tooltip title={`Add ${label}`} placement="top" arrow>
              <IconButton onClick={onCreate}>
                <AddIcon />
              </IconButton>
            </Tooltip>
            {title}
          </Typography>
        }
      />
      {
        canEdit !== undefined && editRow && (
          <EditDialog
            open={true}
            title={editRow[id_key] ? `Edit "${editRow[name_key]}"` : `Create New ${label}`}
            initialValues={editRow}
            onSave={async (row) =>  {
              if (onSave) {
                const resp = await onSave(row);
                if (makeEditPath) {
                  // Navigate to the new page after creation
                  navigateTo(makeEditPath, resp);
                }
                else {
                  // Refresh the table
                  refresh();
                }
                return !!resp;
              }
              return true;
            }}
            onClose={() => setEditRow(null)}
            {...FormikProps}
          >
            {children}
          </EditDialog>
        )
      }
      {
        canDelete && onDelete && deleteRow && (
          <Dialog open={true}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to delete "{deleteRow[name_key]}"
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteRow(null)}>
                Cancel
              </Button>
              <Button variant="contained" color="primary"
                onClick={async () => {
                  const res = await onDelete(deleteRow);
                  if (res) {
                    // Refresh the table
                    setDeleteRow(null);
                    refresh();
                  }
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
    </>
  );
}
