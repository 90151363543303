import BuilderList from "@features/all-builders/components/BuilderList";
import BuildersProvider from "@features/all-builders/components/BuildersProvider";
import Header from "@features/Common/Header";
import { FC } from "react";


/** Wrapper for /builders */
const AllBuilders: FC = () => (
  <BuildersProvider>
    <Header />
    <BuilderList />
  </BuildersProvider>
);

export default AllBuilders;