import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import AdminTable from '@features/Common/AdminTable';
import ConfirmDialog from '@features/Common/ConfirmDialog';
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { PropertyManifestEntry } from '@features/home-manifest/types';
import SystemStatus from '@features/home-status/components/SystemStatus';
import { Device } from '@features/home/types';
import { ManifestDevice, Sensor } from '@features/plan-manifest/types';
import { findCategoryLabel } from '@lib/labels';
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import { Backdrop, Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useSnackbar } from "notistack";
import { FC, useCallback, useMemo, useState } from 'react';
import { RIOT_BLUE } from 'theme';
import { useDismissAllMutation, useDismissMutation, useNeighborhoodNotificationsQuery, useNotificationsQuery } from '../api';
import { NotificationRow, NotificationStatus, UserNotification } from '../types';
import ViewControls from './ViewControls';
import { getColumns } from './columns';


//** Provides a grid table of notifications for current property */
const Notifications: FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const awsUser = useAppSelector((state: RootState) => state.awsUser);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const property = useAppSelector((state: RootState) => state.property);
  const neighborhood = useAppSelector((state: RootState) => state.neighborhood.neighborhood);
  const userNotificationState = useAppSelector((state: RootState) => state.notifications);

  const userNotifications = useMemo(() => userNotificationState?.notifications || [], [userNotificationState?.notifications]);
  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant?.builder_color || RIOT_BLUE, [currentTenant?.builder_color]);
  const manifestEntries = useMemo(() => property.manifestEntries || [], [property.manifestEntries]);
  const devices = useMemo(() => property.devices || [], [property.devices]);

  const [dismissingOne, setDismissingOne] = useState<string>('')
  const [dismissingAll, setDismissingAll] = useState<boolean>(false);

  const {
    refetch: refetchHomeNotifs,
  } = useNotificationsQuery({
    userTenantId: currentTenant?.tenant_id || '',
    propertyId: property?.property_id || '',
    includeUserActions: true,
    username: awsUser.username,
  }, {
    skip: !currentTenant?.tenant_id || !property?.property_id,
  });

  const {
    refetch: refetchHoodNotifs,
  } = useNeighborhoodNotificationsQuery({
    userTenantId: currentTenant?.tenant_id || '',
    neighborhoodId: neighborhood?.neighborhood_id || '',
    includeUserActions: true,
    username: awsUser.username,
  }, {
    skip: !currentTenant?.tenant_id || !neighborhood?.neighborhood_id,
  });

  const refetch = useCallback(() => {
    refetchHomeNotifs();
    refetchHoodNotifs();
  }, [refetchHomeNotifs, refetchHoodNotifs]);

  const [
    dismissNotification,
  ] = useDismissMutation();

  const [
    dismissAllNotifications,
    { isLoading: isDismissingAll },
  ] = useDismissAllMutation();

  const handleDismissOne = useCallback(async () => {
    const result = await dismissNotification({
      userTenantId: currentTenant?.tenant_id || '',
      userNotificationId: dismissingOne,
    });

    const errorDetails = (result as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar(`Couldn't dismiss notification:`, {
        key: "notification-dismiss-error",
        content: (
          <ExpandableSnack
            id="notification-dismiss-error"
            message={`Couldn't dismiss notification:`}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("notification dismissed", {
        variant: "success",
      });
      setDismissingOne('');
      refetch();
    }
  }, [dismissNotification, dismissingOne, enqueueSnackbar, refetch, currentTenant?.tenant_id]);

  const handleDismissAll = useCallback(async () => {
    const result = await dismissAllNotifications({
      userTenantId: currentTenant?.tenant_id || '',
      propertyId: property?.property_id,
    });

    const errorDetails = (result as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar(`Couldn't dismiss all notifications:`, {
        key: "notifications-dismiss-error",
        content: (
          <ExpandableSnack
            id="notifications-dismiss-error"
            message={`Couldn't dismiss all notifications:`}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("All notification dismissed", {
        variant: "success",
      });
      setDismissingAll(false);
      refetch();
    }
  }, [dismissAllNotifications, enqueueSnackbar, property?.property_id, refetch, currentTenant?.tenant_id]);

  /** GRID ROWS */
  const displayedNotifications: NotificationRow[] = useMemo(() => (userNotifications || [])
    .filter(n => n.lifecycle_action !== NotificationStatus.dismissed)
    .filter(n => !n.lifecycle.find(l => l.action === NotificationStatus.dismissed))
    .sort((a, b) => Number(b.datetime) - Number(a.datetime))
    .map((n: UserNotification) => {
      const entry: PropertyManifestEntry | undefined = manifestEntries
        ?.find((e) => Object.values(e?.sensor_map || {}).includes(n.entity_id));

      const sensor: Sensor | undefined = (entry?.device as ManifestDevice)?.sensors
        ?.find(s => entry?.sensor_map?.[s.sensor_id ?? s.friendly_name] === n.entity_id);

      const device: Device | undefined = devices?.find(dd => dd.data.entity_id === n.entity_id);

      return ({
        ...n,
        category: sensor?.sensor_category ? findCategoryLabel(sensor?.sensor_category) : 'unknown',
        sensor,
        device,
        entry,
      } as NotificationRow);
    }), [devices, manifestEntries, userNotifications]);


  const isLoading = useMemo(() => !currentTenant.tenant_id || !(property.property_id || neighborhood?.neighborhood_id) || isDismissingAll,
    [currentTenant.tenant_id, isDismissingAll, neighborhood?.neighborhood_id, property.property_id]);

  const renderedTable = useMemo(() => (
    <AdminTable<NotificationRow>
      title=""
      label="Notification"
      id_key="user_notification_id"
      name_key="property_area"
      columns={getColumns(displayedNotifications, themeColor, setDismissingOne)}
      options={{
        enableNestedDataAccess: '.',
      }}
      data={displayedNotifications}
      refresh={refetch}
      // we're not letting anyone to edit/delete notifications directly at this time,
      // these are intentionally non-existing permissions
      editPermission="delete-notifications"
      deletePermission="delete-notifications"
    >
    </AdminTable>
  ), [displayedNotifications, refetch, themeColor]);


  /** MAIN RENDER */
  return (
    <Box
      component="div"
      sx={{
        maxWidth: 'unset',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
      }}
    >
      <Stack spacing={1}>
        <SystemStatus alert />
        <ViewControls onDismissAll={() => setDismissingAll(true)} />
        {isLoading ? <Backdrop open><CircularProgress /></Backdrop> : renderedTable}
      </Stack>
      <ConfirmDialog
        open={Boolean(dismissingOne)}
        onClose={() => setDismissingOne('')}
        onConfirm={handleDismissOne}
        title="Dismissing notification"
        initialValues={{
          isConfirmed: false,
        }}
      >
        <Typography variant="body1">
          Are you sure you want to dismiss this notification?
          This will remove the notification from Dashboard.
        </Typography>
      </ConfirmDialog>
      <ConfirmDialog
        open={Boolean(dismissingAll)}
        onClose={() => setDismissingAll(false)}
        onConfirm={handleDismissAll}
        title="Dismissing all notifications"
        initialValues={{
          isConfirmed: false,
        }}
      >
        <Typography variant="body1">
          Are you sure you want to dismiss <b>ALL</b> notifications for this home?
          This will remove the notifications from Dashboard.
        </Typography>
      </ConfirmDialog>
    </Box>
  );
}

export default Notifications;