import { NotificationEventSummary, Property } from "@features/home/types";
import { U } from "@lib/utils";
import AnnouncementIcon from '@mui/icons-material/Announcement';
import WarningIcon from '@mui/icons-material/Warning';
import { Badge, ButtonGroup, IconButton, Tooltip } from "@mui/material";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";


type Props = {
  home: Property;
}

const NotificationCountsColumn = ({ home }: Props) => {
  const navigate = useNavigate();

  const notifications = useMemo(() => home.event_summary?.notifications || {} as NotificationEventSummary, [home.event_summary]);

  return (
    <ButtonGroup>
      <Tooltip title={`Urgent`} placement="top" arrow>
        <IconButton onClick={() => navigate(`/homes/${U(home.property_id)}/notifications`)}>
          <Badge badgeContent={notifications?.urgent} color="error">
            <AnnouncementIcon color="action" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Tooltip title={`Warnings`} placement="top" arrow>
        <IconButton onClick={() => navigate(`/homes/${U(home.property_id)}/notifications`)}>
          <Badge badgeContent={notifications?.warning} color="info">
            <WarningIcon color="action" />
          </Badge>
        </IconButton>
      </Tooltip>
    </ButtonGroup>);
}

export default NotificationCountsColumn;