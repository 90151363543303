import { Address } from "@features/home/types";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";

type Props = {
  address?: Address;
  spacing?: number;
  variant?: "body2" | "button" | "caption" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "inherit" | "subtitle1" | "subtitle2" | "body1" | "overline" | undefined;
}

/* Displays address */
const AddressSummary = ({ address, spacing = 1, variant = "body2" }: Props) => {
  if (!address) return <></>;

  const postalAddress = [
    address.address_line_1,
    address.address_line_2,
  ].filter(e => e);

  const legalAddress = [
    [
      address.legal_address?.lot,
      address.legal_address?.block,
      address.legal_address?.section,
    ].filter(e => e?.length).join('/'),
    address.legal_address?.subdivision,
    address.legal_address?.community,
  ].filter(e => e);

  const children = [
    ...postalAddress,
    legalAddress.join(', '),
    [
      address.city,
      address.state,
      address.zip_postal_code,
    ].join(', '),
    address.country,
  ]
    .filter(e => e)
    .map(e => e as string)
    .map((item: string, idx: number) => (
      <Typography
        key={`addr-${idx}`}
        variant={variant}
      >
        {item || ''}
      </Typography>
    ));

  return <Stack spacing={spacing}>{children}</Stack>;

}

export default AddressSummary;
