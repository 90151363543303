import { Tenant } from "@features/userTenant/types";
import { createSlice } from "@reduxjs/toolkit";


const initialState: Tenant[] = []

/** State slice for the tenant selected to use */
export const tenantReducer = createSlice({
  name: 'builders',
  initialState,
  reducers: {
    setBuilders: (state: any, action: any) => {
      if (action.payload) {
        state.splice(0, state.length);
        action.payload.forEach((tt: Tenant) => state.push(tt));
      }
    },
    resetBuilders: (state) => state = initialState,
  }
});

export default tenantReducer.reducer;

export const {
  setBuilders,
} = tenantReducer.actions;