import { ALL_CATEGORIES, Category, getMeasuresByCategory, Measure } from "@lib/labels";
import { createSlice } from "@reduxjs/toolkit";

type CategoryMeasureMapping = {
  [category in Category]: Measure[];
}

const initialState = ALL_CATEGORIES.reduce((acc, cat) => ({
  ...acc,
  [cat]: getMeasuresByCategory(cat),
} as CategoryMeasureMapping), {} as CategoryMeasureMapping);

export const categoryFilterReducer = createSlice({
  name: 'displayedCategories',
  initialState,
  reducers: {
    setCategories: (state: any, action: any) => {
      if (action.payload) {
        const { measures, category } = action.payload;
        state[category as Category] = measures;
      }
    },
    setAllCategories: (state: any, action: any) => {
      if (action.payload) {
        Object.keys(state).forEach((cat) => state[cat as Category] = getMeasuresByCategory(cat as Category));
      } else {
        Object.keys(state).forEach(cat => state[cat as Category] = []);
      }
    }
  }
});

export default categoryFilterReducer.reducer;
export const { setCategories, setAllCategories } = categoryFilterReducer.actions;