import { useAppDispatch, useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { pollingInterval } from "@features/tenant-selector/types";
import { FC, ReactElement, useEffect, useMemo } from "react";
import { useCasesQuery } from "../api";
import { setSupportCases } from "../slice";


/** Wrapper for plan list under selected tenant */
const HomeSupportCasesProvider: FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const property =  useAppSelector((state: RootState) => state.property);
  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const {
    currentData: cases,
  } = useCasesQuery({
    userTenantId: currentTenant?.tenant_id || '',
    propertyId: property?.property_id || '',
  }, {
    skip: !currentTenant?.tenant_id || !property?.property_id,
    pollingInterval,
  });

  useEffect(() => {
    dispatch(setSupportCases(cases));
  }, [userTenant.tenant_id, cases, dispatch]);

  return children as ReactElement;
}

export default HomeSupportCasesProvider;
