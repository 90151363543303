import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ViewTypeToggle from "@features/Common/ViewTypeToggle";
import { Property } from '@features/home/types';
import { DashboardView, DashboardViewType } from "@features/settings/types";
import AddIcon from '@mui/icons-material/AddBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteCloseReason,
  Box,
  Button,
  ClickAwayListener,
  FormGroup,
  IconButton,
  Input,
  Paper,
  Popper,
  Stack, Tooltip
} from "@mui/material";
import {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  MouseEvent,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { useNavigate } from 'react-router-dom';
import { RIOT_BLUE } from 'theme';
import PopperComponent from '../../Common/AutocompletePopper';


type Props = {
  homes: Property[];
  onTypeChange: Dispatch<SetStateAction<DashboardViewType>>;
  onNameFilterChange: (filter: string[]) => void;
};

interface HomeOption {
  id: string;
  label: string;
  location: string;
}


const ViewControls = ({ homes, onTypeChange, onNameFilterChange }: Props) => {
  const navigate = useNavigate();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant?.builder_color || RIOT_BLUE, [currentTenant?.builder_color]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [pendingValue, setPendingValue] = useState<HomeOption[]>([]);

  const getHomeLabel = (home: Property)  => {
    if (home.name) return home.name;
    const { address_line_1: street, legal_address: legal } = home.physical_address;
    if (street) return street;
    if (legal) {
      const { lot, block, section, community, subdivision } = legal;
      return `${section}/${block}/${lot} - ${community || ''} ${subdivision || ''}`;
    }
    return '--';
  }

  const getHomeLocation = (home: Property) => {
    const { physical_address: address } = home;
    if (address) {
      const { city, legal_address: legal } = address;
      if (legal) {
        const { community, subdivision } = legal;
        if (community?.length || subdivision?.length)
          return [community, subdivision].filter(ll => ll?.length).join(' ');
      }
      return city;
    }
    return '--';
  }

  const searchOptions =  useMemo(() => homes.map(hh => ({
    label: getHomeLabel(hh),
    id: hh.property_id,
    location: getHomeLocation(hh),
  } as HomeOption)), [homes]);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangeFilter = (
    event: SyntheticEvent<Element, Event>,
    value: HomeOption[],
    reason: AutocompleteChangeReason,
  ) => {
    if (
      event.type === 'keydown' &&
      (event as KeyboardEvent).key === 'Backspace' &&
      reason === 'removeOption'
    ) {
      return;
    }
    setPendingValue(value);
  }

  const handleClearFilter = useCallback(() => {
    onNameFilterChange([]);
    setPendingValue([]);
  }, [onNameFilterChange]);

  useEffect(() => {
    onNameFilterChange(pendingValue.map(v => v.id));
  }, [onNameFilterChange, pendingValue]);

  const open = Boolean(anchorEl);
  const id = open ? 'city-filter' : undefined;

  /** MAIN RENDER */
  return (
    <FormGroup sx={{
      background: "white",
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: 2,
      p: 1,
      mb: 2,
    }}>
      <FormGroup sx={{
        flexDirection: 'row',
        alignItems: 'center',
      }}>
        <Tooltip title="New Home" placement="top" arrow>
          <IconButton
            onClick={() => navigate('/homes/new')}
            sx={{
              ml: 1,
              bgcolor: 'white',
              color: themeColor,
            }}
          >
            <AddIcon fontSize="large" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Compare Homes" placement="top" arrow>
          <span>
            <IconButton
              disabled={!homes.length}
              onClick={handleClick}
              sx={{
                ml: 1,
                bgcolor: 'white',
                color: themeColor,
              }}
            >
              <TravelExploreIcon fontSize="large" />
            </IconButton>
          </span>
        </Tooltip>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-start"
        >
          <ClickAwayListener
            onClickAway={(e) => {
              e.preventDefault();
              setAnchorEl(null);
            }}
          >
            <Paper sx={{
              p: 1,
              border: `1px solid ${themeColor}`,
              width: '350px'
            }}>
              <Autocomplete
                open
                multiple
                onClose={(
                  event: ChangeEvent<{}>,
                  reason: AutocompleteCloseReason,
                ) => {
                  if (reason === 'escape') {
                    setAnchorEl(null);
                  }
                }}
                value={pendingValue}
                onChange={handleChangeFilter}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                disableCloseOnSelect
                PopperComponent={PopperComponent}
                renderTags={() => null}
                noOptionsText="No labels"
                getOptionLabel={(option) => option.label}
                renderOption={(props, option, { selected }) =>{
                  return (
                    <li {...props} key={option.id}>
                      {selected ? <DoneIcon /> : <CheckBoxOutlineBlankIcon />}
                      <Box
                        component="span"
                        sx={{
                          width: 14,
                          height: 14,
                          flexShrink: 0,
                          borderRadius: '3px',
                          mr: 1,
                          mt: '2px',
                        }}
                      />
                      <Box
                        component="div"
                        sx={{
                          flexGrow: 1,
                        }}
                      >
                        <span>{option.label}</span>
                      </Box>
                      {selected && <CloseIcon />}
                    </li>
                )}}
                options={[...searchOptions].sort((a, b) => {
                  return -b.location.localeCompare(a.location);
                })}
                groupBy={option => option.location}
                renderInput={params => (
                  <Stack direction="row" justifyContent="space-between">
                    <Input
                      ref={params.InputProps.ref}
                      inputProps={params.inputProps}
                      autoFocus
                      placeholder="Select homes"
                      sx={{
                        width: '100%',
                        color: themeColor,
                        '&::after': {
                          borderBottom: `2px solid ${themeColor}`
                        },
                      }}
                    />
                    <Tooltip placement='top' title="Clear selection">
                      <span>
                        <Button onClick={handleClearFilter} sx={{ color: themeColor }}>
                          Clear
                        </Button>
                      </span>
                    </Tooltip>
                  </Stack>
                )}
              />
            </Paper>
          </ClickAwayListener>
        </Popper>
      </FormGroup>
      <ViewTypeToggle onChange={onTypeChange} view={DashboardView.homes} />
    </FormGroup>
  );
}

export default ViewControls;