import { riotApi } from "@features/aws-user/api";
import { U } from "@lib/utils";


export const homesApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      homes: builder.query<any, { userTenantId: string, tenantId: string, includeSummary?: boolean }>({
        query: ({ userTenantId, tenantId, includeSummary }) => ({
          url: `/tenant/${U(userTenantId)}/properties`,
          params: {
            tenant_id: U(tenantId),
            include_summary: U(includeSummary ?? true),
          },
        }),
      }),
    };
  },
});

export const {
  useHomesQuery,
} = homesApi;