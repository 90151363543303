

// import AbcIcon from '@mui/icons-material/Abc';
// import SpeedIcon from '@mui/icons-material/Speed';
// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { FormGroup, IconButton, Tooltip } from "@mui/material";


type Props = {
  onDismissAll: () => void;
}

const ViewControls = ({ onDismissAll }: Props) => {


  return (
    <FormGroup sx={{
      background: "white",
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: 2,
      p: 1,
      mb: 2,
    }}>
      <Tooltip title="Dismiss all notifications" placement="top" arrow>
        <span>
          <IconButton
            color="primary"
            onClick={() => onDismissAll()}
            sx={{
              ml: 1,
              bgcolor: 'white',
            }}
          >
            <MarkEmailReadIcon />
          </IconButton>
        </span>
      </Tooltip>
    </FormGroup>
  );
}

export default ViewControls;