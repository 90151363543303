import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import DeviceSensors from "@features/plan-manifest/components/DeviceSensors";
import InfoIcon from '@mui/icons-material/Info';
import { Autocomplete, List, ListItemButton, ListItemText, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { SyntheticEvent, useCallback, useMemo } from "react";
import { RIOT_BLUE } from "theme";

type Props = {
  onChange: (selected: string|null) => unknown;
  selected?: string;
  compact?: boolean;
}

type Option = {
  label: string | undefined;
  id: string;
};

type AutocompleteOption = NonNullable<string|Option>;

const DeviceSelector = ({ onChange, selected, compact = false }: Props) => {
  const inventory = useAppSelector((state: RootState) => state.inventory);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant.builder_color || RIOT_BLUE, [currentTenant.builder_color]);

  const handleDeviceClick = useCallback((
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    deviceId: string,
  ) => {
      onChange(deviceId);
  }, [onChange]);


  const handleSelectDevice = useCallback((event: SyntheticEvent<Element, Event>, value: AutocompleteOption) => {
    if (typeof value === 'string') onChange(value);
    else onChange((value as Option).id);
  }, [onChange]);


  const sortedInventory = useMemo(() => {
    const sorted = [...inventory].sort((a, b) => a?.friendly_name?.localeCompare(b?.friendly_name || '') || 0);
    const match = sorted.find(d => d.device_id === selected);
    if (!match) return sorted;
    return [
      match,
      ...sorted.filter(d => d.device_id !== selected),
    ]

  }, [inventory, selected]);


  if (compact) {
    return (
      <Autocomplete
        freeSolo
        options={sortedInventory.map(d => ({
          label: d.friendly_name,
          id: d.device_id,
        }))}
        renderOption={(props, option) => <Typography {...props} key={option.id}>{option.label}</Typography>}
        renderInput={params => <TextField {...params} label="device" />}
        onChange={handleSelectDevice}
        disableClearable
        size="small"
      />
    );
  }

  return (
    <List
      sx={{
        width: '100%',
        overflow: 'auto',
        maxHeight: 330,
        '& ul': { padding: 0 },
      }}
    >
      {
        sortedInventory?.map((d, idx) => (
          <Stack
            direction="row"
            key={`inventory-option-${idx}`}
            justifyContent="space-between"
            alignItems="center"
            sx={{
              paddingRight: 0.5,
              color: d.device_id === selected ? themeColor : 'inherit'
            }}
          >
            <ListItemButton
              dense
              onClick={e => handleDeviceClick(e, d.device_id)}
            >
              <ListItemText>{d.friendly_name}</ListItemText>
            </ListItemButton>
            <Tooltip
              placement="left-start"
              arrow
              title={<DeviceSensors device={d} />}
            >
              <span>
                <InfoIcon htmlColor={themeColor} />
              </span>
            </Tooltip>
          </Stack>))
      }
    </List>
  );
}

export default DeviceSelector;