import DocumentList from "@features/all-documents/components/DocumentList";
import DocumentsProvider from "@features/all-documents/components/DocumentsProvider";
import Header from "@features/Common/Header";
import { FC } from "react";


/** Wrapper for /contacts */
const AllDocuments: FC = () => (
  <DocumentsProvider>
    <Header />
    <DocumentList />
  </DocumentsProvider>
);

export default AllDocuments;