import { ManifestDevice, ManifestDeviceBase, Sensor } from '@features/plan-manifest/types';
import * as yup from 'yup';

export interface DeviceCoordinatesBase {
  x_axis?: string;
  y_axis?: string;
  z_axis?: string;
}


export interface DeviceCoordinates {
  x_axis: string;
  y_axis: string;
  z_axis: string;
}

export type SensorMap = {
  [friendlyName: string]: string | undefined;
}

export interface PropertyManifestEntryBase {
  coordinates?: DeviceCoordinatesBase;
  device?: string | ManifestDeviceBase;
  gateway_device_id?: string;
  pairing_data?: string;
  provisioned?: string | number | null;
  commissioned?: string | number | null;
  provision_order?: number;
  property_area?: string;
  sensor_map?: SensorMap;
}

export interface PropertyManifestEntryCreate extends PropertyManifestEntryBase {
  provisioned?: string | number | null;
  commissioned?: string | number | null;
  coordinates: DeviceCoordinates;
  device: string;
  provision_order: number;
  property_area: string;
}

export interface PropertyManifestEntryUpdate extends PropertyManifestEntryBase {
  device?: string;
}

export interface PropertyManifestEntry extends PropertyManifestEntryBase {
  manifest_entry_id: string;
  provisioned?: string | number | null;
  commissioned?: string | number | null;
  device: string | ManifestDevice;
  is_deleted: boolean;
  provision_order: number;
  property_area: string;
  created: number;
  updated: number;
}

export interface PropertyManifestEntryDevice {
  manufacturer?: string;
  part_number?: string;
  model_number?: string;
  discontinued?: boolean;
  description?: string;
  friendly_name?: string;
  sensors: Sensor[];
  pairing_instructions?: string;
  unpairing_instructions?: string;
  reset_instructions?: string;
}


export interface PropertyManifest {
  name: string;
  description: string;
  property_id?: string;
  plan_manifest_id: string;
  manifest_entries: PropertyManifestEntry[];
}

export const PLAN_SCHEMA = yup.object({
  name: yup.string().label('Name'),
  description: yup.string().label('Description'),
});



export enum Action {
  create = 'add',
  delete = 'delete',
}