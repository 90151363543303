import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { PropertyManifestEntry } from "@features/home-manifest/types";
import CategoryReadingIcon from "@features/home-status/components/CategoryReadingIcon";
import { ReadingPair } from "@features/home-status/types";
import { ManifestDevice } from "@features/plan-manifest/types";
import { Category, Measure } from "@lib/labels";
import { formatLabel } from "@lib/utils";
import { Stack, Typography } from "@mui/material";
import { useMemo } from "react";


type Props = {
  evseEntry?: PropertyManifestEntry;
}

const CableStatus = (props: Props) => {
  const { evseEntry } = props;
  const property = useAppSelector((state: RootState) => state.property);

  const devices = useMemo(() => property.devices || [], [property.devices]);

  const cableSensor = useMemo(() => (evseEntry?.device as ManifestDevice)?.sensors
    ?.find(s => s.sensor_category === Category.cable && s.measure_name === Measure.status), [evseEntry?.device]);

  const cableDevice = useMemo(() => devices
    .find(r => r.data.entity_id === evseEntry?.sensor_map?.[cableSensor?.sensor_id ?? cableSensor?.friendly_name ?? '']), [cableSensor?.sensor_id, cableSensor?.friendly_name, devices, evseEntry?.sensor_map]);

  const cableReading = useMemo(() => ({
    device: cableDevice,
    sensor: cableSensor,
    deviceName: formatLabel((evseEntry?.device as ManifestDevice)?.friendly_name
      || (evseEntry?.device as ManifestDevice)?.manufacturer || ''),
    area: formatLabel(evseEntry?.property_area || ''),
    entryId: evseEntry?.manifest_entry_id,
  } as ReadingPair), [cableDevice, cableSensor, evseEntry?.device, evseEntry?.manifest_entry_id, evseEntry?.property_area]);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="h6">Cable</Typography>
      <CategoryReadingIcon
        category={Category.cable}
        measure={Measure.status}
        readings={cableReading}
      />
    </Stack>
  );
};

export default CableStatus;