import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { PropertyManifestEntry } from "@features/home-manifest/types";
import { useGetPropertyManifestQuery } from "@features/home/api";
import { Device } from "@features/home/types";
import { ManifestDevice } from "@features/plan-manifest/types";
import { useUpdatePropertyManifestEntryMutation } from "@features/provisioning/api";
import { SnackType } from "@lib/types";
import { formatDate, getErrorMessage } from "@lib/utils";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItemButton, ListItemText, Tooltip, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";

type Props = {
  entry: PropertyManifestEntry;
  isOpen: boolean;
  onClose: () => void;
}

const ManualLinkModal = ({
  isOpen,
  onClose,
  entry,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const selectedTenant = useAppSelector((state: RootState) => state.tenant);
  const property = useAppSelector((state: RootState) => state.property);

  const devices = useMemo(() => property.devices, [property.devices]);
  const allEntries = useMemo(() => property.manifestEntries || [], [property.manifestEntries]);
  const currentTenant = useMemo(() => selectedTenant.currentTenant || userTenant, [selectedTenant.currentTenant, userTenant]);

  const [selectedDevice, setSelectedDevice] = useState<string | null>(null);

  const {
    refetch: refetchManifest,
  } = useGetPropertyManifestQuery({
    tenantId: currentTenant?.tenant_id || '',
    propertyId: property?.property_id || '',
    includeEntries: true,
  }, {
    skip: !currentTenant.tenant_id || !property?.property_id,
  });

  const [
    updatePropertyManifestEntry,
  ] = useUpdatePropertyManifestEntryMutation();


  const unlinkedDeviceIds = useMemo(() => {
    const linkedDeviceIds: Set<string> = new Set(
      allEntries
        ?.map(e => e.gateway_device_id)
        .filter((e): e is string => !!e)
    );
    return new Set(
      devices
        ?.filter(d => !linkedDeviceIds.has(d.data.device_id))
        .map(d => d.data.device_id)
    );
  }, [allEntries, devices]);

  const unlinkedDevices: Record<string, Device[]> = useMemo(() => {
    return Array.from(new Set(devices
      ?.filter(d => d.data.device_id !== 'system' && unlinkedDeviceIds.has(d.data.device_id))
      .sort((a, b) => b.datetime - a.datetime)
    ).values()).reduce<Record<string, Device[]>>(
      (a, v) => ({
        ...a,
        [v.data.device_id]: [
          ...(a[v.data.device_id] || []),
          v,
        ],
      }),
      {},
    )
  }, [devices, unlinkedDeviceIds]);


  /* Function to handle manual link of device */
  const handleLink = useCallback(async (gatewayDeviceId: string) => {
    try {
      if (currentTenant.tenant_id && entry) {
        const updatedEntry = await updatePropertyManifestEntry({
          userTenantId: currentTenant.tenant_id,
          propertyId: property.property_id,
          entryId: entry.manifest_entry_id,
          body: {
            gateway_device_id: gatewayDeviceId,
          },
        });

        if ('error' in updatedEntry) {
          enqueueSnackbar("Couldn't link entry:", {
            key: "entry-error",
            content: (
              <ExpandableSnack
                id="entry-error"
                message={"Couldn't link entry:"}
                variant={SnackType.error}
                detail={getErrorMessage(updatedEntry.error)}
              />),
          });

        } else {
          enqueueSnackbar("Linked entry to device", {
            variant: "success",
          });

          refetchManifest();
        }
      }
    } finally {
      onClose();
    }
  }, [currentTenant.tenant_id, entry, updatePropertyManifestEntry, property.property_id, enqueueSnackbar, refetchManifest, onClose]);


  const handleSave = useCallback(() => {
    if (selectedDevice) {
      handleLink(selectedDevice);
    }
  }, [handleLink, selectedDevice]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>Link {(entry.device as ManifestDevice).friendly_name}</DialogTitle>
      <DialogContent>
        <Typography>
          The following devices have been paired to the home but are not linked. Choose one of them to link this entry to.
        </Typography>
        <List>
          {
            Object.entries(unlinkedDevices).map(([deviceId, events]) => (
              <ListItemButton
                key={deviceId}
                selected={selectedDevice === deviceId}
                onClick={() => setSelectedDevice(deviceId)}
              >
                <Tooltip
                  arrow
                  placement="top"
                  title={[events.map(e => e.data.entity_id).join('\n')]}
                >
                  <ListItemText
                    primary={`${deviceId}: ${events.length} sensors`}
                    secondary={`Last reading at ${events.length > 0 ? formatDate(events[0].datetime).format('hh:mma [on] MM/DD/YYYY') : ''}`}
                  />
                </Tooltip>
              </ListItemButton>
            ))
          }
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          Cancel
        </Button>
        <Button disabled={!selectedDevice} onClick={handleSave} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ManualLinkModal;
