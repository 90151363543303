import { PropertyManifestEntry } from "@features/home-manifest/types";
import { AttentionLevel } from "@features/home-notifications/types";
import { getAttentionLevelColor } from "@features/live-home/utils";
import { Box, Button, CircularProgress, Divider, Grid, Paper, Popover, Stack, Typography } from "@mui/material";
import { MouseEvent, useMemo, useState } from "react";
import { HealthCheckResult } from "../types";
import { getHealthCheckSentiment } from "../utils";
import ReadingsList from "./ReadingsList";

type Props = {
  icon: JSX.Element;
  title?: string;
  healthCheckResult: HealthCheckResult | null;
  entries: PropertyManifestEntry[];
  status?: string;
  substatus?: string;
  statuses?: {
    key: string;
    status: string;
    substatus: string;
  }[];
};

const HealthCheckSummary = ({ icon, title, healthCheckResult, entries, status, substatus, statuses }: Props) => {

  const color = useMemo(() => {
    if (healthCheckResult === null) return getAttentionLevelColor(AttentionLevel.notice);
    return getAttentionLevelColor(getHealthCheckSentiment(healthCheckResult));
  }, [healthCheckResult]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const seeMore = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const id = useMemo(() => open ? 'related-readings' : undefined, [open]);

  return (
    <Box sx={{ color, height: '100%', p: 0.5 }} component={Paper}>
      <Grid
        container
        columns={{ xs: 1, sm: 1, md: 5 }}
        sx={{ height: 'inherit' }}
      >
        <Grid sx={{
          p: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
          xs={1}
          item
        >
          {icon}
        </Grid>
        <Grid
          sx={{ height: 'inherit', width: 'inherit' }}
          xs={1}
          sm={1}
          md={4}
          item
        >
          {
            healthCheckResult === null
              ? <Box
                component="div"
                sx={{
                  p: 1,
                  height: 'inherit',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress />
              </Box>
              : <Stack
                sx={{ height: 'inherit', p: 0.5 }}
                justifyContent="space-between"
              >
                <Stack spacing={0}>
                  <Typography variant="h4">
                    {title || ''}
                  </Typography>
                  <Divider sx={{ borderColor: color }} />
                </Stack>
                {
                  statuses ? (
                    statuses.map(status => (
                      <Stack key={status.key} sx={{ height: 'inherit', width: 'inherit' }}>
                        <Typography variant="h6">{status.status}</Typography>
                        <Typography variant="subtitle1" sx={{ whiteSpace: 'pre-wrap' }}>{status.substatus}</Typography>
                      </Stack>
                    ))
                  ) : (
                    <Stack sx={{ height: 'inherit', width: 'inherit' }}>
                      <Typography variant="h6">{status || ' '}</Typography>
                      <Typography variant="subtitle1" sx={{ whiteSpace: 'pre-wrap' }}>{substatus || ' '}</Typography>
                    </Stack>
                  )
                }
                {
                  entries.length > 0 &&
                  <>
                    <Stack direction='row' justifyContent="center">
                      <Button variant="text" onClick={seeMore}>more info</Button>
                    </Stack>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                    >
                      <ReadingsList entries={entries} />
                    </Popover>
                  </>
                }
              </Stack>
          }
        </Grid>
      </Grid>
    </Box>
  );
}

export default HealthCheckSummary;
