import { Typography } from "@mui/material";
import Notifications from "@features/home-notifications/components/Notifications";

type Props = {

};

const NeighborhoodNotificationsView: React.FC<Props> = function NeighborhoodSummaryView(props: Props) {
  return <>
    <Typography variant="h4" sx={{ mb: 6 }}>Notifications</Typography>
    <Notifications />
  </>;
}

export default NeighborhoodNotificationsView;
