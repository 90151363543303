import AirFilterHealth from "@features/home-health/components/AirFilterHealth";
import ExternalPlumbingFreezeHealth from "@features/home-health/components/ExternalPlumbingFreezeHealth";
import LeakHealth from "@features/home-health/components/LeakHealth";
import SubsurfaceMoistureHealth from "@features/home-health/components/SubsurfaceMoistureHealth";
import { Box, Grid } from "@mui/material";


const HealthChecks = () => {
  return (
    <Box component="div">
      <Grid
        container
        columns={{ xs: 1, sm: 1, md: 2 }}
      >
        <Grid sx={{ p: 1 }} xs={1} item>
          <LeakHealth />
        </Grid>
        <Grid sx={{ p: 1 }} xs={1} item>
          <SubsurfaceMoistureHealth />
        </Grid>
        <Grid sx={{ p: 1 }} xs={1} item>
          <AirFilterHealth />
        </Grid>
        <Grid sx={{ p: 1 }} xs={1} item>
          <ExternalPlumbingFreezeHealth />
        </Grid>
      </Grid>
    </Box>
  );
}

export default HealthChecks;