import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { ButtonProps, CircularProgress, IconButton, Stack } from "@mui/material";
import { useMemo } from "react";
import { RIOT_BLUE } from "theme";

type ExtraButton = {
  key: string;
  icon: JSX.Element;
  loading?: boolean;
  onClick: () => void;
}

export type ListControlsProps = {
  onAdd?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  addLoading?: boolean;
  editLoading?: boolean;
  deleteLoading?: boolean;
  extraButtonProps?: Partial<ButtonProps>;
  extraButtons?: ExtraButton[];
}

const ListControls = ({
  onAdd,
  onEdit,
  onDelete,
  extraButtonProps = {},
  addLoading,
  editLoading,
  deleteLoading,
  extraButtons,
}: ListControlsProps) => {
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant.builder_color || RIOT_BLUE, [currentTenant.builder_color]);

  return (
    <Stack direction="row" justifyContent="flex-end" spacing={0.1}>
      {
        onDelete &&
        <IconButton size="large" {...extraButtonProps} onClick={() => onDelete()}>
          { deleteLoading ? <CircularProgress size="small" /> : <DeleteIcon htmlColor="black" /> }
        </IconButton>
      }
      {
        onEdit && <IconButton size="large" {...extraButtonProps} onClick={() => onEdit()}>
          { editLoading ? <CircularProgress size="small" /> : <EditIcon color="warning" /> }
        </IconButton>
      }
      {
        onAdd && <IconButton size="large" {...extraButtonProps} onClick={() => onAdd()}>
          { addLoading ? <CircularProgress size="small" /> : <AddIcon htmlColor={themeColor} /> }
        </IconButton>
      }
      {
        extraButtons?.map((eb, idx) => (
          <IconButton size="large" {...extraButtonProps} onClick={eb.onClick} key={`extra-buttons-${idx}`}>
            { eb.loading ? <CircularProgress size="small" /> : eb.icon }
          </IconButton>
        ))
      }
    </Stack>);
}


export default ListControls;