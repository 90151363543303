import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { useUtilgroupQuery } from "@features/all-utility-groups/api";
import { EnergyUsage, UtilGroup } from "@features/all-utility-groups/types";
import ExpandableSnack from '@features/Common/ExpandableSnack';
import { findUnitsLabel, Unit } from "@lib/labels";
import { ApiErrorResponse, SnackType } from '@lib/types';
import { getErrorMessage } from '@lib/utils';
import { LoadingButton } from "@mui/lab";
import { Button, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { useCreateShedRequestMutation, useRemoveShedRequestMutation } from "../api";
import EnergyReading from "./EnergyReading";



const SheddingRequest = () => {
  const { enqueueSnackbar } = useSnackbar();

  const utilGroup = useAppSelector((state: RootState) => state.utilityGroup.utilityGroup as UtilGroup);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const currentTarget = useMemo(() => (
    utilGroup.utility_targets?.find(ut => !!(ut as EnergyUsage).grid_consumption) as EnergyUsage
  )?.grid_consumption, [utilGroup.utility_targets]);

  const [editing, setEditing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [percentage, setPercentage] = useState<number>(5);

  const { refetch } = useUtilgroupQuery({
    userTenantId: currentTenant?.tenant_id || '',
    groupId: utilGroup?.group_id || '',
    builderId: currentTenant?.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id || !utilGroup?.group_id,
  });

  const [
    createShedRequest,
  ] = useCreateShedRequestMutation();

  const [
    removeShedRequest,
  ] = useRemoveShedRequestMutation();

  const handleSendRequest = useCallback(async () => {
    setLoading(true);
    const shedRequest = await createShedRequest({
      userTenantId: userTenant?.tenant_id || '',
      groupId: utilGroup?.group_id || '',
      builderId: currentTenant?.tenant_id || '',
      percentage,
    });

    const errorDetails = (shedRequest as ApiErrorResponse)?.error;


    if (errorDetails) {
      enqueueSnackbar("Couldn't submit a shed request:", {
        key: "shedrequest-error",
        content: (
          <ExpandableSnack
            id="shedrequest-error"
            message={"Couldn't submit a shed request:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("Shed request submitted", {
        variant: "success",
      });

      setLoading(false);
      refetch();
      setEditing(false);
    }

  }, [createShedRequest, currentTenant?.tenant_id, enqueueSnackbar, percentage, refetch, userTenant?.tenant_id, utilGroup?.group_id]);

  const handleClearRequest = useCallback(async () => {
    setLoading(true);
    const shedRequest = await removeShedRequest({
      userTenantId: userTenant?.tenant_id || '',
      groupId: utilGroup?.group_id || '',
      builderId: currentTenant?.tenant_id || '',
    });

    const errorDetails = (shedRequest as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't clear shed request:", {
        key: "clearshedrequest-error",
        content: (
          <ExpandableSnack
            id="clearshedrequest-error"
            message={"Couldn't clear shed request:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("Shed request cleared", {
        variant: "success",
      });

      setLoading(false);
      refetch();
    }
  }, [currentTenant?.tenant_id, enqueueSnackbar, refetch, removeShedRequest, userTenant?.tenant_id, utilGroup?.group_id]);


  const handleEditMode = useCallback(() => {
    setEditing(!editing);
  }, [editing]);

  const handleChangePercentage = useCallback((e: any) => {
    setPercentage(e.target.value);
  }, []);


  return (
    <Stack sx={{ mb: 1}} spacing={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">Shedding Request Target</Typography>
        <EnergyReading variant="body1" energy={currentTarget!} />
      </Stack>
      <Stack direction="row" justifyContent="flex-end" spacing={1}>
        {
          editing &&
          <>
            <TextField
              label="Request to lower consumption by"
              InputProps={{
                startAdornment: <InputAdornment position="start">{findUnitsLabel(Unit.percent)}</InputAdornment>,
              }}
              type="number"
              defaultValue={5}
              size="small"
              onChange={handleChangePercentage}
              required
            />
            <LoadingButton
              onClick={handleSendRequest}
              variant="contained"
              color="warning"
              loading={loading}
              disabled={!percentage}
            >Set</LoadingButton>
          </>
        }
        <Button
          size="small"
          variant={editing ? 'outlined' : 'contained'}
          onClick={handleEditMode}
          color={editing ? 'inherit' : 'success'}
        >
          {editing ? 'Cancel' : 'New'}
        </Button>
        {
          !!currentTarget && !editing &&
          <LoadingButton
            onClick={handleClearRequest}
            variant="contained"
            color="warning"
            loading={loading}
          >Clear</LoadingButton>
        }
      </Stack>
    </Stack>

  );
}

export default SheddingRequest;