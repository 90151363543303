import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import LegalAddress from '@features/Address/LegalAddress';
import StreetAddress from '@features/Address/StreetAddress';
import TownAddress from '@features/Address/TownAddress';
import { useRegionsQuery } from '@features/userTenant/api';
import { TenantType } from '@features/userTenant/types';
import {
  Box, Checkbox, FormControlLabel, FormGroup,
  MenuItem,
  Typography
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { useGetPropertyManifestQuery } from '../api';
import { Property, Region } from '../types';
import AppTextField from './AppTextField';

type Props = {
  readOnly?: boolean;
  width?: string;
}

const HomeFields = ({ readOnly, width }: Props) => {
  const f = useFormikContext();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const property = useAppSelector((state: RootState) => state.property);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const propertyId = useMemo(() => property?.property_id || (f.values as Property)?.property_id, [f.values, property?.property_id]);

  const {
    currentData: homeManifest,
  } = useGetPropertyManifestQuery({
    tenantId: currentTenant?.tenant_id || '',
    propertyId: propertyId,
    includeEntries: false,
  }, {
    skip: !currentTenant.tenant_id || !propertyId
  });

  useEffect(() => {
    if (homeManifest?.name) {
      f.setFieldValue('manifest.name', homeManifest?.name);
    } else {
      f.setFieldValue('manifest.name', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeManifest?.name]);


  const {
    currentData: regions,
    isFetching: isFetchingRegions,
  } = useRegionsQuery({
    userTenantId: currentTenant?.tenant_id || '',
  }, {
    skip: !currentTenant.tenant_id,
  });


  /* First row of Property details:
   * Name | Latitude | Longitude | Region | Country
   */
  const renderedFirstRow = useMemo(() => (
    <FormGroup row sx={{
      width: '100%',
      margin: 0,
      justifyContent: 'space-between',
    }}>
      <AppTextField
        name="name"
        label="Nickname"
        type="text"
        required
        sx={{
          width: '19%',
        }}
        margin="dense"
        size="small"
        disabled={readOnly}
      />
      <AppTextField
        name="latitude"
        label="Latitude"
        type="text"
        sx={{
          width: '19%',
        }}
        margin="dense"
        size="small"
        disabled={readOnly}
      />
      <AppTextField
        name="longitude"
        label="Longitude"
        type="text"
        sx={{
          width: '19%',
        }}
        margin="dense"
        size="small"
        disabled={readOnly}
      />
      {
        regions && !isFetchingRegions &&
        <AppTextField
          name="region_id"
          label="Region"
          select
          margin="dense"
          size="small"
          required
          sx={{
            width: '19%',
          }}
          onChange={(e: any) => {
            // Update the country based on the selected region
            const region_id = e.target.value;
            const region = regions && regions.find((r: Region) => r.region_id === region_id);
            if (region) {
              f.setFieldValue('physical_address.country', region.country);
            }
            // Let Formik do its thing
            f.handleChange(e);
          }}
          disabled={readOnly}
        >
          {
            regions && regions.map((r: Region) => (
              <MenuItem key={r.region_id} value={r.region_id}>
                {r.country} » {r.name} » {r.subregion}
              </MenuItem>))
          }
        </AppTextField>
      }
      <AppTextField
        name="physical_address.country"
        label="Country"
        type="text"
        margin="dense"
        size="small"
        sx={{
          width: '19%',
        }}
        disabled={readOnly}
      />
    </FormGroup>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [isFetchingRegions, readOnly, regions]);

  const debugEnabled = useMemo(
    () => Boolean(Object(f.values)?.['debug']?.['enabled']),
    [f],
  );

  /* Default to 30 minutes sync interval */
  useEffect(
    () => {
      if (debugEnabled && !Object(f.values)?.['debug']?.['sync_interval']) {
        f.setFieldValue('debug', {
          enabled: true,
          sync_interval: 30,
        } satisfies Property['debug']);
      }
    },
    [debugEnabled, f],
  );

  /**
   * MAIN RENDER
   * */
  return (
    <Box
      component="div"
      sx={{
        minWidth: 300,
        height: '100%',
        width: width || 'inherit',
        p: 1
      }}
    >
      <Typography variant='h6'>Home Details</Typography>
      {renderedFirstRow}
      <FormGroup row sx={{
        width: '100%',
      }}>
        <StreetAddress readOnly={readOnly} />
        <LegalAddress readOnly={readOnly} />
      </FormGroup>
      <TownAddress isCounty isRequired readOnly={readOnly} />
      {
        userTenant.tenant_type === TenantType.System
        ? <FormGroup row sx={{ width: '100%' }}>
            <FormControlLabel
              label="Debug Logging Enabled"
              control={
                <Checkbox name="debug.enabled" checked={debugEnabled} onChange={f.handleChange}></Checkbox>
              }
            />
            <AppTextField
              name="debug.sync_interval"
              label="Log sync interval (minutes)"
              type="number"
              margin="dense"
              size="small"
              disabled={readOnly || !debugEnabled}
            />
        </FormGroup>
        : null
      }
    </Box>
  );
}

export default HomeFields;
