import { Paper, Stack, Typography, Table, TableRow, TableCell, TableHead, TableBody } from "@mui/material";
import { GasBill } from "../types";
import { useMemo } from "react";
import { AnalyticsSet } from "@lib/types";
import { formatDate } from "@lib/utils";
import moment from "moment";


interface UsageSummary {
  [timePeriod: string]: {
    amount: string;
    isEstimate?: boolean;
  }[];
}

export interface UsageBillingSummaryProps {
  priorPeriodBill?: GasBill;
  usageData?: AnalyticsSet[];
  billingData?: GasBill[];
}

const UsageBillingSummary: React.FC<UsageBillingSummaryProps> = function UsageBillingSummary(
  props: UsageBillingSummaryProps,
) {
  const { priorPeriodBill, usageData, billingData } = props;
  
  /** Heater usage/billing summary for predefined periods */
  const usageSummary: UsageSummary | null = useMemo(() => {
    /** Returns amount of hours heater was on during period between start and end */
    const calculateUsageForPeriod = (start: Date, end: Date): number => {
      return usageData?.reduce((partialSum, usageData) => {
        return partialSum + usageData.Rows.reduce((rowSum, row) => {
          const [timeBin, usage] = row.slice(-2);

          const timeBinDate = formatDate(timeBin).toDate();

          if (timeBinDate.getTime() >= start.getTime()
            && timeBinDate.getTime() <= end.getTime()
          ) {
            return rowSum + Number(usage) * 24;
          }
          return rowSum;
        }, 0);
      }, 0) ?? NaN;
    };

    const yearStart: Date = moment().utc().subtract(1, 'year').endOf('year').toDate();
    const lastPeriodStart: Date = priorPeriodBill
      ? formatDate(priorPeriodBill.billing_cycle.start)
        .toDate()
      : moment().utc().subtract(1, 'month').startOf('month').toDate();
    const lastPeriodEnd: Date = priorPeriodBill
      ? formatDate(priorPeriodBill.billing_cycle.end)
        .toDate()
      : moment(lastPeriodStart).add(1, 'month').toDate();
    const thisPeriodStart: Date = moment(lastPeriodEnd).add(1, 'day').toDate();
    const thisWeekStart: Date = moment().utc().startOf('week').toDate();

    const ytdUsage = billingData?.reduce((partialUsage, bill) => {
      const billStart = formatDate(bill.billing_cycle.start)
        .toDate();
      const billEnd = formatDate(bill.billing_cycle.end)
        .toDate();
      return (billStart.getTime() >= yearStart.getTime())
        ? (partialUsage + calculateUsageForPeriod(
          billStart,
          billEnd,
        )) : partialUsage;
    }, 0) ?? NaN;

    const ytdCost = billingData?.reduce((partialCost, bill) => {
      const billStart = formatDate(bill.billing_cycle.start)
        .toDate();
      return (billStart.getTime() >= yearStart.getTime())
        ? (partialCost + (bill.therms_used * bill.therm_cost))
        : partialCost;
    }, 0) ?? NaN;

    const ytdTherms = billingData?.reduce((partialCost, bill) => {
      const billStart = formatDate(bill.billing_cycle.start)
        .toDate();
      return (billStart.getTime() >= yearStart.getTime())
        ? (partialCost + bill.therms_used)
        : partialCost;
    }, 0) ?? NaN;

    const lastCycleUsage = calculateUsageForPeriod(
      lastPeriodStart,
      lastPeriodEnd,
    );

    const thisCycleUsage = calculateUsageForPeriod(
      thisPeriodStart,
      new Date(),
    );

    const thisWeekUsage = calculateUsageForPeriod(
      thisWeekStart,
      new Date(),
    );

    /* Create usage summary */
    return {
      'Year to Date': [{
        amount: Number.isNaN(ytdCost) ? '-' : `$${ytdCost.toFixed(2)}`,
      }, {
        amount: Number.isNaN(ytdUsage) ? '-' : `${ytdUsage.toFixed(1)} hours`,
      }, {
        amount: Number.isNaN(ytdTherms) ? '-' : `${ytdTherms.toFixed(1)} therms`,
      }],
      'Last month': [
        {
          amount: priorPeriodBill ? `$${
            (priorPeriodBill.therms_used * priorPeriodBill.therm_cost).toFixed(2)
          }` : '-',
        }, {
          amount: Number.isNaN(lastCycleUsage) ? '-' : `${lastCycleUsage.toFixed(1)} hours`,
        }, {
          amount: priorPeriodBill ? `${
            (priorPeriodBill.therms_used).toFixed(1)
          } therms` : '-',
        },
      ],
      'This month': [
        {
          amount: (priorPeriodBill
            && lastCycleUsage
            && !Number.isNaN(thisCycleUsage)
          ) ? `$${
              (
                (priorPeriodBill.therms_used / lastCycleUsage)
                * thisCycleUsage
                * priorPeriodBill.therm_cost
              ).toFixed(2)
            }` : '-',
          isEstimate: true,
        }, {
          amount: Number.isNaN(thisCycleUsage) ? '-' : `${thisCycleUsage.toFixed(1)} hours`,
        }, {
          amount: (priorPeriodBill
            && lastCycleUsage
            && !Number.isNaN(thisCycleUsage)
          ) ? `${
              ((priorPeriodBill.therms_used / lastCycleUsage) * thisCycleUsage).toFixed(1)
            } therms` : '-',
          isEstimate: true,
        },
      ],
      'This week': [
        {
          amount: (priorPeriodBill
            && lastCycleUsage
            && !Number.isNaN(thisWeekUsage)
          ) ? `$${
              (
                (priorPeriodBill.therms_used / lastCycleUsage)
                * thisWeekUsage
                * priorPeriodBill.therm_cost
              ).toFixed(2)
            }` : '-',
          isEstimate: true,
        }, {
          amount: Number.isNaN(thisWeekUsage) ? '-' : `${thisWeekUsage.toFixed(1)} hours`,
        }, {
          amount: (priorPeriodBill
            && lastCycleUsage
            && !Number.isNaN(thisWeekUsage)
          ) ? `${
              ((priorPeriodBill.therms_used / lastCycleUsage) * thisWeekUsage).toFixed(1)
            } therms` : '-',
          isEstimate: true,
        },
      ],
    };
  }, [billingData, priorPeriodBill, usageData]);

  return <Paper component={Stack} direction="column" flex={1}>
  <Typography variant="h5" sx={{ textAlign: 'center', pt: 1 }}>
    Billing & Usage Summary
  </Typography>
  <Stack direction="row" flex={1}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Period</TableCell>
          <TableCell>Total Cost</TableCell>
          <TableCell>Hours of Usage</TableCell>
          <TableCell>Therms Used</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(usageSummary || {}).map(([period, usage]) => 
            <TableRow key={period}>
              <TableCell sx={{ fontWeight: 'bold' }}>{period}</TableCell>
              {usage.map(u => <TableCell>{u.amount} {u.isEstimate ? '*' : ''}</TableCell>)}
            </TableRow>
          )}
      </TableBody>
    </Table>
  </Stack>
</Paper>
}

export default UsageBillingSummary;
