import { Autocomplete, Checkbox, FormControlLabel, Select, Stack, TextField, ToggleButton, Typography } from "@mui/material";
import { DeviceStateType, DeviceStateValue, SceneTemplateDeviceCriteria, SceneTemplateDeviceStateCondition } from "../types";
import { useCallback, useEffect, useMemo } from "react";
import { ALL_CATEGORIES, Category, Unit, findCategoryLabel, findMeasureLabel, formatReadingDetails, getMeasuresByCategory } from "@lib/labels";

interface SceneTemplateDeviceCriteriaSelectorProps {
  stateType: DeviceStateType;
  stateValue: DeviceStateValue;

  deviceCriteria: SceneTemplateDeviceCriteria;
  onChange: (stateType: DeviceStateType, stateValue: DeviceStateValue) => void;
}

export default function SceneTemplateDeviceCriteriaSelector(props: SceneTemplateDeviceCriteriaSelectorProps) {
  const { stateType, stateValue, deviceCriteria, onChange} = props;

  const handleToggle = useCallback(() => {
    onChange(stateType, !stateValue);
  }, [onChange, stateType, stateValue]);

  return <Stack direction="row" sx={{ flex: '1 1 auto', alignItems: 'center' }} spacing={1}>
    <Typography sx={{ justifyContent: 'center', alignItems: 'center' }}>
      becomes
    </Typography>
    <ToggleButton value={stateValue} onChange={handleToggle} sx={{ flex: '1 1 auto'}}>
      {(deviceCriteria.category && deviceCriteria.measure)
        ? formatReadingDetails(deviceCriteria.category, deviceCriteria.measure, Unit.binary, stateValue).value
        : stateValue.toString()
      }
    </ToggleButton>
  </Stack>
}
