import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { AttentionLevel } from "@features/home-notifications/types";
import { ManifestDevice } from "@features/plan-manifest/types";
import { Category, Measure } from "@lib/labels";
import { SnackType } from "@lib/types";
import { formatDate, getErrorMessage } from "@lib/utils";
import WaterDamageIcon from '@mui/icons-material/WaterDamage';
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useHealthCheckHomeMutation } from "../api";
import { HealthCheckResult, HealthCheckType } from "../types";
import { getHealthCheckSentiment } from "../utils";
import HealthCheckSummary from "./HealthCheckSummary";

const LeakHealth = () => {
  const { enqueueSnackbar } = useSnackbar();
  const property = useAppSelector((state: RootState) => state.property);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const manifestEntries = useMemo(() => property.manifestEntries, [property.manifestEntries]);

  const [healthCheckResult, setHealthCheckResult] = useState<HealthCheckResult | null>(null);

  const [
    healthCheckHome,
  ] = useHealthCheckHomeMutation();

  useEffect(() => {
    if (currentTenant.tenant_id && property.property_id) {
      healthCheckHome({
        healthCheckType: HealthCheckType.LEAK,
        propertyId: property.property_id,
        userTenantId: currentTenant.tenant_id,
      }).then((res) => {
        if ('error' in res) {
          enqueueSnackbar("Failed to perform health check:", {
            key: "leak-hc-error",
            content: (
              <ExpandableSnack
                id="leak-hc-error"
                message={"Couldn't perform health check:"}
                variant={SnackType.error}
                detail={getErrorMessage(res.error)}
              />),
          });
        } else {
          setHealthCheckResult(res.data);
        }
      });
    }
  }, [healthCheckHome, property.property_id, currentTenant.tenant_id, enqueueSnackbar]);

  const involvedEntries = useMemo(() => manifestEntries
    ?.filter(ee => (ee.device as ManifestDevice).sensors.some(ss => (
      ss.sensor_category === Category.water && ss.measure_name === Measure.usage_rate))), [manifestEntries]);

  const status = useMemo(() => {
    switch(getHealthCheckSentiment(healthCheckResult)) {
      default:
      case AttentionLevel.warning:
        return 'Status is unknown.';
      case AttentionLevel.urgent:
        return 'Water leak detected.';
      case AttentionLevel.info:
        return 'Waiting on healthcheck to complete.';
      case undefined:
        return 'No water leaks detected.';
    }
  }, [healthCheckResult]);

  const substatus = useMemo(() => {
    const lastReset = 'Last time water usage reached zero was';
    if (healthCheckResult === null) return `${lastReset} unknown.`;

    if (healthCheckResult?.leak && healthCheckResult.leak.length > 0) {
      if (healthCheckResult.leak.length === 1) {
        if (healthCheckResult.leak[0].last_reset_date) {
          return `${lastReset} ${formatDate(healthCheckResult.leak[0].last_reset_date).fromNow()}.`;
        }
      } else {
        /* Multiple leaks */
        return `${lastReset} ${
          healthCheckResult.leak.reduce<string[]>(
            (resetTimes, leak) => {
              if (!leak.last_reset_date || !leak.property_area) return resetTimes;
              return [
                ...resetTimes,
                `${formatDate(leak.last_reset_date).fromNow()} (${leak.property_area})`
              ];
            },
            []
          ).join(', ')
        }.`;
      }
    }

    return `${lastReset} unknown.`;
  }, [healthCheckResult]);

  return (
    <HealthCheckSummary
      icon={<WaterDamageIcon sx={{ fontSize: '6em' }} />}
      healthCheckResult={healthCheckResult}
      title="Water Leaks"
      entries={involvedEntries || []}
      status={status}
      substatus={substatus}
    />
  );
}

export default LeakHealth;
