import { Checkbox, FormControlLabel, FormGroup, Stack, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { ChangeEvent } from "react";
import { CommissioningForm } from "../types";

const CommissionGateway = () => {
  const f = useFormikContext();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    f.setFieldValue(name, event.target.checked);
  }

  const { gatewayLocated, lanConnected, powerConnected, networkConnected } = f.values as CommissioningForm;

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={gatewayLocated}
            onChange={handleChange}
            name="gatewayLocated"
          />
        }
        label={
          <Typography variant="h6">
            1. Locate the Router, the Switch, the Access Point and the Gateway within the home.
          </Typography>
        }
      />
      <FormControlLabel
        sx={{
          alignItems: 'start',
        }}
        control={
          <Checkbox
            checked={lanConnected}
            onChange={handleChange}
            name="lanConnected"
            disabled={!gatewayLocated}
          />
        }
        label={
          <Stack>
            <Typography variant="h6">
              2. Ensure the Router, the Access Point and the Gateway are connected to the Switch via Ethernet cables.
            </Typography>
          </Stack>
        }
      />
      <FormControlLabel
        sx={{
          alignItems: 'start',
        }}
        control={
          <Checkbox
            checked={powerConnected}
            onChange={handleChange}
            name="powerConnected"
            disabled={!gatewayLocated || !lanConnected}
          />
        }
        label={
          <Stack>
            <Typography variant="h6">
              3. Ensure the Switch is connected to the power outlet.
            </Typography>
          </Stack>
        }
      />
      <FormControlLabel
        sx={{
          alignItems: 'start',
        }}
        control={
          <Checkbox
            checked={networkConnected}
            onChange={handleChange}
            name="networkConnected"
            disabled={!gatewayLocated || !lanConnected || !powerConnected}
          />
        }
        label={
          <Stack>
            <Typography variant="h6">
              4. Ensure the Router is connected to the home's ISP Modem via Ethernet cable.
            </Typography>
          </Stack>
        }
      />
    </FormGroup>
  );
}

export default CommissionGateway;