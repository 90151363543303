import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { Aggregate, BinSize } from "@features/settings/types";
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useCallback, useMemo } from 'react';
import { RIOT_BLUE } from 'theme';

type Props = {
  initial: BinSize;
  aggregate: Aggregate;
  onChange: (b: BinSize) => void;
}

const ChartBins = ({ initial, aggregate, onChange }: Props) => {
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant?.builder_color || RIOT_BLUE, [currentTenant?.builder_color]);

  const handleChange = useCallback((e: SelectChangeEvent<BinSize|string>) => {
    onChange(e.target.value as BinSize)
  }, [onChange]);

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <Select
        disabled={aggregate === Aggregate.none}
        size="small"
        value={aggregate === Aggregate.none ? 'N/A' : initial}
        onChange={handleChange}
        sx={{
          '&:focus': {
            border: `1px solid ${themeColor}`,
          }
        }}
      >
        {aggregate === Aggregate.none && <MenuItem value="N/A">No grouping</MenuItem>}
        <MenuItem value={BinSize.hour}>Hour</MenuItem>
        <MenuItem value={BinSize.day}>Day</MenuItem>
        <MenuItem value={BinSize.week}>Week</MenuItem>
        <MenuItem value={BinSize.month}>Month</MenuItem>
      </Select>
    </FormControl>
  );
}

export default ChartBins;