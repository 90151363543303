import { useAppDispatch, useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { pollingInterval } from "@features/tenant-selector/types";
import { FC, ReactElement, useEffect, useMemo } from "react";
import { useTemplateInventoryQuery } from "../api";
import { setSceneTemplates } from "../slice";


const SceneTemplatesProvider: FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const {
    currentData: sceneTemplates,
  } = useTemplateInventoryQuery({
    userTenantId: currentTenant?.tenant_id || '',
  }, {
    skip: !userTenant?.tenant_id,
    // pollingInterval,
  });

  useEffect(() => {
    dispatch(setSceneTemplates(sceneTemplates ?? []));
  }, [dispatch, sceneTemplates]);

  return children as ReactElement;
}

export default SceneTemplatesProvider;