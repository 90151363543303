import { User } from '@features/all-users/types';
import { riotApi } from "@features/aws-user/api";
import { Property } from '@features/home/types';
import { HTTPMethodType } from '@lib/types';
import { U } from '@lib/utils';


export const homesaleApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      /** get all homes pending sale completion */
      pendingHomes: builder.query<Property[], { userTenantId: string }>({
        query: ({ userTenantId }) => ({
          url: `/tenant/${U(userTenantId)}/properties/own`,
        }),
      }),
      /** complete sale of home with user access and ownership assignment */
      sellHome: builder.mutation<Property, {
        userTenantId: string,
        propertyId: string,
        tenantId: string, // homeowner
        user: Partial<User>,
        builderAccess: boolean,
      }>({
        query: ({ userTenantId, propertyId, tenantId, user, builderAccess }) => ({
          url: `/tenant/${U(userTenantId)}/properties/${U(propertyId)}/transfer`,
          method: HTTPMethodType.POST,
          body: user,
          params: {
            tenant_id: tenantId,
            builder_access: builderAccess,
          },
        })
      })
    };
  },
});

export const {
  usePendingHomesQuery,
  useSellHomeMutation,
} = homesaleApi;