import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { useChildTenantsQuery } from "@features/all-homeowners/api";
import { Tenant, TenantType } from "@features/userTenant/types";
import { Autocomplete, TextField } from "@mui/material";
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTenantsQuery } from "../api";

type Props = {
  onChange: (v: Tenant | null) => void;
}

const TenantAutocomplete = ({ onChange }: Props) => {
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const myTenants = useMemo(() => userTenant.myTenants || [], [userTenant.myTenants]);

  const {
    currentData: allTenants,
    isFetching: fetchingAllTenants,
  } = useTenantsQuery({
    userTenantId: userTenant.tenant_id!,
  }, {
    skip: !userTenant?.tenant_id || currentTenant.tenant_type !== TenantType.System,
  });

  const {
    currentData: childTenants,
    isFetching: fetchingChildTenants,
  } = useChildTenantsQuery({
    userTenantId: userTenant.tenant_id!,
  }, {
    skip: !userTenant?.tenant_id || currentTenant.tenant_type === TenantType.System,
  });

  const tenantsList: Tenant[] | undefined = useMemo(() => (
    currentTenant.tenant_type === TenantType.System
      ? allTenants || []
      : [...myTenants, ...(childTenants || [])]
  ), [allTenants, childTenants, currentTenant.tenant_type, myTenants]);

  const defaultTenantSelection = useMemo(() => (tenantsList)?.find(t => t.tenant_id === currentTenant.tenant_id), [tenantsList, currentTenant.tenant_id],);

  const [selectedTenant, setSelectedTenant] = useState<Tenant|null>(null);

  useEffect(() => {
    setSelectedTenant(defaultTenantSelection || null);
  }, [defaultTenantSelection]);

  useEffect(() => {
    onChange(selectedTenant);
  }, [onChange, selectedTenant]);

  const handleTenantChange = useCallback((e: SyntheticEvent<Element, Event>, v: Tenant | null) => {
    setSelectedTenant(v);
  }, []);

  return (
    <Autocomplete
      fullWidth
      loading={fetchingAllTenants || fetchingChildTenants}
      options={tenantsList}
      getOptionLabel={(t) => t.name}
      value={selectedTenant}
      onChange={handleTenantChange}
      isOptionEqualToValue={(a, b) => a.tenant_id === b.tenant_id}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Tenant"
          placeholder="Tenant"
        />
      )}
    />);
}

export default TenantAutocomplete;
