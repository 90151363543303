import HomesProvider from "@features/all-homes/components/HomesProvider";
import { Grid } from "@mui/material";
import NeighborhoodDetails from "./NeighborhoodDetails";
import NeighborhoodHomes from "./NeighborhoodHomes";
import { useAppSelector } from "@app/hooks";
import { NeighborhoodBase } from "../types";
import { RootState } from "@app/store";
import NeighborhoodNotifSummary from "./NeighborhoodNotifSummary";

type Props = {
  health?: NeighborhoodBase['health_status'];
  loadingHealth?: boolean;
};

const NeighborhoodSummary: React.FC<Props> = (props: Props) => {
  const neighborhood = useAppSelector((state: RootState) => state.neighborhood);

  return (
    <>
      <Grid container columns={{ xs: 1, md: 1 }} spacing={1} alignItems="start">
        {
          !!neighborhood.neighborhood?.neighborhood_id && <NeighborhoodNotifSummary />
        }
        <Grid item xs={1} container columns={{ xs: 1, md: 1 }} sx={{ p: '1em' }} spacing={2}>
          <Grid item xs={2}>
            <NeighborhoodDetails  />
          </Grid>
          <Grid item xs={2}>
            <HomesProvider>
              <NeighborhoodHomes />
            </HomesProvider>
          </Grid>
        </Grid>
      </Grid>

    </>
  );
}

export default NeighborhoodSummary;