import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Typography } from "@mui/material";

const AllConfirmed = () => (
  <Box component="div" textAlign="center" p={5}>
    <Typography color="green">
      <CheckCircleIcon fontSize="large" />
    </Typography>
    <Typography component="p">
      All devices are confirmed.
    </Typography>
  </Box>
);

export default AllConfirmed;