import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { useCleanupMutation, useGetDevicesQuery, useGetPropertyManifestQuery, useUpdatePropertyMutation } from '@features/home/api';
import { setProperty } from "@features/home/slice";
import { Property } from '@features/home/types';
import { ManifestDevice } from "@features/plan-manifest/types";
import { useBeginNetworkHealMutation } from "@features/provisioning/api";
import { TenantState } from "@features/userTenant/types";
import { Category } from "@lib/labels";
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage } from '@lib/utils';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Stack, Tab, Typography } from "@mui/material";
import { Formik, FormikValues } from "formik";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { CommissioningForm } from "../types";
import CommissionDevices from "./CommissionDevices";
import CommissionGateway from "./CommissionGateway";
import CommissionKiosk from "./CommissionKiosk";
import Review from "./Review";


const Commissioning = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const initialFormValues = {
    gatewayLocated: false,
    lanConnected: false,
    powerConnected: false,
    networkConnected: false,
    kioskLocated: false,
    kioskPowered: false,
    kioskOn: false,
  } as CommissioningForm;

  const userTenant: TenantState = useAppSelector((state: RootState) => state.userTenant);
  const property: Property = useAppSelector((state: RootState) => state.property);
  const selectedTenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => selectedTenant.currentTenant || userTenant, [selectedTenant.currentTenant, userTenant]);

  const [activeStep, setActiveStep] = useState<string>("Gateway");

  const [
    updateProperty,
  ] = useUpdatePropertyMutation();

  const [
    cleanupProperty,
  ] = useCleanupMutation();

  const [
    beginNetworkHeal,
  ] = useBeginNetworkHealMutation();

  const {
    currentData: devices,
    refetch: refetchDevices,
  } = useGetDevicesQuery({
    tenantId: currentTenant?.tenant_id || '',
    propertyId: property?.property_id || '',
  }, {
    skip: !currentTenant.tenant_id || !property?.property_id,
  });

  const {
    currentData: manifest,
    refetch: refetchManifest,
  } = useGetPropertyManifestQuery({
    tenantId: currentTenant?.tenant_id || '',
    propertyId: property?.property_id || '',
    includeEntries: true,
  }, {
    skip: !currentTenant.tenant_id || !property?.property_id,
  });

  useEffect(() => {
    refetchDevices();
    refetchManifest();
  }, [property.property_id, refetchDevices, refetchManifest]);


  /** Begin z-wave network heal */
  const healNetwork = useCallback(async () => {
    if (!userTenant.tenant_id) return;

    const healResult = await beginNetworkHeal({
      userTenantId: userTenant.tenant_id,
      propertyId: property.property_id,
    });

    const errorDetails = (healResult as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't start network healing:", {
        key: "healing-error",
        content: (
          <ExpandableSnack
            id="healing-error"
            message={"Couldn't start network healing:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    }
  }, [beginNetworkHeal, enqueueSnackbar, property.property_id, userTenant.tenant_id]);

  const allCommissioned = useMemo(() => manifest?.manifest_entries
    ?.every(e => e.commissioned || (e.device as ManifestDevice)?.sensors
      ?.some(s => s.sensor_category === Category.system)), [manifest?.manifest_entries]);

  const confirmCommissioning = useCallback(async () => {

    if (!allCommissioned) {
      enqueueSnackbar('Not all devices are confirmed.', {
        variant: 'error',
      });
      return false;
    }

    /* Trigger z-wave network heal */
    await healNetwork();

    /** Clean up device entities */
    const cleanupPropertyResult = await cleanupProperty({
      userTenantId: userTenant?.tenant_id || '',
      propertyId: property?.property_id || '',
    });

    if ('error' in cleanupPropertyResult) throw cleanupPropertyResult.error;

    /** update property commissioning status  */
    const updatePropertyResult = await updateProperty({
      userTenantId: userTenant?.tenant_id || '',
      propertyId: property?.property_id || '',
      body: { commissioned: Math.round(Date.now() / 1000).toString() },
    });

    if ('error' in updatePropertyResult) throw updatePropertyResult.error;

    // TODO: snackbar
    return true;
  }, [allCommissioned, cleanupProperty, enqueueSnackbar, healNetwork, property?.property_id, updateProperty, userTenant?.tenant_id]);

  /** Complete commissioning */
  const onSubmit = useCallback(async (
    values: FormikValues,
  ) => {
    try {
      const isCommissioned = await confirmCommissioning();
      if (isCommissioned) {
        navigate(`/property/${property.property_id}/current`);
      }
    } catch (e: any) {
      enqueueSnackbar("Couldn't commission home:", {
        key: "contact-error",
        content: (
          <ExpandableSnack
            id="contact-error"
            message={"Couldn't commission home:"}
            variant={SnackType.error}
            detail={getErrorMessage(e)}
          />),
      });
    }
  }, [confirmCommissioning, enqueueSnackbar, navigate, property.property_id]);


  /** Navigate to all-homes view on Cancel */
  const onCancel = useCallback(() => {
    dispatch(setProperty(null));

    navigate('/homes');
  }, [dispatch, navigate]);

  useEffect(() => {
    if (activeStep === 'Cancel') onCancel();
  }, [activeStep, onCancel]);

  const submitNetwork = useCallback(() => {
    setActiveStep("Kiosk");
  }, []);

  const submitKiosk = useCallback(() => {
    setActiveStep("Devices");
  }, []);

  const submitDevices = useCallback(() => {
    setActiveStep("Review");
  }, []);

  const isGatewaySetup = (values: Partial<CommissioningForm>) => {
    const { gatewayLocated, lanConnected, powerConnected, networkConnected } = values;
    return gatewayLocated && lanConnected && powerConnected && networkConnected;
  }

  const isKioskSetup = (values: Partial<CommissioningForm>) => {
    const { kioskLocated, kioskPowered, kioskOn } = values;
    return kioskLocated && kioskPowered && kioskOn;
  }

  return (
    <Box component="div" sx={{ background: "white", height: '53em' }}>
      <Formik
        validateOnChange
        initialValues={initialFormValues}
        onSubmit={onSubmit}
      >
        {f => (
          <form noValidate onSubmit={f.handleSubmit}>
            <TabContext value={activeStep}>
              <Box component="div" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={(e, step) => setActiveStep(step)}>
                  <Tab icon={<CloseIcon />} value="Cancel" />
                  <Tab label="Validate Gateway" value="Gateway" />
                  <Tab label="Validate Home Kiosk" value="Kiosk" />
                  <Tab label="Validate Devices" value="Devices" />
                  <Tab label="Review Status" value="Review" />
                </TabList>
              </Box>
              <TabPanel value="Gateway">
                <Stack direction="row">
                  <Stack alignItems="center" justifyItems="space-between">
                    <Typography variant="h5">Ensure Network and Gateway are installed</Typography>
                    <CommissionGateway />
                    <Button
                      variant="contained"
                      disabled={!isGatewaySetup(f.values)}
                      onClick={() => submitNetwork()}
                    >Confirm</Button>
                  </Stack>
                  <img
                    width="500"
                    height="400"
                    alt="gateway"
                    src="/commissioning/gateway.png"
                  />
                </Stack>
              </TabPanel>
              <TabPanel value="Kiosk">
                <Stack direction="row">
                  <Stack alignItems="center" justifyItems="space-between">
                    <Typography variant="h5">Ensure Kiosk is installed</Typography>
                    <CommissionKiosk />
                    <Button
                      variant="contained"
                      disabled={!isKioskSetup(f.values)}
                      onClick={() => submitKiosk()}
                    >Confirm</Button>
                  </Stack>
                  <img
                    width="500"
                    height="400"
                    alt="kiosk"
                    src="/commissioning/kiosk.png"
                  />
                </Stack>
                <Stack alignItems="center">

                </Stack>
              </TabPanel>
              <TabPanel value="Devices">
                <Stack alignItems="center">
                  <Typography variant="h5">Ensure the rest of the devices are installed</Typography>
                  <CommissionDevices
                    devices={devices}
                    refetchDevices={refetchDevices}
                    manifest={manifest}
                    refetchManifest={refetchManifest}
                  />
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={() => submitDevices()}
                  >Next</Button>
                </Stack>
              </TabPanel>
              <TabPanel value="Review">
                <Stack alignItems="center">
                  <Typography variant="h5">Review your progress</Typography>
                  <Review
                    manifest={manifest}
                    refetchManifest={refetchManifest}
                  />
                  <LoadingButton
                    loading={f.isSubmitting}
                    type='submit'
                    variant="contained"
                  >Finish Commissioning</LoadingButton>
                </Stack>
              </TabPanel>
            </TabContext>
          </form>
        )}
      </Formik>
    </Box>
  );
}

export default Commissioning;