import { riotApi } from "@features/aws-user/api";
import { Tenant } from "@features/userTenant/types";

export const propertyManagersApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      propertyManagers: builder.query<Tenant[], {
        userTenantId: string,
      }>({
        query: ({userTenantId}) => ({
          url: `/tenants/${encodeURIComponent(userTenantId)}/propertymanagers`,
        }),
      }),
    };
  },
});

export const {
  usePropertyManagersQuery,
} = propertyManagersApi;