import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import AdminTable from "@features/Common/AdminTable";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import DeviceSensors from "@features/plan-manifest/components/DeviceSensors";
import { ManifestDevice } from "@features/plan-manifest/types";
import { ApiErrorResponse, SnackType } from '@lib/types';
import { getErrorMessage } from "@lib/utils";
import SensorsIcon from '@mui/icons-material/Sensors';
import TuneIcon from '@mui/icons-material/Tune';
import { Backdrop, Box, CircularProgress, IconButton, Tooltip, Typography } from "@mui/material";
import { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from 'react';
import { RIOT_BLUE } from "theme";
import { useCreateDeviceMutation, useInventoryQuery, useUpdateDeviceMutation } from "../api";
import DeviceFields from "./DeviceFields";
import SensorsModal from "./SensorsModal";
import ZwaveConfigModal from "./ZwaveConfigModal";


const DeviceList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const inventory = useAppSelector((state: RootState) => state.inventory);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const  currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant.builder_color || RIOT_BLUE, [currentTenant.builder_color]);

  const [managingSensors, setManagingSensors] = useState<ManifestDevice|null>(null);
  const [managingConfig, setManagingConfig] = useState<ManifestDevice|null>(null);

  const {
    refetch: fetchInventory,
  } = useInventoryQuery({
    userTenantId: currentTenant?.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id,
  });

  const [
    createDevice,
  ] = useCreateDeviceMutation();

  const [
    updateDevice,
  ] = useUpdateDeviceMutation();

  const handleSaveDevice = useCallback(async (device: Partial<ManifestDevice>) => {
    /** new device */
    if (!device.device_id) {
      const newDevice = await createDevice({
        userTenantId: currentTenant?.tenant_id || '',
        body: {
          ...device,
          sensors: [],
          zwave_config: [],
        },
      });

      const errorDetails = (newDevice as ApiErrorResponse)?.error;

      if (errorDetails) {
        enqueueSnackbar("Couldn't add device to inventory:", {
          key: "device-create-error",
          content: (
            <ExpandableSnack
              id="device-create-error"
              message={"Couldn't add device to inventory:"}
              variant={SnackType.error}
              detail={getErrorMessage(errorDetails)}
            />),
        });
      } else {
        enqueueSnackbar("Added device to inventory", {
          variant: "success",
        });
      }

      return (newDevice as any).data;
    }
    /** update device */
    const updatedDevice = await updateDevice({
      userTenantId: currentTenant?.tenant_id || '',
      deviceId: device.device_id,
      body: device,
    });

    const errorDetails = (updatedDevice as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't update device:", {
        key: "device-update-error",
        content: (
          <ExpandableSnack
            id="device-update-error"
            message={"Couldn't update device:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("Updated device", {
        variant: "success",
      });
    }

    return (updatedDevice as any).data;

  }, [createDevice, currentTenant?.tenant_id, enqueueSnackbar, updateDevice]);

  const renderedTable = useMemo(() => {
    if (userTenant.checkingTenant) return <Backdrop open><CircularProgress /></Backdrop>;

    const options: MUIDataTableOptions = {
      enableNestedDataAccess: '.',
    }

    const columns: MUIDataTableColumn[] = [
      {
        name: 'friendly_name',
        label: 'Label',
        options: {
          sort: true,
          filter: true,
          customBodyRenderLite: (idx) => (
            <Tooltip
              placement="top"
              arrow
              title={inventory[idx].description || 'No description provided'}
            >
              <span>
                <Typography variant="subtitle1">{inventory[idx].friendly_name || ''}</Typography>
              </span>
            </Tooltip>)
        },
      },
      {
        name: 'manufacturer',
        label: 'Manufacturer',
      },
      {
        name: 'part_number',
        label: 'Part number',
      },
      {
        name: 'model_number',
        label: 'Model number',
      },
      {
        name: '',
        label: 'Sensors',
        options: {
          sort: false,
          filter: false,
          customBodyRenderLite: (idx) => (
            <Tooltip
              placement="top"
              arrow
              title={<DeviceSensors device={inventory[idx]} />}
            >
              <span>
                <IconButton
                  onClick={() => setManagingSensors(inventory[idx])}
                >
                  <SensorsIcon htmlColor={themeColor} />
                </IconButton>
              </span>
            </Tooltip>)
        },
      },
      {
        name: '',
        label: 'Z-Wave Config',
        options: {
          sort: false,
          filter: false,
          customBodyRenderLite: (idx) => (
            <Tooltip
              placement="top"
              arrow
              title={
                inventory[idx].zwave_config.length > 0
                  ? `${inventory[idx].zwave_config.length} parameter(s) to be set during provisioning`
                  : 'No configuration required'
              }
            >
              <span>
                <IconButton
                  onClick={() => setManagingConfig(inventory[idx])}
                  >
                  <TuneIcon htmlColor={themeColor}  />
                </IconButton>
              </span>
            </Tooltip>)
        },
      },
    ];

    return (
      <AdminTable<ManifestDevice & { name: string }>
        title="Available devices"
        label="Device"
        id_key="device_id"
        name_key="friendly_name"
        columns={columns}
        options={options}
        data={inventory}
        makeNew={() => ({
          friendly_name: '',
          manufacturer: '',
          part_number: '',
          model_number: '',
        })}
        onSave={handleSaveDevice}
        refresh={fetchInventory}
        editPermission="write-plans"
        deletePermission="delete-devices"
      >
        {f => <DeviceFields />}
      </AdminTable>
    );

  }, [fetchInventory, handleSaveDevice, inventory, themeColor, userTenant.checkingTenant]);

  const handleUpdatedConfigs = (changes: boolean) => {
    if (changes) fetchInventory();
    setManagingConfig(null);
  }

  const handleUpdatedSensors = (changes: boolean) => {
    if (changes) fetchInventory();
    setManagingSensors(null);
  }

  return (
    <Box
      component="div"
      sx={{
        margin: '1em',
        padding: '2em',
      }}
    >
      <Typography variant="h4">{currentTenant.name}</Typography>
      <Typography variant="h5">Available devices</Typography>
      <Box
        component="div"
        sx={{
          mt: '3em',
        }}
      >
        {renderedTable}
        {
          managingSensors?.device_id &&
          <SensorsModal
            onClose={handleUpdatedSensors}
            device={managingSensors}
          />
        }
        {
          managingConfig?.device_id &&
          <ZwaveConfigModal
            onClose={handleUpdatedConfigs}
            device={managingConfig}
          />
        }
      </Box>
    </Box>
  );
}

export default DeviceList;