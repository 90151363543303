import { EnergyManagement } from "@features/all-utility-groups/types";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  energy: {} as EnergyManagement,
};

export const utilGroupEnergyReducer = createSlice({
  name: 'utilityGroupEnergy',
  initialState,
  reducers: {
    setEnergy: (state: { energy: EnergyManagement }, action: any) => {
      if (action.payload) {
        state.energy = action.payload as EnergyManagement;
      } else {
        state.energy = initialState.energy;
      }
    },
  }
});

export default utilGroupEnergyReducer.reducer;

export const {
  setEnergy,
} = utilGroupEnergyReducer.actions;