
import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { Tenant } from "@features/userTenant/types";
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import LoadingButton from "@mui/lab/LoadingButton";
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { useShareDocumentMutation, useUnshareDocumentMutation } from "../api";
import { Document, DocumentApiResponse } from '../types';

type Props = {
  document: Document;
  open: boolean;
  onClose: (changes: boolean) => void;
}

const ShareModal = ({ document, open, onClose }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const myTenants = useMemo(() => userTenant.myTenants, [userTenant.myTenants]);
  const lastSharedWith = useMemo(() => new Set<string>(document?.shared_with.map(s => s.tenant_id)), [document.shared_with]);

  const [sharedWith, setSharedWith] = useState<Set<string>>(lastSharedWith.add(document.tenant_id));

  const [
    shareDocument,
    { isLoading: isSharingDocument },
  ] = useShareDocumentMutation();

  const [
    unshareDocument,
    { isLoading: isUnsharingDocument },
  ] = useUnshareDocumentMutation();

  const getTenantById = useCallback((tenantId: string): Tenant | null => {
    // TODO map tenants by ID rather than brute-force search for efficiency
    return myTenants?.find(t => t.tenant_id === tenantId) || null;
  }, [myTenants]);

  /** Handles sharing of documents  */
  const handleSubmit = useCallback(async () => {
    const tenantsToAdd = Array.from(sharedWith.values())
      .filter((sw => !lastSharedWith.has(sw)));
    const tenantsToRemove = Array.from(lastSharedWith.values())
      .filter((sw => !sharedWith.has(sw)));

    if (tenantsToAdd.length || tenantsToRemove.length) {
      const res = await Promise.all([
        ...tenantsToAdd.map((t) => shareDocument({
          userTenantId: currentTenant?.tenant_id || '',
          documentId: document.document_id,
          tenantId: t || '',
        })),
        ...tenantsToRemove.map((t) => unshareDocument({
          userTenantId: currentTenant?.tenant_id || '',
          documentId: document.document_id,
          tenantId: t || '',
        })),
      ]);

      if (res.every(e => (e as DocumentApiResponse).data)) {
        enqueueSnackbar("Updated document sharing", {
          variant: "success",
        });
        onClose(true);
      } else {

        res.filter(e => (e as ApiErrorResponse)?.error)
          .forEach(e => {
            const errorDetails = (e as ApiErrorResponse)?.error;
            enqueueSnackbar("Couldn't update document sharing:", {
              key: "document-error",
              content: (
                <ExpandableSnack
                  id="document-error"
                  message={"Couldn't update document sharing:"}
                  variant={SnackType.error}
                  detail={getErrorMessage(errorDetails)}
                />),
            });
            onClose(false);
          });
      }
    } else {
      onClose(false);
    }
  }, [currentTenant?.tenant_id, document.document_id, enqueueSnackbar, lastSharedWith, onClose, shareDocument, sharedWith, unshareDocument]);

  const handleAutocompleteChange = useCallback((e: SyntheticEvent<Element, Event>, v: Tenant[]) => {
    const newSharedwith = new Set([
      ...v.map(t => t?.tenant_id || '')
    ]);
    setSharedWith(newSharedwith);
  }, []);

  return (
    <Dialog open={open} scroll="paper" fullWidth onClose={onClose}>
      <DialogTitle>Share {document.name}</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={1} sx={{ mt: 1 }}>
          <Autocomplete
            fullWidth
            multiple
            options={myTenants}
            getOptionLabel={(t: Tenant) => t.name}
            defaultValue={
              Array.from(
                lastSharedWith.values()
              ).map(getTenantById).filter((e): e is Tenant => e !== null)
            }
            onChange={handleAutocompleteChange}
            isOptionEqualToValue={(a, b) => a.tenant_id === b.tenant_id}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Shared with"
                placeholder="Add organization"
              />
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          loading={isSharingDocument || isUnsharingDocument}
        >
          Share
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default ShareModal;