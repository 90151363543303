import { riotApi } from "@features/aws-user/api";
import { U } from "@lib/utils";


/** API slice for the tenant-related endpoints */
export const tenantApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      /** Get tenant's properties */
      tenantProperties: builder.query<any, { userTenantId: string, tenantId: string }>({
        query: ({ userTenantId, tenantId }) => ({
          url: `/tenant/${U(userTenantId)}/properties`,
          params: {
            tenant_id: U(tenantId)
          }
        })
      }),
    };
  }
});

export const {
  useTenantPropertiesQuery,
} = tenantApi;
