import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import {
  Button, CardHeader,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText, Stack
} from '@mui/material';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { RIOT_BLUE } from "theme";

type Props<T> = {
  /** array available to transfer */
  available: T[];
  /** array of already transfered */
  selected: T[];
  /** name of the attribute for record id */
  id_key: keyof T;
  /** name of the attribute for record name */
  name_key: keyof T;
  /** callback to run when the updates to the list are submitted */
  callback?: (toRemove: string[], toAdd: string[]) => void;
  /** headers for the lists */
  titleLeft?: string;
  titleRight?: string;
}

type Items<T> = {
  left: T[];
  right: T[];
}

export default function TransferList<T>({
  available,
  selected,
  id_key,
  name_key,
  titleLeft,
  titleRight,
  callback,
}: Props<T>) {
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant?.builder_color || RIOT_BLUE, [currentTenant?.builder_color]);
  /**
   *
   * @param a -  array
   * @param b -  array
   * @returns all elements of first array that are not in the second one
   */
  const not = useCallback((
    a: readonly T[],
    b: readonly T[],
  ) => a.filter((value) => b.indexOf(value) === -1), []);

  /**
   *
   * @param a -  array
   * @param b -  array
   * @returns all elements of first array that are in the second one
   */
  const intersection = useCallback((
    a: readonly T[],
    b: readonly T[],
  ) => a.filter((value) => b.indexOf(value) !== -1), []);

  /**
   *
   * @param a -  array
   * @param b -  array
   * @returns union of 2 arrays, without repetition
   */
  const union = useCallback((
    a: readonly T[],
    b: readonly T[],
  ) => ([...a, ...not(b, a)]), [not]);

  const [checked, setChecked] = useState<readonly T[]>([]);

  const [items, setItems] = useState<Items<T>>({
    left: [...not(available, selected)],
    right: [...selected],
  });

  const leftChecked = useMemo(() => intersection(checked, items.left), [checked, intersection, items.left]);
  const rightChecked = useMemo(() => intersection(checked, items.right), [checked, intersection, items.right]);

  /** toggles item's checkbox */
  const handleToggle = useCallback((value: T) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  }, [checked]);

  // returns number of selected items
  const stringOfChecked = useCallback((items: readonly T[]) =>
    intersection(checked, items).length, [checked, intersection]);

  /** toggles all items in the list based on "toggle all" checkbox */
  const handleToggleAll = useCallback((items: readonly T[]) => () => {
    if (stringOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  }, [checked, not, stringOfChecked, union]);

  /** moves checked items to the right */
  const handleCheckedRight = useCallback(() => {
    setItems({
      left: not(items.left, leftChecked),
      right: items.right.concat(leftChecked),
    });
    setChecked(not(checked, leftChecked));
  }, [checked, items.left, items.right, leftChecked, not]);

  /** moves checked items to the left */
  const handleCheckedLeft = useCallback(() => {
    setItems({
      left: items.left.concat(rightChecked),
      right: not(items.right, rightChecked),
    });
    setChecked(not(checked, rightChecked));
  }, [checked, items.left, items.right, not, rightChecked]);


  /**
   * run callback when items are moved from left to right
   * or from right to left
   */
  const processCallback = useCallback(() => {
    if (callback) {

      const toRemove = items.left
        .map(l => l[id_key] as unknown as string);

      const toAdd = items.right
        .map(r => r[id_key] as unknown as string);

      callback(toRemove, toAdd);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);


  useEffect(() => {
    processCallback();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  /** Displays Checkbox to check all items in the list */
  const renderCheckAll = useCallback((title: ReactNode, items: readonly T[]) => (
    <CardHeader
      sx={{ px: 2, py: 1 }}
      avatar={
        <Checkbox
          onClick={handleToggleAll(items)}
          checked={stringOfChecked(items) === items.length && items.length !== 0}
          indeterminate={
            stringOfChecked(items) !== items.length && stringOfChecked(items) !== 0
          }
          disabled={items.length === 0}
          inputProps={{
            'aria-label': 'all items selected',
          }}
        />
      }
      title={title}
      subheader={`${stringOfChecked(items)}/${items.length} selected`}
    />
  ), [handleToggleAll, stringOfChecked]);

  /** Displays checkbox with label */
  const renderItem = useCallback((item: T) => {
    const labelId = `transfer-list-all-item-${item[id_key]}-label`;

    return (
      <ListItem
        key={item[id_key] as unknown as string}
        role="listitem"
        button
        onClick={handleToggle(item)}
      >
        <ListItemIcon>
          <Checkbox
            checked={checked.indexOf(item) !== -1}
            tabIndex={-1}
            disableRipple
            inputProps={{
              'aria-labelledby': labelId,
            }}
          />
        </ListItemIcon>
        <ListItemText id={labelId} primary={item[name_key]} />
      </ListItem>
    );
  }, [checked, handleToggle, id_key, name_key]);

  /** Displays a list of checkboxes including "check all" option */
  const customList = useCallback((title: ReactNode, items: readonly T[]) => (
    <Stack sx={{
      width: '40%',
      padding: '1em',
    }}>
      {renderCheckAll(title, items)}
      <Divider />
      <List
        sx={{
          position: 'relative',
          overflow: 'auto',
          height: 200,
          border: `1px solid ${themeColor}`,
          padding: '6px',
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value: T) => renderItem(value))}
        <ListItem />
      </List>
    </Stack>
  ), [renderCheckAll, renderItem, themeColor]);

  /** MAIN RENDER */
  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      {customList(titleLeft, items.left)}
      <Stack>
        <Button
          sx={{ my: 0.5 }}
          variant="outlined"
          size="small"
          onClick={handleCheckedRight}
          disabled={leftChecked.length === 0}
          aria-label="move selected right"
        >
          &gt;
        </Button>
        <Button
          sx={{ my: 0.5 }}
          variant="outlined"
          size="small"
          onClick={handleCheckedLeft}
          disabled={rightChecked.length === 0}
          aria-label="move selected left"
        >
          &lt;
        </Button>
      </Stack>
      {customList(titleRight, items.right)}
    </Stack>
  );
}
