import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { TenantType } from "@features/userTenant/types";
import AddHomeIcon from '@mui/icons-material/AddHome';
import { Card, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemText, Skeleton, Tooltip } from "@mui/material";
import { useMemo, useState } from "react";
import AddHomesDialog from "./AddHomesDialog";
import HomeHealthDialog from "./HomeHealthDialog";
import NeighborhoodHomesList from "./NeighborhoodHomesList";

type Props = {
}

const NeighborhoodHomes = (props: Props) => {
  const neighborhood = useAppSelector((state: RootState) => state.neighborhood);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const  currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const canEdit = useMemo(() => [TenantType.Builder, TenantType.System].includes(currentTenant?.tenant_type), [currentTenant?.tenant_type]);

  const [editingHomes, setEditingHomes] = useState<boolean>(false);
  const [selectedHomeId, setSelectedHomeId] = useState<string|null>(null);
  
  return (
    <>
      <Card>
        <CardHeader
          title="Homes"
          titleTypographyProps={{
            variant: 'h6'
          }}
          action={
            <Tooltip title="Add homes to the neighborhood" placement="top" arrow>
              <span>
                <IconButton onClick={() => setEditingHomes(true)} disabled={!canEdit}>
                  <AddHomeIcon color={canEdit ? 'warning' : 'disabled'} />
                </IconButton>
              </span>
            </Tooltip>
          }
        />
        <Divider />
        <CardContent sx={{ height: '34em', minWidth: '20em' }}>
          {
            !neighborhood.neighborhood?.neighborhood_id
              ? <List>
                <ListItem><ListItemText><Skeleton variant="rectangular" /></ListItemText></ListItem>
                <ListItem><ListItemText><Skeleton variant="rectangular" /></ListItemText></ListItem>
                <ListItem><ListItemText><Skeleton variant="rectangular" /></ListItemText></ListItem>
                <ListItem><ListItemText><Skeleton variant="rectangular" /></ListItemText></ListItem>
              </List>
              : <NeighborhoodHomesList handleShowHealthDialog={setSelectedHomeId}/>
          }
        </CardContent>
      </Card>
      <AddHomesDialog
        open={editingHomes}
        onClose={() => setEditingHomes(false)}
      />
      {
        selectedHomeId && <HomeHealthDialog
          health={neighborhood.health}
          homeId={selectedHomeId}
          open
          onClose={() => setSelectedHomeId(null)}
         />
      }
    </>
  )
}

export default NeighborhoodHomes;