import PlanList from "@features/all-plans/components/PlanList";
import PlansProvider from "@features/all-plans/components/PlansProvider";
import Header from "@features/Common/Header";
import { FC } from "react";

/** Wrapper for /users */
const AllPlans: FC = () => {
  return (
    <PlansProvider>
      <Header />
      <PlanList />
    </PlansProvider>
  );
}
export default AllPlans;