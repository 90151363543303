import { useAppSelector } from "@app/hooks";
import { RootState } from '@app/store';
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import DraftsIcon from '@mui/icons-material/Drafts';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import { ButtonGroup, IconButton, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useMemo } from "react";
import { useNotificationsQuery, useReadMutation, useSnoozeMutation, useUnreadMutation } from "../api";
import { NotificationStatus, UserNotification } from "../types";
import SnoozeButton from "./SnoozeButton";


type Props = {
  notification: UserNotification;
  onDismiss: (id: string) => void;
}

const Actions = ({ notification, onDismiss }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const awsUser = useAppSelector((state: RootState) => state.awsUser);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const property = useAppSelector((state: RootState) => state.property);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const latestReadStatus = useMemo(() => [...notification.lifecycle]
    .sort((a, b) => (Number(b.datetime) - Number(a.datetime)))
    .find(l => [
      NotificationStatus.new,
      NotificationStatus.unread,
      NotificationStatus.read,
    ].includes(l.action))?.action || notification.lifecycle_action, [notification.lifecycle, notification.lifecycle_action]);

  const isUnread = useMemo(() => [
    NotificationStatus.new,
    NotificationStatus.unread,
  ].includes(latestReadStatus), [latestReadStatus]);

  const actionLabel = useMemo(() => [
    NotificationStatus.new,
    NotificationStatus.unread,
  ].includes(latestReadStatus) ? 'Read' : 'Unread', [latestReadStatus]);


  const {
    refetch,
  } = useNotificationsQuery({
    userTenantId: currentTenant?.tenant_id || '',
    propertyId: property?.property_id || '',
    includeUserActions: true,
    username: awsUser.username || '',
  }, {
    skip: !currentTenant?.tenant_id || !property?.property_id,
  });

  const [
    readNotification,
    { isLoading: isReading },
  ] = useReadMutation();

  const [
    unreadNotification,
    { isLoading: isUnreading },
  ] = useUnreadMutation();

  const [
    snoozeNotification,
  ] = useSnoozeMutation();


  const handleReadUnreadNotification = useCallback(async (notification: UserNotification) => {

    const action = [
      NotificationStatus.new,
      NotificationStatus.unread,
    ].includes(latestReadStatus) ? readNotification : unreadNotification;

    const actionLabel = [
      NotificationStatus.new,
      NotificationStatus.unread,
    ].includes(latestReadStatus) ? 'Read' : 'Unread';


    const result = await action({
      userTenantId: currentTenant?.tenant_id || '',
      userNotificationId: notification.user_notification_id,
    });

    const errorDetails = (result as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar(`Couldn't mark notification as ${actionLabel}:`, {
        key: "notification-read-error",
        content: (
          <ExpandableSnack
            id="notification-read-error"
            message={`Couldn't mark notification as ${actionLabel}:`}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar(`notification marked as ${actionLabel}`, {
        variant: "success",
      });
      refetch();
    }
  }, [latestReadStatus, readNotification, unreadNotification, currentTenant?.tenant_id, enqueueSnackbar, refetch]);


  const handleSnooze = useCallback(async (notif: UserNotification, hours: number) => {
    const result: any = await snoozeNotification({
      userNotificationId: notif.user_notification_id,
      userTenantId: currentTenant?.tenant_id || '',
      snoozeHours: hours,
    })

    const errorDetails = (result as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar(`Couldn't snooze notification:`, {
        key: "notifications-snooze-error",
        content: (
          <ExpandableSnack
            id="notifications-snooze-error"
            message={`Couldn't snooze notification:`}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar(`Notification snoozed`, {
        variant: "success",
      });
      refetch();
    }
  }, [enqueueSnackbar, refetch, snoozeNotification, currentTenant?.tenant_id]);


  return (
    <ButtonGroup>
      <Tooltip title={`Mark as ${actionLabel}`} placement='top'>
        <span>
          <IconButton
            disabled={isReading || isUnreading}
            onClick={() => handleReadUnreadNotification(notification)}
          >
            {isUnread ? <MarkEmailUnreadIcon /> : <DraftsIcon />}
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Dismiss" placement='top'>
        <span>
          <IconButton onClick={() => onDismiss(notification.user_notification_id)}>
            <MarkEmailReadIcon />
          </IconButton>
        </span>
      </Tooltip>
      <SnoozeButton
        notification={notification}
        handleSnooze={(hours) => handleSnooze(notification, hours)}
      />
    </ButtonGroup>
  );
}

export default Actions;