import { PlanManifest } from "@features/plan-manifest/types";
import {
  Box,
  FormControlLabel,
  Radio,
  Typography
} from "@mui/material";

type Props = {
  manifest: PlanManifest;
  isSelected: boolean;
}

/*
* Displays a card for a Manifest Template Summary
*/
const PlanManifestSummary = ({ manifest, isSelected }: Props) => (
  <Box
    component="div"
    key={manifest.manifest_id}
    sx={{
      maxHeight: '4em',
      height: 'fit-content',
      padding: '5px',
      margin: '5px',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}
  >
    <FormControlLabel
      value={manifest.manifest_id}
      control={<Radio checked={isSelected} />}
      label={manifest.name}
    />
    <Box
      component="div"
      sx={{
        padding: '2px'
      }}
    >
      <Typography variant="subtitle1"><i>{manifest.description}</i></Typography>
    </Box>
  </Box>
);

export default PlanManifestSummary;