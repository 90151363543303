export const greetingStep = `Thank you for your interest in collaborating with RIoT-Technology! In order to interact with RIoT-Technology OEM API, a client certificate needs to be generated using the API key and the steps below.`;

export const keyStep = `1. Save your unique API key to your environment`;

export const keyScript = `export API_KEY=`;

export const csrStep = `2. Create a certificate signing request (CSR) secured with a new RSA key. You will need to provide some details during this step, and a password to secure your key. You will need my_csr.csr file generated during this step to generate signed certificate later.`;

export const csrScript = `openssl genrsa -out private_key.key 2048 && openssl req -new -key private_key.key -out my_csr.csr`;

export const shellStep = `3. Save the script below to a new file sign-cert.sh. Make sure this file has execution rights.`;

export const certShellScript = `#!/bin/bash

SIGNER_URL=$1
CSR=$2
CERT_DESTINATION=$3
API_KEY=$4

result=$(curl -X POST --data-binary "@$CSR" --header "Content-Type: binary/octet-stream" -H "x-api-key: $API_KEY" "$SIGNER_URL")
echo "$result" | base64 --decode > $CERT_DESTINATION`;

export const generateStep = `4. Execute sign-cert.sh script using command below`;

export const genCertScript = `./sign-cert.sh https://csr.dev.riot-homes.com/ ./my_csr.csr ./cert.pem $API_KEY`;

export const useStep = `5. As the result of step 4, you will have a new cert.pem file containing the signed certificate. Use this certificate when making requests to RIoT-Technology OEM API.`;

export const goodbyeStep = `RIoT-Technology Central`;

export const emailSubject = `RIoT-Technology Onboarding Instructions`;

export const SAMPLE_KEY = '';