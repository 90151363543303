import { Stack, Typography } from "@mui/material";
import ProvisionGateway from "./ProvisionGateway";


const ProvisionGatewayPanel = () => {
  return (
    <Stack direction="row">
      <Stack alignItems="center" justifyItems="space-between">
        <Typography variant="h5">Provision Gateway device</Typography>
        <ProvisionGateway />
      </Stack>
      <img
        width="400"
        height="400"
        alt="gateway"
        src="/provisioning/gateway.png"
      />
    </Stack>
  );
}

export default ProvisionGatewayPanel;