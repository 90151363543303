import Header from "@features/Common/Header";
import UtilityGroup from "@features/utility-group/components/UtilityGroup";
import UtilityGroupProvider from "@features/utility-group/components/UtilityGroupProvider";
import { Box } from "@mui/material";


const UtilityGroupView = () => {
  return (
    <Box
      component="div"
      sx={{
        margin: '1em',
        padding: '2em',
      }}
    >
      <UtilityGroupProvider>
        <Header />
        <UtilityGroup />
      </UtilityGroupProvider>
    </Box>
  )
}

export default UtilityGroupView;