import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import AddressSummary from "@features/Address/AddressSummary";
import HomeFields from "@features/home/components/HomeFields";
import { Property, PROPERTY_SCHEMA } from "@features/home/types";
import { LoadingButton } from "@mui/lab";
import { Button, Divider, FormGroup, Stack, Typography } from "@mui/material";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useState } from "react";


type Props = {
  onSave: (pp: Property, helpers: FormikHelpers<Property>)  => void;
}

const HomeEditor = ({ onSave }: Props) => {
  const home = useAppSelector((state: RootState) => state.property as Property);
  const [editMode, setEditMode] = useState<boolean>(false);

  const handleCancel = (f: FormikProps<Property>) => {
    setEditMode(false);
    f.resetForm();
  }

  const handleSave = (f: FormikProps<Property>) => {
    setEditMode(false);
    f.submitForm();
  }

  return (
    <Formik
      initialValues={home}
      validationSchema={PROPERTY_SCHEMA}
      onSubmit={onSave}
    >
      {f => (
        <form>
          <Stack sx={{ width: '100%' }}>
            {
              !editMode
                ? (
                  <Stack spacing={1}>
                    <Typography variant="h5">Home Address</Typography>
                    <Divider />
                    <AddressSummary
                      address={home.physical_address}
                      spacing={1.4}
                      variant="h6"
                    />
                  </Stack>)
                : <HomeFields readOnly={!editMode} />
            }
            <FormGroup row sx={{ p: 1, justifyContent: 'center' }}>
            {
              !editMode
                ? <Button variant="contained" onClick={() => setEditMode(true)}>Edit</Button>
                : (<>
                    <Button type="reset" onClick={() => handleCancel(f)}>
                      Cancel
                    </Button>
                    <LoadingButton
                      loading={f.isSubmitting}
                      onClick={() => handleSave(f)}
                      disabled={!f.dirty || !f.isValid || f.isSubmitting}
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </LoadingButton>
                  </>)
            }
            </FormGroup>
          </Stack>
        </form>
      )}
    </Formik>
  );
}
export default HomeEditor;