import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import ExpandableSnack from '@features/Common/ExpandableSnack';
import { TenantType } from '@features/userTenant/types';
import { ApiErrorResponse, SnackType } from '@lib/types';
import { formatDate, getErrorMessage } from '@lib/utils';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { Button, Divider, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from "notistack";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { RIOT_BLUE } from 'theme';
import { useResolveCaseMutation, useSupportCaseQuery, useUpdateCaseMutation } from '../api';
import { SupportCase, SupportCaseUpdate } from '../types';


const SupportCaseEditor = () => {
  const { enqueueSnackbar } = useSnackbar();

  const supportCase = useAppSelector((state: RootState) => state.supportCase.case as SupportCase);
  const property = useAppSelector((state: RootState) => state.property);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant.builder_color || RIOT_BLUE, [currentTenant.builder_color]);
  const isAdmin = useMemo(() => currentTenant?.tenant_type === TenantType.System, [currentTenant?.tenant_type]);

  const creator = useMemo(() => [
    [
      supportCase?.given_name || '',
      supportCase?.family_name || '',
    ].join(' '),
    [
      '(',
      supportCase?.username || 'Unknown user',
      ')'
    ].join(''),
    formatDate(supportCase?.created).fromNow(),
  ].join(' '), [supportCase?.created, supportCase?.family_name, supportCase?.given_name, supportCase?.username]);

  const status = useMemo(() => supportCase?.resolved ? 'Resolved' : 'Open', [supportCase?.resolved]);

  const statusIcon = useMemo(() => supportCase?.resolved
    ? <Tooltip placement="left" arrow title="Case is resolved">
        <AssignmentTurnedInIcon fontSize='large' color="success" />
      </Tooltip>
    : <Tooltip placement="left" arrow title="Case is open and requires attention">
        <AssignmentLateIcon fontSize='large' color="warning" />
      </Tooltip>, [supportCase?.resolved]);

  const [editing, setEditing] = useState<boolean>(false);
  const [issueSummary, setIssueSummary] = useState<string>(supportCase?.issue_summary || '');

  const [
    updateCase,
  ] = useUpdateCaseMutation();

  const [
    resolveCase,
  ] = useResolveCaseMutation();

  const {
    refetch: refetchCase,
  } = useSupportCaseQuery({
    userTenantId: currentTenant?.tenant_id || '',
    propertyId: property?.property_id || '',
    caseId: supportCase?.case_id || '',
  }, {
    skip: !currentTenant?.tenant_id || !property?.property_id || !supportCase?.case_id,
  });

  const handleEditMode = useCallback(() => setEditing(!editing), [editing]);

  useEffect(() => {
    if (!editing) setIssueSummary(supportCase?.issue_summary || '');
  }, [editing, supportCase?.issue_summary]);

  const handleUpdateDetails = useCallback(async () => {
    const updatedCase = await updateCase({
      userTenantId: currentTenant?.tenant_id || '',
      propertyId: property?.property_id || '',
      caseId: supportCase.case_id || '',
      body: {
        issue_summary: issueSummary,
      } as SupportCaseUpdate
    });

    const errorDetails = (updatedCase as ApiErrorResponse)?.error;
    if (errorDetails) {
      enqueueSnackbar("Couldn't update case:", {
        key: "update-case-error",
        content: (
          <ExpandableSnack
            id="update-case-error"
            message={"Couldn't update case:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("Updated case", {
        variant: "success",
      });
      refetchCase();
      setEditing(false);
    }
  }, [currentTenant?.tenant_id, enqueueSnackbar, issueSummary, property?.property_id, refetchCase, supportCase.case_id, updateCase]);

  const detailsNotValid = useMemo(() => !issueSummary || issueSummary === supportCase?.issue_summary, [issueSummary, supportCase?.issue_summary]);

  const handleResolveCase = useCallback(async () => {
    const updatedCase = await resolveCase({
      userTenantId: currentTenant?.tenant_id || '',
      propertyId: property?.property_id || '',
      caseId: supportCase?.case_id || '',
      keep: true,
    });

    const errorDetails = (updatedCase as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't resolve case:", {
        key: "resolve-case-error",
        content: (
          <ExpandableSnack
            id="resolve-case-error"
            message={"Couldn't resolve case:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("Resolved case", {
        variant: "success",
      });
      refetchCase();
    }
  }, [currentTenant?.tenant_id, enqueueSnackbar, property?.property_id, refetchCase, resolveCase, supportCase?.case_id]);

  return <>
    <Stack direction='row' justifyContent="space-between" alignItems="center">
      <Typography variant="h6">Case Details</Typography>
      {
        isAdmin &&
        <Stack direction="row" spacing={1}>
          {
            !editing && !supportCase?.resolved &&
            <Button
              size="small"
              variant='contained'
              onClick={handleResolveCase}
              color="success"
            >
              Resolve
            </Button>
          }
          {
            editing &&
            <Button
              size="small"
              variant="contained"
              disabled={detailsNotValid}
              onClick={handleUpdateDetails}
            >
              Save
            </Button>
          }
          <Button
            size="small"
            variant="contained"
            onClick={handleEditMode}
          >
            {editing ? 'Cancel' : 'Edit'}
          </Button>
        </Stack>
      }
    </Stack>
    <Divider />
    <Stack spacing={1}>
      <Stack
        sx={{
          width: '100%',
        }}
      >
        <Typography variant="caption" sx={{ color: themeColor }}>Issue summary</Typography>
        {
          editing
            ? <TextField
                value={issueSummary}
                multiline
                rows={3}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setIssueSummary(e.target.value)}
              />
            : <Typography
                variant="body1"
                sx={{
                  border: `1px solid ${supportCase.issue_summary ? themeColor : 'transparent'}`,
                  borderRadius: 2,
                  padding: 1,
                  height: '7em',
                  overflow: 'auto'
                }}
              >
                {supportCase.issue_summary}
              </Typography>
        }
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography variant="caption" sx={{ color: themeColor }}>Created by</Typography>
          <Typography variant="body1">{creator}</Typography>
        </Stack>
        <Stack alignItems="end">
          <Typography variant="caption" sx={{ color: themeColor }}>Updated</Typography>
          <Typography variant="body1">{formatDate(supportCase?.updated).fromNow()}</Typography>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack>
          <Typography variant="caption" sx={{ color: themeColor }}>Status</Typography>
          <Typography variant="body1">{status}</Typography>
        </Stack>
        {statusIcon}
      </Stack>
    </Stack></>;
}

export default SupportCaseEditor;