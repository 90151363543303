import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import { Box, Button, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { RIOT_BLUE } from "theme";
import { useUploadLogoMutation } from '../api';


const BuilderLogo = () => {

  const { enqueueSnackbar } = useSnackbar();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const [logo, setLogo] = useState<any>(null);


  const [
    uploadLogo,
  ] = useUploadLogoMutation();

  const handleFilesChange = (file: File) =>  setLogo(file);

  const handleUploadLogo = useCallback(async () => {
    const formData = new FormData();
    formData.append('file', logo);
    const uploadedFile = await uploadLogo({
      userTenantId: currentTenant?.tenant_id || '',
      fileName: `logo.${logo.name.split('.')?.[1]}`,
      file: formData,
    });

    const errorDetails = (uploadedFile as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't upload a new logo:", {
        key: "logo-upload-error",
        content: (
          <ExpandableSnack
            id="logo-upload-error"
            message={"Couldn't upload a new logo"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("Uploaded a new Logo", {
        variant: "success",
      });

    }
  }, [currentTenant?.tenant_id, enqueueSnackbar, logo, uploadLogo]);


  return (
    <Card sx={{
      width: '40em',
      height: '25em',
    }}>
      <CardHeader title="Logo" />
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="h6">
            Your users will see your logo instead of default RIoT-Technology App.
          </Typography>
          <Typography variant="button" color="error">
            Images uploaded here will be publicly accessible. Please do not upload any private or explicit materials.
          </Typography>
          <Stack direction="row">
            <Stack spacing={1}>
              <FileUploader
                multiple={false}
                handleChange={handleFilesChange}
                name="logo"
                label="Upload or drop your logo"
                types={['JPEG', 'PNG', 'SVG']}
                maxSize={2}
                children={
                  <Box
                    component="div"
                    sx={{
                      border: `1px solid ${currentTenant.builder_color || RIOT_BLUE}`,
                      padding: '1em',
                      width: '25em'
                    }}
                  >
                    <Typography variant="caption">
                      Click <Typography variant="button">here</Typography> or drag your logo for upload
                    </Typography>
                  </Box>
                }
              />
              <Typography variant="body1">{logo?.name || 'No file selected'}</Typography>
            </Stack>

            <Button onClick={handleUploadLogo} disabled={!logo}>Upload</Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default BuilderLogo;
