import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import AdminTable from '@features/Common/AdminTable';
import ExpandableSnack from '@features/Common/ExpandableSnack';
import { useCasesQuery, useCreateCaseMutation, useUpdateCaseMutation } from "@features/home-support-cases/api";
import AppTextField from '@features/home/components/AppTextField';
import { SupportCase, SupportCaseCreate, SupportCaseUpdate } from '@features/support-case/types';
import { pollingInterval } from '@features/tenant-selector/types';
import { ApiErrorResponse, SnackType } from '@lib/types';
import { U, formatDate, getErrorMessage } from '@lib/utils';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from 'react';
import { RIOT_BLUE } from 'theme';
import { getColumns } from './columns';


const HomeSupportCases = () => {
  const { enqueueSnackbar } = useSnackbar();

  const awsUser = useAppSelector((state: RootState) => state.awsUser);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const property = useAppSelector((state: RootState) => state.property);
  const supportCases = useAppSelector((state: RootState) => state.supportCases);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant?.builder_color || RIOT_BLUE, [currentTenant?.builder_color]);
  const isLoading = useMemo(() => !currentTenant.tenant_id || !property.property_id, [currentTenant.tenant_id, property.property_id]);

  const {
    refetch: refetchHomeCases,
  } = useCasesQuery({
    userTenantId: currentTenant?.tenant_id || '',
    propertyId: property?.property_id || '',
  }, {
    skip: !currentTenant?.tenant_id || !property?.property_id,
    pollingInterval,
  });

  const [showResolved, ] = useState<boolean>(true);

  const [
    createCase,
  ] = useCreateCaseMutation();

  const [
    updateCase,
  ] = useUpdateCaseMutation();

  const handleSaveCase = useCallback(async (supportCase: Partial<SupportCase>) => {
    /** creating a case */
    if (!supportCase.case_id) {
      const newCase = await createCase({
        userTenantId: currentTenant?.tenant_id || '',
        propertyId: property?.property_id || '',
        body: {
          issue_summary: supportCase.issue_summary,
          homeowner_id: property?.homeowner_id || '',
          builder_id: property?.builder_id || '',
          username: awsUser?.username || '',
          given_name: awsUser?.attributes?.given_name || '',
          family_name: awsUser?.attributes?.family_name || '',
          resolved: false,
        } as SupportCaseCreate,
      });

      const errorDetails = (newCase as ApiErrorResponse)?.error;

      if (errorDetails) {
        enqueueSnackbar("Couldn't create support case:", {
          key: "create-case-error",
          content: (
            <ExpandableSnack
              id="create-case-error"
              message={"Couldn't create support case:"}
              variant={SnackType.error}
              detail={getErrorMessage(errorDetails)}
            />),
        });
      } else {
        refetchHomeCases();
        enqueueSnackbar("Created support case", {
          variant: "success",
        });
      }
      return (newCase as any).data;
    }

    /** update existing support case */
    const updatedCase = await updateCase({
      userTenantId: currentTenant?.tenant_id || '',
      propertyId: property?.property_id || '',
      caseId: supportCase.case_id || '',
      body: {
        issue_summary: supportCase.issue_summary,
      } as SupportCaseUpdate
    });

    const errorDetails = (updatedCase as ApiErrorResponse)?.error;
    if (errorDetails) {
      enqueueSnackbar("Couldn't update case:", {
        key: "update-case-error",
        content: (
          <ExpandableSnack
            id="update-case-error"
            message={"Couldn't update case:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("Updated case", {
        variant: "success",
      });
    }

    return updatedCase;

  }, [awsUser?.attributes?.family_name, awsUser?.attributes?.given_name, awsUser?.username, createCase, currentTenant?.tenant_id, enqueueSnackbar, property?.builder_id, property?.homeowner_id, property?.property_id, refetchHomeCases, updateCase]);


  const displayedCases = useMemo(() => {
    return supportCases
      .filter(c => showResolved || !c.resolved)
      .sort((a, b) => {
        if (a.resolved && !b.resolved) return 1;
        if (!a.resolved && b.resolved) return -1;
        return formatDate(a.updated).unix() - formatDate(b.updated).unix();
      })
  }, [showResolved, supportCases]);

  const renderedTable = useMemo(() => (
    <AdminTable<SupportCase & { name: string }>
      title=""
      label="Case"
      id_key="case_id"
      name_key="case_id"
      columns={getColumns(displayedCases, themeColor)}
      options={{
        enableNestedDataAccess: '.',
        tableBodyMaxHeight: '41em',
      }}
      data={displayedCases}
      refresh={() => refetchHomeCases()}
      makeNew={() => ({
        issue_summary: '',
     })}
      makeViewPath={
        (row) => `/homes/${U(property?.property_id || '')}/support/${U(row.case_id)}`
      }
      onSave={handleSaveCase}
      editPermission="admin-only"
      // we're not letting anyone to delete cases directly at this time,
      // these are intentionally non-existing permissions
      deletePermission="delete-cases"
    >
      <AppTextField
        name="issue_summary"
        label="Issue Summary"
        type="text"
        multiline
        fullWidth
        rows={4}
      />
    </AdminTable>
  ), [displayedCases, handleSaveCase, property?.property_id, refetchHomeCases, themeColor]);

  return (
    <Box
      component="div"
      sx={{
        maxWidth: 'unset',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
      }}
    >
      {isLoading && <Backdrop open><CircularProgress /></Backdrop>}
      {!isLoading && renderedTable}
    </Box>
  );
}

export default HomeSupportCases;