import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import AdminTable from "@features/Common/AdminTable";
import ConfirmDialog from "@features/Common/ConfirmDialog";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import {
  useDeletePropertyMutation,
  useRefreshPropertyTokenMutation,
  useUpdatePropertyMutation
} from "@features/home/api";
import HomeFields from "@features/home/components/HomeFields";
import { setProperty } from "@features/home/slice";
import { PROPERTY_SCHEMA, Property } from '@features/home/types';
import { useReleaseHomeMutation } from "@features/all-homeowners/api";
import { DashboardView, DashboardViewType } from "@features/settings/types";
import { ApiErrorResponse, SnackType } from "@lib/types";
import { U, getErrorMessage } from "@lib/utils";
import { Box, CircularProgress, Typography } from "@mui/material";
import { MUIDataTableOptions } from "mui-datatables";
import { useSnackbar } from "notistack";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useUnregisterMutation } from "../../home/regApi";
import { useHomesQuery } from "../api";
import HomeActions from "./HomeActions";
import HomeCard from "./HomeCard";
import ViewControls from "./ViewControls";
import { getColumns } from "./columns";


/** Display All RIoT homes for selected tenant as a table */
const HomeList: FC = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const currentUser = useAppSelector((state: RootState) => state.awsUser);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const homes = useAppSelector((state: RootState) => state.homes);
  const preferenceState = useAppSelector((state: RootState) => state.preferences);

  const preferences = useMemo(() => preferenceState.preferences, [preferenceState.preferences]);
  const viewPreference = useMemo(() => preferences?.views?.[DashboardView.homes], [preferences?.views]);
  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const [displayedHomes, setDisplayedHomes] = useState<Property[]>(homes);
  const [viewType, setViewType] = useState<DashboardViewType>(viewPreference || DashboardViewType.cards);
  const [filter, setFilter] = useState<string[]>([]);

  /** applies search filter */
  useEffect(() => {
    if (!filter.length) {
      setDisplayedHomes(homes);
    } else {
      setDisplayedHomes(
        homes?.filter((pp: Property) => filter.includes(pp.property_id))
      );
    }
  }, [filter, homes]);


  /** Selected home to unregister */
  const [propertyToUnregister, setPropertyToUnregister] = useState<Property | null>(null);
  /** Selected home to sell */
  const [homeToSell, setHomeToSell] = useState<Property|null>(null);

  const {
    refetch,
  } = useHomesQuery({
    userTenantId: userTenant?.tenant_id || '',
    tenantId: currentTenant?.tenant_id || '',
  }, {
    skip: (!userTenant.tenant_id || !currentTenant?.tenant_id),
  });

  const [
    updateProperty,
  ] = useUpdatePropertyMutation();

  const [
    deleteProperty,
  ] = useDeletePropertyMutation();

  const [
    unregisterProperty,
  ] = useUnregisterMutation();

  const [
    refreshPropertyToken,
  ] = useRefreshPropertyTokenMutation();


  const handleUnregisterProperty = useCallback((property: Property) => {
    /* Obtain property token */
    refreshPropertyToken({
      propertyId: property.property_id,
      userTenantId: currentTenant.tenant_id!,
    }).then((refreshResult: any) => {
      if ('error' in refreshResult) throw refreshResult.error;
      /* Unregister property */
      return unregisterProperty({
        propertyId: property.property_id,
        propertyToken: refreshResult.data.token_value,
      });
    }).then((result: any) => {
      if ('error' in result) {
        enqueueSnackbar("Failed to un-register property:", {
          key: "property-unregister-error",
          content: (
            <ExpandableSnack
              id="contact-error"
              message={"Couldn't unregister property:"}
              variant={SnackType.error}
              detail={getErrorMessage(result.error)}
            />),
        });
      } else {
        enqueueSnackbar("Property un-registered successfully", {
          variant: "success",
        });
      }

      setPropertyToUnregister(null);
    });
  }, [enqueueSnackbar, refreshPropertyToken, currentTenant.tenant_id, unregisterProperty]);

  const [
    releaseHome,
  ] = useReleaseHomeMutation();

  const handleReleaseHome = useCallback(async (home: Property) => {
    const updatedHome = await releaseHome({
      userTenantId: currentTenant?.tenant_id || '',
      propertyId: home.property_id
    });

    const errorDetails = (updatedHome as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't sell home:", {
        key: "sellhome-error",
        content: (
          <ExpandableSnack
            id="sellhome-error"
            message={"Couldn't sell home:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("Home is sold:", {
        key: "sellhome-success",
        content: (
          <ExpandableSnack
            id="sellhome-success"
            message={"Home is sold:"}
            variant={SnackType.success}
            detail={[
              "Congratulations!",
              "You have initiated sale of home to its assigned homeowner",
              "RIoT Technology Admin will provide the homeowner with necessary access.",
            ].join(' ')}
          />),
      });
      setHomeToSell(null);
    }

  }, [enqueueSnackbar, releaseHome, currentTenant?.tenant_id]);


  const handleHomeAction = useCallback((home: Property, actionType: string) => {
    dispatch(setProperty(home))
    switch (actionType) {
      case 'transfer':
        setHomeToSell(home);
        break;
      case 'unregister':
        setPropertyToUnregister(home);
        break;
      default:
        navigate(`/homes/new/${U(home.property_id)}/${actionType}`);
    }
  }, [dispatch, navigate]);

  /** Displays RIoT Homes under currentTenant */
  const renderedHomes = useMemo(() => {
    if (userTenant.checkingTenant === true) return <CircularProgress />;

    /** Displays RIoT Homes as CARDS */
    const renderCards = () => {

      return (
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          {displayedHomes
            ?.map((p) => <HomeCard key={p.property_id} home={p} callback={handleHomeAction} />)}
        </Box>
      );
    }

    /** Displays RIoT Homes as a TABLE*/
    const renderTable = () => {
      const options: MUIDataTableOptions = {
        enableNestedDataAccess: '.',
      };

      const handleUpdateProperty = async (pp: Partial<Property>) => {
        const updatedProperty = await updateProperty({
          userTenantId: currentTenant?.tenant_id || '',
          propertyId: pp?.property_id || '',
          body: pp,
        });


        const errorDetails = (updatedProperty as ApiErrorResponse)?.error;

        if (errorDetails) {
          enqueueSnackbar("Couldn't update home:", {
            key: "home-error",
            content: (
              <ExpandableSnack
                id="home-error"
                message={"Couldn't update home:"}
                variant={SnackType.error}
                detail={getErrorMessage(errorDetails)}
              />),
          });

          return null;
        } else {
          enqueueSnackbar("Updated home", {
            variant: "success",
          });

          return (updatedProperty as any).data;
        }
      }

      const handleDeleteProperty = async (pp: Partial<Property>) => {
        const deletedHome = await deleteProperty({
          userTenantId: currentTenant?.tenant_id || '',
          propertyId: pp?.property_id || '',
        });

        const errorDetails = (deletedHome as ApiErrorResponse).error;

        if (errorDetails) {
          enqueueSnackbar("Couldn't delete home:", {
            key: "home-error",
            content: (
              <ExpandableSnack
                id="home-error"
                message={"Couldn't delete home:"}
                variant={SnackType.error}
                detail={getErrorMessage(errorDetails)}
              />),
          });
          return false;
        } else {
          enqueueSnackbar("Deleted home", {
            variant: "success",
          });
          return true;
        }
      }

      return (
        <AdminTable<Property>
          title="RIoT Homes"
          label="RIoT Home"
          id_key="property_id"
          name_key="name"
          columns={getColumns(displayedHomes, currentTenant.tenant_type, navigate)}
          options={options}
          data={displayedHomes}
          FormikProps={{
            validationSchema: PROPERTY_SCHEMA
          }}
          makeViewPath={
            (row) => `/homes/${U(row.property_id)}`
          }
          customActions={(row) =>
            <HomeActions
              home={row}
              callback={handleHomeAction}
            />
          }
          onSave={handleUpdateProperty}
          onDelete={handleDeleteProperty}
          refresh={() => refetch()}
          editPermission="write-properties"
          deletePermission="delete-properties"
        >
          <HomeFields />
        </AdminTable>
      );
    }

    return (
      <Box component="div">
        <ViewControls
          onTypeChange={setViewType}
          homes={homes}
          onNameFilterChange={setFilter}
        />
        {viewType === DashboardViewType.cards && renderCards()}
        {viewType === DashboardViewType.list && renderTable()}
      </Box>
    );
  }, [currentTenant?.tenant_id, currentTenant.tenant_type, deleteProperty, displayedHomes, enqueueSnackbar, handleHomeAction, homes, navigate, refetch, updateProperty, userTenant.checkingTenant, viewType])

  const lname = useMemo(() => currentUser.attributes?.family_name || '', [currentUser.attributes?.family_name]);
  const fname = useMemo(() => currentUser.attributes?.given_name || '', [currentUser.attributes?.given_name]);

  /** MAIN RENDER */
  return (
    <Box
      component="div"
      sx={{
        margin: '1em',
        padding: '2em',
      }}
    >
      <Typography variant="h4">Welcome, {fname} {lname}</Typography>
      <Typography variant="h5">RIoT Homes of {currentTenant?.name}</Typography>
      <Box
        component="div"
        sx={{
          mt: '3em',
        }}
      >
        {renderedHomes}
        <ConfirmDialog
          title={`Unregister ${propertyToUnregister?.name || 'home'}`}
          open={!!propertyToUnregister}
          onClose={() => setPropertyToUnregister(null)}
          onConfirm={() => handleUnregisterProperty(propertyToUnregister!)}
          initialValues={{}}
        >
          <Typography variant="body1">
            Are you sure you want to unregister this home?
            Doing so will cause the Gateway to lose its connection to the cloud.
          </Typography>
        </ConfirmDialog>
        <ConfirmDialog
          title={`Initiate sale of ${homeToSell?.name || 'home'}`}
          open={!!homeToSell}
          onClose={() => setHomeToSell(null)}
          onConfirm={() => handleReleaseHome(homeToSell!)}
          initialValues={{}}
        >
          <Typography variant="body1">
            Are you sure you want to sell this home to the currently assigned homeowner?
            Doing so will transfer home to RIoT Technology Admin
            indicating that homeowner is ready to assume ownership.
          </Typography>
        </ConfirmDialog>
      </Box>
    </Box>
  );
}

export default HomeList;
