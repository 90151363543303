import { PropertyManifestEntry } from "@features/home-manifest/types";
import { AttentionLevel as EventAttentionLevel } from "@features/home-notifications/types";
import { Sensor } from "@features/plan-manifest/types";
import { Category, Measure } from "@lib/labels";

/** Generic enum for numerical compararison operations */
export enum Comparator {
  EQUAL_TO = 'equal_to',
  NOT_EQUAL_TO = 'not_equal_to',
  GREATER_THAN = 'greater_than',
  GREATER_OR_EQUAL_TO = 'greater_or_equal_to',
  LESS_THAN = 'less_than',
  LESS_OR_EQUAL_TO = 'less_or_equal_to',
}

/** Cron expression components represented as an object */
export interface CronExpr {
  second?: string;
  minute: string;
  hour: string;
  dayOfMonth: string;
  month: string;
  dayOfWeek: string;
}

/** Enumerated type for Device State Types */
export enum DeviceStateType {
  BINARY_SWITCH = 'binary_switch',
  ANALOG_SWITCH = 'analog_switch',
}

export enum TimeOfDay {
  SUNRISE = 'sunrise',
  SUNSET = 'sunset',
}

/** Enumerated type representing possible logical operations */
export enum LogicConditionOperator {
  AND = 'and',
  OR = 'or',
  NOR = 'nor',
  XOR = 'xor',
  NAND = 'nand',
}

/** Type for possible device state values */
export type DeviceStateValue<StateType extends DeviceStateType = DeviceStateType> = (
    StateType extends DeviceStateType.BINARY_SWITCH ? BinarySwitchStateType
      : StateType extends DeviceStateType.ANALOG_SWITCH ? AnalogSwitchStateType
        : (AnalogSwitchStateType | BinarySwitchStateType)
  /* NOTE: Add another ternary branch if a new device state value type is added */
);

export type BinarySwitchStateType = boolean;
export type AnalogSwitchStateType = number;

/* Device State models */
export interface DeviceStateBase<ST extends DeviceStateType = DeviceStateType> {
  stateType?: ST;
  currentValue?: DeviceStateValue<ST>;
  entityId?: string;
}

export interface DeviceStateCreate<ST extends DeviceStateType = DeviceStateType>
  extends DeviceStateBase<ST> {
  stateType: ST;
  currentValue: DeviceStateValue<ST>;
  entityId: string;
}

export interface DeviceState<ST extends DeviceStateType = DeviceStateType>
  extends DeviceStateCreate<ST> {

}

/* Device capabilities models */
export interface DeviceCapability {
  stateType: DeviceStateType;
  entityId: string;
  manifestEntry?: PropertyManifestEntry;
  sensor?: Sensor;
  canWrite: boolean;
}

/** Base model for scene template definition */
export interface SceneTemplateBase {
  name?: string;
  scene_template_id?: string;
  enabled?: boolean;
  condition?: SceneTemplateCondition | null;
  action?: SceneTemplateAction | null;

  /** Optional amount of time that must elapse before scene can fire again (milliseconds) */
  cooldown_time?: number | null;
}

export interface SceneTemplateCreate extends SceneTemplateBase {
  name: string;
  enabled: boolean;
  condition: SceneTemplateCondition;
  action: SceneTemplateAction;
}

export interface SceneTemplateUpdate extends SceneTemplateBase {
}

export interface SceneTemplate extends SceneTemplateCreate {
  scene_template_id: string;
}

/** Enumerated type for all Scene Template condition types */
export enum SceneTemplateConditionType {
  /** Condition for a device state change */
  STATE = 'state',
  /** Condition for a Cron expression */
  CRON = 'cron',
  /** Condition representing a logical combination of other conditions */
  LOGIC = 'logic',
  /** Time of day condition, e.g. sunrise/sunset */
  TIME_OF_DAY = 'time_of_day',
}

/** Base model for scene template definition */
export interface SceneTemplateBase {
  name?: string;
  scene_template_id?: string;
  enabled?: boolean;
  condition?: SceneTemplateCondition | null;
  action?: SceneTemplateAction | null;

  /** Optional amount of time that must elapse before scene can fire again (milliseconds) */
  cooldown_time?: number | null;
}

export interface SceneTemplateCreate extends SceneTemplateBase {
  name: string;
  enabled: boolean;
}

export interface SceneTemplateUpdate extends SceneTemplateBase {
}

export interface SceneTemplate extends SceneTemplateCreate {
  scene_template_id: string;
}

/** Base model for Scene Template condition */
export interface SceneTemplateConditionBase {
  type: SceneTemplateConditionType;
}

/** Mode for device state conditions */
export enum DeviceStateConditionMode {
  /** If any devices match, condition is met */
  ANY = 'any',
  /** If all devices match, condition is met */
  ALL = 'all',

  // TODO: aggregate value matching for numeric sensors
}

/** Possible criteria used to match device capabilities,
 * for both read (triggering based on state) and write (appplying states).
 */
export interface SceneTemplateDeviceCriteria {
  /** Case-insensitive list of property area names (meets criteria if any match) */
  areas?: string[] | null;
  /** Category to match */
  category?: Category | null;
  /** Measure to match */
  measure?: Measure | null;
}

/** Scene Template condition for a device state change */
export interface SceneTemplateDeviceStateCondition
  <T extends DeviceStateType = any> extends SceneTemplateConditionBase {
  type: SceneTemplateConditionType.STATE;
  /** Criteria to use to find devices for condition */
  device_criteria: SceneTemplateDeviceCriteria;
  /** All devices must have the same type */
  state_type: T;
  /** State value that will meet condition (using comparator if applicable) */
  state_value: DeviceStateValue<T>;
  /** Mode to use for handling values (e.g. all must match or only one must match) */
  mode: DeviceStateConditionMode;
  /**
   * Comparator for desired device state. Equality is used if not specified.
   * Does not affect binary switches.
   */
  state_comparator?: Comparator;
}

/** Scene Template condition for a Cron expression (time/date condition) */
export interface SceneTemplateCronCondition extends SceneTemplateConditionBase {
  type: SceneTemplateConditionType.CRON;
  cronExpr: CronExpr;
}

/** Scene Template trigger condition representing a logical combination of other conditions */
export interface SceneTemplateLogicCondition extends SceneTemplateConditionBase {
  type: SceneTemplateConditionType.LOGIC;
  operands: SceneTemplateCondition[];
  operator: LogicConditionOperator;
}

export interface SceneTemplateTimeOfDayCondition extends SceneTemplateConditionBase {
  type: SceneTemplateConditionType.TIME_OF_DAY;
  time_of_day: TimeOfDay;

  /**
   * Applicable days of week. If not specified, condition applies to every day.
   * Sunday=0, ..., Friday=6
   * Same values as [Date.getDay\(\)](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getDay).
   */
  days_of_week?: number[];
}

/** Generic Scene Template condition type */
export type SceneTemplateCondition = SceneTemplateDeviceStateCondition
| SceneTemplateCronCondition
| SceneTemplateLogicCondition
| SceneTemplateTimeOfDayCondition;

/** Enumerated type for all Scene Template action types */
export enum SceneTemplateActionType {
  /** Action to apply a single device state */
  APPLY_DEVICE_STATE = 'apply_device_state',

  /** Action to perform another action after a specified delay */
  DELAYED_ACTION = 'delayed_action',

  /** Action to create a notification */
  NOTIFICATION = 'notification',

  /** Action to perform multiple actions */
  MULTIPLE = 'multiple',
}

/** Base model for Scene Template action */
export interface SceneTemplateActionBase {
  type: SceneTemplateActionType;
}

/** Scene Template action to apply a device state */
export interface SceneTemplateDeviceStateAction
<T extends DeviceStateType = any> extends SceneTemplateActionBase {
  type: SceneTemplateActionType.APPLY_DEVICE_STATE;
  /** Criteria to use to find devices to apply state of */
  device_criteria: SceneTemplateDeviceCriteria;
  /** All devices must have the same type */
  state_type: T;
  /** Value to set */
  state_value: DeviceStateValue<T>;
}

/** Scene Template action to perform another action with a delay */
export interface SceneTemplateDelayedAction
<T extends SceneTemplateAction = SceneTemplateAction> extends SceneTemplateActionBase {
  type: SceneTemplateActionType.DELAYED_ACTION;
  delayed_action: T;
  delay_ms: number;
}

/** Scene template action to perform multiple other actions */
export interface SceneTemplateMultipleAction extends SceneTemplateActionBase {
  type: SceneTemplateActionType.MULTIPLE;
  actions: SceneTemplateAction[];

  /** Whether to perform the actions concurrently
    * or one after the other (in order specified).
    * Default true.
    */
  concurrent?: boolean;

  /** Whether to stop executing actions if an error
   * is encounted with any (non-concurrent mode only) */
  stop_on_error?: boolean;
}

/** Scene Template action to send a notification */
export interface SceneTemplateNotificationAction extends SceneTemplateActionBase {
  type: SceneTemplateActionType.NOTIFICATION;

  property_area?: string;
  attention_level: EventAttentionLevel;
  message: string;
}

/** Generic Scene Template action type */
export type SceneTemplateAction = SceneTemplateDeviceStateAction
| SceneTemplateNotificationAction
| SceneTemplateDelayedAction
| SceneTemplateMultipleAction;
