import { ColorResult } from "react-color";
import { ColorType } from "./types";

export const formatRGBColor = (c: ColorResult) => `rgb(${c.rgb.r}, ${c.rgb.g}, ${c.rgb.b})`;

export const formatRGBAColor = (c: ColorResult) => `rgba(${c.rgb.r}, ${c.rgb.g}, ${c.rgb.b}, ${c.rgb.a})`;

export const formatHEXColor = (c: ColorResult) => c.hex;


/** Formats color received from ChromePicker to given type  */
export const formatColorResult = (c: ColorResult, type: ColorType) => {
  switch (type) {
    case 'hex':
      return formatHEXColor(c);
    case 'rgb':
      return formatRGBColor(c);
    case 'rgba':
    default:
      return formatRGBAColor(c);
  }
};


type LogoDetails = {
  id: string;
  logo: string;
};

const defaultLogoDetails = {
  id: '',
  logo: '',
} as LogoDetails;


export const parseLogoUrl = (url: string): LogoDetails => {
  /** https://{bucketUrl}/{folderId}/{fileName} */
  const regex = /(https:\/\/)([a-zA-Z0-9-.]+)\/([a-zA-Z0-9]{26})\/([a-zA-Z0-9-_.]+)/gm;

  const m = regex.exec(url);
  // url didn't match the pattern
  if (!m || !m[0]) return defaultLogoDetails;
  /**
   * m[0]: part of url that matches the pattern (in our case this should be the whole url)
   * m[1]: https://
   * m[2]: bucketurl (currently same as config.ASSETS_BUCKET_URL)
   * m[3]: public_key - unique per builder, this identifies the folder where logo is stored
   * m[4]: name of the logo file (logo.png is default)
   */

  if (m.length !== 5) return defaultLogoDetails;
  return {
    id: m[3],
    logo: m[4]
  } as LogoDetails;
};
