import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import { Amplify } from 'aws-amplify';

const configs = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITYPOOL_ID,
    mandatorySignIn: true,
    oauth: {
      scope:['openid'],
      redirectSignIn: '',
      redirectSignOut: '',
      responseType: 'token'
    },
  },
  API: {
    endpoints: [
      {
        name: "api",
        endpoint: `${process.env.REACT_APP_API_ENDPOINT}rest/v1/`,
      },
    ],

    // AppSync configuration for Preferences
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_region: process.env.REACT_APP_AWS_REGION,
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_PREFS_ENDPOINT + 'graphql',
  },
};

const config = {
  API_ENDPOINT: `${process.env.REACT_APP_API_ENDPOINT}rest/v1/`,
  REG_API_ENDPOINT: process.env.REACT_APP_REG_API_ENDPOINT || 'localhost:3000/',
  REG_API_KEY: process.env.REACT_APP_REG_API_KEY || '',
  DASHBOARD_URL: process.env.REACT_APP_DASHBOARD_URL || window.location.origin,
  ASSETS_BUCKET_URL: process.env.REACT_APP_PUBLIC_BUCKET_URL,
}

// Configure Amplify
Auth.configure(configs.Auth);
API.configure(configs.API);

Amplify.configure(configs);

export default config;