import { Stack, Typography } from "@mui/material";
import { ReactElement } from "react";


type Props = {
  icon: ReactElement;
  value: string;
  onClick?: () => void;
}

const IconWithValue = ({ icon, value, onClick}: Props) => {
  return (
      <Stack
        direction="row"
        alignItems="center"
        alignContent="start"
        spacing={0.6}
        onClick={onClick}
        sx={{
          height: '2.2em',
        }}
      >
        {icon}
        <Typography
          variant="caption"
          sx={{
            fontSize: '0.8em',
            fontWeight: 700,
          }}
        >
          {value}
        </Typography>
      </Stack>
  );
}

export default IconWithValue;