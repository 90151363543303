import { riotApi } from "@features/aws-user/api";
import { PropertyManifestEntry } from "@features/home-manifest/types";
import { Device } from "@features/home/types";
import { HTTPMethodType } from "@lib/types";
import { U } from "@lib/utils";


/** API slice for the provisioning endpoints */
export const provisioningApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      /** Start inclusion mode for the property */
      beginInclusionMode: builder.mutation<any, {
        userTenantId: string,
        propertyId: string,
      }>({
        query: ({ userTenantId, propertyId }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/inclusion`,
          method: HTTPMethodType.PATCH,
        })
      }),
      /** Start exclusion mode for the property */
      beginExclusionMode: builder.mutation<any, {
        userTenantId: string,
        propertyId: string,
      }>({
        query: ({ userTenantId, propertyId }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/exclusion`,
          method: HTTPMethodType.PATCH,
        })
      }),
      /** Updates device */
      updateDevice: builder.mutation<any, {
        userTenantId: string,
        propertyId: string,
        deviceId: string,
        body: Partial<Device>,
      }>({
        query: ({ userTenantId, propertyId, deviceId, body }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/devices/${U(deviceId)}`,
          method: HTTPMethodType.PATCH,
          body,
        })
      }),
      /** Updates Manifest Entries */
      updatePropertyManifestEntry: builder.mutation<any, {
        userTenantId: string,
        propertyId: string,
        entryId: string,
        body: Partial<PropertyManifestEntry>
      }>({
        query: ({ userTenantId, propertyId, entryId, body }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/manifestentries/${U(entryId)}`,
          method: HTTPMethodType.PATCH,
          body,
        })
      }),
      /** Configure device */
      configureDevice: builder.mutation<any, {
        userTenantId: string,
        propertyId: string,
        entryId: string,
      }>({
        query: ({ userTenantId, propertyId, entryId }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/manifestentries/${U(entryId)}/configure`,
          method: HTTPMethodType.PATCH,
        })
      }),
      /** Start z-wave network heal for the property */
      beginNetworkHeal: builder.mutation<void, {
        userTenantId: string,
        propertyId: string,
      }>({
        query: ({ userTenantId, propertyId }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/heal`,
          method: HTTPMethodType.PATCH,
        })
      }),
      /** Create z-wave smartstart provisioning entry for a device */
      createSmartstartEntry: builder.mutation<void, {
        userTenantId: string,
        propertyId: string,
        qrCodeString: string,
        deviceName: string,
        propertyArea: string,
      }>({
        query: ({ userTenantId, propertyId, deviceName, propertyArea, qrCodeString }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/smartstart`,
          method: HTTPMethodType.PATCH,
          body: {
            qr_code_string: qrCodeString,
            device_name: deviceName,
            property_area: propertyArea,
          }
        })
      }),
    };
  }
});

export const {
  useBeginInclusionModeMutation,
  useBeginExclusionModeMutation,
  useCreateSmartstartEntryMutation,
  useUpdateDeviceMutation,
  useUpdatePropertyManifestEntryMutation,
  useConfigureDeviceMutation,
  useBeginNetworkHealMutation,
} = provisioningApi;
