import { PropertyManifestEntryDevice } from "@features/home-manifest/types";
import { Device } from "@features/home/types";
import { Sensor } from "@features/plan-manifest/types";
import { Category, Measure, Unit, ValueType } from "@lib/labels";
import { DeviceStateType, HassDomain, ReadingPair } from "./types";


export const getStockImageByRoom = (room: string) => {
  const baseUrl = '/home-areas/';

  switch (room.toLowerCase().replaceAll(/[\W_]+/g, '')) {
    case 'laundry':
    case 'laundryroom':
      return `${baseUrl}laundryroom.webp`;
    case 'front':
    case 'frontdoor':
      return `${baseUrl}frontdoor.webp`;
    case 'firstfloor':
    case 'foyer':
      return `${baseUrl}foyer.webp`;
    case 'greatroom':
      return `${baseUrl}greatroom.webp`;
    case 'secondfloor':
    case 'flexspace':
      return `${baseUrl}flex.webp`;
    case 'bathroom':
    case 'ownerbath':
    case 'ownersbath':
    case 'masterbath':
      return `${baseUrl}ownerbath.webp`;
    case 'bedroom0':
    case 'ownersuite':
    case 'ownerssuite':
    case 'masterbedroom':
    case 'mastersuite':
      return `${baseUrl}ownersuite.webp`;
    case 'bathroom1':
      return `${baseUrl}bathroom1.webp`;
    case 'bedroom1':
      return `${baseUrl}bedroom1.webp`;
    case 'bathroom2':
      return `${baseUrl}bathroom2.webp`;
    case 'bedroom2':
      return `${baseUrl}bedroom2.webp`;
    case 'bedroom3':
      return `${baseUrl}bedroom3.webp`;
    case 'backporch':
    case 'back':
      return `${baseUrl}backporch.webp`;
    case 'roofdeck':
      return `${baseUrl}roofdeck.webp`;
    case 'diningroom':
      return `${baseUrl}diningroom.webp`;
    case 'halfbath':
      return `${baseUrl}halfbath.webp`;
    case 'kitchen':
      return `${baseUrl}kitchen.webp`;
    case 'utilitycloset':
    case 'hvac':
    case 'externalplumbing':
    case 'main':
    case 'garage':
    case 'waterheater':
    case 'foundation':
    default:
      return `${baseUrl}blankroom.jpg`;
  }
}


export const getDeviceName = (device: PropertyManifestEntryDevice) => {
  if (device.friendly_name) return device.friendly_name;
  return `${device.model_number || 'Unknown model'} by ${device.manufacturer || 'Unknown manufacturer'}`;
}

export const TEST_READING = {
  device: {
    data: {
      entity_id: "sensor.multisensor_6_temperature",
      device_id: "0ed2cb80f8d7fbfcfd9ff4924edc8c74",
      value: 0,
      value_updated: "2022-11-15T20:43:40Z"
    },
    id: '123',
  } as Device,
  sensor: {
    friendly_name: "Temperature Sensor",
    sensor_id: "Temperature Sensor",
    description: "",
    entity_id: "sensor.multisensor_6_temperature",
    units: Unit.fahrenheit,
    sensor_category: Category.air,
    value_type: ValueType.numeric,
    measure_name: Measure.temperature,
    order: 0
  } as Sensor,
  deviceName: 'Test Device',
  area: 'Test area',
} as ReadingPair;


export const getDeviceCapabilities = (entityId: string) => {
  const domain = entityId.split('.')?.[0] || '';

  switch (domain) {
    default:
      return [];
    case HassDomain.SWITCH:
      return [{
        entityId: entityId,
        stateType: DeviceStateType.BINARY_SWITCH,
      }];
    case HassDomain.CLIMATE:
      return [{
        entityId: entityId,
        stateType: DeviceStateType.THERMOSTAT_TEMPERATURE,
      }];
  }
}

export const THERMOSTAT_RANGE = {
  MIN: 40,
  MAX: 99,
}