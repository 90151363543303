import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { ChartType } from "@features/settings/types";
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useCallback, useMemo } from 'react';
import { RIOT_BLUE } from 'theme';

type Props = {
  initial: ChartType;
  onChange: (m: ChartType) => void;
}

const ChartTypes = ({ initial, onChange }: Props) => {
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant?.builder_color || RIOT_BLUE, [currentTenant?.builder_color]);

  const handleChange = useCallback((e: SelectChangeEvent<ChartType>) => {
    onChange(e.target.value as ChartType);
  }, [onChange]);

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <Select
        value={initial}
        onChange={handleChange}
        sx={{
          '&:focus': {
            border: `1px solid ${themeColor}`,
          }
        }}
      >
        {
          [ChartType.line, ChartType.scatter, ChartType.bar]
            .map((t, idx) => <MenuItem key={`chart-type-option-${idx}`} value={t}>{t}</MenuItem>)
        }
      </Select>
    </FormControl>
  );
}

export default ChartTypes;