import { createSlice } from "@reduxjs/toolkit";
import { Document } from "./types";

const initialState: Document[] = [];

/** State slice for the tenant selected to use */
export const documentsReducer = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setDocuments: (state: Document[], action: any) => {
      if (action.payload) {
        state.splice(0, state.length);
        action.payload.forEach((dd: Document) => state.push(dd));
      }
    },
  }
});

export default documentsReducer.reducer;

export const {
  setDocuments,
} = documentsReducer.actions;