import AppTextField from "@features/home/components/AppTextField";
import { FormGroup } from "@mui/material";

type Props = {
  /** address attribute on form */
  attributeName?: string;
  /** width of the street address formgroup */
  width?: string;
  /** margins of the street address formgroup */
  margin?: string;
  /** indicates whether or not county should be included */
  isCounty?: boolean;
  /** indicates whether the address is required on form */
  isRequired?: boolean;
  /** indicates whether to render fields as editable or readOnly */
  readOnly?: boolean;
}

/* Less granular address
  * City | County | State | Country
  */
const TownAddress = ({
  attributeName = 'physical_address',
  width = '100%',
  margin = '0',
  isCounty = false,
  isRequired = false,
  readOnly,
}: Props) => (
  <FormGroup row sx={{
    width,
    margin,
    justifyContent: 'space-between'
  }}>
    <FormGroup row sx={{
      width: '49%',
      justifyContent: 'space-between'
    }}>
      <AppTextField
        name={`${attributeName}.city`}
        label="City"
        type="text"
        margin="dense"
        size="small"
        required={isRequired}
        sx={{
          width: isCounty ? '49%' : '100%'
        }}
        disabled={readOnly}
      />
      {
        isCounty &&
        <AppTextField
          name={`${attributeName}.county`}
          label="County"
          type="text"
          margin="dense"
          size="small"
          sx={{
            width: '49%'
          }}
          disabled={readOnly}
          />
      }
    </FormGroup>
    <FormGroup row sx={{
      width: '49%',
      justifyContent: 'space-between'
    }}>
      <AppTextField
        name={`${attributeName}.state`}
        label="State"
        type="text"
        margin="dense"
        size="small"
        required={isRequired}
        sx={{
          width: '49%',
        }}
        disabled={readOnly}
      />
      {
        <AppTextField
          name={`${attributeName}.zip_postal_code`}
          label="ZIP"
          type="text"
          margin="dense"
          size="small"
          required={isRequired}
          sx={{
            width: '49%',
          }}
          disabled={readOnly}
          />
      }
    </FormGroup>
  </FormGroup>
);

export default TownAddress;