import { PropertyManifestEntry } from "@features/home-manifest/types";
import { ManifestDevice } from "@features/plan-manifest/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

type Props = {
  entry: PropertyManifestEntry;
  isOpen: boolean;
  onClose: () => void;
}

const ResetingModal = ({
  isOpen,
  onClose,
  entry,
}: Props) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>Factory Reset {(entry.device as ManifestDevice).friendly_name}</DialogTitle>
      <DialogContent>
        <Typography>
          Perform the following actions in order to factory reset the device.
        </Typography>
        <Typography component="p" sx={{ m: 3 }} fontStyle="italic">
          {(entry.device as ManifestDevice).reset_instructions || 'This device has no provided factory reset instructions.'}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ResetingModal;
