import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { AttentionLevel, NotificationStatus, UserNotification } from "@features/home-notifications/types";
import { Alert, AlertTitle, Box } from "@mui/material";
import { useMemo } from "react";

type Props = {

};

const NeighborhoodNotifSummary: React.FC<Props> = function NeighborhoodNotifSummary(props: Props) {
  const neighborhood = useAppSelector((state: RootState) => state.neighborhood.neighborhood);
  const health = useAppSelector((state: RootState) => state.neighborhood.health);
  const userNotificationState = useAppSelector((state: RootState) => state.notifications);
  const userNotifications = useMemo(() => userNotificationState?.notifications || [], [userNotificationState?.notifications]);

  const importantNotifsByHome = useMemo<Record<string, UserNotification[] | undefined>>(() => {
    return userNotifications.reduce((partialNotifsByHome, notif) => {
      if (notif.property_id
        && [AttentionLevel.urgent, AttentionLevel.warning].includes(notif.attention_level)
        && ![NotificationStatus.snooze, NotificationStatus.dismissed].includes(notif.lifecycle_action)) {
          return {
            ...partialNotifsByHome,
            [notif.property_id]: [
              ...(partialNotifsByHome[notif.property_id] ?? []),
              notif,
            ]
          };
      }

      return partialNotifsByHome;
    }, {} as Record<string, UserNotification[]>);
  }, [userNotifications]);

  const unhealthyHomeCount: number = useMemo(() => Object.keys(health || {})
    .filter(home => (
      !health?.[home]?.all_healthy
    ))?.length, [health]);
  const unhealthyCheckCount: number = useMemo(
      () => Object.values(health || {})
        .reduce((sum, homeHealth) => (sum + Object.values(homeHealth.checks).filter(isHealthy => isHealthy === false).length), 0),
      [health],
    );

  const importantNotifCount = useMemo(() => Math.max(...Object.values(importantNotifsByHome).map(n => n?.length ?? 0)), [importantNotifsByHome]);
  const unreadNotifCount = useMemo(() => Math.max(...Object.values(importantNotifsByHome)
    .map(n => n?.filter(e => e.lifecycle.some(l => l.action === NotificationStatus.read)).length ?? 0)
  ), [importantNotifsByHome]);

  return <Box component="div" sx={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
    {
      (importantNotifCount > 0 || unhealthyHomeCount > 0)
        ? <Alert color={unreadNotifCount > 0 ? 'error' : 'warning'}>
          {
            [
              ...(importantNotifCount > 0 ? [
                importantNotifCount === 1
                ? <><strong>{importantNotifCount}</strong> notification requires attention</>
                : <><strong>{importantNotifCount}</strong> notifications require attention</>,
              ] : []),
              ...(unhealthyHomeCount > 0 ? [
                <><strong>{unhealthyCheckCount}</strong> health checks are failing across {unhealthyHomeCount} homes</>
              ] : []),
            ].reduce((a, v, idx) => idx === 0 ? v : <>{a}<span>, and </span>{v}</>, <></>)
          }
          .
        </Alert>
        : <Alert variant="standard" color="success">
          No homes {neighborhood?.name ? `in ${neighborhood.name}` : ''} require any action at this time.
        </Alert>
    }
  </Box>
};

export default NeighborhoodNotifSummary;
