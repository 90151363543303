import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { TenantType } from "@features/userTenant/types";
import { ApiErrorResponse, SnackType } from '@lib/types';
import { formatDate, getErrorMessage } from "@lib/utils";
import SomeUnhealthyIcon from '@mui/icons-material/AssignmentLate';
import AllHealthyIcon from '@mui/icons-material/AssignmentTurnedIn';
import HealthUnavailableIcon from '@mui/icons-material/ContentPasteOff';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Accordion, AccordionDetails, AccordionSummary, Badge, Box, IconButton, Link as MuiLink, List, ListItem, ListItemIcon, ListItemText, Stack, Tooltip, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { MouseEvent, useCallback, useMemo, useState } from "react";
import { useNeighborhoodQuery, useRemoveHomeMutation } from "../api";
import { NeighborhoodBase } from "../types";
import HealthCheckItem from "./HealthCheckItem";
import { HealthCheckType } from "@features/home-health/types";
import { IconCheckCircleOutline, IconNotificationsNone } from "@aws-amplify/ui-react";
import { Property } from "@features/home/types";
import { AttentionLevel, NotificationStatus, UserNotification } from "@features/home-notifications/types";
import Warning from "@mui/icons-material/Warning";
import Notifications from "@mui/icons-material/Notifications";
import { Link } from "react-router-dom";

type Props = {
  handleShowHealthDialog: (neighborhoodId: string|null) => void
}

const NeighborhoodHomesList = ({ handleShowHealthDialog }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const neighborhood = useAppSelector((state: RootState) => state.neighborhood.neighborhood);
  const health = useAppSelector((state: RootState) => state.neighborhood.health);
  const homes = useAppSelector((state: RootState) => state.homes);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const userNotificationState = useAppSelector((state: RootState) => state.notifications);
  const userNotifications = useMemo(() => userNotificationState?.notifications || [], [userNotificationState?.notifications]);

  const neighborhoodHomes = useMemo(() => homes.filter(h => neighborhood?.property_ids?.includes(h.property_id)), [homes, neighborhood?.property_ids]);
  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const canEdit = useMemo(() => [TenantType.Builder, TenantType.System].includes(currentTenant?.tenant_type), [currentTenant?.tenant_type]);

  const [focusedPropertyId, setFocusedProprtyId] = useState<string | undefined>();
  const [saving, setSaving] = useState<boolean>(false);

  const [ removeHome ] = useRemoveHomeMutation();

  const {
    refetch: refetchNeighborhood,
  } = useNeighborhoodQuery({
    userTenantId: currentTenant?.tenant_id || '',
    neighborhoodId: neighborhood?.neighborhood_id || '',
  }, {
    skip: !currentTenant?.tenant_id || !neighborhood?.neighborhood_id,
  });

  const importantNotifsByHome = useMemo<Record<string, UserNotification[] | undefined>>(() => {
    return userNotifications.reduce((partialNotifsByHome, notif) => {
      if (notif.property_id
        && [AttentionLevel.urgent, AttentionLevel.warning].includes(notif.attention_level)
        && ![NotificationStatus.snooze, NotificationStatus.dismissed].includes(notif.lifecycle_action)) {
          return {
            ...partialNotifsByHome,
            [notif.property_id]: [
              ...(partialNotifsByHome[notif.property_id] ?? []),
              notif,
            ]
          };
      }

      return partialNotifsByHome;
    }, {} as Record<string, UserNotification[]>);
  }, [userNotifications]);

  const handleRemoveHome = useCallback(async (homeId: string) => {
    if (!canEdit) return;

    setSaving(true);

    const updated = await removeHome({
      userTenantId: currentTenant?.tenant_id || '',
      neighborhoodId: neighborhood?.neighborhood_id || '',
      propertyId: homeId,
    });

    const errorDetails = (updated as ApiErrorResponse)?.error;
    if (errorDetails) {
      enqueueSnackbar("Couldn't update neighborhood:", {
        key: "neighborhood-error",
        content: (
          <ExpandableSnack
            id="neighborhood-error"
            message={"Couldn't update neighborhood:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
      setSaving(false);
    } else {
      enqueueSnackbar("Updated neighborhood", {
        variant: "success",
      });
      refetchNeighborhood();
      setSaving(false);
    }
  }, [canEdit, currentTenant?.tenant_id, enqueueSnackbar, neighborhood?.neighborhood_id, refetchNeighborhood, removeHome]);

  const homeStatus = useCallback((homeId: string) => {
    const status = health?.[homeId];
    
    if (!status) {
      return (
        <Tooltip title="Health status is not available" placement="top" arrow>
          <span>
            <IconButton disabled>
              <HealthUnavailableIcon color="disabled" />
            </IconButton>
          </span>
        </Tooltip>
      );
    }
    const onClick = (e: MouseEvent) => {
      e.stopPropagation();
      handleShowHealthDialog(homeId);
    };

    const importantNotifCount = importantNotifsByHome[homeId]?.length ?? 0;
    const unreadNotifCount = importantNotifsByHome[homeId]?.filter(e => e.lifecycle.some(l => l.action === NotificationStatus.read)).length ?? 0;
    const unhealthyCheckCount = Object.values(status.checks ?? {}).reduce((unhealthyCount, check) => check !== false ? unhealthyCount : unhealthyCount + 1, 0);
  
    return (
      <>
      {
        status.all_healthy
          ? <Tooltip title="All healthchecks were successful. Click to see details." placement="top" arrow>
              <span>
                <IconButton onClick={onClick} disabled={saving || !status}>
                  <AllHealthyIcon color="success" />
                </IconButton>
              </span>
            </Tooltip>
          : <Tooltip title="Some healthchecks failed. Click to see details." placement="top" arrow>
              <Badge badgeContent={unhealthyCheckCount} color="error">
                <IconButton onClick={onClick} disabled={saving || !status}>
                  <SomeUnhealthyIcon color="error" />
                </IconButton>
              </Badge>
            </Tooltip>
      }
      {
        importantNotifCount === 0
        ? <Tooltip title="No urgent notifications for this home." placement="top" arrow>
            <span>
              <IconButton disabled={saving || !importantNotifsByHome}>
                <Notifications color="disabled" />
              </IconButton>
            </span>
          </Tooltip>
        : <Tooltip title="There are important notifications for this home." placement="top" arrow>
            <Badge badgeContent={importantNotifCount} color="error">
              <IconButton disabled={saving || !importantNotifsByHome}>
                <Notifications color="error" />
              </IconButton>
            </Badge>
          </Tooltip>
      }
      </>
    )
  }, [health, importantNotifsByHome, saving, handleShowHealthDialog])

  const renderItem = useCallback((home: Property) => {
    const homeHealth = health?.[home.property_id];

    const unhealthyCheckCount: number = Object.values(homeHealth?.checks ?? {}).filter(isHealthy => isHealthy === false).length;
    const totalCheckCount: number = Object.values(homeHealth?.checks ?? {}).length;
    const importantNotifCount = importantNotifsByHome[home.property_id]?.length ?? 0;
    const unreadNotifCount = importantNotifsByHome[home.property_id]?.filter(e => e.lifecycle.some(l => l.action === NotificationStatus.read)).length ?? 0
  
    return <Accordion
      expanded={!!focusedPropertyId && home.property_id === focusedPropertyId}
      onChange={(e, isExpanded) => { setFocusedProprtyId(isExpanded ? home.property_id : undefined) }}
      TransitionProps={{ unmountOnExit: true }}
      key={`home-status-${home.property_id}`}
    >
      <AccordionSummary>
        <Typography sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'space-between' }}>
          <Tooltip title="Remove home from the neighborhood" placement="top" arrow>
            <span>
              <IconButton onClick={(e) => {
                e.stopPropagation();
                handleRemoveHome(home.property_id);
              }} disabled={saving}>
                <DeleteOutlineIcon color="warning" />
              </IconButton>
            </span>
          </Tooltip>
          {home.name || [home.physical_address.address_line_1, home.physical_address.address_line_2].join('-')}
          <Box component="span" sx={{ ml: 'auto' }}>
            {
              homeStatus(home.property_id)
            }
          </Box>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="row">
          <Box component="div" sx={{ flex: '1 1 0', textAlign: 'center' }}>
            {
              health?.[home.property_id]?.all_healthy
                ? <>
                  <Tooltip title="All health checks passed.">
                    <IconCheckCircleOutline color="green" fontSize="large" />
                  </Tooltip>
                  <Typography sx={{ textAlign: 'center' }}>All health checks passed.</Typography>
                </>
                : <>
                  <List
                    dense
                    disablePadding
                    subheader={
                      <span>
                        <MuiLink component={Link} to={`/homes/${home.property_id}/health`}>
                          Health
                        </MuiLink>
                        {' '}
                        updated
                        {' '}
                        {
                          !!health?.[home.property_id]?.updated ? formatDate(health[home.property_id].updated).fromNow() : undefined
                        }
                      </span>
                    }
                  >
                    {
                      Object.entries(health?.[home.property_id]?.checks ?? {})
                        .map(([ch, isHealthy]) => isHealthy === false ? (
                          <HealthCheckItem
                            key={`home-check-${home.property_id}-${ch}`}
                            health={health}
                            homeId={home.property_id}
                            healthCheckType={ch as HealthCheckType}
                          />
                      ) : <></>)
                    }
                  </List>
                </>
            }
          </Box>
          <Box component="div" sx={{ flex: '1 1 0', textAlign: 'center', display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>
            {
              importantNotifCount > 0
                ?  <>
                  <List
                    dense
                    disablePadding
                    sx={{ width: '100%' }}
                    subheader={
                      <span>
                        {importantNotifCount}
                        {' '}
                        important
                        {' '}
                        <MuiLink component={Link} to={`/homes/${home.property_id}/notifications`}>
                          notification{importantNotifCount > 1 ? 's' : ''}
                        </MuiLink>
                      </span>
                    }
                  >
                  {
                    (importantNotifsByHome[home.property_id] ?? [])
                      .map((homeImportantNotif) => homeImportantNotif.lifecycle_action !== NotificationStatus.dismissed ? (
                        <ListItem
                          key={`home-notif-${home.property_id}-${homeImportantNotif.user_notification_id}`}
                        >
                          <ListItemText primary={homeImportantNotif.message} secondary={formatDate(homeImportantNotif.datetime).fromNow()}>
                            {homeImportantNotif.message}
                          </ListItemText>
                          <ListItemIcon>
                            <Warning color={homeImportantNotif.attention_level === AttentionLevel.urgent ? 'error' : 'warning'} />
                          </ListItemIcon>
                        </ListItem>
                    ) : <></>)
                  }
                  </List>
                </>
                : <>
                  <Tooltip title="No urgent notifications">
                    <IconCheckCircleOutline color="green" fontSize="large" />
                  </Tooltip>
                  <Typography sx={{ textAlign: 'center' }}>No urgent notifications.</Typography>
                </>
            }
          </Box>
        </Stack>
      </AccordionDetails>
    </Accordion>;
  }, [focusedPropertyId, handleRemoveHome, health, homeStatus, importantNotifsByHome, saving]);

  return (
    <Box component="div" sx={{
      overflowY: 'scroll',
      height: '30em',
    }}>
      {
        neighborhoodHomes?.map(renderItem)
      }
    </Box>
  );
}

export default NeighborhoodHomesList;
