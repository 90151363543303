import { Neighborhood } from "@features/neighborhood/types";
import { createSlice } from "@reduxjs/toolkit";

const initialState: Neighborhood[] = [];

export const neighborhoodsReducer = createSlice({
  name: 'neighborhoods',
  initialState,
  reducers: {
    setNeighborhoods: (state: Neighborhood[], action: any) => {
      if (action.payload) {
        state.splice(0, state.length);
        action.payload.forEach((cc: Neighborhood) => state.push(cc));
      } else {
        state.splice(0, state.length);
      }
    },
  }
});

export default neighborhoodsReducer.reducer;

export const {
  setNeighborhoods,
} = neighborhoodsReducer.actions;