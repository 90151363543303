import HomesProvider from '@features/all-homes/components/HomesProvider';
import PlansProvider from '@features/all-plans/components/PlansProvider';
import ChartPreferencesCard from '@features/chart-settings/components/ChartPreferencesCard';
import { AnalyticsType } from '@features/settings/types';
import RefreshIcon from '@mui/icons-material/Refresh';
import TuneIcon from '@mui/icons-material/Tune';
import { LoadingButton } from '@mui/lab';
import { ButtonGroup, FormGroup, IconButton, Popover, Stack, Tooltip } from "@mui/material";
import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { getChartDimensions } from '../utils';

type Props = {
  onRefreshClick: () => void;
  chartType: AnalyticsType;
}

const ViewControls = ({ onRefreshClick, chartType }: Props) => {

  const [ready, setReady] = useState<boolean>(true);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    onRefreshClick();
    const timeout = setTimeout(() => setReady(true), 2000);
    return () => clearTimeout(timeout);
  }, [onRefreshClick, ready]);

  const handleOpenPreferences = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  return (
    <FormGroup
      sx={{
        width:  getChartDimensions().width + 50,
        background: "white",
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        p: 1,
        mb: 2,
      }}
    >
      <Stack direction="row" spacing={1}>
        <Tooltip title="Refresh all data from cloud" placement='top'>
          <span>
            <ButtonGroup>
              <LoadingButton
                loading={!ready}
                onClick={() => setReady(false)}
              >
                <RefreshIcon />
              </LoadingButton>
            </ButtonGroup>
          </span>
        </Tooltip>
      </Stack>
      <Tooltip title="Chart details" placement='top'>
        <span>
          <IconButton onClick={handleOpenPreferences}>
            <TuneIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Popover
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Stack alignItems='center'>
          {
            ready &&
            <PlansProvider>
              <HomesProvider>
                <ChartPreferencesCard
                  chartType={chartType}
                />
              </HomesProvider>
            </PlansProvider>

          }
        </Stack>
      </Popover>
    </FormGroup>
  );
}

export default ViewControls;