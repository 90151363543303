import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { TYPICAL_RESIDENTIAL_VOLTAGE, UtilityManagement } from "@features/all-utility-groups/types";
import AppTextField from "@features/home/components/AppTextField";
import { Property, PROPERTY_SCHEMA } from "@features/home/types";
import { TenantType } from "@features/userTenant/types";
import { findUnitsLabel, Unit } from "@lib/labels";
import { LoadingButton } from "@mui/lab";
import { Button, Divider, FormGroup, Stack, Typography } from "@mui/material";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useMemo, useState } from "react";

type Props = {
  onSave: (pp: Property, helpers: FormikHelpers<Property>)  => void;
}

const PowerPanelManagement = ({ onSave }: Props) => {
  const home = useAppSelector((state: RootState) => state.property as Property);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const maxPower = useMemo(() => home.utility_management?.energy_management.svc_max_power.amps, [home.utility_management?.energy_management.svc_max_power.amps]);

  const allowedToEdit = useMemo(() => (
    [ TenantType.System, TenantType.Builder ].includes(currentTenant.tenant_type)
   && currentTenant.tenant_id === home.builder_id
  ), [currentTenant.tenant_id, currentTenant.tenant_type, home.builder_id]);

  const [editMode, setEditMode] = useState<boolean>(false);

  const handleCancel = (f: FormikProps<Property>) => {
    setEditMode(false);
    f.resetForm();
  }

  const handleSave = (f: FormikProps<Property>) => {
    f.submitForm();
    setEditMode(false);
  }

  const handleChangeServicePower = (e: any, f: FormikProps<Property>) => {
    const util = f.values.utility_management || {} as UtilityManagement;
    if (!util.energy_management) {
      util.energy_management = {
        svc_max_power: {
          volts: TYPICAL_RESIDENTIAL_VOLTAGE,
          amps: Number(e.target.value),
        }
      }
      f.setFieldValue('utility_management', util);
    } else {
      f.handleChange(e);
    }
  }


  return (
    <Formik
      initialValues={home}
      validationSchema={PROPERTY_SCHEMA}
      onSubmit={onSave}
    >
      {f => (
        <form>
          <Stack sx={{ width: '100%' }}>
            {
              !editMode
                ? <Stack spacing={1}>
                    <Typography variant="h5">Service Panel</Typography>
                    <Divider />
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography variant="h6">Maximum Service Power</Typography>
                      <Typography variant="body1">{maxPower}{findUnitsLabel(Unit.amps)}</Typography>
                    </Stack>
                  </Stack>
                : <AppTextField
                    name="utility_management.energy_management.svc_max_power.amps"
                    label={`Max Service Power (${findUnitsLabel(Unit.amps)})`}
                    onChange={(e: any) => handleChangeServicePower(e, f)}
                    type="number"
                    margin="dense"
                    required
                  />
            }
            <FormGroup row sx={{ p: 1, justifyContent: 'center' }}>
              {
                !editMode
                  ? <Button
                      variant="contained"
                      onClick={() => setEditMode(true)}
                      disabled={!allowedToEdit}
                    >
                      Edit
                    </Button>
                  : (<>
                      <Button type="reset" onClick={() => handleCancel(f)}>
                        Cancel
                      </Button>
                      <LoadingButton
                        loading={f.isSubmitting}
                        onClick={() => handleSave(f)}
                        disabled={!f.dirty}
                        variant="contained"
                        color="primary"
                      >
                        Save
                      </LoadingButton>
                    </>)
              }
            </FormGroup>

          </Stack>
        </form>
      )}
    </Formik>
  );
}

export default PowerPanelManagement;