import { createSlice } from "@reduxjs/toolkit";
import { SupportCase } from "../support-case/types";


const initialState: SupportCase[] = [];

export const supportCasesReducer = createSlice({
  name: 'supportCases',
  initialState,
  reducers: {
    setSupportCases: (state: SupportCase[], action: any) => {
      if (action.payload) {
        state.splice(0, state.length);
        action.payload.forEach((c: SupportCase) => state.push(c));
      } else {
        state.splice(0, state.length);
      }
    },
  }
});

export default supportCasesReducer.reducer;

export const {
  setSupportCases,
} = supportCasesReducer.actions;
