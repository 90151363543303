import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import ExpandableSnack from '@features/Common/ExpandableSnack';
import { useGetPropertyQuery } from '@features/home/api';
import AppTextField from '@features/home/components/AppTextField';
import { Property } from '@features/home/types';
import { Tenant, TenantCreate, TenantType, TenantUpdate } from '@features/userTenant/types';
import { ApiErrorResponse, SnackType } from '@lib/types';
import { getErrorMessage } from "@lib/utils";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Button, Divider, FormGroup, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { Formik, FormikContextType, FormikHelpers } from 'formik';
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAssignHomeownerMutation, useCreateHomeownerMutation, useFindHomeownerByEmailQuery, useHomeownerQuery, useUpdateHomeownerMutation } from '../../all-homeowners/api';

const Homeowner = () => {
  const { enqueueSnackbar } = useSnackbar();

  const property: Property = useAppSelector((state: RootState) => state.property);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  /**
   * User is allowed to change the homeowner or edit details
   * of the home's homeowner only if they are RIoT-T Admin
   * or if their tenant owns the home
   */
  const isAllowedToSetHomeowner = useMemo(() => {
    if (currentTenant?.tenant_type === TenantType.System) return true;
    return property?.owner_id === currentTenant?.tenant_id;
  }, [currentTenant.tenant_id, currentTenant?.tenant_type, property?.owner_id]);

  // mode to change/assign new homeowner
  const [isChanging, setIsChanging] = useState<boolean>(false);
  // mode to edit details of existing homeowner
  const [isEditing, setIsEditing] = useState<boolean>(false);
  // mode to create a new homeowner
  const [isCreatingHomeowner, setIsCreatingHomeowner] = useState<boolean>(false);
  const [isSettingHomeowner, setIsSettingHomeowner] = useState<boolean>(false);
  const [emailToCheck, setEmailToCheck] = useState<string>('');

  const {
    refetch: refetchHome,
    isFetching: isFetchingHome,
  } = useGetPropertyQuery({
    tenantId: currentTenant?.tenant_id || '',
    propertyId: property?.property_id || '',
  }, {
    skip: !currentTenant?.tenant_id || !property?.property_id,
  });

  const {
    currentData: homeowner,
    refetch: refetchHomeowner,
    isFetching: isFetchingHomeowner,
  } = useHomeownerQuery({
    userTenantId: currentTenant?.tenant_id || '',
    homeownerId: property?.homeowner_id || '',
  }, {
    skip: !currentTenant?.tenant_id || !property?.homeowner_id,
  });

  const {
    currentData: matchingTenantId,
    isFetching: isSearchingForTenant,
  } = useFindHomeownerByEmailQuery({
    userTenantId: currentTenant?.tenant_id || '',
    email: emailToCheck,
  }, {
    skip: !currentTenant?.tenant_id,
  });

  useEffect(() => {
    if (!matchingTenantId) {
      // when matching tenant is not found, creating homeowner mode is on
      setIsCreatingHomeowner(true);
    }
  }, [matchingTenantId]);

  /**
   * Clears visible form fields
   * @param f - Formik object
   */
  const clearForm = (f: FormikContextType<Tenant>) => {
    f.setFieldValue('email', '');
    f.setFieldValue('name', '');
    f.setFieldValue('phone_number', '');
  }

  /**
   * Sets email to check for existing tenants against
   * @param f - Formik object
   */
  const handleFindMatch = (f: FormikContextType<Tenant>) => {
    setEmailToCheck((f.values as any)?.email);
    setIsSettingHomeowner(true);
  }

  /**
   * Resets Email form to check for another email address
   * @param f - Formik object
   */
  const handleCheckOther = (f: FormikContextType<Tenant>) => {
    setIsSettingHomeowner(false);
    setEmailToCheck('');
    clearForm(f);
  }

  const [
    assignHomeowner,
  ] = useAssignHomeownerMutation();

  /**
   * Assigns existing Homeowner Tenant as Home's homeowner
   * @param f - Formik object
   */
  const handleAssignExisting = useCallback(async (f: FormikContextType<Tenant>) => {
    f.setSubmitting(true);
    const updatedHome = await assignHomeowner({
      userTenantId: currentTenant?.tenant_id || '',
      propertyId: property?.property_id || '',
      homeownerId: matchingTenantId || '',
    });

    f.setSubmitting(false);

    const errorDetails = (updatedHome as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't assign homeowner:", {
        key: "assignhomeowner-error",
        content: (
          <ExpandableSnack
            id="assignhomeowner-error"
            message={"Couldn't assign homeowner:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });

    } else {
      enqueueSnackbar("Assigned Homeowner", {
        variant: "success",
      });

      refetchHome();
      setIsChanging(false);
      setIsSettingHomeowner(false);
      refetchHomeowner();
    }

  }, [assignHomeowner, currentTenant?.tenant_id, enqueueSnackbar, matchingTenantId, property?.property_id, refetchHome, refetchHomeowner]);

  const [
    createHomeowner,
  ] = useCreateHomeownerMutation();

  const [
    updateHomeowner,
  ] = useUpdateHomeownerMutation();

  /**
   * Creates a new homeowner and assigns it as Home's homeowner
   * @param values - form values as Tenant object
   * @param helpers - Formik helpers
   */
  const handleCreateHomeowner = useCallback(async (values: Tenant, helpers: FormikHelpers<Tenant>) => {
    helpers.setSubmitting(true);

    // Editing existing homeowner
    if (isEditing) {
      // only pass visible editable fields
      const updates = {
        name: values.name,
        phone_number: values.phone_number,
      } as TenantUpdate;

      const updated = await updateHomeowner({
        userTenantId: currentTenant?.tenant_id || '',
        tenantId: homeowner?.tenant_id || '',
        body: updates,
      });

      const errorDetails = (updated as ApiErrorResponse)?.error;

      if (errorDetails) {
        enqueueSnackbar("Couldn't update homeowner details:", {
          key: "updatehomeowner-error",
          content: (
            <ExpandableSnack
              id="updatehomeowner-error"
              message={"Couldn't update homeowner details:"}
              variant={SnackType.error}
              detail={getErrorMessage(errorDetails)}
            />),
        });

      } else {
        enqueueSnackbar("Updated homeowner details", {
          variant: "success",
        });

        helpers.resetForm();
        setIsEditing(false);
        setIsSettingHomeowner(false);
        refetchHomeowner();
      }


    } else {
      // Creating a new homeowner
      const newHomeowner: TenantCreate = {
        description: `Homeowner for ${property.name}`,
        email: values.email,
        name: values.name,
        phone_number: values.phone_number,
        tenant_type: TenantType.Homeowner,
        property_ids: [
          property.property_id,
        ],
        physical_address: {
          ...property.physical_address,
        },
      };

      const created = await createHomeowner({
        userTenantId: currentTenant?.tenant_id || '',
        body: newHomeowner,
      });

      const errorDetails = (created as ApiErrorResponse)?.error;

      if (errorDetails) {
        enqueueSnackbar(`Couldn't create homeowner for ${property.name}:`, {
          key: "createhomeowner-error",
          content: (
            <ExpandableSnack
              id="createhomeowner-error"
              message={`Couldn't create homeowner for ${property.name}:`}
              variant={SnackType.error}
              detail={getErrorMessage(errorDetails)}
            />),
        });

      } else {
        enqueueSnackbar(`Created homeowner for ${property.name}:`, {
          variant: "success",
        });

        helpers.setSubmitting(false);
        helpers.resetForm();

        setIsChanging(false);
        setIsCreatingHomeowner(false);
        setIsSettingHomeowner(false);

        refetchHomeowner();
      }
    }
  }, [createHomeowner, currentTenant?.tenant_id, enqueueSnackbar, homeowner?.tenant_id, isEditing, property.name, property.physical_address, property.property_id, refetchHomeowner, updateHomeowner]);

  /**
   * Resets form and cancels out of changing homeowner
   * @param f - formik form object
   */
  const handleCancelChange = useCallback((f: FormikContextType<Tenant>) => {
    f.resetForm();
    if (isEditing) setIsEditing(false);
    if (isChanging) setIsChanging(false);
    if (isCreatingHomeowner) setIsCreatingHomeowner(false);
    if (isSettingHomeowner) setIsSettingHomeowner(false);
  }, [isChanging, isCreatingHomeowner, isEditing, isSettingHomeowner]);

  /**
   * Prevents form submission on Enter/Return
   * @param e - keypress event
   */
  const preventDefault = (e: any) => {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
  }

  /**
   * Clears form and sets change homeowner mode
   * @param f - Formik object
   */
  const handleEnterChangeMode = (f: FormikContextType<Tenant>) => {
    clearForm(f);
    setIsChanging(true);
  }

  /**
   * Enters edit homeowner details mode
   * @param f - Formik object
   */
  const handleEnterEditMode = (f: FormikContextType<Tenant>) => {
    setIsEditing(true);
  }

  const isLoading = useMemo(() => isFetchingHomeowner || !property?.property_id, [isFetchingHomeowner, property?.property_id]);

  const showChangeButton = useMemo(() => !isFetchingHomeowner && isAllowedToSetHomeowner && !isChanging && !isEditing, [isAllowedToSetHomeowner, isChanging, isEditing, isFetchingHomeowner]);
  const showEditButton = useMemo(() => showChangeButton && !isEditing && !!homeowner?.email, [homeowner?.email, isEditing, showChangeButton]);
  const showCancelButton = useMemo(() => isChanging || isEditing, [isChanging, isEditing]);
  const showCreateHomeownerForm = useMemo(() => isSettingHomeowner && emailToCheck && !matchingTenantId, [emailToCheck, isSettingHomeowner, matchingTenantId]);
  const showEditHomeownerForm = useMemo(() => showCreateHomeownerForm || isEditing, [isEditing, showCreateHomeownerForm]);
  const showAssignExistingForm = useMemo(() => isSettingHomeowner && emailToCheck && matchingTenantId, [emailToCheck, isSettingHomeowner, matchingTenantId]);

  return (
    <Stack
      spacing={1}
      sx={{ minWidth: '50%' }}
    >
      <Typography variant='h5'>Homeowner</Typography>
      <Divider />
      {
        isLoading && <Skeleton variant="text" />
      }
      {
        !isLoading &&
        <Formik
          initialValues={{
            email: '',
            name: '',
            phone_number: '',
            ...homeowner,
          } as Tenant}
          onSubmit={handleCreateHomeowner}
        >
          {f => (
            <form>
              {/* Email Address field */}
              {
                !isChanging &&
                <>
                {
                  homeowner?.email
                    ? <Typography variant="h6">{homeowner?.email}</Typography>
                    : <Typography variant="subtitle1">Homeowner hasn't been assigned yet</Typography>
                }
                </>
              }
              <FormGroup
                  sx={{
                    width: '100%',
                    padding: 0,
                    alignItems: 'center'
                  }}
                >
                  {
                    isChanging &&
                    <AppTextField
                      name="email"
                      label="Email Address"
                      type="text"
                      margin="dense"
                      size="small"
                      fullWidth
                      onKeyPress={preventDefault}
                      required
                      disabled={!isChanging || !isAllowedToSetHomeowner}
                    />
                  }
                  <FormGroup row sx={{ p: 1 }}>
                  {/* Button to cancel out of Change/Assign mode */}
                  {
                    showCancelButton &&
                    <Button
                      color="inherit"
                      onClick={() => handleCancelChange(f)}
                      variant="outlined"
                      sx={{ m: '0 5px' }}
                    >Cancel</Button>
                  }
                  {/* Button to enter Change/Assign homeowner mode */}
                  {
                    showChangeButton &&
                    <Tooltip placement="top" arrow title="Assign a different Homeowner">
                      <span>
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={() => handleEnterChangeMode(f)}
                          sx={{ m: '0 5px' }}
                        >
                          {homeowner?.email ? "Change": "Assign"}
                        </Button>
                      </span>
                    </Tooltip>

                  }
                  {/* Button to edit existing homeowner */}
                  {
                    showEditButton &&
                    <Tooltip placement="top" arrow title="Edit Homeowner Info">
                      <span>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleEnterEditMode(f)}
                          sx={{ m: '0 5px' }}
                        >
                          Edit
                        </Button>
                      </span>
                    </Tooltip>
                  }
                  {/* Icon Button to Check if email is in use */}
                  {
                    isAllowedToSetHomeowner && isChanging && f.dirty &&
                    <Tooltip title="Check if the email is in use" placement='top'>
                      <Button
                        startIcon={<PersonSearchIcon />}
                        onClick={() => handleFindMatch(f)}
                        disabled={isSearchingForTenant}
                        variant="contained"
                        sx={{ m: '0 5px' }}
                      >
                        Check
                      </Button>
                    </Tooltip>
                  }
                </FormGroup>
              </FormGroup>
              {/* Assign existing homeowner */}
              {
                !isSearchingForTenant && showAssignExistingForm &&
                <Alert
                  severity="success"
                  title='Homeowner already exists'
                  action={
                    <>
                      <Button
                        color="inherit"
                        type="reset"
                        onClick={() => handleCheckOther(f)}>
                        Check other
                      </Button>
                      <LoadingButton
                        color="primary"
                        onClick={() => handleAssignExisting(f)}
                        loading={false}
                      >Assign</LoadingButton>

                    </>
                  }
                >
                  Looks like this Homeowner already exists. Would you like to assign them to this Home?
                </Alert>
              }
              {/* No matching Homeowner found */}
              {
                !isSearchingForTenant && showCreateHomeownerForm &&
                <Alert
                  severity="success"
                  title='No matches found'
                >
                  Provided email is not in use. Let's create a new Homeowner.
                  The address will be populated from {property.name}
                </Alert>
              }
              {/* Create a new Homeowner/Edit existing Homeowner */}
              {
                !isSearchingForTenant && showEditHomeownerForm &&
                <FormGroup
                  sx={{
                    width: '100%',
                    padding: 0,
                    alignItems: 'center'
                  }}
                >
                  <FormGroup row sx={{ width: 'inherit' }}>
                    <AppTextField
                      name="name"
                      label="Name"
                      type="text"
                      margin="dense"
                      size="small"
                      onKeyPress={preventDefault}
                      required
                      sx={{
                        width: '60%',
                        mr: '1%',
                      }}
                    />
                    <AppTextField
                      name="phone_number"
                      label="Phone Number"
                      onKeyPress={preventDefault}
                      type="text"
                      margin="dense"
                      size="small"
                      required
                      sx={{
                        width: '39%'
                      }}
                    />
                  </FormGroup>
                  <FormGroup row sx={{ p: 1 }}>
                    <Tooltip title={`${isEditing ? 'Update' : 'Create'} a new Homeowner tenant`} placement='top'>
                      <span>
                        <LoadingButton
                          loading={f.isSubmitting}
                          onClick={() => f.submitForm()}
                          disabled={!f.dirty || !f.isValid || f.isSubmitting}
                          variant="contained"
                          color="primary"
                        >
                          {isEditing ? 'Update' : 'Create'}
                        </LoadingButton>
                      </span>
                    </Tooltip>
                  </FormGroup>
                </FormGroup>
              }
            </form>
          )}
        </Formik>
      }
    </Stack>
  );
}

export default Homeowner;