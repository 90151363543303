import { riotApi } from "@features/aws-user/api";
import { HTTPMethodType } from '@lib/types';
import { U } from "@lib/utils";
import { DeviceState } from "./types";

export const deviceStatesApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      updateDeviceState: builder.mutation<string, {
        userTenantId: string,
        propertyId: string,
        deviceState: DeviceState,
      }>({
        query: ({ userTenantId, propertyId, deviceState }) => ({
          url: `/tenant/${U(userTenantId)}/properties/${U(propertyId)}/states`,
          method: HTTPMethodType.PUT,
          body: deviceState,
        }),
      }),
    };
  },
});

export const {
  useUpdateDeviceStateMutation,
} = deviceStatesApi;