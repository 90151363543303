import Header from '@features/Common/Header';
import NeighborhoodViewFooter from '@features/Common/NeighborhoodViewFooter';
import HomesProvider from '@features/all-homes/components/HomesProvider';
import NotificationProvider from '@features/home-notifications/components/NotificationProvider';
import NeighborhoodHealthProvider from '@features/neighborhood/components/NeighborhoodHealthProvider';
import NeighborhoodProvider from '@features/neighborhood/components/NeighborhoodProvider';
import { Box } from '@mui/material';
import { Outlet } from "react-router-dom";

const NeighborhoodViews = () => {
  return (
    <Box
      component="div"
    >
      <NeighborhoodProvider>
        <NotificationProvider>
          <NeighborhoodHealthProvider>
            <HomesProvider>
              <Header />
              <Outlet />
              <NeighborhoodViewFooter />
            </HomesProvider>
          </NeighborhoodHealthProvider>
        </NotificationProvider>
      </NeighborhoodProvider>
    </Box>
  );
}

export default NeighborhoodViews;