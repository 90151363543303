import { useAppDispatch, useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { pollingInterval } from "@features/tenant-selector/types";
import { FC, ReactElement, useEffect, useMemo } from "react";
import { usePlansQuery } from "../api";
import { setTenantPlans } from "../slice";

/** Wrapper for plan list under selected tenant */
const PlansProvider: FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const {
    currentData: plans,
  } = usePlansQuery({
    userTenantId: userTenant?.tenant_id || '',
    tenantId: currentTenant?.tenant_id || '',
  }, {
    skip: !userTenant?.tenant_id,
    pollingInterval,
  });

  useEffect(() => {
    dispatch(setTenantPlans(plans));
  }, [plans, dispatch]);

  return children as ReactElement;
}

export default PlansProvider;
