import { getHeaderStyles, MIN_CELL, renderLite } from "@features/Common/tables";
import { formatDate } from "@lib/utils";
import { Box } from "@mui/material";
import { NotificationRow } from "../types";
import Actions from './Actions';
import AttentionLevelColumn from "./AttentionLevelColumn";
import DeviceColumn from "./DeviceColumn";
import UserActions from "./UserActionsColumn";


export const getColumns = (
  data: NotificationRow[],
  themeColor: string,
  onDismiss: (id: string) => void
) => [
  {
    name: 'actions',
    label: 'Actions',
    options: {
      searchable: false,
      sort: false,
      filter: false,
      download: false,
      setCellProps: () => ({
        style: {
          width: 0,
          whiteSpace: 'nowrap',
          align:"center",
        },
      }),
      setCellHeaderProps: () => getHeaderStyles(themeColor),
      customBodyRenderLite: renderLite(
        data as NotificationRow[],
        (row: NotificationRow) => <Actions notification={row} onDismiss={onDismiss} />
      ),
    }
  },
  {
    name: 'attention_level',
    label: ' ',
    options: {
      searchable: false,
      setCellProps: () => ({
        style: {
          width: 0,
          whiteSpace: 'nowrap',
          align:"center",
        },
      }),
      setCellHeaderProps: () => getHeaderStyles(themeColor),
      customBodyRenderLite: renderLite(
        data as NotificationRow[],
        (row: NotificationRow) => <AttentionLevelColumn notification={row} />
      ),
    },
  },
  {
    name: 'entry.device.friendly_name',
    label: 'Device',
    options: {
      setCellHeaderProps: () => getHeaderStyles(themeColor),
      customBodyRenderLite: renderLite(
        data as NotificationRow[],
        (row: NotificationRow) => <DeviceColumn notification={row} />
      ),
    },
  },
  {
    name: 'property_area',
    label: 'Home area',
    options: {
      setCellProps: MIN_CELL,
      setCellHeaderProps: () => getHeaderStyles(themeColor),
    }
  },
  {
    name: 'category',
    label: 'Category',
    options: {
      setCellProps: MIN_CELL,
      setCellHeaderProps: () => getHeaderStyles(themeColor),
    }
  },
  {
    name: 'message',
    label: 'Message',
    options: {
      setCellHeaderProps: () => ({
        ...getHeaderStyles(themeColor),
        style: {
          ...getHeaderStyles(themeColor).style,
          width: '20em',
        }
      }),
      customBodyRenderLite: renderLite(
        data as NotificationRow[],
        (row: NotificationRow) => (
          <Box
            component="div"
            sx={{
              minWidth: '20em',
              maxHeight: '8em',
              overflowY: 'auto',
            }}>
            {row.message}
          </Box>
        )
      ),
    }
  },
  {
    name: 'datetime',
    label: 'Date',
    options: {
      setCellProps: MIN_CELL,
      setCellHeaderProps: () => getHeaderStyles(themeColor),
      customBodyRenderLite: (idx: number) => !!data[idx].datetime ? formatDate(data[idx].datetime)?.fromNow() || '' : '',
    }
  },
  {
    name: 'actions',
    label: 'User Actions',
    searchable: false,
    sort: false,
    filter: false,
    download: false,
    options: {
      setCellProps: MIN_CELL,
      setCellHeaderProps: () => getHeaderStyles(themeColor),
      customBodyRenderLite: renderLite(
        data as NotificationRow[],
        (row: NotificationRow) => <UserActions notification={row} />
      ),
    }
  }
];