import AppTextField from "@features/home/components/AppTextField";
import { FormGroup } from "@mui/material";


type Props = {
  /** address attribute on form */
  attributeName?: string;
  /** width of the street address formgroup */
  width?: string;
  /** margins of the street address formgroup */
  margin?: string;
  /** indicates whether to render fields editable or read only */
  readOnly?: boolean;
}

/**
 * Street
 * Line 2
 * */
const StreetAddress = ({
  attributeName = 'physical_address',
  width = '49%',
  margin = '0 1% 0 0',
  readOnly,
}: Props) => (
  <FormGroup sx={{
    width,
    margin,
  }}>
    <AppTextField
      name={`${attributeName}.address_line_1`}
      label="Street Address"
      type="text"
      margin="dense"
      size="small"
      fullWidth
      disabled={readOnly}
    />
    <AppTextField
      name={`${attributeName}.address_line_2`}
      label="Address - Continued"
      type="text"
      margin="dense"
      size="small"
      fullWidth
      disabled={readOnly}
    />
  </FormGroup>
);

export default StreetAddress;