import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import HomesProvider from "@features/all-homes/components/HomesProvider";
import { EnergyUsage, UtilGroup } from "@features/all-utility-groups/types";
import PieChartIcon from '@mui/icons-material/PieChart';
import { Dialog, DialogContent, DialogTitle, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import EnergyReading from "./EnergyReading";
import HomesConsumption from "./HomesConsumption";


const CurrentConsumption = () => {
  const utilGroup = useAppSelector((state: RootState) => state.utilityGroup.utilityGroup as UtilGroup);

  const consumption = useMemo(() => (
    utilGroup.utility_realtime?.find(ur => !!(ur as EnergyUsage).grid_consumption) as EnergyUsage
  )?.grid_consumption, [utilGroup.utility_realtime]);

  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">Current Consumption</Typography>
        <Stack direction="row" alignItems="center">
          <Tooltip placement="top" arrow title="see details">
            <span>
              <IconButton onClick={() => setOpen(true)}>
                <PieChartIcon color="info" />
              </IconButton>
            </span>
          </Tooltip>
          <EnergyReading variant="body1" energy={consumption!} />
        </Stack>
      </Stack>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Home Energy Consumption</DialogTitle>
        <DialogContent>
          <HomesProvider>
            <HomesConsumption />
          </HomesProvider>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CurrentConsumption;