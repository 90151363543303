import { Tenant } from "@features/userTenant/types";
import { createSlice } from "@reduxjs/toolkit";


const initialState: Tenant[] = []

/** State slice for the tenant selected to use */
export const homeownersReducer = createSlice({
  name: 'homeowners',
  initialState,
  reducers: {
    setHomeowners: (state: any, action: any) => {
      if (action.payload) {
        state.splice(0, state.length);
        action.payload.forEach((tt: Tenant) => state.push(tt));
      }
    },
    resetHomeowners: (state) => state = initialState,
  }
});

export default homeownersReducer.reducer;

export const {
  setHomeowners,
  resetHomeowners,
} = homeownersReducer.actions;
