export enum Interval {
  HOUR = '1 hour',
  DAY = '1 day',
  WEEK = '1 week',
  MONTH = '1 month',
  QUARTER = '3 months',
  YEAR = '1 year',
}

export enum CompareLabel {
  devices = 'my home only',
  zip = 'vs zip average',
  floorplan = 'vs plan average',
  system = 'vs system average',
}

export type RawDataRow = {
  bucket: string,
  device_id: string,
  entity_id: string,
  value: string,
};

export type RawAverageDataRow = {
  bucket: string,
  value: string | number,
};