import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { ManifestDevice, Sensor } from '@features/plan-manifest/types';
import { getUnitsByCategoryMeasure } from '@features/unit-settings/utils';
import { Category, findUnitsLabel, formatCategoryMeasurePair, formatWaterUsageRate } from '@lib/labels';
import { List, ListItem, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { USAGE_RATE_MEASURE, WATER_USAGE_CATEGORY } from '../types';
import { formatValue } from '../utils';


const findSensor = (sensor: Sensor, category: Category) => {
  if (sensor.sensor_category !== category) return false;
  return sensor.measure_name === USAGE_RATE_MEASURE;
}

type Props = {
  category: Category,
}

const CurrentReadings = ({ category }: Props) => {
  const home = useAppSelector((state: RootState) => state.property);
  const userPreferences = useAppSelector((state: RootState) => state.preferences);

  const manifestEntries = useMemo(() => home.manifestEntries || [], [home.manifestEntries]);

  const preferences = useMemo(() => userPreferences.preferences, [userPreferences.preferences]);
  const unitPreferences = useMemo(() => preferences.units || {}, [preferences.units]);
  const preferredUnit = useMemo(() => (
    unitPreferences?.[formatCategoryMeasurePair(category, USAGE_RATE_MEASURE)]
    || getUnitsByCategoryMeasure(category, USAGE_RATE_MEASURE)[0]
  ), [category, unitPreferences]);

  const devices = useMemo(() => home.devices || [], [home.devices]);

  const meters = useMemo(() => manifestEntries
    ?.filter(me => (me.device as ManifestDevice).sensors.some(ss => findSensor(ss, category)))
  , [category, manifestEntries]);

  const meterReadings = useMemo(() => meters
    ?.map(mm => {
      const deviceName = `${(mm.device as ManifestDevice).friendly_name} in ${mm.property_area}`;
      const waterMeterSensor = (mm.device as ManifestDevice).sensors.find(s => findSensor(s, category));
      const entityId = mm.sensor_map?.[waterMeterSensor?.sensor_id ?? waterMeterSensor?.friendly_name ?? ''] || '';
      const reading = devices
        .find(d => d.data.device_id === mm.gateway_device_id && d.data.entity_id === entityId);

      const value = reading?.data.value;
      return {
        deviceName,
        value,
      };
    })
    .sort((a, b) => a.deviceName.toLocaleLowerCase() > b.deviceName.toLocaleLowerCase() ? 1 : -1)
  , [category, devices, meters]);

  return (
    <List
      disablePadding
      sx={{
        minWidth: 400,
      }}
    >
      {
        meterReadings.map((rr, idx) => (
          <ListItem key={`usage-${idx}`}>
            <Stack direction="row" justifyContent="space-between" sx={{ width: 'inherit'}}>
              <Typography>{rr.deviceName}</Typography>
              <Typography>
              {
                category === WATER_USAGE_CATEGORY
                ? formatWaterUsageRate(rr.value, preferredUnit)
                : [
                  formatValue(rr.value),
                  findUnitsLabel(preferredUnit),
                ].join('')
              }
              </Typography>
            </Stack>
          </ListItem>
        ))
      }
    </List>
  );
}

export default CurrentReadings;