import Header from "@features/Common/Header";
import NeighborhoodList from "@features/all-neighborhoods/components/NeighborhoodList";
import NeighborhoodsProvider from "@features/all-neighborhoods/components/NeighborhoodsProvider";
import { FC } from "react";



const AllNeighborhoods: FC = () => {
  return (
    <NeighborhoodsProvider>
      <Header />
      <NeighborhoodList />
    </NeighborhoodsProvider>
  )
}

export default AllNeighborhoods;