import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { findCategoryLabel, findMeasureLabel, findUnitsLabel, findValueTypeLabel } from "@lib/labels";
import { Divider, Grid, Typography } from "@mui/material";
import { useMemo } from "react";
import { RIOT_BLUE } from "theme";
import { Sensor } from "../types";

type Props = {
  sensor: Sensor;
}

const SensorDetails = ({ sensor }: Props) => {
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant.builder_color || RIOT_BLUE, [currentTenant.builder_color]);

  return (
    <Grid container columns={10}>
      <Grid item xs={10}>
        <Typography variant="subtitle1">
          {sensor.friendly_name} (sensor #{sensor.order})
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Divider sx={{ borderColor: themeColor }} />
      </Grid>
      <Grid item xs={5}>
        <Typography variant="caption">category</Typography>
        <Typography variant="body1">{findCategoryLabel(sensor.sensor_category)}</Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography variant="caption">measure</Typography>
        <Typography variant="body1">{findMeasureLabel(sensor.measure_name)}</Typography>
      </Grid>
      <Grid item xs={10}>
        <Divider sx={{ borderColor: themeColor }} />
      </Grid>
      <Grid item xs={5}>
        <Typography variant="caption">value type</Typography>
        <Typography variant="body1">{findValueTypeLabel(sensor.value_type)}</Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography variant="caption">units</Typography>
        <Typography variant="body1">{findUnitsLabel(sensor.units)}</Typography>
      </Grid>
    </Grid>
  );
}

export default SensorDetails;