import { PropertyManifestEntry } from "@features/home-manifest/types";
import { Aggregate, AnalyticsType, ApexChartType, BinSize, ChartType, interval, TrendLines } from "@features/settings/types";
import { getDurationFromLabel } from "@features/settings/utils";
import { Category, convertValue, Measure, Unit } from "@lib/labels";
import { AnalyticsSet, AnalyticsTimeseries } from "@lib/types";
import { formatDate } from "@lib/utils";
import { Moment } from "moment";
import createTrend from 'trendline';
import {
  ChartDimensions,
  SeriesDataObject
} from "./types";
import { Plan } from "@features/floorplan/types";
import { Property } from "@features/home/types";


/** Calculates start date for selected range */
export const getStartDate = (range: interval | undefined): Moment => {
  const now = formatDate('');
  if (!range) return now;
  return now.subtract(getDurationFromLabel(range));
};


const getForecastLabel = (entityId: string) => {
  const [, num, duration] = entityId.split('_');
  return `${num} ${duration.substring(0, duration.length - 1)} forecast`;
};


const getSensorLabel = (
  entityId: string,
  manifestEntries: PropertyManifestEntry[],
) => {
  const UNKNOWN = 'Unknown Sensor';
  const FORECAST = 'weather_forecast';

  if (entityId.startsWith(FORECAST)) return getForecastLabel(entityId.slice(FORECAST.length + 1));

  const targetEntry = manifestEntries.find(e => Object.values(e.sensor_map || {}).includes(entityId));

  if (!targetEntry) return `${UNKNOWN} (${entityId})`;

  const target = Object.entries(targetEntry?.sensor_map || {})
    .find(([, sensorEntityId]) => sensorEntityId === entityId)?.[0];

  if (!target) return `${UNKNOWN} (${entityId})`;

  const { property_area } = targetEntry;

  return `${target} (${property_area})`;
};

const getPlanLabel = (
  planId: string,
  plans: Plan[],
) => {
  const UNKNOWN = 'Unknown Plan';

  const plan = plans.find(p => p.plan_id === planId);

  if (!plan) return UNKNOWN;

  return plan.name || plan.plan_id;
};

const getPropertyLabel = (
  propertyId: string,
  properties: Property[],
) => {
  const UNKNOWN = 'Unknown Property';

  const prop = properties.find(p => p.property_id === propertyId);

  if (!prop) return UNKNOWN;

  return prop.name || prop.property_id;
};

const getZipCodeLabel = (
  zipCode?: string,
) => {
  const UNKNOWN = 'Unknown Zip Code';
  return `Zip Code ${zipCode ?? UNKNOWN}`;
};


export const formatRawDataForTimeLineChart = (
  rawData: AnalyticsSet[],
  manifestEntries: PropertyManifestEntry[],
  plans: Plan[],
  properties: Property[],
  showForecast: boolean,
  trendLines: TrendLines,
  chartType: string,
  category: Category,
  measure: Measure,
  unit: Unit,
): ApexAxisChartSeries => {
  const adjustChartType = (): ApexChartType => {
    if (chartType !== ChartType.scatter) return chartType as ApexChartType;
    if (trendLines !== TrendLines.none) return ChartType.line as ApexChartType;
    return ChartType.scatter as ApexChartType;
  };

  const adjustedChartType = adjustChartType();

  if (!rawData.length) return [];
  const deviceData: ApexAxisChartSeries & {
    data: SeriesDataObject[];
  }[] = [];

  rawData.forEach((rawSeries => {
    const { ColumnInfo = [], Rows = [] } = rawSeries;

    const [
      propertyIdColIdx,
      planIdColIdx,
      zipColIdx,
      entityIdColIdx,
      sysAggCalColIdx,
    ] = [
      ColumnInfo.indexOf('property_id'),
      ColumnInfo.indexOf('plan_id'),
      ColumnInfo.indexOf('loc_zip'),
      ColumnInfo.indexOf('entity_id'),
      ColumnInfo.indexOf('sys_aggval'),
    ];

    const entityNameColumnIndex = [
      entityIdColIdx,
      planIdColIdx,
      zipColIdx,
      propertyIdColIdx,
      sysAggCalColIdx,
    ].find(e => e !== -1);
  
    if (entityNameColumnIndex === undefined) return [];
  
    enum RowType {
      ENTITY,
      PROPERTY,
      PLAN,
      ZIP,
      SYSTEM_AVERAGE,
    }

    const rowType: RowType = (() => {
      switch (entityNameColumnIndex) {
        default:
        case entityIdColIdx:
          return RowType.ENTITY;
        case planIdColIdx:
          return RowType.PLAN;
        case propertyIdColIdx:
          return RowType.PROPERTY;
        case zipColIdx:
          return RowType.ZIP;
        case sysAggCalColIdx:
          return RowType.SYSTEM_AVERAGE;
      }
    })();
  
    const timebinColumnIndex = [
      ColumnInfo.indexOf('time_bin'),
      ColumnInfo.indexOf('buk'),
    ].find(e => e !== -1);

    /* If time bin is not a column, it is assumed that last column is an array of timeseries data */
    const dataColumnIndex = typeof timebinColumnIndex !== 'undefined'
      ? [
        ColumnInfo.indexOf('aggval'),
        ColumnInfo.indexOf('sys_aggval'),
        ColumnInfo.length - 1,
      ].find(e => e !== -1)
      : undefined;

    const FORECAST_ENTITY_FILTER = [
      'weather',
      'forecast',
    ];
  
    Rows
      .filter(row => {
        if (showForecast) return true;
        const entityName = String(row[entityNameColumnIndex] ?? '');
        if (!entityName.length) return true;
        return FORECAST_ENTITY_FILTER.every(ff => !entityName.toLowerCase().includes(ff));
      })
      .forEach((row) => {
        const seriesName = (() => {
          switch (rowType) {
            default:
            case RowType.ENTITY:
              return getSensorLabel(row[entityNameColumnIndex] as string || '', manifestEntries);
            case RowType.PLAN:
              return getPlanLabel(row[entityNameColumnIndex] as string || '', plans);
            case RowType.PROPERTY:
              return getPropertyLabel(row[entityNameColumnIndex] as string || '', properties);
            case RowType.ZIP:
              return getZipCodeLabel(row[zipColIdx] as string || '');
            case RowType.SYSTEM_AVERAGE:
              return 'System Average';
          }
        })();

        if (typeof timebinColumnIndex !== 'undefined' && timebinColumnIndex !== -1 && typeof dataColumnIndex !== 'undefined' && dataColumnIndex !== -1) {
          const match = deviceData.find(s => s.name === seriesName);
  
          const value = convertValue(row[dataColumnIndex] as (string | number | boolean), category, measure, unit);

          const data: SeriesDataObject = {
            x: formatDate(row[timebinColumnIndex]).valueOf(),
            y: value,
          };

          if (!match) {
            deviceData.push(
              {
                name: seriesName,
                type: adjustedChartType,
                data: [data],
              }
            );
          } else {
            (match.data as SeriesDataObject[]).push(data);
          }
        } else {
          /* Assume last column is array of timeseries data */
          const lastCol = row[row.length - 1];
          const seriesData: SeriesDataObject[] = [];

          if (Array.isArray(lastCol)) {
            (lastCol as AnalyticsTimeseries[]).forEach((point) => {
              const value = convertValue(point.value, category, measure, unit);

              seriesData.push({
                x: formatDate(point.time).valueOf(),
                y: value,
              });
           });

            deviceData.push(
              {
                name: seriesName,
                type: adjustedChartType,
                data: seriesData,
              }
            );
          }
        }
      });
  }));

  deviceData.sort((a, b) => a.name?.localeCompare(b?.name || '') || 0)

  if (trendLines === TrendLines.individual) deviceData
    .forEach((series) => {
      const data = series.data as SeriesDataObject[];
      const minX = Math.min(...data.map((dd) => Number((dd).x))) || 0;
      const maxX = Math.max(...data.map((dd) => Number((dd).x))) || 0;

      const trend = createTrend(data, 'x', 'y');

      deviceData.push(
        series,
        {
          name: `Trendline for ${series.name}`,
          type: 'line',
          data: [
            { x: minX, y: trend.calcY(minX) },
            { x: maxX, y: trend.calcY(maxX) },
          ]
        }
      );
      });

  if (trendLines === TrendLines.combined) {
    const dataSet = deviceData.reduce<SeriesDataObject[]>((acc, series) => [
      ...acc,
      ...(series.data as SeriesDataObject[]),
    ], []);

    if (!dataSet.length) return deviceData;

    const minX = Math.min(...dataSet.map((dd) => Number(dd.x))) || 0;
    const maxX = Math.max(...dataSet.map((dd) => Number(dd.x))) || 0;

    const trend = createTrend(dataSet, 'x', 'y');

    deviceData.push({
      name: 'Combined Trendline',
      type: 'line',
      data: [
        { x: minX, y: trend.calcY(minX) },
        { x: maxX, y: trend.calcY(maxX) },
      ]
    })
  }

  /* Sort all series by timestamp */
  deviceData.forEach((series) => {
    series.data.sort((a, b) => ((b as SeriesDataObject).x - (a as SeriesDataObject).x));
  });

  return deviceData;
};


export const getFallbackChartOptions = (ctype: AnalyticsType) => ({
  category: ctype === AnalyticsType.performance
    ? Category.air
    : Category.power,
  measure: ctype === AnalyticsType.performance ? Measure.temperature : Measure.usage_total,
  binSize: BinSize.day,
  aggregate: Aggregate.none,
  chartType: ChartType.line,
  trendLines: TrendLines.none,
});


export const getChartDimensions = () => {
  const screenWidth = window.visualViewport?.width || window.screen.width;
  const screenHeight = window.visualViewport?.height || window.screen.height;

  // vertical orientation
  if (screenHeight > screenWidth) {
    return {
      width: screenWidth * 0.7,
      height: screenHeight,
      legend: 'bottom',
    } as ChartDimensions;
  }

  // horizontal orientation
  return {
    width: screenWidth * 0.85,
    height: screenHeight * 0.65,
    legend: 'right',
  } as ChartDimensions;
};


export const debounce = (fn: (...arg: any) => any, ms: number) => {
  let timer: NodeJS.Timeout | null = null;
  return () => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn();
    }, ms);
  };
};