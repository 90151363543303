import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { AttentionLevel } from "@features/home-notifications/types";
import { ManifestDevice } from "@features/plan-manifest/types";
import { useWeatherForecastQuery } from "@features/weather/api";
import { Category, Measure } from "@lib/labels";
import { SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import AcUnitIcon from '@mui/icons-material/AcUnit';
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useHealthCheckHomeMutation } from "../api";
import { HealthCheckResult, HealthCheckType } from "../types";
import { getHealthCheckSentiment } from "../utils";
import HealthCheckSummary from "./HealthCheckSummary";

export const TWELVE_HOUR_TEMP_ENTITY = 'weather_forecast.temp_12_hours'
export const TWO_HOUR_TEMP_ENTITY = 'weather_forecast.temp_2_hours'
export const FREEZING_TEMP_C = 0;


const ExternalPlumbingFreezeHealth = () => {
  const { enqueueSnackbar } = useSnackbar();
  const property = useAppSelector((state: RootState) => state.property);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const { manifestEntries } = useMemo(() => property, [property]);

  const [healthCheckResult, setHealthCheckResult] = useState<HealthCheckResult | null>(null);

  const [
    healthCheckHome
  ] = useHealthCheckHomeMutation();

  const {
    currentData: weather,
  } = useWeatherForecastQuery({
    propertyId: property.property_id,
    userTenantId: currentTenant.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id || !property.property_id,
  });

  useEffect(() => {
    if (currentTenant.tenant_id && property.property_id) {
      healthCheckHome({
        healthCheckType: HealthCheckType.EXTERNAL_PLUMBING_FREEZE_WARNING,
        propertyId: property.property_id,
        userTenantId: currentTenant.tenant_id,
      }).then((res) => {
        if ('error' in res) {
          enqueueSnackbar("Failed to perform health check:", {
            key: "epf-hc-error",
            content: (
              <ExpandableSnack
                id="epf-hc-error"
                message={"Couldn't perform health check:"}
                variant={SnackType.error}
                detail={getErrorMessage(res.error)}
              />),
          });
        } else {
          setHealthCheckResult(res.data);
        }
      });
    }
  }, [healthCheckHome, property.property_id, currentTenant.tenant_id, enqueueSnackbar]);

  const involvedEntries = useMemo(() => manifestEntries
    ?.filter(ee => (ee.device as ManifestDevice).sensors.some(ss => (
      ss.sensor_category === Category.water && ss.measure_name === Measure.switch))), [manifestEntries]);

  const status = useMemo(() => {
    switch(getHealthCheckSentiment(healthCheckResult)) {
      default:
      case AttentionLevel.warning:
        return 'Status is unknown. External plumbing might be at risk of freezing.';
      case AttentionLevel.urgent:
        return 'External plumbing is at risk of freezing.';
      case AttentionLevel.info:
        return 'Waiting on healthcheck to complete.';
      case undefined:
        return 'External plumbing is not at risk of freezing.';
    }
  }, [healthCheckResult]);

  const freezingTempsExpected = useMemo((): boolean => {
    return weather?.forecast?.forecastday
      .flatMap((day: any) => {
        return day.hour;
      }).some((hourForecast: any) => {
        return (hourForecast.time_epoch * 1000) - Date.now() <= (1000 * 60 * 60 * 12 )
        && hourForecast.temp_c <= FREEZING_TEMP_C
      });
  }, [weather]);

  const substatus = useMemo(() => {
    const forecastMessage = `Freezing temperatures ${freezingTempsExpected ? 'are' : 'are not'} expected `
    return forecastMessage;
  }, [freezingTempsExpected]);

  return (
    <HealthCheckSummary
      icon={<AcUnitIcon sx={{ fontSize: '6em' }} />}
      healthCheckResult={healthCheckResult}
      title="External Plumbing"
      entries={involvedEntries || []}
      status={status}
      substatus={substatus}
    />
  );
}

export default ExternalPlumbingFreezeHealth;
