import { ManifestEntry, Sensor } from "@features/plan-manifest/types";
import { DeviceCount, LocationSummary } from "@features/provisioning-request/types";

/**
 * Reduces array of devices registered to the property
 * to the home areas they are installed in, providing
 * alphabetical room-by-room overview of home's devices
 *
 * @param devices - list of devices to map to locations
 */
export const getHomeAreas = (
  devices: ManifestEntry[],
): LocationSummary[] => devices.reduce((
  acc: LocationSummary[],
  entry: ManifestEntry,
) => {
  const deviceName = entry.device?.friendly_name
    || entry.device?.description
    || entry.device?.sensors[0].friendly_name;

  // find out if device's location already exists
  const locationIndex = acc.findIndex((
    location: LocationSummary,
  ) => location.location === entry.property_area);

  if (locationIndex === -1) {
    // create a new location summary if the location doesn't exist
    acc.push({
      location: entry.property_area,
      devices: [{
        name: deviceName || '-',
        count: 1,
      }],
      measurements: entry.device?.sensors.map((s: Sensor) => s.friendly_name) || [],
    });
  } else {
    // add device to the existing location summary
    // find out if we already have this type of device
    // in this location
    const deviceIndex = acc[locationIndex].devices
      .findIndex((deviceCount: DeviceCount) => deviceCount.name === deviceName);

    if (deviceIndex === -1) {
      // if we don't we add device to the summary
      acc[locationIndex].devices.push({
        name: deviceName || '-',
        count: 1,
      });
    } else {
      // otherwise update counts for this type of device
      acc[locationIndex].devices[deviceIndex].count += 1;
    }
  }

  return acc;
}, [])
  .sort((
    a: LocationSummary,
    b: LocationSummary,
  ) => (
    a.location.toUpperCase() <= b.location.toUpperCase() ? -1 : 1
  ));