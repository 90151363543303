import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { formatValue } from '@features/home-utility-monitoring/utils';
import { getUnitsByCategoryMeasure } from '@features/unit-settings/utils';
import { Category, convertValue, findUnitsLabel, formatCategoryMeasurePair, Measure } from '@lib/labels';
import { formatDate, formatLabel } from '@lib/utils';
import { Alert, Card, CardContent, CardHeader, CircularProgress, Divider } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import createTrend from 'trendline';
import { useAverageReadingsQuery, useIntervalReadingsQuery } from '../api';
import { CompareLabel, Interval, RawAverageDataRow, RawDataRow } from '../types';

type Props = {
  category: Category,
  measure: Measure,
  interval: Interval,
  compare: CompareLabel,
}


const PerformanceChart = ({ category, measure, interval, compare }: Props) => {
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const home = useAppSelector((state: RootState) => state.property);
  const userPreferences = useAppSelector((state: RootState) => state.preferences);

  const manifestEntries = useMemo(() => home.manifestEntries || [], [home.manifestEntries]);
  const propertyId = useMemo(() => home?.property_id || '', [home?.property_id]);
  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const preferences = useMemo(() => userPreferences.preferences, [userPreferences.preferences]);
  const unitPreferences = useMemo(() => preferences.units || {}, [preferences.units]);
  const preferredUnit = useMemo(() => (
    unitPreferences?.[formatCategoryMeasurePair(category, measure)]
    || getUnitsByCategoryMeasure(category, measure)[0]
  ), [category, measure, unitPreferences]);

  const [zip, setZip] = useState<string|undefined>(undefined);
  const [plan, setPlan] = useState<string|undefined>(undefined);

  const {
    currentData: rawData,
    isFetching,
  } = useIntervalReadingsQuery({
    userTenantId: currentTenant?.tenant_id || '',
    propertyId,
    category,
    measure,
    interval,
  }, {
    skip: !currentTenant?.tenant_id || !propertyId,
  });

  const homeChartData = useMemo(() => {
    if (compare === CompareLabel.devices) {
      return rawData?.reduce((series: any, row: RawDataRow) => {
        const matchingEntry = manifestEntries.find(e => e.gateway_device_id === row.device_id.trim());
        const areaName = formatLabel(matchingEntry?.property_area || 'weather forecast');

        const match = series.find((s: any) => s.name === areaName);

        const convertedValue = convertValue(row.value, category, measure, preferredUnit);

        if (match) {
          // add to existing row of series
          match.data.push({
            x: row.bucket,
            y: convertedValue,
          });
        } else {
          // create new row of series
          series.push({
            name: areaName,
            data: [{
              x: row.bucket,
              y: convertedValue,
            }],
          });
        }

        return series;
      }, [] as ApexAxisChartSeries) || [];
    }

    const data = rawData?.reduce((series: any, row: RawDataRow) => {
      const bucket = row.bucket;
      const bucketValues = rawData?.filter((d: RawDataRow) => d.bucket === bucket)?.map((d: RawDataRow) => d.value) || [];

      if (bucketValues.length) {
        const bucketAvg = bucketValues.reduce((sum: number, val: string) => sum + Number(val), 0)/bucketValues.length;

        series.data.push({
          x: bucket,
          y: convertValue(bucketAvg, category, measure, preferredUnit),
        });
      }

      return series;
    }, { name: home?.name || 'my home', data: [] });
    return [data];
  }, [category, compare, home?.name, manifestEntries, measure, preferredUnit, rawData]);

  const trendlineData = useMemo(() => {
    const dataSet = rawData?.map((row: RawDataRow) => ({
      x: formatDate(row.bucket).valueOf(),
      y: convertValue(row.value, category, measure, preferredUnit),
    }));

    if (!dataSet?.length) return undefined;
    const minX = Math.min(...dataSet.map((dd: { x: any; }) => Number(dd.x))) || 0;
    const maxX = Math.max(...dataSet.map((dd: { x: any; }) => Number(dd.x))) || 0;

    const trend = createTrend(dataSet, 'x', 'y');

    return {
      name: 'Combined Trendline',
      data: [
        { x: minX, y: trend.calcY(minX) },
        { x: maxX, y: trend.calcY(maxX) },
      ],
    }
  }, [category, measure, preferredUnit, rawData]);

  const {
    currentData: rawAverageData,
  } = useAverageReadingsQuery({
    userTenantId: currentTenant?.tenant_id || '',
    category,
    measure,
    interval,
    zip,
    plan,
  }, {
    skip: !currentTenant?.tenant_id,
  });

  const averageLabel = useMemo(() => {
    switch (compare) {
      default:
      case CompareLabel.floorplan:
        return home.plan_name;
      case CompareLabel.zip:
        return zip;
      case CompareLabel.system:
        return 'RIoT system';
    }
  }, [compare, home.plan_name, zip]);

  const averageChartData = useMemo(() => ({
    name: `Average for ${averageLabel}`,
    data: rawAverageData?.map((row: RawAverageDataRow) => ({
      x: row.bucket,
      y: convertValue(row.value, category, measure, preferredUnit),
    })),
  }), [averageLabel, category, measure, preferredUnit, rawAverageData]);

  const chartData = useMemo(() => {
    if (compare === CompareLabel.devices) {
      return [
        ...homeChartData,
        trendlineData,
      ].filter(e => e !== undefined);
    }

    return [
      ...homeChartData,
      averageChartData,
    ];

  }, [averageChartData, compare, homeChartData, trendlineData]);

  const chartOptions = useMemo(() => ({
    chart: {
      id: 'performance-chart',
      stacked: false,
      zoom: {
        enabled: true
      },
      animations: {
        enabled: false,
      }
    },
    title: {
      text: '',
      style: {
        fontFamily: 'Montserrat',
      }
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        }
      },
      yaxis: {
        lines: {
          show: true,
        }
      },
    },
    xaxis: {
      type: 'datetime',
      tickPlacement: 'on',
    },
    yaxis: {
      decimalsInFloat: 0,
      labels: {
        formatter: (value: any) => `${formatValue(value)}${findUnitsLabel(preferredUnit)}`,
      }
    },
    tooltip: {
      fontFamily: 'Montserrat',
      y: {
        title: {
          formatter: (seriesName: any) => seriesName,
        },
        formatter: (value: any) => `${formatValue(value)}${findUnitsLabel(preferredUnit)}`,
      },
    },
    legend: {
      fontFamily: 'Montserrat',
    },
    fills: {
      opacity: 1,
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: chartData?.map(d => d.name?.toLocaleLowerCase().includes('trend') ? 1 : 3),
    },
    markers: {
      size: 2,
    },
  } as unknown as ApexCharts.ApexOptions), [chartData, preferredUnit]);

  useEffect(() => {
    switch (compare) {
      default:
      case CompareLabel.devices:
      case CompareLabel.system:
        setZip(undefined);
        setPlan(undefined);
        break;
      case CompareLabel.zip:
        setZip(home.physical_address?.zip_postal_code);
        setPlan(undefined)
        break;
      case CompareLabel.floorplan:
        setZip(undefined);
        setPlan(home.plan_id);
    }
  }, [compare, home.physical_address?.zip_postal_code, home.plan_id]);

  return (
    <Card sx={{ height: 660, maxWidth: 700 }}>
      <CardHeader title="Home performance" />
      <Divider />
      <CardContent>
        {
          chartData?.length > 0 &&
            <Chart
              series={chartData}
              type="line"
              options={chartOptions}
              width="650"
              height="500"
            />
        }
        {
          !chartData?.length &&
          isFetching &&
          <CircularProgress />
        }
        {
          !chartData?.length &&
          !isFetching &&
          <Alert severity="info">No data found</Alert>
        }
      </CardContent>
    </Card>
  );
}

export default PerformanceChart;
