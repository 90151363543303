import { useAppDispatch, useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { pollingInterval } from "@features/tenant-selector/types";
import { FC, ReactElement, useEffect, useMemo } from "react";
import { useZipCodesQuery } from "../api";
import { setZips } from "../slice";

const ZipsProvider: FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const {
    currentData: zipCodes,
  } = useZipCodesQuery({
    userTenantId: currentTenant?.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id,
    pollingInterval,
  });

  useEffect(() => {
    dispatch(setZips(zipCodes?.[0]));
  }, [dispatch, zipCodes]);

  return children as ReactElement;
}

export default ZipsProvider;