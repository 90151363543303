import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { MouseEventHandler } from "react";

type Props = {
  isUnpairing: boolean;
  onUnpair: MouseEventHandler<HTMLButtonElement> | undefined;
};

/* Displays pairing button */
const UnpairingButton = ({ isUnpairing, onUnpair }: Props) => (
  <Button disabled={isUnpairing} onClick={onUnpair} variant="contained" color="secondary">
    {
      !isUnpairing && 'Begin unpairing mode (30s)'
    }
    {
      isUnpairing && (
      <Stack direction="row">
        <CircularProgress
          size="1em"
          color="inherit"
          sx={{
            mr: 1,
            mt: 'auto',
            mb: 'auto',
            verticalAlign: 'bottom',
          }}
        />
        <Typography sx={{ verticalAlign: 'bottom' }}>Unpairing</Typography>
      </Stack>
      )
    }
  </Button>
);

export default UnpairingButton;
