import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import AppTextField from "@features/home/components/AppTextField";
import { Autocomplete, CircularProgress, Stack, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { SyntheticEvent, useMemo } from "react";
import { Neighborhood } from "../types";


const NeighborhoodFields = () => {
  const managers = useAppSelector((state: RootState) => state.propertyManagers);
  const f = useFormikContext();

  const autocompleteOptions = useMemo(() => managers
    ?.map(mgr => ({
      id: mgr.tenant_id,
      label: mgr.name,
    })) || [], [managers]);

  const currentManager = useMemo(() => autocompleteOptions
    .find(oo => oo.id === (f.values as Neighborhood).property_manager_id) || null, [autocompleteOptions, f.values]);

  const handleSelectManager = (e: SyntheticEvent<Element, Event>, value: {
    id: string;
    label: string;
} | null) => {
    f.setFieldValue('property_manager_id', value?.id);
  }

  return (
    <Stack direction="row" spacing={1}>
      <AppTextField
        name="name"
        label="name"
        type="text"
        sx={{
          width: '45%'
        }}
      />
      {
        !autocompleteOptions?.length
          ? <CircularProgress />
          : <Autocomplete
              fullWidth
              onChange={handleSelectManager}
              options={autocompleteOptions}
              renderInput={(params) => <TextField {...params} label="Property Manager" />}
              value={currentManager}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
      }
    </Stack>
  );
}

export default NeighborhoodFields;