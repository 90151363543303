import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import AdminTable from '@features/Common/AdminTable';
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { ServicePower, TYPICAL_RESIDENTIAL_VOLTAGE, UtilityManagement } from '@features/all-utility-groups/types';
import { Plan, PlanCreate, PlanUpdate } from '@features/floorplan/types';
import AppTextField from '@features/home/components/AppTextField';
import { ApiErrorResponse, SnackType } from '@lib/types';
import { formatDate, getErrorMessage } from "@lib/utils";
import ManifestIcon from '@mui/icons-material/Ballot';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import { FormikProps } from 'formik';
import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from 'react';
import { PLAN_SCHEMA } from '../../plan-manifest/types';
import {
  useCreateMyPlanMutation,
  useCreateTenantPlanMutation,
  useDeleteMyPlanMutation,
  useDeleteTenantPlanMutation,
  usePlansQuery,
  useUpdateMyPlanMutation,
  useUpdateTenantPlanMutation
} from '../api';
import ManifestManagementModal from './ManifestManagementModal';


const PlanList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const plans = useAppSelector((state: RootState) => state.plans);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const  currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);

  const {
    refetch: fetchPlans,
  } = usePlansQuery({
    userTenantId: userTenant?.tenant_id || '',
    tenantId: currentTenant?.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id,
  });

  const [
    createMyPlan,
  ] = useCreateMyPlanMutation();

  const [
    createTenantPlan,
  ] = useCreateTenantPlanMutation();

  const [
    updateTenantPlan,
  ] = useUpdateTenantPlanMutation();

  const [
    updateMyPlan,
  ] = useUpdateMyPlanMutation();

  const [
    deleteMyPlan,
  ] = useDeleteMyPlanMutation();

  const [
    deleteTenantPlan,
  ] = useDeleteTenantPlanMutation();


  /** Handles Save button on New/Edit Plan dialog */
  const handleSavePlan = useCallback(async (plan: Partial<Plan>) => {
    /** New plan */
    if (!plan.plan_id) {
      if (currentTenant?.tenant_id === userTenant?.tenant_id) {
        /** Create a plan for myself */
        const newPlan = await createMyPlan({
          userTenantId: currentTenant?.tenant_id || '',
          body: plan as PlanCreate,
        });

        const errorDetails = (newPlan as ApiErrorResponse)?.error;

        if (errorDetails) {
          enqueueSnackbar("Couldn't create plan:", {
            key: "plan-error",
            content: (
              <ExpandableSnack
                id="plan-error"
                message={"Couldn't create plan:"}
                variant={SnackType.error}
                detail={getErrorMessage(errorDetails)}
              />),
          });
        } else {
          enqueueSnackbar("Created plan", {
            variant: "success",
          });
        }

        return (newPlan as any).data;
      }
      /** Create a plan for another tenant */
      const newPlan = await createTenantPlan({
        userTenantId: userTenant?.tenant_id || '',
        tenantId: currentTenant?.tenant_id || '',
        body: plan as PlanCreate,
      });

      const errorDetails = (newPlan as ApiErrorResponse)?.error;

      if (errorDetails) {
        enqueueSnackbar("Couldn't create plan:", {
          key: "plan-error",
          content: (
            <ExpandableSnack
              id="plan-error"
              message={"Couldn't create plan:"}
              variant={SnackType.error}
              detail={getErrorMessage(errorDetails)}
            />),
        });
      } else {
        enqueueSnackbar("Created plan", {
          variant: "success",
        });
      }

      return (newPlan as any).data;

    }
    /** Existing plan */
    if (currentTenant?.tenant_id === userTenant?.tenant_id) {
      /** Update my plan */
      const updatedPlan = await updateMyPlan({
        userTenantId: currentTenant?.tenant_id || '',
        planId: plan?.plan_id || '',
        body: plan as PlanUpdate,
      });

      const errorDetails = (updatedPlan as ApiErrorResponse)?.error;

      if (errorDetails) {
        enqueueSnackbar("Couldn't update plan:", {
          key: "plan-error",
          content: (
            <ExpandableSnack
              id="plan-error"
              message={"Couldn't update plan:"}
              variant={SnackType.error}
              detail={getErrorMessage(errorDetails)}
            />),
        });
      } else {
        enqueueSnackbar("Updated plan", {
          variant: "success",
        });
      }
      return (updatedPlan as any).data;
    }
    /** Update a plan for another tenant */
    const updatedPlan = await updateTenantPlan({
      userTenantId: userTenant?.tenant_id || '',
      tenantId: currentTenant?.tenant_id || '',
      planId: plan?.plan_id || '',
      body: plan as PlanUpdate,
    });

    const errorDetails = (updatedPlan as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't update plan:", {
        key: "plan-error",
        content: (
          <ExpandableSnack
            id="plan-error"
            message={"Couldn't update plan:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("Updated plan", {
        variant: "success",
      });
    }

    return updatedPlan;
  }, [
    createMyPlan,
    createTenantPlan,
    currentTenant?.tenant_id,
    enqueueSnackbar,
    updateMyPlan,
    updateTenantPlan,
    userTenant?.tenant_id,
  ]);

  const handleDeletePlan = useCallback(async (plan: Plan) => {

    if (currentTenant?.tenant_id === userTenant?.tenant_id) {
      const deletedPlan = await deleteMyPlan({
        userTenantId: currentTenant?.tenant_id || '',
        planId: plan.plan_id || '',
      });

      const errorDetails = (deletedPlan as ApiErrorResponse).error;

      if (errorDetails) {
        enqueueSnackbar("Couldn't delete plan:", {
          key: "plan-error",
          content: (
            <ExpandableSnack
              id="plan-error"
              message={"Couldn't delete plan:"}
              variant={SnackType.error}
              detail={getErrorMessage(errorDetails)}
            />),
        });
        return false;
      } else {
        enqueueSnackbar("Deleted plan", {
          variant: "success",
        });
        return true;
      }
    } else {
      const deletedPlan = await deleteTenantPlan({
        userTenantId: userTenant?.tenant_id || '',
        tenantId: currentTenant?.tenant_id || '',
        planId: plan.plan_id || '',
      });

      const errorDetails = (deletedPlan as ApiErrorResponse).error;

      if (errorDetails) {
        enqueueSnackbar("Couldn't delete plan:", {
          key: "plan-error",
          content: (
            <ExpandableSnack
              id="plan-error"
              message={"Couldn't delete plan:"}
              variant={SnackType.error}
              detail={getErrorMessage(errorDetails)}
            />),
        });
        return false;
      } else {
        enqueueSnackbar("Deleted plan", {
          variant: "success",
        });
        return true;
      }
    }
  }, [
    currentTenant?.tenant_id,
    deleteMyPlan,
    deleteTenantPlan,
    enqueueSnackbar,
    userTenant?.tenant_id,
  ]);

  const handleChangeServicePower = (e: any, f: FormikProps<Plan>) => {
    const util = f.values.utility_management || {} as UtilityManagement;
    if (!util.energy_management) {
      util.energy_management = {
        svc_max_power: {
          volts: TYPICAL_RESIDENTIAL_VOLTAGE,
          amps: Number(e.target.value),
        }
      }
      f.setFieldValue('utility_management', util);
    } else {
      f.handleChange(e);
    }
  }

  /** Renders the plans as an Admin table */
  const renderedTable = useMemo(() => {
    if (userTenant.checkingTenant) return <Backdrop open><CircularProgress /></Backdrop>;

    const options: MUIDataTableOptions = {
      enableNestedDataAccess: '.',
    };

    const columns: MUIDataTableColumn[] = [
      {
        name: '',
        label: 'Manage Manifests',
        options: {
          sort: false,
          filter: false,
          customBodyRenderLite: (idx) => (
            <Button
              startIcon={<ManifestIcon />}
              onClick={() => {
                setSelectedPlan(plans[idx])
              }}
            >
              Manifests
            </Button>
          )
        }
      },
      {
        name: 'name',
        label: 'Name',
      },
      {
        name: 'description',
        label: 'Description',
        options: {
          sort: false,
          filter: false,
        }
      },
      {
        name: 'utility_management.energy_management.svc_max_power.amps',
        label: 'Service Power (Amps)',
      },
      {
        name: 'created',
        label: 'Created',
        options: {
          customBodyRenderLite: (idx) => !!plans[idx].created ? formatDate(plans[idx].created)?.format('YYYY-MM-DD') || '' : '',
        }
      }
    ];

    return (
      <>
        <AdminTable<Plan>
          title="Plans"
          label="Plan"
          id_key="plan_id"
          name_key="name"
          columns={columns}
          options={options}
          data={plans}
          FormikProps={{
            validationSchema: PLAN_SCHEMA,
          }}
          makeNew={() => ({
            name: '',
            description: '',
            utility_management: {
              energy_management: {
                svc_max_power: new ServicePower(),
              }
            }
          })}
          onSave={handleSavePlan}
          onDelete={handleDeletePlan}
          refresh={fetchPlans}
          editPermission="write-plans"
          deletePermission="delete-plans"
        >
          {f => (
            <>
              <AppTextField
                name="name"
                label="Plan name"
                fullWidth
                required
              />

              <AppTextField
                fullWidth
                multiline
                minRows={2}
                name="description"
                label="Description"
              />
              <AppTextField
                name="utility_management.energy_management.svc_max_power.amps"
                type="number"
                onChange={(e: any) => handleChangeServicePower(e, f)}
                label="Service Power (Amps)"
                fullWidth
                required
                inputProps={{
                  step: 10,
                }}
              />
            </>
          )}
        </AdminTable>
        {
          selectedPlan &&
          <ManifestManagementModal
            onClose={() => setSelectedPlan(null)}
            open={selectedPlan !== null}
            plan={selectedPlan}
          />
        }
      </>
    );
  }, [fetchPlans, handleDeletePlan, handleSavePlan, plans, selectedPlan, userTenant.checkingTenant]);

  return (
    <Box
      component="div"
      sx={{
        margin: '1em',
        padding: '2em',
      }}
    >
      <Typography variant="h4">{currentTenant.name}</Typography>
      <Typography variant="h5">Plans</Typography>
      <Box
        component="div"
        sx={{
          mt: '3em',
        }}
      >
        {renderedTable}
      </Box>
    </Box>
  );
}

export default PlanList;