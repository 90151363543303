import * as yup from 'yup';

type EntityBase = {
  readonly created: string | number;
  readonly updated: string | number;
}


/** Validation for Documents */
export enum DocumentType {
  CLOSING = "Closing Document",
  VENDOR = "Vendor Document",
  WARRANTY = "Warranty Document",
  SURVEY = "Survey",
  TAX = "Tax Document",
  PLAN = "Floor Plan",
  OTHER = "Other"
}

export const DOCUMENT_SCHEMA = yup.object({
  name: yup.string().label('Name'),
  description: yup.string().label('Description'),
  document_type: yup.string().oneOf(Object.values(DocumentType)).label('Type')
});


export type DocumentDownload = {
  readonly url: string;
  readonly ttl: number;
}

export type DocumentUpload = {
  url: string;
  fields: Record<string, string>;
}


export type DocumentShare = {
  tenant_id: string;
  document_id: string;
  property_id: string;
}


export type Document = {
  readonly tenant_id: string;
  readonly document_id: string;
  readonly property_id: string;

  description: string;
  is_owner: boolean;
  shared_with: DocumentShare[];
  name: string;
  size: string;
  url: string;
  key: string;
  ttl: number;
  upload_headers: DocumentUpload;
  document_type: DocumentType;
  path: string;
} & EntityBase;

export type DocumentApiResponse = {
  data: Document;
}

export const DEFAULT_DOCUMENT_TTL = 3600;