import { PropertyManifest, PropertyManifestEntry } from "@features/home-manifest/types";
import { ManifestDevice } from "@features/plan-manifest/types";
import { Category } from "@lib/labels";
import { Alert, Box, Checkbox, FormControlLabel, FormGroup, Stack } from "@mui/material";
import { useEffect, useMemo } from "react";

type Props = {
  manifest?: PropertyManifest;
  refetchManifest: () => void;
};

const Review = ({ manifest, refetchManifest }: Props) => {

  /* Refetch manifest on mount */
  useEffect(() => {
    refetchManifest();
  }, [refetchManifest]);

  /* Formats commissioned date */
  const getCommissionDateLabel = ((entry: PropertyManifestEntry) => {
    if (!entry.commissioned) return '';
    return `(${new Date(Number(entry.commissioned) * 1000).toLocaleDateString()})`;
  })

  /** Displays current status of manifest entry */
  const renderDeviceStatus = ((entry: PropertyManifestEntry) => {
    const device = entry.device as ManifestDevice;
    const entryName = device.friendly_name || device.description || '';
    return (
      <FormControlLabel
        key={entry.manifest_entry_id}
        control={
          <Checkbox
            checked={Boolean(entry.commissioned)}
            name={entry.manifest_entry_id}
            readOnly
          />
        }
        label={`${entryName} in ${entry.property_area} ${getCommissionDateLabel(entry)}`}
      />
    );
  })

  const unconfirmedDevices = useMemo(() => manifest?.manifest_entries
    .filter((e => !(e.commissioned || (e.device as ManifestDevice)?.sensors
      ?.some(s => s.sensor_category === Category.system)))) || [], [manifest?.manifest_entries]);

  const unconfirmedMessage = useMemo(() => [
    'There are',
    unconfirmedDevices.length,
    'unconfirmed devices.',
    'Return to the previous step to continue confirming devices.'
  ].join(' '), [unconfirmedDevices.length]);

  return (
    <Box component="div" sx={{ p: 1 }}>
      <Stack spacing={2}>
        {
          unconfirmedDevices?.length === 0 &&
          <Alert severity="success">
            All devices are paired.
          </Alert>
        }
        {
          unconfirmedDevices?.length > 0 &&
          <Alert severity="error">
            {unconfirmedMessage}
          </Alert>
        }
        <FormGroup>
          {
            manifest?.manifest_entries
              .filter(e => !(e.device as ManifestDevice)?.sensors?.some(s => s.sensor_category === Category.system))
              .map((entry) => renderDeviceStatus(entry))
          }
        </FormGroup>
      </Stack>
    </Box>
  );
}

export default Review;