import { DeviceCount, LocationSummary } from "@features/provisioning-request/types";
import {
  Card, CardContent, FormControl, InputLabel, MenuItem, Select, Typography
} from "@mui/material";

type Props = {
  location: LocationSummary;
  device: DeviceCount;
}

/**
 * Displays summary of each type of device for given home area
 * with (disabled for now) selector of quantities
*/
const PlanManifestDevice = ({ device }: Props) => (
    <Card sx={{
      width: '100%',
      border: 'none',
      boxShadow: 'none',
    }}>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: '6px',
        }}
      >
        <Typography component="div" variant="body2">
          {device.name}
        </Typography>
        <FormControl sx={{ width: '23%' }}>
          <InputLabel id='device-count-label'>
            Device Count
          </InputLabel>
          <Select
            value={device.count}
            label={'Device Count'}
            id='device-count-label'
            fullWidth
            sx={{
              height: '2em'
            }}
          >
            <MenuItem value={device.count}>{device.count}</MenuItem>
          </Select>
        </FormControl>
      </CardContent>
    </Card>
);


export default PlanManifestDevice;