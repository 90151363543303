import { createSlice } from "@reduxjs/toolkit";
import { UserNotification } from "./types";

type NotificationsSliceState = {
  notifications?: UserNotification[];
  fetchDone: boolean;
}

const initialState: NotificationsSliceState = {
  notifications: [],
  fetchDone: false,
}


/** State slice for Home Notifications */
export const notificationsReducer = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications: (state: NotificationsSliceState, action: any) => {
      if (action.payload) {
        state.notifications = action.payload;
        if (!state.fetchDone) state.fetchDone = true;
      }
    },
  }
});

export default notificationsReducer.reducer;

export const  {
  setNotifications,
} = notificationsReducer.actions;