import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import Header from "@features/Common/Header";
import NotificationProvider from "@features/home-notifications/components/NotificationProvider";
import Notifications from "@features/home-notifications/components/Notifications";
import { Box, Typography } from "@mui/material";

/**
 * Displays notifications for selected RIoT Home
 */
const HomeNotifications = () => {
  const property = useAppSelector((state: RootState) => state.property);

  return (
    <Box
    component="div"
    sx={{
      margin: '1em',
      padding: '2em',
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
    }}>
      <Header />

      <Typography
        variant="h5"
        sx={{
          mb: '3em',
        }}
      >
        Notifications for {property.name}
      </Typography>
      <NotificationProvider><Notifications /></NotificationProvider>
    </Box>
  );
}
export default HomeNotifications;
