import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { Box, ClickAwayListener, Paper, Portal, Stack, TextField, TextFieldProps } from "@mui/material";
import { ChangeEvent, MouseEvent, useEffect, useMemo, useRef, useState } from "react";
import { ChromePicker, ColorResult } from "react-color";
import { RIOT_BLUE } from "theme";
import { DEFAULT_FORMAT } from "../types";
import { formatColorResult } from "../utils";
import ColorButton from "./ColorButton";


type Props = TextFieldProps & {
  onColorChange: (value: string) => unknown;
  active: boolean;
};

const ColorPicker = ({
  onColorChange,
  active,
  // TextFieldProps
  name, label, placeholder, value, ...rest
}: Props) => {

  const [show, setShow] = useState(false);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant?.builder_color || RIOT_BLUE, [currentTenant?.builder_color]);

  const handleClick = (event: MouseEvent<HTMLElement>) => setShow(!show);

  const [internalValue, setValue] = useState<string>(themeColor);

  const handleColorChangeViaText = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    setValue(value);
  }

  const handleColorChangeViaPicker = (color: ColorResult) => {
    setValue(formatColorResult(color, DEFAULT_FORMAT));
  }

  useEffect(() => {
    onColorChange(internalValue);
  }, [internalValue, onColorChange]);

  const container = useRef(null);

  return (
    <>
      <Box
        component="div"
        ref={container}
        sx={{
          zIndex: 35,
          position: 'absolute',
          bottom: '18em',
          left: '20%',
        }}
      ></Box>
      <Stack direction="row">
        <TextField
          disabled={!active}
          variant="standard"
          name={name}
          label={label}
          placeholder={!internalValue ? placeholder : ''}
          value={internalValue}
          onChange={handleColorChangeViaText}
          sx={{
            width: '26em',
          }}
          {...rest}
        />
          <ColorButton
            disabled={!active}
            onClick={handleClick}
            color={value as string || internalValue}
          />
        {
          show &&
          <Portal container={container.current}>
            <ClickAwayListener
              onClickAway={(e) => {
                e.preventDefault();
                setShow(false);
              }}
            >
              <Paper sx={{
                p: 1,
                border: `1px solid ${themeColor}`,
                width: '240px'
              }}>
                <ChromePicker
                  color={value as string || internalValue}
                  onChange={handleColorChangeViaPicker}
                />
              </Paper>
            </ClickAwayListener>
          </Portal>
        }
      </Stack>
    </>
  );
};

export default ColorPicker;