import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import WarningIcon from '@mui/icons-material/Warning';
import { ButtonGroup, Tooltip } from "@mui/material";
import { useMemo } from 'react';
import { AttentionLevel, NotificationRow } from "../types";


type Props = {
  notification: NotificationRow;
}

const AttentionLevelColumn = ({ notification }: Props) => {
  const level = useMemo(() => notification.attention_level, [notification.attention_level]);
  return (
    <ButtonGroup>
      <Tooltip title={level} placement="top" arrow>
        {
          [AttentionLevel.urgent, AttentionLevel.warning].includes(level)
            ? <WarningIcon color={level === AttentionLevel.urgent ? 'error' : 'warning'} />
            : <PriorityHighIcon />
        }
      </Tooltip>
    </ButtonGroup>
  );
}

export default AttentionLevelColumn;