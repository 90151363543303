import { createSlice } from "@reduxjs/toolkit";
import { SupportCase, SupportCaseState } from "../support-case/types";

const initialState = {
  case: {} as SupportCase,
};

export const supportCaseReducer = createSlice({
  name: 'supportCase',
  initialState,
  reducers: {
    setSupportCase: (state: SupportCaseState, action: any) => {
      if (action.payload) {
        state.case = action.payload as SupportCase;
      } else {
        state.case = initialState.case;
      }
    },
  }
});

export default supportCaseReducer.reducer;

export const {
  setSupportCase,
} = supportCaseReducer.actions;