import { riotApi } from "@features/aws-user/api";
import { U } from "@lib/utils";
import { DashboardEmbedInfoRaw, DashboardInfo } from "./types";


/** API slice for analytics-related endpoints */
export const dashboardsApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      /** Get list of available dashboards */
      dashboards: builder.query<DashboardInfo[], { userTenantId: string }>({
        query: ({ userTenantId }) => ({
          url: `/tenant/${U(userTenantId)}/properties/analytics/dashboards`,
        })
      }),
      /** Get embed information for a dashboard */
      embed: builder.mutation<DashboardEmbedInfoRaw, { userTenantId: string, arn: string }>({
        query: ({ userTenantId, arn }) => ({
          url: `/tenant/${U(userTenantId)}/properties/analytics/embed`,
          params: {
            arn,
          }
        })
      }),
    };
  },
});

export const {
  useDashboardsQuery,
  useEmbedMutation,
} = dashboardsApi;
