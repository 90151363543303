import {
  createSlice
} from '@reduxjs/toolkit';
import { UserData } from './types';


const initialState: UserData =  {
  username: '',
  attributes: null,
  idToken: '',
};


export type AwsUserAction = {
  type: string;
  payload: UserData;
}

/** State slice for logged in User */
export const awsUserReducer = createSlice({
  name: 'awsUser',
  initialState,
  reducers: {
    setUser: (state, action: AwsUserAction) => {
      if (action.payload) {
        state.username = action.payload.username || '';
        state.attributes = action.payload.attributes ? {
          ...action.payload.attributes
        } : null;
        state.idToken = action.payload.idToken;
      }
    },
    resetUser: (state: any) => {
      state.idToken = initialState.idToken;
      state.username = initialState.username;
      state.attributes = initialState.attributes;
    },
  },
});


export default awsUserReducer.reducer;

export const { setUser, resetUser } = awsUserReducer.actions;
