import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import AlertDialog from '@features/Common/AlertDialog';
import ExpandableSnack from '@features/Common/ExpandableSnack';
import { usePerformanceQuery } from '@features/charts/api';
import { Aggregate, BinSize } from '@features/settings/types';
import { THERMOSTAT_CATEGORY, THERMOSTAT_DEFAULT_UNIT, THERMOSTAT_MEASURE } from '@features/thermostat/types';
import { getUnitsByCategoryMeasure } from '@features/unit-settings/utils';
import { Category, Measure, formatCategoryMeasurePair } from '@lib/labels';
import { SnackType } from '@lib/types';
import { formatApiTimestamp, formatDate, getErrorMessage } from '@lib/utils';
import { Alert, Stack } from "@mui/material";
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDeleteGasBillMutation, useGasBillingQuery, useHvacDutyCycleRegressionMutation } from '../api';
import { GasBill, HvacDutyCycleRegression } from '../types';
import BillingHistory from './BillingHistory';
import BillingHistoryChart from './BillingHistoryChart';
import CreateBillDialog from './CreateBillDialog';
import UsageBillingSummary from './UsageBillingSummary';

const SUGGESTED_ADJUSTMENT_DEGREES_C = 3;
const SUGGESTED_ADJUSTMENT_DEGREES_F = 5;

const HomeEnergy = () => {
  const { enqueueSnackbar } = useSnackbar();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const property = useAppSelector((state: RootState) => state.property);
  const userPreferences = useAppSelector((state: RootState) => state.preferences);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const preferences = useMemo(() => userPreferences.preferences, [userPreferences.preferences]);
  const unitPreferences = useMemo(() => preferences.units || {}, [preferences.units]);
  const preferredUnit = useMemo(() => (
      unitPreferences?.[formatCategoryMeasurePair(THERMOSTAT_CATEGORY, THERMOSTAT_MEASURE)]
      || getUnitsByCategoryMeasure(THERMOSTAT_CATEGORY, THERMOSTAT_MEASURE)[0]
    ), [unitPreferences]);


  const [creatingBill, setCreatingBill] = useState<boolean>(false);
  const [confirmDeleteBill, setConfirmDeleteBill] = useState<GasBill | null>(null);
  const [hvacDutyCycleRegr, setHvacDutyCycleRegr] = useState<HvacDutyCycleRegression | null>(null);

  const {
    currentData: usageData,
    error: usageDataError,
  } = usePerformanceQuery({
    userTenantId: currentTenant?.tenant_id || '',
    propertyId: property?.property_id || '',
    category: Category.air,
    measure: Measure.switch,
    aggregate: Aggregate.avg,
    binSize: BinSize.day,
    start: formatApiTimestamp(moment().startOf('year')),
    end: formatApiTimestamp(moment().endOf('day')),
  }, {
    skip: !currentTenant?.tenant_id || !property?.property_id,
  });

  useEffect(() => {
    if (usageDataError) {
      if (usageDataError) {
        enqueueSnackbar("Couldn't retrieve usage data", {
          key: "get-usage-data-error",
          content: (
            <ExpandableSnack
              id="usage-data-error"
              message={"Couldn't retrieve usage data:"}
              variant={SnackType.error}
              detail={getErrorMessage(usageDataError)}
            />),
        });
      }
    }
  }, [enqueueSnackbar, usageDataError]);

  const {
    currentData: billingData,
    refetch: refetchBillingData,
    error: billingDataError,
  } = useGasBillingQuery({
    userTenantId: currentTenant?.tenant_id || '',
    propertyId: property?.property_id || '',
  }, {
    skip: !currentTenant?.tenant_id || !property?.property_id,
  });

  useEffect(() => {
    if (billingDataError) {
      enqueueSnackbar("Couldn't retrieve billing data", {
        key: "get-bill-error",
        content: (
          <ExpandableSnack
            id="get-bill-error"
            message={"Couldn't retrieve billing data:"}
            variant={SnackType.error}
            detail={getErrorMessage(billingDataError)}
          />),
      });
    }
  }, [billingDataError, enqueueSnackbar]);

  const [getHvacDutyCycleRegression] = useHvacDutyCycleRegressionMutation();
  const [deleteBill] = useDeleteGasBillMutation();

  useEffect(() => {
    if (currentTenant?.tenant_id && property?.property_id) {
      getHvacDutyCycleRegression({
        userTenantId: currentTenant.tenant_id,
        propertyId: property.property_id,
      }).then((regr) => {
        if ('error' in regr) {
          enqueueSnackbar("Couldn't retrieve usage data", {
            key: "get-usage-error",
            content: (
              <ExpandableSnack
                id="get-usage-error"
                message={"Couldn't retrieve usage data:"}
                variant={SnackType.error}
                detail={getErrorMessage(regr.error)}
              />),
          });

          setHvacDutyCycleRegr(null);
        } else {
          setHvacDutyCycleRegr(regr.data);
        }
      })
    } else {
      setHvacDutyCycleRegr(null);
    }
  }, [currentTenant.tenant_id, enqueueSnackbar, getHvacDutyCycleRegression, property.property_id]);

  const getPriorPeriodBill = useCallback((usageDate: Date) => {
    return [...(billingData || [])]
      .sort((a, b) => (
        formatDate(b.billing_cycle.start).toDate().getTime()
          - formatDate(a.billing_cycle.start).toDate().getTime()
      ))
      .find((b) => formatDate(b.billing_cycle.end).toDate().getTime() <= usageDate.getTime());
  }, [billingData]);

  const priorPeriodBill = useMemo(() => getPriorPeriodBill(new Date()), [getPriorPeriodBill]);

  const [estimatedSavings, estimatedSavingsAdjustment] = useMemo<[savings: number | undefined, adjustment: number | undefined]>(() => {
    /* Estimate savings by adjusting thermostat */
    if (hvacDutyCycleRegr && priorPeriodBill) {
      const temperatureRange = preferredUnit === THERMOSTAT_DEFAULT_UNIT
        ? SUGGESTED_ADJUSTMENT_DEGREES_F * 5 / 9
        : SUGGESTED_ADJUSTMENT_DEGREES_C;

      const suggestedAdjustment = preferredUnit === THERMOSTAT_DEFAULT_UNIT
        ? SUGGESTED_ADJUSTMENT_DEGREES_F
        : SUGGESTED_ADJUSTMENT_DEGREES_C;

      return [
        Math.abs(hvacDutyCycleRegr.duty_cycle_per_temp_diff_degree / 100 * temperatureRange)
        * priorPeriodBill.therms_used
        * priorPeriodBill.therm_cost,
        suggestedAdjustment,
      ];
    }

    return [undefined, undefined]
  }, [hvacDutyCycleRegr, preferredUnit, priorPeriodBill]);

  const handleDeleteBill = useCallback(() => {
    if (confirmDeleteBill) {
      deleteBill({
        propertyId: property.property_id,
        userTenantId: currentTenant.tenant_id,
        start: confirmDeleteBill.billing_cycle.start,
        end: confirmDeleteBill.billing_cycle.end,
      }).then((res) => {
        if ('error' in res) {
          enqueueSnackbar("Couldn't delete bill", {
            key: "delete-bill-error",
            content: (
              <ExpandableSnack
                id="delete-bill-error"
                message={"Couldn't delete bill:"}
                variant={SnackType.error}
                detail={getErrorMessage(res.error)}
              />),
          });
        } else {
          enqueueSnackbar("Bill deleted.", { variant: 'success'});
          refetchBillingData();
        }
      });
    }
  }, [confirmDeleteBill, currentTenant.tenant_id, deleteBill, enqueueSnackbar, property.property_id, refetchBillingData]);

  const handleCreateDialogClose = useCallback((changes) => {
    if (changes) {
      refetchBillingData();
    }

    setCreatingBill(false);
  }, [refetchBillingData]);


  return <Stack direction="column" flex={1} sx={{ height: '90%', pb: 10 }}>
    {(estimatedSavings && estimatedSavingsAdjustment) ?
    <Alert severity="success" sx={{ m: 1 }}>
      You could save an estimated
      {' '}
      <b>${estimatedSavings.toFixed(2)}</b>
      {' '}
      next month by adjusting your average thermostat setting by
      {' '}
      <b>{estimatedSavingsAdjustment}</b>
      {' '}
      degrees.
    </Alert> : false}
    <Stack direction="row" spacing={1} flex={1} flexWrap="wrap" gap={1} justifyContent="center">
      <Stack direction="column" spacing={1} flex={1}>
        <UsageBillingSummary priorPeriodBill={priorPeriodBill} usageData={usageData} billingData={billingData} />
        <BillingHistory
          billingData={billingData}
          handleCreateNewBill={() => setCreatingBill(true)}
          handleDeleteBill={(bill) => setConfirmDeleteBill(bill)}
        />
      </Stack>
      <BillingHistoryChart billingData={billingData} />
    </Stack>
    {confirmDeleteBill !== null && (
      <AlertDialog
        content={`Are you sure you want to delete the bill dated ${
          formatDate(confirmDeleteBill.billing_cycle.start).calendar()
        } to ${
          formatDate(confirmDeleteBill.billing_cycle.end).calendar()
        }?`}
        title="Confirm bill deletion"
        onCancel={() => setConfirmDeleteBill(null)}
        onClose={() => setConfirmDeleteBill(null)}
        onConfirm={handleDeleteBill}
      />
    )}
    {
      creatingBill && (
        <CreateBillDialog
          open={creatingBill}
          onClose={handleCreateDialogClose}
        />
      )
    }
  </Stack>;
}

export default HomeEnergy;
