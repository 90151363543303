import { riotApi } from "@features/aws-user/api";
import { PropertyManifest } from '@features/home-manifest/types';
import { Device, Property, PropertyTokenRefresh } from '@features/home/types';
import { ManifestEntry } from '@features/plan-manifest/types';
import { HTTPMethodType } from '@lib/types';
import { U } from "@lib/utils";

export const homeApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      /** get property details */
      getProperty: builder.query<any, { tenantId: string, propertyId: string }>({
        query: ({ tenantId, propertyId }) => ({
          url: `/tenant/${U(tenantId)}/properties/${U(propertyId)}`,
          method: HTTPMethodType.GET,
        })
      }),
      /** get property devices */
      getDevices: builder.query<Device[], { tenantId: string, propertyId: string }>({
        query: ({ tenantId, propertyId }) => ({
          url: `/tenant/${U(tenantId)}/properties/${U(propertyId)}/devices`,
          method: HTTPMethodType.GET,
        })
      }),
      /** get property's manifest */
      getManifestEntries: builder.query<any, { tenantId: string, propertyId: string }>({
        query: ({ tenantId, propertyId }) => ({
          url: `/tenant/${U(tenantId)}/properties/${U(propertyId)}/manifestentries`,
          method: HTTPMethodType.GET,
        }),
      }),
      /** get a plan manifest */
      getManifest: builder.query<any, { tenantId: string, manifestId: string }>({
        query: ({ tenantId, manifestId }) => ({
          url: `/tenant/${U(tenantId)}/manifests/${U(manifestId)}/entries`,
          method: HTTPMethodType.GET,
        }),
      }),
      /* get a property manifest */
      getPropertyManifest: builder.query<PropertyManifest, {
        tenantId: string, propertyId: string, includeEntries?: boolean
      }>({
        query: ({ tenantId, propertyId, includeEntries }) => ({
          url: `/tenant/${U(tenantId)}/properties/${U(propertyId)}/manifest`,
          method: HTTPMethodType.GET,
          params: {
            include_entries: includeEntries,
          }
        }),
      }),
      getPropertyManifestEntries: builder.query<ManifestEntry[], { tenantId: string, propertyId: string }>({
        query: ({ tenantId, propertyId }) => ({
          url: `/tenant/${U(tenantId)}/properties/${U(propertyId)}/manifestentries`,
          method: HTTPMethodType.GET,
        }),
      }),
      /** update property with given deltas */
      updateProperty: builder.mutation<Property | null, { userTenantId: string, propertyId: string, body: any }>({
        query: ({ userTenantId, propertyId, body }) => ({
          url: `/tenant/${U(userTenantId)}/properties/${U(propertyId)}`,
          method: HTTPMethodType.PATCH,
          body,
        }),
      }),
      /** delete property */
      deleteProperty: builder.mutation<any, { userTenantId: string, propertyId: string }>({
        query: ({ userTenantId, propertyId }) => ({
          url: `/tenant/${U(userTenantId)}/properties/${U(propertyId)}`,
          method: HTTPMethodType.DELETE,
        }),
      }),
      /** refresh token */
      refreshPropertyToken: builder.mutation<PropertyTokenRefresh, { userTenantId: string, propertyId: string }>({
        query: ({ userTenantId, propertyId }) => ({
          url: `/tenant/${U(userTenantId)}/properties/${U(propertyId)}/token/refresh`,
          method: HTTPMethodType.POST,
        }),
      }),
      /** pings gateway/kiosk via mqtt */
      healthcheck: builder.mutation<void, { userTenantId: string, propertyId: string }>({
        query: ({ userTenantId, propertyId }) => ({
          url: `/tenant/${U(userTenantId)}/properties/${U(propertyId)}/health`,
          method: HTTPMethodType.PATCH,
        })
      }),
      /** cleans up unlinked device entities */
      cleanup: builder.mutation<void, { userTenantId: string, propertyId: string }>({
        query: ({ userTenantId, propertyId }) => ({
          url: `/tenant/${U(userTenantId)}/properties/${U(propertyId)}/cleanup`,
          method: HTTPMethodType.DELETE,
        })
      }),
      /** uploads photo for home */
      uploadHomePhoto: builder.mutation<void, { userTenantId: string, propertyId: string, file: FormData }>({
        query: ({ userTenantId, propertyId, file }) => ({
          url: `/tenant/${U(userTenantId)}/properties/${U(propertyId)}/image`,
          method: HTTPMethodType.PUT,
          body: file,
        })
      }),
      /** removes photo for home */
      deleteHomePhoto: builder.mutation<void, { userTenantId: string, propertyId: string }>({
        query: ({ userTenantId, propertyId }) => ({
          url: `/tenant/${U(userTenantId)}/properties/${U(propertyId)}/image`,
          method: HTTPMethodType.DELETE,
        })
      }),
    };
  }
});



export const {
  // endpoints in format:
  //  endpointName => useEndpointNameQuery
  useGetPropertyQuery,
  useGetDevicesQuery,
  useGetManifestEntriesQuery,
  useGetManifestQuery,
  useGetPropertyManifestQuery,
  useUpdatePropertyMutation,
  useDeletePropertyMutation,
  useHealthcheckMutation,
  usePrefetch,
  useRefreshPropertyTokenMutation,
  useCleanupMutation,
  useUploadHomePhotoMutation,
  useDeleteHomePhotoMutation,
} = homeApi;
