import { ZwaveConfig } from "@features/plan-manifest/types";
import Cancel from "@mui/icons-material/Cancel";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import Save from "@mui/icons-material/Save";
import { Box, IconButton, Stack, TextField } from "@mui/material";
import { useCallback, useState } from "react";

export type ZwaveConfigEntryProps = {
  existingEntry?: ZwaveConfig;
  handleSave: (entry: ZwaveConfig) => void;
  handleDelete?: () => void;
};

const ZwaveConfigEntry = ({ existingEntry, handleSave, handleDelete }: ZwaveConfigEntryProps) => {

  const [configId, setConfigId] = useState<number | null>(existingEntry?.parameter ?? null);
  const [configValue, setConfigValue] = useState<number | null>(existingEntry?.value ?? null);
  const [configBitmask, setConfigBitmask] = useState<number | null>(existingEntry?.bitmask ?? null);
  const [editing, setEditing] = useState<boolean>(false);

  const onSave = useCallback(() => {
    if (configId && typeof configValue === 'number') {
      handleSave({
        parameter: configId,
        value: configValue,
        bitmask: configBitmask ?? undefined,
      });

      setConfigId(null);
      setConfigValue(null);
      setConfigBitmask(null);
    }
  }, [configBitmask, configId, configValue, handleSave]);

  const onDelete = useCallback(() => {
    if (handleDelete) handleDelete();
  }, [handleDelete]);

  return (
    <Box component="form">
      <Stack direction="row" spacing={1}>
        <TextField
          size="small"
          disabled={existingEntry && !editing}
          variant="outlined"
          label="Parameter ID"
          type="number"
          value={configId ?? ''}
          onChange={(e) => setConfigId(!e.target.value ? null : Number(e.target.value))}
          required
        />
        <TextField
          size="small"
          disabled={existingEntry && !editing}
          variant="outlined"
          label="Bitmask (optional)"
          type="number"
          value={configBitmask ?? ''}
          onChange={(e) => setConfigBitmask(!e.target.value ? null : Number(e.target.value))}
        />
        <TextField
          size="small"
          disabled={existingEntry && !editing}
          variant="outlined"
          label="Value"
          type="number"
          value={configValue ?? ''}
          onChange={(e) => setConfigValue(!e.target.value ? null : Number(e.target.value))}
          required
        />
        {
          existingEntry &&
          !editing &&
          <IconButton
            size="small"
            color="primary"
            onClick={() => setEditing(true)}
          >
            <Edit />
          </IconButton>
        }
        {
          existingEntry &&
          editing &&
          <IconButton
            size="small"
            color="secondary"
            onClick={() => setEditing(false)}
          >
            <Cancel />
          </IconButton>
        }
        <IconButton
          size="small"
          color="primary"
          disabled={(existingEntry && !editing) || !configId || typeof configValue !== 'number'}
          onClick={onSave}
        >
          <Save />
        </IconButton>
        {
          existingEntry &&
          <IconButton
            size="small"
            color="warning"
            disabled={!existingEntry}
            onClick={onDelete}
          >
            <Delete />
          </IconButton>
        }
      </Stack>
    </Box>);
}


export default ZwaveConfigEntry;