import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Typography } from "@mui/material";

/* Display message that all devices are paired*/
const AllPaired = () => (
  <Box component="div" textAlign="center" p={5}>
    <Typography color="green">
      <CheckCircleIcon fontSize="large" />
    </Typography>
    <Typography component="p">
      All devices are paired.
    </Typography>
  </Box>
);

export default AllPaired;