/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updatePrefs = /* GraphQL */ `
  mutation UpdatePrefs($input: PrefsInputs!) {
    updatePrefs(input: $input) {
      id
      prefs
    }
  }
`;
