import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { TenantType } from "@features/userTenant/types";
import EditIcon from '@mui/icons-material/Edit';
import { Card, CardContent, CardHeader, CircularProgress, Divider, IconButton, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import EditNeighborhoodDialog from "./EditNeighborhoodDialog";
import NeighborhoodStatus from "./NeighborhoodStatus";
import { useMemo, useState } from "react";

type Props = {
}

const NeighborhoodDetails = (props: Props) => {
  const neighborhood = useAppSelector((state: RootState) => state.neighborhood);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const  currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const canEdit = useMemo(() => [TenantType.Builder, TenantType.System].includes(currentTenant?.tenant_type), [currentTenant?.tenant_type]);

  const [editingDetails, setEditingDetails] = useState<boolean>(false);

  return (
    <>
      <Card>
        <CardHeader
          avatar={
            !neighborhood.neighborhood?.neighborhood_id
              ? <CircularProgress />
              : <NeighborhoodStatus
                  neighborhood={neighborhood.neighborhood}
                  health={neighborhood?.health}
                  onClick={() => {}}
            />
          }
          title={neighborhood?.neighborhood?.name || 'Neighborhood Name'}
          titleTypographyProps={{
            variant: 'h6'
          }}
          action={
            <Tooltip title="Edit details" placement="top" arrow>
              <span>
                <IconButton onClick={() => setEditingDetails(true)} disabled={!canEdit || !neighborhood.neighborhood?.neighborhood_id}>
                  <EditIcon color={canEdit ? 'warning' : 'disabled'} />
                </IconButton>
              </span>
            </Tooltip>
          }
        />
        <Divider />
        <CardContent sx={{ height: '6em' }}>
          {
            !neighborhood.neighborhood?.neighborhood_id
              ? <Stack spacing={1}>
                <Typography><Skeleton variant="rectangular" /></Typography>
                <Typography><Skeleton variant="rectangular" /></Typography>
              </Stack>
              : <Stack spacing={1}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography color="text.secondary">
                      Managed by
                    </Typography>
                    <Typography>
                      {neighborhood.neighborhood?.property_manager_name || 'Manager Name'}
                    </Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography color="text.secondary">
                      Built by
                    </Typography>
                    <Typography>
                    {neighborhood.neighborhood?.builder_name || 'Builder Name'}
                    </Typography>
                  </Stack>
                </Stack>
          }
        </CardContent>
      </Card>
      <EditNeighborhoodDialog
        open={editingDetails}
        onClose={() => setEditingDetails(false)}
      />
    </>
  );
}

export default NeighborhoodDetails;