import { Category, Measure } from "@lib/labels";
import AddIcon from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import { Checkbox, Divider, FormControlLabel, IconButton, Stack, TextField } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DeviceStateType, DeviceStateValue, SceneTemplate, SceneTemplateAction, SceneTemplateActionType, SceneTemplateDelayedAction, SceneTemplateDeviceCriteria, SceneTemplateDeviceStateAction, SceneTemplateMultipleAction } from "../types";
import SceneTemplateDeviceCriteriaSelector from "./SceneTemplateDeviceCriteriaSelector";
import SceneTemplateDeviceStateSelector from "./SceneTemplateDeviceStateSelector";

interface SceneTemplateActionProps {
  sceneTemplate: SceneTemplate;
  onChange: (newAction: SceneTemplateAction) => void;
}

/** Return the given action represented as a "multiple" action */
function packAction(action: SceneTemplateAction): SceneTemplateMultipleAction {
  if (action.type === SceneTemplateActionType.MULTIPLE) return action;
  return {
    type: SceneTemplateActionType.MULTIPLE,
    actions: [action],
  }
}

export default function SceneTemplateActionEditor(props: SceneTemplateActionProps) {
  const { sceneTemplate, onChange } = props;

  /* For now, the UI will allow a single "multiple" action with any amount of child actions */
  const [newAction, setNewAction] = useState<SceneTemplateMultipleAction>({
    type: SceneTemplateActionType.MULTIPLE,
    actions: [],
  });

  useEffect(() => {
    if (sceneTemplate.action) setNewAction(packAction(sceneTemplate.action));
  }, [sceneTemplate.action]);

  const updateChildAction = useCallback((childActionIndex: number, childAction: SceneTemplateAction) => {
    const updatedAction = ({
      ...newAction,
      actions: newAction.actions.map((action, actionIndex) => actionIndex === childActionIndex ? childAction : action),
    });

    setNewAction(updatedAction);
    onChange(updatedAction);
  }, [newAction, onChange]);

  const deleteChildAction = useCallback((childActionIndex: number) => {
    const updatedAction = ({
      ...newAction,
      actions: newAction.actions.filter((action, actionIndex) => actionIndex !== childActionIndex),
    });

    setNewAction(updatedAction);
    onChange(updatedAction);
  }, [newAction, onChange]);

  const addNewAction = useCallback(() => {
    const updatedAction: SceneTemplateMultipleAction = {
      ...newAction,
      actions: [
        ...newAction.actions ?? [],
        {
          type: SceneTemplateActionType.APPLY_DEVICE_STATE,
          state_type: DeviceStateType.BINARY_SWITCH,
          state_value: true,
          device_criteria: {
            category: Category.light,
            measure: Measure.switch,
            areas: [],
          }
        }
      ]
    };
    setNewAction(updatedAction);
    onChange(updatedAction);
  }, [newAction, onChange]);

  const handleChangeDeviceCriteria = useCallback((newDeviceCriteria: SceneTemplateDeviceCriteria, childActionIdx: number) => {
    const childAction = newAction.actions[childActionIdx];

    switch (childAction.type) {
      case SceneTemplateActionType.APPLY_DEVICE_STATE:
        updateChildAction(childActionIdx, {
          ...childAction,
          device_criteria: newDeviceCriteria,
        } satisfies SceneTemplateDeviceStateAction);
        break;
      case SceneTemplateActionType.DELAYED_ACTION:
        if (childAction.delayed_action.type === SceneTemplateActionType.APPLY_DEVICE_STATE) {
          updateChildAction(childActionIdx, {
            ...childAction,
            delayed_action: {
              ...childAction.delayed_action,
              device_criteria: newDeviceCriteria,
            } satisfies SceneTemplateDeviceStateAction,
          });
        }
        break;
      default:
        break;
    }
  }, [newAction.actions, updateChildAction]);

  const handleChangeDeviceState = useCallback(
    (newDeviceStateType: DeviceStateType, newDeviceStateValue: DeviceStateValue, childActionIdx: number) => {
      const childAction = newAction.actions[childActionIdx];

      switch (childAction.type) {
        case SceneTemplateActionType.APPLY_DEVICE_STATE:
          updateChildAction(childActionIdx, {
            ...childAction,
            state_type: newDeviceStateType,
            state_value: newDeviceStateValue,
          } satisfies SceneTemplateDeviceStateAction);
          break;
        case SceneTemplateActionType.DELAYED_ACTION:
          if (childAction.delayed_action.type === SceneTemplateActionType.APPLY_DEVICE_STATE) {
            updateChildAction(childActionIdx, {
              ...childAction,
              delayed_action: {
                ...childAction.delayed_action,
                state_type: newDeviceStateType,
                state_value: newDeviceStateValue,
              },
            } satisfies SceneTemplateDelayedAction<SceneTemplateDeviceStateAction>);
          }
          break;
        default:
          break;
      }
    }, 
    [newAction.actions, updateChildAction]
  );

  const getChildActionDeviceStateAction = useCallback((childAction: SceneTemplateAction): SceneTemplateDeviceStateAction | null => {
    switch (childAction.type) {
      case SceneTemplateActionType.APPLY_DEVICE_STATE:
        return childAction;
      case SceneTemplateActionType.DELAYED_ACTION:
        return getChildActionDeviceStateAction(childAction.delayed_action);
      default:
        return null;
    }
  }, []);

  const renderDeviceStateChildActionControls = useCallback((childAction: SceneTemplateAction, childActionIdx: number) => {
    const deviceStateAction = getChildActionDeviceStateAction(childAction);
    if (!deviceStateAction) return null;

    return <>
      <SceneTemplateDeviceCriteriaSelector
        deviceCriteria={deviceStateAction.device_criteria}
        onChange={(newCriteria) => handleChangeDeviceCriteria(newCriteria, childActionIdx)}
      />
      <SceneTemplateDeviceStateSelector
        stateType={deviceStateAction.state_type}
        stateValue={deviceStateAction.state_value}
        deviceCriteria={deviceStateAction.device_criteria}
        onChange={(newStateType, newStateValue) => handleChangeDeviceState(newStateType, newStateValue, childActionIdx)}
      />
    </>
  }, [getChildActionDeviceStateAction, handleChangeDeviceCriteria, handleChangeDeviceState]);

  const handleChangeDelay = useCallback((delayMs: number | undefined | null, childActionIdx: number) => {
    const childAction = newAction.actions[childActionIdx];

      switch (childAction.type) {
        case SceneTemplateActionType.APPLY_DEVICE_STATE:
          updateChildAction(childActionIdx, (typeof delayMs === 'number')
          ? {
            type: SceneTemplateActionType.DELAYED_ACTION,
            delay_ms: delayMs,
            delayed_action: childAction,
          } satisfies SceneTemplateDelayedAction<SceneTemplateDeviceStateAction>
          : childAction);
          break;
        case SceneTemplateActionType.DELAYED_ACTION:
          if (childAction.delayed_action.type === SceneTemplateActionType.APPLY_DEVICE_STATE) {
            updateChildAction(childActionIdx, (typeof delayMs === 'number')
              ? {
                ...childAction,
                delayed_action: childAction.delayed_action,
                delay_ms: delayMs,
              } satisfies SceneTemplateDelayedAction<SceneTemplateDeviceStateAction>
              : childAction
            );
          }
          break;
        default:
          break;
      }
  }, [newAction.actions, updateChildAction]);

  const handleEnableDelay = useCallback((delayEnabled: boolean, childActionIdx: number) => {
    handleChangeDelay(delayEnabled ? 1000 : undefined, childActionIdx);
  }, [handleChangeDelay]);

  const getChildActionDelay = useCallback((childAction: SceneTemplateAction): number | undefined | null => {
    switch (childAction.type) {
      case SceneTemplateActionType.DELAYED_ACTION:
        return childAction.delay_ms;
      default:
        return null;
    }
  }, []);

  const renderDelayedChildActionControls = useCallback((childAction: SceneTemplateAction, childActionIdx: number) => {
    const delayMs = getChildActionDelay(childAction);

    return <>
      <Stack direction="row">
        <FormControlLabel
          sx={{ flex: '1 1 0' }}
          control={
            <Checkbox
              checked={typeof delayMs === 'number'}
              onChange={(e, v) => handleEnableDelay(v, childActionIdx)}
            />
          }
          label="Enable delay"
        />
        {
          typeof delayMs === 'number'
          ? <TextField
              label="Delay (seconds)"
              type="number"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]+', step: 0.001, min: 1 }}
              value={delayMs / 1000}
              onChange={(e) => handleChangeDelay(Number(e.target.value) * 1000, childActionIdx)}
            />
          : null
        }
      </Stack>
    </>
  }, [getChildActionDelay, handleChangeDelay, handleEnableDelay]);

  return (
    <Stack direction="column" sx={{ flex: '1 1 0' }} spacing={3} divider={<Divider />}>
      {
        newAction.actions
          .map(
            (childAction, childActionIdx) => (
            <Stack sx={{ flex: '0 auto', width: '100%' }} direction="column" key={`${newAction.type}-action-${childAction}`}>
              <Stack direction="row">
                <Stack direction="column" sx={{ flex: '1 1 auto' }} spacing={2}>
                  {renderDeviceStateChildActionControls(childAction, childActionIdx)}
                  {renderDelayedChildActionControls(childAction, childActionIdx)}
                </Stack>
                <Stack direction="column" sx={{ ml: 2, mr: 2, alignItems: 'center', justifyContent: 'center' }}>
                  <IconButton color="error" onClick={() => deleteChildAction(childActionIdx)}>
                    <Delete />
                  </IconButton>
                </Stack>
              </Stack>
            </Stack>
          ))
      }
      <IconButton onClick={addNewAction} size="large" color="primary"><AddIcon /></IconButton>
    </Stack>
  );
}
