import HomeownerList from "@features/all-homeowners/components/HomeownerList";
import HomeownersProvider from "@features/all-homeowners/components/HomeownersProvider";
import Header from "@features/Common/Header";
import { FC } from "react";


/** Wrapper for /homeowners */
const AllHomeowners: FC = () => (
  <HomeownersProvider>
    <Header />
    <HomeownerList />
  </HomeownersProvider>
);

export default AllHomeowners;
