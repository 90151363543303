import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { DashboardView, DashboardViewType, ViewPreferences } from '@features/settings/types';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { useEffect, useMemo, useState } from 'react';

type Props = {
  onChange: (type: DashboardViewType) => void;
  view: DashboardView;
}

const ViewTypeToggle = ({ onChange, view }: Props) => {
  const preferenceState = useAppSelector((state: RootState) => state.preferences);
  const preferences = useMemo(() => preferenceState.preferences, [preferenceState.preferences]);
  const viewPreference = useMemo(() => (preferences?.views as ViewPreferences)?.[view], [preferences?.views, view]);

  const [value, setValue] = useState<DashboardViewType>(viewPreference || DashboardViewType.cards);

  const handleViewTypeChange = (_e: any, newValue: DashboardViewType) => {
    if (newValue !== null) setValue(newValue);
  }

  useEffect(() => {
    onChange(value)
  }, [value, onChange]);

  /** MAIN RENDER */
  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={handleViewTypeChange}
      color="primary"
    >
      <ToggleButton value={DashboardViewType.list}>
        <Tooltip title="View as List" placement="top" arrow>
          <TableRowsIcon />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value={DashboardViewType.cards}>
        <Tooltip title="View as Gallery" placement="top" arrow>
          <ViewModuleIcon />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default ViewTypeToggle;