import { PropertyManifestEntry } from "@features/home-manifest/types";
import { Device } from "@features/home/types";
import { Sensor } from "@features/plan-manifest/types";
import { Stack } from "@mui/material";
import EntryToPair from "./EntryToPair";

type Props = {
  entries: PropertyManifestEntry[];
  devices: Device[];
  onPair: (data: [PropertyManifestEntry, Sensor] | null) => void;
  onUnpair: (
    manifestEntry: PropertyManifestEntry,
    sensor: Sensor,
  ) => Promise<void>;
  onRemove: (manifestEntry: PropertyManifestEntry) => void;
  onAdd: (manifestEntry: PropertyManifestEntry) => void;
  onAddSmartstart: (manifestEntry: PropertyManifestEntry) => void;
  onReset: (manifestEntry: PropertyManifestEntry) => void;
  onManualUnlink: (manifestEntry: PropertyManifestEntry) => void;
  onManualLink: (manifestEntry: PropertyManifestEntry) => void;
}

/* Displays device for pairing */
const DevicesToPair = ({
  entries,
  onPair,
  onUnpair,
  onRemove,
  onAdd,
  onAddSmartstart,
  onReset,
  onManualLink,
  onManualUnlink
}: Props) => (
  <Stack direction="row" spacing={2} m={2} justifyContent="center" sx={{ width: 'inherit'}}>
    {
      entries.map((entry) => (
        <EntryToPair
          key={`to-pair-${entry.manifest_entry_id}`}
          entry={entry}
          entries={entries}
          onPair={onPair}
          onUnpair={onUnpair}
          onRemove={onRemove}
          onAdd={onAdd}
          onAddSmartstart={onAddSmartstart}
          onReset={onReset}
          onManualUnlink={onManualUnlink}
          onManualLink={onManualLink}
        />
      ))
    }
  </Stack>
);

export default DevicesToPair;
