import HomeList from "@features/all-homes/components/HomeList";
import HomesProvider from "@features/all-homes/components/HomesProvider";
import Header from "@features/Common/Header";
import { FC } from "react";

/** Wrapper for /homes */
const AllHomes: FC = () => {
  return (
    <>
      <Header />
      <HomesProvider><HomeList /></HomesProvider>
    </>
  );
}

export default AllHomes;