import AppTextField from '@features/home/components/AppTextField';
import { Stack } from "@mui/material";

const SceneTemplateFields = () => {

  return (
    <Stack spacing={1.3}>
      <AppTextField
        variant="outlined"
        label="Name"
        placeholder=""
        name="name"
        required
        fullWidth
        size="small"
      />
      <AppTextField
        name="description"
        label="Description"
        type="text"
        fullWidth
      />
    </Stack>);
}

export default SceneTemplateFields;