import { createSlice } from "@reduxjs/toolkit";
import { Neighborhood } from "./types";

const nullNeighborhood = {
  neighborhood_id: '',
  created: 0,
  updated: 0,
  name: '',
} as Neighborhood;

type NeighborhoodState = {
  neighborhood: Neighborhood | null;
  health?: Neighborhood['health_status'] | null;
  loadingHealth?: boolean;
}

const initialState: NeighborhoodState= {
  neighborhood: nullNeighborhood,
};

export const neighborhoodReducer = createSlice({
  name: 'neighborhood',
  initialState,
  reducers: {
    setNeighborhood: (state: NeighborhoodState, action: any) => {
      if (action.payload) {
        state.neighborhood = action.payload;
      } else {
        state.neighborhood = nullNeighborhood;
      }
    },
    setHealth: (state: NeighborhoodState, action: any) => {
      if (action.payload) {
        state.health = action.payload;
      } else {
        state.health = undefined;
      }
    },
    setLoadingHealth: (state: NeighborhoodState, action: any) => {
      if (action.payload) {
        state.loadingHealth = action.payload;
      } else {
        state.loadingHealth = undefined;
      }
    }
  },
});

export default neighborhoodReducer.reducer;

export const {
  setNeighborhood,
  setHealth,
  setLoadingHealth,
} = neighborhoodReducer.actions;