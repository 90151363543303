import { CardHeader, CardContent, Grid, Card, Divider } from "@mui/material";
import CurrentUsage from "./CurrentUsage";
import NeighborhoodHistoricalUsage from "./NeighborhoodHistoricalUsage";

type Props = {

};

const NeighborhoodUsageView: React.FC<Props> = function NeighborhoodUsageView(props: Props) {
  return <Grid item xs={1} container columns={{ xs: 1, md: 1 }} sx={{ p: '1em' }} spacing={2}>
    <Grid item xs={2}>
      <Card sx={{ minHeight: '16em' }}>
        <CardHeader title="Current Readings" />
        <Divider />
        <CardContent>
          <CurrentUsage />
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={2}>
      <Card sx={{ minHeight: '34em' }}>
        <CardHeader title="Usage History" />
        <Divider />
        <CardContent>
          <NeighborhoodHistoricalUsage />
        </CardContent>
      </Card>
    </Grid>
  </Grid>
}

export default NeighborhoodUsageView;
