import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import FileIcon from '@mui/icons-material/FileUpload';
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  InputLabel, Select,
  SelectChangeEvent,
  Stack,
  TextField
} from "@mui/material";
import { useSnackbar } from "notistack";
import {
  ChangeEvent,
  useCallback, useMemo, useRef,
  useState
} from "react";
import { useCreateDocumentMutation } from "../api";
import { DEFAULT_DOCUMENT_TTL, DocumentApiResponse, DocumentType } from '../types';
import DocumentTypeList from "./DocumentTypes";

const ACCEPTED_FILE_TYPES = [
  '.pdf',
  '.svg',
  '.dxf',
  '.jpg',
  '.jpeg',
  '.png',
  '.fbx',
].join(',');


type Props = {
  open: boolean;
  onClose: (changes: boolean) => void;
}

const UploadModal = ({ open, onClose }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const [docType, setDocType] = useState<DocumentType>(DocumentType.OTHER);
  const [docDescription, setDocDescription] = useState<string | null>(null);
  const [docFile, setDocFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);

  const fileRef = useRef<HTMLInputElement>(null);

  const [createDocument] = useCreateDocumentMutation();

  /** Uploads document */
  const handleSubmit = useCallback(async () => {
    if (docType && docFile) {
      setUploading(true);

      try {
        const docResponse = await createDocument({
          userTenantId: currentTenant?.tenant_id || '',
          path: docFile.name,
          body: {
            name: docFile.name,
            description: docDescription || '',
            document_type: docType,
            path: docFile.name,
            ttl: DEFAULT_DOCUMENT_TTL,
          },
        });

        const errorDetails = (docResponse as ApiErrorResponse)?.error;

        if (errorDetails) {
          enqueueSnackbar("Couldn't create document:", {
            key: "document-error",
            content: (
              <ExpandableSnack
                id="document-error"
                message={"Couldn't create document:"}
                variant={SnackType.error}
                detail={getErrorMessage(errorDetails)}
              />),
          });
        } else {
          const formData = new FormData();

          const doc = (docResponse as DocumentApiResponse).data;

          Object.entries(doc.upload_headers.fields).forEach(([k, v]) => {
            formData.append(k, v);
          });

          formData.append('file', docFile);

          const result = await fetch(doc.upload_headers.url, {
            method: 'POST',
            body: formData,
          });

          if (!result.ok) {
            enqueueSnackbar("Couldn't upload document:", {
              key: "document-error",
              content: (
                <ExpandableSnack
                  id="document-error"
                  message={"Couldn't upload document:"}
                  variant={SnackType.error}
                  detail={result.statusText || result.status}
                />),
            });
          }

          onClose(true);
        }
      } catch (e: any) {
        enqueueSnackbar("Couldn't create document:", {
          key: "document-error",
          content: (
            <ExpandableSnack
              id="document-error"
              message={"Couldn't create document:"}
              variant={SnackType.error}
              detail={e instanceof Error ? e.message : `${e}`}
            />),
        });
      } finally {
        setUploading(false);
      }
    }
  }, [
    createDocument,
    docDescription,
    docFile,
    docType,
    onClose,
    enqueueSnackbar,
    currentTenant?.tenant_id,
  ]);

  const handleCategoryChange = useCallback((event: SelectChangeEvent<DocumentType | ''>) => {
    setDocType(event.target.value as DocumentType || null);
  }, []);

  const handleDescriptionChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setDocDescription(event.target.value);
  }, []);

  const openFileBrowser = useCallback(() => {
    fileRef.current?.click();
  }, []);

  return (
    <Dialog open={open} scroll="paper" fullWidth onClose={onClose}>
      <DialogTitle>New document</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={1} sx={{ mt: 1 }}>
          <FormControl fullWidth>
            <InputLabel>Document Category</InputLabel>
            <Select
              value={docType || ''}
              label="Document Category"
              onChange={handleCategoryChange}
            >
              {DocumentTypeList()}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            multiline
            minRows={2}
            label="Description"
            value={docDescription || ''}
            onChange={handleDescriptionChange}
          />
          <FormControl fullWidth>
            <FormLabel>
              <input
                ref={fileRef}
                type="file"
                accept={ACCEPTED_FILE_TYPES}
                style={{ display: 'none' }}
                onChange={(event) => {
                  if (event.target.files?.length === 1) {
                    setDocFile(event.target.files[0]);
                  }
                }}
              />
              <Button
                variant={docFile ? 'outlined' : 'contained'}
                startIcon={<FileIcon />}
                onClick={openFileBrowser}
                color={docFile ? 'success' : 'inherit'}
              >
                <span>
                 {docFile ? docFile.name : 'Select a file'}
                </span>
              </Button>
            </FormLabel>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!docFile || !docType}
          loading={uploading}
        >
          Upload
        </LoadingButton>
      </DialogActions>
  </Dialog >
  );
}

export default UploadModal;