import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { PropertyManifestEntry } from "@features/home-manifest/types";
import { Device } from "@features/home/types";
import { ManifestDevice, Sensor } from "@features/plan-manifest/types";
import { Box, Modal, Typography } from "@mui/material";
import { useMemo } from "react";
import UnpairedDeviceList from "../UnpairedDeviceList";

type Props = {
  allowedDeviceIds?: string[];
  isOpen: boolean;
  entryArr: PropertyManifestEntry[];
  onClose: () => void;
  onSelect: (device: Device) => void;
  sensorType: string | null;
}

/* Displays Device Pairing Modal */
const DeviceModal = ({
  isOpen,
  entryArr,
  onClose,
  onSelect,
}: Props) => {
  const property = useAppSelector((state: RootState) => state.property);

  const devices = useMemo(() => property.devices || [], [property.devices]);

  const sensorsByEntityId: Record<string, Sensor> = useMemo(() => (
    entryArr.reduce((partialSensorsByEntityId, e) => ({
      ...partialSensorsByEntityId,
      ...(((e.device as ManifestDevice)?.sensors || [])
        .filter(s => e.sensor_map?.[s.sensor_id ?? s.friendly_name])
        .reduce<Record<string, Sensor>>((sensorsToAdd, s) => ({
          ...sensorsToAdd,
          [e.sensor_map?.[s.sensor_id ?? s.friendly_name]!]: s,
        }), {})),
    }), {})
  ), [entryArr]);

  const unpairedDevices = useMemo(() => (
    [...devices]
      .filter((dev) => !sensorsByEntityId[dev.data.entity_id])
      .filter((dev) => entryArr.some(me => me.gateway_device_id && me.gateway_device_id === dev.data.device_id))
      /* Sort by value updated date descending */
      .sort((a, b) => Number(b.datetime) - Number(a.datetime))
  ), [devices, entryArr, sensorsByEntityId]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 'auto',
          height: unpairedDevices?.length ? 600 : 300,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2">
          Choose device
        </Typography>
        <Typography sx={{ mt: 2 }}>
          The following devices have been detected. Please choose the item from the list below corresponding to the device being provisioned.
        </Typography>
        {
          unpairedDevices?.length > 0 &&
          <UnpairedDeviceList
            devices={unpairedDevices}
            onSelect={onSelect}
          />
        }
      </Box>
    </Modal>
  );
}

export default DeviceModal;