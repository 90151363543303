import { EnergyUsage, UtilGroup } from "@features/all-utility-groups/types";
import { riotApi } from "@features/aws-user/api";
import { Device } from "@features/home/types";
import { HTTPMethodType } from "@lib/types";
import { U } from "@lib/utils";

export const energyApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      energyStatus: builder.query<UtilGroup, {
        userTenantId: string,
        groupId: string,
        builderId: string,
      }>({
        query: ({ userTenantId, groupId, builderId }) => ({
          url: `tenant/${U(userTenantId)}/groups/${U(groupId)}/energy`,
          params: {
            builder_id: builderId,
          },
        }),
      }),
      consumptionDetails: builder.query<{ [k: string]: Device[]; }, {
        userTenantId: string,
        groupId: string,
        builderId: string,
      }>({
        query: ({ userTenantId, groupId, builderId }) => ({
          url: `tenant/${U(userTenantId)}/groups/${U(groupId)}/energy/consumption`,
          params: {
            builder_id: builderId,
          },
        }),
      }),
      createShedRequest: builder.mutation<UtilGroup, {
        userTenantId: string,
        groupId: string,
        builderId: string,
        percentage: number,
      }>({
        query: ({ userTenantId, groupId, builderId, percentage }) => ({
          url: `tenant/${U(userTenantId)}/groups/${U(groupId)}/energy/request`,
          method: HTTPMethodType.POST,
          params: {
            builder_id: builderId,
            percentage,
          },
        }),
      }),
      removeShedRequest: builder.mutation<UtilGroup, {
        userTenantId: string,
        groupId: string,
        builderId: string,
      }>({
        query: ({ userTenantId, groupId, builderId }) => ({
          url: `tenant/${U(userTenantId)}/groups/${U(groupId)}/energy/request`,
          method: HTTPMethodType.DELETE,
          params: {
            builder_id: builderId,
          },
        }),
      }),
      setPowerLimit: builder.mutation<UtilGroup, {
        userTenantId: string,
        groupId: string,
        builderId: string,
        watts: number,
      }>({
        query: ({ userTenantId, groupId, builderId, watts }) => ({
          url: `tenant/${U(userTenantId)}/groups/${U(groupId)}/energy/limit`,
          method: HTTPMethodType.POST,
          params: {
            builder_id: builderId,
            watts,
          },
        }),
      }),
      removePowerLimit: builder.mutation<UtilGroup, {
        userTenantId: string,
        groupId: string,
        builderId: string,
      }>({
        query: ({ userTenantId, groupId, builderId }) => ({
          url: `tenant/${U(userTenantId)}/groups/${U(groupId)}/energy/limit`,
          method: HTTPMethodType.DELETE,
          params: {
            builder_id: builderId,
          },
        }),
      }),
      resetPower: builder.mutation<UtilGroup, {
        userTenantId: string,
        groupId: string,
        builderId: string,
      }>({
        query: ({ userTenantId, groupId, builderId }) => ({
          url: `tenant/${U(userTenantId)}/groups/${U(groupId)}/energy/clear`,
          method: HTTPMethodType.DELETE,
          params: {
            builder_id: builderId,
          },
        }),
      }),
      setRealtime: builder.mutation<EnergyUsage, {
        userTenantId: string,
        groupId: string,
        builderId: string,
        realtime: EnergyUsage,
      }>({
        query: ({ userTenantId, groupId, builderId, realtime }) => ({
          url: `tenant/${U(userTenantId)}/groups/${U(groupId)}/energy/realtime`,
          method: HTTPMethodType.PUT,
          params: {
            builder_id: builderId,
          },
          body: realtime,
        }),
      })
    };
  },
});

export const {
  useCreateShedRequestMutation,
  useEnergyStatusQuery,
  useRemovePowerLimitMutation,
  useRemoveShedRequestMutation,
  useSetPowerLimitMutation,
  useResetPowerMutation,
  useConsumptionDetailsQuery,
  useSetRealtimeMutation,
} = energyApi;