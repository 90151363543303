import { riotApi } from "@features/aws-user/api";
import { Category, Measure } from "@lib/labels";
import { U } from "@lib/utils";
import { Interval } from "./types";


export const homeAnalyticsApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      intervalReadings: builder.query<any, {
        userTenantId: string,
        propertyId: string,
        category: Category,
        measure: Measure,
        interval: Interval,
      }>({
        query: ({ userTenantId, propertyId, category, measure, interval }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/analytics/readings`,
          params: {
            category,
            measure,
            interval,
          },
        }),
      }),
      averageReadings: builder.query<any, {
        userTenantId: string,
        category: Category,
        measure: Measure,
        interval: Interval,
        zip: string | undefined,
        plan: string | undefined,
      }>({
        query: ({ userTenantId, category, measure, interval, zip, plan }) => ({
          url: `tenant/${U(userTenantId)}/system/analytics/readings/average`,
          params: {
            category,
            measure,
            interval,
            zip_code: zip,
            plan_id: plan,
          },
        }),
      }),
    };
  }
});

export const {
  useIntervalReadingsQuery,
  useAverageReadingsQuery,
} = homeAnalyticsApi;