import { useAppDispatch, useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { pollingInterval } from "@features/tenant-selector/types";
import { FC, ReactElement, useEffect, useMemo } from "react";
import { useNeighborhoodsQuery } from "../api";
import { setNeighborhoods } from "../slice";

const NeighborhoodsProvider: FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const {
    currentData: neighborhoods,
  } = useNeighborhoodsQuery({
    userTenantId: currentTenant?.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id,
    pollingInterval,
  });

  useEffect(() => {
    if (neighborhoods) {
      dispatch(setNeighborhoods(neighborhoods));
    }
  }, [dispatch, neighborhoods]);

  return children as ReactElement;
}

export default NeighborhoodsProvider;