import { riotApi } from "@features/aws-user/api";
import { U } from "@lib/utils";


export const weatherApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      currentWeather: builder.query<any, {
        userTenantId: string,
        propertyId: string,
      }>({
        query: ({ userTenantId, propertyId }) => ({
          url: `/tenant/${U(userTenantId)}/properties/${U(propertyId)}/weather/current`,
        }),
      }),
      weatherForecast: builder.query<any, {
        userTenantId: string,
        propertyId: string,
      }>({
        query: ({ userTenantId, propertyId }) => ({
          url: `/tenant/${U(userTenantId)}/properties/${U(propertyId)}/weather/forecast`,
        }),
      }),
    };
  }
})

export const {
  useCurrentWeatherQuery,
  useWeatherForecastQuery,
} = weatherApi;