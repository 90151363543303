/**
 * Common Data Types
 */

import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { CognitoUser } from "amazon-cognito-identity-js";

 export type Option<T> = {
   label: string;
   value: T;
 }

export type AnalyticsTimeseries = {
  time:  string;
  value: number;
};
/** Response from the analytics queries */
export type AnalyticsSet = {
   Type: string;
   ColumnInfo: string[],
   Rows: (string|number|AnalyticsTimeseries)[][],
 };

export interface AuthState {
  isLoggedIn: Boolean;
  user: CognitoUser | null;
  token: string | null;
}

export enum HTTPMethodType {
  GET = 'GET',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
  POST = 'POST',
}

export type ApiErrorResponse = {
  error: FetchBaseQueryError | SerializedError;
}

export enum SnackType {
  'default',
  'error',
  'success',
  'warning',
  'info'
}

export interface HassEntity {
  entity_id: string;
  state: string;
  last_changed: string;
  last_updated: string;
  attributes: {
    [key: string]: any;
  };
}