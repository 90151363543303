import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import HomeOem from '@features/home-oem/components/HomeOem';
import { Box, Typography } from '@mui/material';

const HomeOemView = () => {
  const property = useAppSelector((state: RootState) => state.property);

  /** MAIN RENDER */
  return (
    <Box
      component="div"
      sx={{
        margin: '1em',
        padding: '2em',
      }}
    >
      <Typography variant="h4" sx={{ mb: 6 }}>
        OEM Devices at {property?.name}
      </Typography>
      <HomeOem />
    </Box>
  );

}

export default HomeOemView;