import { useAppDispatch, useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { pollingInterval } from '@features/tenant-selector/types';
import { FC, ReactElement, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useNeighborhoodQuery } from '../api';
import { setNeighborhood } from '../slice';


const NeighborhoodProvider: FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const pathParams = useParams();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const neighborhoodId = useMemo(() => pathParams?.neighborhood_id || '', [pathParams?.neighborhood_id]);

  const {
    currentData: neighborhood,
  } = useNeighborhoodQuery({
    userTenantId: currentTenant?.tenant_id || '',
    neighborhoodId,
  }, {
    skip: !currentTenant?.tenant_id || !neighborhoodId,
    pollingInterval,
  });

  useEffect(() => {
    if (neighborhoodId && neighborhood?.neighborhood_id) {
      dispatch(setNeighborhood(neighborhood));
    } else {
      dispatch(setNeighborhood(null));
    }
  }, [dispatch, neighborhood, neighborhoodId]);

  return children as ReactElement;
}

export default NeighborhoodProvider;