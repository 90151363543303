import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import ExpandableSnack from '@features/Common/ExpandableSnack';
import { ApiErrorResponse, SnackType } from '@lib/types';
import { formatDate, getErrorMessage } from '@lib/utils';
import SubjectIcon from '@mui/icons-material/Subject';
import { LoadingButton } from '@mui/lab';
import { Button, Divider, List, ListItem, ListItemIcon, ListItemText, Stack, TextField, Typography } from '@mui/material';
import { useSnackbar } from "notistack";
import { ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { RIOT_BLUE } from 'theme';
import { useAddNoteMutation, useSupportCaseQuery } from '../api';
import { SupportCase } from '../types';

const CaseNotes = () => {
  const { enqueueSnackbar } = useSnackbar();

  const supportCase = useAppSelector((state: RootState) => state.supportCase.case as SupportCase);
  const property = useAppSelector((state: RootState) => state.property);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant.builder_color || RIOT_BLUE, [currentTenant.builder_color]);

  const [addingNote, setAddingNote] = useState<boolean>(false);
  const [note, setNote] = useState<string>('');

  const handleAddNoteMode = useCallback(() => {
    setAddingNote(!addingNote);
  }, [addingNote]);

  useEffect(() => {
    if (!addingNote) setNote('');
  }, [addingNote]);

  const [
    addNote,
  ] = useAddNoteMutation();

  const {
    refetch: refetchCase,
  } = useSupportCaseQuery({
    userTenantId: currentTenant?.tenant_id || '',
    propertyId: property?.property_id || '',
    caseId: supportCase?.case_id || '',
  }, {
    skip: !currentTenant?.tenant_id || !property?.property_id || !supportCase?.case_id,
  });


  const handleSaveNote = useCallback(async () => {
    const result = await addNote({
      userTenantId: currentTenant?.tenant_id || '',
      propertyId: property?.property_id || '',
      caseId: supportCase?.case_id || '',
      note,
    });

    const errorDetails = (result as ApiErrorResponse)?.error;
    if (errorDetails) {
      enqueueSnackbar("Couldn't add a note to the case:", {
        key: "add-case-note-error",
        content: (
          <ExpandableSnack
            id="add-case-note-error"
            message={"Couldn't add a note to the case:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("Updated case", {
        variant: "success",
      });
      refetchCase();
      setAddingNote(false);
    }
  }, [addNote, currentTenant?.tenant_id, enqueueSnackbar, note, property?.property_id, refetchCase, supportCase?.case_id]);

  return (
    <>
      <Typography variant="h6">Notes</Typography>
      {
        !addingNote &&
        <List
          dense
          sx={{
            padding: 1,
            maxHeight: '10em',
            overflow: 'auto'
          }}
        >
        {
          supportCase?.notes?.map((nn, idx) => (
            <Fragment key={`note-${idx}`}>
              <ListItem
                sx={{
                  alignItems: 'start',
                }}
              >
                <ListItemIcon sx={{
                  marginTop: '4px',
                }}>
                  <SubjectIcon htmlColor={themeColor} />
                </ListItemIcon>
                <ListItemText disableTypography>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography>
                      {nn.note || ''}
                    </Typography>
                    <Stack sx={{ minWidth: '10em'}}>
                      <Typography variant="caption">
                        {nn.username || ''}
                      </Typography>
                      <Divider />
                      <Typography variant="caption">
                        {formatDate(nn.datetime).fromNow()}
                      </Typography>
                    </Stack>
                  </Stack>
                </ListItemText>
              </ListItem>
              <Divider component="li" sx={{ borderColor: themeColor }} />
            </Fragment>))
        }
        </List>
      }
      {
        addingNote &&
        <TextField
          value={note}
          multiline
          rows={3}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setNote(e.target.value)}
        />
      }
      <Stack direction="row" spacing={1}>
        <Button size="small" variant="contained" onClick={handleAddNoteMode}>
          {addingNote ? 'Cancel' : 'Add a note'}
        </Button>
        {
          addingNote &&
          <LoadingButton size="small" variant="contained" onClick={handleSaveNote}>
            Submit
          </LoadingButton>
        }
      </Stack>
    </>
  );
}

export default CaseNotes;