import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { usePrefs } from "@features/settings/slice";
import { DashboardView, DashboardViewType, Preferences, ViewPreferences } from "@features/settings/types";
import TableRowsIcon from '@mui/icons-material/TableRows';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { Card, CardContent, Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import { useCallback, useMemo } from 'react';

const ViewsSection = () => {
  const prefProvider = usePrefs();

  const preferenceState = useAppSelector((state: RootState) => state.preferences);

  const preferences = useMemo(() => preferenceState.preferences, [preferenceState.preferences]);
  const viewPreferences = useMemo(() => preferences.views || {}, [preferences.views]);

  const handleChangeViewTypePreference = useCallback((view, value) => {
    prefProvider.setPrefs({
      ...preferences,
      views: {
        ...viewPreferences,
        [view]: value,
      } as ViewPreferences,
    } as Preferences, () => prefProvider.getPrefs(() => {}));
  }, [prefProvider, preferences, viewPreferences]);

  return (
    <Stack spacing={2}>
      <Typography variant="h4" textAlign="center">View Preferences</Typography>
      <Stack
        direction="row"
        justifyContent="space-evenly"
      >
        <Card sx={{
          width: '40em',
        }}>
          <CardContent>
            <Stack spacing={1}>
            {
              Object.values(DashboardView)
                .map((view) => (
                  <Stack direction="row" justifyContent="space-between" key={`view-preference-${view}`}>
                    <Typography variant="h6">{view}</Typography>
                    <ToggleButtonGroup
                      color="primary"
                      value={viewPreferences?.[view] || DashboardViewType.cards}
                      exclusive
                      onChange={(e, value) => handleChangeViewTypePreference(view, value)}
                    >
                      <ToggleButton value={DashboardViewType.list}>
                        <Tooltip title="Display this view as a list" placement="top" arrow>
                          <TableRowsIcon />
                        </Tooltip>
                      </ToggleButton>
                      <ToggleButton value={DashboardViewType.cards}>
                        <Tooltip title="Display this view as a gallery" placement="top" arrow>
                          <ViewModuleIcon />
                        </Tooltip>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>
                ))
            }
            </Stack>
          </CardContent>
        </Card>
      </Stack>

    </Stack>
  );
}

export default ViewsSection;