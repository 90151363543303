import { createSlice } from "@reduxjs/toolkit";
import { UtilGroup } from "./types";


const initialState: UtilGroup[] = [];

export const utilGroupsReducer = createSlice({
  name: 'utilgroups',
  initialState,
  reducers: {
    setUtilGroups: (state: UtilGroup[], action: any) => {
      if (action.payload) {
        state.splice(0, state.length);
        action.payload.forEach((ug: UtilGroup) => state.push(ug));
      } else {
        state.splice(0, state.length);
      }
    },
  }
});

export default utilGroupsReducer.reducer;

export const {
  setUtilGroups,
} = utilGroupsReducer.actions;