import ContactList from "@features/all-contacts/components/ContactList";
import ContactsProvider from "@features/all-contacts/components/ContactsProvider";
import Header from "@features/Common/Header";
import { FC } from "react";


/** Wrapper for /contacts */
const AllContacts: FC = () => (
  <ContactsProvider>
    <Header />
    <ContactList />
  </ContactsProvider>
);

export default AllContacts;