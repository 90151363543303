import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { UtilGroup } from "@features/all-utility-groups/types";
import { findUnitsLabel, Unit } from "@lib/labels";
import { Divider, List, ListItem, ListSubheader, Stack, Typography } from "@mui/material";
import { Fragment, useEffect, useMemo } from "react";
import { RIOT_BLUE } from "theme";
import { useConsumptionDetailsQuery, useSetRealtimeMutation } from "../api";

const HomesConsumption = () => {
  const homes = useAppSelector((state: RootState) => state.homes);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const utilGroup = useAppSelector((state: RootState) => state.utilityGroup.utilityGroup as UtilGroup);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant.builder_color || RIOT_BLUE, [currentTenant.builder_color]);

  const {
    currentData: consumptionData,
  } = useConsumptionDetailsQuery({
    userTenantId:  currentTenant?.tenant_id || '',
    groupId: utilGroup?.group_id || '',
    builderId: currentTenant?.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id || !utilGroup?.group_id,
  });

  /* Inform backend of total consumption when it changes */
  const [setRealtimeMutation] = useSetRealtimeMutation();
  const totalPowerConsumptionWatts = useMemo(() => {
    if (!consumptionData) return NaN;
    return Object.values(consumptionData).reduce<number>((partialSum, readings) => partialSum + Math.max(...readings.map(r => Number(r.data.value))), 0);
  }, [consumptionData]);

  useEffect(() => {
    if (!Number.isNaN(totalPowerConsumptionWatts)) {
      setRealtimeMutation({
        userTenantId:  currentTenant?.tenant_id || '',
        groupId: utilGroup?.group_id || '',
        builderId: currentTenant?.tenant_id || '',
        realtime: {
          grid_consumption: {
            watts: totalPowerConsumptionWatts,
            volts: 120, // TODO: AVERAGE NEIGHBORHOOD GRID VOLTAGE
          }
        }
      });
    }
  }, [currentTenant?.tenant_id, setRealtimeMutation, totalPowerConsumptionWatts, utilGroup?.group_id]);

  const mappedHomeNames = useMemo(() => utilGroup?.property_ids
    .map((id: string) => {
      const home = homes?.find(hh => hh.property_id === id);
      const address = home?.physical_address;
      const label = home?.name || `${address?.address_line_1 || ''}, ${address?.address_line_2 || ''}`;
      return ({
        id,
        label,
      });
    })
    .sort((a, b) => a.label.toLocaleLowerCase() > b.label.toLocaleLowerCase() ? 1 : -1) || [],
    [homes, utilGroup?.property_ids]);

  return (
    <List
      sx={{
        width: '100%',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 600,
      }}
      subheader={
        <ListSubheader color="primary">
          <Stack direction="row" justifyContent="space-between" sx={{ width: 400 }}>
            <Typography>Home</Typography>
            <Typography>Usage</Typography>
          </Stack>
          <Divider sx={{ borderColor: themeColor }} />
        </ListSubheader>
      }
    >
      {
        mappedHomeNames
          ?.map(home => (
          <Fragment key={`homeName-${home.id}`}>
            <ListItem>
              <Stack direction="row" justifyContent="space-between" sx={{ width: 400 }}>
                <Typography variant="h6">{home.label}</Typography>
                <Stack alignItems="end">
                  <Typography variant="h6">
                    {
                      consumptionData?.[home.id]
                        ?.reduce((acc, rr) => acc + Number(rr.data.value), 0)
                        .toFixed(1)
                    }{findUnitsLabel(Unit.watt)}
                  </Typography>
                  <Typography variant="caption">
                  ({
                    consumptionData?.[home.id]
                      ?.map((reading, idx) => (
                        `${Number(reading.data.value).toFixed(1)}${findUnitsLabel(Unit.watt)}`)
                      ).join(' + ')
                  })
                  </Typography>
                </Stack>
              </Stack>
            </ListItem>
          </Fragment>
          ))
      }
    </List>
  );
}

export default HomesConsumption;