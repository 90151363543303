import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  logo: '',
};

/** State slice for the tenant selected to use */
export const logoReducer = createSlice({
  name: 'logo',
  initialState,
  reducers: {
    setLogo: (state, action) => {
      if (action.payload) {
        state.logo = action.payload;
      }
    },
  }
});

export default logoReducer.reducer;

export const {
  setLogo,
} = logoReducer.actions;