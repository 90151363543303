import { useAppDispatch, useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { pollingInterval } from "@features/tenant-selector/types";
import { TenantType } from "@features/userTenant/types";
import { FC, ReactElement, useEffect, useMemo } from "react";
import { useChildTenantsQuery, useHomeownersQuery } from "../api";
import { resetHomeowners, setHomeowners } from "../slice";

/** Wrapper for homeowners under selected tenant */
const HomeownersProvider: FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const {
    currentData: adminHomeowners,
  } = useHomeownersQuery({
    userTenantId: currentTenant?.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id || currentTenant.tenant_type !== TenantType.System,
    pollingInterval,
  });

  const {
    currentData: childHomeowners,
  } = useChildTenantsQuery({
    userTenantId: currentTenant?.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id || currentTenant.tenant_type === TenantType.System,
    pollingInterval,
  });

  useEffect(() => {
    if (adminHomeowners) {
      dispatch(setHomeowners(adminHomeowners));
    } else if (childHomeowners) {
      dispatch(setHomeowners(childHomeowners.filter(c => c.tenant_type === TenantType.Homeowner)));
    } else {
      dispatch(resetHomeowners());
    }
  }, [adminHomeowners, childHomeowners, currentTenant.tenant_id, dispatch]);

  return children as ReactElement;
}

export default HomeownersProvider;
