import { Plan } from "@features/floorplan/types";
import { createSlice } from "@reduxjs/toolkit";


const initialState: Plan[] = [];

export const plansReducer = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    setTenantPlans: (state: Plan[], action: any) => {
      if (action.payload) {
        state.splice(0, state.length);
        action.payload.forEach((cc: Plan) => state.push(cc));
      } else {
        state.splice(0, state.length);
      }
    },
  }
});

export default plansReducer.reducer;

export const {
  setTenantPlans,
} = plansReducer.actions;