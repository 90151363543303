import { riotApi } from "@features/aws-user/api";
import { HTTPMethodType } from "@lib/types";
import { U } from "@lib/utils";
import { Neighborhood, NeighborhoodBase, NeighborhoodCurrentUsage, NeighborhoodUsageHistory } from "./types";
import { Category } from "@lib/labels";


export const neighborhoodApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      neighborhood: builder.query<Neighborhood, {
        userTenantId: string,
        neighborhoodId: string,
      }>({
        query: ({ userTenantId, neighborhoodId }) => ({
          url: `tenant/${U(userTenantId)}/neighborhoods/${U(neighborhoodId)}`,
          params: {
            include_names: true,
          },
        }),
      }),
      neighborhoodCurrentUsage: builder.query<NeighborhoodCurrentUsage, {
        category: Category,
        userTenantId: string,
        neighborhoodId: string,
      }>({
        query: ({ userTenantId, neighborhoodId, category }) => ({
          url: `tenant/${U(userTenantId)}/neighborhoods/${U(neighborhoodId)}/usage-rate/${U(category)}`,
        }),
      }),
      neighborhoodHistoricalUsage: builder.query<NeighborhoodUsageHistory, {
        category: Category,
        userTenantId: string,
        neighborhoodId: string,
      }>({
        query: ({ userTenantId, neighborhoodId, category }) => ({
          url: `tenant/${U(userTenantId)}/neighborhoods/${U(neighborhoodId)}/usage/${U(category)}`,
          method: 'POST',
        }),
      }),
      addHomes: builder.mutation<any, {
        userTenantId: string,
        neighborhoodId: string,
        propertyIds: string[],
      }>({
        query: ({ userTenantId, neighborhoodId, propertyIds }) => ({
          url: `tenant/${U(userTenantId)}/neighborhoods/${U(neighborhoodId)}/homes`,
          method: HTTPMethodType.PATCH,
          body: {
            add: propertyIds,
            remove: [],
          },
        }),
      }),
      removeHome: builder.mutation<any, {
        userTenantId: string,
        neighborhoodId: string,
        propertyId: string,
      }>({
        query: ({ userTenantId, neighborhoodId, propertyId }) => ({
          url: `/tenant/${U(userTenantId)}/neighborhoods/${U(neighborhoodId)}/homes`,
          method: HTTPMethodType.PATCH,
          body: {
            update: {
              add: [],
              remove: [propertyId],
            },
          },
        }),
      }),
      health: builder.mutation<NeighborhoodBase['health_status'], {
        userTenantId: string,
        neighborhoodId: string,
      }>({
        query: ({ userTenantId, neighborhoodId }) => ({
          url: `/tenant/${U(userTenantId)}/neighborhoods/${U(neighborhoodId)}/health`,
          method: HTTPMethodType.POST,
        }),
      }),
    };
  }
});

export const {
  useNeighborhoodQuery,
  useNeighborhoodCurrentUsageQuery,
  useNeighborhoodHistoricalUsageQuery,
  useAddHomesMutation,
  useRemoveHomeMutation,
  useHealthMutation,
} = neighborhoodApi;