import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { useUtilgroupQuery } from "@features/all-utility-groups/api";
import { EnergyUsage, UtilGroup } from "@features/all-utility-groups/types";
import ExpandableSnack from '@features/Common/ExpandableSnack';
import { findUnitsLabel, Unit } from "@lib/labels";
import { ApiErrorResponse, SnackType } from '@lib/types';
import { getErrorMessage } from '@lib/utils';
import { LoadingButton } from "@mui/lab";
import { Button, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { useRemovePowerLimitMutation, useSetPowerLimitMutation } from "../api";
import EnergyReading from "./EnergyReading";


const ConsumptionLimit = () => {
  const { enqueueSnackbar } = useSnackbar();

  const utilGroup = useAppSelector((state: RootState) => state.utilityGroup.utilityGroup as UtilGroup);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const currentLimit = useMemo(() => (
    utilGroup.utility_available?.find(ua => !!(ua as EnergyUsage).grid_consumption) as EnergyUsage
  )?.grid_consumption, [utilGroup.utility_available]);


  const serviceLimit = useMemo(() => utilGroup.utility_management?.energy_management.svc_max_power.watts || 0, [utilGroup.utility_management?.energy_management.svc_max_power.watts]);

  const [editing, setEditing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [limitWatt, setLimitWatt] = useState<number>(serviceLimit);

  const { refetch } = useUtilgroupQuery({
    userTenantId: currentTenant?.tenant_id || '',
    groupId: utilGroup?.group_id || '',
    builderId: currentTenant?.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id || !utilGroup?.group_id,
  });

  const [ setPowerLimit ] = useSetPowerLimitMutation();

  const [ removePowerLimit ] = useRemovePowerLimitMutation();

  const handleSetLimit = useCallback(async () => {
    setLoading(true);
    const shedRequest = await setPowerLimit({
      userTenantId: userTenant?.tenant_id || '',
      groupId: utilGroup?.group_id || '',
      builderId: currentTenant?.tenant_id || '',
      watts: limitWatt,
    });

    const errorDetails = (shedRequest as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't set power limit:", {
        key: "powerlimit-error",
        content: (
          <ExpandableSnack
            id="powerlimit-error"
            message={"Couldn't set power limit:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("Power limit set", {
        variant: "success",
      });

      setLoading(false);
      refetch();
      setEditing(false);
    }

  }, [currentTenant?.tenant_id, enqueueSnackbar, limitWatt, refetch, setPowerLimit, userTenant?.tenant_id, utilGroup?.group_id]);

  const handleRemoveLimit = useCallback(async () => {
    setLoading(true);
    const shedRequest = await removePowerLimit({
      userTenantId: userTenant?.tenant_id || '',
      groupId: utilGroup?.group_id || '',
      builderId: currentTenant?.tenant_id || '',
    });

    const errorDetails = (shedRequest as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't remove power limit:", {
        key: "removepowerlimit-error",
        content: (
          <ExpandableSnack
            id="removepowerlimit-error"
            message={"Couldn't remove power limit:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("Power limit removed", {
        variant: "success",
      });

      setLoading(false);
      refetch();
    }
  }, [currentTenant?.tenant_id, enqueueSnackbar, refetch, removePowerLimit, userTenant?.tenant_id, utilGroup?.group_id]);

  const handleEditMode = useCallback(() => {
    setEditing(!editing);
  }, [editing]);

  const handleSetLimitWatts = useCallback((e: any) => {
    setLimitWatt(e.target.value*1000);
  }, []);

  return (
    <Stack sx={{ mb: 1}} spacing={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">Soft Power Limit</Typography>
        <EnergyReading variant="body1" energy={currentLimit!} />
      </Stack>
      <Stack direction="row" justifyContent="flex-end" spacing={1}>
        {
          editing &&
          <>
            <TextField
              label="Set soft power limit"
              InputProps={{
                startAdornment: <InputAdornment position="start">{findUnitsLabel(Unit.kw)}</InputAdornment>,
              }}
              type="number"
              defaultValue={serviceLimit/1000}
              size="small"
              onChange={handleSetLimitWatts}
              required
            />
            <LoadingButton
              onClick={handleSetLimit}
              variant="contained"
              color="warning"
              loading={loading}
              disabled={!limitWatt}
            >Set</LoadingButton>
          </>
        }
        <Button
          size="small"
          variant={editing ? 'outlined' : 'contained'}
          onClick={handleEditMode}
          color={editing ? 'inherit' : 'success'}
        >
          {editing ? 'Cancel' : 'New'}
        </Button>
        {
          !!currentLimit && !editing &&
          <LoadingButton
            onClick={handleRemoveLimit}
            variant="contained"
            color="warning"
            loading={loading}
          >Clear</LoadingButton>
        }
      </Stack>
  </Stack>
  );
}

export default ConsumptionLimit;