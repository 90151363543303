import { Typography } from "@mui/material";


type Props = {
  homeName: string;
}

const OrderConfirmation = ({ homeName }: Props) => (
  <>
    <Typography variant="h5">
      Thank you for your order!
    </Typography>
    <Typography variant="body1">
      RIoT Control will be in touch with you to discuss next steps for {homeName}.
    </Typography>
  </>
);

export default OrderConfirmation;