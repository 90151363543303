import { Checkbox, FormControlLabel, FormGroup, Stack, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { ChangeEvent } from "react";
import { ProvisioningForm } from "../../types";
import ProvisioningToolInstructions from "./ProvisioningToolInstructions";


const ProvisionGateway = () => {
  const f = useFormikContext();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    f.setFieldValue(name, event.target.checked);
  }

  const { gatewayCableStatus, gatewayProvisioningStatus } = f.values as ProvisioningForm;

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={gatewayCableStatus}
            onChange={handleChange}
            name="gatewayCableStatus"
          />
        }
        label={
          <Typography variant="h6">
            1. Connect Gateway device to Switch device via Ethernet cable.
          </Typography>
        }
      />
      <FormControlLabel
        sx={{
          alignItems: 'start',
        }}
        control={
          <Checkbox
            checked={gatewayProvisioningStatus}
            onChange={handleChange}
            name="gatewayProvisioningStatus"
            disabled={!gatewayCableStatus}
          />
        }
        label={
          <Stack>
            <Typography variant="h6">
              2. Provision Gateway using Provisioning Tool installed on your workstation.
            </Typography>
            <ProvisioningToolInstructions device="gateway" />
          </Stack>
        }
      />
    </FormGroup>
  );

}

export default ProvisionGateway;