import API, { graphqlOperation } from '@aws-amplify/api';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import * as gql_mutations from './graphql/mutations';
import * as gql_queries from './graphql/queries';
import * as gql_types from './graphql/types';
import { Preferences } from './types';

/** Fetch the User preferences from AppSync */
export const getPrefs = async (): Promise<Preferences|undefined> => {
  const resp = await (
    API.graphql(graphqlOperation(gql_queries.getPrefs),
  ) as Promise<GraphQLResult<gql_types.GetPrefsQuery>>);
  return parsePrefs(resp.data?.getPrefs);
}

export const setPrefs = async (prefs: Partial<Preferences>): Promise<Preferences|undefined> => {
  const resp = await (API.graphql(graphqlOperation(gql_mutations.updatePrefs, {
    input: {
      prefs: JSON.stringify(prefs)
    }
  })) as Promise<GraphQLResult<gql_types.UpdatePrefsMutation>>);

  return parsePrefs(resp.data?.updatePrefs);
}


/** Parse the User Preferences from the AppSync responses */
const parsePrefs = (resp: gql_types.Prefs | undefined | null): Preferences|undefined => {
  if (!resp?.prefs) {
    return undefined;
  }
  try {
    return JSON.parse(resp.prefs);
  }
  catch (error) {
    console.warn('Could not parse prefs', resp.prefs, error);
    return undefined;
  }
}
