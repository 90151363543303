import { useAppDispatch, useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { pollingInterval } from "@features/tenant-selector/types";
import { ReactElement, ReactNode, useEffect, useMemo } from "react";
import { useMyDocumentsQuery, useTenantDocumentsQuery } from "../api";
import { setDocuments } from "../slice";

type Props = {
  children: ReactNode;
}

/** Wrapper for documents belonging to the selected tenant */
const DocumentsProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const isOtherTenant = useMemo(() => (
    !!userTenant?.tenant_id &&
    !!currentTenant?.tenant_id &&
    userTenant.tenant_id !== currentTenant.tenant_id), [currentTenant.tenant_id, userTenant.tenant_id]);

  const {
    currentData: myDocuments,
  } = useMyDocumentsQuery({
    userTenantId: currentTenant?.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id || isOtherTenant,
    //pollingInterval,
  });

  const {
    currentData: tenantDocuments,
  } = useTenantDocumentsQuery({
    userTenantId: userTenant?.tenant_id || '',
    tenantId: currentTenant?.tenant_id || ''
  }, {
    skip: !userTenant?.tenant_id || !currentTenant?.tenant_id || !isOtherTenant,
    //pollingInterval,
  });

  useEffect(() => {
    if (isOtherTenant && tenantDocuments) {
      dispatch(setDocuments(tenantDocuments));
    }
    if (!isOtherTenant && myDocuments) {
      dispatch(setDocuments(myDocuments));
    }
  }, [isOtherTenant, tenantDocuments, myDocuments, dispatch]);

  return children as ReactElement;
}

export default DocumentsProvider;