import { Box } from "@mui/material";
import ShippingFields from "./ShippingFields";

const margin = (
  theme: { spacing: (arg0: number) => any; },
) => `${theme.spacing(1)} !important`;

const ShippingDetails = () => (
  <Box
    component="div"
    sx={{
      '& > *': {
        // Space the form elements out a bit
        margin,
      }
    }}
  >
    <ShippingFields />
  </Box>
);

export default ShippingDetails;