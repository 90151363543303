import {
  Button, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle
} from "@mui/material";
import { useState } from "react";

export type Props = {
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  title?: string;
  content: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
};

const AlertDialog = ({
  confirmButtonLabel,
  cancelButtonLabel,
  title,
  content,
  onConfirm,
  onCancel,
  onClose,
}: Props) => {
  const [open, setOpen] = useState<boolean>(true);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        if (onClose) onClose();
      }}
    >
      <DialogTitle>
        {title ?? 'Alert'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            if (onCancel) onCancel();
          }}>
          {cancelButtonLabel ?? 'Cancel'}
        </Button>
        <Button
          onClick={() => {
            setOpen(false);
            if (onConfirm) onConfirm();
          }}
          autoFocus
        >
          {confirmButtonLabel ?? 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlertDialog;