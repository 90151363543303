import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import { Button, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useMemo } from "react";
import { useNotificationsQuery, useUserActionMutation } from "../api";
import { DeviceAction, NotificationAction, NotificationRow, UserActionType, UserNotification } from "../types";

type Props = {
  notification: NotificationRow;
}

const UserActions = ({ notification }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const awsUser = useAppSelector((state: RootState) => state.awsUser);
  const property = useAppSelector((state: RootState) => state.property);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const {
    refetch,
  } = useNotificationsQuery({
    userTenantId: currentTenant?.tenant_id || '',
    propertyId: property?.property_id || '',
    includeUserActions: true,
    username: awsUser.username,
  }, {
    skip: !currentTenant?.tenant_id || !property?.property_id,
  });

  const [
    userAction,
  ] = useUserActionMutation();

  const handleAction = useCallback(async (notification: UserNotification, action: NotificationAction) => {
    const result = await userAction({
      userTenantId: currentTenant?.tenant_id || '',
      propertyId: property?.property_id,
      eventId: notification.event_id,
      action: action.action as DeviceAction,
    });

    const errorDetails = (result as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar(`Couldn't perform action:`, {
        key: "notification-action-error",
        content: (
          <ExpandableSnack
            id="notification-action-error"
            message={`Couldn't perform action:`}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("Done!", {
        variant: "success",
      });
      refetch();
    }
  }, [enqueueSnackbar, property?.property_id, refetch, userAction, currentTenant?.tenant_id]);

  return (
    <Stack gap={1}>
      {
        notification.user_actions
          ?.filter(aa => aa.is_repeatable || (!aa.datetime))
          ?.filter(a => {
            /* Filter out dismiss actions for other users */
            if (a.action.action_type === UserActionType.dismiss) {
              return a.action.username?.toLowerCase() === awsUser.username?.toLowerCase();
            }

            return true;
          })
          ?.map((action: NotificationAction, idx: number) => (
            <Button
              color="primary"
              onClick={() => handleAction(notification, action)}
              key={`${notification.user_notification_id}-user-action-${idx}`}
              size="small"
              variant="contained"
            >{action.label}</Button>
          ))
      }
    </Stack>
  );
}

export default UserActions;