import { riotApi } from "@features/aws-user/api";
import { Interval, RawAverageDataRow } from "@features/home-analytics/types";
import { Category } from "@lib/labels";
import { U } from "@lib/utils";

export const utilityMonitoringApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      homeUsage: builder.query<RawAverageDataRow[], {
        userTenantId: string,
        propertyId: string,
        category: Category,
        interval: Interval,
      }>({
        query: ({ userTenantId, propertyId, category, interval }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/analytics/usage`,
          params: {
            category,
            interval,
          },
        }),
      }),
      averageUsage: builder.query<RawAverageDataRow[], {
        userTenantId: string,
        category: Category,
        interval: Interval,
        zip?: string,
        plan?: string,
      }>({
        query: ({
          userTenantId,
          category,
          interval,
          zip,
          plan,
        }) => ({
          url: `tenant/${U(userTenantId)}/system/analytics/usage/average`,
          params: {
            category,
            interval,
            zip_code: zip,
            plan_id: plan,
          },
        }),
      })
    };
  }
});

export const {
  useHomeUsageQuery,
  useAverageUsageQuery,
} = utilityMonitoringApi;