import { riotApi } from "@features/aws-user/api";
import { Tenant, TenantCreate, TenantType, TenantUpdate } from "@features/userTenant/types";
import { HTTPMethodType } from "@lib/types";
import { U } from "@lib/utils";


/** API slice for the tenant-related endpoints */
export const buildersApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      /** Get all builders */
      builders: builder.query<Tenant, { userTenantId: string }>({
        query: ({ userTenantId }) => ({
          url: `/tenant/${U(userTenantId)}/tenants`,
          params: {
            tenant_type: TenantType.Builder,
          }
        }),
      }),
      /** Create a builder */
      createBuilder: builder.mutation<Tenant, { userTenantId: string, body: TenantCreate }>({
        query: ({ userTenantId, body }) => ({
          url: `tenant/${U(userTenantId)}/tenants`,
          method: HTTPMethodType.POST,
          body: {
            ...body,
            tenant_type: TenantType.Builder,
          },
        }),
      }),
      /** Update a builder */
      updateBuilder: builder.mutation<Tenant, { userTenantId: string, tenantId: string, body: TenantUpdate }>({
        query: ({ userTenantId, body, tenantId }) => ({
          url: `tenant/${U(userTenantId)}/tenants/${U(tenantId)}`,
          method: HTTPMethodType.PATCH,
          body,
        }),
      }),
      /** Delete a builder */
      deleteBuilder: builder.mutation<Tenant, { userTenantId: string, tenantId: string }>({
        query: ({ userTenantId, tenantId }) => ({
          url: `tenant/${U(userTenantId)}/tenants/${U(tenantId)}`,
          method: HTTPMethodType.DELETE,
        }),
      }),
    };
  }
});

export const {
  useBuildersQuery,
  useCreateBuilderMutation,
  useUpdateBuilderMutation,
  useDeleteBuilderMutation,
} = buildersApi;
