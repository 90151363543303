import {
  Button,
  Heading,
  Image,
  Text,
  useAuthenticator,
  useTheme,
  View
} from "@aws-amplify/ui-react";
import config from "@lib/config";
import { RIOT_BLUE } from "theme";

export const components = {
  Header: () => {
    const params = new URLSearchParams(window.location.search);

    const id = params.has('id') ? params.get('id') : '';
    const logo = params.has('logo') ? params.get('logo') : 'logo.png';

    const logoBucket = config.ASSETS_BUCKET_URL;
    const logoSrc = id?.length ? `${logoBucket}${id}/${logo}` : '/logo.svg';

    return (
      <View textAlign="center">
        <Image
          alt="logo"
          src={logoSrc}
        />
      </View>
    );
  },

  SignIn: {
    Header: () => {
      const { tokens } = useTheme();

      return (
        <Heading
          textAlign={"center"}
          padding={`${tokens.space.xl} ${tokens.space.xl} 0 ${tokens.space.xl}`}
          level={3}
        >
          RIoT Homes Dashboard
        </Heading>
      );
    },
    Footer: () => {
      const { toResetPassword } = useAuthenticator();

      return (
        <View
          textAlign="center"
        >
          <Button
            color={RIOT_BLUE}
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
          >
            Reset Password
          </Button>
          <Text fontSize={10}>
            &copy; Powered by RIoT Technology.
          </Text>
        </View>
      );
    },
  },
};

export const formFields = {
  signIn: {
    username: {
      labelHidden: true,
      placeholder: 'Username',
    },
  },
  forceNewPassword: {
    password: {
      labelHidden: false,
      placeholder: 'Enter your Password:',
    },
  },
  resetPassword: {
    username: {
      labelHidden: false,
      placeholder: 'Enter your username:',
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      labelHidden: false,
      placeholder: 'Enter your Confirmation Code:',
      isRequired: false,
    },
    confirm_password: {
      labelHidden: false,
      placeholder: 'Enter your Password Please:',
    },
  },
};