import { useAppDispatch, useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { pollingInterval } from "@features/tenant-selector/types";
import { FC, ReactElement, useEffect, useMemo } from "react";
import { useUtilgroupsQuery } from "../api";
import { setUtilGroups } from "../slice";


const UtilityGroupsProvider: FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const {
    currentData: utilGroups,
  } = useUtilgroupsQuery({
    userTenantId: currentTenant?.tenant_id || '',
    builderId: currentTenant?.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id,
    pollingInterval,
  });

  useEffect(() => {
    dispatch(setUtilGroups(utilGroups));
  }, [dispatch, utilGroups]);

  return children as ReactElement;
}

export default UtilityGroupsProvider;