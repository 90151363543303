import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { findMeasureLabel, interval } from "@features/settings/types";
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useCallback, useMemo } from 'react';
import { RIOT_BLUE } from 'theme';

type Props = {
  initial: interval;
  onChange: (m: interval) => void;
}

const ChartIntervals = ({ initial, onChange }: Props) => {
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant?.builder_color || RIOT_BLUE, [currentTenant?.builder_color]);

  const handleChange = useCallback((e: SelectChangeEvent<interval>) => {
    onChange(e.target.value as interval);
  }, [onChange]);

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <Select
        value={initial}
        onChange={handleChange}
        sx={{
          '&:focus': {
            border: `1px solid ${themeColor}`,
          }
        }}
      >
        {Object.values(interval).map(i => (
          <MenuItem value={i} key={`interval-${i}`}>{findMeasureLabel(i)}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default ChartIntervals;