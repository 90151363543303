import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import AdminTable from '@features/Common/AdminTable';
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { renderLite } from '@features/Common/tables';
import PropertyManagersProvider from '@features/all-property-managers/components/PropertyManagersProvider';
import NeighborhoodFields from '@features/neighborhood/components/NeighborhoodFields';
import NeighborhoodStatus from '@features/neighborhood/components/NeighborhoodStatus';
import { Neighborhood, NeighborhoodBase, NeighborhoodCreate, NeighborhoodUpdate } from '@features/neighborhood/types';
import { TenantType } from '@features/userTenant/types';
import { ApiErrorResponse, SnackType } from '@lib/types';
import { U, getErrorMessage } from "@lib/utils";
import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { useSnackbar } from "notistack";
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateNeighborhoodMutation, useNeighborhoodsQuery, useUpdateNeighborhoodMutation } from '../api';

const NeighborhoodList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const neighborhoods = useAppSelector((state: RootState) => state.neighborhoods);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const  currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const isBuilderOrSystem = useMemo(() => [TenantType.Builder, TenantType.System].includes(currentTenant?.tenant_type), [currentTenant?.tenant_type]);
  const isManager = useMemo(() => currentTenant?.tenant_type === TenantType.PropertyManager, [currentTenant?.tenant_type]);

  const {
    refetch: refetchNeighborhoods,
  } = useNeighborhoodsQuery({
    userTenantId: currentTenant?.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id,
  });

  const [
    createNeighborhood,
  ] = useCreateNeighborhoodMutation();

  const [
    updateNeighborhood,
  ] = useUpdateNeighborhoodMutation();

  const handleSaveNeighborhood = useCallback(async (neighborhood: NeighborhoodUpdate|NeighborhoodCreate) => {
    // only builders can create/edit neighborhoods
    if (!isBuilderOrSystem) return;

    /** New neighborhood */
    if (!neighborhood.neighborhood_id) {
      const newNeighborhood = await createNeighborhood({
        userTenantId: currentTenant?.tenant_id || '',
        neighborhood: {
          name: neighborhood.name,
          property_manager_id: neighborhood.property_manager_id,
          property_ids: [],
        } as NeighborhoodCreate,
      });

      const errorDetails = (newNeighborhood as ApiErrorResponse)?.error;

      if (errorDetails) {
        enqueueSnackbar("Couldn't create neighborhood:", {
          key: "neighborhood-error",
          content: (
            <ExpandableSnack
              id="neighborhood-error"
              message={"Couldn't create neighborhood:"}
              variant={SnackType.error}
              detail={getErrorMessage(errorDetails)}
            />),
        });
      } else {
        enqueueSnackbar("Created neighborhood", {
          variant: "success",
        });
      }

      refetchNeighborhoods();
      return (newNeighborhood as any).data;
    }
    /** Update existing plan */
    const updated = await updateNeighborhood({
      userTenantId: currentTenant?.tenant_id || '',
      neighborhoodId: neighborhood.neighborhood_id,
      neighborhood: {
        name: neighborhood.name,
        property_manager_id: neighborhood.property_manager_id,
      } as NeighborhoodUpdate,
    });

    const errorDetails = (updated as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't update neighborhood:", {
        key: "neighborhood-error",
        content: (
          <ExpandableSnack
            id="neighborhood-error"
            message={"Couldn't update neighborhood:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("Updated neighborhood", {
        variant: "success",
      });
    }
    refetchNeighborhoods();
    return (updated as any).data;
  }, [createNeighborhood, currentTenant?.tenant_id, enqueueSnackbar, isBuilderOrSystem, refetchNeighborhoods, updateNeighborhood]);

  const renderedTable = useMemo(() => {
    if (userTenant.checkingTenant) return <Backdrop open><CircularProgress /></Backdrop>;
    if (!isBuilderOrSystem && !isManager) return <></>;
    const options: MUIDataTableOptions = {
      enableNestedDataAccess: '.',
    };

    const columns: MUIDataTableColumn[] = [
      {
        name: 'name',
        label: 'Name',
      },
      {
        name: isBuilderOrSystem ? 'property_manager_name' : 'builder_name',
        label: isBuilderOrSystem ? 'Property Manager' : 'Builder',
      },
      {
        name: 'health_status',
        label: 'Status',
        options: {
          // Disable all the features
          filter: false,
          download: false,
          searchable: false,
          sort: false,
          customBodyRenderLite: renderLite(
            neighborhoods,
            (row: Neighborhood) => (
              <NeighborhoodStatus
                neighborhood={row}
                health={row.health_status}
                onClick={() => navigate(`neighborhoods/${U(row.neighborhood_id)}`)}
              />
            ),
          ),
        },
      }
    ];


    return (
      <>
        <AdminTable<Neighborhood>
          title="Neighborhoods"
          label="Neighborhood"
          id_key="neighborhood_id"
          name_key="name"
          columns={columns}
          options={options}
          data={neighborhoods}
          makeViewPath={
            (row) => `/neighborhoods/${U(row.neighborhood_id)}`
          }
          makeNew={() => ({
            name: '',
          } as NeighborhoodCreate)}
          onSave={handleSaveNeighborhood}
          refresh={refetchNeighborhoods}
          editPermission="write-properties"
          deletePermission="delete-neighborhoods"
        >
          {
            f => <PropertyManagersProvider>
              <NeighborhoodFields />
            </PropertyManagersProvider>
          }
        </AdminTable>
      </>
    )
  }, [handleSaveNeighborhood, isBuilderOrSystem, isManager, navigate, neighborhoods, refetchNeighborhoods, userTenant.checkingTenant]);



  return  (
    <Box
      component="div"
      sx={{
        margin: '1em',
        padding: '2em',
      }}
    >
      <Typography variant="h4">{currentTenant.name}</Typography>
      <Typography variant="h5">Neighborhoods</Typography>
      <Box
        component="div"
        sx={{
          mt: '3em',
        }}
      >
        {renderedTable}
      </Box>
    </Box>
  );
}

export default NeighborhoodList;