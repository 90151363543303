import { useAppDispatch, useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { pollingInterval } from "@features/tenant-selector/types";
import { FC, ReactElement, useEffect, useMemo } from "react";
import { useContactsQuery } from "../api";
import { setTenantContacts } from "../slice";

/** Wrapper for contact list under selected tenant */
const ContactsProvider: FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const {
    currentData: contacts,
  } = useContactsQuery({
    userTenantId: userTenant?.tenant_id || '',
    tenantId: currentTenant?.tenant_id || '',
  }, {
    skip: !userTenant?.tenant_id,
    pollingInterval,
  });

  useEffect(() => {
    dispatch(setTenantContacts(contacts));
  }, [userTenant.tenant_id, contacts, dispatch]);

  return children as ReactElement;
}

export default ContactsProvider;
