import { riotApi } from "@features/aws-user/api";
import { HTTPMethodType } from "@lib/types";
import { Document, DocumentDownload } from "./types";

const safe = encodeURIComponent;

/** API slice for the contacts-related endpoints */
export const documentsApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      /** Get my documents */
      myDocuments: builder.query<Document[], {
        userTenantId: string,
      }>({
        query: ({ userTenantId }) => ({
          url: `/tenant/${safe(userTenantId)}/docs`,
        })
      }),
      /** Get tenant's documents */
      tenantDocuments: builder.query<Document[], {
        userTenantId: string,
        tenantId: string,
      }>({
        query: ({ userTenantId, tenantId }) => ({
          url: `/tenant/${safe(userTenantId)}/tenants/${tenantId}/docs`,
        })
      }),
      /** Get the Tenant Documents for a single property */
      propertyDocuments:  builder.query<Document, {
        userTenantId: string,
        propertyId: string,
      }>({
        query: ({ userTenantId, propertyId }) => ({
          url: `tenant/${safe(userTenantId)}/properties/${safe(propertyId)}/docs`,
        })
      }),
      /** Get a URL to the contents of a document */
      documentContentsUrl: builder.query<DocumentDownload, {
        userTenantId: string,
        documentId: string,
      }>({
        query: ({ userTenantId, documentId }) => ({
          url: `tenant/${safe(userTenantId)}/docs/${safe(documentId)}/download`,
        })
      }),
      /** Get a URL to the contents of a document */
      documentDownload: builder.mutation<DocumentDownload, {
        userTenantId: string,
        documentId: string,
      }>({
        query: ({ userTenantId, documentId }) => ({
          url: `tenant/${safe(userTenantId)}/docs/${safe(documentId)}/download`,
        })
      }),
      /** Create a new document */
      createDocument: builder.mutation<Document, {
        userTenantId: string,
        path: string,
        body: Partial<Document>
      }>({
        query: ({ userTenantId, path, body }) => ({
          url: `tenant/${safe(userTenantId)}/docs/${safe(path)}`,
          method: HTTPMethodType.POST,
          body,
        })
      }),
      /** Deletes a document */
      deleteDocument: builder.mutation<Document, {
        userTenantId: string,
        documentId: string,
      }>({
        query: ({ userTenantId, documentId }) => ({
          url: `tenant/${safe(userTenantId)}/docs/${safe(documentId)}`,
          method: HTTPMethodType.DELETE,
        })
      }),
      /** Updates a document */
      updateDocument: builder.mutation<Document, {
        userTenantId: string,
        documentId: string,
        body: Partial<Document>
      }>({
        query: ({ userTenantId, documentId, body }) => ({
          url: `tenant/${safe(userTenantId)}/docs/${safe(documentId)}`,
          method: HTTPMethodType.PATCH,
          body,
        })
      }),
      /** Shares document with another tenant */
      shareDocument: builder.mutation<Document, {
        userTenantId: string,
        documentId: string,
        tenantId: string,
      }>({
        query: ({ userTenantId, documentId, tenantId }) => ({
          url: `tenant/${safe(userTenantId)}/docs/${safe(documentId)}/share`,
          method: HTTPMethodType.PATCH,
          body: {
            tenant_id: tenantId,
          }
        })
      }),
      /** Un-Shares document from another tenant */
      unshareDocument: builder.mutation<Document, {
        userTenantId: string,
        documentId: string,
        tenantId: string,
      }>({
        query: ({ userTenantId, documentId, tenantId }) => ({
          url: `tenant/${safe(userTenantId)}/docs/${safe(documentId)}/unshare`,
          method: HTTPMethodType.PATCH,
          body: {
            tenant_id: tenantId,
          }
        })
      }),
    };
  }
});

export const {
  useCreateDocumentMutation,
  useDeleteDocumentMutation,
  useDocumentContentsUrlQuery,
  useMyDocumentsQuery,
  usePropertyDocumentsQuery,
  useShareDocumentMutation,
  useTenantDocumentsQuery,
  useUnshareDocumentMutation,
  useUpdateDocumentMutation,
  usePrefetch,
  useDocumentDownloadMutation,
} = documentsApi;
