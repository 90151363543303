import { DeviceCount, LocationSummary } from "@features/provisioning-request/types";
import { ListItem, ListItemText, Typography } from "@mui/material";

type Props = {
  location: LocationSummary,
  device: DeviceCount,
}

/**
 * Displays Device Counts for given home area and device
 * @param location - home area summary
 * @param device - device counts
 */
const DeviceSummary = ({ location, device }: Props) => (
  <ListItem key={`${location.location}-${device.name}`} sx={{
    pt: 0,
    pb: 0,
  }}>
    <ListItemText>
      <Typography variant="body2">
        {device.name} x {device.count}
      </Typography>
    </ListItemText>
  </ListItem>
);

export default DeviceSummary;