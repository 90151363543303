import { Interval } from '@features/home-analytics/types';
import { Category } from '@lib/labels';
import { Card, CardContent, CardHeader, Divider, Grid } from "@mui/material";
import IntervalHighlights from './IntervalHighlights';

type Props = {
  category: Category,
}

const UsageHighlights = ({ category }: Props) => {
  return (
    <Card sx={{ minHeight: '16em' }}>
      <CardHeader title={`Usage Highlights`} />
      <Divider />
      <CardContent>
        <Grid container columns={9} sx={{ width: 'inherit' }} spacing={1}>
          <Grid item xs={3}>
            Interval
          </Grid>
          <Grid item xs={2} textAlign="end">
            Most Usage
          </Grid>
          <Grid item xs={2} textAlign="end">
            Least Usage
          </Grid>
          <Grid item xs={2} textAlign="end">
            Total Usage
          </Grid>
          <Grid item xs={9}>
            <Divider />
          </Grid>
          {
            [
              Interval.DAY,
              Interval.WEEK,
              Interval.MONTH,
              Interval.YEAR,
            ].map(interval => (
              <IntervalHighlights
                category={category}
                interval={interval}
                key={`row-${interval}`}
              />
            ))
          }
        </Grid>
      </CardContent>
    </Card>
  );
}

export default UsageHighlights;