import { PropertyManifestEntry } from "@features/home-manifest/types";
import { Device } from "@features/home/types";
import { Sensor } from "@features/plan-manifest/types";

type EntityBase = {
  readonly created: string | number;
  readonly updated: string | number;
}

export type Notification = {
  readonly tenant_id: string;
  readonly property_id: string;
  readonly device_id?: string;
  readonly entity_id?: string;

  readonly datetime: string | number;
  readonly event_id: string;
  readonly attention_level: string;
  readonly relates_to: string;
  readonly message: string;
  readonly dismissed: boolean;
} & EntityBase;

export enum NotificationStatus {
  new = 'type.publish',
  read = 'type.read',
  dismissed = 'type.dismiss',
  unread = 'type.unread',
  snooze = 'type.snooze',
  unsnooze = 'type.unsnooze',
}

export enum UserActionType {
  device = 'DeviceState',
  link = 'Link',
  dismiss = 'Dismiss'
}

export type DeviceAction = {
  action_type: UserActionType.device;
  device_id: string;
  entity_id: string;
  value: any;
}

export type LinkAction = {
  action_type: UserActionType.link;
  url: string;
}

export type DismissAction = {
  action_type: UserActionType.dismiss;
  username: string;
  user_type: string;
  privileged: boolean;
}

export type NotificationAction = {
  label: string;
  action: DeviceAction | LinkAction | DismissAction;
  is_repeatable?: boolean;
  datetime?: string | number;
  username?: string | number;
}

export type NotificationLifecycle = {
  action: NotificationStatus;
  datetime: string | number;
}

export enum AttentionLevel {
  urgent = 'urgent',
  warning = 'warning',
  info = 'info',
  notice = 'notice'
}

export type UserNotification = {
  property_area: string;
  device_id: string;
  relates_to: string;
  message: string;
  user_notification_id: string;
  event_type: string;
  datetime: string | number;
  entity_id: string;
  attention_level: AttentionLevel;
  event_id: string;
  property_id: string;
  username: string;
  is_deleted: boolean;
  lifecycle_action: NotificationStatus;
  user_actions?: NotificationAction[];
  lifecycle: NotificationLifecycle[];
  snoozable: boolean;
} & EntityBase;

export interface UserNotificationStack extends UserNotification {
  timestamps: number[];
}


export interface NotificationRow extends UserNotification {
  entry?: PropertyManifestEntry;
  device?: Device;
  sensor?: Sensor;
  category: string;
  name?: string;
}

export enum AlertColor {
  urgent = 'rgba(255, 0, 0, 0.2)',
  warning = 'rgba(255, 125, 0, 0.3)',
}