import { Address } from "@features/home/types";

export enum PrivacyLevel {
  private = 'Private',
  public = 'Public',
}

export interface ContactBase {
  name?: string;
  description?: string;
  privacy_level?: PrivacyLevel;
  phone_number?: string;
  website?: string;
  physical_address?: Address;
  tenant_id?: string;
  contact_id?: string;

};

export interface Contact extends ContactBase {
  property_ids?: string[];
  is_deleted?: boolean;
  updated?: string | number;
  created?: string | number;
}

export interface PropertyIds {
  add_properties: string[];
  remove_properties: string[];
}

export interface ContactUpdate extends ContactBase {
  property_ids?: PropertyIds;
}

export interface ContactCreate extends ContactBase {
  property_ids?: string[];
}