import SupportCaseDetails from '@features/support-case/components/SupportCaseDetails';
import SupportCaseProvider from '@features/support-case/components/SupportCaseProvider';
import { Box } from '@mui/material';

const SupportCaseView = () => {
  return (
    <Box
      component="div"
      sx={{
        margin: '1em',
        padding: '2em',
      }}
    >
      <SupportCaseProvider>
        <SupportCaseDetails />
      </SupportCaseProvider>
    </Box>
  );
}

export default SupportCaseView;