import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import HomeUtilityMonitoring from '@features/home-utility-monitoring/components/HomeUtilityMonitoring';
import { Box, Typography } from '@mui/material';
import { useMemo } from "react";

  /**
   * Monthly usage reports
   */
  const Usage = () => {
    const userTenant = useAppSelector((state: RootState) => state.userTenant);
    const tenant = useAppSelector((state: RootState) => state.tenant);
    const property = useAppSelector((state: RootState) => state.property);

    const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

    if (!currentTenant || !property) return <></>;

    return (
      <Box
        component="div"
        sx={{
          margin: '1em',
          padding: '2em',
        }}
      >
        <Typography
          variant="h5"
          sx={{
            mb: '3em'
          }}
        >
          Utility usage for {property.name}
        </Typography>
        <HomeUtilityMonitoring />
      </Box>
    );
  }

  export default Usage;