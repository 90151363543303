import { PropertyManifestEntry } from "@features/home-manifest/types";
import { ManifestDevice } from "@features/plan-manifest/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import UnpairingButton from "../UnpairingButton";

type Props = {
  entry: PropertyManifestEntry;
  isUnpairing: boolean;
  onUnpair: () => void;
  isOpen: boolean;
  onClose: () => void;
}

const UnpairingModal = ({
  isOpen,
  onClose,
  entry,
  isUnpairing,
  onUnpair,
}: Props) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>Unpair {(entry.device as ManifestDevice).friendly_name}</DialogTitle>
      <DialogContent>
        <Typography>
          Perform the following actions after entering unpairing mode in order to unpair the device.
        </Typography>
        <Typography component="p" sx={{ m: 3 }} fontStyle="italic">
          {(entry.device as ManifestDevice).unpairing_instructions || 'This device has no provided unpairing instructions.'}
        </Typography>
      </DialogContent>
      <DialogActions>
        <UnpairingButton isUnpairing={isUnpairing} onUnpair={onUnpair} />
        <Button onClick={onClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UnpairingModal;
