export type ProvisioningForm = {
  switchStatus: boolean;
  routerStatus: boolean;
  accessPointStatus: boolean;
  gatewayCableStatus: boolean;
  gatewayProvisioningStatus: boolean;
}

export enum ProvisioningStep {
  network = "Provision Network",
  gateway = "Provision Gateway",
  devices = "Provision Devices",
  review = "Review Status",
}