import { Category } from "@lib/labels";
import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import CurrentReadings from "./CurrentReadings";

type Props = {
  category: Category,
}

const UsageReadings = ({ category }: Props) => {
  return (
    <Card>
      <CardHeader title="Current readings"/>
      <Divider />
      <CardContent>
        <CurrentReadings category={category} />
      </CardContent>
    </Card>
  );
}

export default UsageReadings;