import HomeViewFooter from "@features/Common/HomeViewFooter";
import Header from '@features/Common/Header';
import HomeProvider from "@features/home/components/HomeProvider";
import { Outlet } from "react-router-dom";

type Props = {
  kiosk?: boolean;
}

const HomeViews = ({ kiosk }: Props) => {

  return (
    <HomeProvider>
      <Header />
      <Outlet />
      { !kiosk && <HomeViewFooter hidden={false} /> }
    </HomeProvider>
  );
}

export default HomeViews;