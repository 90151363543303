import StreetAddress from "@features/Address/StreetAddress";
import TownAddress from "@features/Address/TownAddress";


/**
 * Common Formik fields for a Shipping Address
 */
const ShippingFields = () => (
  <>
    <StreetAddress
      attributeName='shipping_address'
      width='100%'
      margin='0'
    />
    <TownAddress
      isRequired={true}
      attributeName='shipping_address'
      width='100%'
      margin='0'
    />
  </>
  );


export default ShippingFields;
