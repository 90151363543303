import { Property } from "@features/home/types";
import { createSlice } from "@reduxjs/toolkit";

const initialState: Property[] = [];

export const homesReducer = createSlice({
  name: 'homes',
  initialState,
  reducers: {
    setHomes: (state: Property[], action: any) => {
      state.splice(0, state.length);
      if (action.payload) {
        state.push(...action.payload);
      }
    },
  },
});

export default homesReducer.reducer;

export const {
  setHomes,
} = homesReducer.actions;