import { formatDate } from "@lib/utils";
import { Paper } from "@mui/material";
import { useMemo } from "react";
import { GasBill } from "../types";

import Chart from 'react-apexcharts';

export interface BillingHistoryChartProps {
  billingData?: GasBill[];
}

const BillingHistoryChart: React.FC<BillingHistoryChartProps> = function BillingHistoryChart(
  props: BillingHistoryChartProps,
) {
  const { billingData } = props;
  
  const chartData: ApexAxisChartSeries = useMemo(
    () => {
    if (!billingData?.length) return [];

    return [{
        name: 'Cost per therm',
        data: billingData.map((row) => ({
          x: formatDate(row.billing_cycle.end).toDate(),
          y: row.therm_cost,
        })),
        type: 'line',
      },{
        name: 'Therms used',
        data: billingData.map((row) => ({
          x: formatDate(row.billing_cycle.end).toDate(),
          y: row.therms_used,
        })),
        type: 'bar',
      }];
    },
    [billingData],
  );

  const chartOptions: ApexCharts.ApexOptions = useMemo(() => ({
    chart: {
      id: 'billing-chart',
      stacked: false,
      type: 'line',
      zoom: {
        enabled: false
      },
      animations: {
        enabled: true,
      },
      fontFamily: 'Montserrat',
      selection: {
        enabled: false,
      }
    },
    stroke: {
      show: true,
      curve: 'smooth',
      lineCap: 'butt',
      width: [4,0],
      dashArray: 0,
    },
    markers: {
      size: 4,
      strokeOpacity: 0,
      fillOpacity: 1,
      shape: 'square',
    },
    title: {
      text: 'Billing History',
      style: {
        fontFamily: 'Montserrat',
      },
      align: 'center',
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        }
      },
      yaxis: {
        lines: {
          show: true,
        }
      },
    },
    xaxis: {
      type: 'datetime',
      tickPlacement: 'on',
    },
    yaxis: [{
      title: {
        text: 'Cost per therm',
      },
      seriesName: 'Cost per therm',
      opposite: true,
      decimalsInFloat: 2,
    }, {
      title: {
        text: 'Therms used',
      },
      seriesName: 'Therms used',
      decimalsInFloat: 0,
    }],
    tooltip: {
      fontFamily: 'Montserrat',
      y: {
        title: {
        },
      },
      enabled: true,
    },
    legend: {
      show: false,
      fontFamily: 'Montserrat',
    },
    plotOptions: {
      bar: {
        horizontal: false,
      }
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['rgba(0, 0, 0, 0.8)', 'rgba(0, 0, 0, 0.8)'],
        fontFamily: 'Montserrat',
        fontSize: '11px',
      },
      offsetY: 26,
      background: {
        enabled: false,
        dropShadow: {
          enabled: false,
        }
      },
      dropShadow: {
        enabled: false,
      },
      formatter: (val, opts) => {
        if (opts.seriesIndex === 0) {
          return `$${val}`;
        }
        return '';
      }
    },
  }), []);

  return <Paper
    component={Chart}
    flex={1}
    series={chartData}
    type="bar"
    options={chartOptions}
    width="600"
    height="500"
  >
  </Paper>
}

export default BillingHistoryChart;
