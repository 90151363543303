import { PropertyManifestEntry } from "@features/home-manifest/types";
import { Device } from "@features/home/types";
import { Sensor } from "@features/plan-manifest/types";
import { Stack } from "@mui/material";
import EntryToConfirm from "./EntryToConfirm";

type Props = {
  entries: PropertyManifestEntry[];
  devices: Device[];
  onConfirm: (entry: PropertyManifestEntry, sensor: Sensor, device: Device) => void;
  onUnconfirm: (entryId: string) => void;
}

/* Displays device for pairing */
const DevicesToConfirm = ({ devices, entries, onConfirm, onUnconfirm }: Props) => (
  <Stack direction="row" spacing={2} m={2} justifyContent="center">
    {
      entries.map((entry) => (
        <EntryToConfirm
          key={`to-confirm-${entry.manifest_entry_id}`}
          entry={entry}
          devices={devices}
          onConfirm={onConfirm}
          onUnconfirm={onUnconfirm}
        />
      ))
    }
  </Stack>
);

export default DevicesToConfirm;