import { Stack, Typography } from "@mui/material";
import AccentColor from "./AccentColor";
import BrandedLogin from "./BrandedLogin";
import BuilderLogo from "./BuilderLogo";



const BuilderBranding = () => {

  return (
    <Stack spacing={2}>
      <Typography variant="h4" textAlign="center">Branding Preferences</Typography>
      <Stack
        direction="row"
        justifyContent="space-evenly"
      >
        <BuilderLogo />
        <Stack spacing={1}>
          <AccentColor />
          <BrandedLogin />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default BuilderBranding;