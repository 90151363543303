/**
 * A simple Confirmation Dialog.
 *
 * <ConfirmDialog title="Really?"
 *   open={confirmOpen}
 *   onClose={() => this.setState({confirmOpen: false})}
 *   onConfirm={() => doTerribleThing()}
 * >
 *   <Typography>
 *     Are you sure? Like really sure?
 *   </Typography>
 * </ConfirmDialog>
 */

import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import { Formik, FormikConfig, FormikHelpers, FormikProps, FormikValues, isFunction } from 'formik';
import React from 'react';


type Props = {
  title: string;
  open: boolean;
  onClose: () => void;
  onConfirm: (values: any, helpers: FormikHelpers<any>) => Promise<boolean|void>|void;
  positive?: string;
  negative?: string;
  children?: React.ReactNode;
} & Omit<FormikConfig<FormikValues>, 'onSubmit'>;

const ConfirmDialog = ({
  title,
  children,
  open,
  positive,
  negative,
  onClose,
  onConfirm,
  ...formikProps
}: Props) => {

  /** Submits form */
  const handleFormSubmit = async (
    values: any,
    helpers: FormikHelpers<any>,
  ) => {
    const result = await onConfirm(values, helpers);
    helpers.setSubmitting(false);

    if (result) {
      onClose();
    }
  }

  const renderChildren = (f: FormikProps<FormikValues>) => {
    if (!children) return <></>;
    if (!isFunction(children)) return children;
    return (children as (bag: FormikProps<FormikValues>) => React.ReactNode)(f);
  }

  return (
    <Formik
      {...formikProps}
      onSubmit={handleFormSubmit}
    >
    {f => (
      <Dialog
        open={open}
        onClose={() => onClose()}
        aria-labelledby="confirm-dialog"
      >
        <DialogTitle id="confirm-dialog">{title}</DialogTitle>
        <DialogContent>
          <form noValidate onSubmit={f.handleSubmit}>
            {renderChildren(f) as JSX.Element}
          </form>
        </DialogContent>
        <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => onClose()}
            >
              {negative ?? 'No'}
            </Button>
            <LoadingButton
              loading={f.isSubmitting}
              variant="contained"
              color="primary"
              onClick={() => f.submitForm()}
              disabled={f.isSubmitting}
            >
              {positive ?? 'Yes'}
            </LoadingButton>
        </DialogActions>
      </Dialog>
    )}
    </Formik>
  );
};
export default ConfirmDialog;
