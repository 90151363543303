import { useAppDispatch, useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { pollingInterval } from "@features/tenant-selector/types";
import { FC, ReactElement, useEffect, useMemo } from "react";
import { useMyUsersQuery, useTenantUsersQuery } from "../api";
import { setMyUsers } from "../slice";

/** Wrapper for user list under selected tenant */
const UsersProvider: FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const isOtherTenant = useMemo(() => (
    !!userTenant?.tenant_id &&
    !!currentTenant?.tenant_id &&
    userTenant.tenant_id !== currentTenant.tenant_id
  ), [currentTenant.tenant_id, userTenant.tenant_id]);


  const {
    currentData: myUsers,
  } = useMyUsersQuery({
    userTenantId: userTenant?.tenant_id || '',
  }, {
    skip: !userTenant?.tenant_id || isOtherTenant,
    pollingInterval,
  });

  const {
    currentData: tenantUsers,
  } = useTenantUsersQuery({
    userTenantId: userTenant?.tenant_id || '',
    tenantId: currentTenant?.tenant_id || ''
  }, {
    skip: !userTenant?.tenant_id || !isOtherTenant,
    pollingInterval,
  });

  useEffect(() => {
    if (isOtherTenant && tenantUsers) {
      dispatch(setMyUsers(tenantUsers));
    }
    if (!isOtherTenant && myUsers) {
      dispatch(setMyUsers(myUsers));
    }
  }, [userTenant.tenant_id, dispatch, isOtherTenant, tenantUsers, myUsers]);

  return children as ReactElement;
}

export default UsersProvider;
