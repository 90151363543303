import HomeStatus from '@features/home-status/components/HomeStatus';


const CurrentStatus = () => {

  return (
    <>
      <HomeStatus />
    </>
  );
}

export default CurrentStatus;