import { riotApi } from "@features/aws-user/api";
import { Neighborhood, NeighborhoodCreate, NeighborhoodUpdate } from "@features/neighborhood/types";
import { HTTPMethodType } from "@lib/types";
import { U } from "@lib/utils";

export const neighborhoodsApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      neighborhoods: builder.query<Neighborhood[], {
        userTenantId: string,
      }>({
        query: ({ userTenantId }) => ({
          url: `tenant/${U(userTenantId)}/neighborhoods`,
          params: {
            include_names: true,
          },
        }),
      }),
      createNeighborhood: builder.mutation<Neighborhood, {
        userTenantId: string,
        neighborhood: NeighborhoodCreate,
      }>({
        query: ({ userTenantId, neighborhood }) => ({
          url: `tenant/${U(userTenantId)}/neighborhoods`,
          method: HTTPMethodType.POST,
          body: neighborhood,
        })
      }),
      updateNeighborhood: builder.mutation<Neighborhood, {
        userTenantId: string,
        neighborhoodId: string,
        neighborhood: NeighborhoodUpdate,
      }>({
        query: ({ userTenantId, neighborhood, neighborhoodId }) => ({
          url: `tenant/${U(userTenantId)}/neighborhoods/${U(neighborhoodId)}`,
          method: HTTPMethodType.PATCH,
          body: neighborhood,
        }),
      }),
      updateNeighborhoodHomes: builder.mutation<Neighborhood, {
        userTenantId: string,
        neighborhoodId: string,
        add: string[],
        remove: string[],
      }>({
        query: ({ userTenantId, neighborhoodId, add, remove }) => ({
          url: `tenant/${U(userTenantId)}/neighborhoods/${U(neighborhoodId)}/homes`,
          method: HTTPMethodType.PATCH,
          params: {
            add, remove,
          }
        }),
      }),
    };
  }
});

export const {
  useNeighborhoodsQuery,
  useCreateNeighborhoodMutation,
  useUpdateNeighborhoodMutation,
  useUpdateNeighborhoodHomesMutation,
} = neighborhoodsApi;