import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import AdminTable from '@features/Common/AdminTable';
import { formatDate } from '@lib/utils';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, Stack, Tooltip, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useOemDeviceHistoryQuery } from '../api';
import { OemDevice, OemDeviceHistoricalStatus } from '../types';

const columns = [
  {
    name: 'oem_name',
    label: 'Manufacturer',
  },
  {
    name: 'device_type',
    label: 'Device type',
  },
  {
    name: 'serial_number',
    label: 'Serial Number',
  },
  {
    name: 'status',
    label: 'Status',
  }
];

const DATEFORMAT = 'YYYY-M-D H:m:s';

const OemDeviceList = () => {

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const oemDevices = useAppSelector((state: RootState) => state.oemDevices);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const [selectedSerialNumber, setSelectedSerialNumber] = useState<string|null>(null)
  const [startDate, setStartDate] = useState<string>(formatDate(null).add(-1, 'day').format(DATEFORMAT));

  const {
    currentData: deviceStatuses,
  } = useOemDeviceHistoryQuery({
    userTenantId: currentTenant?.tenant_id || '',
    serialNumber: selectedSerialNumber || '',
    startDate,
  }, {
    skip: !currentTenant?.tenant_id || !selectedSerialNumber,
  });

  const handleOpenDeviceHistory = useCallback((value: string) => {
    setSelectedSerialNumber(value);
  }, []);

  const handleCloseDeviceHistory = useCallback(() => {
    setSelectedSerialNumber(null);
  }, []);

  const renderedTable = useMemo(() => (
    <AdminTable<OemDevice & {name: string}>
      title=""
      data={oemDevices}
      label="OEM Device"
      id_key='serial_number'
      name_key='serial_number'
      columns={columns}
      customActions={
        (row) => <>
        <Tooltip title='View status history' placement="top" arrow>
            <IconButton onClick={() => handleOpenDeviceHistory(row.serial_number)}>
              <WorkHistoryIcon />
            </IconButton>
          </Tooltip>
        </>
      }
      refresh={() => {}}
      options={{
        enableNestedDataAccess: '.',
        tableBodyMaxHeight: '41em',
      }}
      editPermission="delete-oem"
      deletePermission="delete-oem"
    ></AdminTable>
  ), [handleOpenDeviceHistory, oemDevices]);

  const deviceHistory = useMemo(() => {
    if (!deviceStatuses) return undefined;
    return [
    ...deviceStatuses,
    ]
      .sort((a, b) => formatDate(b.time).unix() - formatDate(a.time).unix())
      .filter((status: OemDeviceHistoricalStatus, idx, arr) => (
        idx === 0 || status.status !== arr[idx-1]?.status
      ));
  }, [deviceStatuses]);

  const selectedDevice = useMemo(() => oemDevices?.find(d => d.serial_number === selectedSerialNumber), [oemDevices, selectedSerialNumber]);

  const deviceName = useMemo(() => {
    if (!selectedDevice?.serial_number) return 'unknown appliance';
    return [
      selectedDevice?.oem_name || '',
      selectedDevice?.device_type,
    ].join(' ');
  }, [selectedDevice?.device_type, selectedDevice?.oem_name, selectedDevice?.serial_number]);

  return (
    <>
      <Box
        component="div"
        sx={{
          maxWidth: 'unset',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
        }}
      >
        {renderedTable}
      </Box>
      <Dialog open={!!selectedSerialNumber} onClose={handleCloseDeviceHistory}>
        <DialogTitle>Status History for {deviceName}</DialogTitle>
        <DialogContent>
          {!deviceHistory && <CircularProgress />}
          {
            deviceHistory &&
            <List
              sx={{
                width: 500,
                bgcolor: 'background.paper',
                position: 'relative',
                overflow: 'auto',
                maxHeight: 300,
                '& ul': { padding: 0 },
              }}
              subheader={<li />}
            >
              {
                deviceHistory.map((status, idx) => (
                  <ListItem key={`status-${status.time}-${status.status}-${idx}`} sx={{ padding: 1 }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: 'inherit' }}>
                      <Typography>{status.status}</Typography>
                      <Tooltip title={formatDate(status.time).format(DATEFORMAT)} placement="top" arrow>
                        <Typography variant='caption'>{formatDate(status.time).fromNow()}</Typography>
                      </Tooltip>
                    </Stack>
                  </ListItem>
                ))
              }
            </List>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeviceHistory}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default OemDeviceList;