import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { useCreateDocumentMutation, useDocumentContentsUrlQuery } from "@features/all-documents/api";
import { DEFAULT_DOCUMENT_TTL, Document, DocumentApiResponse, DocumentType } from "@features/all-documents/types";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { Plan } from "@features/floorplan/types";
import { useCreatePlanManifestMutation } from "@features/plan-manifest/api";
import { PlanManifest } from "@features/plan-manifest/types";
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { RIOT_BLUE } from "theme";

export type NewManifestModalProps = {
  plan: Plan;
  open: boolean;
  onClose: (changes: boolean) => void;
};

const NewManifestModal = ({ plan, open, onClose }: NewManifestModalProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [floorplan, setFloorplan] = useState<File|null>(null);

  const [docId, setDocId] = useState<string>('');

  const {
    currentData: docUrl,
  } = useDocumentContentsUrlQuery({
    userTenantId: currentTenant?.tenant_id || '',
    documentId: docId || '',
  }, {
    skip: !currentTenant?.tenant_id || !docId,
  });

  const [createPlanManifest] = useCreatePlanManifestMutation();

  const [createDocument] = useCreateDocumentMutation();

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    const newManifest = await createPlanManifest({
      userTenantId: currentTenant.tenant_id!,
      planId: plan.plan_id!,
      body: {
        name,
        description,
      }
    });

    const errorDetails = (newManifest as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't create plan manifest:", {
        key: "manifest-error",
        content: (
          <ExpandableSnack
            id="manifest-error"
            message={"Couldn't create plan manifest:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
      setLoading(false);
    } else {
      enqueueSnackbar("Created plan manifest", {
        variant: "success",
      });
      const manifestId = ((newManifest as any).data as PlanManifest).manifest_id;
      const name = `${manifestId}.fbx`;

      if (floorplan !== null) {
        const docResponse = await createDocument({
          userTenantId: currentTenant?.tenant_id || '',
          path: name,
          body: {
            name,
            description: 'floorplan drawing',
            document_type: DocumentType.PLAN,
            path: name,
            ttl: DEFAULT_DOCUMENT_TTL,
          },
        });

        const errorDetails = (docResponse as ApiErrorResponse)?.error;

        if (errorDetails) {
          enqueueSnackbar("Couldn't upload floorplan:", {
            key: "document-error",
            content: (
              <ExpandableSnack
                id="document-error"
                message={"Couldn't upload floorplan:"}
                variant={SnackType.error}
                detail={getErrorMessage(errorDetails)}
              />),
          });
        } else {
          const formData = new FormData();

          const doc = (docResponse as DocumentApiResponse).data;

          Object.entries(doc.upload_headers.fields).forEach(([k, v]) => {
            formData.append(k, v);
          });

          formData.append('file', floorplan);

          const result = await fetch(doc.upload_headers.url, {
            method: 'POST',
            body: formData,
          });

          if (!result.ok) {
            enqueueSnackbar("Couldn't upload floorplan:", {
              key: "floorplan-error",
              content: (
                <ExpandableSnack
                  id="floorplan-error"
                  message={"Couldn't upload floorplan:"}
                  variant={SnackType.error}
                  detail={result.statusText || result.status}
                />),
            });
          } else {
            setDocId(((docResponse as any).data as Document).document_id);
          }
        }

      }

      onClose(true);
    }

  }, [createPlanManifest, currentTenant.tenant_id, plan.plan_id, name, description, enqueueSnackbar, floorplan, onClose, createDocument]);


  return (
    <Dialog
      open={open}
      scroll="paper"
      fullWidth
      onClose={() => onClose(false)}
    >
      <DialogTitle>Create a new manifest for {plan.name}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            label="Name"
            placeholder=""
            value={name}
            onChange={(e) => setName(e.target.value)}
            size="small"
            required
          />
          <TextField
            variant="outlined"
            label="Description"
            placeholder=""
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            size="small"
          />
            <Stack spacing={1}>
              <FileUploader
                multiple={false}
                handleChange={(f: File) => setFloorplan(f)}
                name="floorplan drawing"
                label="Upload or drop your floorplan drawing"
                types={['FBX']}
                maxSize={2}
                children={
                  <Box
                    component="div"
                    sx={{
                      border: `1px solid ${currentTenant.builder_color || RIOT_BLUE}`,
                      padding: '1em',
                      width: '100%'
                    }}
                  >
                    <Typography variant="caption">
                      Click <Typography variant="button">here</Typography> or drag your floorplan drawing for upload
                    </Typography>
                  </Box>
                }
              />
              <Typography variant="body1">{floorplan?.name || 'No file selected'}</Typography>
            </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          loading={loading}
        >
          Create manifest
        </LoadingButton>
      </DialogActions>
    </Dialog>);
}

export default NewManifestModal;