import { riotApi } from "@features/aws-user/api";
import { AnalyticsSet } from "@lib/types";
import { U } from "@lib/utils";


export const zipsApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      zipCodes: builder.query<AnalyticsSet[], {
        userTenantId: string,
      }>({
        query: ({ userTenantId }) => ({
          url: `tenant/${U(userTenantId)}/system/analytics/zips`,
        }),
      }),
    };
  }
});

export const {
  useZipCodesQuery,
} = zipsApi;