import { useAppDispatch, useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { pollingInterval } from '@features/tenant-selector/types';
import { FC, ReactElement, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSupportCaseQuery } from '../api';
import { setSupportCase } from '../slice';


const SupportCaseProvider: FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const pathParams = useParams();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const propertyId = useMemo(() => pathParams?.property_id || '', [pathParams?.property_id]);
  const caseId = useMemo(() => pathParams?.case_id || '', [pathParams?.case_id]);

  const {
    currentData: supportCase,
  } = useSupportCaseQuery({
    userTenantId: currentTenant?.tenant_id || '',
    propertyId,
    caseId,
  }, {
    skip: !currentTenant?.tenant_id || !propertyId || !caseId,
    pollingInterval,
  });

  useEffect(() => {
    if (caseId && supportCase?.case_id) {
      dispatch(setSupportCase(supportCase));
    } else {
      dispatch(setSupportCase(null));
    }
  }, [caseId, dispatch, supportCase]);

  return children as ReactElement;
}

export default SupportCaseProvider;