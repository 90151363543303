import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import AdminTable from "@features/Common/AdminTable";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { getHeaderStyles } from "@features/Common/tables";
import { DEFAULT_ADDRESS } from "@features/home/types";
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { MUIDataTableColumn } from "mui-datatables";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { RIOT_BLUE } from "theme";
import {
  useContactsQuery,
  useCreateMyContactMutation,
  useCreateTenantContactMutation,
  useDeleteMyContactMutation,
  useUpdateMyContactMutation,
  useUpdateTenantContactMutation
} from "../api";
import {
  Contact,
  ContactCreate,
  ContactUpdate,
  PrivacyLevel,
  PropertyIds
} from "../types";
import ContactFields from "./ContactFields";


const ContactList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const contacts = useAppSelector((state: RootState) => state.contacts);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant?.builder_color || RIOT_BLUE, [currentTenant?.builder_color]);

  const [propertyIds, setPropertyIds] = useState<PropertyIds>({
    add_properties: [],
    remove_properties: [],
  });

  const [
    createMyContact,
  ] = useCreateMyContactMutation();

  const [
    createTenantContact,
  ] = useCreateTenantContactMutation();

  const [
    updateTenantContact,
  ] = useUpdateTenantContactMutation();

  const [
    updateMyContact,
  ] = useUpdateMyContactMutation();

  const [
    deleteMyContact,
  ] = useDeleteMyContactMutation();

  /**
   * detect changes in the properties contact is shared with
   */
  const handleUpdateProperties = useCallback((toRemove: string[], toAdd: string[]) => {
    /**
     * we may have changed the order of properties,
     * but if they are the same, we don't need to make updates
     */
    const noChange = (a: string[], b: string[]): boolean => (
      a.every(el => b.indexOf(el) !== -1)
      && b.every(el => a.indexOf(el) !== -1)
    );

    if (
      !noChange(propertyIds.add_properties, toAdd)
      || !noChange(propertyIds.remove_properties, toRemove)
    ) {
      setPropertyIds({
        add_properties: toAdd,
        remove_properties: toRemove,
      });
    }
  }, [propertyIds.add_properties, propertyIds.remove_properties]);


  /** format changes to the record for update */
  const formatContactForUpdate = useCallback((contact: Contact) => {

    const getAddress = () => {
      if (
        !contact.physical_address?.address_line_1
        && !contact.physical_address?.address_line_2
        && !contact.physical_address?.city
        && !contact.physical_address?.state
      ) {
        return {
          ...DEFAULT_ADDRESS,
          legal_address: {
            lot: '',
            block: '',
          }
        };
      }
      return {
        ...DEFAULT_ADDRESS,
        ...contact.physical_address,
        legal_address: {
          lot: '',
          block: '',
        }
      };
    }

    const getPropertyIds = () => {
      if (!propertyIds) return undefined;

      if (!contact.contact_id) {
        if (!propertyIds.add_properties.length) return undefined;
        return Array.from(new Set(propertyIds.add_properties));
      }

      const getToRemove = () => {
        // nothing to remove
        if (!contact.property_ids || !contact.property_ids?.length) {
          return undefined;
        }
        if (!propertyIds.remove_properties.length) return undefined;
        // filter down to the ones on the contact
        return propertyIds.remove_properties.filter(pp => contact.property_ids?.includes(pp));
      }

      const getToAdd = () => {
        // nothing to add
        if (!propertyIds.add_properties.length) return undefined;
        // filter out to the properties on the contact
        return propertyIds.add_properties.filter(pp => !contact.property_ids?.includes(pp));
      }

      const add = getToAdd();
      const remove = getToRemove();

      if (!add && !remove) return undefined;

      return {
        add_properties: add,
        remove_properties: remove,
      } as PropertyIds;
    }

    const contactUpdate = {
      ...contact,
      physical_address: getAddress(),
      property_ids: getPropertyIds(),
    };

    return contactUpdate;
  }, [propertyIds]);

  /** Handles Save button on New/Edit Contact dialog */
  const handleSaveContact = useCallback(async (contact: Partial<Contact>) => {
    /** New contact */
    if (!contact.contact_id) {
      if (currentTenant?.tenant_id === userTenant?.tenant_id) {
        /** Create a contact for myself */
        const newContact = await createMyContact({
          userTenantId: userTenant?.tenant_id || '',
          body: formatContactForUpdate(contact) as ContactCreate,
        });

        const errorDetails = (newContact as ApiErrorResponse)?.error;

        if (errorDetails) {
          enqueueSnackbar("Couldn't create contact:", {
            key: "contact-error",
            content: (
              <ExpandableSnack
                id="contact-error"
                message={"Couldn't create contact:"}
                variant={SnackType.error}
                detail={getErrorMessage(errorDetails)}
              />),
          });
        } else {
          enqueueSnackbar("Created contact", {
            variant: "success",
          });
        }

        return (newContact as any).data;
      }
      /** Create a contact for another tenant */
      const newContact = await createTenantContact({
        userTenantId: userTenant?.tenant_id || '',
        tenantId: currentTenant?.tenant_id || '',
        body: formatContactForUpdate(contact) as ContactCreate,
      });

      const errorDetails = (newContact as ApiErrorResponse)?.error;

      if (errorDetails) {
        enqueueSnackbar("Couldn't create contact:", {
          key: "contact-error",
          content: (
            <ExpandableSnack
              id="contact-error"
              message={"Couldn't create contact:"}
              variant={SnackType.error}
              detail={getErrorMessage(errorDetails)}
            />),
        });
      } else {
        enqueueSnackbar("Created contact", {
          variant: "success",
        });
      }

      return (newContact as any).data;

    }
    /** Existing contact */
    if (currentTenant?.tenant_id === userTenant?.tenant_id) {
      /** Update my contact */
      const updatedContact = await updateMyContact({
        userTenantId: userTenant?.tenant_id || '',
        contactId: contact?.contact_id || '',
        body: formatContactForUpdate(contact) as ContactUpdate,
      });

      const errorDetails = (updatedContact as ApiErrorResponse)?.error;

      if (errorDetails) {
        enqueueSnackbar("Couldn't update contact:", {
          key: "contact-error",
          content: (
            <ExpandableSnack
              id="contact-error"
              message={"Couldn't update contact:"}
              variant={SnackType.error}
              detail={getErrorMessage(errorDetails)}
            />),
        });
      } else {
        enqueueSnackbar("Updated contact", {
          variant: "success",
        });
      }

      return updatedContact;
    }
    /** Create a contact for another tenant */
    const updatedContact = await updateTenantContact({
      userTenantId: userTenant?.tenant_id || '',
      tenantId: currentTenant?.tenant_id || '',
      contactId: contact?.contact_id || '',
      body: formatContactForUpdate(contact) as ContactUpdate,
    });

    const errorDetails = (updatedContact as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't update contact:", {
        key: "contact-error",
        content: (
          <ExpandableSnack
            id="contact-error"
            message={"Couldn't update contact:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("Updated contact", {
        variant: "success",
      });
    }

    return updatedContact;
  }, [createMyContact, createTenantContact, currentTenant?.tenant_id, enqueueSnackbar, formatContactForUpdate, updateMyContact, updateTenantContact, userTenant?.tenant_id]);

  const handleDeleteContact = useCallback(async (contact: Contact) => {
    const deletedContact = await deleteMyContact({
      userTenantId: userTenant?.tenant_id || '',
      contactId: contact?.contact_id || '',
      force: false,
    }).unwrap();


    if (!deletedContact) {
      enqueueSnackbar("Couldn't delete contact:", {
        variant: 'error',
      });
    } else {
      enqueueSnackbar("Deleted contact", {
        variant: "success",
      });
    }

    return deletedContact;
  }, [deleteMyContact, enqueueSnackbar, userTenant?.tenant_id]);

  const { refetch } = useContactsQuery({
    userTenantId: userTenant?.tenant_id || '',
    tenantId: currentTenant?.tenant_id || '',
  }, {
    skip: !userTenant?.tenant_id,
  });

  const renderedContactsTable = useMemo(() => {
    if (userTenant.checkingTenant) return <Backdrop open><CircularProgress /></Backdrop>;

    const columns: MUIDataTableColumn[] = [
      {
        name: 'name',
        label: 'Name',
      } ,
      {
        name: 'phone_number',
        label: 'Phone Number',
      },
      {
        name: 'website',
        label: 'Website',
      },
      {
        name: 'description',
        label: 'Description',
      },
      {
        name: 'privacy_level',
        label: 'Privacy Level',
      }
    ].map(cc => ({
      ...cc,
      options: {
        setCellHeaderProps: () => getHeaderStyles(themeColor),
      },
    } as MUIDataTableColumn));

    return (
      <AdminTable<Contact>
        title="Contacts"
        label="Contact"
        id_key="contact_id"
        name_key="name"
        columns={columns}
        data={contacts}
        refresh={refetch}
        makeNew={() => ({
          name: '',
          description: '',
          physical_address: {
            ...DEFAULT_ADDRESS,
          },
          privacy_level: PrivacyLevel.public,
          phone_number: '',
          website: '',
        })}
        onSave={handleSaveContact}
        onDelete={handleDeleteContact}
        editPermission={'update-contacts'}
        deletePermission={'delete-contacts'}
      >
        <ContactFields onShare={handleUpdateProperties} />
      </AdminTable>
    );
  }, [contacts, handleDeleteContact, handleSaveContact, handleUpdateProperties, refetch, themeColor, userTenant.checkingTenant]);

  /** MAIN RENDER */
  return (
    <Box
      component="div"
      sx={{
        margin: '1em',
        padding: '2em',
      }}
    >
      <Typography variant="h4">{currentTenant.name}</Typography>
      <Typography variant="h5">Contacts</Typography>
      <Box
        component="div"
        sx={{
          mt: '3em',
        }}
      >
        {renderedContactsTable}
      </Box>
    </Box>
  );
}

export default ContactList;