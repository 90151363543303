import { Card, CardContent } from "@mui/material";
import { useCallback } from "react";
import { ManifestEntryCreate } from "../types";
import ManifestEntryFields from "./ManifestEntryFields";

type Props = {
  entry: Partial<ManifestEntryCreate>;
  onChange: (entry: Partial<ManifestEntryCreate>) => void;
}


const ManifestEntryEditor = ({ entry, onChange}: Props) => {

  const handleChange = useCallback((entry: Partial<ManifestEntryCreate>) => {
    onChange(entry)
  }, [onChange]);

  return (
    <Card raised>
      <CardContent>
        <ManifestEntryFields
          onChange={handleChange}
          existingEntry={entry}
        />
      </CardContent>
    </Card>
  );
}

export default ManifestEntryEditor;