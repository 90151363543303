import { TenantType } from "@features/userTenant/types";
import { createSlice } from "@reduxjs/toolkit";
import { SelectedTenant } from "./types";


const initialState: SelectedTenant = {
  currentTenant: {
    name: '',
    property_ids: [],
    tenant_id: '',
    email: '',
    created: '',
    updated: '',
    is_deleted: false,
    description: '',
    tenant_type: TenantType.Homeowner,
    phone_number: '',
  },
  access: [],
}

/** State slice for the tenant selected to use */
export const tenantReducer = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    setCurrentTenant: (state: any, action: any) => {
      if (action.payload) {
        state.currentTenant = action.payload;
      }
    },
    resetTenant: () => initialState,
    setAccess: (state, action) => {
      if (action.payload) {
        state.access = action.payload;
      }
    },
  }
});

export default tenantReducer.reducer;

export const {
  setCurrentTenant,
  resetTenant,
  setAccess,
} = tenantReducer.actions;