import { Stack, Typography } from "@mui/material";
import SetupNetwork from "./SetupNetwork";

const ProvisionNetworkPanel = () => {

  return (
    <Stack direction="row">
      <Stack alignItems="center" justifyItems="space-between" spacing={1}>
        <Typography variant="h5">Connect Network devices</Typography>
        <SetupNetwork />
      </Stack>
      <img
        width="400"
        height="400"
        alt="network"
        src="/provisioning/network.png"
      />
    </Stack>
  );

}

export default ProvisionNetworkPanel;