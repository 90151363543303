import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { Property } from "@features/home/types";
import { Tenant, TenantType } from "@features/userTenant/types";
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import DeleteIcon from '@mui/icons-material/Clear';
import InputIcon from '@mui/icons-material/Input';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import { IconButton, Tooltip } from "@mui/material";
import { useMemo } from "react";
import { RIOT_BLUE } from "theme";


type Props = {
  home: Property;
  callback: (home: Property, actionType: string) => void;
}

const canSellHome = (home: Property, userTenant: Tenant) => {

  // can't sell home if there is no homeowner
  if (!home.homeowner_id) return false;
  // System Tenant users can do everything
  if (userTenant.tenant_type === TenantType.System) {
    // but no need to release the homes from builder again
    if (userTenant.tenant_id === home.owner_id) return false;
    return true;
  }
  // the rest of users can only sell their own homes
  if (userTenant.tenant_id === home.owner_id) return true;

  return false;
}

const HomeActions = ({ home, callback }: Props) => {
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant?.builder_color || RIOT_BLUE, [currentTenant?.builder_color]);

  return (
    <>
      <Tooltip title="Provision Home" placement="top" arrow>
        <span>
          <IconButton
            sx={{
              color: themeColor,
            }}
            onClick={() => callback(home, 'provision')}
          >
            <AddToQueueIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Commission Home" placement="top" arrow>
        <span>
          <IconButton
            sx={{
              color: themeColor,
            }}
            onClick={() => callback(home, 'commission')}
          >
            <InputIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Start Sale" placement="top" arrow>
        <span>
          <IconButton
            sx={{
              color: themeColor,
            }}
            onClick={() => callback(home, 'transfer')}
            disabled={!canSellHome(home, userTenant)}
          >
            <TransferWithinAStationIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Unregister home" placement="top" arrow>
        <span>
          <IconButton
            sx={{
              color: themeColor,
            }}
            onClick={() => callback(home, 'unregister')}
          >
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
}
export default HomeActions;