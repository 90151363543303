import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { Button, Divider } from "@aws-amplify/ui-react";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { TenantType } from "@features/userTenant/types";
import { ApiErrorResponse, SnackType } from '@lib/types';
import { getErrorMessage } from "@lib/utils";
import { LoadingButton } from "@mui/lab";
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { useAddHomesMutation, useNeighborhoodQuery } from "../api";

type Props = {
  onClose: () => void;
  open: boolean;
}

const AddHomesDialog = ({open, onClose }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const neighborhood = useAppSelector((state: RootState) => state.neighborhood.neighborhood);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const homes = useAppSelector((state: RootState) => state.homes);

  const  currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const canEdit = useMemo(() => [TenantType.Builder, TenantType.System].includes(currentTenant?.tenant_type), [currentTenant?.tenant_type]);

  const neighborhoodHomes = useMemo(() => homes.filter(h => neighborhood?.property_ids?.includes(h.property_id)), [homes, neighborhood?.property_ids]);

  const availableHomes = useMemo(() => homes
    .filter(h => neighborhoodHomes.every(nh => nh.property_id !== h.property_id)), [homes, neighborhoodHomes]);

  const [isSaving, setSaving] = useState<boolean>(false);
  const [selectedHomeIds, setSelectedHomeIds] = useState<string[]>([]);

  const [ addHomes ] = useAddHomesMutation();

  const {
    refetch: refetchNeighborhood,
  } = useNeighborhoodQuery({
    userTenantId: currentTenant?.tenant_id || '',
    neighborhoodId: neighborhood?.neighborhood_id || '',
  }, {
    skip: !currentTenant?.tenant_id || !neighborhood?.neighborhood_id,
  });


  const handleAddHomes = useCallback(async () => {
    if (!canEdit) return;

    const updated = await addHomes({
      userTenantId: currentTenant?.tenant_id || '',
      neighborhoodId: neighborhood?.neighborhood_id || '',
      propertyIds: selectedHomeIds,
    });

    const errorDetails = (updated as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't add homes to the neighborhood:", {
        key: "neighborhood-error",
        content: (
          <ExpandableSnack
            id="neighborhood-error"
            message={"Couldn't add homes to the neighborhood:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
      setSaving(false);
    } else {
      enqueueSnackbar("Successfully added homes to the neighborhood", {
        variant: "success",
      });
      refetchNeighborhood();
      setSaving(false);
      onClose();
    }

  }, [addHomes, canEdit, currentTenant?.tenant_id, enqueueSnackbar, neighborhood?.neighborhood_id, onClose, refetchNeighborhood, selectedHomeIds]);


  const handleToggle = useCallback((homeId: string) => {
    if (selectedHomeIds.includes(homeId)) {
      setSelectedHomeIds(selectedHomeIds.filter(id => id !== homeId));
    } else {
      setSelectedHomeIds([
        ...selectedHomeIds,
        homeId,
      ]);
    }
  }, [selectedHomeIds]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Homes to {neighborhood?.name || 'this neighborhood'}</DialogTitle>
      <Divider />
      <DialogContent>
        <List
          disablePadding
          sx={{
            height: '20em',
            overflowY: 'scroll',
          }}
        >
        {
          availableHomes.map(ah => (
            <ListItem key={`available-home-${ah.property_id}`} disableGutters dense>

              <ListItemButton role={undefined} onClick={() => handleToggle(ah.property_id)} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={selectedHomeIds.includes(ah.property_id)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': `checkbox-list-label-${ah.property_id}` }}
                  />
                </ListItemIcon>
                <ListItemText id={`checkbox-list-label-${ah.property_id}`}>
                  {ah.name || [ah.physical_address.address_line_1, ah.physical_address.address_line_2].join('-')}
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))
        }
        </List>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} disabled={isSaving}>Cancel</Button>
        <LoadingButton
          onClick={() => handleAddHomes()}
          variant="contained"
          loading={isSaving}
          disabled={!selectedHomeIds.length || isSaving}
        >Add</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default AddHomesDialog;