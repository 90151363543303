import SensorReadings from "@features/device/components/SensorReadings";
import { PropertyManifestEntry } from "@features/home-manifest/types";
import { Device } from "@features/home/types";
import { ManifestDevice, Sensor } from "@features/plan-manifest/types";
import { findCategoryLabel } from "@lib/labels";
import LoadingButton from "@mui/lab/LoadingButton";
import { Card, CardActions, CardContent, CardMedia, Typography } from "@mui/material";
import { useMemo } from "react";

type Props = {
  sensor: Sensor;
  entry: PropertyManifestEntry;
  devices: Device[];
  onUnconfirm: (entryId: string) => void;
  onConfirm: (entry: PropertyManifestEntry, sensor: Sensor, device: Device) => void;
}

const SensorToConfirm = ({
  sensor,
  entry,
  devices: devicesUnsorted,
  onUnconfirm,
  onConfirm,
}: Props) => {
  const devices = useMemo(() => {
    /* Sort devices by date desc */
    return devicesUnsorted && [...devicesUnsorted].sort((a, b) => b.datetime - a.datetime);
  }, [devicesUnsorted]);

  const confirmed = useMemo(() => !!entry.commissioned, [entry.commissioned]);
  const entityId = useMemo(() => entry.sensor_map?.[sensor.sensor_id ?? sensor.friendly_name], [entry.sensor_map, sensor.sensor_id, sensor.friendly_name]);

  const pairedDevice = useMemo(() => devices?.find(d => !!entityId && d.data.entity_id === entityId), [devices, entityId]);

  const sensorName = useMemo(() => (
    sensor.friendly_name ||
    (entry.device as ManifestDevice).friendly_name ||
    `${entry.property_area} ${findCategoryLabel(sensor.sensor_category) || ''} sensor`
  ), [entry.device, entry.property_area, sensor.friendly_name, sensor.sensor_category]);


  const sensorLocation = useMemo(() => `${entry.property_area}`, [entry.property_area]);

  /**
   * Displays commissioning button
   */
  const renderedCommissionButton = useMemo(() => {
    if (confirmed) {
      return (
        <LoadingButton
          size="small"
          color="error"
          onClick={() => onUnconfirm(entry.manifest_entry_id)}
        >
          Undo confirmation
        </LoadingButton>
      );
    }
    return (
      <LoadingButton
        onClick={() => onConfirm(entry, sensor, pairedDevice!)}
      >
        Confirm
      </LoadingButton>
    );
  }, [confirmed, entry, onConfirm, onUnconfirm, pairedDevice, sensor]);

  return (
    <Card key={sensor.order}>
      <CardMedia
        component="img"
        src={''}
        height={128}
        sx={{ width: '128px', ml: 'auto', mr: 'auto', mt: 1 }}
      />
      <CardContent>
        <Typography mt={1} color="text.primary" gutterBottom>
          {sensorName}
        </Typography>
        <Typography variant="body1" component="div">
          {sensorLocation}
        </Typography>
        <Typography mt={1} color="text.secondary" gutterBottom>
          {(entry.device as ManifestDevice).friendly_name || '-'}
        </Typography>
        {
          sensor.sensor_category &&
          <Typography sx={{ mb: 1.5 }} color="text.secondary" gutterBottom>
            {findCategoryLabel(sensor.sensor_category)}
          </Typography>
        }
        {
          pairedDevice !== undefined &&
          <SensorReadings
            device={pairedDevice}
            entry={entry}
          />
        }
        <Typography sx={{ fontSize: 14 }} color="text.secondary">
          {confirmed ? 'Device is confirmed.' : 'Device is unconfirmed.'}
        </Typography>
      </CardContent>
      <CardActions>
        {renderedCommissionButton}
      </CardActions>
    </Card>
  );
}

export default SensorToConfirm;