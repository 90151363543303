import { Property } from "@features/home/types";
import { U } from "@lib/utils";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import { IconButton, Tooltip } from "@mui/material";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  home: Property;
}

const HomeStatusColumn = ({ home }: Props) => {
  const navigate = useNavigate();

  const statusIcon = useMemo(() => {
    const failures = home.event_summary?.failures;

    const haveFailures = failures && Object.keys(failures).length > 0;
    const unhealtyChecks = Object.values(home.health_summary?.health_checks || {})
      .filter(e => e.result.unhealthy);

    if (haveFailures || unhealtyChecks.length > 0) {
      return <FmdBadIcon color="error"/>
    }
    return <CheckCircleIcon color='success' />;
  }, [home.event_summary?.failures, home.health_summary?.health_checks])

  return (
    <Tooltip title='Home Health' placement="top" arrow>
      <IconButton onClick={() => navigate(`/homes/${U(home.property_id)}/health`)}>
        {statusIcon}
      </IconButton>
    </Tooltip>
  );
}

export default HomeStatusColumn;