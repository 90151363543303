import { createSlice } from "@reduxjs/toolkit";
import { WeatherCacheEntry } from "./types";


const initialState: WeatherCacheEntry = {};

export const weatherReducer = createSlice({
  name: 'weather',
  initialState,
  reducers: {
    setCurrentWeather: (state: any, action: any) => {
      if (action.payload) {
        state.current = action.payload;
        state.updated = Date.now();
      }
    },
    setForecast: (state: any, action: any) => {
      if (action.payload) {
        state.forecast = action.payload;
        state.updated = Date.now();
      }
    }
  }
});

export default weatherReducer.reducer;

export const {
  setCurrentWeather,
  setForecast,
} = weatherReducer.actions;