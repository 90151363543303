import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import ExpandableSnack from '@features/Common/ExpandableSnack';
import { useGetPropertyManifestQuery } from '@features/home/api';
import { ApiErrorResponse, SnackType } from '@lib/types';
import { getErrorMessage } from '@lib/utils';
import { LoadingButton } from '@mui/lab';
import { ButtonGroup, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { RIOT_BLUE } from "theme";
import { useCreateManifestEntryMutation } from '../api';
import { PropertyManifestEntry, PropertyManifestEntryCreate, PropertyManifestEntryUpdate } from '../types';
import PropertyManifestEntryFields from "./PropertyManifestEntryFields";

const CreatePropertyManifestEntry = () => {
  const { enqueueSnackbar } = useSnackbar();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const home = useAppSelector((state: RootState) => state.property);

  const manifestEntries = useMemo(() => home.manifestEntries || [], [home.manifestEntries]);

  const currentProvisionOrder = useMemo(() => manifestEntries
    .reduce((max, a) => max > Number(a.provision_order) || 0 ? max : Number(a.provision_order) || 0,
      Number(manifestEntries?.[0]?.provision_order) || 0), [manifestEntries]);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant?.builder_color || RIOT_BLUE, [currentTenant?.builder_color]);

  const [currentEntry, setCurrentEntry] = useState<PropertyManifestEntryUpdate>({
    provision_order: currentProvisionOrder + 1,
  } as PropertyManifestEntryUpdate);
  const [adding, setAdding] = useState<boolean>(false);

  const handleChange = useCallback((entry: PropertyManifestEntryUpdate) => {
    setCurrentEntry({
      ...currentEntry,
      ...entry,
    });
  }, [currentEntry]);

  const [
    createManifestEntry,
  ] = useCreateManifestEntryMutation();

  const {
    refetch: refetchManifest,
  } = useGetPropertyManifestQuery({
    tenantId: currentTenant?.tenant_id || '',
    propertyId: home?.property_id || '',
    includeEntries: true,
  })

  const handleCreateManifestEntry = useCallback(async () => {
    setAdding(true);

    const newEntry = {
      ...currentEntry,
    } as PropertyManifestEntryCreate;

    if (!newEntry) setAdding(false);

    const result = await createManifestEntry({
      userTenantId: currentTenant?.tenant_id || '',
      propertyId: home?.property_id || '',
      newEntry,
    });

    const errorDetails = (result as ApiErrorResponse)?.error;
    if (errorDetails) {
      enqueueSnackbar("Couldn't add device:", {
        key: "create-manifest-entry-error",
        content: (
          <ExpandableSnack
            id="create-manifest-entry-error"
            message={"Couldn't add device:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      refetchManifest();
      setCurrentEntry({
        provision_order: currentProvisionOrder + 1,
      } as PropertyManifestEntryUpdate);
      const area = ((result as any).data as PropertyManifestEntry).property_area;
      enqueueSnackbar(`Successfully added new device to ${area}`, {
        variant: "success",
      });
      setAdding(false);
    }
  }, [createManifestEntry, currentEntry, currentProvisionOrder, currentTenant?.tenant_id, enqueueSnackbar, home?.property_id, refetchManifest]);

  const valid = useMemo(() => {
    const newEntry = {
      ...currentEntry,
    } as PropertyManifestEntryCreate;

    if (!newEntry.provision_order || !newEntry.property_area || !newEntry.device) return false;
    return true;
  }, [currentEntry]);

  return (
    <Stack spacing={1} alignItems="flex-end">
      <Stack
        spacing={1}
        sx={{
          border: `1px solid ${themeColor}`,
          width: '100%',
        }}
      >
        <PropertyManifestEntryFields
          currentEntry={currentEntry}
          onChange={handleChange}
        />
      </Stack>
      <ButtonGroup>
        <LoadingButton
          variant='contained'
          color="success"
          loading={adding}
          disabled={!valid || adding}
          onClick={handleCreateManifestEntry}
        >
          Add device
        </LoadingButton>
      </ButtonGroup>
    </Stack>
);
}

export default CreatePropertyManifestEntry;
