import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import Header from "@features/Common/Header";
import BuilderBranding from '@features/builder-branding/components/BuilderBranding';
import ChartsSection from "@features/chart-settings/components/ChartsSection";
import OemOnboarding from '@features/oem-onboarding/components/OemOnboarding';
import UnitSection from '@features/unit-settings/components/UnitSection';
import ViewsSection from '@features/view-settings/components/ViewsSection';
import { Box, Stack, Typography } from "@mui/material";
import { useMemo } from 'react';


const Settings = () => {
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const access = useMemo(() => tenant.access, [tenant.access]);

  return (
    <div id="settings">
      <Header />
      <Box
        component="div"
        sx={{
          margin: '1em',
          p: '2em',
        }}
      >
        <Typography variant="h3">Settings</Typography>
        <Typography variant="h4">{currentTenant?.name || ''}</Typography>
        <Stack spacing={2} sx={{ mt: 1 }}>
          <Stack direction="row" justifyContent="space-evenly" alignItems="start">
            <UnitSection />
            <ViewsSection />
          </Stack>

          <ChartsSection />
          {
            access.includes('builder-admins') &&
            <BuilderBranding />
          }
          {
            access.includes('riot-admins') &&
            <OemOnboarding />
          }
        </Stack>
      </Box>
    </div>
  );
};

export default Settings;
