import { AttentionLevel } from "@features/home-notifications/types";
import { HealthCheckResult } from "./types";

export const getHealthCheckSentiment = (healthCheckResult: HealthCheckResult|null) => {
  if (healthCheckResult === null) return AttentionLevel.info;
  if (healthCheckResult?.unhealthy && !healthCheckResult?.unavailable) return AttentionLevel.urgent;
  // temporary front-end warning that there is not enough data
  if (
    healthCheckResult?.unavailable === null
    || healthCheckResult?.unavailable
  ) return AttentionLevel.warning;
  if (healthCheckResult?.unavailable) return AttentionLevel.warning;
  return undefined;
}