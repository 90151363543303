import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import HomesProvider from "@features/all-homes/components/HomesProvider";
import { useUpdateUtilGroupHomesMutation, useUtilgroupQuery } from "@features/all-utility-groups/api";
import { UtilGroup, UtilGroupPropertyUpdate } from "@features/all-utility-groups/types";
import ExpandableSnack from '@features/Common/ExpandableSnack';
import { ApiErrorResponse, SnackType } from '@lib/types';
import { getErrorMessage } from '@lib/utils';
import { LoadingButton } from "@mui/lab";
import { Button, Card, CardActions, CardContent, CardHeader } from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import UtilityGroupFields from "./UtilityGroupFields";
import UtilityGroupSummary from "./UtilityGroupSummary";

const UtilityGroupEditor = () => {
  const { enqueueSnackbar } = useSnackbar();

  const utilGroup = useAppSelector((state: RootState) => state.utilityGroup.utilityGroup as UtilGroup);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const [editing, setEditing] = useState<boolean>(false);

  const { refetch } = useUtilgroupQuery({
    userTenantId: currentTenant?.tenant_id || '',
    groupId: utilGroup?.group_id || '',
    builderId: currentTenant?.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id || !utilGroup?.group_id,
  });

  const [ updateUtilGroupHomes ] = useUpdateUtilGroupHomesMutation();

  const handleEditMode = useCallback(() => setEditing(!editing), [editing]);

  const handleUpdateDetails = useCallback(async (ug: UtilGroup, helpers: FormikHelpers<UtilGroup>) => {
    const currentHomes = utilGroup.property_ids;

    const toAdd = ug.property_ids?.filter(h => !currentHomes?.includes(h));
    const toRemove = currentHomes?.filter(h => !ug.property_ids?.includes(h));

    const updatedUtilGroup = await updateUtilGroupHomes({
      userTenantId: currentTenant?.tenant_id || '',
      groupId: utilGroup?.group_id || '',
      builderId: currentTenant?.tenant_id || '',
      updates: {
        add_properties: toAdd?.length ? toAdd : undefined,
        remove_properties: toRemove?.length ? toRemove : undefined,
      } as UtilGroupPropertyUpdate,
    });

    const errorDetails = (updatedUtilGroup as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't update utility group:", {
        key: "utilgroup-error",
        content: (
          <ExpandableSnack
            id="utilgroup-error"
            message={"Couldn't update utility group:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("Updated utility group", {
        variant: "success",
      });

      refetch();
      setEditing(false);
    }

    helpers.setSubmitting(false);
  }, [currentTenant?.tenant_id, enqueueSnackbar, refetch, updateUtilGroupHomes, utilGroup?.group_id, utilGroup.property_ids]);


  return (
    <Formik initialValues={utilGroup} onSubmit={handleUpdateDetails}>
      {
        f => (
        <Card
          sx={{
            minWidth: 400,
            padding: 1,
            height: 370,
          }}
        >
          <CardHeader title="Utility Group Details" />
          <CardContent sx={{
            height: 250,
            overflowY: 'auto',
          }}>
            <HomesProvider>
            {
              !editing
                ? <UtilityGroupSummary />
                : <UtilityGroupFields />
            }
            </HomesProvider>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
          {
            editing &&
            <LoadingButton
              loading={f.isSubmitting}
              onClick={() => f.submitForm()}
              disabled={!f.dirty || !f.isValid || f.isSubmitting}
              variant="contained"
              color="primary"
            >
              Save
            </LoadingButton>
          }
            <Button
              size="small"
              variant={editing ? 'outlined' : 'contained'}
              onClick={handleEditMode}
              color={editing ? 'inherit' : 'primary'}
            >
              {editing ? 'Cancel' : 'Edit'}
            </Button>
          </CardActions>
        </Card>)
      }
    </Formik>

  );
}

export default UtilityGroupEditor;