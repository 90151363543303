import { riotApi } from "@features/aws-user/api";
import { HTTPMethodType } from "@lib/types";
import { U } from "@lib/utils";
import { DeviceAction, UserNotification } from './types';

/** API slice for Home Notification-related endpoint */
export const notificationsApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      /** Get User Notifications for given property */
      notifications: builder.query<UserNotification[], {
        userTenantId: string,
        propertyId: string,
        includeUserActions?: boolean,
        username?: string,
      }>({
        query: ({ userTenantId, propertyId, includeUserActions, username }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/notifications`,
          params: {
            username: username ? U(username) : undefined,
            include_user_actions: !!includeUserActions,
          }
        }),
      }),
      /** Get User Notifications for given neighborhood */
      neighborhoodNotifications: builder.query<UserNotification[], {
        userTenantId: string,
        neighborhoodId: string,
        includeUserActions?: boolean,
        username?: string,
      }>({
        query: ({ userTenantId, neighborhoodId, includeUserActions, username }) => ({
          url: `tenant/${U(userTenantId)}/neighborhoods/${U(neighborhoodId)}/notifications`,
          params: {
            username: username ? U(username) : undefined,
            include_user_actions: !!includeUserActions,
          }
        }),
      }),
      /** read a notification */
      read: builder.mutation<string, {
        userTenantId: string,
        userNotificationId: string,
      }>({
        query: ({ userTenantId,  userNotificationId }) => ({
          url: `tenant/${U(userTenantId)}/notifications/${U(userNotificationId)}/read`,
          method: HTTPMethodType.PATCH,
        }),
      }),
      /** unread a notification */
      unread: builder.mutation<string, {
        userTenantId: string,
        userNotificationId: string,
      }>({
        query: ({ userTenantId, userNotificationId }) => ({
          url: `tenant/${U(userTenantId)}/notifications/${U(userNotificationId)}/unread`,
          method: HTTPMethodType.PATCH,
        }),
      }),
      /** dismiss a notification */
      dismiss: builder.mutation<string, {
        userTenantId: string,
        userNotificationId: string,
      }>({
        query: ({ userTenantId, userNotificationId }) => ({
          url: `tenant/${U(userTenantId)}/notifications/${U(userNotificationId)}/dismiss`,
          method: HTTPMethodType.PATCH,
        }),
      }),
      /** dismiss all notifications */
      dismissAll: builder.mutation<string, {
        userTenantId: string,
        propertyId: string,
      }>({
        query: ({ userTenantId, propertyId }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/notifications/dismiss`,
          method: HTTPMethodType.PATCH,
        }),
      }),
      /** perform user action */
      userAction: builder.mutation<boolean, {
        userTenantId: string,
        propertyId: string,
        eventId: string,
        action: DeviceAction,
      }>({
        query: ({ userTenantId, propertyId, eventId, action }) => ({
          url: `/tenant/${U(userTenantId)}/properties/${U(propertyId)}/notifications/${U(eventId)}/action`,
          method: HTTPMethodType.PATCH,
          body: action,
        }),
      }),
      /** snooze notification */
      snooze: builder.mutation<boolean, {
        userTenantId: string,
        userNotificationId: string,
        snoozeHours: number,
      }>({
        query: ({ userTenantId, userNotificationId, snoozeHours }) => ({
          url: `/tenant/${U(userTenantId)}/notifications/${U(userNotificationId)}/snooze`,
          method: HTTPMethodType.PATCH,
          body: {
            snooze_hours: snoozeHours,
          },
        }),
      }),
    };
  }
});

export const {
  useNotificationsQuery,
  useNeighborhoodNotificationsQuery,
  useUserActionMutation,
  useReadMutation,
  useUnreadMutation,
  useDismissMutation,
  useDismissAllMutation,
  useSnoozeMutation,
} = notificationsApi;