import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { Interval, RawAverageDataRow } from '@features/home-analytics/types';
import { getUnitsByCategoryMeasure } from '@features/unit-settings/utils';
import { Category, findUnitsLabel, formatCategoryMeasurePair, formatWaterUsage } from "@lib/labels";
import { CircularProgress, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { useMemo } from "react";
import { useHomeUsageQuery } from "../api";
import { USAGE_TOTAL_MEASURE, WATER_USAGE_CATEGORY } from '../types';
import { findIntervalLabel, formatBucket, formatValue } from '../utils';


type Props = {
  interval: Interval,
  category: Category,
}

const IntervalHighlights = ({ interval, category }: Props) => {
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const home = useAppSelector((state: RootState) => state.property);
  const userPreferences = useAppSelector((state: RootState) => state.preferences);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const preferences = useMemo(() => userPreferences.preferences, [userPreferences.preferences]);
  const unitPreferences = useMemo(() => preferences.units || {}, [preferences.units]);
  const preferredUnit = useMemo(() => (
    unitPreferences?.[formatCategoryMeasurePair(category, USAGE_TOTAL_MEASURE)]
    || getUnitsByCategoryMeasure(category, USAGE_TOTAL_MEASURE)[0]
  ), [category, unitPreferences]);

  const {
    currentData: rawData,
  } = useHomeUsageQuery({
    userTenantId: currentTenant?.tenant_id || '',
    propertyId: home?.property_id || '',
    category,
    interval,
  }, {
    skip: !currentTenant?.tenant_id || !home.property_id,
  });

  const highlights = useMemo(() => {
    if (!rawData?.length) return {};

    const total = rawData
      ?.reduce((acc: number, dd: RawAverageDataRow) => acc + Number(dd.value), 0);

    const min = rawData
      ?.reduce((
        acc: { value: number, buckets: string[]},
        dd: RawAverageDataRow,
      ) => {
        const value = Number(dd.value);
        if (!acc.value || value < acc.value) {
          return {
            value,
            buckets: [dd.bucket],
          };
        }
        if (value === acc.value) {
          return {
            value,
            buckets: [
              ...acc.buckets,
              dd.bucket,
            ],
          };
        }
        return acc;
      }, { value: 0, buckets: []});

    const max = rawData
      ?.reduce((
        acc: { value: number, buckets: string[]},
        dd: RawAverageDataRow,
      ) => {
        const value = Number(dd.value);
        if (!acc.value || value > acc.value) {
          return {
            value,
            buckets: [dd.bucket],
          };
        }
        if (value === acc.value) {
          return {
            value,
            buckets: [
              ...acc.buckets,
              dd.bucket,
            ],
          };
        }
        return acc;
      }, { value: 0, buckets: []});

      return {
        min,
        max,
        total,
      };
  }, [rawData]);

  return (
    <Grid container item xs={9} columns={9}>
      <Grid item xs={3}>{findIntervalLabel(interval)}</Grid>
      <Grid item xs={2} textAlign="end">
        {
          highlights === undefined
            ? <CircularProgress size={16} />
            : (
              <Tooltip
                placement="top-end"
                arrow
                title={
                  <Stack>
                    {
                      highlights.max?.buckets.map((bb: string) => (
                        <Typography variant="caption" key={`max-bb`}>{formatBucket(bb, interval)}</Typography>
                      ))
                    }
                  </Stack>
                }
              >
                <Typography>
                  {
                    category === WATER_USAGE_CATEGORY
                    ? formatWaterUsage(highlights.max?.value, preferredUnit)
                    : [
                      formatValue(highlights.max?.value),
                      findUnitsLabel(preferredUnit),
                    ].join('')
                  }
                </Typography>
              </Tooltip>)
        }
      </Grid>
      <Grid item xs={2} textAlign="end">
        {
          highlights === undefined
            ? <CircularProgress size={16} />
            : (
              <Tooltip
                placement="top-end"
                arrow
                title={
                  <Stack>
                    {
                      highlights.min?.buckets.map((bb: string) => (
                        <Typography variant="caption" key={`max-bb`}>{formatBucket(bb, interval)}</Typography>
                      ))
                    }
                  </Stack>
                }
              >
                <Typography>
                {
                    category === WATER_USAGE_CATEGORY
                    ? formatWaterUsage(highlights.min?.value, preferredUnit)
                    : [
                      formatValue(highlights.min?.value),
                      findUnitsLabel(preferredUnit),
                    ].join('')
                  }
                </Typography>
              </Tooltip>)
        }
      </Grid>
      <Grid item xs={2} textAlign="end">
        <Typography>
        {
          category === WATER_USAGE_CATEGORY
          ? formatWaterUsage(highlights.total, preferredUnit)
          : [
            formatValue(highlights.total),
            findUnitsLabel(preferredUnit),
          ].join('')
        }
        </Typography>
      </Grid>
    </Grid>
  );
}

export default IntervalHighlights;