import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { Vector3 } from "three";
import { useCreatePlanManifestEntryMutation, useGetPlanManifestsQuery } from "../api";
import { Coordinates, ManifestEntryCreate, PlanManifest } from "../types";
import ManifestEntryEditor from "./ManifestEntryEditor";


type Props = {
  coordinates: Vector3[];
  onCancel: () => void;
  onSubmit: () => void;
  manifest: PlanManifest;
}

const ImportModal = ({ coordinates, onSubmit, onCancel, manifest }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const [importing, setImporting] = useState<boolean>(false);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const [entries, setEntries] = useState<Partial<ManifestEntryCreate>[]>(
    coordinates.map((cc, idx) => ({
      provision_order: idx.toString(),
      manifest_id: manifest.manifest_id,
      property_area: '',
      coordinates: {
        x_axis: cc.x.toFixed(3),
        y_axis: cc.y.toFixed(3),
        z_axis: cc.z.toFixed(3),
      } as Coordinates,
    } as Partial<ManifestEntryCreate>)));

  const [createManifestEntry] = useCreatePlanManifestEntryMutation();

  const {
    refetch,
  } = useGetPlanManifestsQuery({
    userTenantId: currentTenant.tenant_id!,
    planId: manifest.plan_id || '',
    includeEntries: true,
  });

  const handleChangeEntry = useCallback((entry: Partial<ManifestEntryCreate>) => {
    const updatedEntry = entries.find(e => e.provision_order === entry.provision_order);
    setEntries([
      ...entries.filter(e => e.provision_order !== entry.provision_order),
      {
        ...updatedEntry,
        ...entry,
      }
    ].sort((a, b) => Number(a.provision_order) - Number(b.provision_order)));
  }, [entries]);

  const ready = useMemo(() => entries
    .every(e => !!e.device && !!e.property_area)
  , [entries]);

  const handleImport = useCallback(async () => {
    setImporting(true);
    const promises = entries.map(ee => createManifestEntry({
      userTenantId: currentTenant?.tenant_id || '',
      manifestId: manifest?.manifest_id || '',
      body: ee,
    }));

    const results = await Promise.all(promises);

    const errorArray = results.map(r => (r as ApiErrorResponse).error).filter(e => !!e);
    setImporting(false);

    if (errorArray.length) {
      enqueueSnackbar(`Couldn't import ${errorArray.length} entries`, {
        key: "import-error",
        content: (
          <ExpandableSnack
            id="import-error"
            message={"Couldn't delete entry:"}
            variant={SnackType.error}
            detail={errorArray.map(e => getErrorMessage(e)).join('\n')}
          />),
      });
    } else {
      refetch()
      onSubmit();
    }

  }, [createManifestEntry, currentTenant?.tenant_id, enqueueSnackbar, entries, manifest?.manifest_id, onSubmit, refetch]);


  const handleClose = useCallback((event: object, reason: string) => {
    if (reason === 'backdropClick') return;
    onCancel();
  }, [onCancel]);


  return (
    <Dialog open scroll="paper" fullWidth onClose={handleClose}>
      <DialogTitle>
        Import Devices
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ height: '40em' }}>
        {
          importing
            ? <CircularProgress />
            : <Stack spacing={1}>
              {
                entries.map((ee, idx) => (
                  <ManifestEntryEditor
                    key={`entry-editor- ${idx}`}
                    entry={ee}
                    onChange={handleChangeEntry}
                  />))
              }
              </Stack>
        }

      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={!ready}
          loading={importing}
          onClick={handleImport}
        >
          Import
        </LoadingButton>
      </DialogActions>
    </Dialog>);
}

export default ImportModal