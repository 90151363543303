import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { usePrefs } from "@features/settings/slice";
import { Preferences, UnitPreferences, UnitSelection } from "@features/settings/types";
import { Category, Unit, findUnitsLabel, formatCategoryMeasurePair, getMeasuresByCategory } from "@lib/labels";
import { Card, CardContent, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useCallback, useMemo } from 'react';
import { getUnitsByCategoryMeasure } from "../utils";


const UnitSection = () => {
  const prefProvider = usePrefs();

  const preferenceState = useAppSelector((state: RootState) => state.preferences);

  const preferences = useMemo(() => preferenceState.preferences, [preferenceState.preferences]);
  const unitPreferences = useMemo(() => preferences.units || {}, [preferences.units]);

  const allMappedUnits = useMemo(() => Object.values(Category)
    .reduce((acc: UnitSelection, cat: Category) => ({
      ...acc,
      ...getMeasuresByCategory(cat)
        .reduce((cache: UnitSelection, measure) => ({
          ...cache,
          [formatCategoryMeasurePair(cat, measure)]: getUnitsByCategoryMeasure(cat, measure),
        } as UnitSelection), {} as UnitSelection),
    }), {} as UnitSelection)
  , []);

  const configurables = useMemo(() => Object.keys(allMappedUnits)
    .reduce((acc: UnitSelection, catMeasure: string) => {
      if (allMappedUnits[catMeasure].length > 1) {
        acc[catMeasure] = allMappedUnits[catMeasure];
      }
      return acc;
    }, {} as UnitSelection)
  , [allMappedUnits]);

  const handleChangeUnitPreferences = useCallback((measure: string, value: Unit) => {
    prefProvider.setPrefs({
      ...preferences,
      units: {
        ...preferences.units,
        [measure]: value,
      } as UnitPreferences,
    } as Preferences, () => prefProvider.getPrefs(() => {}))
  }, [prefProvider, preferences]);

  return (
    <Stack spacing={2}>
      <Typography variant="h4" textAlign="center">Unit Preferences</Typography>
      <Stack
        direction="row"
        justifyContent="space-evenly"
      >
        <Card sx={{
          width: '40em',
        }}>
          <CardContent>
            <Stack spacing={1}>
              {
                Object.keys(configurables)
                  .map((catMeasure: string) => (
                    <Stack direction="row" justifyContent="space-between" key={`unit-preference-${catMeasure}`}>
                      <Typography variant="h6">{catMeasure}</Typography>
                      <ToggleButtonGroup
                        color="primary"
                        exclusive
                        value={unitPreferences?.[catMeasure] || configurables?.[catMeasure]?.[0] || Unit.binary}
                        onChange={(e, value) => handleChangeUnitPreferences(catMeasure, value)}
                      >
                        {
                          configurables?.[catMeasure]
                            .map(u => (
                              <ToggleButton value={u} sx={{ width: '5em' }} key={`unit-toggle-${catMeasure}-${u}`}>
                                {findUnitsLabel(u)}
                              </ToggleButton>
                            ))
                        }
                      </ToggleButtonGroup>
                    </Stack>
                  ))
              }
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  );
}

export default UnitSection;