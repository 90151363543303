export const TYPICAL_RESIDENTIAL_VOLTAGE = 240;
export const TYPICAL_MAX_SERVICE_POWER = 200;

export class ServicePower {
  watts?: number;
  amps?: number = TYPICAL_MAX_SERVICE_POWER;
  volts?: number = TYPICAL_RESIDENTIAL_VOLTAGE;
}

interface EnergyManagementBase {
  svc_max_power?: ServicePower;
  temp_max_power?: ServicePower;
  solar_gen_capacity?: number;
  generator_capacity?: number;
  battery_capacity?: number;
}

export interface EnergyManagement extends EnergyManagementBase {
  svc_max_power: ServicePower;
}

interface UtilityManagementBase {
  energy_management?: EnergyManagement;
}

export interface UtilityManagement extends UtilityManagementBase {
  energy_management: EnergyManagement;
}

interface EnergyUsageBase {
  grid_consumption?: ServicePower;
}

export interface EnergyUsage extends EnergyUsageBase {
  grid_consumption: ServicePower;
}

interface WaterUsageBase {}

export interface WaterUsage extends WaterUsageBase {}

export type UtilityUsage = EnergyUsage | WaterUsage;

interface UtilGroupBase {
  builder_id?: string;
  property_ids?: string[];
  utility_realtime?: UtilityUsage[];
  utility_targets?: UtilityUsage[];
  utility_available?: UtilityUsage[];
  utility_management?: UtilityManagement;
}

export interface UtilGroupCreate extends UtilGroupBase {
  builder_id: string;
  property_ids: string[];
}

export interface UtilGroupPropertyUpdate {
  add_properties?: string[];
  remove_properties?: string[];
}

export interface UtilGroup extends UtilGroupCreate {
  group_id: string;
  created: number;
  updated: number;
  is_deleted: boolean;
}