import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { useMyTenantsQuery } from "@features/userTenant/api";
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Card, CardContent, CardHeader, IconButton, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { RIOT_BLUE } from "theme";
import { useUpdateColorMutation } from '../api';
import ColorPicker from "./ColorPicker";


const AccentColor = () => {
  const { enqueueSnackbar } = useSnackbar();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [color, setColor] = useState<string>(currentTenant.builder_color || RIOT_BLUE);

  const {
    refetch,
  } = useMyTenantsQuery();

  const [
    updateColor,
  ] = useUpdateColorMutation();

  const handleEdit = useCallback(() => setIsEditing(true), []);

  const handleChangeColor = useCallback(async () => {
    const updatedBuilder = await updateColor({
      userTenantId: currentTenant?.tenant_id,
      color,
    });

    const errorDetails = (updatedBuilder as ApiErrorResponse)?.error;
    if (errorDetails) {
      enqueueSnackbar("Couldn't update color:", {
        key: "color-error",
        content: (
          <ExpandableSnack
            id="color-error"
            message={"Couldn't update color:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });

      return null;
    } else {
      enqueueSnackbar("Updated color", {
        variant: "success",
      });
      refetch();
      return (updatedBuilder as any).data;
    }
  }, [color, currentTenant?.tenant_id, enqueueSnackbar, refetch, updateColor]);

  const handleSelectedColor = useCallback((c: string) => setColor(c), []);

  const handleCancel = useCallback(() => setIsEditing(false), []);

  return (
    <Card sx={{
      width: '40em',
      height: '11em',
    }}>
      <CardHeader title="Accent Color" />
      <CardContent>
        <Stack direction="row" justifyContent="space-between">
          <ColorPicker
            active={isEditing}
            onColorChange={handleSelectedColor}
            placeholder="#"
            name="color-picker"
            label="Select Color"
          />
          <Stack direction="row">
          {
            !isEditing &&
            <Button onClick={handleEdit}>Change</Button>
          }
          {
            isEditing && <>
              <IconButton onClick={handleCancel}><CancelIcon /></IconButton>
              <IconButton onClick={handleChangeColor}><CheckCircleIcon /></IconButton>
            </>
          }
          </Stack>

        </Stack>
      </CardContent>
    </Card>
  );
}

export default AccentColor;