import { riotApi } from "@features/aws-user/api";
import { PropertyManifest } from "@features/home-manifest/types";
import { Property } from "@features/home/types";
import { HTTPMethodType } from "@lib/types";
import { U } from "@lib/utils";

export const provisioningRequestApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      /** Create Property */
      createProperty: builder.mutation<Property | null, {
        userTenantId: string,
        body: Partial<Property>
      }>({
        query: ({ userTenantId, body }) => ({
          url: `tenant/${U(userTenantId)}/properties`,
          method: HTTPMethodType.POST,
          body,
        })
      }),
      /** Create Property Manifest */
      createHomeManifest: builder.mutation<PropertyManifest, {
        userTenantId: string,
        propertyId: string,
        templateId: string,
      }>({
        query: ({ userTenantId, propertyId, templateId }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/manifest/${U(templateId)}`,
          method: HTTPMethodType.POST,
        }),
      }),

    }
  }
});

export const {
  useCreateHomeManifestMutation,
  useCreatePropertyMutation,
} = provisioningRequestApi;
