import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { EnergyManagement, ServicePower, TYPICAL_MAX_SERVICE_POWER, TYPICAL_RESIDENTIAL_VOLTAGE, UtilityManagement } from "@features/all-utility-groups/types";
import { Plan } from "@features/floorplan/types";
import { PropertyManifest } from "@features/home-manifest/types";
import HomeFields from "@features/home/components/HomeFields";
import { Address, DEFAULT_ADDRESS, Property } from "@features/home/types";
import { useGetPlanManifestsQuery } from "@features/plan-manifest/api";
import { ManifestEntry } from "@features/plan-manifest/types";
import Floorplans from "@features/provisioning-request/components/plan-manifest/Floorplans";
import { useTenantPropertiesQuery } from "@features/tenant-selector/api";
import { SelectedTenant } from "@features/tenant-selector/types";
import { TenantState } from "@features/userTenant/types";
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, CircularProgress, Stack, Tab, Typography } from "@mui/material";
import { Formik, FormikProps } from "formik";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateHomeManifestMutation, useCreatePropertyMutation } from "../api";
import { ProvisioningRequestForm } from "../types";
import OrderConfirmation from "./OrderConfirmation";
import OrderReview from "./OrderReview";
import ShippingDetails from "./ShippingDetails";
import PlanManifest from "./plan-manifest/PlanManifest";


const initialFormValues = {
  homeDetails: {
    region_id: '',
    property_id: '',
    name: '',
    latitude: '',
    longitude: '',
    plan_id: '',
  } as Property,
  manifestId: '',
  plan: {
    plan_id: '',
    name: '',
    description: '',
  } as Plan,
  manifestEntries: [],
  shippingAddress: {
    ...DEFAULT_ADDRESS,
  } as Address,
  physicalAddress: {
    ...DEFAULT_ADDRESS,
    legal_address: {
      lot: '',
      block: '',
      section: '',
      subdivision: '',
      community: '',
    },
  } as Address,
  manifest: {
    plan_manifest_id: '',
    name: '',
    description: ''
  } as PropertyManifest,
  utility_management: {
    energy_management: {
      svc_max_power: {
        amps: TYPICAL_MAX_SERVICE_POWER,
        volts: TYPICAL_RESIDENTIAL_VOLTAGE,
      } as ServicePower,
    } as EnergyManagement,
  } as UtilityManagement,
}

const ProvisioningRequest = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const userTenant: TenantState = useAppSelector((state: RootState) => state.userTenant);
  const tenant: SelectedTenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const [activeStep, setActiveStep] = useState<string>("Home Location");
  const [physicalAddress, setPhysicalAddress] = useState<Address>(initialFormValues.physicalAddress);
  const [propertyDetails, setPropertyDetails] = useState<Partial<Property>>(initialFormValues.homeDetails);
  const [plan, setPlan] = useState<Plan>(initialFormValues.plan);
  const [utilityManagement, setUtilityManagement] = useState<UtilityManagement>(initialFormValues.utility_management)
  const [shippingAddress, setShippingAddress] = useState<Address>(initialFormValues.shippingAddress);
  const [manifestId, setManifestId] = useState<string>(initialFormValues.manifestId);
  const [manifestEntries, setManifestEntries] = useState<ManifestEntry[]>(initialFormValues.manifestEntries);
  const [homeDetailsSet, setHomeDetailsSet] = useState<Boolean>(false);
  const [floorplanSet, setFloorplanSet] = useState<Boolean>(false);
  const [devicesSet, setDevicesSet] = useState<Boolean>(false);
  const [shippingDetailsSet, setShippingDetailsSet] = useState<Boolean>(false);
  const [shipToHome, setShipToHome] = useState<Boolean>(false);

  const [
    createProperty,
  ] = useCreatePropertyMutation();

  const [
    createHomeManifest,
  ] = useCreateHomeManifestMutation();

  const {
    refetch: refetchHomes,
  } = useTenantPropertiesQuery({
    userTenantId: userTenant?.tenant_id || '',
    tenantId: currentTenant?.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id,
  });

  const {
    currentData: planManifests,
    isFetching: isFetchingManifests,
  } = useGetPlanManifestsQuery({
    userTenantId: currentTenant?.tenant_id || '',
    planId: plan?.plan_id || '',
    includeEntries: true,
  }, {
    skip: !currentTenant?.tenant_id || !plan?.plan_id,
  });

  /** Handles submission of Provisioning request */
  const onSubmit = useCallback(async (): Promise<any> => {

    const propertyBody: Partial<Property> = {
      plan_id: plan.plan_id,
      physical_address: {
        ...physicalAddress,
      } as Address,
      shipping_address: {
        ...shippingAddress,
      } as Address,
      region_id: propertyDetails.region_id,
      latitude: propertyDetails.latitude,
      longitude: propertyDetails.longitude,
      builder_id: currentTenant?.tenant_id || '',
      name: propertyDetails.name || '',
      utility_management: utilityManagement,
    }


    if (currentTenant?.tenant_id) {
      // Create property
      const newProperty = await createProperty({
        userTenantId: currentTenant?.tenant_id || '',
        body: propertyBody,
      });

      const errorDetails = (newProperty as ApiErrorResponse)?.error;

      if (errorDetails) {
        enqueueSnackbar("Couldn't create a new home:", {
          key: "home-error",
          content: (
            <ExpandableSnack
              id="home-error"
              message={"Couldn't create a new home:"}
              variant={SnackType.error}
              detail={getErrorMessage(errorDetails)}
            />),
        });
      } else {
        enqueueSnackbar("Created a new home", {
          variant: "success",
        });

        const home = (newProperty as any).data;

        // create property manifest
        if (manifestId) {
          const propertyManifest = await createHomeManifest({
            userTenantId: currentTenant?.tenant_id || '',
            propertyId: home?.property_id || '',
            templateId: manifestId,
          });

          const errorDetails = (propertyManifest as ApiErrorResponse)?.error;

          if (errorDetails) {
            enqueueSnackbar("Couldn't create home manifest:", {
              key: "homemanifest-error",
              content: (
                <ExpandableSnack
                  id="homemanifest-error"
                  message={"Couldn't create home manifest:"}
                  variant={SnackType.error}
                  detail={getErrorMessage(errorDetails)}
                />),
            });

          } else {
            enqueueSnackbar("Created home manifest", {
              variant: "success",
            });

            // TODO: make changes to manifest entries if necessary

            enqueueSnackbar("Provisioning request submitted:", {
              key: "provisioningrequest-error",
              content: (
                <ExpandableSnack
                  id="provisioningrequest-error"
                  message={"Provisioning request submitted:"}
                  variant={SnackType.success}
                  detail={<OrderConfirmation homeName={home.name || home.physical_address.address_line_1} />}
                />),
            });
            // redirect to /homes on success
            refetchHomes();
            navigate('/homes');
          }
        } else {
          enqueueSnackbar("Plan manifest is not selected", {
            variant: "error",
          });
        }
      }
    }

  }, [createHomeManifest, createProperty, currentTenant?.tenant_id, enqueueSnackbar, manifestId, navigate, physicalAddress, plan.plan_id, propertyDetails.latitude, propertyDetails.longitude, propertyDetails.name, propertyDetails.region_id, refetchHomes, shippingAddress, utilityManagement]);


  /** Redirects to /homes
  *  when cancel out of request
  */
  const onCancel = useCallback(async () => {
    navigate('/homes');
  }, [navigate]);

  useEffect(() => {
    if (activeStep === 'Cancel') onCancel();
  }, [activeStep, onCancel]);

  /**
 * Checks whether all required fields for property creation are populated
 */
  const homeDetailsValid = (values: Partial<ProvisioningRequestForm>) => {
    const { name, physical_address } = values;
    return (
      // unless all of these are populated, return false
      Boolean(name?.length)
      && Boolean(physical_address?.city?.length)
      && Boolean(physical_address?.state?.length)
      && Boolean(physical_address?.country?.length)
    );
  }

  /** Checks whether manifest was selected or not */
  const manifestValid = (values: Partial<ProvisioningRequestForm>) => {
    return Boolean(values.manifest_id);
  }

  /**
   * Checks whether all required fields for shipping details are populated
   */
  const shippingDetailsValid = (values: Partial<ProvisioningRequestForm>) => {
    const { shipping_address } = values;
    return (
      // unless all of these are populated, return false
      Boolean(shipping_address?.address_line_1?.length)
      && Boolean(shipping_address?.city?.length)
      && Boolean(shipping_address?.state?.length)
      && Boolean(shipping_address?.country?.length)
      && Boolean(shipping_address?.zip_postal_code?.length)
    );
  }

  const submitHomeDetails = (values: ProvisioningRequestForm) => {
    const {
      physical_address,
      region_id,
      latitude,
      longitude,
      name
    } = values;

    setPropertyDetails({
      latitude,
      longitude,
      name,
      region_id,
    });
    setPhysicalAddress(physical_address);
    setActiveStep("Floorplan");
    setHomeDetailsSet(true);
  }

  const submitPlanSelection = (values: ProvisioningRequestForm) => {
    setPlan(values.plan);
    setUtilityManagement(values.utility_management);
    setActiveStep("Devices");
    setFloorplanSet(true);
  }

  const submitDeviceSelection = (values: ProvisioningRequestForm) => {
    const {
      manifest_entries,
      manifest_id,
    } = values;

    setManifestEntries(manifest_entries);
    setManifestId(manifest_id);
    setActiveStep("Shipping Address");
    setDevicesSet(true);
  }

  const handleAutofillAddress = useCallback((f: FormikProps<ProvisioningRequestForm>) => {
    if (shipToHome) {
      f.setFieldValue('shipping_address.address_line_1', currentTenant?.physical_address?.address_line_1);
      f.setFieldValue('shipping_address.address_line_2', currentTenant?.physical_address?.address_line_2);
      f.setFieldValue('shipping_address.city', currentTenant?.physical_address?.city);
      f.setFieldValue('shipping_address.state', currentTenant?.physical_address?.state);
      f.setFieldValue('shipping_address.zip_postal_code', currentTenant?.physical_address?.zip_postal_code);
      setShipToHome(false);
    } else {
      f.setFieldValue('shipping_address.address_line_1', physicalAddress.address_line_1);
      f.setFieldValue('shipping_address.address_line_2', physicalAddress.address_line_2);
      f.setFieldValue('shipping_address.city', physicalAddress.city);
      f.setFieldValue('shipping_address.state', physicalAddress.state);
      f.setFieldValue('shipping_address.zip_postal_code', physicalAddress.zip_postal_code);
      setShipToHome(true);
    }

  }, [currentTenant?.physical_address?.address_line_1, currentTenant?.physical_address?.address_line_2, currentTenant?.physical_address?.city, currentTenant?.physical_address?.state, currentTenant?.physical_address?.zip_postal_code, physicalAddress.address_line_1, physicalAddress.address_line_2, physicalAddress.city, physicalAddress.state, physicalAddress.zip_postal_code, shipToHome]);

  const submitShippingAddress = (values: ProvisioningRequestForm) => {
    const { shipping_address } = values;
    setShippingAddress(shipping_address);
    setActiveStep("Summary");
    setShippingDetailsSet(true);
  }

  const orderValid = useCallback((values: Partial<ProvisioningRequestForm>) =>
    homeDetailsSet && floorplanSet && devicesSet && shippingDetailsSet, [devicesSet, floorplanSet, homeDetailsSet, shippingDetailsSet]);

  if (userTenant.checkingTenant === true) return <CircularProgress />;

  return (
    <Box component="div" sx={{ background: "white", height: '53em' }}>
      <Formik
        validateOnChange
        initialValues={{
          ...initialFormValues.homeDetails,
          manifest_entries: initialFormValues.manifestEntries,
          shipping_address: {
            ...initialFormValues.shippingAddress,
            ...userTenant.physical_address,
          },
          physical_address: initialFormValues.physicalAddress,
          plan: initialFormValues.plan,
          manifest_id: initialFormValues.manifestId,
          manifest: initialFormValues.manifest,
          utility_management: initialFormValues.utility_management,
        } as ProvisioningRequestForm}
        onSubmit={onSubmit}
      >
        {f => (
          <form noValidate onSubmit={f.handleSubmit}>
            <TabContext value={activeStep}>
              <Box component="div" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={(e, step) => setActiveStep(step)}>
                  <Tab icon={<CloseIcon />} value="Cancel" />
                  <Tab label="Home Location" value="Home Location" />
                  <Tab
                    label="Floorplan"
                    value="Floorplan"
                    disabled={!homeDetailsSet} />
                  <Tab
                    label="Devices"
                    value="Devices"
                    disabled={!homeDetailsSet || !floorplanSet}
                  />
                  <Tab
                    label="Shipping Address"
                    value="Shipping Address"
                  />
                  <Tab label="Summary" value="Summary" />
                </TabList>
              </Box>
              <TabPanel value="Home Location">
                <Stack direction="row">
                  <Stack alignItems="center" justifyItems="space-between">
                    <Typography variant="h5">Provide location of the new home</Typography>
                    <HomeFields />
                    <Button
                      variant="contained"
                      disabled={!homeDetailsValid(f.values)}
                      onClick={() => submitHomeDetails(f.values)}
                    >Next</Button>
                  </Stack>
                  <img
                    width="400"
                    height="400"
                    alt="location"
                    src="/provisioning-request/location.png"
                  />
                </Stack>
              </TabPanel>
              <TabPanel value="Floorplan">
                <Stack direction="row">
                  <Stack
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      width: '60%',
                      height: '42em'
                    }}
                  >
                    <Typography variant="h5">Select floorplan for this home</Typography>
                    <Floorplans />
                    <Button
                      variant="contained"
                      disabled={!Boolean(f.values.plan?.plan_id)}
                      onClick={() => submitPlanSelection(f.values)}
                    >Next</Button>
                  </Stack>
                  <img
                    width="400"
                    height="400"
                    alt="selected floorplan"
                    src="/provisioning-request/floorplan.jpg "
                  />
                </Stack>
              </TabPanel>
              <TabPanel value="Devices">
                <Stack direction="row">
                  <Stack
                    alignItems="center"
                    justifyItems="space-between"
                    sx={{
                      width: '60%',
                    }}
                  >
                    <Typography variant="h5">Select devices for this home</Typography>
                    {(isFetchingManifests && !planManifests?.length) ? <CircularProgress /> : <PlanManifest manifests={planManifests || []} />}
                    <Button
                      variant="contained"
                      disabled={!manifestValid(f.values)}
                      onClick={() => submitDeviceSelection(f.values)}
                    >Next</Button>
                  </Stack>
                  <img
                    width="400"
                    height="400"
                    alt="devices"
                    src="/provisioning-request/devices.png"
                  />
                </Stack>
              </TabPanel>
              <TabPanel value="Shipping Address">
                <Stack direction="row">
                  <Stack
                    alignItems="center"
                    justifyItems="space-between"
                    sx={{
                      width: '60%',
                    }}
                  >
                    <Typography variant="h5">Where should we ship the devices?</Typography>
                    <ShippingDetails />
                    <Stack direction="row" spacing={2}>
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={() => handleAutofillAddress(f)}
                      >Ship to {shipToHome ? 'my office' : 'new home'}</Button>
                      <Button
                        variant="contained"
                        disabled={!shippingDetailsValid(f.values)}
                        onClick={() => submitShippingAddress(f.values)}
                      >Next</Button>
                    </Stack>
                  </Stack>
                  <img
                    width="400"
                    height="400"
                    alt="selected floorplan"
                    src="/provisioning-request/shipping.png"
                  />
                </Stack>
              </TabPanel>
              <TabPanel value="Summary">
                <Stack direction="row">
                  <Stack
                    alignItems="center"
                    justifyItems="space-between"
                    sx={{
                      width: '60%',
                    }}
                  >
                    <Typography variant="h5">Review the order</Typography>
                    <OrderReview
                      formData={{
                        ...propertyDetails,
                        manifest_entries: manifestEntries,
                        shipping_address: shippingAddress,
                        physical_address: physicalAddress,
                        plan: plan,
                      }}
                    />
                    <LoadingButton
                      loading={f.isSubmitting}
                      type='submit'
                      variant="contained"
                      disabled={!orderValid(f.values)}
                    >Submit Order</LoadingButton>
                  </Stack>
                  <img
                    width="400"
                    height="400"
                    alt="selected floorplan"
                    src="/provisioning-request/shipping.png"
                  />
                </Stack>
              </TabPanel>
            </TabContext>
          </form>
        )}
      </Formik>
    </Box>
  );
}

export default ProvisioningRequest;