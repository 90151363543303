import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import AdminTable from "@features/Common/AdminTable";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { DEFAULT_ADDRESS } from "@features/home/types";
import { Tenant, TenantCreate, TenantType, TenantUpdate } from "@features/userTenant/types";
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { MUIDataTableColumn } from "mui-datatables";
import { useSnackbar } from "notistack";
import { useCallback, useMemo } from "react";
import HomeownerFields from "../../all-builders/components/BuilderFields";
import {
  useChildTenantsQuery, useCreateHomeownerMutation,
  useDeleteHomeownerMutation, useHomeownersQuery, useUpdateHomeownerMutation
} from "../api";


const columns: MUIDataTableColumn[] = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'email',
    label: 'Email',
  },
  {
    name: 'phone_number',
    label: 'Phone Number',
  },
  {
    name: 'website',
    label: 'Website',
  },
  {
    name: 'description',
    label: 'Description',
  },
];

const HomeownerList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const homeowners = useAppSelector((state: RootState) => state.homeowners);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const [
    createHomeowner
  ] = useCreateHomeownerMutation();

  const [
    updateHomeowner,
  ] = useUpdateHomeownerMutation();

  const [
    deleteHomeowner,
  ] = useDeleteHomeownerMutation();


  const {
    refetch: refetchHomeowners,
  } = useHomeownersQuery({
    userTenantId: currentTenant?.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id || currentTenant.tenant_type !== TenantType.System,
  });

  const {
    refetch: refetchChildren,
  } = useChildTenantsQuery({
    userTenantId: currentTenant?.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id || currentTenant.tenant_type === TenantType.System,
  });

  /** Handles Save button on New/Edit Homeowner dialog */
  const handleSaveHomeowner = useCallback(async (homeowner: Partial<Tenant>) => {
    /** New homeowner */
    if (!homeowner.tenant_id) {
      const newHomeowner = await createHomeowner({
        userTenantId: currentTenant?.tenant_id || '',
        body: {
          name: homeowner.name,
          description: homeowner.description,
          email: homeowner.email,
          phone_number: homeowner.phone_number,
          physical_address: homeowner.physical_address,
          website: homeowner.website,
          property_ids: [],
          tenant_type: TenantType.Homeowner,
        } as TenantCreate,
      });

      const errorDetails = (newHomeowner as ApiErrorResponse)?.error;

      if (errorDetails) {
        enqueueSnackbar("Couldn't create homeowner:", {
          key: "homeowner-error",
          content: (
            <ExpandableSnack
              id="homeowner-error"
              message={"Couldn't create homeowner:"}
              variant={SnackType.error}
              detail={getErrorMessage(errorDetails)}
            />),
        });
      } else {
        enqueueSnackbar("Created homeowner", {
          variant: "success",
        });
      }

      return (newHomeowner as any).data;
    }
    /** Existing homeowner */
    const updatedHomeowner = await updateHomeowner({
      userTenantId: currentTenant?.tenant_id || '',
      tenantId: homeowner.tenant_id,
      body: {
        name: homeowner.name,
        description: homeowner.description,
        phone_number: homeowner.phone_number,
        physical_address: homeowner.physical_address,
        website: homeowner.website,
      } as TenantUpdate,
    });

    const errorDetails = (updatedHomeowner as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't update homeowner:", {
        key: "homeowner-error",
        content: (
          <ExpandableSnack
            id="homeowner-error"
            message={"Couldn't update homeowner:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("Updated homeowner", {
        variant: "success",
      });
    }

    return updatedHomeowner;
  }, [createHomeowner, currentTenant?.tenant_id, enqueueSnackbar, updateHomeowner]);

  const handleDeleteHomeowner = useCallback(async (homeowner: Tenant) => {
    const deletedHomeowner = await deleteHomeowner({
      userTenantId: currentTenant?.tenant_id || '',
      tenantId: homeowner?.tenant_id || '',
    });

    if ('error' in deletedHomeowner) {
      enqueueSnackbar("Couldn't delete homeowner:", {
        key: "homeowner-delete-error",
        content: (
          <ExpandableSnack
            id="homeowner-delete-error"
            message={"Couldn't delete homeowner:"}
            variant={SnackType.error}
            detail={getErrorMessage(deletedHomeowner?.error)}
          />),
      });
      return false;
    } else {
      enqueueSnackbar("Deleted homeowner", {
        variant: "success",
      });
      return Boolean(deletedHomeowner.data);
    }
  }, [currentTenant?.tenant_id, deleteHomeowner, enqueueSnackbar]);

  const renderedHomeownersTable = useMemo(() => {
    if (userTenant.checkingTenant) return <Backdrop open><CircularProgress /></Backdrop>;
    return (
      <AdminTable<Tenant>
        title="Homeowners"
        label="Homeowner"
        id_key="tenant_id"
        name_key="name"
        columns={columns}
        data={homeowners}
        refresh={() => {
          refetchChildren();
          refetchHomeowners();
        }}
        makeNew={() => ({
          name: '',
          description: '',
          email: '',
          website: '',
          phone_number: '',
          physical_address: DEFAULT_ADDRESS,
        } as TenantCreate)}
        onSave={handleSaveHomeowner}
        onDelete={handleDeleteHomeowner}
        editPermission={'update-tenants'}
        deletePermission={'delete-tenants'}
      >
        <HomeownerFields />
      </AdminTable>
    );
  }, [handleDeleteHomeowner, handleSaveHomeowner, homeowners, refetchChildren, refetchHomeowners, userTenant.checkingTenant]);

  return (
    <Box
      component="div"
      sx={{
        margin: '1em',
        padding: '2em',
      }}
    >
      <Typography variant="h4">{currentTenant.name}</Typography>
      <Typography variant="h5">Homeowners</Typography>
      <Box
        component="div"
        sx={{
          mt: '3em',
        }}
      >
        {renderedHomeownersTable}
      </Box>
    </Box>
  );
}


export default HomeownerList;