import { UtilityManagement } from '@features/all-utility-groups/types';
import { HealthCheckResult, HealthCheckType } from '@features/home-health/types';
import { PropertyManifest, PropertyManifestEntry } from '@features/home-manifest/types';
import * as yup from 'yup';

type EntityBase = {
  readonly created: string | number;
  readonly updated: string | number;
}

type HasTags = {
  tags?: Record<string, any> | null;
}


export type Region = {
  readonly region_id: string;
  name: string;
  country: string;
  subregion: string;
} & HasTags;

export type LegalAddress = {
  lot: string;
  block: string;
  section?: string
  subdivision?: string;
  community?: string;
}

export type Address = {
  address_line_1: string;
  address_line_2?: string | null;
  city: string;
  country: string;
  county: string;
  state: string;
  zip_postal_code: string;
  legal_address?: LegalAddress;
}

export type Device = {
  id: string;
  datetime: number;
  event_type: string;
  data: {
    entity_id: string;
    device_id: string;
    value: string | number | boolean;
    value_updated: string | number;
  };
} & EntityBase;


/** Current Counts of Event Notifications for the home */
export type NotificationEventSummary = {
  urgent: number;
  warning: number;
  info: number;
  notice: number;
};


export type Failures = Record<string, number>;

export type Operations = Record<string, number>;

export type PropertyEventSummary = {
  notifications: NotificationEventSummary;
  failures: Failures;
  operations: Operations;
}

export type CachedHealthCheckResult = {
  result: HealthCheckResult;
  cached_date: string | number;
};

export type PropertyHealthSummary = {
  health_checks: Record<HealthCheckType, CachedHealthCheckResult>;
};


export type PropertyTokenRefresh = {
  token_value: string,
  token_expiration: string,
  updated: string,
};

/** Validation for Address */
export const ADDRESS_SCHEMA = yup.object({
  address_line_1: yup.string().required().label('Street'),
  city: yup.string().required().label('City'),
  country: yup.string().required().label('Country'),
  state: yup.string().required().label('State'),
  zip_postal_code: yup.string().required().label('ZIP'),
});


/** Validation for Properties */
export const PROPERTY_SCHEMA = yup.object({
  plan_id: yup.string().required().label('Plan'),
  region_id: yup.string().required().label('Region'),
  physical_address: ADDRESS_SCHEMA,
});


export const DEFAULT_ADDRESS: Address = {
  address_line_1: '',
  address_line_2: '',
  city: '',
  state: '',
  zip_postal_code: '',
  county: '',
  country: 'United States of America',
};

export const GATEWAY_HEALTHCHECK_NAME = 'system.gateway';
export const KIOSK_HEALTHCHECK_NAME = 'system.kiosk';

export type PropertyDebugSettings = {
  enabled?: boolean;
  sync_interval?: number;
};

export type PropertyBase = {
  name?: string,
  physical_address?: Address,
  shipping_address?: Address,
  tags?: Map<string, string>,
  plan_id?: string,
  region_id?: string,
  latitude?: string,
  longitude?: string,
  builder_id?: string,
  manifest_id?: string,
  token?: PropertyTokenRefresh,
  debug?: PropertyDebugSettings,
  homeowner_id?: string,
  owner_id?: string,
  health_summary?: PropertyHealthSummary,
  utility_management?: UtilityManagement,
  heat_btu?: number,
  case_count?: number,
  image_key?: string,
};

export type PropertyCreate = PropertyBase & {
  plan_id: string,
  physical_address: Address,
  region_id: string,
  latitude: string,
  longitude: string,
  builder_id: string,
};

export type PropertyUpdate = PropertyBase;

export type Property = PropertyCreate & {
  property_id: string,
  is_deleted: boolean,
  updated: number,
  created: number,
  event_summary?: PropertyEventSummary,
  token: PropertyTokenRefresh,
  owner_id: string,
  plan_name?: string,
}

export type Home = Property & {
  devices: Device[],
  manifestEntries: PropertyManifestEntry[],
  manifest: PropertyManifest | null,
  planManifestId: string,
}