import { createSlice } from "@reduxjs/toolkit";
import { OemDevice } from "./types";

const initialState: OemDevice[] = [];

export const oemDevicesReducer = createSlice({
  name: 'oemDevices',
  initialState,
  reducers: {
    setDevices: (state: OemDevice[], action: any) => {
      state.splice(0, state.length);
      if (action.payload) {
        action.payload.forEach((device: OemDevice) => {
          state.push(device);
        });
      }
    },
  },
});

export default oemDevicesReducer.reducer;

export const {
  setDevices,
} = oemDevicesReducer.actions;