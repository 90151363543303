import { RootState } from "@app/store";
import { riotApi } from "@features/aws-user/api";
import { SupportCase } from "@features/support-case/types";
import config from "@lib/config";
import { HTTPMethodType } from "@lib/types";
import { U } from "@lib/utils";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/react";
import { FileType } from "./types";


export const supportScreenshotApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      attachScreenshot: builder.mutation<SupportCase|void, {
        userTenantId: string,
        propertyId: string,
        caseId: string,
        context?: string,
        fileType?: FileType,
        screenshot: Buffer,
        quiet?: boolean,
      }>({
        query: ({ userTenantId, propertyId, caseId, context, fileType, screenshot, quiet = true }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/support/${U(caseId)}`,
          method: HTTPMethodType.POST,
          params: {
            context,
            file_type: fileType,
            quiet,
          },
          body: screenshot,
        }),
      }),
      screenshot: builder.query<any, {
        userTenantId: string,
        propertyId: string,
        screenshotId: string,
      }>({
        queryFn: async (
          { userTenantId, propertyId, screenshotId},
          { getState },
        ) => {
          const token = (getState() as RootState).awsUser.idToken;

          const response: any = await fetch(
            `${config.API_ENDPOINT}tenant/${U(userTenantId)}/properties/${U(propertyId)}/support/screenshot/${U(screenshotId)}`,
            {
              headers: new Headers({
                'Authorization': `Bearer ${token}`
              }),
            }
          )

          if (response.status === 200) {
            return {
              data: URL.createObjectURL(await response.blob()),
            };
          }

          return {
            error: {
              status: 404,
              statusText: 'Could not fetch image',
            },
            data: null,
          } as unknown as QueryReturnValue<any, FetchBaseQueryError, unknown>;
        },
      }),
      deleteScreenshots: builder.mutation<void, {
        userTenantId: string,
        caseId: string,
        propertyId: string,
        screenshotIds: string[],
      }>({
        query: ({ userTenantId, caseId, propertyId, screenshotIds }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/support/${U(caseId)}/screenshot`,
          method: HTTPMethodType.DELETE,
          params: {
            screenshot_ids: screenshotIds,
          },
        }),
      }),
    };
  },
});


export const {
  useAttachScreenshotMutation,
  useDeleteScreenshotsMutation,
  useScreenshotQuery,
} = supportScreenshotApi;