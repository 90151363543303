import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { useUpdateDeviceStateMutation } from '@features/home-status/api';
import { DeviceState, DeviceStateType, FanMode, ThermostatModeStateType } from "@features/home-status/types";
import { useGetDevicesQuery } from '@features/home/api';
import { ManifestDevice } from "@features/plan-manifest/types";
import { LoadingButton } from "@mui/lab";
import { Menu, MenuItem } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { RIOT_BLUE } from "theme";
import { DIALOG_Z_INDEX, FanModes, ThermostatAttributes } from "../types";


type Props = {
  entryId: string;
}

const ThermostatFanModeMenu = ({ entryId }: Props ) => {
  const home = useAppSelector((state: RootState) => state.property);
  const devices = useMemo(() => home.devices || [], [home.devices]);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant?.builder_color || RIOT_BLUE, [currentTenant?.builder_color]);
  const manifestEntries = useMemo(() => home.manifestEntries || [], [home.manifestEntries]);
  const thermostatEntry = useMemo(() => manifestEntries.find(me => me.manifest_entry_id === entryId), [manifestEntries, entryId]);

  const currentFanModeSensor = useMemo(() => (thermostatEntry?.device as ManifestDevice).sensors
    .find(s => thermostatEntry?.sensor_map?.[s.sensor_id ?? s.friendly_name]?.split('#')?.[1] === ThermostatAttributes.fanMode), [thermostatEntry?.device, thermostatEntry?.sensor_map]);

  const currentFanModeEntityId = useMemo(() => thermostatEntry?.sensor_map?.[currentFanModeSensor?.sensor_id ?? currentFanModeSensor?.friendly_name ?? '']?.split('#')?.[0] || '', [currentFanModeSensor?.sensor_id, currentFanModeSensor?.friendly_name, thermostatEntry?.sensor_map]);

  const fanModesSensor = useMemo(() => (thermostatEntry?.device as ManifestDevice).sensors
    .find(s => thermostatEntry?.sensor_map?.[s.sensor_id ?? s.friendly_name]?.split('#')?.[1] === ThermostatAttributes.fanModes), [thermostatEntry?.device, thermostatEntry?.sensor_map]);

  const fanModesDevice = useMemo(() => devices
    .find(r => r.data.entity_id === thermostatEntry?.sensor_map?.[fanModesSensor?.sensor_id ?? fanModesSensor?.friendly_name ?? '']), [devices, fanModesSensor?.sensor_id,  fanModesSensor?.friendly_name, thermostatEntry?.sensor_map]);

  const fanModes = useMemo(() => String(fanModesDevice?.data.value || '')
    .split('$').filter((fm): fm is FanMode => Object.values(FanModes).includes(fm as any)), [fanModesDevice?.data.value]);

  const [saving, setSaving] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const [ updateDeviceState ] = useUpdateDeviceStateMutation();

  const {
    refetch,
  } = useGetDevicesQuery({
    tenantId: currentTenant?.tenant_id || '',
    propertyId: home.property_id,
  }, {
    skip: !currentTenant?.tenant_id || !home.property_id,
  });

  const handleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleChangeFanMode = useCallback(async (value: FanMode) => {
    setSaving(true);
    setAnchorEl(null);

    await updateDeviceState({
      userTenantId: currentTenant?.tenant_id || '',
      propertyId: home?.property_id || '',
      deviceState: {
        entity_id: currentFanModeEntityId,
        state_type: DeviceStateType.THERMOSTAT_MODE,
        target_value: {
          fanMode: value,
        } as ThermostatModeStateType,
      } as DeviceState,
    });

    refetch();
    setSaving(false);
  }, [currentTenant?.tenant_id, currentFanModeEntityId, home?.property_id, refetch, updateDeviceState]);

  if (fanModes.length < 1) return <></>;

  return <>
    <LoadingButton
      variant="text"
      onClick={handleOpen}
      id="fan-mode-button"
      loading={saving}
    >
      Fan
    </LoadingButton>
    <Menu
      aria-labelledby="fan-mode-button"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{
        zIndex: DIALOG_Z_INDEX+100,
        '& .MuiMenu-list': {
          padding: '4px 0',
          color: themeColor,
        },
      }}
    >
      {
        fanModes.map((fm, idx) => (
          <MenuItem
            key={`hvac-option-${fm}-${idx}`}
            onClick={() => handleChangeFanMode(fm)}
            sx={{ textTransform: 'capitalize' }}
          >{fm}</MenuItem>
        ))
      }
    </Menu>
  </>;
}

export default ThermostatFanModeMenu;