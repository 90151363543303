import { DeviceCount, LocationSummary } from "@features/provisioning-request/types";
import { formatLabel } from "@lib/utils";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from "@mui/material";
import { SyntheticEvent, useMemo } from "react";
import PlanManifestDevice from "./PlanManifestDevice";

type Props = {
  location: LocationSummary;
  selected: string;
  onChange: (event: SyntheticEvent, newExpanded: boolean) => void;
}
/**
 * Displays summary of devices included in manifest for given home area
 */
const PlanManifestHomeArea = ({ location, selected, onChange }: Props) => {
  const deviceCount = useMemo(() => location.devices
    ?.reduce((count: number, dd: DeviceCount) => (count + dd.count), 0), [location.devices]);

  return (
    <Accordion
      expanded={selected === location.location}
      onChange={onChange}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: 600 }}>
          {formatLabel(location.location)}
        </Typography>
        <Typography sx={{ color: 'text.secondary' }} variant="caption">
          {deviceCount} device{String(deviceCount).slice(-1) !== '1' ? 's' : ''}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          height: '23em',
          overflowY: 'scroll',
        }}
      >
        <Stack>
        {
          location.devices.map((dc: DeviceCount) => (
            <PlanManifestDevice
              key={`plan-manifest-${location.location}-${dc.name}-${dc.count}`}
              location={location}
              device={dc}
            />
          ))
        }
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}

export default PlanManifestHomeArea;