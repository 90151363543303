import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { AnalyticsType, ChartType, TrendLines } from "@features/settings/types";
import { getUnitsByCategoryMeasure } from '@features/unit-settings/utils';
import { Category, Measure, Unit, findUnitsLabel, formatCategoryMeasurePair, formatValue } from "@lib/labels";
import { AnalyticsSet } from '@lib/types';
import { useEffect, useMemo, useState } from "react";
import Chart from "react-apexcharts";
import { ChartDimensions } from '../types';
import { debounce, formatRawDataForTimeLineChart, getChartDimensions, getFallbackChartOptions } from '../utils';

type Props = {
  data: AnalyticsSet[],
  ctype: AnalyticsType,
}

const LineChart = ({ data, ctype }: Props) => {
  const property = useAppSelector((state: RootState) => state.property);
  const userPreferences = useAppSelector((state: RootState) => state.preferences);
  const homes = useAppSelector((state: RootState) => state.homes);
  const plans = useAppSelector((state: RootState) => state.plans);

  const manifestEntries = useMemo(() => property.manifestEntries || [], [property.manifestEntries]);
  const fallbackOptions = useMemo(() => getFallbackChartOptions(ctype), [ctype]);
  const preferences = useMemo(() => userPreferences.preferences, [userPreferences.preferences]);
  const unitPreferences = useMemo(() => preferences.units || {}, [preferences.units]);

  const chartType = useMemo<ChartType>(() => (
    preferences.charts[ctype]?.chartType || fallbackOptions.chartType
  ), [fallbackOptions.chartType, ctype, preferences.charts]);

  const category = useMemo<Category>(() => (
    preferences.charts[ctype]?.category || fallbackOptions.category
  ), [fallbackOptions.category, ctype, preferences.charts]);

  const measure = useMemo<Measure>(() => (
    preferences.charts[ctype]?.measure || fallbackOptions.measure
  ), [fallbackOptions.measure, ctype, preferences.charts]);

  const categoryMeasurePair = useMemo<string>(() => formatCategoryMeasurePair(category, measure), [category, measure]);

  const preferredUnit = useMemo<Unit>(() => {
    const defaultUnit = getUnitsByCategoryMeasure(category, measure)[0];
    return unitPreferences[categoryMeasurePair] || defaultUnit;
  }, [category, categoryMeasurePair, measure, unitPreferences]);

  const trendLines = useMemo<TrendLines>(() => (
    preferences.charts[ctype]?.trendLines || fallbackOptions.trendLines
  ), [fallbackOptions.trendLines, ctype, preferences.charts]);

  const showForecast = useMemo<boolean>(() => Boolean(preferences.charts[ctype]?.showForecast), [ctype, preferences.charts]);

  const chartData = useMemo(() => {
    return formatRawDataForTimeLineChart(
      data,
      manifestEntries,
      plans,
      homes,
      showForecast,
      trendLines,
      chartType,
      category,
      measure,
      preferredUnit,
    );
  }, [data, manifestEntries, plans, homes, showForecast, trendLines, chartType, category, measure, preferredUnit]);

  const [chartDimensions, setChartDimensions] = useState<ChartDimensions>(getChartDimensions());

  const chartOptions = useMemo(() => {
    const width = chartData.map(s => {
      if ((s as any)?.name?.toLowerCase().includes('trendline')) {
        return 1;
      }
      return chartType === ChartType.scatter ? 0 : 3;
    }) || [3];

    const markerSize = chartData.map(s => {
      if ((s as any)?.name?.toLowerCase().includes('trendline')) {
        return 0;
      }
      return chartType === ChartType.scatter ? 3 : 0;
    }) || [3];

    const titleText = (() => {
      if (!data.length) return 'Loading data...';

      const noDataText = `No data found for ${categoryMeasurePair}`;
      if (!chartData?.length) return noDataText;

      return categoryMeasurePair;
    })();


    return ({
      chart: {
        id: 'home-chart',
        stacked: false,
        zoom: {
          enabled: true
        },
        animations: {
          enabled: false,
        }
      },
      stroke: {
        curve: 'smooth',
        width,
      },
      title: {
        text: titleText,
        style: {
          fontFamily: 'Montserrat',
        }
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          }
        },
        yaxis: {
          lines: {
            show: true,
          }
        },
      },
      xaxis: {
        type: 'datetime',
        tickPlacement: 'on',
      },
      yaxis: {
        decimalsInFloat: 0,
        labels: {
          formatter: (value: any) => `${formatValue(value)}${findUnitsLabel(preferredUnit)}`,
        }
      },
      tooltip: {
        fontFamily: 'Montserrat',
        y: {
          formatter: value => `${formatValue(value)}${findUnitsLabel(preferredUnit)}`,
        },
      },
      markers: {
        size: markerSize,
      },
      legend: {
        position: chartDimensions.legend,
        fontFamily: 'Montserrat',
      },
      plotOptions: {
        bar: {
          columnWidth: '50%'
        }
      },
    } as ApexCharts.ApexOptions);
  }, [categoryMeasurePair, chartData, chartDimensions.legend, chartType, data.length, preferredUnit]);

  useEffect(() => {
    const debouncedResize = debounce(() => setChartDimensions(getChartDimensions()), 1000);
    window.addEventListener('resize', debouncedResize);
    return () => window.removeEventListener('resize', debouncedResize);
  }, []);


  return (
    <Chart
      series={chartData}
      options={chartOptions}
      type='line'
      width={chartDimensions.width}
      height={chartDimensions.height}
    />
  );
};


export default LineChart;