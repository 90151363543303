import { useAppDispatch, useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { pollingInterval } from '@features/tenant-selector/types';
import { Box } from '@mui/material';
import { FC, useEffect, useMemo } from "react";
import { useHomesQuery } from '../api';
import { setHomes } from '../slice';


const HomesProvider: FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const {
    currentData: homes,
    isFetching,
  } = useHomesQuery({
    userTenantId: userTenant?.tenant_id || '',
    tenantId: currentTenant?.tenant_id || '',
  },{
    skip: !userTenant?.tenant_id || !currentTenant?.tenant_id,
    pollingInterval,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  useEffect(() => {
    if (homes !== undefined) {
      dispatch(setHomes(homes));
    }
  }, [dispatch, homes]);


  return (
    <Box component="div" sx={{ opacity: isFetching ? 0.9 : 1 }}>
      {children as JSX.Element}
    </Box>
  );

}

export default HomesProvider;