import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import { Backdrop, Box, CircularProgress, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useEffect, useMemo, useState } from "react";
import { useDashboardsQuery, useEmbedMutation } from "./api";
import { DashboardEmbedInfo } from "./types";

const EmbeddedDashboard: FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const [selectedDashboardArn, setSelectedDashboardArn] = useState<string | null>(null);
  const [embedInfo, setEmbedInfo] = useState<DashboardEmbedInfo | null>(null);
  const [fetchingEmbed, setFetchingEmbed] = useState<boolean>(false);

  const {
    currentData: dashboards,
    isFetching: fetchingDashboards,
  } = useDashboardsQuery({
    userTenantId: currentTenant!.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id,
  });

  const [
    getEmbed,
  ] = useEmbedMutation();

  /* Select default dashboard */
  useEffect(() => {
    if (!selectedDashboardArn && dashboards?.length) {
      setSelectedDashboardArn(dashboards[0].Arn);
    }
  }, [dashboards, selectedDashboardArn]);

  /* Fetch dashboard embed when selection changes */
  useEffect(() => {
    setEmbedInfo(null);

    if (currentTenant?.tenant_id && selectedDashboardArn) {
      setFetchingEmbed(true);

      getEmbed({
        userTenantId: currentTenant!.tenant_id,
        arn: selectedDashboardArn,
      }).then((res) => {
        if ('error' in res) {
          enqueueSnackbar("Failed to fetch dashboard:", {
            key: "fetch-dashboard-error",
            content: (
              <ExpandableSnack
                id="fetch-dashboard-error"
                message={"Couldn't fetch dashboard:"}
                variant={SnackType.error}
                detail={getErrorMessage(res.error)}
              />),
          });
        } else {
          setEmbedInfo(JSON.parse(res.data.body));
        }
      }).finally(() => setFetchingEmbed(false));
    }
  }, [enqueueSnackbar, getEmbed, selectedDashboardArn, currentTenant]);

  const isLoading = useMemo(() => fetchingDashboards || fetchingEmbed, [fetchingDashboards, fetchingEmbed]);

  const renderedDashboard = useMemo(() => (
    <Box component="div" sx={{ flex: 1, flexDirection: 'row', display: 'flex' }}>
      <iframe src={embedInfo?.EmbedUrl} title="embedded-dashboard" style={{ border: 'none', flex: 1 }}>
      </iframe>
    </Box>
  ), [embedInfo?.EmbedUrl]);

  const renderedDashboardSelector = useMemo(() => (
    <FormControl fullWidth sx={{ mt: 2 }}>
      <InputLabel sx={{ mt: 1.5 }} id="dashboard-select-label">Select a Dashboard to view</InputLabel>
      <Select
        labelId="dashboard-select-label"
        value={selectedDashboardArn || ''}
        variant="filled"
        onChange={(d) => setSelectedDashboardArn(d.target.value ? d.target.value : null)}
      >
        {
          dashboards?.map((d) => <MenuItem key={d.Arn} value={d.Arn}>{d.Name}</MenuItem>)
        }
      </Select>
    </FormControl>
  ), [dashboards, selectedDashboardArn]);

  return (
    <Box
      component="div"
      sx={{
        margin: '1em',
        padding: '2em',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}
    >
      <Typography variant="h4">{currentTenant.name}</Typography>
      <Typography variant="h5">Analytics</Typography>
      {renderedDashboardSelector}
      <Box
        component="div"
        sx={{
          display: 'flex',
          flex: '1',
          flexDirection: 'column',
        }}
      >
        {isLoading && <Backdrop open><CircularProgress /></Backdrop>}
        {!isLoading && renderedDashboard}
      </Box>
    </Box>
  );
}

export default EmbeddedDashboard;