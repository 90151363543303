import { Category, Measure, Unit } from "@lib/labels";
import { HassEntity } from "@lib/types";

export enum HvacModes {
  off = 'off',
  heat = 'heat',
  cool = 'cool',
  heat_cool = 'heat_cool'
}

export enum FanModes {
  auto_low = 'Auto low',
  low = 'Low',
  circulation = 'Circulation'
}

export enum PresetModes {
  none = 'none',
  energy_heat = 'Energy heat',
  energy_cool = 'Energy cool'
}

export interface ThermostatHeaterAttributes {
  hvac_modes: string[];
  min_temp: number;
  max_temp: number;
  fan_modes: string[];
  preset_modes: string[];
  current_temperature: number;
  temperature: number;
  target_temp_high: number | null;
  target_temp_low: number | null;
  current_humidity: number;
  fan_mode: string;
  hvac_action: string;
  preset_mode: string;
  fan_state: string;
  friendly_name: string;
  supported_features: number;
}

export interface ThermostatEntityState extends HassEntity {
  state: string;
  attributes: ThermostatHeaterAttributes;
}

export enum ThermostatAttributes {
  currentTemperature = 'current_temperature',
  temperatureSetpoint = 'temperature',
  currentHumidity = 'current_humidity',
  fanMode = 'fan_mode',
  fanModes = 'fan_modes',
  hvacMode = '',
  hvacModes = 'hvac_modes',
  presetMode = 'preset_mode',
  presetModes = 'preset_modes',
  hvacAction = 'hvac_action',
  fanState = 'fan_state',
}

export const DIALOG_Z_INDEX = 20000000;


export const THERMOSTAT_CATEGORY = Category.air;
export const THERMOSTAT_MEASURE = Measure.temperature_setpoint;
export const CURRENT_TEMPERATURE_MEASURE = Measure.temperature;
export const THERMOSTAT_DEFAULT_UNIT = Unit.fahrenheit;