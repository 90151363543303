import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { formatValue } from '@features/home-utility-monitoring/utils';
import { ManifestDevice, Sensor } from '@features/plan-manifest/types';
import { getUnitsByCategoryMeasure } from '@features/unit-settings/utils';
import { Category, Measure, convertValue, findUnitsLabel, formatCategoryMeasurePair } from '@lib/labels';
import { formatLabel } from '@lib/utils';
import { Card, CardContent, CardHeader, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';


type Props = {
  category: Category,
  measure: Measure,
}

const CurrentReadings = ({ category, measure }: Props) => {
  const home = useAppSelector((state: RootState) => state.property);
  const userPreferences = useAppSelector((state: RootState) => state.preferences);

  const manifestEntries = useMemo(() => home.manifestEntries || [], [home.manifestEntries]);
  const devices = useMemo(() => home.devices || [], [home.devices]);
  const preferences = useMemo(() => userPreferences.preferences, [userPreferences.preferences]);
  const unitPreferences = useMemo(() => preferences.units || {}, [preferences.units]);
  const preferredUnit = useMemo(() => (
    unitPreferences?.[formatCategoryMeasurePair(category, measure)]
    || getUnitsByCategoryMeasure(category, measure)[0]
  ), [category, measure, unitPreferences]);

  const matchingSensors = useCallback((ss: Sensor) => ss.sensor_category === category && ss.measure_name === measure, [category, measure]);

  const currentEntries = useMemo(() => manifestEntries
    .filter(e => (e.device as ManifestDevice)?.sensors?.some(matchingSensors))
  , [manifestEntries, matchingSensors]);

  const currentReadings = useMemo(() => currentEntries
    .map(cu => {
      const name = formatLabel(cu.property_area);
      const deviceId = cu.gateway_device_id;
      const sensor = (cu.device as ManifestDevice).sensors.find(matchingSensors);
      const entityId = cu.sensor_map?.[sensor?.sensor_id ?? sensor?.friendly_name ?? ''] || '';
      const readings = devices
        .filter(d => d.data.device_id === deviceId && d.data.entity_id === entityId)
        .sort((a, b) => a.datetime - b.datetime);

      const value = convertValue(readings?.[0]?.data.value, category, measure, preferredUnit);

      return { name, value };
    })
  , [category, currentEntries, devices, matchingSensors, measure, preferredUnit]);

  return (
    <Card>
      <CardHeader title="Current Readings" />
      <Divider />
      <CardContent>
        <Stack spacing={1}>
          {!currentReadings && <CircularProgress />}
          {
            currentReadings?.map(rr => (
              <Stack direction="row" justifyContent="space-between" key={`room-reading-${rr.name}`}>
                <Typography>{rr.name}</Typography>
                <Typography>
                  {formatValue(rr.value, 0)}{findUnitsLabel(preferredUnit)}
                </Typography>
              </Stack>
            ))
          }
        </Stack>
      </CardContent>
    </Card>
  );
}

export default CurrentReadings;