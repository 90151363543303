import { riotApi } from "@features/aws-user/api";
import { Plan } from "@features/floorplan/types";
import { HTTPMethodType } from "@lib/types";
import { U } from "@lib/utils";
import { ManifestEntry, ManifestEntryCreate, PlanManifest } from "./types";


/** API slice for plan manifest-related endpoints */
export const planManifestApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      /** Get all the Plans */
      listPlans: builder.query<Plan[], {
        userTenantId: string,
      }>({
        query: ({ userTenantId }) => ({
          url: `tenant/${U(userTenantId)}/plans`,
          method: HTTPMethodType.GET,
        })
      }),
      /** Get all Plan Manifests for specified plan */
      getPlanManifests: builder.query<PlanManifest[], {
        userTenantId: string,
        planId: string,
        includeEntries?: boolean,
      }>({
        query: ({ userTenantId, planId, includeEntries = false }) => ({
          url: `tenant/${U(userTenantId)}/plans/${U(planId)}/manifests?include_entries=${U(includeEntries)}`,
          method: HTTPMethodType.GET,
        })
      }),

      /** Create plan manifest */
      createPlanManifest: builder.mutation<PlanManifest, {
        userTenantId: string,
        planId: string,
        body: Partial<PlanManifest>,
      }>({
        query: ({ userTenantId, planId, body }) => ({
          url: `tenant/${U(userTenantId)}/plans/${U(planId)}/manifests`,
          method: HTTPMethodType.POST,
          body,
        })
      }),

      /** Delete a Plan Manifest */
      deletePlanManifest: builder.mutation<void, {
        userTenantId: string,
        planId: string,
        planManifestId: string,
      }>({
        query: ({ userTenantId, planId, planManifestId }) => ({
          url: `tenant/${U(userTenantId)}/plans/${U(planId)}/manifests/${U(planManifestId)}`,
          method: HTTPMethodType.DELETE,
        })
      }),

      /** Create a plan manifest entry */
      createPlanManifestEntry: builder.mutation<ManifestEntry, {
        userTenantId: string,
        manifestId: string,
        body: Partial<ManifestEntryCreate>,
      }>({
        query: ({ userTenantId, manifestId, body }) => ({
          url: `tenant/${U(userTenantId)}/manifests/${U(manifestId)}/entries`,
          method: HTTPMethodType.POST,
          body,
        })
      }),

      /** Delete a plan manifest entry  */
      deletePlanManifestEntry: builder.mutation<void, {
        userTenantId: string,
        planManifestId: string,
        planManifestEntryId: string,
      }>({
        query: ({ userTenantId, planManifestId, planManifestEntryId }) => ({
          url: `tenant/${U(userTenantId)}/manifests/${U(planManifestId)}/entries/${U(planManifestEntryId)}`,
          method: HTTPMethodType.DELETE,
        })
      }),

      /** Update a plan manifest entry */
      updatePlanManifestEntry: builder.mutation<ManifestEntry, {
        userTenantId: string,
        planManifestId: string,
        planManifestEntryId: string,
        body: Partial<ManifestEntryCreate>,
      }>({
        query: ({ userTenantId, planManifestId, planManifestEntryId, body }) => ({
          url: `tenant/${U(userTenantId)}/manifests/${U(planManifestId)}/entries/${U(planManifestEntryId)}`,
          method: HTTPMethodType.PATCH,
          body,
        })
      }),

      /** Get all manifest entries for a given plan manifest ID */
      getPlanManifest: builder.query<PlanManifest, {
        userTenantId: string,
        planId: string,
        planManifestId: string,
        includeEntries?: boolean,
      }>({
        query: ({ userTenantId, planId, planManifestId, includeEntries = false }) => ({
          url: `tenant/${U(userTenantId)}/plans/${U(planId)}/manifests/${U(planManifestId)}?include_entries=${U(includeEntries)}`,
          method: HTTPMethodType.GET,
        })
      }),
    };
  }
});

export const {
  useListPlansQuery,
  useGetPlanManifestsQuery,
  useGetPlanManifestQuery,
  useCreatePlanManifestMutation,
  useDeletePlanManifestMutation,
  useCreatePlanManifestEntryMutation,
  useDeletePlanManifestEntryMutation,
  useUpdatePlanManifestEntryMutation,
} = planManifestApi;
