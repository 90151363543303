import AppTextField from '@features/home/components/AppTextField';
import { Stack } from "@mui/material";

const DeviceFields = () => {

  return (
    <Stack spacing={1.3}>
      <Stack direction="row" spacing={1} justifyContent="space-between">
        <AppTextField
          variant="outlined"
          label="Manufacturer"
          placeholder="Manufacturer"
          name="manufacturer"
          fullWidth
          size="small"
        />
        <AppTextField
          variant="outlined"
          label="Part Number"
          placeholder="Part Number"
          name="part_number"
          fullWidth
          size="small"
        />
        <AppTextField
          variant="outlined"
          label="Model Number"
          placeholder="Model Number"
          name="model_number"
          fullWidth
          size="small"
        />
      </Stack>
      <AppTextField
        variant="outlined"
        label="Label"
        placeholder="e.g. Multisensor 6"
        name="friendly_name"
        required
        fullWidth
        size="small"
      />
      <AppTextField
        variant="outlined"
        label="Description"
        placeholder="Description"
        name="description"
        fullWidth
        multiline
        rows={2}
        size="small"
      />
      <AppTextField
        variant="outlined"
        label="Pairing instructions"
        placeholder="Pairing instructions"
        name="pair_instructions"
        fullWidth
        multiline
        rows={2}
        size="small"
      />
      <AppTextField
        variant="outlined"
        label="Unpairing instructions"
        placeholder="Unpairing instructions"
        name="unpair_instructions"
        fullWidth
        multiline
        rows={2}
        size="small"
      />
      <AppTextField
        variant="outlined"
        label="Reset instructions"
        placeholder="Reset instructions"
        name="reset_instructions"
        fullWidth
        multiline
        rows={2}
        size="small"
      />
    </Stack>);
}

export default DeviceFields;