import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { PropertyManifestEntry } from "@features/home-manifest/types";
import CategoryReadingIcon from "@features/home-status/components/CategoryReadingIcon";
import { ReadingPair } from "@features/home-status/types";
import { ManifestDevice } from "@features/plan-manifest/types";
import { Category, Measure } from "@lib/labels";
import { formatLabel } from "@lib/utils";
import { Stack, Typography } from "@mui/material";
import { useMemo } from "react";


type Props = {
  evseEntry?: PropertyManifestEntry;
}

const ChargeStatus = (props: Props) => {
  const { evseEntry } = props;

  const property = useAppSelector((state: RootState) => state.property);

  const devices = useMemo(() => property.devices || [], [property.devices]);

  const chargeSensor = useMemo(() => (evseEntry?.device as ManifestDevice)?.sensors
    ?.find(s => s.sensor_category === Category.power && s.measure_name === Measure.status), [evseEntry?.device]);

  const chargeDevice = useMemo(() => devices
    .find(r => r.data.entity_id === evseEntry?.sensor_map?.[chargeSensor?.sensor_id ?? chargeSensor?.friendly_name ?? '']), [chargeSensor?.sensor_id, chargeSensor?.friendly_name, devices, evseEntry?.sensor_map]);

  const chargeReading = useMemo(() => ({
    device: chargeDevice,
    sensor: chargeSensor,
    deviceName: formatLabel((evseEntry?.device as ManifestDevice)?.friendly_name
      || (evseEntry?.device as ManifestDevice)?.manufacturer || ''),
    area: formatLabel(evseEntry?.property_area || ''),
    entryId: evseEntry?.manifest_entry_id,
  } as ReadingPair), [chargeDevice, chargeSensor, evseEntry?.device, evseEntry?.manifest_entry_id, evseEntry?.property_area]);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="h6">Status</Typography>
      <CategoryReadingIcon
        category={Category.power}
        measure={Measure.status}
        readings={chargeReading}
      />
    </Stack>
  );
}

export default ChargeStatus;