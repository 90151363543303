import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import AdminTable from "@features/Common/AdminTable";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { getHeaderStyles } from "@features/Common/tables";
import AppTextField from "@features/home/components/AppTextField";
import { pollingInterval } from "@features/tenant-selector/types";
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import LoadingButton from '@mui/lab/LoadingButton';
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { RIOT_BLUE } from "theme";
import {
  useDeleteDocumentMutation,
  useDocumentContentsUrlQuery,
  useMyDocumentsQuery,
  usePrefetch,
  useTenantDocumentsQuery,
  useUpdateDocumentMutation
} from "../api";
import { Document, DOCUMENT_SCHEMA } from '../types';
import DocumentTypeList from "./DocumentTypes";
import ShareModal from "./ShareModal";
import UploadModal from "./UploadModal";


const DocumentList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const documents = useAppSelector((state: RootState) => state.documents);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const themeColor = useMemo(() => currentTenant?.builder_color || RIOT_BLUE, [currentTenant?.builder_color]);

  const [creating, setCreating] = useState<boolean>(false);
  const [currentDoc, setCurrentDoc] = useState<Document>(documents[0]);
  const [sharingDoc, setSharingDoc] = useState<Document|null>(null);

  const {
    currentData: docUrl,
    isFetching: isFetchingDocUrl,
  } = useDocumentContentsUrlQuery({
    userTenantId: currentTenant?.tenant_id || '',
    documentId: currentDoc?.document_id || '',
  }, {
    skip: !currentTenant?.tenant_id || !currentDoc?.document_id,
    pollingInterval: pollingInterval*10*6,
  });

  const [
    deleteDocument,
  ] = useDeleteDocumentMutation();

  const [
    updateDocument,
  ] = useUpdateDocumentMutation();

  const prefetchUrl = usePrefetch('documentContentsUrl');

  /** Fetches pre-signed url for current document */
  const prefetchNextUrl = useCallback((doc: Document) => {
    if (currentDoc && currentDoc.document_id === doc.document_id) return;
    setCurrentDoc(doc);
    prefetchUrl({
      userTenantId: currentTenant?.tenant_id || '',
      documentId: doc?.document_id || '',
    }, { force: true });
  }, [currentDoc, prefetchUrl, currentTenant?.tenant_id]);

  /** Deletes a document */
  const handleDeleteDocument = useCallback(async (document: Partial<Document>) => {
    const res = await deleteDocument({
      userTenantId: currentTenant?.tenant_id || '',
      documentId: document.document_id || '',
    });

    if ('error' in res) {
      enqueueSnackbar("Failed to delete document:", {
        key: "delete-document-error",
        content: (
          <ExpandableSnack
            id="contact-error"
            message={"Couldn't delete document:"}
            variant={SnackType.error}
            detail={getErrorMessage(res.error)}
          />),
      });
      return false;
    }

    if (res !== null) {
      enqueueSnackbar("Deleted Document", {
        variant: "success",
      });
      return true;
    }

    enqueueSnackbar("Couldn't delete document", {
      variant: "error",
    });

    return false;
  }, [currentTenant?.tenant_id, deleteDocument, enqueueSnackbar]);


  /** Updates an existing document */
  const handleSaveDocument = useCallback(async (document: Partial<Document>) => {
    const newDoc = await updateDocument({
      userTenantId: currentTenant?.tenant_id || '',
      documentId: document?.document_id || '',
      body: document,
    });

    const errorDetails = (newDoc as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't save document:", {
        key: "contact-error",
        content: (
          <ExpandableSnack
            id="contact-error"
            message={"Couldn't save document:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("Saved document", {
        variant: "success",
      });
    }

    return null;
  }, [currentTenant?.tenant_id, enqueueSnackbar, updateDocument]);

  /** Opens a document */
  const openDocument = useCallback((document: Document) => {
    if (docUrl) {
      window.location.href = docUrl.url;
    }
  }, [docUrl]);

  const {
    refetch: refetchMyDocuments,
  } = useMyDocumentsQuery({
    userTenantId: currentTenant?.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id,
  });

  const {
    refetch: refetchTenantDocuments,
  } = useTenantDocumentsQuery({
    userTenantId: userTenant?.tenant_id || '',
    tenantId: currentTenant?.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id,
  });

  const refresh = useCallback(() => {
    const isOtherTenant =
      !!userTenant?.tenant_id &&
      !!currentTenant?.tenant_id &&
      userTenant.tenant_id !== currentTenant.tenant_id;

    if (isOtherTenant) return refetchTenantDocuments();
    return refetchMyDocuments();
  }, [currentTenant.tenant_id, refetchMyDocuments, refetchTenantDocuments, userTenant.tenant_id]);

  /** Displays the Documents as an Admin table */
  const renderedTable = useMemo(() => {
    if (userTenant.checkingTenant) return <Backdrop open><CircularProgress /></Backdrop>;
    const options: MUIDataTableOptions = {
      enableNestedDataAccess: '.',
    };
    const data = documents;
    const columns: MUIDataTableColumn[] = [
      {
        name: 'name',
        label: 'File Name',
        options: {
          setCellHeaderProps: () => getHeaderStyles(themeColor),
        },
      },
      {
        name: 'is_owner',
        label: 'Source',
        options: {
          setCellHeaderProps: () => getHeaderStyles(themeColor),
          customBodyRenderLite: (
            dataIndex,
          ) => documents[dataIndex]?.is_owner ? 'Created by you' : 'Shared with you',
        },
      },
      {
        name: 'document_type',
        label: 'Category',
        options: {
          setCellHeaderProps: () => getHeaderStyles(themeColor),
        },
      },
      {
        name: 'description',
        label: 'Description',
        options: {
          sort: false,
          filter: false,
          setCellHeaderProps: () => getHeaderStyles(themeColor),
        },
      },
      {
        name: 'size',
        label: 'Size',
        options: {
          setCellHeaderProps: () => getHeaderStyles(themeColor),
          customBodyRenderLite: (
            dataIndex,
          ) => `${(Number(documents[dataIndex]?.size) / 1024 / 1024).toFixed(2)} MiB`
        }
      },
      {
        name: '',
        label: '',
        options: {
          filter: false,
          sort: false,
          download: false,
          setCellHeaderProps: () => getHeaderStyles(themeColor),
          customBodyRenderLite: (dataIndex, rowIndex) => {
            const doc = documents[dataIndex];
            const openingDoc: boolean = Boolean(
              currentDoc &&
              doc.document_id === currentDoc.document_id &&
              isFetchingDocUrl
            );

            return (
              <LoadingButton
                onClick={() => openDocument(doc)}
                loading={openingDoc}
                onMouseEnter={() => prefetchNextUrl(doc)}
              >
                View Document
              </LoadingButton>);
          },
        }
      }
    ];

    return (
      <>
        <AdminTable<Document>
          title="Documents"
          label="Document"
          id_key="document_id"
          name_key="name"
          columns={columns}
          options={options}
          data={data}
          FormikProps={{
            validationSchema: DOCUMENT_SCHEMA,
          }}
          onNew={async () => {
            setCreating(true);
            return true;
          }}
          onShare={async (doc: Document) => {
            setSharingDoc(doc);
            return true;
          }}
          refresh={refresh}
          editPermission="write-documents"
          deletePermission="delete-documents"
          onSave={handleSaveDocument}
          onDelete={handleDeleteDocument}
        >
          {f => (
            <>
              <AppTextField
                name="document_type"
                label="Document Category"
                select
                fullWidth
                required
              >
                {DocumentTypeList()}
              </AppTextField>
              <AppTextField
                fullWidth
                multiline
                minRows={2}
                name="description"
                label="Description"
              />
            </>
          )}
        </AdminTable>
        {
          creating &&
          <UploadModal
            open={creating}
            onClose={(changes: any) => {
              setCreating(false);
              if (changes) {
                refresh();
              }
            }}
          />
        }
        {
          sharingDoc &&
          <ShareModal
            document={sharingDoc}
            open={Boolean(sharingDoc)}
            onClose={(changes: any) => {
              setSharingDoc(null);

              if (changes) {
                refresh();
              }
            }}
          />
        }
      </>
    );
  }, [creating, currentDoc, documents, handleDeleteDocument, handleSaveDocument, isFetchingDocUrl, openDocument, prefetchNextUrl, refresh, sharingDoc, themeColor, userTenant.checkingTenant]);


  return (
    <Box
      component="div"
      sx={{
        margin: '1em',
        padding: '2em',
      }}
    >
      <Typography variant="h4">{currentTenant.name}</Typography>
      <Typography variant="h5">Documents</Typography>
      <Box
        component="div"
        sx={{
          mt: '3em',
        }}
      >
        {renderedTable}
      </Box>
    </Box>
  );
}

export default DocumentList;