import { useAppDispatch, useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { useDocumentDownloadMutation } from '@features/all-documents/api';
import { FC, useEffect, useMemo, useState } from "react";
import { setFloorplanUrl } from '../slice';

const FloorplanProvider: FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const property = useAppSelector((state: RootState) => state.property);
  const documents = useAppSelector((state: RootState) => state.documents);

  const planManifestId = useMemo(() => property.manifest_id || '', [property.manifest_id]);
  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const floorplanDocId = useMemo(() => {
    return documents
      .find(doc => doc.name
        .includes(planManifestId) && doc.name.toLocaleLowerCase().includes('.fbx'))?.document_id;
  }, [documents, planManifestId]);

  const [downloadDocument] = useDocumentDownloadMutation();

  useEffect(() => {
    if (floorplanDocId) {
      const dl = downloadDocument({
        userTenantId: currentTenant.tenant_id,
        documentId: floorplanDocId,
      });
      dl.then((docDownload) => {
        if (!('error' in docDownload)) {
          dispatch(setFloorplanUrl(docDownload.data));
        }
      });
      return () => dl.abort();
    } else {
      dispatch(setFloorplanUrl({}));

      return () => {};
    }
  }, [currentTenant.tenant_id, dispatch, downloadDocument, floorplanDocId]);

  return children as JSX.Element;
}

export default FloorplanProvider;