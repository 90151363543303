import { MUIDataTableOptions } from 'mui-datatables';

/** Table options to strip off all the table controls */
export const BARE_TABLE: MUIDataTableOptions = {
  selectableRows: 'none',
  search: false,
  filter: false,
  download: false,
  print: false,
  viewColumns: false,
  pagination: false,
  elevation: 0,
  setTableProps: () => ({ size: 'small'})
};

/** Cell properties to make the column as narrow as possible without wrapping */
export const MIN_CELL = () => ({ style: { width: 0, whiteSpace: 'nowrap' }});
/** Cell properties to disabled text wrapping in the column */
export const NO_WRAP = () => ({ style: { whiteSpace: 'nowrap' }});

export const getHeaderStyles = (themeColor: string) => ({
  style: {
    width: 0,
    whiteSpace: 'nowrap',
    color: themeColor,
  },
});


/**
 * Wrapper that finds the original data row and renders a field.
 *
 * @param rows all the rows in the table
 * @param callback function that renders the field
 * @returns wrapper for customBodyRenderLite
 */
 export const renderLite = <T>(rows: T[], callback: (row: T) => any) => {
  return (dataIndex: number, _rowIndex: number) => {
    const row = rows[dataIndex];
    if (!row) {
      return null;
    }

    return callback.call(null, row);
  };
}
