import { riotApi } from "@features/aws-user/api";
import { HTTPMethodType } from "@lib/types";
import { U } from "@lib/utils";

export const oemOnboardingApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      createApiKey: builder.mutation<string, {
        userTenantId: string,
        oemName: string,
        location: string,
      }>({
        query: ({ userTenantId, oemName, location }) => ({
          url: `tenant/${U(userTenantId)}/oem/new`,
          method: HTTPMethodType.POST,
          params: {
            oem_name: oemName,
            location,
          }
        }),
      }),
    };
  }
});

export const {
  useCreateApiKeyMutation,
} = oemOnboardingApi;