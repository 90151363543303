import { ManifestDevice } from "@features/plan-manifest/types";
import { createSlice } from "@reduxjs/toolkit";

const initialState: ManifestDevice[] = [];

export const inventoryReducer = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    setInventory: (state: ManifestDevice[], action: any) => {
      if (action.payload) {
        state.splice(0, state.length);
        action.payload.forEach((md: ManifestDevice) => state.push(md));
      } else {
        state.splice(0, state.length);
      }
    }
  }
});

export default inventoryReducer.reducer;

export const {
  setInventory,
} = inventoryReducer.actions;