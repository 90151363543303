import { createSlice } from "@reduxjs/toolkit";
import { Contact } from "./types";


const initialState: Contact[] = [];

/** State slice for the cotacts under selected tenant */
export const contactsReducer = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setTenantContacts: (state: Contact[], action: any) => {
      if (action.payload) {
        state.splice(0, state.length);
        action.payload.forEach((cc: Contact) => state.push(cc));
      }
    },
  }
});

export default contactsReducer.reducer;

export const {
  setTenantContacts,
} = contactsReducer.actions;