export type ColorType = 'hex' | 'rgb' | 'rgba';

export const DEFAULT_FORMAT = 'rgba';

export const LOGO_UPLOAD_WARNING = [
  'You can upload an image here to replace the logo',
  'displayed in this Dashboard for all of your users',
  '(including homeowners).',
  'Please note that all logos used here are publicly accessible,',
  'so we strongly recommend that you do not upload any files',
  'containing private or explicit content.',
].join(' ');