import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import SensorReadings from "@features/device/components/SensorReadings";
import { PropertyManifestEntry } from "@features/home-manifest/types";
import { Sensor } from "@features/plan-manifest/types";
import { formatCategoryMeasurePair } from "@lib/labels";
import LeakAddIcon from '@mui/icons-material/LeakAdd';
import LeakRemoveIcon from '@mui/icons-material/LeakRemove';
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Tooltip,
  Typography
} from "@mui/material";
import { MouseEventHandler, useMemo } from "react";

type Props = {
  entry: PropertyManifestEntry,
  sensor: Sensor;
  onPair: MouseEventHandler<HTMLButtonElement> | undefined;
  onUnpair: (
    manifestEntry: PropertyManifestEntry,
    sensor: Sensor,
  ) => Promise<void>;
}

/* Displays individual sensor for pairing */
const SensorToPair = ({ sensor, entry, onPair, onUnpair }: Props) => {
  const property = useAppSelector((state: RootState) => state.property);

  const devices = useMemo(() => {
    /* Sort devices by date desc */
    return [...(property.devices || [])].sort((a, b) => b.datetime - a.datetime);
  }, [property.devices]);

  const entityId = useMemo(() => entry.sensor_map?.[sensor.sensor_id ?? sensor.friendly_name], [entry.sensor_map, sensor.sensor_id, sensor.friendly_name]);

  /* Find the device in the property readings */
  const pairedDevice = useMemo(() => devices
    .find(d => entityId && d.data.entity_id === entityId && d.data.device_id === entry.gateway_device_id), [devices, entityId, entry.gateway_device_id]);

  const sensorName = useMemo(() => (
    sensor.friendly_name ||
    `${formatCategoryMeasurePair(sensor.sensor_category, sensor.measure_name)} sensor`
  ), [sensor.friendly_name, sensor.measure_name, sensor.sensor_category]);

  /* Displays device status */
  const linkedEntityId = useMemo(() => entry.sensor_map?.[sensor.sensor_id ?? sensor.friendly_name], [entry.sensor_map, sensor.sensor_id, sensor.friendly_name]);

  const deviceStatus = useMemo(() => (
    linkedEntityId ? 'Sensor is linked.' : 'Sensor is unlinked.'
  ), [linkedEntityId]);

  return (
    <Card raised>
      <CardHeader
        disableTypography
        avatar={
          <Tooltip title={deviceStatus} placement="top" arrow>
            <span>
              {
                linkedEntityId
                  ? <LeakAddIcon color="success" />
                  : <LeakRemoveIcon color="warning" />
              }
            </span>
          </Tooltip>
        }
        title={
          <Typography variant='button' sx={{ fontSize: '1rem' }}>
            {sensorName}
          </Typography>
        }
        subheader={
          <Typography color="text.secondary">
            {
              [
                'measures',
                formatCategoryMeasurePair(sensor.sensor_category, sensor.measure_name),
              ].join(' ')
            }
          </Typography>
        }
        action={
          <ButtonGroup>
          {
            (pairedDevice || linkedEntityId) &&
            <Button
              size="small"
              color="error"
              onClick={() => onUnpair(entry, sensor)}
            >
              Unlink
            </Button>
          }
          {
            !pairedDevice &&
            !linkedEntityId &&
            <Button onClick={onPair}>
              Link sensor
            </Button>
          }
          </ButtonGroup>
        }
      />
      <CardContent sx={{ height: '5em' }}>
        {pairedDevice && <SensorReadings entry={entry} device={pairedDevice} />}
      </CardContent>
    </Card>
  );
}

export default SensorToPair;