import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import Commissioning from '@features/commissioning/components/Commissioning';
import Header from '@features/Common/Header';
import { Box, Typography } from '@mui/material';
import { useMemo } from "react";

const CommissionHome = () => {
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const property = useAppSelector((state: RootState) => state.property);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  if (!property.property_id) return <></>;

  return (
    <>
      <Header />
      <Box
        component="div"
        sx={{
          margin: '1em',
          padding: '2em',
        }}
      >
        <Typography variant="h4">{currentTenant.name}</Typography>
        <Typography variant="h5">
          Commissioning devices for {property.name || 'new RIoT Home'}
        </Typography>
        <Box
          component="div"
          sx={{
            mt: '3em',
          }}
        >
          <Commissioning />
        </Box>
      </Box>
      </>
  );
}

export default CommissionHome;