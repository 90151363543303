import { CardHeader, CardContent, Grid, Card, Divider, Box } from "@mui/material";
import CurrentUsage from "./CurrentUsage";
import NeighborhoodHistoricalUsage from "./NeighborhoodHistoricalUsage";
import NeighborhoodPerformance from "./NeighborhoodPerformance";

type Props = {

};

const NeighborhoodPerformanceView: React.FC<Props> = function NeighborhoodUsageView(props: Props) {
  return <Box component="div">
    <Card sx={{ minHeight: '34em' }}>
      <CardHeader title="Neighborhood performance" />
      <Divider />
      <CardContent>
        <NeighborhoodPerformance />
      </CardContent>
    </Card>
  </Box>
}

export default NeighborhoodPerformanceView;
