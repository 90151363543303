import { PropertyManifestEntry } from "@features/home-manifest/types";
import { ManifestDevice } from "@features/plan-manifest/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import PairingButton from "../PairingButton";

type Props = {
  entry: PropertyManifestEntry;
  isPairing: boolean;
  onPair: () => void;
  isOpen: boolean;
  onClose: () => void;
}

const PairingModal = ({
  isOpen,
  onClose,
  entry,
  isPairing,
  onPair,
}: Props) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>Pair {(entry.device as ManifestDevice).friendly_name}</DialogTitle>
      <DialogContent>
        <Typography>
          Perform the following actions after entering pairing mode in order to pair the device.
        </Typography>
        <Typography component="p" sx={{ m: 3 }} fontStyle="italic">
          {(entry.device as ManifestDevice).pairing_instructions || 'This device has no provided pairing instructions.'}
        </Typography>
      </DialogContent>
      <DialogActions>
        <PairingButton isPairing={isPairing} onPair={onPair} />
        <Button onClick={onClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PairingModal;
