import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { ManifestDevice } from "@features/plan-manifest/types";
import { Category, Measure } from "@lib/labels";
import { SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import AirIcon from '@mui/icons-material/Air';
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHealthCheckHomeMutation } from "../api";
import { AirFilterHealthResult, HealthCheckResult, HealthCheckType } from "../types";
import HealthCheckSummary from "./HealthCheckSummary";

const AirFilterHealth = () => {
  const { enqueueSnackbar } = useSnackbar();
  const property = useAppSelector((state: RootState) => state.property);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const { manifestEntries } = useMemo(() => property, [property]);

  const [healthCheckResult, setHealthCheckResult] = useState<HealthCheckResult | null>(null);

  const [
    healthCheckHome
  ] = useHealthCheckHomeMutation();

  useEffect(() => {
    if (currentTenant.tenant_id && property.property_id) {
      healthCheckHome({
        healthCheckType: HealthCheckType.AIR_FILTER,
        propertyId: property.property_id,
        userTenantId: currentTenant.tenant_id,
      }).then((res) => {
        if ('error' in res) {
          enqueueSnackbar("Failed to perform health check:", {
            key: "af-hc-error",
            content: (
              <ExpandableSnack
                id="af-hc-error"
                message={"Couldn't perform health check:"}
                variant={SnackType.error}
                detail={getErrorMessage(res.error)}
              />),
          });
        } else {
          setHealthCheckResult(res.data);
        }
      });
    }
  }, [healthCheckHome, property.property_id, currentTenant.tenant_id, enqueueSnackbar]);

  const involvedEntries = useMemo(() => manifestEntries
    ?.filter(ee => (ee.device as ManifestDevice).sensors.some(ss => (
      ss.sensor_category === Category.air && ss.measure_name === Measure.airpressure))), [manifestEntries]);

  const getStatus = useCallback((health: AirFilterHealthResult) => {
    const lifetimeRemain = typeof health.percent_remaining === 'number'
      ? ` (${Math.round(health.percent_remaining)}% lifetime remaining)`
      : '';

    if (health.needs_replacement) {
      return `${health.property_area} air filter needs replacement${lifetimeRemain}.`
    }

    return `${health.property_area} air filter is normal${lifetimeRemain}.`;
  }, []);

  const getSubstatus = useCallback((health: AirFilterHealthResult) => {
    const expected = 'Expected average filter lifetime is';
    if (typeof health.expected_lifetime !== 'number') return `${expected} unknown.`;
    return `${expected} ${health.expected_lifetime.toFixed(0)} hours of use`;
  }, []);

  const statuses = useMemo(() => (
    healthCheckResult?.air_filter?.length
      ? healthCheckResult.air_filter.map((airFilterHealth) => ({
        key: airFilterHealth.property_area,
        status: getStatus(airFilterHealth),
        substatus: getSubstatus(airFilterHealth),
      }))
      : [{
        key: 'unknown',
        status: 'Air filter status is unknown. It may need replacement or service.',
        substatus: 'Expected remaining filter lifetime is unknown.',
      }]
  ), [getStatus, getSubstatus, healthCheckResult?.air_filter]);

  return (
    <HealthCheckSummary
      icon={<AirIcon sx={{ fontSize: '6em' }} />}
      healthCheckResult={healthCheckResult}
      title="Air Filter"
      entries={involvedEntries || []}
      statuses={statuses}
    />
  );
}

export default AirFilterHealth;
