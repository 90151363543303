import { BinSize, ChartInterval, ChartIntervalOption, interval } from "./types";

export const getDurationFromLabel = (
  label: interval | BinSize | null | undefined
): ChartInterval => {
  if (!label) return {};
  const num = Number(label.match(/\d+/) || 1);
  const type = label.match(/[a-zA-Z]+/)?.join('').toUpperCase();
  switch (type) {
    case 'D':
      return {
        days: num,
      };
    case 'M':
      return {
        months: num,
      };
    case 'Y':
    default:
      return {
        years: num,
      };
  }
};

/** Options for Interval Selector */
export const getIntervals = (): ChartIntervalOption[] =>
  Array.from(Object.values(interval)).map((label) => ({
    label,
    duration: getDurationFromLabel(label)
  }));
