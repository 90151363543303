import { useAppDispatch, useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { ALL_CATEGORIES, Category, Measure, getMeasuresByCategory } from "@lib/labels";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Checkbox, ClickAwayListener, Divider, FormControlLabel, IconButton, Paper, Popper, Stack, Tooltip, Typography } from "@mui/material";
import { Fragment, MouseEvent, useCallback, useMemo, useState } from "react";
import { RIOT_BLUE } from "theme";
import { setAllCategories } from "../slice";
import CustomList from "./CustomList";


const CategoryFilters = () => {
  const dispatch = useAppDispatch();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const displayedCategories = useAppSelector((state: RootState) => state.displayedCategories || new Map<Category, Measure[]>());

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant?.builder_color || RIOT_BLUE, [currentTenant?.builder_color]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'category-filter' : undefined;

  const allChecked = useMemo(() => Object.keys(displayedCategories)
    .every(cat => getMeasuresByCategory(cat as Category)
      .every(mm => displayedCategories?.[cat as Category]?.includes(mm))), [displayedCategories]);

  const someChecked = useMemo(() => !allChecked && Object.keys(displayedCategories)
      .some(cat => !!displayedCategories?.[cat as Category]?.length), [allChecked, displayedCategories]);

  const handleToggleAll = useCallback(() => {
    if (allChecked) {
      dispatch(setAllCategories(false));
    } else {
      dispatch(setAllCategories(true));
    }
  }, [allChecked, dispatch]);

  return (
    <>
      <Tooltip title="Hide Readings" placement="top" arrow>
        <span>
          <IconButton
            color="primary"
            onClick={handleClick}
            sx={{
              ml: 1,
              bgcolor: 'white',
            }}
          >
            <VisibilityOffIcon fontSize="large" />
          </IconButton>
        </span>
      </Tooltip>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener
          onClickAway={(e) => {
            e.preventDefault();
            setAnchorEl(null);
          }}
        >
          <Paper sx={{
            border: `1px solid ${themeColor}`,
            width: '80em'
          }}>
            <Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" component="span" sx={{ pl: 1 }}>Measures</Typography>
                <FormControlLabel
                  control={<Checkbox
                    checked={allChecked}
                    indeterminate={someChecked}
                    onChange={handleToggleAll}
                  />}
                  label="Show all"
                />
              </Stack>
              <Divider flexItem />
              <Stack direction="row">
                {
                  ALL_CATEGORIES.map((cat, cidx) => (
                    <Fragment key={`cat-list-${cidx}`}>
                      <CustomList category={cat as Category} />
                      {cidx < ALL_CATEGORIES.length - 1 && <Divider orientation='vertical' flexItem light />}
                    </Fragment>
                  ))
                }
              </Stack>
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
}

export default CategoryFilters;