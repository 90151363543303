import Header from "@features/Common/Header";
import DeviceList from "@features/inventory/components/DeviceList";
import InventoryProvider from "@features/inventory/components/InventoryProvider";
import { FC } from "react";


const AllDevices: FC = () => {
  return (
    <InventoryProvider>
      <Header />
      <DeviceList />
    </InventoryProvider>
  );
}

export default AllDevices;