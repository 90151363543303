
import { AwsRum } from 'aws-rum-web';
import PackageJson from '../../package.json';

const initRum = () => {
  try {
    if (process.env.REACT_APP_CLOUDWATCH_APP_ID && process.env.REACT_APP_AWS_REGION) {
      const rum = new AwsRum(
        process.env.REACT_APP_CLOUDWATCH_APP_ID,
        PackageJson.version,
        process.env.REACT_APP_AWS_REGION,
        {
          allowCookies: true,
          sessionSampleRate: 1,
          guestRoleArn: process.env.REACT_APP_CLOUDWATCH_GUEST_ROLE_ARN,
          identityPoolId: process.env.REACT_APP_COGNITO_IDENTITYPOOL_ID,
          enableXRay: true,
          telemetries: [ 
            'errors', 
            'performance',
            [ 'http', { addXRayTraceIdHeader: true} ],
          ]
        }
      );
      return rum;
    }
  } catch (error) {
    console.error('RUM init failed: %o', error);
    return null;
  }
};

export default initRum;
