import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import AlertDialog from "@features/Common/AlertDialog";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { PropertyManifest, PropertyManifestEntry } from "@features/home-manifest/types";
import { Device, Property } from "@features/home/types";
import { ManifestDevice, Sensor } from "@features/plan-manifest/types";
import { useUpdatePropertyManifestEntryMutation } from "@features/provisioning/api";
import { pollingInterval } from "@features/tenant-selector/types";
import { Category } from "@lib/labels";
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Box, Button, Container, MobileStepper, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import AllConfirmed from "./AllConfirmed";
import DevicesToConfirm from "./DevicesToConfirm";
import NotAllConfirmed from "./NotAllConfirmed";

/** How recently a device reading must be in order to confirm the device, in milliseconds */
const MAX_DEVICE_READING_DELAY = 1000 * 60 * 60 * 4;

type Props = {
  manifest?: PropertyManifest;
  refetchManifest: () => void;
  devices?: Device[];
  refetchDevices: () => void;
};

const CommissionDevices = ({
  manifest,
  refetchManifest,
  devices,
  refetchDevices,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const property: Property = useAppSelector((state: RootState) => state.property);

  const [activeStep, setActiveStep] = useState(0);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  /* Get set of devices at current step */
  const devicesByStep = useMemo(() => {
    return manifest?.manifest_entries
      .filter((e => !(e.device as ManifestDevice)?.sensors?.some(s => s.sensor_category === Category.system)))
      .reduce((acc, entry) => {
        const order = Number(entry.provision_order);
        const devicesAtStep = acc.get(order) || new Set();
        devicesAtStep.add(entry);
        acc.set(order, devicesAtStep);
        return acc;
      }, new Map<number, Set<PropertyManifestEntry>>());
  }, [manifest?.manifest_entries]);

  const steps = useMemo(() => devicesByStep ? Array.from(devicesByStep.values()) : [], [devicesByStep]);
  const totalSteps = useMemo(() => steps.length, [steps.length]);
  const currentManifestDevices = useMemo(() => Array.from((steps[activeStep] || new Set()).values()), [activeStep, steps]);


  const [
    entryToConfirm,
    setEntryToConfirm,
  ] = useState<[PropertyManifestEntry, Sensor, Device] | null>(null);

  const [
    updatePropertyManifestEntry,
  ] = useUpdatePropertyManifestEntryMutation();

  const handleNext = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };


  /* Poll for device refresh */
  useEffect(() => {
    const interval = setInterval(refetchDevices, pollingInterval);
    return () => clearInterval(interval);
  });

  /** Confirm device */
  const confirmDevice = useCallback(async (manifestEntryId: string) => {
    const updatedEntry = await updatePropertyManifestEntry({
      userTenantId: currentTenant?.tenant_id || '',
      propertyId: property?.property_id || '',
      entryId: manifestEntryId,
      body: {
        commissioned: Math.round(Date.now() / 1000).toString(),
      },
    });

    const errorDetails = (updatedEntry as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't confirm device:", {
        key: "device-error",
        content: (
          <ExpandableSnack
            id="device-error"
            message={"Couldn't confirm device:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });

    } else {
      enqueueSnackbar("Confirmed device", {
        variant: "success",
      });

      refetchManifest();
    }
  }, [currentTenant?.tenant_id, enqueueSnackbar, property?.property_id, refetchManifest, updatePropertyManifestEntry]);

  /** Unconfirm device */
  const unconfirmDevice = useCallback(async (manifestEntryId: string) => {
    const updatedEntry = await updatePropertyManifestEntry({
      userTenantId: currentTenant?.tenant_id || '',
      propertyId: property?.property_id || '',
      entryId: manifestEntryId,
      body: {
        commissioned: '',
      },
    });

    const errorDetails = (updatedEntry as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't clear device status:", {
        key: "device-error",
        content: (
          <ExpandableSnack
            id="device-error"
            message={"Couldn't clear device status:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });

    } else {
      enqueueSnackbar("Cleared device status", {
        variant: "success",
      });

      refetchManifest();
    }
  }, [currentTenant?.tenant_id, enqueueSnackbar, property?.property_id, refetchManifest, updatePropertyManifestEntry]);

  /* Function to confirm device or warn user if no recent readings are present */
  const attemptConfirmDevice = useCallback((entry: PropertyManifestEntry, sensor: Sensor, device: Device) => {
    const updatedTs = new Date(device.datetime * 1000).getTime();
    const timeSinceUpdate = Date.now() - updatedTs;

    if (timeSinceUpdate > MAX_DEVICE_READING_DELAY) {
      setEntryToConfirm([entry, sensor, device]);
    } else {
      confirmDevice(entry.manifest_entry_id);
    }
  }, [confirmDevice]);

  /* Display Confirmation Screen */
  const renderedConfirmationScreen = useMemo(() => (
    <Box component="div">
      {
        manifest?.manifest_entries
          .every(manifest => manifest.commissioned || (manifest.device as ManifestDevice).sensors
            ?.some(s => s.sensor_category === Category.system))
          ? <AllConfirmed />
          : <NotAllConfirmed />
      }
    </Box>
  ), [manifest?.manifest_entries]);


  /* Displays progress spinner if manifest or devices are not ready*/
  if (!manifest?.manifest_entries?.length) {
    return (
      <Container sx={{ mt: 1 }}>
        <Stack direction="row" spacing={2} sx={{ height: '10em' }}>
          <Box
            component="div"
            sx={{
              width: '100%',
              display: 'flex',
            }}
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h6">This home doesn't have any manifest entries</Typography>
          </Box>
        </Stack>
      </Container>);
  }

  const confirmationMessage = [
    'No readings have been received for the selected device recently.',
    'Are you sure you want to confirm this device?',
  ].join(' ');

  return (
    <Container>
      <Box component="div" m={0}>
        <Box component="div" p={1}>
          {
            activeStep === steps.length
              ? renderedConfirmationScreen
              : (
                <DevicesToConfirm
                  entries={currentManifestDevices}
                  devices={devices || []}
                  onConfirm={attemptConfirmDevice}
                  onUnconfirm={unconfirmDevice}
                />
              )
          }
        </Box>
        <Box
          component="div"
          display="flex"
          justifyContent="center"
        >
          <MobileStepper
            variant="progress"
            steps={totalSteps + 1}
            position="static"
            activeStep={activeStep}
            sx={{ maxWidth: 400, flexGrow: 1 }}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === totalSteps}
              >
                Next
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          />
        </Box>
      </Box >
      {
        entryToConfirm &&
        <AlertDialog
          cancelButtonLabel="Cancel"
          confirmButtonLabel="Proceed"
          onCancel={() => setEntryToConfirm(null)}
          onConfirm={() => {
            confirmDevice(entryToConfirm[0].manifest_entry_id)
              .then(() => {
                setEntryToConfirm(null);
              })
          }}
          onClose={() => {
            setEntryToConfirm(null);
          }}
          title="Warning"
          content={confirmationMessage}
        />
      }
    </Container >
  );
}

export default CommissionDevices;