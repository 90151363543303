import { getHomeAreas } from "@features/device/utils";
import PlanManifestSelector from "@features/plan-manifest/components/PlanManifestSelector";
import { ManifestEntry, PlanManifest as PlanManifestType } from "@features/plan-manifest/types";
import { LocationSummary } from "@features/provisioning-request/types";
import AddIcon from '@mui/icons-material/Add';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import PlanManifestHomeArea from "./PlanManifestHomeArea";

/** checks if manifest is defined and returns manifest entries */
const getManifestEntries = (manifest: PlanManifestType|undefined) => {
  if (!manifest) return [];
  return [...manifest?.manifest_entries];
}

const findManifest = (manifests: PlanManifestType[], manifestId: string) => {
  if (!manifests.length) return undefined;
  if (!manifestId) return undefined;
  return manifests.find(m => m.manifest_id === manifestId);
}

type Props = {
  manifests: PlanManifestType[];
}

const PlanManifest = ({ manifests }: Props) => {
  const f = useFormikContext();

  const [manifestId, setManifestId] = useState<string>(manifests?.[0]?.manifest_id);
  const [expandedSections, setExpandedSections] = useState<string[]>([]);
  const [selectedRoom, setSelectedRoom] = useState<string>('');

  const [
    propertyManifest,
    setPropertyManifest,
  ] = useState<ManifestEntry[]>([]);

  const getPropertyManifestEntries = useCallback((mmId: string) => {
    const manifest = findManifest(manifests, mmId);
    if (manifest) {
      setPropertyManifest([...manifest.manifest_entries]);
    }
    f.setFieldValue('manifest_entries', getManifestEntries(manifest));
    if (mmId) f.setFieldValue('manifest_id', mmId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manifests]);


  useEffect(() => {
    if (manifests && manifests.length) {
      getPropertyManifestEntries(manifestId || manifests[0].manifest_id);
    }
  }, [getPropertyManifestEntries, manifestId, manifests]);


  /** Selects manifest and puts its entries on Provisioning request form */
  const handleManifestChange = (e: SyntheticEvent, value: string) => {
    setManifestId(value);
  };


  const handleExpandCollapse = useCallback((section: string) => {
    if (expandedSections.includes(section)) {
      setExpandedSections(expandedSections.filter(s => s !== section));
    } else {
      setExpandedSections([
        ...expandedSections,
        section,
      ]);
    }
  }, [expandedSections]);

  const handleSelectRoom = (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
    setSelectedRoom(newExpanded ? panel : '');
  };

  if (!manifests || !manifests.length) return <></>;

  return (
    <Box component="div" sx={{
      height: '40em',
      overflow: 'hidden',
      width: '100%',
    }}>
      <Accordion
        disableGutters
        expanded={expandedSections.includes('Manifest')}
        onChange={() => handleExpandCollapse('Manifest')}
        sx={{
          border: 'none',
          boxShadow: 'none'
        }}
      >
        <AccordionSummary expandIcon={<AddIcon htmlColor="white"/>} sx={{ bgcolor: 'secondary.main' }}>
          <Typography sx={{ color: 'white' }}>
            Select manifest to start with
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
        {
          manifests?.length && manifestId &&
          <PlanManifestSelector
            options={manifests}
            selected={manifestId}
            onChange={handleManifestChange}
          />
        }
        </AccordionDetails>
      </Accordion>
      <Accordion
        disableGutters
        expanded={expandedSections.includes('Devices')}
        onChange={() => handleExpandCollapse('Devices')}
        sx={{
          border: 'none',
          boxShadow: 'none'
        }}
      >
        <AccordionSummary
          expandIcon={<AddIcon htmlColor="white"/>}
          sx={{
            bgcolor: 'secondary.main',
          }}
        >
          <Typography sx={{ color: 'white' }}>
            Select additional devices
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{
          height: '33em',

        }}>
        {
          getHomeAreas(propertyManifest)
            .map((area: LocationSummary) => (
              <PlanManifestHomeArea
                key={`area-${area.location}`}
                location={area}
                selected={selectedRoom}
                onChange={handleSelectRoom(area.location)}
              />
            ))
        }
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default PlanManifest;