import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Typography } from "@mui/material";


const NotAllConfirmed = () => (
  <Box component="div" textAlign="center" p={5}>
    <Typography color="orange">
      <ErrorOutlineIcon fontSize="large" />
    </Typography>
    <Typography component="p">
      All devices must be confirmed before proceeding.
    </Typography>
  </Box>
);

export default NotAllConfirmed;