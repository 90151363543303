import { PropertyManifestEntry } from "@features/home-manifest/types";
import { Device } from "@features/home/types";
import { ManifestDevice } from "@features/plan-manifest/types";
import { findUnitsLabel } from "@lib/labels";
import { formatDate } from "@lib/utils";
import { Typography } from "@mui/material";
import { useMemo } from "react";

type Props = {
  device: Device;
  entry: PropertyManifestEntry;
}

/* Displays readings from device */
const SensorReadings = ({ device, entry }: Props) => {
  const sensor = useMemo(() => (entry.device as ManifestDevice).sensors
    ?.find(s => entry.sensor_map?.[s.sensor_id ?? s.friendly_name] === device.data.entity_id), [device.data.entity_id, entry.device, entry.sensor_map]);

  return (
    <>
      <Typography variant="subtitle1">
        {
          [
            'Latest reading:',
            `${device.data.value} ${findUnitsLabel(sensor?.units)}`,
            formatDate(device.datetime).fromNow(),
          ].join(' ')
        }
      </Typography>
      {
        Boolean(entry.provisioned) &&
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Provisioned
          {' '}
          {formatDate(entry.provisioned!).fromNow()}
        </Typography>
      }
    </>
  );
};

export default SensorReadings;