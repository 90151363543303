import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { TenantType } from '@features/userTenant/types';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LiveHomeCanvas, { LiveHomeProps } from '@riot/live-home';
import { DeviceAction, NotificationAction } from '@features/home-notifications/types';
import { useDismissMutation, useNotificationsQuery, useReadMutation, useSnoozeMutation, useUserActionMutation } from '@features/home-notifications/api';
import { useSnackbar } from 'notistack';
import ExpandableSnack from '@features/Common/ExpandableSnack';
import { ApiErrorResponse, SnackType } from '@lib/types';
import { getErrorMessage } from '@lib/utils';

/**
 * Landing view for a property.
 * Displays 2.5 live home view.
 * Displays 2 carousel selectors to allow highlight
 * of the live devices based on the area or category
 */
const LiveHome = () => {
  const navigate = useNavigate();
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const weather = useAppSelector((state: RootState) => state.weather);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const property = useAppSelector((state: RootState) => state.property);
  const floorplan = useAppSelector((state: RootState) => state.floorplan);
  const notificationsState = useAppSelector((state: RootState) => state.notifications);

  const fbxUrl = useMemo(() => floorplan?.floorplanUrl.url, [floorplan?.floorplanUrl.url]);

  /* If the user is a homeowner and the floorplan has no model, navigate away */
  const isAdminOrBuilder = useMemo(() => currentTenant && [TenantType.System, TenantType.Builder].includes(currentTenant.tenant_type), [currentTenant]);

  useEffect(() => {
    if (floorplan && !fbxUrl && !isAdminOrBuilder) {
      navigate('details');
    }
  }, [fbxUrl, floorplan, isAdminOrBuilder, navigate]);

  /* Handlers for notification actions */
  const { enqueueSnackbar } = useSnackbar();

  const userTenantId = useMemo(() => currentTenant?.tenant_id, [currentTenant?.tenant_id]);

  const awsUser = useAppSelector((state: RootState) => state.awsUser);
  
  const [
    userAction,
  ] = useUserActionMutation();

  const [
    dismissNotification,
  ] = useDismissMutation();

  const [
    readNotification,
  ] = useReadMutation();

  const [
    snoozeNotification,
  ] = useSnoozeMutation();

  const {
    refetch: refetchNotifications,
  } = useNotificationsQuery({
    userTenantId: currentTenant?.tenant_id || '',
    propertyId: property?.property_id || '',
    username: awsUser.username || '',
  }, {
    skip: !currentTenant?.tenant_id || !property?.property_id,
  });

  const handleDismissNotification: LiveHomeProps['handleDismissNotification'] = useCallback(async (userNotificationId: string) => {
    const result = await dismissNotification({
      userTenantId,
      userNotificationId,
    });

    const errorDetails = (result as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar(`Couldn't dismiss notification:`, {
        key: "notification-dismiss-error",
        content: (
          <ExpandableSnack
            id="notification-dismiss-error"
            message={`Couldn't dismiss notification:`}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("notification dismissed", {
        variant: "success",
      });
      refetchNotifications();
    }
  }, [dismissNotification, enqueueSnackbar, refetchNotifications, userTenantId]);

  const handleNotificationAction: LiveHomeProps['handleNotificationAction'] = useCallback(async (eventId: string, action: NotificationAction) => {
    const result = await userAction({
      userTenantId,
      propertyId: property?.property_id,
      eventId,
      action: action.action as DeviceAction,
    });

    const errorDetails = (result as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar(`Couldn't perform action:`, {
        key: "notification-action-error",
        content: (
          <ExpandableSnack
            id="notification-action-error"
            message={`Couldn't perform action:`}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("Done!", {
        variant: "success",
      });
      refetchNotifications();
    }
  }, [enqueueSnackbar, property?.property_id, refetchNotifications, userAction, userTenantId]);

  const handleReadNotification: LiveHomeProps['handleReadNotification'] = useCallback(async (userNotificationId: string) => {
    await readNotification({
      userTenantId,
      userNotificationId,
    });
  }, [readNotification, userTenantId]);

  const handleSnoozeNotification: LiveHomeProps['handleSnoozeNotification'] = useCallback(async (userNotificationId: string, hours: number) => {
    const result: any = await snoozeNotification({
      userNotificationId,
      userTenantId,
      snoozeHours: hours,
    })

    const errorDetails = (result as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar(`Couldn't snooze notification:`, {
        key: "notifications-snooze-error",
        content: (
          <ExpandableSnack
            id="notifications-snooze-error"
            message={`Couldn't snooze notification:`}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar(`Notification snoozed`, {
        variant: "success",
      });
      refetchNotifications();
    }
  }, [enqueueSnackbar, refetchNotifications, snoozeNotification, userTenantId]);

  if (floorplan && !floorplan.floorplanUrl && isAdminOrBuilder) {
    return <Box component="div" sx={{ width: '50%', textAlign: 'center', position: 'absolute', left: '25%', top: '25%' }}>
      <Typography variant="h2">Floorplan model unavailable</Typography>
        <Typography variant="body1">
        This home's floorplan does not have a model associated with it.
        To enable 3D views for this home, see <Link to="/plans">Floorplans</Link>.
        </Typography>
      </Box>
  }

  if (!fbxUrl) return <CircularProgress />;

  return <Box component="div" sx={{position: 'relative', width: '100%', height: "95vh", marginTop: 0, padding: 0}}>
    <LiveHomeCanvas
      fbxUrl={fbxUrl}
      home={property}
      notifications={notificationsState.notifications ?? []}
      weather={weather}
      preferences={null}
      renderLoadingIndicator={(value) => (<CircularProgress value={value} />)}
      onLoadError={() => navigate('details')}
      handleDismissNotification={handleDismissNotification}
      handleNotificationAction={handleNotificationAction}
      handleReadNotification={handleReadNotification}
      handleSnoozeNotification={handleSnoozeNotification}
    />
  </Box>;
};


export default LiveHome;
