import { ReadingPair } from "@features/home-status/types";
import { Card, CardContent, Grid } from "@mui/material";
import ThermostatCurrentFanMode from "./ThermostatCurrentFanMode";
import ThermostatCurrentHvacMode from "./ThermostatCurrentHvacMode";
import ThermostatCurrentTemperature from "./ThermostatCurrentTemperature";
import ThermostatFanModeMenu from "./ThermostatFanModeMenu";
import ThermostatHvacModeMenu from "./ThermostatHvacModeMenu";
import ThermostatPresetModeMenu from "./ThermostatPresetModeMenu";
import ThermostatTemperatureSetpoint from "./ThermostatTemperatureSetpoint";
import ThermostatTemperatureSetpointAdjust from "./ThermostatTemperatureSetpointAdjust";

type Props = {
  readings: ReadingPair;
}

const Thermostat = ({ readings }: Props) => {

  return (
    <Card variant="outlined">
      <CardContent>
        <Grid
          spacing={2}
          columns={3}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={1} sx={{
            display: 'flex',
            justifyContent: 'center',
          }}>
            <ThermostatTemperatureSetpointAdjust entryId={readings.entryId || ''} down />
          </Grid>
          <Grid item xs={1} sx={{
            display: 'flex',
            justifyContent: 'center',
          }}>
            <ThermostatTemperatureSetpoint entryId={readings.entryId || ''} />
          </Grid>
          <Grid item xs={1} sx={{
            display: 'flex',
            justifyContent: 'center',
          }}>
            <ThermostatTemperatureSetpointAdjust entryId={readings.entryId || ''} />
          </Grid>

          <Grid item xs={1} sx={{
            display: 'flex',
            justifyContent: 'center',
          }}>
            <ThermostatCurrentHvacMode entryId={readings.entryId || ''} />
          </Grid>
          <Grid item xs={1} sx={{
            display: 'flex',
            justifyContent: 'center',
          }}>
            <ThermostatCurrentTemperature entryId={readings.entryId || ''} />
          </Grid>
          <Grid item xs={1} sx={{
            display: 'flex',
            justifyContent: 'center',
          }}>
            <ThermostatCurrentFanMode entryId={readings.entryId || ''} />
          </Grid>
          <Grid item xs={1} sx={{
            display: 'flex',
            justifyContent: 'center',
          }}>
            <ThermostatHvacModeMenu entryId={readings.entryId || ''} />
          </Grid>
          <Grid item xs={1} sx={{
            display: 'flex',
            justifyContent: 'center',
          }}>
            <ThermostatPresetModeMenu entryId={readings.entryId || ''} />
          </Grid>
          <Grid item xs={1} sx={{
            display: 'flex',
            justifyContent: 'center',
          }}>
            <ThermostatFanModeMenu entryId={readings.entryId || ''} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Thermostat;