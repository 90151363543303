import { createSlice } from "@reduxjs/toolkit";

const initialState: string[] = [];

export const zipCodesReducer = createSlice({
  name: 'zipCodes',
  initialState,
  reducers: {
    setZips: (state: string[], action: any) => {
      state.splice(0, state.length);
      if (action.payload) {
        action.payload?.forEach((zip: string) => {
          state.push(zip);
        });
      }
    }
  }
});

export default zipCodesReducer.reducer;

export const {
  setZips,
} = zipCodesReducer.actions;