import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import HomeSupportCasesProvider from '@features/home-support-cases/components/HomeCasesProvider';
import HomeSupportCases from '@features/home-support-cases/components/HomeSupportCases';
import { Box, Typography } from '@mui/material';


const HomeCases = () => {
  const property = useAppSelector((state: RootState) => state.property);

  return (
    <Box
        component="div"
        sx={{
          margin: '1em',
          padding: '2em',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            mb: '3em'
          }}
        >
          Support cases for {property.name}
        </Typography>
        <HomeSupportCasesProvider>
          <HomeSupportCases />
        </HomeSupportCasesProvider>
      </Box>
  );
}

export default HomeCases;