import { useAppDispatch, useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { useUtilgroupQuery } from '@features/all-utility-groups/api';
import { pollingInterval } from '@features/tenant-selector/types';
import { FC, ReactElement, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { setUtilityGroup } from '../slice';

const UtilityGroupProvider: FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const pathParams = useParams();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const groupId = useMemo(() => pathParams?.group_id || '', [pathParams?.group_id]);

  const {
    currentData: utilityGroup,
  } = useUtilgroupQuery({
    userTenantId: currentTenant?.tenant_id || '',
    groupId,
    builderId: currentTenant?.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id,
    pollingInterval,
  });

  useEffect(() => {
    if (utilityGroup && utilityGroup.group_id) {
      dispatch(setUtilityGroup(utilityGroup));
    } else {
      dispatch(setUtilityGroup(null));
    }
  }, [dispatch, utilityGroup]);

  return children as ReactElement;

}

export default UtilityGroupProvider;