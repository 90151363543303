import { useAppDispatch, useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { FC, ReactElement, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useHealthMutation } from '../api';
import { setHealth, setLoadingHealth } from '../slice';
import ExpandableSnack from '@features/Common/ExpandableSnack';
import { SnackType } from '@lib/types';
import { getErrorMessage } from '@lib/utils';
import { useSnackbar } from 'notistack';


const NeighborhoodHealthProvider: FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const pathParams = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const neighborhoodId = useMemo(() => pathParams?.neighborhood_id || '', [pathParams?.neighborhood_id]);

  const [getHealth] = useHealthMutation();

  useEffect(() => {
    dispatch(setLoadingHealth(true));

    if (!currentTenant?.tenant_id || !neighborhoodId) {
      dispatch(setHealth(undefined));
      dispatch(setLoadingHealth(false));
    } else {
      getHealth({
        userTenantId: currentTenant.tenant_id,
        neighborhoodId: neighborhoodId,
      }).then(res => {
        if ('error' in res) {
          enqueueSnackbar("Couldn't get neighborhood health:", {
            key: "neighborhood-health-error",
            content: (
              <ExpandableSnack
                id="neighborhood-health-error"
                message={"Couldn't get neighborhood health:"}
                variant={SnackType.error}
                detail={getErrorMessage(res.error)}
              />),
          });
        } else {
          dispatch(setHealth(res.data));
        }
      }).finally(() => {
        dispatch(setLoadingHealth(false));
      });
    }
  }, [currentTenant.tenant_id, dispatch, enqueueSnackbar, getHealth, neighborhoodId]);

  return children as ReactElement;
}

export default NeighborhoodHealthProvider;