import AddressWithLabel from "@features/Address/AddressWithLabel";
import { getHomeAreas } from "@features/device/utils";
import { Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import {
  LocationSummary,
  ProvisioningRequestForm
} from "../types";
import AreaSummary from './AreaSummary';

type Props = {
  formData: Partial<ProvisioningRequestForm>;
}

const OrderReview = ({ formData }: Props) => {
  const manifestEntries = useMemo(() => formData.manifest_entries || [], [formData.manifest_entries]);
  const physicalAddress = useMemo(() => formData.physical_address, [formData.physical_address]);
  const shippingAddress = useMemo(() => formData.shipping_address, [formData.shipping_address]);
  const locations = useMemo(() => getHomeAreas(manifestEntries), [manifestEntries]);

  return (
    <>
      <Stack direction="row" justifyContent="space-around" sx={{ width: '100%' }}>
        <AddressWithLabel
          address={physicalAddress}
          label="Home Details"
        />
        <AddressWithLabel
          address={shippingAddress}
          label="Shipping Details"
        />
      </Stack>
      <Stack sx={{ width: '100%' }}>
        <Typography variant='h6' padding={'1em'}>
          RIoT Configuration
        </Typography>
        <Stack sx={{
          overflowY: 'auto',
          height: '28em',
        }}>
          {
            locations.map((location: LocationSummary, idx: number) => (
              <AreaSummary key={`areasummary-${idx}`} location={location} />))
          }
        </Stack>
      </Stack>
    </>
  );
}

export default OrderReview;