import { riotApi } from "@features/aws-user/api";
import { U } from "@lib/utils";
import { OemDevice, OemDeviceHistoricalStatus } from "./types";

export const homeOemApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      homeOemDevices: builder.query<OemDevice[], {
        userTenantId: string,
        propertyId: string,
      }>({
        query: ({ userTenantId, propertyId }) => ({
          url: `tenant/${U(userTenantId)}/oem/property/${U(propertyId)}`,
        }),
      }),
      oemDeviceHistory: builder.query<OemDeviceHistoricalStatus[], {
        userTenantId: string,
        serialNumber: string,
        startDate: string,
      }>({
        query: ({ userTenantId, serialNumber, startDate }) => ({
          url: `tenant/${U(userTenantId)}/oem/history`,
          params: {
            serial_numbers: serialNumber,
            start: startDate,
          }
        }),
      }),
    };
  }
});

export const {
  useHomeOemDevicesQuery,
  useOemDeviceHistoryQuery,
} = homeOemApi;