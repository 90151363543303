import { getHeaderStyles, MIN_CELL } from "@features/Common/tables";
import { SupportCase } from "@features/support-case/types";
import { formatDate } from "@lib/utils";
import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Avatar, Tooltip } from "@mui/material";
import { SUCCESS, WARNING } from "theme";

export const getColumns = (
  data: SupportCase[],
  themeColor: string,
) => [
  {
    name: 'username',
    label: 'Username',
  },
  {
    name: '',
    label: 'Name',
    options: {
      setCellProps: MIN_CELL,
      setCellHeaderProps: () => getHeaderStyles(themeColor),
      customBodyRenderLite: (idx: number) => `${data[idx].given_name} ${data[idx].family_name}`,
    },
  },
  {
    name: 'issue_summary',
    label: 'Issue summary',
    options: {
      setCellProps: () => ({
        style: {
          width: '30em',
        }
      }),
      setCellHeaderProps: () => getHeaderStyles(themeColor),
    },
  },
  {
    name: 'created',
    label: 'Created',
    options: {
      filter: false,
      search: false,
      setCellProps: MIN_CELL,
      setCellHeaderProps: () => getHeaderStyles(themeColor),
      customBodyRenderLite: (idx: number) => !!data[idx].created ? formatDate(data[idx].created)?.fromNow() || '' : '',
    },
  },
  {
    name: 'updated',
    label: 'Last updated',
    options: {
      filter: false,
      search: false,
      setCellProps: MIN_CELL,
      setCellHeaderProps: () => getHeaderStyles(themeColor),
      customBodyRenderLite: (idx: number) => !!data[idx].updated ? formatDate(data[idx].updated)?.fromNow() || '' : '',
    },
  },
  {
    name: '',
    label: 'Notes',
    options: {
      filter: false,
      search: false,
      customBodyRenderLite: (idx: number) => (
        <Tooltip arrow placement="top" title="Number of notes">
          <Avatar sx={{ width: 24, height: 24, bgcolor: themeColor }}>{data[idx].notes?.length}</Avatar>
        </Tooltip>
        ),
    }
  },
  {
    name: '',
    label: 'Devices',
    options: {
      filter: false,
      search: false,
      customBodyRenderLite: (idx: number) => (
        <Tooltip arrow placement="top" title="Number of related devices">
          <Avatar sx={{ width: 24, height: 24, bgcolor: themeColor }}>{data[idx].manifest_entry_ids?.length}</Avatar>
        </Tooltip>
        ),
    }
  },
  {
    name: '',
    label: 'Screenshots',
    options: {
      filter: false,
      search: false,
      customBodyRenderLite: (idx: number) => (
        <Tooltip arrow placement="top" title="Number of attached screenshots">
          <Avatar sx={{ width: 24, height: 24, bgcolor: themeColor }}>{data[idx].screenshots?.length}</Avatar>
        </Tooltip>
        ),
    }
  },
  {
    name: 'resolved',
    label: 'Status',
    options: {
      filter: false,
      search: false,
      setCellProps: () => ({
        style: {
          textAlign: 'center'
        }
      }),
      customBodyRenderLite: (idx: number) => (
        data[idx]?.resolved
          ? <Tooltip placement="top" arrow title="Case is resolved">
              <Avatar sx={{ width: 24, height: 24, bgcolor: SUCCESS }}>
                <CheckIcon fontSize="small" />
              </Avatar>
            </Tooltip>
          : <Tooltip placement="top" arrow title="Case is open and requires attention">
              <Avatar sx={{ width: 24, height: 24, bgcolor: WARNING }}>
                <PriorityHighIcon fontSize="small" />
              </Avatar>
            </Tooltip>
      )
    }
  },
]