import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import HomeEnergy from '@features/home-energy/components/HomeEnergy';
import { Box, Typography } from '@mui/material';

const HomeEnergyView = () => {
  const property = useAppSelector((state: RootState) => state.property);

  /** MAIN RENDER */
  return (
    <Box
      component="div"
      sx={{
        margin: '1em',
        padding: '2em',
      }}
    >
      <Typography variant="h4" sx={{ mb: 6 }}>
        Energy Usage at {property?.name}
      </Typography>
      <HomeEnergy />
    </Box>
  );

}

export default HomeEnergyView;