import config from "@lib/config";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { Auth } from "aws-amplify";

export const riotApi = createApi({
  reducerPath: 'riotApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_ENDPOINT,
    prepareHeaders: async (headers) => {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.getSignInUserSession()?.getIdToken().getJwtToken();
      headers.set('authorization', `Bearer ${token}`);
      return headers;
    }
  }),
  refetchOnMountOrArgChange: 15,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: () => {
    return {
      /**
       * base query does not implement endpoints.
       * endpoints are added by injection in the respective api slices.
       */
    };
  },
})