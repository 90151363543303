import { List, ListItem, ListItemText } from "@mui/material";

type Props = {
  device: 'gateway' | 'kiosk';
}

const ProvisioningToolInstructions = ({ device }: Props) => {

  return (
    <List sx={{ pl: '2em' }}>
      <ListItem
        key="1"
        disablePadding
      >
        <ListItemText>
          1. Open the RIoT Provisioning Tool.
        </ListItemText>
      </ListItem>
      <ListItem
        key="2"
        disablePadding
      >
        <ListItemText>
          2. On the top navigation bar, select { device === 'gateway' ? "Gateway" : "Kiosk"}.
        </ListItemText>
      </ListItem>
      {
        device === 'gateway' &&
        <ListItem
          key="3"
          disablePadding
        >
          <ListItemText>
            3. Enter the Gateway provisioning details in the form.
          </ListItemText>
        </ListItem>
      }
      <ListItem
        key={device === 'gateway' ? "4" : "3"}
        disablePadding
      >
        <ListItemText>
         {device === 'gateway' ? "4" : "3"}. Press the "Provision" button and wait for the process to complete.
          </ListItemText>
      </ListItem>
    </List>
  );
}

export default ProvisioningToolInstructions;