import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Typography } from '@mui/material';

/* Display Warning that not all devices are paired */
const NotAllPaired = () => (
  <Box component="div" textAlign="center" p={5}>
    <Typography color="orange">
      <ErrorOutlineIcon fontSize="large" />
    </Typography>
    <Typography component="p">
      All devices must be paired before proceeding.
    </Typography>
  </Box>
);

export default NotAllPaired;