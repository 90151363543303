import { PropertyManifestEntryUpdate } from "@features/home-manifest/types";
import InventoryProvider from "@features/inventory/components/InventoryProvider";
import { Stack, TextField } from "@mui/material";
import { useCallback, useMemo } from "react";
import { ManifestEntry, ManifestEntryCreate } from "../types";
import DeviceSelector from "./DeviceSelector";
import ManifestEntryPosition from "./ManifestEntryPosition";


type Props = {
  existingEntry?: Partial<ManifestEntryCreate|ManifestEntry>;
  onChange: (entry: Partial<ManifestEntryCreate>) => void;
};


const ManifestEntryFields = ({ existingEntry, onChange }: Props) => {

  const handleChangePosition = useCallback((entry: Partial<ManifestEntryCreate|ManifestEntry>|PropertyManifestEntryUpdate) => {
    onChange({
      ...entry,
      provision_order: existingEntry?.provision_order || '0',
    } as ManifestEntryCreate);
  }, [existingEntry?.provision_order, onChange]);

  const handleSelectDevice = useCallback((deviceId: string|null) => {
    deviceId && onChange({
      provision_order: existingEntry?.provision_order || '0',
      device: deviceId,
    } as ManifestEntryCreate);
  }, [existingEntry?.provision_order, onChange]);

  const selectedDeviceId = useMemo(() => {
    if (typeof existingEntry?.device === 'string') {
      return existingEntry?.device || '';
    }
    return (existingEntry as Partial<ManifestEntry>).device?.device_id || '';
  }, [existingEntry]);

  return (
    <Stack spacing={1}>
      <TextField
        variant="outlined"
        label="Provision order"
        type="number"
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]+' }}
        disabled
        value={existingEntry?.provision_order || '0'}
        size="small"
      />
      <InventoryProvider>
        <DeviceSelector
          onChange={handleSelectDevice}
          selected={selectedDeviceId}
          compact
        />
      </InventoryProvider>
      <ManifestEntryPosition
        onChange={handleChangePosition}
        existingPosition={{
          property_area: existingEntry?.property_area,
          coordinates: existingEntry?.coordinates,
        } as Partial<ManifestEntryCreate>}
      />
    </Stack>
  );
}

export default ManifestEntryFields;