import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#46bce6',
      contrastText: 'rgba(251,248,248,0.87)',
    },
    secondary: {
      main: '#56bee3',
      dark: '#187b9e',
      light: '#7ac4df',
    },
    warning: {
      main: 'rgb(200, 120, 40)',
    },
    error: {
      main: '#960000',
    },
  }
});

export default theme;