import { useAppDispatch, useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { useGetDevicesQuery, useGetPropertyManifestQuery, useGetPropertyQuery } from '@features/home/api';
import { setDevices, setManifest, setProperty } from '@features/home/slice';
import { pollingInterval } from '@features/tenant-selector/types';
import { Box, CircularProgress } from '@mui/material';
import { FC, ReactElement, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

/** A wrapper for Property related data such as devices and manifest */
const HomeProvider: FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const pathParams = useParams();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const propertyId: string = useMemo(() => pathParams?.property_id || '', [pathParams?.property_id]);

  const {
    currentData: property,
  } = useGetPropertyQuery({
    tenantId: currentTenant?.tenant_id || '',
    propertyId,
  }, {
    skip: !currentTenant?.tenant_id || !propertyId,
  });

  const {
    currentData: propertyDevices,
  } = useGetDevicesQuery({
    tenantId: currentTenant?.tenant_id || '',
    propertyId,
  }, {
    skip: !currentTenant?.tenant_id || !propertyId,
    pollingInterval,
  });

  const {
    currentData: manifest,
  } = useGetPropertyManifestQuery({
    tenantId: currentTenant?.tenant_id || '',
    propertyId,
    includeEntries: true,
  }, {
    skip: !currentTenant?.tenant_id || !propertyId,
    pollingInterval,
  });


  // set state items
  useEffect(() => {
    if (property && property.property_id) {
      dispatch(setProperty(property));
      if (propertyDevices) dispatch(
        setDevices([
          ...propertyDevices
        ].sort(
          (a, b) => b.datetime - a.datetime) /* Sort events by date desc */
        )
      );
      if (manifest?.manifest_entries) {
        dispatch(setManifest(manifest));
        dispatch(setProperty({
          ...property,
        }));
      }
    }
  }, [property, manifest, dispatch, propertyDevices]);

  useEffect(() => {
    if (propertyId !== property?.property_id) {
      dispatch(setProperty(null));
    }
  }, [dispatch, propertyId, property]);

  if (propertyId && (!property?.property_id || property?.property_id !== propertyId)) {
    return (
      <Box
        component="div"
        sx={{
          display: 'flex',
          width: '95em',
          height: '55em',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <CircularProgress />
      </Box>
    );
  }
  return children as ReactElement;
}


export default HomeProvider;
