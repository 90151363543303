import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import CategoryReadingIcon from "@features/home-status/components/CategoryReadingIcon";
import { ReadingPair } from "@features/home-status/types";
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { RIOT_BLUE } from 'theme';

type Props = {
  reading?: ReadingPair;
}

/**
 * Current status of a single device
 */
const DeviceInfo = ({ reading }: Props) => {

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant?.builder_color || RIOT_BLUE, [currentTenant?.builder_color]);
  const device = useMemo(() => reading?.device || undefined, [reading?.device]);
  const sensor = useMemo(() => reading?.sensor || undefined, [reading?.sensor]);

  if (!device || !sensor) return <></>;

  /** MAIN RENDER */
  return (
    <Box
        component="div"
        sx={{
          position: 'relative',
          border: `1px solid ${themeColor}`,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-around',
          borderRadius: 2,
          overflow: 'clip',
          textAlign: 'center',
          height: '3em',
          width: '8em'
        }}
      >
        <CategoryReadingIcon
          category={sensor?.sensor_category}
          measure={sensor?.measure_name}
          readings={reading}
        />
      </Box>
  );
}

export default DeviceInfo;
