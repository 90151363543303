import { Sensor } from '@features/plan-manifest/types';
import { formatReadingDetails } from '@lib/labels';

type Props = {
  sensor: Sensor,
}

const SensorIcon = ({ sensor }: Props) => {
  const details = formatReadingDetails(
    sensor.sensor_category,
    sensor.measure_name,
    sensor.units,
    true,
  );

  return <details.icon />;
}

export default SensorIcon;