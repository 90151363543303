import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import HomesProvider from '@features/all-homes/components/HomesProvider';
import AdminTable from '@features/Common/AdminTable';
import ExpandableSnack from '@features/Common/ExpandableSnack';
import UtilityGroupFields from '@features/utility-group/components/UtilityGroupFields';
import { ApiErrorResponse, SnackType } from '@lib/types';
import { formatDate, getErrorMessage, U } from '@lib/utils';
import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { useSnackbar } from "notistack";
import { useCallback, useMemo } from 'react';
import { useCreateUtilGroupMutation, useDeleteUtilGroupMutation, useUpdateUtilGroupHomesMutation, useUtilgroupsQuery } from '../api';
import { TYPICAL_MAX_SERVICE_POWER, TYPICAL_RESIDENTIAL_VOLTAGE, UtilGroup, UtilGroupCreate, UtilGroupPropertyUpdate } from '../types';


const UtilityGroupList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const utilGroups = useAppSelector((state: RootState) => state.utilgroups);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const  currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const {
    refetch: refetchUtilGroups,
  } = useUtilgroupsQuery({
    userTenantId: currentTenant?.tenant_id || '',
    builderId: currentTenant?.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id,
  });

  const [
    createUtilGroup,
  ] = useCreateUtilGroupMutation();

  const [
    deleteUtilGroup,
  ] = useDeleteUtilGroupMutation();

  const [
    updateUtilGroupHomes,
  ] = useUpdateUtilGroupHomesMutation();

  const handleSaveUtilGroup = useCallback(async (row: Partial<UtilGroup & { name: string; }>) => {
    /** new utility group */
    if (!row.group_id) {
      const newUtilGroup = await createUtilGroup({
        userTenantId: currentTenant?.tenant_id || '',
        utilGroup: row as UtilGroupCreate,
      });

      const errorDetails = (newUtilGroup as ApiErrorResponse)?.error;

      if (errorDetails) {
        enqueueSnackbar("Couldn't create utility group:", {
          key: "utilgroup-error",
          content: (
            <ExpandableSnack
              id="utilgroup-error"
              message={"Couldn't create utility group:"}
              variant={SnackType.error}
              detail={getErrorMessage(errorDetails)}
            />),
        });
      } else {
        enqueueSnackbar("Created utility group", {
          variant: "success",
        });
      }

      refetchUtilGroups();
      return (newUtilGroup as any).data;
    }
    /** update existing group */
    const currentHomes = utilGroups.find(ug => ug.group_id === row.group_id)?.property_ids || [];
    const toAdd = row.property_ids?.filter(h => !currentHomes?.includes(h));
    const toRemove = row.property_ids?.filter(h => currentHomes?.includes(h));

    const updatedUtilGroup = await updateUtilGroupHomes({
      userTenantId: userTenant?.tenant_id || '',
      groupId: row?.group_id || '',
      builderId: currentTenant?.tenant_id || '',
      updates: {
        add_properties: toAdd,
        remove_properties: toRemove,
      } as UtilGroupPropertyUpdate,
    });

    const errorDetails = (updatedUtilGroup as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't update utility group:", {
        key: "utilgroup-error",
        content: (
          <ExpandableSnack
            id="utilgroup-error"
            message={"Couldn't update utility group:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("Updated utility group", {
        variant: "success",
      });
    }

    refetchUtilGroups();
    return updatedUtilGroup;
  }, [createUtilGroup, currentTenant?.tenant_id, enqueueSnackbar, refetchUtilGroups, updateUtilGroupHomes, userTenant?.tenant_id, utilGroups]);

  const handleDeleteUtilGroup = useCallback(async (row: UtilGroup & { name: string; }) => {
    const deletedUtilGroup = await deleteUtilGroup({
      userTenantId: currentTenant?.tenant_id || '',
      groupId: row.group_id,
      builderId: row.builder_id,
    });

    const errorDetails = (deletedUtilGroup as ApiErrorResponse).error;
      if (errorDetails) {
        enqueueSnackbar("Couldn't delete utility group:", {
          key: "utilgroup-error",
          content: (
            <ExpandableSnack
              id="utilgroup-error"
              message={"Couldn't delete utility group:"}
              variant={SnackType.error}
              detail={getErrorMessage(errorDetails)}
            />),
        });
        return false;
      } else {
        enqueueSnackbar("Deleted utility group", {
          variant: "success",
        });
        return true;
      }
  }, [currentTenant?.tenant_id, deleteUtilGroup, enqueueSnackbar]);

  const renderedTable = useMemo(() => {
    if (!currentTenant?.tenant_id) return <Backdrop open><CircularProgress /></Backdrop>;

    const options: MUIDataTableOptions = {
      enableNestedDataAccess: '.',
    };

    const columns: MUIDataTableColumn[] = [
      {
        name: 'group_id',
        label: 'Group name',
      },
      {
        name: 'created',
        label: 'Created',
        options: {
          customBodyRenderLite: (idx) => !!utilGroups[idx].created ? formatDate(utilGroups[idx].created)?.format('YYYY-MM-DD') || '' : '',
        }
      }
    ];

    return <>
      <AdminTable<UtilGroup & { name: string }>
        title="Utility Groups"
        label="Utility Group"
        id_key="group_id"
        name_key="group_id"
        columns={columns}
        options={options}
        data={utilGroups}
        makeNew={() => ({
          builder_id: currentTenant?.tenant_id || '',
          property_ids: [],
          utility_management: {
            energy_management: {
              svc_max_power: {
                amps: TYPICAL_MAX_SERVICE_POWER,
                volts: TYPICAL_RESIDENTIAL_VOLTAGE,
              }
            }
          }
        })}
        makeViewPath={(ug: UtilGroup) => `/utility-groups/${U(ug.group_id)}`}
        onSave={handleSaveUtilGroup}
        onDelete={handleDeleteUtilGroup}
        refresh={refetchUtilGroups}
        editPermission="manage-properties"
        deletePermission="delete-properties"
      >
        <HomesProvider>
          <UtilityGroupFields />
        </HomesProvider>
      </AdminTable>
    </>;
  }, [currentTenant?.tenant_id, handleDeleteUtilGroup, handleSaveUtilGroup, refetchUtilGroups, utilGroups]);

  return (
    <Box
      component="div"
      sx={{
        margin: '1em',
        padding: '2em',
      }}
    >
      <Typography variant="h4">{currentTenant.name}</Typography>
      <Typography variant="h5">Utility Groups</Typography>
      <Box
        component="div"
        sx={{
          mt: '3em',
        }}
      >
        {renderedTable}
      </Box>
    </Box>
  );
}

export default UtilityGroupList;