import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import { Box, Button, ButtonGroup, IconButton, Popover, Tooltip } from "@mui/material";
import { useCallback, useState } from "react";
import { UserNotification } from "../types";

type Props = {
  notification: UserNotification;
  handleSnooze: (hours: number) => Promise<void>;
}

const SnoozeIntervals = {
  1: '1 hr',
  4: '4 hr',
  24: '1 day',
  168: '1 wk',
} as const;

/** Display a popover with Snooze summary */
const SnoozeButton = ({ notification, handleSnooze }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const doSnooze = useCallback(async (hours: number) => {
    setLoading(true);

    try {
      await handleSnooze(hours);
      setOpen(false);
    } finally {
      setLoading(false);
    }
  }, [handleSnooze]);

  return (
    <>
      <Tooltip title="Snooze" placement='top'>
        <span>
          <IconButton
            disabled={loading || !notification.snoozable}
            onClick={(e) => {
              setOpen(true);
              setAnchorElement(e.currentTarget);
            }}
          >
            <NotificationsPausedIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <Box
          component="div"
          sx={{
            p: 0,
            backgroundColor: 'transparent',
            border: 'none',
          }}
        >
          <ButtonGroup variant="text" size="small" color="secondary" disabled={loading}>
            {
              Object.entries(SnoozeIntervals).map(([snoozeHours, label]) => (
                <Button
                  key={`snooze-${snoozeHours}`}
                  onClick={() => doSnooze(Number(snoozeHours))}
                >
                  {label}
                </Button>
              ))
            }
          </ButtonGroup>
        </Box>
      </Popover>
    </>);
}


export default SnoozeButton;
