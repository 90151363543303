import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { HealthCheckLabels, HealthCheckType } from "@features/home-health/types";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ListItem, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { useMemo } from "react";
import { NeighborhoodBase } from "../types";
import moment from "moment";
import { formatDate } from "@lib/utils";

type Props = {
  health: NeighborhoodBase['health_status'];
  homeId: string;
  healthCheckType: HealthCheckType;
}

const HealthCheckItem = ({ health, homeId, healthCheckType }: Props) => {
  const homes = useAppSelector((state: RootState) => state.homes);
  const healthcheckResult = useMemo(() => health?.[homeId!]?.checks?.[healthCheckType], [health, healthCheckType, homeId]);
  const homeName = useMemo(() => {
    const match = homes.find(h => h.property_id === homeId);
    if (!match) return null;
    return match.name || [match.physical_address.address_line_1, match.physical_address.address_line_2].join('-');
  }, [homeId, homes]);

  const tooltipTitle = useMemo(() => {
    if (healthcheckResult) return 'Healthcheck passed successfully!';
    if (healthcheckResult === false) return HealthCheckLabels[healthCheckType].bad;
    return 'The home does not support this healthcheck';
  }, [healthCheckType, healthcheckResult])

  const iconColor = useMemo(() => {
    if (healthcheckResult) return 'success';
    if (healthcheckResult === false) return 'error';
    return 'disabled';
  }, [healthcheckResult]);

  if (homeName === null) return <></>;

  return (
    <ListItem
      sx={{
      opacity: healthcheckResult === null ? 0.3 : 1,
    }}>
      <ListItemText>{HealthCheckLabels[healthCheckType]?.good ?? ''}</ListItemText>
      <Tooltip
        title={tooltipTitle}
        placement="top"
        arrow
      >
        <ListItemIcon sx={{ m: 0, minWidth: 'fit-content' }}>
          {
            healthcheckResult
              ? <CheckCircleIcon color={iconColor} />
              : <CancelIcon color={iconColor} />
          }
        </ListItemIcon>
      </Tooltip>
    </ListItem>
  );
}

export default HealthCheckItem;