import EmbeddedDashboard from "@features/analytics/EmbeddedDashboard";
import Header from "@features/Common/Header";
import { FC } from "react";

/** Wrapper for /analytics */
const Analytics: FC = () => {
  return (
    <>
      <Header />
      <EmbeddedDashboard />
    </>
  );
}
export default Analytics;