import { Address } from "@features/home/types";
import { Stack, Typography } from "@mui/material";
import AddressSummary from "./AddressSummary";


type Props = {
  address?: Address;
  label: string;
}

const AddressWithLabel = ({ address, label }: Props) => (
  <Stack>
    <Typography variant='h6'>{label}</Typography>
    <AddressSummary address={address} />
  </Stack>
);

export default AddressWithLabel;