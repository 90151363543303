import { riotApi } from "@features/aws-user/api";
import { HTTPMethodType } from '@lib/types';
import { U } from "@lib/utils";
import { HealthCheckResult, HealthCheckType } from './types';


export const homeHealthApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      /** Perform health check on home and return the result */
      healthCheckHome: builder.mutation<HealthCheckResult, {
        userTenantId: string,
        propertyId: string,
        healthCheckType: HealthCheckType,
      }>({
        query: ({ userTenantId, propertyId, healthCheckType }) => ({
          url: `/tenant/${U(userTenantId)}/properties/${U(propertyId)}/healthcheck/${U(healthCheckType)}`,
          method: HTTPMethodType.POST,
        }),
      })
    };
  },
});

export const {
  useHealthCheckHomeMutation,
} = homeHealthApi;
