import HomeHealth from "@features/home-health/components/HomeHealth";

const HomeHealthView = () => {

  return (
    <>
      <HomeHealth />
    </>
  );
}
export default HomeHealthView;
