import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import HomeEvse from '@features/home-evse/components/HomeEvse';
import { Box, Typography } from '@mui/material';

const HomeEvseView = () => {
  const property = useAppSelector((state: RootState) => state.property);

  /** MAIN RENDER */
  return (
    <Box
      component="div"
      sx={{
        margin: '1em',
        padding: '2em',
      }}
    >
      <Typography variant="h4" sx={{ mb: 6 }}>
        EV Service Equipment at {property?.name}
      </Typography>
      <HomeEvse />
    </Box>
  );

}

export default HomeEvseView;