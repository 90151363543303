import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { useGetPropertyQuery, useUploadHomePhotoMutation } from "@features/home/api";
import { Property } from "@features/home/types";
import { SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, CardContent, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { RIOT_BLUE } from "theme";


export default function HomeImageUpload() {
  const home = useAppSelector((state: RootState) => state.property satisfies Property);
  const { enqueueSnackbar } = useSnackbar();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const [uploading, setUploading] = useState<boolean>(false);
  const [photo, setPhoto] = useState<any>(null);

  const {
    refetch: refetchHome,
  } = useGetPropertyQuery({
    propertyId: home.property_id,
    tenantId: currentTenant.tenant_id,
  });

  const [
    uploadHomePhoto,
  ] = useUploadHomePhotoMutation();

  const handleFilesChange = useCallback((file: File) => setPhoto(file), []);

  const handleUploadPhoto = useCallback(async () => {
    try {
      setUploading(true);

      const formData = new FormData();
      formData.append('file', photo);
      const uploadedFileResponse = await uploadHomePhoto({
        userTenantId: currentTenant?.tenant_id || '',
        propertyId: home.property_id,
        file: formData,
      });
  
      if ('error' in uploadedFileResponse) {
        enqueueSnackbar("Error uploading image for home:", {
          key: "home-image-upload-error",
          content: (
            <ExpandableSnack
              id="home-image-upload-error"
              message={"Couldn't upload image for home"}
              variant={SnackType.error}
              detail={getErrorMessage(uploadedFileResponse.error)}
            />),
        });
      } else {
        enqueueSnackbar("Uploaded image for home.", {
          variant: "success",
        });
      }

      refetchHome();
    } finally {
      setUploading(false);
    }

  }, [currentTenant?.tenant_id, enqueueSnackbar, home.property_id, photo, refetchHome, uploadHomePhoto]);

  return (
    <Card sx={{
      maxWidth: '75%',
      maxHeight: '75%',
    }}>
      <CardContent>
        <Stack spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="h6">
            Upload a photo of the home
          </Typography>
          <Typography variant="button" color="error">
            Images uploaded here are publicly accessible. Please do not upload any private or explicit materials.
          </Typography>
          <Stack direction="row">
            <Box sx={{ display: photo ? 'none' : undefined }}>
              <FileUploader
                multiple={false}
                handleChange={handleFilesChange}
                name="photo"
                label="Upload or drop photo here"
                types={['JPEG', 'PNG', 'SVG']}
                maxSize={4}
                children={
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      border: `1px solid ${currentTenant.builder_color || RIOT_BLUE}`,
                      padding: '1em',
                      width: '25em'
                    }}
                  >
                    <Typography variant="caption">
                      Click <Typography variant="button">here</Typography> or drag your photo for upload
                    </Typography>
                  </Stack>
                }
              />
            </Box>
            {photo ? <LoadingButton onClick={handleUploadPhoto} disabled={!photo} loading={uploading}>Upload</LoadingButton> : null }
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

