import { riotApi } from "@features/aws-user/api";
import { HTTPMethodType } from "@lib/types";
import { U } from "@lib/utils";
import { SupportCase, SupportCaseCreate, SupportCaseUpdate } from "../support-case/types";

export const homeSupportCasesApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      cases: builder.query<SupportCase[], {
        userTenantId: string,
        propertyId: string,
      }>({
        query: ({ userTenantId, propertyId }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/support`,
        }),
      }),
      createCase: builder.mutation<SupportCase, {
        userTenantId: string,
        propertyId: string,
        body: SupportCaseCreate,
      }>({
        query: ({ userTenantId, propertyId, body }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/support`,
          method: HTTPMethodType.POST,
          body,
        }),
      }),
      updateCase: builder.mutation<SupportCase, {
        userTenantId: string,
        propertyId: string,
        caseId: string,
        body: SupportCaseUpdate,
      }>({
        query: ({ userTenantId, propertyId, caseId, body }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/support/${U(caseId)}`,
          method: HTTPMethodType.PATCH,
          body,
        }),
      }),
      resolveCase: builder.mutation<SupportCase, {
        userTenantId: string,
        propertyId: string,
        caseId: string,
        keep?: boolean,
      }>({
        query: ({ userTenantId, propertyId, caseId, keep = false }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/support/${U(caseId)}/resolve`,
          params: {
            preserve_shots: keep,
          },
          method: HTTPMethodType.PATCH,
        }),
      }),
      deleteCase: builder.mutation<SupportCase, {
        userTenantId: string,
        propertyId: string,
        caseId: string,
        resolved?: boolean,
        keep?: boolean,
        force?: boolean,
      }>({
        query: ({ userTenantId, propertyId, caseId, force, resolved = !force, keep = false }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/support/${U(caseId)}`,
          method: HTTPMethodType.DELETE,
          params: {
            resolved,
            force,
            preserve_shots: keep,
          },
        }),
      }),
    };
  }
});

export const {
  useCasesQuery,
  useCreateCaseMutation,
  useDeleteCaseMutation,
  useResolveCaseMutation,
  useUpdateCaseMutation,
} = homeSupportCasesApi;