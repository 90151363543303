export enum HealthCheckType {
  LEAK = 'LEAK',
  // Water usage has not reached minimum threshold in 24 hours

  SUBSURFACE_MOISTURE = 'SUBSURFACE_MOISTURE',
  // A foundation moisture sensor's recent readings differ from the mean by two standard deviations

  AIR_FILTER = 'AIR_FILTER',
  // Air filter needs replacement soon

  EXTERNAL_PLUMBING_FREEZE_WARNING = 'EXTERNAL_PLUMBING_FREEZE_WARNING',
  // External plumbing valve is open and freezing temps are expected in 12 hours

  EXTERNAL_PLUMBING_FREEZE_IMMINENT = 'EXTERNAL_PLUMBING_FREEZE_IMMINENT',
  // External plumbing valve is open and freezing temps are expected in 2 hours

  UNOCCUPIED_RECENT_MOTION = 'UNOCCUPIED_RECENT_MOTION',
  // Unoccupied home has motion detected in the last 24 hours

  UNOCCUPIED_UNLOCKED_ACCESS = 'UNOCCUPIED_UNLOCKED_ACCESS',
  // Unoccupied home has unlocked windows/doors

  UNOCCUPIED_HIGH_POWER_USAGE = 'UNOCCUPIED_HIGH_POWER_USAGE',
  // Unoccupied home has high power usage

  UNOCCUPIED_HIGH_WATER_USAGE = 'UNOCCUPIED_HIGH_WATER_USAGE',
  // Unoccupied home has high water usage

  POWER_USAGE_OUTLIER = 'POWER_USAGE_OUTLIER',
  // Power usage is significantly higher than all homes in the neighborhood

  WATER_USAGE_OUTLIER = 'WATER_USAGE_OUTLIER',
  // Water usage is significantly higher than all homes in the neighborhood

  AIR_FILTER_OUTLIER = 'AIR_FILTER_OUTLIER',
  // Air filter consistently needs replacement more often than all homes in the neighbordhood

  HIGH_TEMPERATURE = 'HIGH_TEMPERATURE',
  // Some temperature sensors are reading above some default for extended time

  LOW_TEMPERATURE = 'LOW_TEMPERATURE',
  // Some temperature sensors are reading below some default for extended time
}

export const HealthCheckLabels = {
  [HealthCheckType.AIR_FILTER]: {
    good: 'Air filters do not require replacement',
    bad: 'At least one air filter requires replacement',
  },
  [HealthCheckType.AIR_FILTER_OUTLIER]: {
    good: 'Air filters replacement cycle is typical for the neighborhood',
    bad: 'At least one air filter consistently needs replacement more often than all homes in the neighbordhood',
  },
  [HealthCheckType.EXTERNAL_PLUMBING_FREEZE_IMMINENT]: {
    good: 'No imminent danger of pipes freezing',
    bad: 'External plumbing valve is open and freezing temps are expected in the next 2 hours',
  },
  [HealthCheckType.EXTERNAL_PLUMBING_FREEZE_WARNING]: {
    good: 'No danger of pipes freezing in the next 12 hours',
    bad: 'External plumbing valve is open and freezing temps are expected in the next 12 hours',
  },
  [HealthCheckType.HIGH_TEMPERATURE]: {
    good: 'Home is cool enough to be safe',
    bad: 'Some temperature sensor readings are too hot to be considered safe',
  },
  [HealthCheckType.LEAK]: {
    good: 'No leaks detected',
    bad: 'Leak detected as water usage has not reached minimum threshold in 24 hours',
  },
  [HealthCheckType.LOW_TEMPERATURE]: {
    good: 'Home is warm enough to be safe',
    bad: 'Some temperature sensor readings are too cold to be considered safe',
  },
  [HealthCheckType.POWER_USAGE_OUTLIER]: {
    good: 'Home power usage is typical for the neighborhood',
    bad: 'Power usage is significantly higher than other homes in the neighborhood',
  },
  [HealthCheckType.SUBSURFACE_MOISTURE]: {
    good: 'No risk of foundation subsidence',
    bad: 'Risk of subsidence detected by variance in foundation sensor readings',
  },
  [HealthCheckType.UNOCCUPIED_HIGH_POWER_USAGE]: {
    good: 'Power usage is typical for an unocuppied home in the neighborhood',
    bad: 'Power usage is significantly higher than of other onuccipied homes in the neighborhood',
  },
  [HealthCheckType.UNOCCUPIED_HIGH_WATER_USAGE]: {
    good: 'Water usage is typical for an unocuppied home in the neighborhood',
    bad: 'Water usage is significantly higher than of other onuccipied homes in the neighborhood',
  },
  [HealthCheckType.UNOCCUPIED_RECENT_MOTION]: {
    good: 'No motion detected in an unoccupied home',
    bad: 'Motion recently detected',
  },
  [HealthCheckType.UNOCCUPIED_UNLOCKED_ACCESS]: {
    good: 'All windows and doors are locked in an unoccupied home',
    bad: 'Some windows or doors are unlocked',
  },
  [HealthCheckType.WATER_USAGE_OUTLIER]: {
    good: 'Home water usage is typical for the neighborhood',
    bad: 'Water usage is significantly higher than other homes in the neighborhood',
  },
}

export type AirFilterHealthResult = {
  property_area: string;
  expected_lifetime?: number | null;
  percent_remaining?: number | null;
  needs_replacement?: boolean;
}

export type LeakHealthResult = {
  entity_id: string;
  device_id?: string | null;
  property_area?: string | null;
  last_reset_date?: string | null;
  leak_detected?: boolean | null;
}

export type SubsurfaceMoistureHealthResult = {
  typical_stddev_moisture?: number;
  average_moisture?: number;
  threshold_exceeded: boolean;
}

export type HealthCheckResult = {
  type: HealthCheckType;

  unhealthy?: boolean | null;
  unavailable?: boolean | null;

  air_filter?: AirFilterHealthResult[];
  leak?: LeakHealthResult[];
  subsurface_moisture?: SubsurfaceMoistureHealthResult;
}

export type HomeHealth = {
  all_healthy: boolean;
  checks: Record<HealthCheckType, boolean | null>;
  updated: number | string;
}