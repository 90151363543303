import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage, U } from "@lib/utils";
import { LoadingButton } from "@mui/lab";
import { Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { useCreateApiKeyMutation } from "../api";
import { certShellScript, csrScript, csrStep, emailSubject, genCertScript, generateStep, goodbyeStep, greetingStep, keyScript, keyStep, SAMPLE_KEY, shellStep, useStep } from "../utils";
import CodeBlock from "./CodeBlock";

const OemOnboarding = () => {
  const { enqueueSnackbar } = useSnackbar();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const [creating, setCreating] = useState<boolean>(false);
  const [oemName, setOemName] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [generating, setGenerating] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [apiKey, setApiKey] = useState<string>(SAMPLE_KEY);

  const handleStartCreating = useCallback(() => {
    setCreating(true);
  }, []);

  const handleCancelCreating = useCallback(() => {
    setOemName('');
    setLocation('');
    setCreating(false);
  }, []);

  const handleChangeName = useCallback((value) => {
    setOemName(value);
  }, []);

  const handleChangeLocation = useCallback((value) => {
    setLocation(value);
  }, [])

  const [ createApikey ] = useCreateApiKeyMutation();

  const handleGenerateApiKey = useCallback(async () => {
    setGenerating(true);
    const key = await createApikey({
      userTenantId: currentTenant?.tenant_id || '',
      oemName,
      location,
    });

    const errorDetails = (key as ApiErrorResponse)?.error;
    if (errorDetails) {
      enqueueSnackbar("Couldn't generate an api key:", {
        key: "apiKey-error",
        content: (
          <ExpandableSnack
            id="apiKey-error"
            message={"Couldn't generate an api key:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      setLocation('');
      setOemName('');
      setApiKey((key as any).data);
      setDialogOpen(true);
      enqueueSnackbar("Api key generated", {
        variant: "success",
      });
    }
    setGenerating(false);
    setCreating(false);
  }, [createApikey, currentTenant?.tenant_id, enqueueSnackbar, location, oemName]);

  const handleCloseDialog = useCallback(() => {
    setDialogOpen(false);
    setApiKey(SAMPLE_KEY);
  }, []);

  const handleOpenDialog = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const emailBody = useMemo(() => [
    U(greetingStep),
    U(keyStep),
    Array(10).fill('-').join('-'),
    [U(keyScript), U(apiKey)].join(''),
    [Array(10).fill('-').join('-'), '%0A'].join(''),
    U(csrStep),
    Array(10).fill('-').join('-'),
    U(csrScript),
    [Array(10).fill('-').join('-'), '%0A'].join(''),
    U(shellStep),
    Array(10).fill('-').join('-'),
    U(certShellScript),
    [Array(10).fill('-').join('-'), '%0A'].join(''),
    U(generateStep),
    Array(10).fill('-').join('-'),
    U(genCertScript),
    [Array(10).fill('-').join('-'), '%0A'].join(''),
    U(useStep),
    '%0A',
    U(goodbyeStep),
  ].join('%0A'), [apiKey]);

  return (
    <>
      <Stack spacing={2} alignItems="center">
        <Typography variant="h4" textAlign="center">OEM Onboarding</Typography>
        <Card sx={{
          width: '60em',
          height: '14em',
        }}>
          <CardHeader title="API Keys" />
          <CardContent>
            {
              !creating &&
              <Stack direction="row" spacing={1}>
                <Tooltip
                  placement="top"
                  title="Create an API key and share it with a manufacturer to invite them to share their device readings with RIoT systems"
                  arrow
                >
                  <span>
                    <Button onClick={handleStartCreating} variant="contained">
                      Create a key
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip
                  placement="top"
                  title="View onboarding instructions to be provided to the OEM representative"
                  arrow
                >
                  <span>
                    <Button onClick={handleOpenDialog} variant="contained">Instructions</Button>
                  </span>
                </Tooltip>
              </Stack>
            }
            {
              creating &&
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" spacing={1}>
                  <TextField
                    size="small"
                    onChange={handleChangeName}
                    label="Manufacturer name"
                    sx={{
                      width: '25em',
                    }}
                  />
                  <TextField
                    size="small"
                    onChange={handleChangeLocation}
                    label="Manufacturer location"
                    sx={{
                      width: '20em',
                    }}
                  />
                </Stack>
                <Stack direction="row">
                  <Button onClick={handleCancelCreating}>Cancel</Button>
                  <LoadingButton
                    size="small"
                    variant="contained"
                    color="success"
                    disabled={!location || !oemName}
                    loading={generating}
                    onClick={handleGenerateApiKey}
                  >
                    Generate
                  </LoadingButton>
                </Stack>
              </Stack>
            }
          </CardContent>
        </Card>
      </Stack>
      <Dialog open={dialogOpen}>
        <DialogTitle>OEM Onboarding Instructions</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography>{greetingStep}</Typography>
            <Stack spacing={1}>
              <Typography>{keyStep}</Typography>
              <CodeBlock code={[keyScript, apiKey].join('')} />
            </Stack>
            <Stack spacing={1}>
              <Typography>{csrStep}</Typography>
              <CodeBlock code={csrScript} />
            </Stack>
            <Stack spacing={1}>
              <Typography>{shellStep}</Typography>
              <CodeBlock code={certShellScript} />
            </Stack>
            <Stack spacing={1}>
              <Typography>{generateStep}</Typography>
              <CodeBlock code={genCertScript} />
            </Stack>
            <Typography>{useStep}</Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
          {
            emailBody &&
            <Button href={`mailto:?subject=${emailSubject}&body=${emailBody}`} onClick={handleCloseDialog}>Start email</Button>

          }
        </DialogActions>
      </Dialog>
    </>

  );
}

export default OemOnboarding;