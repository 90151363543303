import { PropertyManifestEntryUpdate } from "@features/home-manifest/types";
import { Stack, TextField } from "@mui/material";
import { ChangeEvent, useCallback, useMemo } from "react";
import { Coordinates, ManifestEntryCreate } from "../types";

type Props = {
  onChange: (entry: Partial<ManifestEntryCreate>|PropertyManifestEntryUpdate) => unknown;
  existingPosition?: Partial<ManifestEntryCreate>|PropertyManifestEntryUpdate;
}

const ManifestEntryPosition = ({ onChange, existingPosition }: Props) => {
  const x = useMemo(() => existingPosition?.coordinates?.x_axis || '0', [existingPosition?.coordinates?.x_axis]);
  const y = useMemo(() => existingPosition?.coordinates?.y_axis || '0', [existingPosition?.coordinates?.y_axis]);
  const z = useMemo(() => existingPosition?.coordinates?.z_axis || '0', [existingPosition?.coordinates?.z_axis]);
  const propertyArea = useMemo(() => existingPosition?.property_area || '', [existingPosition?.property_area]);

  const handleChangePropertyArea = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange({
      ...existingPosition,
      property_area: e.target.value,
    });
  }, [existingPosition, onChange]);


  const handleChangeCoordinate = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, axis: 'x_axis' | 'y_axis' | 'z_axis') => {
    onChange({
      ...existingPosition,
      coordinates: {
        ...existingPosition?.coordinates,
        [axis]: e.target.value,
      } as Coordinates,
    })
  }, [existingPosition, onChange]);

  return (
    <Stack spacing={1.3}>
      <TextField
        variant="outlined"
        label="Home Area"
        placeholder="e.g. Office"
        value={propertyArea}
        onChange={handleChangePropertyArea}
        required
        fullWidth
        size="small"
      />
      <Stack direction="row" spacing={1} justifyContent="space-between">
        <TextField
          variant="outlined"
          label="X Coordinate"
          inputProps={{  inputMode: 'numeric', pattern: '[0-9]+(?:\\.[0-9]*)?' }}
          required
          value={x}
          onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChangeCoordinate(e, 'x_axis')}
          fullWidth
          size="small"
        />
        <TextField
          variant="outlined"
          label="Y Coordinate"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]+(?:\\.[0-9]*)?' }}
          required
          value={y}
          onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChangeCoordinate(e, 'y_axis')}
          fullWidth
          size="small"
        />
        <TextField
          variant="outlined"
          label="Z Coordinate"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]+(?:\\.[0-9]*)?' }}
          required
          value={z}
          onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChangeCoordinate(e, 'z_axis')}
          fullWidth
          size="small"
        />
      </Stack>
    </Stack>);
}

export default ManifestEntryPosition;
