import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { Plan } from "@features/floorplan/types";
import { useGetPlanManifestsQuery } from "@features/plan-manifest/api";
import { Box, CircularProgress, Modal } from "@mui/material";
import { useCallback, useMemo } from "react";
import PlanEditor from "./PlanEditor";


export type ManifestManagementModalProps = {
  open: boolean;
  onClose: (changes: boolean) => void;
  plan: Plan;
};

const ManifestManagementModal = ({ plan, open, onClose }: ManifestManagementModalProps) => {
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const {
    currentData: selectedPlanManifests,
    refetch: fetchManifests,
  } = useGetPlanManifestsQuery({
    userTenantId: currentTenant.tenant_id!,
    planId: plan.plan_id || '',
    includeEntries: true,
  });

  const handleClose = useCallback((event: object, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose(false);
  }, [onClose]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box component="div"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
        {
          selectedPlanManifests ? (
            <PlanEditor
              plan={plan}
              manifests={selectedPlanManifests}
              onChange={fetchManifests}
              onClose={() => onClose(false)}
            />
          ) : <CircularProgress />
        }
      </Box>
    </Modal>);
}

export default ManifestManagementModal;