import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { Category, findMeasureLabel, getMeasuresByCategory, Measure } from '@lib/labels';
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useCallback, useMemo } from 'react';
import { RIOT_BLUE } from 'theme';

type Props = {
  initial: Measure;
  category: Category;
  onChange: (m: Measure) => void;
}

const ChartMeasures = ({ initial, category, onChange }: Props) => {
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant?.builder_color || RIOT_BLUE, [currentTenant?.builder_color]);
  const measureOptions = useMemo(() => getMeasuresByCategory(category), [category]);

  const handleChange = useCallback((e: SelectChangeEvent<Measure>) => {
    onChange(e.target.value as Measure);
  }, [onChange]);

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <Select
        value={initial}
        onChange={handleChange}
        sx={{
          '&:focus': {
            border: `1px solid ${themeColor}`,
          }
        }}
      >
        {
          measureOptions.map((m, idx) =>(
            <MenuItem value={m} key={`measure-option-${idx}`}>
              {findMeasureLabel(m)}
            </MenuItem>))
        }
      </Select>
    </FormControl>
  );
}

export default ChartMeasures;
