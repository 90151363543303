/**
 * Utilities for checking is the current user
 * can perform actions in the GUI.
 */

import { Permission, UserPermissions } from './types';

// Callback to check if the current user can perform the action
export type UserCheck = (action: string) => boolean;

/**
 * Create a callback to check if the current user can perform actions
 * @param tenantType TenantType of the current user
 */
export const makeUserPermissionCheck = (
  permissions: UserPermissions | null,
): UserCheck => {
  // Wrap the calls to can() with the current user's type
  return (action: string) => checkPermission(permissions, action);
}

/**
 * Check if user has access to do or see something.
 */
export const checkPermission = (
  permissions: UserPermissions | null,
  action: string,
): boolean => {
  if (!permissions) {
    // Unknown users can do nothing
    return false;
  }

  // Is this user permitted this action?
  return permissions[action] ?? false;
}

/**
 * Compile the allowed permission IDs from the list
 * of User permissions fetched from the API.
 *
 * @param permissions API permissions
 * @returns compiiled map of allowed permissions
 */
export const compileUserPermissions = (
  permissions: Permission[] | null,
): UserPermissions => {
  const userPermissions: UserPermissions = {};

  permissions?.forEach(p => {
    // Add this permission
    userPermissions[p.name] = true;

    // Also, add any permissions granted by this permission
    p.permissions.forEach(pp => {
      if (pp.enabled) {
        userPermissions[pp.id] = true;
      }
    });
  });

  return userPermissions;
}
