import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { useUtilgroupQuery } from "@features/all-utility-groups/api";
import { EnergyUsage, UtilGroup } from "@features/all-utility-groups/types";
import ExpandableSnack from '@features/Common/ExpandableSnack';
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import { LoadingButton } from "@mui/lab";
import { Card, CardActions, CardContent, CardHeader, Divider, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { useResetPowerMutation } from "../api";
import ConsumptionLimit from "./ConsumptionLimit";
import CurrentConsumption from "./CurrentConsumption";
import SheddingRequest from "./SheddingRequest";


const EnergyManagement = () => {
  const { enqueueSnackbar } = useSnackbar();

  const utilGroup = useAppSelector((state: RootState) => state.utilityGroup.utilityGroup as UtilGroup);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const capacityWatts = useMemo(() => utilGroup.utility_management?.energy_management.svc_max_power.watts || 0, [utilGroup.utility_management?.energy_management.svc_max_power.watts]);

  const shedRequestWatts = useMemo(() => (
    utilGroup.utility_targets?.find(ut => !!(ut as EnergyUsage).grid_consumption) as EnergyUsage
  )?.grid_consumption.watts || 0, [utilGroup.utility_targets]);

  const limitWatts = useMemo(() => (
    utilGroup.utility_available?.find(ua => !!(ua as EnergyUsage).grid_consumption) as EnergyUsage
  )?.grid_consumption.watts || 0, [utilGroup.utility_available]);

  const restrictionsExist = useMemo(() => (
    (shedRequestWatts && shedRequestWatts !== capacityWatts)
    || (limitWatts && limitWatts !== capacityWatts)
  ), [capacityWatts, limitWatts, shedRequestWatts]);

  const { refetch } = useUtilgroupQuery({
    userTenantId: currentTenant?.tenant_id || '',
    groupId: utilGroup?.group_id || '',
    builderId: currentTenant?.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id || !utilGroup?.group_id,
  });

  const [ resetPower ] = useResetPowerMutation();

  const [loading, setLoading] = useState<boolean>(false);

  const handleClearAll = useCallback(async () => {
    setLoading(true);

    const result = await resetPower({
      userTenantId: userTenant?.tenant_id || '',
      groupId: utilGroup?.group_id || '',
      builderId: currentTenant?.tenant_id || '',
    });

    const errorDetails = (result as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't reset power limits:", {
        key: "powerreset-error",
        content: (
          <ExpandableSnack
            id="powerreset-error"
            message={"Couldn't reset power limits:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("Power limits reset", {
        variant: "success",
      });

      setLoading(false);
      refetch();
    }
  }, [currentTenant?.tenant_id, enqueueSnackbar, refetch, resetPower, userTenant?.tenant_id, utilGroup?.group_id]);

  return (
    <Card
      sx={{
        minWidth: 400,
        padding: 1,
        minHeight: 370,
      }}
    >
      <CardHeader title="Energy Management" />
        <CardContent sx={{
          minHeight: 250,
        }}>
          <Stack spacing={2}>
            <CurrentConsumption />
            <Divider />
            <SheddingRequest />
            <Divider />
            <ConsumptionLimit />
          </Stack>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          {
            !!restrictionsExist &&
            <LoadingButton
              onClick={handleClearAll}
              variant="contained"
              color="warning"
              loading={loading}
            >Clear all</LoadingButton>
          }
        </CardActions>
    </Card>
  );
}

export default EnergyManagement;