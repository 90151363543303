import DeviceSummary from "@features/device/components/DeviceSummary";
import { Card, CardContent, List, Typography } from "@mui/material";
import { DeviceCount, LocationSummary } from "../types";

type Props = {
  location: LocationSummary,
}

/**
 * Displays summary for given location
 */
const AreaSummary = ({ location }: Props) => (
  <Card sx={{
    width: '96%',
    border: 'none',
    boxShadow: 'none',
    overflow: 'unset',
  }}>
    <CardContent>
      <Typography variant="caption" sx={{ fontWeight: '700' }}>
        {location.location}
      </Typography>
      <List sx={{ margin: '2px', padding: '2px' }}>
        {location.devices
          .map((device: DeviceCount, idx: number) => (
            <DeviceSummary key={`device-${idx}`} device={device} location={location} />
          ))}
      </List>
    </CardContent>
  </Card>
);

export default AreaSummary;