import { Category, Measure, Unit, ValueType } from '@lib/labels';
import * as yup from 'yup';

export interface Coordinates {
  x_axis: string;
  y_axis: string;
  z_axis: string;
}

export interface Sensor {
  sensor_id: string;
  order: number;
  friendly_name: string;
  value_type: ValueType;
  measure_name: Measure;
  sensor_category: Category;
  entity_id?: string;
  units: Unit;
  pairing_id?: string;
}

export interface ZwaveConfig {
  parameter: number;
  value: number;
  bitmask?: number;
}

export interface ManifestDeviceBase {
  manufacturer?: string;
  part_number?: string;
  model_number?: string;
  discontinued?: boolean;
  pairing_instructions?: string;
  unpairing_instructions?: string;
  reset_instructions?: string;
  description?: string;
  friendly_name?: string;
  sensors: Sensor[];
  zwave_config: ZwaveConfig[];
}

export interface ManifestDevice extends ManifestDeviceBase {
  device_id: string;
}

export interface ManifestDeviceCreate extends ManifestDeviceBase {
  manufacturer: string;
  part_number: string;
  model_number: string;
  friendly_name: string;
}

export interface ManifestEntryBase {
  manifest_id: string;
  manifest_entry_id: string;
  provision_order: string;
  property_area: string;
  coordinates: Coordinates;
  sub_location?: string;
  created?: number| string; // timestamp
  provisioned?: number | string; // timestamp
  commissioned?: number | string; // timestamp
}

export interface ManifestEntry extends ManifestEntryBase {
  device?: ManifestDevice;
}

export interface ManifestEntryCreate extends ManifestEntryBase {
  device: string;
}

export interface PlanManifestBase {
  name: string;
  description: string;
  manifest_id: string;
  property_id?: string;
  plan_id: string;
}

export interface PlanManifest extends PlanManifestBase {
  manifest_entries: ManifestEntry[];
}

export interface PlanManifestCreate extends PlanManifestBase {
  manifest_entries: ManifestEntryCreate[];
}

export const PLAN_SCHEMA = yup.object({
  name: yup.string().label('Name'),
  description: yup.string().label('Description'),
});


export enum DeviceView {
  existing = 'Select existing',
  new = 'Create new',
}

export const DEVICE_VIEW_DESCRIPTION = {
  [DeviceView.existing]: 'Select an existing type of device',
  [DeviceView.new]: 'Create anew type of device',
}