import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { Category, Measure } from "@lib/labels";
import { Box, Card, CardContent, CardHeader, Grid } from '@mui/material';
import { useMemo } from "react";
import { RIOT_BLUE } from "theme";
import { RoomReadings } from '../types';
import { getStockImageByRoom } from "../utils";
import CategoryReadingIcon from "./CategoryReadingIcon";

type Props = {
  roomReadings: RoomReadings;
};

const RoomStatus = ({ roomReadings }: Props) => {
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant.builder_color || RIOT_BLUE, [currentTenant.builder_color]);

  return (
    <Card sx={{ p: 0}}>
      <CardHeader
        title={roomReadings.name}
        sx={{
          color: themeColor,
          opacity: 1,
          height: '4em',
          padding: '0.5em',
          '& .MuiCardHeader-content': {
            display: 'flex',
            height: '5em',
            flexDirection: 'column',
            justifyContent: 'center',
          },
          '& .MuiTypography-h5': {
            fontSize: '1.2em',
            fontWeight: '700',
          }
        }}
      />
      <CardContent sx={{
        height: '16em',
        width: 'auto',
        padding: 0,
      }}>
        <Box
          component="div"
          sx={{
            height: 'inherit',
            width: 'inherit',
            backgroundImage: `url("${getStockImageByRoom(roomReadings.name)}")`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            backgroundSize: "cover",
            position: "relative",
            '&::before': {
              content: '""',
              position: "absolute",
              top: "0px",
              right: "0px",
              bottom: "0px",
              left: "0px",
              background: "rgba(255, 255, 255, 0.7)",
            }
          }}
        ></Box>
        <Grid
          container
          columns={{ xs: 4 }}
          sx={{
            height: 'inherit',
            width: 'inherit',
            position: 'relative',
            bottom: '16em',
            padding: '3px',
          }}
        >
          {
            [
              Measure.temperature,
              Measure.humidity,
              Measure.loudness,
              Measure.co2,
              // --------- row 2 ----
              Measure.voc,
              Measure.pm2_5,
              Measure.smoke,
              Measure.airpressure,
            ].map((mm, midx) => (
              <Grid item xs={1} key={`reading-air-${midx}`}>
                <CategoryReadingIcon
                  category={Category.air}
                  measure={mm}
                  readings={roomReadings.readings?.[Category.air]?.[mm] || undefined}
                />
              </Grid>))
          }


          <Grid item xs={1}>
            {
              [
                Measure.switch,
                Measure.moisture,
                Measure.usage_rate,
              ].map((mm, midx) => (
                <CategoryReadingIcon
                  key={`reading-water-${midx}`}
                  category={Category.water}
                  measure={mm}
                  readings={roomReadings.readings?.[Category.water]?.[mm] || undefined}
                />))
            }
          </Grid>
          <Grid item xs={1}>
            {
              [
                Measure.switch,
                Measure.illuminance,
              ].map((mm, midx) => (
                <CategoryReadingIcon
                  key={`reading-light-${midx}`}
                  category={Category.light}
                  measure={mm}
                  readings={roomReadings.readings?.[Category.light]?.[mm] || undefined}
                />))
            }
          </Grid>
          <Grid item xs={1}>
            {
              [
                Measure.locked,
                Measure.closed,
                Measure.motion,
              ].map((mm, midx) => (
                <CategoryReadingIcon
                  key={`reading-access-${midx}`}
                  category={Category.access}
                  measure={mm}
                  readings={roomReadings.readings?.[Category.access]?.[mm] || undefined}
                />))
            }
          </Grid>
          <Grid item xs={1}>
            {
              [
                Measure.switch,
                Measure.usage_rate,
              ].map((mm, midx) => (
                <CategoryReadingIcon
                  key={`reading-power-${midx}`}
                  category={Category.power}
                  measure={mm}
                  readings={roomReadings.readings?.[Category.power]?.[mm] || undefined}
                />))
            }
          </Grid>
          <Grid item xs={1}>
            <CategoryReadingIcon
              category={Category.water}
              measure={Measure.usage_total}
              readings={roomReadings.readings?.[Category.water]?.[Measure.usage_total] || undefined}
            />
          </Grid>
          <Grid item xs={1}>
            <CategoryReadingIcon
              category={Category.soil}
              measure={Measure.humidity}
              readings={roomReadings.readings?.[Category.soil]?.[Measure.humidity] || undefined}
            />
          </Grid>
          <Grid item xs={1}>
            <CategoryReadingIcon
              category={Category.system}
              measure={Measure.status}
              readings={roomReadings.readings?.[Category.system]?.[Measure.status] || undefined}
            />
          </Grid>
          <Grid item xs={1}>
            <CategoryReadingIcon
              category={Category.power}
              measure={Measure.usage_total}
              readings={roomReadings.readings?.[Category.power]?.[Measure.usage_total] || undefined}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default RoomStatus;
