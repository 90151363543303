import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { CompareLabel, Interval, RawAverageDataRow } from '@features/home-analytics/types';
import { getUnitsByCategoryMeasure } from '@features/unit-settings/utils';
import { Category, Measure, convertVolume, findUnitsLabel, formatCategoryMeasurePair } from '@lib/labels';
import { Alert, Card, CardContent, CardHeader, CircularProgress, Divider } from "@mui/material";
import { useEffect, useMemo, useState } from 'react';
import Chart from "react-apexcharts";
import { useAverageUsageQuery, useHomeUsageQuery } from '../api';
import { formatValue } from '../utils';

const USAGE_TOTAL_MEASURE = Measure.usage_total;

type Props = {
  interval: Interval,
  compare: CompareLabel,
  category: Category,
};

const UsageChart = ({ interval, compare, category }: Props) => {
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const home = useAppSelector((state: RootState) => state.property);
  const userPreferences = useAppSelector((state: RootState) => state.preferences);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const preferences = useMemo(() => userPreferences.preferences, [userPreferences.preferences]);
  const unitPreferences = useMemo(() => preferences.units || {}, [preferences.units]);
  const preferredUnit = useMemo(() => (
    unitPreferences?.[formatCategoryMeasurePair(category, USAGE_TOTAL_MEASURE)]
    || getUnitsByCategoryMeasure(category, USAGE_TOTAL_MEASURE)[0]
  ), [category, unitPreferences]);

  const [zip, setZip] = useState<string|undefined>(undefined);
  const [plan, setPlan] = useState<string|undefined>(undefined);

  const {
    currentData: rawData,
    isFetching,
  } = useHomeUsageQuery({
    userTenantId: currentTenant?.tenant_id || '',
    propertyId: home?.property_id || '',
    category,
    interval,
  }, {
    skip: !currentTenant?.tenant_id || !home.property_id || !category || !interval,
  });

  const homeChartData = useMemo(() => {
    if (!rawData) return {};
    return rawData?.reduce((series: any, row: RawAverageDataRow) => {
      const bucket = row.bucket;
      const bucketValues = rawData
        ?.filter((d: RawAverageDataRow) => d.bucket === bucket)
        ?.map((d: RawAverageDataRow) => convertVolume(d.value, preferredUnit)) || [];

      if (bucketValues.length) {
        const bucketAvg = bucketValues
          .reduce((sum, val) => sum + val, 0)/bucketValues.length;

        series.data.push({
          x: bucket,
          y: bucketAvg,
        });
      }

      return series;
    }, { name: home?.name || 'my home', data: [], type: 'column' });
  }, [home?.name, preferredUnit, rawData]);

  const {
    currentData: rawAverageData,
  } = useAverageUsageQuery({
    userTenantId: currentTenant?.tenant_id || '',
    category,
    interval,
    zip,
    plan,
  }, {
    skip: !currentTenant?.tenant_id || !home.property_id || !category || !interval,
  });

  const averageLabel = useMemo(() => {
    switch (compare) {
      default:
      case CompareLabel.floorplan:
        return home.plan_name;
      case CompareLabel.zip:
        return zip;
      case CompareLabel.system:
        return 'RIoT system';
    }
  }, [compare, home.plan_name, zip]);

  const averageChartData = useMemo(() => ({
    name: `Average for ${averageLabel}`,
    type: 'line',
    data: rawAverageData?.map((row: RawAverageDataRow) => ({
      x: row.bucket,
      y: convertVolume(row.value, preferredUnit),
    })),
  }), [averageLabel, preferredUnit, rawAverageData]);

  const chartData = useMemo(() => {
    if (!homeChartData?.data?.length) return [];

    if (compare === CompareLabel.devices) {
      return [
        homeChartData,
      ];
    }

    return [
      homeChartData,
      averageChartData,
    ]

  }, [averageChartData, compare, homeChartData]);

  const chartOptions = useMemo(() => ({
    chart: {
      id: 'usage-chart',
      stacked: false,
      type: 'line',
      zoom: {
        enabled: true
      },
      animations: {
        enabled: false,
      }
    },
    title: {
      text: '',
      style: {
        fontFamily: 'Montserrat',
      }
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        }
      },
      yaxis: {
        lines: {
          show: true,
        }
      },
    },
    xaxis: {
      type: 'datetime',
      tickPlacement: 'on',
    },
    yaxis: {
      decimalsInFloat: 0,
      labels: {
        formatter: (value: any) => `${formatValue(value)} ${findUnitsLabel(preferredUnit)}`,
      }
    },
    tooltip: {
      fontFamily: 'Montserrat',
      y: {
        title: {
          formatter: () => 'Usage:',
        },
        formatter: (value: any) => `${formatValue(value)} ${findUnitsLabel(preferredUnit)}`,
      },
    },
    legend: {
      fontFamily: 'Montserrat',
    },
    fills: {
      opacity: 1,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [0, 2],
    },
  } as unknown as ApexCharts.ApexOptions), [preferredUnit]);

  useEffect(() => {
    switch (compare) {
      default:
      case CompareLabel.devices:
      case CompareLabel.system:
        setZip(undefined);
        setPlan(undefined);
        break;
      case CompareLabel.zip:
        setZip(home.physical_address?.zip_postal_code);
        setPlan(undefined)
        break;
      case CompareLabel.floorplan:
        setZip(undefined);
        setPlan(home.plan_id);
    }
  }, [compare, home.physical_address?.zip_postal_code, home.plan_id]);


  return (
    <Card sx={{ height: 660, maxWidth: 700 }}>
      <CardHeader title="Usage history" />
      <Divider />
      <CardContent>
        {
          chartData?.length > 0 &&
          <Chart
            series={chartData}
            type="line"
            options={chartOptions}
            width="650"
            height="500"
          />
        }
        {
          !chartData?.length &&
          isFetching &&
          <CircularProgress />
        }
        {
          !chartData?.length &&
          !isFetching &&
          <Alert severity="info">No data found</Alert>
        }
      </CardContent>
    </Card>
  );
}

export default UsageChart;