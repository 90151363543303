
import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { useUpdateDeviceStateMutation } from '@features/home-status/api';
import { DeviceState, DeviceStateType, PresetMode, ThermostatModeStateType } from "@features/home-status/types";
import { useGetDevicesQuery } from '@features/home/api';
import { ManifestDevice } from "@features/plan-manifest/types";
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import { LoadingButton } from "@mui/lab";
import { Menu, MenuItem } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { RIOT_BLUE } from "theme";
import { DIALOG_Z_INDEX, ThermostatAttributes } from "../types";

type Props = {
  entryId: string;
}

const ThermostatPresetModeMenu = ({ entryId }: Props ) => {
  const home = useAppSelector((state: RootState) => state.property);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant?.builder_color || RIOT_BLUE, [currentTenant?.builder_color]);
  const manifestEntries = useMemo(() => home.manifestEntries || [], [home.manifestEntries]);
  const devices = useMemo(() => home.devices || [], [home.devices]);
  const thermostatEntry = useMemo(() => manifestEntries.find(me => me.manifest_entry_id === entryId), [manifestEntries, entryId]);

  const currentPresetModeSensor = useMemo(() => (thermostatEntry?.device as ManifestDevice).sensors
    .find(s => thermostatEntry?.sensor_map?.[s.sensor_id ?? s.friendly_name]?.split('#')?.[1] === ThermostatAttributes.presetMode), [thermostatEntry?.device, thermostatEntry?.sensor_map]);

  const currentPresetModeDevice = useMemo(() => devices
    .find(r => r.data.entity_id === thermostatEntry?.sensor_map?.[currentPresetModeSensor?.sensor_id ?? currentPresetModeSensor?.friendly_name ?? '']), [currentPresetModeSensor?.sensor_id, currentPresetModeSensor?.friendly_name, devices, thermostatEntry?.sensor_map]);

  const isEcoMode = useMemo(() => currentPresetModeDevice?.data.value as PresetMode !== PresetMode.NONE, [currentPresetModeDevice?.data.value]);

  const presetModeEntityId = useMemo(() => thermostatEntry?.sensor_map?.[currentPresetModeSensor?.sensor_id ?? currentPresetModeSensor?.friendly_name ?? '']?.split('#')?.[0] || '', [currentPresetModeSensor?.sensor_id, currentPresetModeSensor?.friendly_name, thermostatEntry?.sensor_map]);

  const presetModesSensor = useMemo(() => (thermostatEntry?.device as ManifestDevice).sensors
    .find(s => thermostatEntry?.sensor_map?.[s.sensor_id ?? s.friendly_name]?.split('#')?.[1] === ThermostatAttributes.presetModes), [thermostatEntry?.device, thermostatEntry?.sensor_map]);
  const presetModesDevice = useMemo(() => devices
    .find(r => r.data.entity_id === thermostatEntry?.sensor_map?.[presetModesSensor?.sensor_id ?? presetModesSensor?.friendly_name ?? '']), [devices, presetModesSensor?.sensor_id, presetModesSensor?.friendly_name, thermostatEntry?.sensor_map]);
  const presetModes = useMemo(() => String(presetModesDevice?.data.value || '')
    .split('$').filter((hm): hm is PresetMode => Object.values(PresetMode).includes(hm as any)), [presetModesDevice?.data.value]);

  const [saving, setSaving] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const [ updateDeviceState ] = useUpdateDeviceStateMutation();

  const {
    refetch,
  } = useGetDevicesQuery({
    tenantId: currentTenant?.tenant_id || '',
    propertyId: home.property_id,
  }, {
    skip: !currentTenant?.tenant_id || !home.property_id,
  });

  const handleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleChangePresetMode = useCallback(async (value: PresetMode) => {
    setSaving(true);
    setAnchorEl(null);

    await updateDeviceState({
      userTenantId: currentTenant?.tenant_id || '',
      propertyId: home?.property_id || '',
      deviceState: {
        entity_id: presetModeEntityId,
        state_type: DeviceStateType.THERMOSTAT_MODE,
        target_value: {
          presetMode: value,
        } as ThermostatModeStateType,
      } as DeviceState,
    });

    refetch();
    setSaving(false);
  }, [currentTenant?.tenant_id, presetModeEntityId, home?.property_id, refetch, updateDeviceState]);

  if (presetModes.length < 1) return <></>;

  return <>
    <LoadingButton
      variant="text"
      onClick={handleOpen}
      id="preset-mode-button"
      loading={saving}
    >
      <EnergySavingsLeafIcon color={isEcoMode ? 'success' : 'inherit'} />
    </LoadingButton>
    <Menu
      aria-labelledby="preset-mode-button"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{
        zIndex: DIALOG_Z_INDEX+100,
        '& .MuiMenu-list': {
          padding: '4px 0',
          color: themeColor,
        }
      }}
    >
      {
        presetModes.map((pm, idx) => (
          <MenuItem
            key={`preset-option-${pm}-${idx}`}
            onClick={() => handleChangePresetMode(pm)}
            sx={{ textTransform: 'capitalize' }}
          >{pm}</MenuItem>
        ))
      }
    </Menu>
  </>;
}

export default ThermostatPresetModeMenu;