import { useAppDispatch, useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { Category, findCategoryLabel, findMeasureLabel, getMeasuresByCategory, Measure } from "@lib/labels";
import { Card, CardContent, CardHeader, Checkbox, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import { setCategories } from "../slice";

type Props = {
  category: Category;
}

const CustomList = ({ category }: Props) => {
  const dispatch = useAppDispatch();

  const displayedCategories = useAppSelector((state: RootState) => state.displayedCategories);

  const items = useMemo(() => getMeasuresByCategory(category), [category]);
  const displayedMeasures = useMemo(() => displayedCategories[category] || [], [displayedCategories, category]);

  const handleToggle = useCallback((measure: Measure) => {
    if (!displayedMeasures.includes(measure)) {
      dispatch(setCategories({
        category,
        measures: [
          ...displayedMeasures,
          measure,
        ],
      }));
    } else {
      dispatch(setCategories({
        category,
        measures: displayedMeasures.filter(m => m !== measure),
      }))
    }

  }, [category, dispatch, displayedMeasures]);

  const handleToggleAll = useCallback(() => {
    dispatch(setCategories({
      category,
      measures: displayedMeasures.length === items.length ? [] : [...items],
    }));

  }, [category, dispatch, displayedMeasures, items]);

  return (
    <Card
      variant="outlined"
      sx={{
        width: '15em',
        border: 'none'
      }}
    >
      <CardHeader
        sx={{ p: 0, m: 0 }}
        avatar={
          <Checkbox
            disabled={items.length === 0}
            checked={displayedMeasures.length === items.length}
            indeterminate={displayedMeasures.length > 0 && displayedMeasures.length < items.length}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={<Typography variant="subtitle2" sx={{ fontWeight: 700 }}>{findCategoryLabel(category)}</Typography>}
        subheader={`${displayedMeasures.length}/${items.length} shown`}
        onClick={handleToggleAll}
      />
      <CardContent sx={{ p: 0, m: 0 }}>
        <Divider light />
        <List
          sx={{
            bgcolor: 'background.paper',
            overflow: 'auto',
          }}
          dense
          component="div"
          role="list"
        >
          {items.map((mm: Measure) => {
            const labelId = `transfer-list-all-item-${mm}-label`;

            return (
              <ListItem
                key={mm}
                role="listitem"
                sx={{
                  p: '0 4px',
                }}
                onClick={() => handleToggle(mm)}
              >
                <ListItemIcon>
                  <Checkbox
                    tabIndex={-1}
                    disableRipple
                    checked={displayedMeasures.includes(mm)}
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                    sx={{
                      p: '0 4px',
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={findMeasureLabel(mm)} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </CardContent>
    </Card>
  );
}

export default CustomList;
