import { riotApi } from "@features/aws-user/api";
import { Property } from "@features/home/types";
import { Tenant, TenantCreate, TenantType, TenantUpdate } from "@features/userTenant/types";
import { HTTPMethodType } from "@lib/types";

const safe = encodeURIComponent;

/** API slice for the tenant-related endpoints */
export const homeownersApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      /** Get all homeowners */
      homeowners: builder.query<Tenant[], { userTenantId: string }>({
        query: ({ userTenantId }) => ({
          url: `/tenant/${safe(userTenantId)}/tenants`,
          params: {
            tenant_type: TenantType.Homeowner,
          }
        }),
      }),
      /** Get all child tenants that are homeowners */
      childTenants: builder.query<Tenant[], { userTenantId: string }>({
        query: ({ userTenantId }) => ({
          url: `/tenant/${safe(userTenantId)}/children`,
        }),
      }),
      /** Create a homeowner */
      createHomeowner: builder.mutation<Tenant, { userTenantId: string, body: TenantCreate }>({
        query: ({ userTenantId, body }) => ({
          url: `tenant/${safe(userTenantId)}/tenants/child`,
          method: HTTPMethodType.POST,
          body: {
            ...body,
            tenant_type: TenantType.Homeowner,
          },
        }),
      }),
      /** Update a homeowner */
      updateHomeowner: builder.mutation<Tenant, { userTenantId: string, tenantId: string, body: TenantUpdate }>({
        query: ({ userTenantId, body, tenantId }) => ({
          url: `tenant/${safe(userTenantId)}/tenants/${safe(tenantId)}`,
          method: HTTPMethodType.PATCH,
          body,
        }),
      }),
      /** Delete a homeowner */
      deleteHomeowner: builder.mutation<Tenant, { userTenantId: string, tenantId: string }>({
        query: ({ userTenantId, tenantId }) => ({
          url: `tenant/${safe(userTenantId)}/tenants/${safe(tenantId)}`,
          method: HTTPMethodType.DELETE,
        }),
      }),
      /**get homeowner */
      homeowner: builder.query<Tenant, { userTenantId: string, homeownerId: string }>({
        query: ({ userTenantId, homeownerId }) => ({
          url: `/tenant/${safe(userTenantId)}/tenants/${safe(homeownerId)}`,
          method: HTTPMethodType.GET,
        })
      }),
      findHomeownerByEmail: builder.query<string, { userTenantId: string, email: string}>({
        query: ({ userTenantId, email }) => ({
          url: `/tenant/${safe(userTenantId)}/tenants/find`,
          method: HTTPMethodType.GET,
          params: {
            email
          }
        })
      }),
      /** assign an existing homeowner to a home */
      assignHomeowner: builder.mutation<Property, {
        userTenantId: string,
        propertyId: string,
        homeownerId: string
      }>({
        query: ({ userTenantId, propertyId, homeownerId }) => ({
          url: `/tenant/${safe(userTenantId)}/properties/${safe(propertyId)}`,
          method: HTTPMethodType.PATCH,
          body: {
            homeowner_id: homeownerId,
          }
        })
      }),
      /** transfer home to system tenant as ready to be released to homeowner */
      releaseHome: builder.mutation<Property, {
        userTenantId: string,
        propertyId: string,
      }>({
        query: ({ userTenantId, propertyId}) => ({
          url: `/tenant/${safe(userTenantId)}/properties/${safe(propertyId)}/release`,
          method: HTTPMethodType.PATCH,
        }),
      }),
    };
  }
});

export const {
  useHomeownersQuery,
  useChildTenantsQuery,
  useCreateHomeownerMutation,
  useUpdateHomeownerMutation,
  useDeleteHomeownerMutation,
  useHomeownerQuery,
  useFindHomeownerByEmailQuery,
  useAssignHomeownerMutation,
  useReleaseHomeMutation,
  usePrefetch,
} = homeownersApi;
