import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import PerformanceChart from '@features/charts/components/PerformanceChart';
import { Box, Typography } from '@mui/material';
  /**
   * Monthly usage reports
   */
  const Performance = () => {
    const property = useAppSelector((state: RootState) => state.property);

    return (
      <Box
        component="div"
        sx={{
          margin: '1em',
          padding: '2em',
        }}
      >
        <Typography
          variant="h5"
          sx={{
            mb: '3em'
          }}
        >
          Performance analytics for {property.name}
        </Typography>
        <PerformanceChart />
      </Box>
    );
  }

  export default Performance;