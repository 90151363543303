import { Stack, Typography } from "@mui/material";
import Review from "./Review";

const ReviewPanel = () => {

  return (
    <Stack alignItems="center">
      <Typography variant="h5">Review your progress</Typography>
      <Review />
    </Stack>
  );
}

export default ReviewPanel;