import { Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { ChangeEvent } from "react";
import { ProvisioningForm } from "../../types";


const SetupNetwork = () => {
  const f = useFormikContext();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    f.setFieldValue(name, event.target.checked);
  }

  const { switchStatus, routerStatus, accessPointStatus } = f.values as ProvisioningForm;

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={switchStatus}
            onChange={handleChange}
            name="switchStatus"
          />
        }
        label={
          <Typography variant="h6">
            1. Connect Switch device to power outlet using its power cord.
          </Typography>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={routerStatus}
            onChange={handleChange}
            name="routerStatus"
            disabled={!switchStatus}
          />
        }
        label={
          <Typography variant="h6">
            2. Connect Router device to Switch using Ethernet cable.
          </Typography>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={accessPointStatus}
            onChange={handleChange}
            name="accessPointStatus"
            disabled={!switchStatus || !routerStatus}
          />
        }
        label={
          <Typography variant="h6">
            3. Connect Access Point device to Switch using Ethernet cable.
          </Typography>
        }
      />
    </FormGroup>
  );
}

export default SetupNetwork;