import { Role, User, UserAccess } from "@features/all-users/types";
import { riotApi } from "@features/aws-user/api";
import { Tenant } from "@features/userTenant/types";
import { HTTPMethodType } from "@lib/types";
import { U } from "@lib/utils";


/** API slice for tenantUser-related endpoints */
export const tenantUserApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      /** Get all tenants */
      tenants: builder.query<Tenant[], { userTenantId: string }>({
        query: ({ userTenantId }) => ({
          url: `/tenant/${U(userTenantId)}/tenants`,
        }),
      }),
      /** Get users under current tenant */
      myUsers: builder.query<any, { userTenantId: string }>({
        query: ({ userTenantId }) => ({
          url: `/tenant/${U(userTenantId)}/users`,
        })
      }),
      /** Get users under different tenant */
      tenantUsers: builder.query<any, { userTenantId: string, tenantId: string, includeChildren?: boolean }>({
        query: ({ userTenantId, tenantId, includeChildren }) => ({
          url: `/tenant/${U(userTenantId)}/tenants/${U(tenantId)}/users`,
          params: typeof includeChildren === 'boolean' ? { include_children: includeChildren } : undefined,
        }),
      }),
      /** Create a user under current tenant */
      createUser: builder.mutation<any, { userTenantId: string, body: Partial<User> }>({
        query: ({ userTenantId, body }) => ({
          url: `/tenant/${U(userTenantId)}/users`,
          method: HTTPMethodType.POST,
          body,
        })
      }),
      /** Create a user under a given tenant tenant */
      createUserInTenant: builder.mutation<any, { userTenantId: string, tenantId: string, body: Partial<User> }>({
        query: ({ userTenantId, tenantId, body }) => ({
          url: `/tenant/${U(userTenantId)}/tenants/${U(tenantId)}/users`,
          method: HTTPMethodType.POST,
          body,
        })
      }),
      /** Update a user under current tenant */
      updateUser: builder.mutation<any, { userTenantId: string, username: string, body: Partial<User> }>({
        query: ({ userTenantId, username, body }) => ({
          url: `/tenant/${U(userTenantId)}/users/${U(username)}`,
          method: HTTPMethodType.PATCH,
          body,
        })
      }),
      /** Delete a user under current tenant */
      deleteUser: builder.mutation<any, { userTenantId: string, username: string }>({
        query: ({ userTenantId, username }) => ({
          url: `/tenant/${U(userTenantId)}/users/${U(username)}`,
          method: HTTPMethodType.DELETE,
        })
      }),
      /** Get roles */
      roles: builder.query<Role[], { userTenantId: string }>({
        query: ({ userTenantId }) => ({
          url: `/tenant/${U(userTenantId)}/roles`,
        })
      }),
      /** Get a user's access in a tenant */
      userAccess: builder.query<UserAccess, { userTenantId: string, tenantId: string, userId: string }>({
        query: ({ userTenantId, tenantId, userId }) => ({
          url: `/tenant/${U(userTenantId)}/tenants/${U(tenantId)}/users/${U(userId)}/access`,
        })
      }),
      /** Update a user's access in a given tenant  */
      updateUserAccess: builder.mutation<any /* TODO */, { userTenantId: string, tenantId: string, userId: string, roles: string[] }>({
        query: ({ userTenantId, userId, tenantId, roles }) => ({
          url: `/tenant/${U(userTenantId)}/users/${U(userId)}/access`,
          method: HTTPMethodType.PATCH,
          body: {
            tenant_id: tenantId,
            roles,
          }
        })
      })
    };
  },
});

export const {
  useTenantsQuery,
  useCreateUserMutation,
  useCreateUserInTenantMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useMyUsersQuery,
  useTenantUsersQuery,
  useRolesQuery,
  useUserAccessQuery,
  useUpdateUserAccessMutation,
} = tenantUserApi;
