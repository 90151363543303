import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { Sensor } from "@features/plan-manifest/types";
import { getUnitsByCategoryMeasure } from "@features/unit-settings/utils";
import { ALL_CATEGORIES, Category, findCategoryLabel, findMeasureLabel, findUnitsLabel, getMeasuresByCategory, LABEL_VALUE_TYPES, Measure, Unit, ValueType } from "@lib/labels";
import Cancel from "@mui/icons-material/Cancel";
import Check from '@mui/icons-material/CheckCircle';
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { Box, IconButton, MenuItem, Stack, TextField, Tooltip } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { RIOT_BLUE } from "theme";

type Props = {
  existingEntry?: Sensor;
  onSave: (entry: Sensor) => boolean;
  onEdit?: () => void;
  order?: number;
  onDelete?: () => void;
}

const SensorEntry = ({ existingEntry, onSave, onDelete, order, onEdit }: Props) => {
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant.builder_color || RIOT_BLUE, [currentTenant.builder_color]);

  const [friendlyName, setFriendlyName] = useState<string>(useMemo(() => existingEntry?.friendly_name || '', [existingEntry?.friendly_name]));
  const [sensorCategory, setSensorCategory] = useState<Category | null>(useMemo(() => existingEntry?.sensor_category || null, [existingEntry?.sensor_category]));
  const [sensorMeasure, setSensorMeasure] = useState<Measure | null>(useMemo(() => existingEntry?.measure_name || null, [existingEntry?.measure_name]));
  const [units, setUnits] = useState<Unit>(useMemo(() => existingEntry?.units || Unit.binary, [existingEntry?.units]));
  const [pairingId, setPairingId] = useState<string>(useMemo(() => existingEntry?.pairing_id ?? '', [existingEntry?.pairing_id]));
  const [valueType, setValueType] = useState<ValueType | null>(useMemo(() => existingEntry?.value_type || null, [existingEntry?.value_type]));
  const [editing, setEditing] = useState<boolean>(false);

  const resetForm = useCallback(() => {
    setFriendlyName(existingEntry?.friendly_name || '');
    setSensorCategory(existingEntry?.sensor_category || null);
    setSensorMeasure(existingEntry?.measure_name || null);
    setUnits(existingEntry?.units || Unit.binary);
    setValueType(existingEntry?.value_type || null);
    setPairingId(existingEntry?.pairing_id ?? '')
  }, [existingEntry?.friendly_name, existingEntry?.measure_name, existingEntry?.pairing_id, existingEntry?.sensor_category, existingEntry?.units, existingEntry?.value_type]);

  const handleEditClick = useCallback(() => {
    if (!editing) setEditing(true);
    else {
      // reset form
      resetForm();
      setEditing(false);
    }
  }, [editing, resetForm]);

  useEffect(() => {
    editing && onEdit && onEdit();
  }, [editing, onEdit]);


  const handleSaveEntry = useCallback(() => {
    const success = onSave({
      friendly_name: friendlyName,
      sensor_category: sensorCategory,
      measure_name: sensorMeasure,
      units,
      value_type: valueType,
      order: existingEntry?.order || order || 0,
      pairing_id: pairingId ?? null,
    } as Sensor);

    if (success) {
      resetForm();
      setEditing(false);
    }

  }, [existingEntry?.order, friendlyName, onSave, order, pairingId, resetForm, sensorCategory, sensorMeasure, units, valueType]);

  const handleDeleteEntry = useCallback(() => {
    onDelete && onDelete();
  }, [onDelete]);


  const checkDisabled = useMemo(() => {
    if (existingEntry && !editing) return true;
    if (!friendlyName || !sensorCategory || !sensorMeasure || !valueType) return true;
    return false;
  }, [editing, existingEntry, friendlyName, sensorCategory, sensorMeasure, valueType]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
      <Box
        component="form"
        sx={{
          padding: 1.5,
          width: 'inherit',
          border: `1px solid ${themeColor}`,
          borderRadius: 3,
          '& div.Mui-disabled, input:disabled.MuiSelect-nativeInput, input:disabled.MuiOutlinedInput-input': {
            color: themeColor,
            WebkitTextFillColor: themeColor,
          }
        }}
      >
        <Stack spacing={1}>
          <TextField
            variant="outlined"
            label="Name"
            disabled={existingEntry && !editing}
            placeholder="e.g. Temperature sensor"
            value={friendlyName}
            onChange={(e) => setFriendlyName(e.target.value)}
            required
            size="small"
          />
          <Stack
            direction="row"
            spacing={0.5}
            sx={{
              width: '100%',
            }}
          >
            <TextField
              variant="outlined"
              label="Sensor Type"
              placeholder=""
              required
              disabled={existingEntry && !editing}
              value={sensorCategory || ''}
              onChange={(e) => setSensorCategory(e.target.value as Category)}
              select
              fullWidth
              size="small"
              sx={{
                textTransform: 'capitalize',
              }}
              >
              {
                ALL_CATEGORIES.map((cat: Category) =>
                  <MenuItem
                    key={cat}
                    value={cat}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {findCategoryLabel(cat)}
                  </MenuItem>
                )
              }
            </TextField>
            <TextField
              variant="outlined"
              label="Sensor Measure"
              placeholder=""
              required
              disabled={existingEntry && !editing}
              value={sensorMeasure || ''}
              onChange={(e) => setSensorMeasure(e.target.value as Measure)}
              select
              fullWidth
              size="small"
              sx={{ textTransform: 'capitalize' }}
            >
              {
                getMeasuresByCategory(sensorCategory).map((mea: Measure) =>
                  <MenuItem
                    key={mea}
                    value={mea}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {findMeasureLabel(mea)}
                  </MenuItem>
                )
              }
            </TextField>
          </Stack>
          <Stack direction="row" spacing={0.5} sx={{ width: '100%'}}>
            <TextField
              variant="outlined"
              label="Sensor value type"
              disabled={existingEntry && !editing}
              placeholder=""
              fullWidth
              required
              value={valueType || ''}
              onChange={(e) => setValueType(e.target.value as ValueType)}
              select
              size="small"
            >
              {
                Object.entries(LABEL_VALUE_TYPES).map(([valueType, label]) =>
                  <MenuItem key={valueType} value={valueType}>{label}</MenuItem>
                )
              }
            </TextField>
            <TextField
              variant="outlined"
              label="Sensor units"
              disabled={existingEntry && !editing}
              placeholder=""
              fullWidth
              select
              onChange={(e) => setUnits(e.target.value as Unit)}
              value={units || ''}
              size="small"
            >
              {
                Array.from(new Set([
                  units,
                  ...getUnitsByCategoryMeasure(sensorCategory, sensorMeasure),
                ])).map(uu => <MenuItem key={uu} value={uu}>{uu === Unit.binary ? '--' : findUnitsLabel(uu)}</MenuItem>)
              }
            </TextField>
            <TextField
              variant="outlined"
              label="Pairing ID"
              disabled={existingEntry && !editing}
              placeholder=""
              fullWidth
              onChange={(e) => setPairingId(e.target.value)}
              value={pairingId}
              size="small"
            />
          </Stack>
        </Stack>
      </Box>
      <Stack spacing={1}>
      {
        existingEntry &&
        <Tooltip arrow placement="right" title={!editing ? 'Edit sensor' : 'Discard changes'}>
          <span>
            <IconButton
              size="small"
              color={!editing ? 'warning' : 'inherit'}
              onClick={handleEditClick}
            >
              {!editing ? <Edit /> : <Cancel />}
            </IconButton>
          </span>
        </Tooltip>

      }
        <Tooltip arrow placement="right" title={`Validate ${existingEntry ? 'changes' : 'details'}`}>
          <span>
            <IconButton
              size="small"
              color="success"
              disabled={checkDisabled}
              onClick={handleSaveEntry}
            >
              <Check />
            </IconButton>
          </span>
        </Tooltip>
      {
        existingEntry &&
        <Tooltip arrow placement="right" title="Remove sensor">
          <span>
            <IconButton
              size="small"
              color="error"
              onClick={handleDeleteEntry}
            >
              <Delete />
            </IconButton>
          </span>
        </Tooltip>
      }
      </Stack>
    </Stack>

  )
}

export default SensorEntry;