import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { ManifestDevice } from "@features/plan-manifest/types";
import InfoIcon from '@mui/icons-material/Info';
import { Divider, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { useMemo } from "react";
import { RIOT_BLUE } from "theme";
import SensorIcon from "../../inventory/components/SensorIcon";
import SensorDetails from "./SensorDetails";

export type Props = {
  device?: ManifestDevice;
};

const DeviceDetails = ({ device }: Props) => {

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant.builder_color || RIOT_BLUE, [currentTenant.builder_color]);
  const sensors = useMemo(() => device?.sensors || [], [device?.sensors]);

  return (
    <>
      <Paper sx={{ m: 1, p: 1, minWidth: 300, height: '100%' }}>
        <List
          dense
          sx={{
            width: '100%',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 300,
          }}
          subheader={<ListSubheader />}
        >
          <ListSubheader color="primary">
            <ListItemText  sx={{
              textAlign: 'center',
            }}>
              Details
            </ListItemText>
            <Divider sx={{ borderColor: themeColor }} />
          </ListSubheader>
          <ListItem>
            <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
              <ListItemText><Typography variant="caption">Manufacturer</Typography></ListItemText>
              <ListItemText><Typography variant="subtitle1" align="right">{device?.manufacturer || '--'}</Typography></ListItemText>
            </Stack>
          </ListItem>
          <ListItem>
            <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
              <ListItemText><Typography variant="caption">Part number</Typography></ListItemText>
              <ListItemText><Typography variant="subtitle1" align="right">{device?.part_number || '--'}</Typography></ListItemText>
            </Stack>
          </ListItem>
          <ListItem>
            <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
              <ListItemText><Typography variant="caption">Model number</Typography></ListItemText>
              <ListItemText><Typography variant="subtitle1" align="right">{device?.model_number || '--'}</Typography></ListItemText>
            </Stack>
          </ListItem>
          <ListSubheader color="primary">
            <ListItemText  sx={{
              textAlign: 'center',
            }}>
              Sensors
            </ListItemText>
            <Divider sx={{ borderColor: themeColor }} />
          </ListSubheader>
          {
            sensors.length > 0
              ? [...sensors]
                  .sort((a, b) => a.friendly_name.localeCompare(b.friendly_name))
                  .map(sensor => (
                    <ListItem key={sensor.order}>
                      <ListItemIcon>
                        <SensorIcon sensor={sensor} />
                      </ListItemIcon>
                      <ListItemText>
                        {sensor.friendly_name}
                      </ListItemText>
                      <Tooltip
                        arrow
                        placement="left-start"
                        title={<SensorDetails sensor={sensor} />}
                      >
                        <span>
                          <InfoIcon htmlColor={themeColor} />
                        </span>
                      </Tooltip>
                    </ListItem>
                  ))
              : <ListItem>
                  <ListItemText>No sensors registered</ListItemText>
                </ListItem>
          }
        </List>

      </Paper>
    </>
  )
}


export default DeviceDetails;