import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import ConfirmDialog from '@features/Common/ConfirmDialog';
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { Property, PropertyUpdate } from "@features/home/types";
import Homeowner from '@features/homeowner/components/Homeowner';
import config from '@lib/config';
import { ApiErrorResponse, SnackType } from "@lib/types";
import { checkIfImageExists, getErrorMessage } from "@lib/utils";
import Close from '@mui/icons-material/Close';
import Edit from '@mui/icons-material/Edit';
import { Box, IconButton, Paper, Stack, Typography } from "@mui/material";
import { FormikHelpers } from "formik";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDeleteHomePhotoMutation, useGetPropertyQuery, useUpdatePropertyMutation } from '../../home/api';
import HomeEditor from './HomeEditor';
import HomeImageUpload from './HomeImageUpload';
import PowerPanelManagement from './PowerPanelManagement';
import Delete from '@mui/icons-material/Delete';

/** Displays ediable Home details */
const HomeDetails = () => {
  const { enqueueSnackbar } = useSnackbar();

  const property: Property = useAppSelector((state: RootState) => state.property);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const [uploadingImage, setUploadingImage] = useState<boolean>(false);
  const [homeImage, setHomeImage] = useState<string>('/plans/default.png');

  const [ updateProperty ] = useUpdatePropertyMutation();

  const { refetch } = useGetPropertyQuery({
    tenantId: currentTenant?.tenant_id || '',
    propertyId: property?.property_id || '',
  }, {
    skip: !currentTenant?.tenant_id || !property?.property_id,
  });

  const handleSave = useCallback(async (pp: Property, helpers: FormikHelpers<Property>) => {
    helpers.setSubmitting(true);
    const changes = Object.keys(pp as PropertyUpdate)
      .reduce((acc, kk) => {
      const attr = kk as keyof PropertyUpdate;
      if (pp[attr] !== property[attr]) {
        (acc as any)[attr] = pp[attr];
      }
      return acc as PropertyUpdate;
    }, {} as PropertyUpdate);

    const updatedHome = await updateProperty({
      userTenantId: currentTenant?.tenant_id || '',
      propertyId: property?.property_id || '',
      body: changes,
    });

    helpers.setSubmitting(false);

    const errorDetails = (updatedHome as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't update home details:", {
        key: "updatehome-error",
        content: (
          <ExpandableSnack
            id="updatehome-error"
            message={"Couldn't update home details:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });

    } else {
      enqueueSnackbar("Updated home details", {
        variant: "success",
      });
      refetch();

      helpers.resetForm();
    }


    return (updatedHome as any)?.data;
  }, [currentTenant?.tenant_id, enqueueSnackbar, property, refetch, updateProperty]);

  useEffect(() => {
    if (property.image_key && config.ASSETS_BUCKET_URL) {
      const imageUrl = [config.ASSETS_BUCKET_URL, property.image_key].join('/');

      checkIfImageExists(imageUrl, (exists: boolean) => {
        if (exists) setHomeImage(imageUrl);
        else setHomeImage('/plans/default.png');
      });
    } else {
      const imageName = (property.plan_name || 'default').toLowerCase()
      .replace(/\W+/g, ' ').trim().replaceAll(' ', '-');

      checkIfImageExists(`/plans/${imageName}.png`, (exists: boolean) => {
        if (exists) setHomeImage(`/plans/${imageName}.png`);
        else setHomeImage('/plans/default.png');
      });
    }

  }, [property.image_key, property.plan_name]);

  const [
    deleteHomePhoto,
  ] = useDeleteHomePhotoMutation();

  const handleDeletePhoto = useCallback(async () => {
    const deleteFileResponse = await deleteHomePhoto({
      userTenantId: currentTenant?.tenant_id || '',
      propertyId: property.property_id,
    });

    if ('error' in deleteFileResponse) {
      enqueueSnackbar("Error deleting image for home:", {
        key: "home-image-delete-error",
        content: (
          <ExpandableSnack
            id="home-image-delete-error"
            message={"Couldn't delete image for home"}
            variant={SnackType.error}
            detail={getErrorMessage(deleteFileResponse.error)}
          />),
      });
    } else {
      enqueueSnackbar("Deleted image for home.", {
        variant: "success",
      });
      setConfirmingDelete(false);
    }

    refetch();
  }, [currentTenant?.tenant_id, deleteHomePhoto, enqueueSnackbar, property.property_id, refetch]);

  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);

  /** MAIN RENDER */
  return (
    <>
      <Paper>
        <Stack direction="row" justifyContent='space-between'>
          <Box component="div"
            sx={{
              width: '50em',
              height: '40em',
            }}
          >
            <Box
              component="div"
              sx={{
                width: '50em',
                height: '40em',
                backgroundImage: `url("${homeImage}")`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundColor: "rgba(255, 255, 255, 0.3)",
                backgroundSize: "cover",
                position: "relative",
              }}
            >
              {
                uploadingImage
                ? <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  flexGrow={1}
                  sx={{
                    position: 'relative',
                    top: '10%'
                  }}>
                  <HomeImageUpload></HomeImageUpload>
                </Stack>
                : null
              }
              <Box sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                }}>
                  {
                  uploadingImage
                  ? <>
                    <IconButton onClick={() => setConfirmingDelete(true)} color="default">
                      <Delete />
                    </IconButton>
                    <IconButton onClick={()=> setUploadingImage(false)} color="default">
                      <Close />
                    </IconButton>
                  </>
                  : <IconButton onClick={()=> setUploadingImage(true)} color="default">
                    <Edit />
                  </IconButton>
                }
                  
                </Box>
            </Box>
            <Typography variant="h5" align="center" sx={{
              position: 'relative',
              height: '2em',
              bottom: '2em',
              color: 'white',
            }}>{property?.plan_name || ''}</Typography>
          </Box>
          <Stack spacing={4} sx={{ width: '100%', p: 2 }}>
            <HomeEditor onSave={handleSave} />
            <PowerPanelManagement onSave={handleSave} />
            <Homeowner />
          </Stack>
        </Stack>
      </Paper>
      <ConfirmDialog title="Confirm Delete"
        open={confirmingDelete}
        onClose={() => setConfirmingDelete(false)}
        onConfirm={handleDeletePhoto}
        initialValues={{
        }}
      >
        <Typography>
          Are you sure you want to delete the photo for this home?
        </Typography>
      </ConfirmDialog>
    </>
    );

}

export default HomeDetails;