import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { PropertyManifestEntry } from "@features/home-manifest/types";
import { ManifestDevice, Sensor } from "@features/plan-manifest/types";
import Add from "@mui/icons-material/Add";
import QrCodeScanner from "@mui/icons-material/QrCodeScanner";
import Delete from "@mui/icons-material/Delete";
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import RestartAlt from '@mui/icons-material/RestartAlt';
import { Alert, Badge, ButtonGroup, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useMemo } from "react";
import SensorToPair from "./SensorToPair";

type Props = {
  entry: PropertyManifestEntry;
  entries: PropertyManifestEntry[];
  onPair: (data: [PropertyManifestEntry, Sensor] | null) => void;
  onUnpair: (
    manifestEntry: PropertyManifestEntry,
    sensor: Sensor,
  ) => Promise<void>;
  onRemove: (manifestEntry: PropertyManifestEntry) => void;
  onAdd: (manifestEntry: PropertyManifestEntry) => void;
  onAddSmartstart: (manifestEntry: PropertyManifestEntry) => void;
  onReset: (manifestEntry: PropertyManifestEntry) => void;
  onManualUnlink: (manifestEntry: PropertyManifestEntry) => void;
  onManualLink: (manifestEntry: PropertyManifestEntry) => void;
}

/* Displays Manifest Entry */
const EntryToPair = ({
  entry, entries, onPair, onUnpair,
  onRemove, onAdd, onAddSmartstart,
  onReset, onManualLink, onManualUnlink,
}: Props) => {
  const property = useAppSelector((state: RootState) => state.property);

  const devices = useMemo(() => property.devices || [], [property.devices]);

  const unlinkedDeviceCount = useMemo(() => {
    const linkedDeviceIds: Set<string> = new Set(
      entries
        .map(e => e.gateway_device_id)
        .filter((e): e is string => !!e)
    );
    const unlinkedDeviceIds: Set<string> = new Set(
      devices
        .filter(d => !linkedDeviceIds.has(d.data.device_id))
        .map(d => d.data.device_id)
    );
    return unlinkedDeviceIds.size;
  }, [entries, devices]);

  return (
    <Stack spacing={1} sx={{ width: 'inherit' }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        {(entry.device as ManifestDevice).friendly_name} &mdash; {entry.property_area}
        <ButtonGroup sx={{ ml: 2 }}>
          <Tooltip title="Scan QR code for device">
            <span>
              <IconButton disabled={!!entry.gateway_device_id} size="large" color="primary" onClick={() => onAddSmartstart(entry)}>
                <QrCodeScanner />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Add Device">
            <span>
              <IconButton disabled={!!entry.gateway_device_id} size="large" color="primary" onClick={() => onAdd(entry)}>
                <Add />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Reset Device">
            <span>
              <IconButton size="large" color="secondary" onClick={() => onReset(entry)}>
                <RestartAlt />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Manually unlink device">
            <span>
              <IconButton disabled={!entry.gateway_device_id} size="large" color="secondary" onClick={() => onManualUnlink(entry)}>
                <LinkOffIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Manually link device">
            <span>
              <IconButton disabled={!!entry.gateway_device_id} size="large" color="secondary" onClick={() => onManualLink(entry)}>
                <Badge badgeContent={unlinkedDeviceCount}>
                  <LinkIcon />
                </Badge>
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Remove Device">
            <span>
              <IconButton size="large" color="error" onClick={() => onRemove(entry)}>
                <Delete />
              </IconButton>
            </span>
          </Tooltip>
        </ButtonGroup>
      </Typography>
      {
        entry.gateway_device_id ? (
          <Stack sx={{ height: '28em', overflowY: 'scroll' }}>
            <Stack spacing={1} sx={{ padding: 1 }}>
              {
                ((entry.device as ManifestDevice).sensors || []).map((ss: Sensor) => (
                  <SensorToPair
                    key={`pairing-${ss.order}-${entry.manifest_entry_id}`}
                    entry={entry}
                    sensor={ss}
                    onPair={() => onPair([entry, ss])}
                    onUnpair={onUnpair}
                  />
                ))
              }
            </Stack>
          </Stack>
        ) : (
          <Stack sx={{ m: 3 }} alignItems="center">
            <Alert color="info">
              Pair the {(entry.device as ManifestDevice).friendly_name || ''} device with the Gateway by pressing the "+" button above.
            </Alert>
          </Stack>
        )
      }

    </Stack>
  );
};

export default EntryToPair;