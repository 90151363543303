import { useAppDispatch, useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { pollingInterval } from "@features/tenant-selector/types";
import { FC, ReactElement, useEffect } from "react";
import { useRolesQuery } from "../api";
import { setRoles } from "../slice";

/** Wrapper for roles */
const RolesProvider: FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const userTenant = useAppSelector((state: RootState) => state.userTenant);

  const {
    currentData: roles,
  } = useRolesQuery({
    userTenantId: userTenant?.tenant_id || '',
  }, {
    skip: !userTenant?.tenant_id,
    pollingInterval,
  });

  useEffect(() => {
    if (roles) {
      dispatch(setRoles(roles));
    }
  }, [dispatch, roles]);

  return children as ReactElement;
}

export default RolesProvider;
