import { Box, Button, Card, CardActions, CardContent, CardHeader, Divider, IconButton, MenuItem, Select, Stack, Typography } from "@mui/material";
import { Comparator, DeviceStateConditionMode, DeviceStateType, LogicConditionOperator, SceneTemplate, SceneTemplateCondition, SceneTemplateConditionType, SceneTemplateDeviceStateCondition, SceneTemplateLogicCondition } from "../types"
import { useCallback, useEffect, useMemo, useState } from "react";
import SceneTemplateDeviceCriteriaSelector from "./SceneTemplateDeviceCriteriaSelector";
import { Category, Measure } from "@lib/labels";
import AddIcon from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import SceneTemplateDeviceStateSelector from "./SceneTemplateDeviceStateSelector";

interface SceneTemplateConditionProps {
  sceneTemplate: SceneTemplate;
  onChange: (newCondition: SceneTemplateCondition) => void;
}

/** Return the given condition represented as a logic condition */
function packCondition(condition: SceneTemplateCondition): SceneTemplateLogicCondition {
  if (condition.type === SceneTemplateConditionType.LOGIC) return condition;
  return {
    type: SceneTemplateConditionType.LOGIC,
    operator: LogicConditionOperator.AND,
    operands: [condition],
  }
}

export default function SceneTemplateConditionEditor(props: SceneTemplateConditionProps) {
  const { sceneTemplate, onChange } = props;

  /* For now, the UI will allow a single logic AND/OR condition (with any amount of child conditions) */
  const [newCondition, setNewCondition] = useState<SceneTemplateLogicCondition>({
    type: SceneTemplateConditionType.LOGIC,
    operator: LogicConditionOperator.AND,
    operands: [],
  });

  useEffect(() => {
    if (sceneTemplate.condition) setNewCondition(packCondition(sceneTemplate.condition));
  }, [sceneTemplate.condition]);

  const updateMode = useCallback((newLogicConditionOperator: LogicConditionOperator) => {
    const updatedCondition: SceneTemplateLogicCondition = ({
      ...newCondition,
      operator: newLogicConditionOperator,
    });

    setNewCondition(updatedCondition);
    onChange(updatedCondition);
  }, [newCondition, onChange])
  const updateChildCondition = useCallback((conditionIndex: number, childCondition: SceneTemplateCondition) => {
    const updatedCondition = ({
      ...newCondition,
      operands: newCondition.operands.map((operand, operandIndex) => operandIndex === conditionIndex ? childCondition : operand),
    });

    setNewCondition(updatedCondition);
    onChange(updatedCondition);
  }, [newCondition, onChange]);

  const deleteChildCondition = useCallback((conditionIndex: number) => {
    const updatedCondition = ({
      ...newCondition,
      operands: newCondition.operands.filter((operand, operandIndex) => operandIndex !== conditionIndex),
    });

    setNewCondition(updatedCondition);
    onChange(updatedCondition);
  }, [newCondition, onChange]);

  const addNewCondition = useCallback(() => {
    const updatedCondition: SceneTemplateLogicCondition = {
      ...newCondition,
      operands: [
        ...newCondition.operands ?? [],
        {
          type: SceneTemplateConditionType.STATE,
          mode: DeviceStateConditionMode.ANY,
          state_type: DeviceStateType.BINARY_SWITCH,
          state_value: true,
          device_criteria: {
            category: Category.light,
            measure: Measure.switch,
            areas: [],
          }
        }
      ]
    };
    setNewCondition(updatedCondition);
    onChange(updatedCondition);
  }, [newCondition, onChange]);

  const renderedConditions = useMemo(
    () => newCondition.operands
      .filter((e): e is SceneTemplateDeviceStateCondition => e.type === SceneTemplateConditionType.STATE), 
    [newCondition.operands]
  );

  return (
    <Stack direction="column" sx={{ flex: '1 1 0' }} spacing={3} divider={<Divider />}>
      <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'center' }} spacing={1}>
        <Typography>
          When
        </Typography>
        <Select size="small" onChange={(e) => updateMode(e.target.value as LogicConditionOperator)} value={newCondition.operator}>
          <MenuItem value={LogicConditionOperator.AND}>All</MenuItem>
          <MenuItem value={LogicConditionOperator.OR}>Any</MenuItem>
        </Select>
        <Typography>
          of the following occurs:
        </Typography>
      </Stack>
      {
        renderedConditions
          .map((childCondition, childConditionIdx) => (
            <Stack sx={{ flex: '0 auto', width: '100%' }} direction="column" key={`${newCondition.type}-operand-${childCondition}`}>
              <Stack direction="row">
                <Stack direction="column" sx={{ flex: '1 1 auto' }} spacing={2}>
                  <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'center' }} spacing={1}>
                    <Select size="small"
                      value={childCondition.mode}
                      onChange={(e) => updateChildCondition(childConditionIdx, { ...childCondition, mode: e.target.value as DeviceStateConditionMode })}>
                      <MenuItem value={DeviceStateConditionMode.ALL}>All devices</MenuItem>
                      <MenuItem value={DeviceStateConditionMode.ANY}>Any devices</MenuItem>
                    </Select>
                    <Typography>
                      meeting the following criteria
                    </Typography>
                  </Stack>
                  <SceneTemplateDeviceCriteriaSelector
                    deviceCriteria={childCondition.device_criteria}
                    onChange={(newCriteria) => updateChildCondition(childConditionIdx, { ...childCondition, device_criteria: newCriteria })}
                  />
                  <SceneTemplateDeviceStateSelector
                    stateType={childCondition.state_type}
                    stateValue={childCondition.state_value}
                    deviceCriteria={childCondition.device_criteria}
                    onChange={(newStateType, newStateValue) => updateChildCondition(childConditionIdx, { ...childCondition, state_value: newStateValue, state_type: newStateType })}
                  />
                </Stack>
                <Stack direction="column" sx={{ ml: 2, mr: 2, alignItems: 'center', justifyContent: 'center' }}>
                  <IconButton color="error" onClick={() => deleteChildCondition(childConditionIdx)}>
                    <Delete />
                  </IconButton>
                </Stack>
              </Stack>
            </Stack>
          ))
      }
      <IconButton onClick={addNewCondition} size="large" color="primary"><AddIcon /></IconButton>
    </Stack>
  );
}
