import { formatDate } from "@lib/utils";
import { Alert } from "@mui/material";

type Props = {
  label: string;
  datetime: number;
}

const SystemDeviceStatus = ({ label, datetime }: Props) => {

    return (
      <Alert
        severity={datetime ? 'success': 'warning'}
        variant='outlined'
        sx={{
          width: '40em',
          background: 'white',
        }}
      >
        {
          datetime
            ? `${label}'s latest healthcheck performed ${formatDate(datetime).fromNow()}`
            : `No successful healthchecks for ${label} found`
        }

      </Alert>
    );
}

export default SystemDeviceStatus;