import CheckIcon from '@mui/icons-material/Check';
import { Avatar, IconButton, Tooltip } from "@mui/material";
import { useMemo } from "react";
import { SUCCESS, WARNING } from "theme";
import { Neighborhood, NeighborhoodBase } from "../types";

type Props = {
  health: NeighborhoodBase['health_status'];
  neighborhood: Neighborhood,
  onClick: () => void,
}

const NeighborhoodStatus = ({ health, neighborhood, onClick }: Props) => {
  const unhealthyCount = useMemo(() => Object.keys(health || {})
    .filter(home => (
      !health?.[home]?.all_healthy
    ))?.length, [health]);

  const tooltipTitle = useMemo(() => (
    !unhealthyCount
      ? 'All homes in the neighborhood are healthy'
      : `Neighborhood has ${unhealthyCount} homes`
  ), [unhealthyCount]);

  return (
    <Tooltip title={tooltipTitle} placement="top" arrow>
      <span>
        <IconButton onClick={onClick}>
          <Avatar sx={{
            width: 22,
            height: 22,
            bgcolor: !unhealthyCount ? SUCCESS : WARNING,
            fontSize: '0.8rem',
          }}>{unhealthyCount > 0 ? unhealthyCount : <CheckIcon />}</Avatar>
        </IconButton>
      </span>
    </Tooltip>
  );
}

export default NeighborhoodStatus;