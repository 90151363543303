import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ProvisioningRequest from "@features/provisioning-request/components/ProvisioningRequest";
import { Box, Typography } from "@mui/material";
import { useMemo } from "react";


const CreateHome = () => {
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  return (
    <>
      <Box
        component="div"
        sx={{
          margin: '1em',
          padding: '2em',
        }}
      >
        <Typography variant="h4">{currentTenant.name}</Typography>
        <Typography variant="h5">Provisioning request for a new RIoT Home</Typography>
        <Box
          component="div"
          sx={{
            mt: '3em',
          }}
        >
          <ProvisioningRequest />
        </Box>
      </Box>
      </>
    );
};

export default CreateHome;