import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { useDocumentContentsUrlQuery } from "@features/all-documents/api";
import Floorplan from "@features/all-plans/components/Flooplan";
import ConfirmDialog from "@features/Common/ConfirmDialog";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { useDeletePlanManifestEntryMutation } from "@features/plan-manifest/api";
import { ManifestEntry, PlanManifest } from "@features/plan-manifest/types";
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Button, CircularProgress, Divider, List, ListItem, ListItemButton, ListItemText, ListSubheader, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { RIOT_BLUE } from "theme";
import { Vector3 } from "three";
import ListControls from "../../all-plans/components/ListControls";
import DeviceDetails from "./DeviceDetails";
import ImportModal from "./ImportModal";
import ManifestEntryModal from "./ManifestEntryModal";


export type ManifestEditorProps = {
  manifest: PlanManifest;
  onChange: () => void;
};

const ManifestEditor = ({
  manifest, onChange,
}: ManifestEditorProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const documents = useAppSelector((state: RootState) => state.documents);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant.builder_color || RIOT_BLUE, [currentTenant.builder_color]);

  const floorplanDoc = useMemo(() => documents
    .find(doc => doc.name.includes(manifest.manifest_id) && doc.name.toLocaleLowerCase().includes('.fbx'))
  , [documents, manifest.manifest_id]);

  const [selectedEntry, setSelectedEntry] = useState<ManifestEntry | null>(null);
  const [creatingManifestEntry, setCreatingManifestEntry] = useState<boolean>(false);
  const [editingManifestEntry, setEditingManifestEntry] = useState<boolean>(false);
  const [confirmingDeleteEntry, setConfirmingDeleteEntry] = useState<boolean>(false);
  const [deletingEntry, setDeletingEntry] = useState<boolean>(false);

  const [deletePlanManifestEntry] = useDeletePlanManifestEntryMutation();

  const {
    currentData: docUrl,
  } = useDocumentContentsUrlQuery({
    userTenantId: currentTenant?.tenant_id || '',
    documentId: floorplanDoc?.document_id || '',
  }, {
    skip: !currentTenant?.tenant_id || !floorplanDoc?.document_id,
  });


  useEffect(() => {
    setSelectedEntry(manifest.manifest_entries
      .find(e => e.manifest_entry_id === selectedEntry?.manifest_entry_id) || null
    );
  }, [manifest, selectedEntry?.manifest_entry_id]);

  const handleDeleteEntry = useCallback(async () => {
    setDeletingEntry(true);

    if (!selectedEntry) return false;

    const deletedEntry = await deletePlanManifestEntry({
      userTenantId: currentTenant.tenant_id!,
      planManifestId: manifest.manifest_id,
      planManifestEntryId: selectedEntry.manifest_entry_id,
    });

    const errorDetails = (deletedEntry as ApiErrorResponse).error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't delete entry:", {
        key: "user-error",
        content: (
          <ExpandableSnack
            id="user-error"
            message={"Couldn't delete entry:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
      setDeletingEntry(false);
      return false;
    } else {
      enqueueSnackbar("Deleted entry", {
        variant: "success",
      });
      onChange();
      setDeletingEntry(false);
      return true;
    }
  }, [selectedEntry, deletePlanManifestEntry, currentTenant.tenant_id, manifest.manifest_id, enqueueSnackbar, onChange]);

  const [coordinates, setCoordinates] = useState<Vector3[]>([]);
  /** controls open state of ImportModal */
  const [importingEntries, setImportingEntries] = useState<boolean>(false);

  const handleSubmitImport = useCallback(() => {
    setImportingEntries(false);
  }, []);

  const sortedEntries = useMemo(() => [
    ...manifest.manifest_entries,
  ].sort((a, b) => Number(a.provision_order) - Number(b.provision_order)),
  [manifest.manifest_entries]);

  if (!manifest) return <CircularProgress />;


  return (
    <>
      <Paper sx={{ m: 1, p: 1, minWidth: 400, height: '100%' }}>
        <Stack direction="column" justifyContent="space-between" sx={{ height: '100%' }}>
        <List
            sx={{
              width: '100%',
              position: 'relative',
              overflow: 'auto',
              maxHeight: 600,
            }}
            subheader={
              <ListSubheader color="primary">
                <Stack>
                  <ListItemText sx={{
                    textAlign: 'center',
                    mb: 0
                  }}>
                    Floorplan
                  </ListItemText>
                  <Divider sx={{ borderColor: themeColor }} />
                </Stack>
              </ListSubheader>
            }
          >
            {
              !!docUrl?.url &&
              <>
                <ListItem>
                  <Stack direction="row">
                    <Tooltip title="Download File" placement="top" arrow>
                      <span>
                        <Button
                          LinkComponent={Button}
                          href={docUrl.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FileDownloadIcon />
                        </Button>
                      </span>
                    </Tooltip>
                    <Tooltip title="Import Manifest Entries from floorplan drawing" placement="top" arrow>
                      <span>
                        <Button disabled={!coordinates.length} onClick={() => setImportingEntries(true)}>
                          <AutoFixHighIcon />
                        </Button>
                      </span>
                    </Tooltip>
                  </Stack>
              </ListItem>
              </>
            }
          </List>
          <List
            sx={{
              width: '100%',
              position: 'relative',
              overflow: 'auto',
              maxHeight: 600,
            }}
            subheader={
              <ListSubheader color="primary">
                <Stack>
                  <ListItemText sx={{
                    textAlign: 'center',
                    mb: 0
                  }}>
                    Manifest entries
                  </ListItemText>
                  <Divider sx={{ borderColor: themeColor }} />
                  <Stack direction="row" justifyContent="space-between">
                    <ListItemText>Home Area</ListItemText>
                    <ListItemText sx={{ textAlign: 'end' }}>Device Name</ListItemText>
                  </Stack>
                  <Divider sx={{ borderColor: themeColor }} />
                </Stack>
              </ListSubheader>
            }
          >
            {
              sortedEntries.map(entry => (
                <ListItemButton
                  key={entry.manifest_entry_id}
                  onClick={() => setSelectedEntry(entry)}
                  selected={selectedEntry?.manifest_entry_id === entry.manifest_entry_id}
                >
                  <ListItemText>
                    <Typography>
                      {entry.property_area}
                    </Typography>
                  </ListItemText>
                  <ListItemText sx={{ textAlign: 'end' }}>
                    {entry.device?.friendly_name || entry.device?.description || '-'}
                  </ListItemText>
                </ListItemButton>
              ))
            }
          </List>
          <ListControls
            onAdd={() => setCreatingManifestEntry(true)}
            onEdit={() => setEditingManifestEntry(true)}
            onDelete={selectedEntry ? () => setConfirmingDeleteEntry(true) : undefined}
            deleteLoading={deletingEntry}
          />
        </Stack>
      </Paper>
      {
        selectedEntry && <>
          <DoubleArrowIcon sx={{ color: 'white' }} fontSize="large" />
          <DeviceDetails
            device={selectedEntry.device}
          />
        </>
      }
      {
        (creatingManifestEntry || (editingManifestEntry && selectedEntry)) &&
        <ManifestEntryModal
          manifest={manifest}
          onClose={(changes) => {
            setCreatingManifestEntry(false);
            setEditingManifestEntry(false);

            if (changes) {
              setSelectedEntry(null);
              onChange();
            }
          }}
          existingEntry={(editingManifestEntry && selectedEntry) || undefined}
        />
      }
      {
        importingEntries &&
        <ImportModal
          coordinates={coordinates}
          onCancel={() => setImportingEntries(false)}
          onSubmit={handleSubmitImport}
          manifest={manifest}
        />
      }
      <ConfirmDialog
        open={confirmingDeleteEntry}
        onClose={() => setConfirmingDeleteEntry(false)}
        onConfirm={handleDeleteEntry}
        title="Confirm deletion"
        initialValues={{
          isConfirmed: false,
        }}
      >
        <Typography variant="body1">
          Are you sure you want to delete
          {' '}
          {selectedEntry?.device?.friendly_name || 'this device'}
          ?
        </Typography>
      </ConfirmDialog>
    </>
  )
}

export default ManifestEditor;