import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { Button } from "@aws-amplify/ui-react";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { useUpdateNeighborhoodMutation } from "@features/all-neighborhoods/api";
import PropertyManagersProvider from "@features/all-property-managers/components/PropertyManagersProvider";
import { ApiErrorResponse, SnackType } from '@lib/types';
import { getErrorMessage } from "@lib/utils";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { useNeighborhoodQuery } from "../api";
import { NeighborhoodUpdate } from "../types";
import NeighborhoodFields from "./NeighborhoodFields";
import { TenantType } from "@features/userTenant/types";


type Props = {
  open: boolean;
  onClose: () => void;
}

const EditNeighborhoodDialog = ({ open, onClose }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const neighborhood = useAppSelector((state: RootState) => state.neighborhood.neighborhood);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const  currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const canEdit = useMemo(() => [TenantType.Builder, TenantType.System].includes(currentTenant?.tenant_type), [currentTenant?.tenant_type]);

  const [isSaving, setSaving] = useState<boolean>(false);

  const [
    updateNeighborhood,
  ] = useUpdateNeighborhoodMutation();

  const {
    refetch: refetchNeighborhood,
  } = useNeighborhoodQuery({
    userTenantId: currentTenant?.tenant_id || '',
    neighborhoodId: neighborhood?.neighborhood_id || '',
  }, {
    skip: !currentTenant?.tenant_id || !neighborhood?.neighborhood_id,
  });


  const handleUpdateNeighborhood = useCallback(async () => {
    // only builders can create/edit neighborhoods
    if (!canEdit) return;
    setSaving(true);
    /** Update existing plan */
    const updated = await updateNeighborhood({
      userTenantId: currentTenant?.tenant_id || '',
      neighborhoodId: neighborhood?.neighborhood_id || '',
      neighborhood: neighborhood as NeighborhoodUpdate,
    });

    const errorDetails = (updated as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't update neighborhood:", {
        key: "neighborhood-error",
        content: (
          <ExpandableSnack
            id="neighborhood-error"
            message={"Couldn't update neighborhood:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
      setSaving(false);
    } else {
      enqueueSnackbar("Updated neighborhood", {
        variant: "success",
      });
      refetchNeighborhood();
      setSaving(false);
      onClose();
    }

    return (updated as any).data;
  }, [canEdit, currentTenant?.tenant_id, enqueueSnackbar, neighborhood, onClose, refetchNeighborhood, updateNeighborhood]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Edit Neighborhood details</DialogTitle>
      <DialogContent dividers>
        <PropertyManagersProvider>
            <NeighborhoodFields />
        </PropertyManagersProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isSaving}>Cancel</Button>
        <LoadingButton
          variant="contained"
          onClick={() => handleUpdateNeighborhood()}
          loading={isSaving}
        >Save</LoadingButton>
      </DialogActions>
    </Dialog>

  );
}

export default EditNeighborhoodDialog;