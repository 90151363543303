import { useAppDispatch, useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { pollingInterval, SelectedTenant } from "@features/tenant-selector/types";
import { FC, ReactElement, useEffect } from "react";
import { useBuildersQuery } from "../api";
import { setBuilders } from "../slice";

/** Wrapper for contact list under selected tenant */
const BuildersProvider: FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant: SelectedTenant = useAppSelector((state: RootState) => state.tenant);
  const { currentTenant = userTenant } = tenant;

  const {
    currentData: builders,
  } = useBuildersQuery({
    userTenantId: currentTenant?.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id,
    pollingInterval,
  });

  useEffect(() => {
    dispatch(setBuilders(builders));
  }, [currentTenant.tenant_id, builders, dispatch]);

  return children as ReactElement;
}

export default BuildersProvider;
