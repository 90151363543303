import ViewTypeToggle from "@features/Common/ViewTypeToggle";
import ManifestManagementControls from "@features/home-manifest/components/ManifestManagementControls";
import { DashboardView, DashboardViewType } from "@features/settings/types";
import { FormGroup, Stack } from "@mui/material";
import CategoryFilters from "./CategoryFilters";

type Props = {
  onViewTypeChange: (type: DashboardViewType) => void;
}

const ViewControls = ({ onViewTypeChange }: Props) => {

  return (
    <FormGroup
      sx={{
        background: "white",
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 2,
        p: 1,
        mb: 2,
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <CategoryFilters />
        <ManifestManagementControls />
      </Stack>
        <ViewTypeToggle onChange={onViewTypeChange} view={DashboardView.status} />
      </FormGroup>
  );
}

export default ViewControls;