import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { ManifestDevice } from "@features/plan-manifest/types";
import { Stack, Typography } from "@mui/material";
import { useMemo } from "react";

type Props = {
  entryId: string;
}

const ThermostatCurrentHvacMode = ({ entryId }: Props) => {
  const property = useAppSelector((state: RootState) => state.property);

  const devices = useMemo(() => property.devices || [], [property.devices]);
  const manifestEntries = useMemo(() => property.manifestEntries || [], [property.manifestEntries]);
  const thermostatEntry = useMemo(() => manifestEntries.find(me => me.manifest_entry_id === entryId), [manifestEntries, entryId]);

  const currentHvacModeSensor = useMemo(() => (thermostatEntry?.device as ManifestDevice).sensors
    .find(s => !thermostatEntry?.sensor_map?.[s.sensor_id ?? s.friendly_name]?.includes('#')), [thermostatEntry?.device, thermostatEntry?.sensor_map]);

  const currentHvacModeDevice = useMemo(() => devices
    .find(r => r.data.entity_id === thermostatEntry?.sensor_map?.[currentHvacModeSensor?.sensor_id ?? currentHvacModeSensor?.friendly_name ?? '']), [currentHvacModeSensor?.sensor_id, currentHvacModeSensor?.friendly_name, devices, thermostatEntry?.sensor_map]);

  const currentHvacModeValue = useMemo(() => currentHvacModeDevice?.data.value || '--', [currentHvacModeDevice?.data.value]);

  return (
    <Stack alignItems="center">
      <Typography variant="caption">Mode</Typography>
      <Typography variant="body1">{currentHvacModeValue.toLocaleString().toLocaleUpperCase()}</Typography>
    </Stack>
  );
}

export default ThermostatCurrentHvacMode;