import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { Thumbnail } from '@features/support-screenshot/types';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { SupportCase } from '../types';

type Props = {
  onClick?: (id: string) => void;
}

const ThumbnailList = ({ onClick }: Props) => {
  const supportCase = useAppSelector((state: RootState) => state.supportCase.case as SupportCase);
  const screenshots = useMemo(() => supportCase?.screenshots || [], [supportCase?.screenshots]);

  const renderThumbnail = useCallback((thumb: Thumbnail) => {
    const src = `data:image/png;base64,${thumb.thumbnail}`;
    return <img src={src} width="200" alt="screenshot-thumbnail" />;
  }, []);

  return (
    <>
      <Stack sx={{ width: '20%' }} spacing={2} alignItems="center">
        <Stack>
          <Typography variant="h5">Screenshots</Typography>
          <Divider />
        </Stack>
        {
          screenshots?.map((ss, idx) => (
            <Button key={`thumb-${idx}`} onClick={() => onClick && onClick(ss.screenshot_id)}>
              {renderThumbnail(ss)}
            </Button>))
        }
      </Stack>
    </>

  );
}

export default ThumbnailList;