import { riotApi } from "@features/aws-user/api";
import { U } from "@lib/utils";
import { Moment } from "moment";
import { GasBill, HvacDutyCycleRegression } from "./types";

export const energyApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      /** Obtains gas bills for property */
      gasBilling: builder.query<GasBill[], {
        userTenantId: string,
        propertyId: string,
        after?: Moment,
      }>({
        query: ({
          userTenantId,
          propertyId,
          after,
        }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/gasbilling`,
          params: after ? {
            after: after.format('YYYY-MM-DD'),
          } : undefined,
        })
      }),
      /** Delete a gas bill */
      deleteGasBill: builder.mutation<void, {
        userTenantId: string,
        propertyId: string,
        start: string,
        end: string,
      }>({
        query: ({
          userTenantId,
          propertyId,
          start,
          end,
        }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/gasbilling`,
          params: {
            start,
            end,
          },
          method: 'DELETE',
        })
      }),
      /** Create a new gas bill */
      createGasBill: builder.mutation<GasBill, {
        userTenantId: string,
        propertyId: string,
        gasBill: GasBill,
      }>({
        query: ({
          userTenantId,
          propertyId,
          gasBill,
        }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/gasbilling`,
          body: gasBill,
          method: 'PUT',
        })
      }),
      /** Obtains HVAC duty cycle vs temperature regression for property */
      hvacDutyCycleRegression: builder.mutation<HvacDutyCycleRegression, {
        userTenantId: string,
        propertyId: string,
      }>({
        query: ({
          userTenantId,
          propertyId,
        }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/analytics/hvac-duty-cycle`,
          method: 'POST',
        })
      }),
    }
  }
});

export const {
  useGasBillingQuery,
  useHvacDutyCycleRegressionMutation,
  useCreateGasBillMutation,
  useDeleteGasBillMutation,
} = energyApi;