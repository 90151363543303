import { Typography } from "@mui/material";
import NeighborhoodSummary from "./NeighborhoodSummary";
import { RootState } from "@app/store";
import { useAppSelector } from "@app/hooks";

type Props = {

};

const NeighborhoodSummaryView: React.FC<Props> = function NeighborhoodSummaryView(props: Props) {
  const neighborhood = useAppSelector((state: RootState) => state.neighborhood);

  return <>
    <Typography variant="h4" sx={{ mb: 6 }}>Neighborhood Details</Typography>
    <NeighborhoodSummary health={neighborhood.health} loadingHealth={neighborhood.loadingHealth} />
  </>;
}

export default NeighborhoodSummaryView;
