import { riotApi } from "@features/aws-user/api";
import { Aggregate, BinSize } from "@features/settings/types";
import { Category, Measure } from "@lib/labels";
import { AnalyticsSet } from "@lib/types";
import { U } from "@lib/utils";


/** API slice for the usage-related endpoints */
export const analyticsApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      /** Gets device data for a RIoT Home */
      performance: builder.query<AnalyticsSet[], {
        userTenantId: string,
        propertyId: string,
        category: Category,
        measure?: Measure,
        start: string,
        end: string,
        aggregate: Aggregate,
        binSize: BinSize | undefined,
      }>({
        query: ({
          userTenantId,
          propertyId,
          category,
          measure,
          start,
          end,
          aggregate,
          binSize,
        }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/analytics/query`,
          params: {
            category,
            measure,
            start,
            end,
            aggregate: aggregate !== Aggregate.none ? aggregate : undefined,
            time_bin: aggregate !== Aggregate.none ? binSize : undefined,
            legacy_output: false,
          }
        })
      }),
      /** Gets device data for all homes of a given plan */
      planPerformance: builder.query<AnalyticsSet[], {
        userTenantId: string,
        planIds: string[],
        category: Category,
        measure?: Measure,
        start: string,
        end: string,
        aggregate: Aggregate,
        binSize: BinSize | undefined,
      }>({
        query: ({
          userTenantId,
          planIds,
          category,
          measure,
          start,
          end,
          aggregate,
          binSize,
        }) => ({
          url: `tenant/${U(userTenantId)}/plans/analytics/query`,
          params: {
            category,
            measure,
            start: start,
            end: end,
            plan_id: planIds,
            aggregate: aggregate !== Aggregate.none ? aggregate : undefined,
            time_bin: aggregate !== Aggregate.none ? binSize : undefined,
            legacy_output: false,
          }
        })
      }),
      /** Gets device data for all homes of a given zip code */
      zipPerformance: builder.query<AnalyticsSet[], {
        userTenantId: string,
        zipCodes: string[],
        category: Category,
        measure?: Measure,
        start: string,
        end: string,
        aggregate: Aggregate,
        binSize: BinSize | undefined,
      }>({
        query: ({
          userTenantId,
          zipCodes,
          category,
          measure,
          start,
          end,
          aggregate,
          binSize,
        }) => ({
          url: `tenant/${U(userTenantId)}/locations/analytics/query?${
            zipCodes.map(p => `zip_codes=${U(p)}`).join('&')
          }`,
          params: {
            category,
            measure,
            start: start,
            end: end,
            aggregate: aggregate !== Aggregate.none ? aggregate : undefined,
            time_bin: aggregate !== Aggregate.none ? binSize : undefined,
          }
        })
      }),
      /** Gets device data for a number of RIoT Homes */
      performanceMulti: builder.query<AnalyticsSet[], {
        userTenantId: string,
        propertyIds: string[],
        category: Category,
        measure?: Measure,
        start: string,
        end: string,
        aggregate: Aggregate,
        binSize: BinSize | undefined,
        zip?: string,
        groupByProperty?: boolean,
      }>({
        query: ({
          userTenantId,
          propertyIds,
          category,
          measure,
          start,
          end,
          aggregate,
          binSize,
          zip,
          groupByProperty,
        }) => ({
          url: `tenant/${U(userTenantId)}/properties/analytics/query`,
          params: {
            property_id: (propertyIds).join(','),
            category,
            measure,
            start,
            end,
            aggregate: aggregate !== Aggregate.none ? aggregate : undefined,
            time_bin: aggregate !== Aggregate.none ? binSize : undefined,
            zip_postal_code: zip,
            legacy_output: false,
            group_by_property: groupByProperty || false,
          }
        })
      }),
      averagePerformance: builder.query<AnalyticsSet[], {
        userTenantId: string,
        category: Category,
        measure?: Measure,
        start: string,
        end: string,
        aggregate: Aggregate,
        binSize: BinSize | undefined,
      }>({
        query: ({
          userTenantId,
          category,
          measure,
          start,
          end,
          aggregate,
          binSize,
        }) => ({
          url: `tenant/${U(userTenantId)}/system/analytics/query_average`,
          params: {
            category,
            measure,
            start,
            end,
            aggregate: aggregate !== Aggregate.none ? aggregate : undefined,
            time_bin: aggregate !== Aggregate.none ? binSize : undefined,
          },
        }),
      })
    };
  }
});

export const {
  usePerformanceQuery,
  usePlanPerformanceQuery,
  useZipPerformanceQuery,
  usePerformanceMultiQuery,
  useAveragePerformanceQuery,
} = analyticsApi;
