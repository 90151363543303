import { Device } from "@features/home/types";
import { formatDate } from "@lib/utils";
import MemoryIcon from '@mui/icons-material/Memory';
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { MouseEventHandler } from "react";

type Props = {
  device: Device;
  onClick: MouseEventHandler<HTMLDivElement> | undefined;
}

/* Displays clickable unpaired device */
const UnpairedDevice = ({ device, onClick }: Props) => (
  <ListItemButton
    key={device.data.device_id}
    onClick={onClick}
  >
    <ListItemIcon sx={{ color: 'inherit' }}>
      <MemoryIcon />
    </ListItemIcon>
    <ListItemText
      primary={device.data.entity_id}
      secondary={`Latest reading was ${device.data.value} ${formatDate(device.datetime).fromNow()}`}
      about="test"
    />
  </ListItemButton>
);

export default UnpairedDevice;