import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import DeviceInfo from '@features/device/components/DeviceInfo';
import { PropertyManifestEntry } from '@features/home-manifest/types';

import { ManifestDevice } from '@features/plan-manifest/types';
import { DashboardView, DashboardViewType } from '@features/settings/types';
import { Category, findCategoryLabel } from "@lib/labels";
import {
  Box, Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Typography
} from "@mui/material";
import { useCallback, useMemo, useState } from 'react';
import { CategoryReadings, ReadingPair, RoomReadings } from '../types';
import { getDeviceName } from '../utils';
import RoomStatus from './RoomStatus';
import ViewControls from './ViewControls';


const DeviceStatus = () => {
  const property = useAppSelector((state: RootState) => state.property);
  const displayedCategories = useAppSelector((state: RootState) => state.displayedCategories);
  const preferenceState = useAppSelector((state: RootState) => state.preferences);

  const preferences = useMemo(() => preferenceState.preferences, [preferenceState.preferences]);
  const viewPreference = useMemo(() => preferences?.views?.[DashboardView.status], [preferences?.views]);

  const manifestEntries = useMemo(() => property.manifestEntries || [], [property.manifestEntries]);
  const devices = useMemo(() => property.devices || [], [property.devices]);

  const [viewType, setViewType] = useState<DashboardViewType>(viewPreference || DashboardViewType.cards);

  const handleChangeViewType = useCallback((type: DashboardViewType) => setViewType(type), []);

  const roomReadings = useMemo<RoomReadings[]>(() => manifestEntries
    .reduce<RoomReadings[]>((rooms: RoomReadings[], entry: PropertyManifestEntry) => {

      const readingsByCategory = (entry.device as ManifestDevice).sensors
        ?.reduce<CategoryReadings>((datas, ss) => ({
          ...datas,
          [ss.sensor_category]: {
            ...(datas[ss.sensor_category] || []),
            [ss.measure_name]: {
              device: devices.find(dd => dd.data.entity_id === entry.sensor_map?.[ss.sensor_id ?? ss.friendly_name]),
              sensor: ss,
              deviceName: getDeviceName(entry.device as ManifestDevice),
              area: entry.property_area,
              entryId: entry.manifest_entry_id,
            } as ReadingPair,
          }
        }), {} as CategoryReadings);

      const room = rooms.find(r => r.name === entry.property_area);

      return [
        ...rooms.filter(r => r.name !== entry.property_area),
        {
          name: entry.property_area,
          readings: Object.entries(readingsByCategory)
            .reduce<CategoryReadings>((partialReadings, [category, catReadings]) => ({
              ...partialReadings,
              [category as Category]: {
                ...(partialReadings[category as Category] || {}),
                ...catReadings,
              },
            }), room?.readings || {} as CategoryReadings),
        }
      ];
    }, [])
    .sort((a, b) => a.name.localeCompare(b.name)), [manifestEntries, devices]);


  const renderedList = useMemo(() => (
    <TableContainer component={Paper} sx={{ maxHeight: '35em' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left"><Typography variant="button" sx={{ fontWeight: 700 }}>Room</Typography></TableCell>
            {
              Object.keys(displayedCategories)
                .filter(cat => displayedCategories[cat as Category]?.length > 0)
                .map(cat => (
                  <TableCell
                    align="center"
                    key={`header-${cat}`}
                  >
                    <Typography variant="button" sx={{ fontWeight: 700 }}>{findCategoryLabel(cat)}</Typography>
                  </TableCell>
                ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            roomReadings?.map((room, idx) => (
              <TableRow key={idx}>
                <TableCell>
                  <Typography variant="button" sx={{ fontWeight: 700 }}>{room.name}</Typography>
                </TableCell>
                {
                  Object.keys(displayedCategories)
                    .filter(cat => displayedCategories[cat as Category]?.length > 0)
                    .map((cat, cidx) => {
                      const category = cat as Category;
                      if (!room) return (
                        <TableCell align="center" key={`deviceinfo-${cat}`}></TableCell>
                      );

                      return (
                        <TableCell align="center" key={`deviceinfo-${cat}`}>
                          <Stack direction="row" spacing={0.2} justifyContent="center">
                            {
                              displayedCategories[category].map((mm, midx) => (
                                <DeviceInfo
                                  key={`deviceinfo-${idx}-${cidx}-${midx}`}
                                  reading={room.readings?.[category]?.[mm]}
                                />))
                            }
                          </Stack>
                        </TableCell>
                      );
                    })
                }
              </TableRow>))
          }
        </TableBody>
      </Table>
    </TableContainer>), [displayedCategories, roomReadings]);


  const renderedCards = useMemo(() => (
    <Box component="div">
      <Grid
        container
        columns={{ xs: 1, sm: 2, md: 3, lg: 4 }}
      >
        {
          roomReadings?.map((rr, idx) => (
            <Grid key={`room-status-${idx}`} sx={{ p: 1 }} xs={1} item>
              <RoomStatus roomReadings={rr} />
            </Grid>))
        }
      </Grid>
    </Box>
  ), [roomReadings]);

  return (
    <Stack>
      <Typography variant="h5">Device readings</Typography>
      <ViewControls
        onViewTypeChange={handleChangeViewType}
      />
      {viewType === DashboardViewType.list ? renderedList : renderedCards}
    </Stack>
  );
}


export default DeviceStatus;