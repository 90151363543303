import { Category, Measure } from "@lib/labels";

export enum HighlightInterval {
  DAY = '1 day',
  WEEK = '1 week',
  MONTH = '1 month',
  YEAR = '1 year',
}


export const WATER_USAGE_CATEGORY = Category.water;
export const USAGE_TOTAL_MEASURE = Measure.usage_total;
export const USAGE_RATE_MEASURE = Measure.usage_rate;
