import { Interval } from "@features/home-analytics/types";
import { formatDate } from "@lib/utils";


export const formatBucket = (bucket: string, interval: Interval) => {
  const date = formatDate(bucket);
  const now = formatDate(null);

  switch (interval) {
    default:
    case Interval.DAY:
      if (now.dayOfYear === date.dayOfYear) return date.format('hh a');
      return date.format('[yesterday] h a');
    case Interval.WEEK:
      return date.format('dddd');
    case Interval.MONTH:
      return date.format('MMMM Qo');
    case Interval.YEAR:
      return date.format('MMMM');
  }
};

export const findIntervalLabel = (interval: Interval) => {
  switch (interval) {
    default:
    case Interval.DAY:
      return 'Last 24 hours (hourly)';
    case Interval.WEEK:
      return 'Last 7 days (daily)';
    case Interval.MONTH:
      return 'Last month (daily)';
    case Interval.YEAR:
      return 'Last 12 months (monthly)';
  }
};

export const findIntervalChartLabel = (interval: Interval) => {
  switch (interval) {
    default:
    case Interval.DAY:
      return 'day';
    case Interval.WEEK:
      return 'week';
    case Interval.MONTH:
      return 'month';
    case Interval.YEAR:
      return 'year';
  }
};

export const formatValue = (
  value: string | number | null | undefined | boolean,
  digits?: number,
): string => {
  const numericValue = isNaN(Number(value)) ? 0 : Number(value);
  if (digits !== undefined) return numericValue.toFixed(digits);
  return numericValue.toFixed(numericValue < 100 ? 1 : 0);
};