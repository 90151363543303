import { SnackType } from '@lib/types';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Card,
  CardActions, Collapse, IconButton, Paper, Typography
} from '@mui/material';
import { SnackbarContent, useSnackbar } from 'notistack';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';

type Props = {
  id: string | number;
  message: string | React.ReactNode;
  detail: string | React.ReactNode;
  variant?: SnackType;
}

type CardColors = {
  background: string;
  header: string;
  text: string;
  headerText: string;
}

const getColors = (variant: SnackType|undefined): CardColors => {
  switch (variant) {
    case SnackType.error:
      return {
        background: '#fcc4c0',
        header: '#8c2424',
        text: '#8c2424',
        headerText: '#fff'
      };
    case SnackType.info:
      return {
        background: '#a2e4f5',
        header: '#091380',
        text: '#091380',
        headerText: '#fff'
      };
    case SnackType.success:
      return {
        background: '#aae6ab',
        header: '#005701',
        text: '#005701',
        headerText: '#fff'
      };
    case SnackType.warning:
      return {
        background: '#ebddc7',
        header: '#ba7506',
        text: '#ba7506',
        headerText: '#fff'
      };
    case SnackType.default:
    default:
      return {
        background: '#878686',
        header: '#313131',
        text: '#000',
        headerText: '#fff'
      };
  }
}

const ExpandableSnack = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = useCallback(() => {
      setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  const handleDismiss = useCallback(() => {
      closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  const colors = useMemo(() => getColors(props?.variant), [props?.variant]);

  return (
    <SnackbarContent
      ref={ref}
    >
      <Card
        sx={{
          width: '100%',
          maxWidth: '300px',
        }}
      >
        <CardActions
          sx={{
            padding: '8px 8px 8px 16px',
            justifyContent: 'space-between',
            background: colors.header,
            color: colors.headerText,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 'bold',
            }}
          >{props.message}</Typography>
          <Box component="div" sx={{
            marginLeft: 'auto',
          }}>
            <IconButton
              aria-label="Show more"
              onClick={handleExpandClick}
              sx={{
                padding: '8px 8px',
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
            <IconButton
              sx={{
                padding: '8px 8px',
              }}
              onClick={handleDismiss}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Paper sx={{
            padding: 4,
            background: colors.background,
            color: colors.text,

          }}>
            {props.detail}
          </Paper>
        </Collapse>
      </Card>
    </SnackbarContent>
  );
});

export default ExpandableSnack;