import { TYPICAL_RESIDENTIAL_VOLTAGE, UtilGroup, UtilityManagement } from "@features/all-utility-groups/types";
import ChartHomeCompare from "@features/chart-settings/components/ChartHomeCompare";
import AppTextField from "@features/home/components/AppTextField";
import { Stack } from "@mui/material";
import { useFormikContext } from "formik";


const UtilityGroupFields = () => {
  const f = useFormikContext<UtilGroup>();

  const handleChangeCapacity = (e: any) => {
    const util = f.values.utility_management || {} as UtilityManagement;
    if (!util.energy_management) {
      util.energy_management = {
        svc_max_power: {
          volts: TYPICAL_RESIDENTIAL_VOLTAGE,
          amps: Number(e.target.value),
        }
      }
      f.setFieldValue('utility_management', util);
    } else {
      f.handleChange(e);
    }
  }

  return (
    <Stack spacing={1}>
      <AppTextField
        name="group_id"
        size="small"
        label="Group name"
        disabled
        fullWidth
      />
      <AppTextField
        name="utility_management.energy_management.svc_max_power.amps"
        size="small"
        label="Service Panel Capacity"
        onChange={handleChangeCapacity}
        fullWidth
      />
      <ChartHomeCompare
        initial={(f.values as UtilGroup).property_ids || []}
        onChange={(homes: string[]) => f.setFieldValue('property_ids', homes)}
        placeholder="Add homes to the utility group"
      />
    </Stack>
  );
}

export default UtilityGroupFields;