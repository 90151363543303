import { Category, Measure, Unit } from "@lib/labels";

/**
 * Always returns an array of at least 1 element
 * First value is used as default
 * @param category
 * @param measure
 * @returns array of Units for the given measure
 */
export const getUnitsByCategoryMeasure = (
  category: Category | null,
  measure: Measure | null,
): Unit[] => {
  switch (category as Category) {
    default:
    case Category.access:
      return [Unit.binary];
    case Category.air:
      switch (measure as Measure) {
        default:
        case Measure.airpressure:
        case Measure.smoke:
        case Measure.obstruction:
          return [Unit.binary];
        case Measure.co2:
          return [Unit.ppm];
        case Measure.humidity:
          return [Unit.percent];
        case Measure.loudness:
          return [Unit.dba];
        case Measure.pm2_5:
          return [Unit.ug_per_m3];
        case Measure.speed:
          return [Unit.mph, Unit.mps];
        case Measure.temperature:
        case Measure.temperature_setpoint:
          return [Unit.fahrenheit, Unit.celcius];
        case Measure.voc:
          return [Unit.ppb];
      }
    case Category.cable:
      switch (measure as Measure) {
        default:
        case Measure.status:
          return [Unit.binary];
      }
    case Category.light:
      switch (measure as Measure) {
        default:
        case Measure.switch:
          return [Unit.binary];
        case Measure.illuminance:
          return [Unit.lux];
      }
    case Category.power:
      switch (measure as Measure) {
        default:
        case Measure.duration:
          return [Unit.hours];
        case Measure.level:
          return [Unit.percent];
        case Measure.status:
        case Measure.switch:
          return [Unit.binary];
        case Measure.output_limit:
        case Measure.output_rate:
        case Measure.usage_rate:
          return [Unit.watt];
        case Measure.output_total:
        case Measure.usage_total:
          return [Unit.kwh];
      }
    case Category.soil:
      switch (measure as Measure) {
        default:
          return [Unit.binary];
        case Measure.moisture:
          return [Unit.percent];
      }
    case Category.system:
      return [Unit.binary];
    case Category.water:
      switch (measure as Measure) {
        default:
        case Measure.moisture:
        case Measure.recirculation:
        case Measure.switch:
          return [Unit.binary];
        case Measure.precipitation:
          return [Unit.inches, Unit.millimeters];
        case Measure.usage_rate:
          return [Unit.gallons_per_minute, Unit.liters_per_minute];
        case Measure.usage_total:
          return [Unit.gallons, Unit.liters];
      }
  }
}
