
import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { Tenant } from "@features/userTenant/types";
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import LoadingButton from "@mui/lab/LoadingButton";
import { Autocomplete, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, MenuItem, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { RIOT_BLUE } from "theme";
import { useUpdateUserAccessMutation, useUserAccessQuery } from "../api";
import { User } from "../types";
import TenantAutocomplete from "./TenantAutocomplete";

type Props = {
  user: User;
  open: boolean;
  onClose: (changes: boolean) => void;
}

const RolesModal = ({ user, open, onClose }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const roleDefs = useAppSelector((state: RootState) => state.users.roles);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant.builder_color || RIOT_BLUE, [currentTenant.builder_color]);

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTenant, setSelectedTenant] = useState<Tenant|null>(null);

  const {
    currentData: currentUserAcccess,
    isFetching: fetchingUserAccess,
  } = useUserAccessQuery({
    userTenantId: currentTenant.tenant_id!,
    tenantId: selectedTenant?.tenant_id!,
    userId: user.username,
  }, {
    skip: !userTenant?.tenant_id || !selectedTenant?.tenant_id || !user.username,
    refetchOnMountOrArgChange: true,
  });

  const [updateUserAccess] = useUpdateUserAccessMutation();

  const currentRoles = useMemo(
    () => new Set<string>(currentUserAcccess?.roles || []),
    [currentUserAcccess?.roles],
  );

  const [newRoles, setNewRoles] = useState<Set<string>>(new Set());

  /* Default new roles to current roles */
  useEffect(() => {
    if (fetchingUserAccess) {
      setNewRoles(new Set());
    } else {
      setNewRoles(currentRoles);
    }
  }, [currentRoles, fetchingUserAccess]);

  /** Handles setting new roles  */
  const handleSubmit = useCallback(async () => {
    setLoading(true);

    try {
      const rolesToAdd = Array.from(newRoles.values())
        .filter((sw => !currentRoles.has(sw)));
      const rolesToRemove = Array.from(currentRoles.values())
        .filter((sw => !newRoles.has(sw)));

      if (rolesToAdd.length || rolesToRemove.length) {
        const res = await updateUserAccess({
          userTenantId: currentTenant.tenant_id!,
          tenantId: selectedTenant?.tenant_id!,
          userId: user.username,
          roles: Array.from(newRoles.values()),
        });

        if (!('error' in res)) {
          enqueueSnackbar("Updated user roles", {
            variant: "success",
          });
          onClose(true);
        } else {
          const errorDetails = (res as ApiErrorResponse)?.error;
          enqueueSnackbar("Couldn't update user roles:", {
            key: "role-error",
            content: (
              <ExpandableSnack
                id="role-error"
                message={"Couldn't update user roles:"}
                variant={SnackType.error}
                detail={getErrorMessage(errorDetails)}
              />),
          });
          onClose(false);
        }
      } else {
        onClose(false);
      }
    } finally {
      setLoading(false);
    }
  }, [currentRoles, currentTenant.tenant_id, enqueueSnackbar, newRoles, onClose, selectedTenant?.tenant_id, updateUserAccess, user.username]);

  const handleRoleChange = useCallback((e: SyntheticEvent<Element, Event>, v: string[]) => {
    const newSharedwith = new Set(v.filter(e => e));
    setNewRoles(newSharedwith);
  }, []);

  const getRoleDescription = useCallback((name: string) => roleDefs
    .find(rr => rr.name === name)?.description || 'no description available', [roleDefs]);

  return (
    <Dialog open={open} scroll="paper" fullWidth onClose={onClose}>
      <DialogTitle>Update access for {[user.given_name, user.family_name].join(' ')}</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2} sx={{ mt: 1 }}>
          <TenantAutocomplete onChange={setSelectedTenant} />
          <Autocomplete
            fullWidth
            disabled={fetchingUserAccess}
            loading={fetchingUserAccess}
            multiple
            options={roleDefs.map(r => r.name).filter(nn => !newRoles.has(nn))}
            getOptionLabel={(roleName: string) => roleDefs.find(r => r.name === roleName)?.name || ''}
            defaultValue={Array.from(currentRoles.values())}
            value={Array.from(newRoles.values())}
            onChange={handleRoleChange}
            isOptionEqualToValue={(a, b) => a === b}
            renderInput={(params) => fetchingUserAccess
              ? <Box component="div" sx={{ height: '100%', transform: 'translateY(50%)' }}>
                  <LinearProgress color="secondary" sx={{transform: 'translateY(-50%)'}} />
                </Box>
              : <TextField
                  {...params}
                  disabled={fetchingUserAccess}
                  variant="outlined"
                  label="New roles"
                  placeholder="Assign a role to the user"
                />
            }
            renderOption={(_, option) => (
              <MenuItem key={`option-${option}`} onClick={(e) => handleRoleChange(e, [...Array.from(newRoles), option])}>
                <Stack>
                  <Typography variant="button" sx={{ color: themeColor }}>{option}</Typography>
                  <Typography variant="caption">
                    {getRoleDescription(option)}
                  </Typography>
                </Stack>
              </MenuItem>
            )}
            renderTags={(selected) => (
              <Stack direction="row" spacing={0.5} flexWrap="wrap">
              {
                selected.map((val, idx) => (
                  <Tooltip
                    title={getRoleDescription(val)}
                    placement="top"
                    key={`role-tag-${idx}`}
                    arrow
                  >
                    <span>
                      <Chip
                        label={<Typography variant="button" sx={{ color: themeColor }}>{val}</Typography>}
                        variant="outlined"
                        onDelete={(e) => handleRoleChange(e, selected.filter(s => s !== val))}
                        sx={{
                          borderColor: themeColor,
                          color: themeColor,
                        }}
                      />
                    </span>
                  </Tooltip>
                ))
              }
              </Stack>
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          loading={loading}
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default RolesModal;
