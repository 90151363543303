import { useAppDispatch, useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { pollingInterval } from '@features/tenant-selector/types';
import { FC, ReactElement, useEffect, useMemo } from "react";
import { usePropertyManagersQuery } from '../api';
import { setPropertyManagers } from '../slice';


const PropertyManagersProvider: FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const {
    currentData: propertyManagers,
  } = usePropertyManagersQuery({
    userTenantId: currentTenant?.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id,
    pollingInterval,
  });

  useEffect(() => {
    if (propertyManagers !== undefined) {
      dispatch(setPropertyManagers(propertyManagers));
    } else {
      dispatch(setPropertyManagers(null));
    }
  }, [dispatch, propertyManagers]);

  return children as ReactElement;
}

export default PropertyManagersProvider;