import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { ManifestDevice } from "@features/plan-manifest/types";
import { getUnitsByCategoryMeasure } from "@features/unit-settings/utils";
import { formatCategoryMeasurePair, formatReadingDetails } from "@lib/labels";
import { Typography } from "@mui/material";
import { useMemo } from "react";
import { THERMOSTAT_CATEGORY, THERMOSTAT_MEASURE, ThermostatAttributes } from "../types";


type Props = {
  entryId: string;
}

const ThermostatTemperatureSetpoint = ({ entryId }: Props) => {
  const property = useAppSelector((state: RootState) => state.property);
  const userPreferences = useAppSelector((state: RootState) => state.preferences);

  const devices = useMemo(() => property.devices || [], [property.devices]);
  const manifestEntries = useMemo(() => property.manifestEntries || [], [property.manifestEntries]);
  const thermostatEntry = useMemo(() => manifestEntries.find(me => me.manifest_entry_id === entryId), [manifestEntries, entryId]);

  const temperatureSetpointSensor = useMemo(() => (thermostatEntry?.device as ManifestDevice).sensors
    .find(s => thermostatEntry?.sensor_map?.[s.sensor_id ?? s.friendly_name]?.split('#')?.[1] === ThermostatAttributes.temperatureSetpoint), [thermostatEntry?.device, thermostatEntry?.sensor_map]);

  const temperatureSetpointDevice = useMemo(() => devices
    .find(r => r.data.entity_id === thermostatEntry?.sensor_map?.[temperatureSetpointSensor?.sensor_id ?? temperatureSetpointSensor?.friendly_name ?? '']), [temperatureSetpointSensor?.sensor_id, temperatureSetpointSensor?.friendly_name, devices, thermostatEntry?.sensor_map]);

  const preferences = useMemo(() => userPreferences.preferences, [userPreferences.preferences]);
  const unitPreferences = useMemo(() => preferences.units || {}, [preferences.units]);
  const preferredUnit = useMemo(() => (
      unitPreferences?.[formatCategoryMeasurePair(THERMOSTAT_CATEGORY, THERMOSTAT_MEASURE)]
      || getUnitsByCategoryMeasure(THERMOSTAT_CATEGORY, THERMOSTAT_MEASURE)[0]
    ), [unitPreferences]);

  const currentTemperatureDetails = useMemo(() => formatReadingDetails(
    THERMOSTAT_CATEGORY,
    THERMOSTAT_MEASURE,
    preferredUnit,
    temperatureSetpointDevice?.data.value,
  ), [preferredUnit, temperatureSetpointDevice?.data.value]);

  return (
    <Typography variant="h6">{currentTemperatureDetails.value || ''}</Typography>
  );
}

export default ThermostatTemperatureSetpoint;