import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { ManifestDevice, ZwaveConfig } from "@features/plan-manifest/types";
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { RIOT_BLUE } from "theme";
import { useUpdateDeviceMutation } from "../api";
import ZwaveConfigEntry from "./ZwaveConfigEntry";

export type ZwaveConfigModalProps = {
  device: ManifestDevice;
  onClose: (changes: boolean) => void;
};

const ZwaveConfigModal = ({ device, onClose }: ZwaveConfigModalProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant.builder_color || RIOT_BLUE, [currentTenant.builder_color]);

  const [loading, setLoading] = useState<boolean>(false);
  const [newZwaveConfig, setNewZwaveConfig] = useState<ZwaveConfig[]>(device.zwave_config || []);

  const [updateDevice] = useUpdateDeviceMutation();

  /** saves new Z-Wave config to the Device definition in cloud */
  const handleSubmit = useCallback(async () => {

    setLoading(true);

    const updatedDevice = await updateDevice({
      userTenantId: currentTenant.tenant_id!,
      deviceId: device.device_id!,
      body: {
        ...device,
        zwave_config: newZwaveConfig,
      } as Partial<ManifestDevice>,
    });

    const errorDetails = (updatedDevice as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't set z-wave config:", {
        key: "zwave-config-error",
        content: (
          <ExpandableSnack
            id="zwave-config-error"
            message={"Couldn't set z-wave config:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });

      onClose(false);
      setLoading(false);
    } else {
      enqueueSnackbar("Z-wave configuration set.", {
        variant: "success",
      });
      onClose(true);
      setLoading(false);
    }
  }, [currentTenant.tenant_id, device, enqueueSnackbar, newZwaveConfig, onClose, updateDevice]);

  /** saves new/edited Z-Wave config to local state */
  const handleSave = useCallback((oldConfig: ZwaveConfig | null, newConfig: ZwaveConfig) => {
    if (oldConfig) {
      /* Update existing entry */
      setNewZwaveConfig([
        ...newZwaveConfig.filter(e => (e.parameter !== oldConfig.parameter || (e.bitmask ?? '') !== (oldConfig.bitmask ?? ''))
          && (e.parameter !== newConfig.parameter || (e.bitmask ?? '') !== (newConfig.bitmask ?? ''))),
        newConfig,
      ]);
    } else {
      /* Add a new entry */
      setNewZwaveConfig([
        ...newZwaveConfig.filter(e => (e.parameter !== newConfig.parameter || (e.bitmask ?? '') !== (newConfig.bitmask ?? ''))),
        newConfig,
      ]);
    }
  }, [newZwaveConfig]);

  /** deletes Z-Wave config from the local state */
  const handleDelete = useCallback((config: ZwaveConfig) => {
    /* Remove entry */
    setNewZwaveConfig([
      ...newZwaveConfig.filter(e => e.parameter !== config.parameter || (e.bitmask ?? '') !== (config.bitmask ?? '')),
    ]);
  }, [newZwaveConfig]);

  return (
    <Dialog open scroll="paper" fullWidth onClose={() => onClose(false)}>
      <DialogTitle>Z-wave configuration for {device?.friendly_name || 'device'}</DialogTitle>
      <Divider sx={{ borderColor: themeColor }} />
      <DialogContent>
        <Stack spacing={1} sx={{ mt: 1 }}>
          {
            newZwaveConfig.map((zwc) => (
              <ZwaveConfigEntry
                key={[zwc.parameter, zwc.bitmask].join('-')}
                existingEntry={zwc}
                handleSave={(newConfig: ZwaveConfig) => handleSave(zwc, newConfig)}
                handleDelete={() => handleDelete(zwc)}
              />
            ))
          }
          <ZwaveConfigEntry
            handleSave={(newConfig: ZwaveConfig) => handleSave(null, newConfig)}
          />
        </Stack>
      </DialogContent>
      <Divider sx={{ borderColor: themeColor }} />
      <DialogActions>
        <Button onClick={() => onClose(false)}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          loading={loading}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>);
}


export default ZwaveConfigModal;