import DocumentsProvider from '@features/all-documents/components/DocumentsProvider';
import FloorplanProvider from '@features/floorplan/components/FloorplanProvider';
import NotificationProvider from '@features/home-notifications/components/NotificationProvider';
import LiveHome from '@features/live-home/components/LiveHome';
import WeatherProvider from '@features/weather/components/WeatherProvider';

const LiveHomeView = () => {
  return (
    <WeatherProvider>
      <NotificationProvider>
          <FloorplanProvider>
            <DocumentsProvider>
              <WeatherProvider>
                <LiveHome />
              </WeatherProvider>
            </DocumentsProvider>
          </FloorplanProvider>
      </NotificationProvider>
    </WeatherProvider>
  );
}

export default LiveHomeView;