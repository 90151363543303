import { riotApi } from "@features/aws-user/api";
import { HTTPMethodType } from "@lib/types";
import { U } from "@lib/utils";
import { SceneTemplate, SceneTemplateCreate, SceneTemplateUpdate } from "./types";


export const sceneTemplateApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      createSceneTemplate: builder.mutation<SceneTemplate, {
        userTenantId: string,
        body: SceneTemplateCreate,
      }>({
        query: ({ userTenantId, body }) => ({
          url: `tenant/${U(userTenantId)}/scenetemplates`,
          method: HTTPMethodType.POST,
          body,
        }),
      }),
      updateSceneTemplate: builder.mutation<SceneTemplate, {
        userTenantId: string,
        scenetemplateId: string,
        body: SceneTemplateUpdate,
      }>({
        query: ({ userTenantId, scenetemplateId, body }) => ({
          url: `tenant/${U(userTenantId)}/scenetemplates/${U(scenetemplateId)}`,
          method: HTTPMethodType.PATCH,
          body,
        }),
      }),
      deleteSceneTemplate: builder.mutation<SceneTemplate, {
        userTenantId: string,
        scenetemplateId: string,
      }>({
        query: ({ userTenantId, scenetemplateId }) => ({
          url: `tenant/${U(userTenantId)}/scenetemplates/${U(scenetemplateId)}`,
          method: HTTPMethodType.DELETE,
        }),
      }),
      templateInventory: builder.query<SceneTemplate[], {
        userTenantId: string,
      }>({
        query: ({ userTenantId }) => ({
          url: `tenant/${U(userTenantId)}/scenetemplates`,
        })
      })
    };
  }
});

export const {
  useCreateSceneTemplateMutation,
  useUpdateSceneTemplateMutation,
  useDeleteSceneTemplateMutation,
  useTemplateInventoryQuery,
} = sceneTemplateApi;
