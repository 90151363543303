import { Box, Button, Divider, Stack, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { SceneTemplate, SceneTemplateAction, SceneTemplateCondition, SceneTemplateUpdate } from "../types"
import SceneTemplateConditionEditor from "./SceneTemplateConditionEditor";
import { useCallback, useMemo, useState } from "react";
import { number } from "yup";
import SceneTemplateActionEditor from "./SceneTemplateActionEditor";
import { LoadingButton } from "@mui/lab";
import SceneTemplateConfigurationEditor from "./SceneTemplateConfigurationEditor";

interface SceneTemplateEditorProps {
  sceneTemplate: SceneTemplate;

  onChange: (newTemplate: SceneTemplateUpdate) => void;
  handleSave: () => Promise<void>;
  handleCancel: () => void;
}

export default function SceneTemplateEditor(props: SceneTemplateEditorProps) {
  const { handleCancel, onChange, handleSave, sceneTemplate } = props;

  const [saving, setSaving] = useState<boolean>(false);

  const saveTemplate = useCallback(async () => {
    setSaving(true);

    try {
      await handleSave();
    } finally {
      setSaving(false);
    }
  }, [handleSave]);
  
  const handleChangeCondition = useCallback((condition: SceneTemplateCondition) => {
    onChange({
      condition: condition,
    });
  }, [onChange]);

  const handleChangeAction = useCallback((action: SceneTemplateAction) => {
    onChange({
      action: action,
    });
  }, [onChange]);


  const [activeStep, setActiveStep] = useState<number>(0);

  const stepDescription = useMemo(() => {
    switch (activeStep) {
      case 0:
      default:
        return 'Define one or more conditions that would cause this Scene to be triggered. Press the + button below to add a new condition.'
      case 1:
        return 'Define what should happen when this Scene is triggered.';
      case 2:
        return 'Configure how the Scene should operate.';
      case 3:
        return 'Press the button below to save the Scene.';
    }
  }, [activeStep]);

  const stepContents = useMemo(() => {
    switch (activeStep) {
      case 0:
      default:
        return <SceneTemplateConditionEditor sceneTemplate={sceneTemplate} onChange={handleChangeCondition} />;
      case 1:
        return <SceneTemplateActionEditor sceneTemplate={sceneTemplate} onChange={handleChangeAction} />;
      case 2:
        return <SceneTemplateConfigurationEditor sceneTemplate={sceneTemplate} onChange={onChange} />;
      case 3:
        return <Stack direction="column" style={{ alignItems: 'center' }}>
          <LoadingButton loading={saving} variant="contained" color="primary" onClick={saveTemplate}>Save Scene Template</LoadingButton>
        </Stack>;
    }
  }, [activeStep, handleChangeAction, handleChangeCondition, onChange, saveTemplate, saving, sceneTemplate]);

  const prevStep = useCallback(() => setActiveStep(Math.max(0, activeStep - 1)), [activeStep]);
  const nextStep = useCallback(() => setActiveStep(Math.min(3, activeStep + 1)), [activeStep]);
  
  return <Stack direction="column" sx={{ flex: '1 1 auto' }}>
    <Stack direction="column" spacing={1}>
      <Stepper variant="outlined" activeStep={activeStep}>
        <Step completed={!!sceneTemplate.condition}>
          <StepLabel>Define Scene conditions</StepLabel>
        </Step>
        <Step completed={!!sceneTemplate.action}>
          <StepLabel>Define Scene actions</StepLabel>
        </Step>
        <Step>
          <StepLabel>Configure scene</StepLabel>
        </Step>
      </Stepper>
      <Typography variant="body1" textAlign="center" sx={{ pt: 0.5, pb: 1.5 }}>
        {stepDescription}
      </Typography>
      {stepContents}
      <Stack direction="row" sx={{ alignItems: 'flex-end', justifyContent: 'flex-end', flex: '1 1 auto', pt: 8 }} spacing={1}>
        <Button color="secondary" variant="outlined" onClick={handleCancel}>Cancel</Button>
        <Button variant="contained" onClick={prevStep} disabled={activeStep === 0}>Back</Button>
        <Button variant="contained" onClick={nextStep} disabled={activeStep >= 3}>Next</Button>
      </Stack>
    </Stack>
  </Stack>
}