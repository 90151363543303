import { Category, Measure, Unit } from "@lib/labels";

export enum ChartType {
  line = 'line',
  bar = 'bar',
  area = 'area',
  column = 'column',
  heatmap = 'heatmap',
  rangebar = 'rangeBar',
  scatter = 'scatter',
  box = 'boxPlot',
}

export type ApexChartType = "area" | "line" | "bar" | "histogram" | "pie" | "donut" | "radialBar" | "scatter" | "bubble" | "heatmap" | "treemap" | "boxPlot" | "candlestick" | "radar" | "polarArea" | "rangeBar" | undefined;

export enum BinSize {
  hour = '1h',
  day = '1d',
  week = '7d',
  month = '30d', // Timestream does not support month intervals
  // See https://docs.aws.amazon.com/timestream/latest/developerguide/supported-data-types.html
}

export enum Aggregate {
  avg = 'AVG',
  min = 'MIN',
  max = 'MAX',
  sum = 'SUM',
  none = 'NONE',
}

export enum interval {
  oneday = '1D',
  oneweek = '7D',
  onemonth = '1M',
  twomonths = '2M',
  threemonths = '3M',
  sixmonths = '6M',
  twelvemonths = '12M',
}

export const findMeasureLabel = (i: interval) => {
  switch (i) {
    default:
    case interval.oneday:
      return 'One day';
    case interval.oneweek:
      return 'One week';
    case interval.onemonth:
      return 'One month';
    case interval.twomonths:
      return '2 months';
    case interval.threemonths:
      return '3 months';
    case interval.sixmonths:
      return '6 months';
    case interval.twelvemonths:
      return '12 months';
  }
};

export type ChartInterval = {
  days?: number;
  months?: number;
  years?: number;
};

export type ChartIntervalOption = {
  label: interval;
  duration: ChartInterval;
};

export enum TrendLines {
  none = 'None',
  individual = 'Per Device',
  combined = 'Combined',
}

/** preferences related to a single chart */
export interface ChartPreferences {
  trendLines: TrendLines;
  showAverage: boolean;
  showForecast: boolean;
  binSize: BinSize;
  category: Category;
  measure: Measure;
  chartType: ChartType;
  interval: interval;
  aggregate: Aggregate;
  comparePlans?: string[];
  compareHomes?: string[];
  compareZip?: string[];
}

export enum ChartOption {
  trendLines = 'trendLines',
  showAverage = 'showAverage',
  showForecast = 'showForecast',
  binSize = 'binSize',
  category = 'category',
  measure = 'measure',
  chartType = 'chartType',
  interval = 'interval',
  aggregate = 'aggregate',
  comparePlans = 'comparePlans',
  compareHomes = 'compareHomes',
  compareZip = 'compareZip',
}

/** preference on whether show current status as text or not */
export type CurrentStatusPreferences = {
  [key in Category]?: boolean;
};

export enum AnalyticsType {
  usage = 'usage',
  performance = 'performance',
}

export type ChartsPreferences = {
  [key in AnalyticsType]?: ChartPreferences;
};

export enum DashboardViewType {
  list = 'List',
  cards = 'Cards'
}

export enum DashboardView {
  homes = 'Homes',
  status = 'Device Status',
}

export type ViewPreferences = {
  [key in DashboardView]: DashboardViewType
}

export type UnitSelection = {
  [categoryMeasurePair: string]: Unit[];
}

export type UnitPreferences = {
  [categoryMeasurePair: string]: Unit;
}

export interface Preferences {
  charts: ChartsPreferences;
  views: ViewPreferences;
  units: UnitPreferences;
}

export interface PreferencesContextType {
  getPrefs: (callback: VoidFunction) => Promise<void>;
  setPrefs: (prefs: Partial<Preferences>, callback: VoidFunction) => Promise<void>;
  preferences: Preferences;
}

export type ChartOptionValueType =
  interval | ChartType | Category | Measure | BinSize | Aggregate | boolean | string[] | TrendLines | string;
