import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { PropertyManifestEntry } from "@features/home-manifest/types";
import { Device } from "@features/home/types";
import { ManifestDevice, Sensor } from "@features/plan-manifest/types";
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  Button, CircularProgress, Container,
  MobileStepper,
  Stack,
  Typography
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useBeginExclusionModeMutation,
  useBeginInclusionModeMutation,
  useConfigureDeviceMutation,
  useCreateSmartstartEntryMutation,
  useUpdatePropertyManifestEntryMutation
} from "../../api";
import AllPaired from "./AllPaired";
import DeviceModal from "./DeviceModal";
import DevicesToPair from "./DevicesToPair";
import NotAllPaired from "./NotAllPaired";
import PairingModal from "./PairingModal";
import ResetModal from "./ResetModal";
import UnpairingModal from "./UnpairingModal";

import { useGetDevicesQuery, useGetPropertyManifestQuery } from "@features/home/api";
import { pollingInterval } from "@features/tenant-selector/types";
import { Category } from "@lib/labels";
import ManualLinkModal from "./ManualLinkModal";
import ManualUnlinkModal from "./ManualUnlinkModal";
import SmartstartDialog from "./SmartstartDialog";

const ProvisionDevices = () => {
  const { enqueueSnackbar } = useSnackbar();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const selectedTenant = useAppSelector((state: RootState) => state.tenant);
  const property = useAppSelector((state: RootState) => state.property);

  const devices = useMemo(() => property.devices || [], [property.devices]);
  const currentTenant = useMemo(() => selectedTenant.currentTenant || userTenant, [selectedTenant.currentTenant, userTenant]);
  const manifestEntries = useMemo(() => property.manifestEntries, [property.manifestEntries]);

  const [activeStep, setActiveStep] = useState(0);
  const [pairing, setPairing] = useState<boolean>(false);
  const [unpairing, setUnpairing] = useState<boolean>(false);
  const [deviceToLink, setDeviceToLink] = useState<[PropertyManifestEntry, Sensor] | null>(null);
  const [deviceToUnpair, setDeviceToRemove] = useState<PropertyManifestEntry | null>(null);
  const [deviceToPair, setDeviceToPair] = useState<PropertyManifestEntry | null>(null);
  const [deviceToScan, setDeviceToScan] = useState<PropertyManifestEntry | null>(null);
  const [deviceToReset, setDeviceToReset] = useState<PropertyManifestEntry | null>(null);
  const [deviceToManualLink, setDeviceToManualLink] = useState<PropertyManifestEntry | null>(null);
  const [deviceToManualUnlink, setDeviceToManualUnlink] = useState<PropertyManifestEntry | null>(null);

  const {
    refetch: refetchDevices,
  } = useGetDevicesQuery({
    tenantId: currentTenant?.tenant_id || '',
    propertyId: property?.property_id || '',
  }, {
    skip: !currentTenant.tenant_id || !property?.property_id,
  });

  const {
    refetch: refetchManifest,
  } = useGetPropertyManifestQuery({
    tenantId: currentTenant?.tenant_id || '',
    propertyId: property?.property_id || '',
    includeEntries: true,
  }, {
    skip: !currentTenant.tenant_id || !property?.property_id,
  });

  const [
    beginInlusionMode,
  ] = useBeginInclusionModeMutation();

  const [
    beginExclusionMode,
  ] = useBeginExclusionModeMutation();

  const [
    createSmartstartEntry
  ] = useCreateSmartstartEntryMutation();
  const [
    configureDevice,
  ] = useConfigureDeviceMutation();

  const [
    updatePropertyManifestEntry,
  ] = useUpdatePropertyManifestEntryMutation();

  const handleNext = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };


  const formatEntry = useCallback((entityId: string, entry: PropertyManifestEntry, sensor: Sensor): Partial<PropertyManifestEntry> => {
    if (!manifestEntries) return {};

    const sensorPaired = (ss: Sensor) => {
      return ss === sensor || Boolean(entry.sensor_map?.[ss.sensor_id ?? ss.friendly_name]);
    };

    const provisioned = (entry.device as ManifestDevice).sensors.every(sensorPaired)
      ? Math.round(Date.now() / 1000).toString()
      : undefined;

    return {
      provisioned: provisioned ?? entry.provisioned,
      sensor_map: {
        ...(entry.sensor_map || {}),
        [sensor.sensor_id ?? sensor.friendly_name]: entityId,
      }
    };
  }, [manifestEntries]);

  /* Get set of devices at current step */
  const devicesByStep = useMemo(() => {
    return manifestEntries
      .filter((e => !(e.device as ManifestDevice)?.sensors
        ?.some(s => s.sensor_category === Category.system)))
      .reduce((acc, entry) => {
        const order = Number(entry.provision_order);
        const devicesAtStep = acc.get(order) || new Set();
        devicesAtStep.add(entry);
        acc.set(order, devicesAtStep);
        return acc;
      }, new Map<number, Set<PropertyManifestEntry>>());
  }, [manifestEntries]);

  const steps = useMemo(() => devicesByStep ? Array.from(devicesByStep.values()) : [], [devicesByStep]);
  const totalSteps = useMemo(() => steps.length, [steps.length]);
  const currentManifestDevices = useMemo(() => Array.from((steps[activeStep] || new Set()).values()), [activeStep, steps]);

  /* Poll for device and manifest refresh */
  useEffect(() => {
    const interval = setInterval(() => {
      refetchManifest();
    }, pollingInterval / 4);
    return () => clearInterval(interval);
  }, [refetchManifest]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetchDevices();
    }, pollingInterval);
    return () => clearInterval(interval);
  }, [refetchDevices]);

  /* Track changes to devices */
  const entitiesByDevice: Record<string, Set<string>> | null = useMemo(() => {
    return devices?.reduce<Record<string, Set<string>>>((a, v) => ({
      ...a,
      [v.data.device_id]: new Set([
        ...Array.from((a[v.data.device_id] || new Set()).values()),
        v.data.entity_id,
      ])
    }), {}) || null;
  }, [devices]);

  const [prevEntitiesByDevice, setPrevEntitiesByDevice] = useState<typeof entitiesByDevice | null>(null);

  useEffect(() => {
    if (entitiesByDevice !== null) {
      if (prevEntitiesByDevice !== null) {
        /* Notify user if any devices/entities were added or removed */
        const oldDevices = new Set(Object.keys(prevEntitiesByDevice));
        const newDevices = new Set(Object.keys(entitiesByDevice));
        const oldEntities = new Set(Object.values(prevEntitiesByDevice).map(es => Array.from(es.values())).flat(1));
        const newEntities = new Set(Object.values(entitiesByDevice).map(es => Array.from(es.values())).flat(1));

        newDevices.forEach((deviceId) => {
          if (deviceId && !oldDevices.has(deviceId)) {
            enqueueSnackbar(`New device added: ${deviceId}`, {
              variant: 'success',
            });
            setPairing(false);

            /* If a device was added during pairing, set the gateway_device_id on the open entry and configure the device */
            if (deviceToPair && currentManifestDevices?.length === 1 && !currentManifestDevices[0]?.gateway_device_id) {
                const deviceName = (currentManifestDevices[0].device as ManifestDevice).friendly_name;
  
                updatePropertyManifestEntry({
                  userTenantId: currentTenant?.tenant_id || '',
                  propertyId: property?.property_id || '',
                  entryId: currentManifestDevices[0].manifest_entry_id,
                  body: {
                    gateway_device_id: deviceId,
                  },
                }).then(() => {
                  enqueueSnackbar(`Paired device ${deviceId} to ${deviceName}`, {
                    variant: 'success',
                  });
                  refetchManifest();
                });  
                configureDevice({
                  userTenantId: currentTenant?.tenant_id || '',
                  propertyId: property?.property_id || '',
                  entryId: currentManifestDevices[0].manifest_entry_id,
                }).then(() => {
                  enqueueSnackbar(`Configured device ${deviceName}`, {
                    variant: 'success',
                  });
                });
              }
          }
        });

        newEntities.forEach((entityId) => {
          if (entityId && !oldEntities.has(entityId)) {
            enqueueSnackbar(`New device entity added: ${entityId}`, {
              variant: 'success',
            });
          }
        });

        oldDevices.forEach((deviceId) => {
          if (deviceId && !entitiesByDevice[deviceId]) {
            enqueueSnackbar(`Device removed: ${deviceId}`, {
              variant: 'warning',
            });
            setUnpairing(false);
            refetchManifest();
          }
        });

        oldEntities.forEach((entityId) => {
          if (entityId && !newEntities.has(entityId)) {
            enqueueSnackbar(`Device entity removed: ${entityId}`, {
              variant: 'warning',
            });
          }
        });
      }

      setPrevEntitiesByDevice(entitiesByDevice);
    }
  }, [
    configureDevice, currentManifestDevices, currentTenant?.tenant_id, deviceToPair, enqueueSnackbar, entitiesByDevice,
    prevEntitiesByDevice, property?.property_id, refetchManifest, updatePropertyManifestEntry]);

  /* Pairs device on click */
  const handlePairing = useCallback(async (dd: Device) => {
    if (!manifestEntries || !deviceToLink) return;

    const updatedEntry = await updatePropertyManifestEntry({
      userTenantId: currentTenant?.tenant_id || '',
      propertyId: property?.property_id || '',
      entryId: deviceToLink[0].manifest_entry_id,
      body: formatEntry(dd.data.entity_id, deviceToLink[0], deviceToLink[1]),
    });

    const errorDetails = (updatedEntry as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't pair entry:", {
        key: "entry-error",
        content: (
          <ExpandableSnack
            id="entry-error"
            message={"Couldn't pair entry:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });

    } else {
      enqueueSnackbar("Paired entry", {
        variant: "success",
      });

      refetchDevices();
      refetchManifest();

      setDeviceToLink(null);
    }
  }, [
    deviceToLink,
    enqueueSnackbar,
    formatEntry,
    manifestEntries,
    property?.property_id,
    refetchDevices,
    refetchManifest,
    updatePropertyManifestEntry,
    currentTenant?.tenant_id,
  ]);

  /**
   * Unpairs Device
   * Clears manifest entry id on the target device,
   * clears provisioned timestamp from manifest entry,
   * then refreshes devices and manifest
   */
  const handleUnpairing = useCallback(async (
    manifestEntry: PropertyManifestEntry,
    sensor: Sensor,
  ): Promise<void> => {
    if (!currentTenant?.tenant_id) return;
    if (!property?.property_id) return;

    const updatedEntry = await updatePropertyManifestEntry({
      userTenantId: currentTenant.tenant_id,
      propertyId: property.property_id,
      entryId: manifestEntry.manifest_entry_id,
      body: formatEntry('', manifestEntry, sensor),
    });

    const errorDetails = (updatedEntry as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't unpair entry:", {
        key: "entry-error",
        content: (
          <ExpandableSnack
            id="entry-error"
            message={"Couldn't unpair entry:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("Unpaired entry", {
        variant: "success",
      });

      refetchDevices();
      refetchManifest();

      setDeviceToLink(null);
    }

    refetchManifest();
    refetchDevices();
  }, [
    currentTenant.tenant_id,
    enqueueSnackbar,
    formatEntry,
    property.property_id,
    refetchDevices,
    refetchManifest,
    updatePropertyManifestEntry,
  ]);

  /* Function to begin pairing mode (inclusion mode) */
  const beginPairing = useCallback(async () => {
    const INCLUSION_MODE_DURATION = 30000 as const;

    if (!currentTenant?.tenant_id || !property?.property_id) return;
    if (pairing) return;
    const inclusionMode = await beginInlusionMode({
      userTenantId: currentTenant.tenant_id,
      propertyId: property.property_id,
    });

    const errorDetails = (inclusionMode as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't start inclusion mode:", {
        key: "inclusion-error",
        content: (
          <ExpandableSnack
            id="inclusion-error"
            message={"Couldn't start inclusion mode:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });

    } else {
      enqueueSnackbar("Started inclusion mode", {
        variant: "success",
      });

      refetchDevices();
      refetchManifest();
    }

    setPairing(true);

    await new Promise((res) => {
      setTimeout(res, INCLUSION_MODE_DURATION);
    });

    setPairing(false);
  }, [
    beginInlusionMode,
    enqueueSnackbar,
    pairing,
    property.property_id,
    refetchDevices,
    refetchManifest,
    currentTenant.tenant_id,
  ]);

  /* Function to begin unpairing mode (exclusion mode) */
  const beginUnpairing = useCallback(async () => {
    const EXCLUSION_MODE_DURATION = 30000 as const;

    if (!currentTenant?.tenant_id || !property?.property_id) return;
    if (unpairing) return;

    const exclusionMode = await beginExclusionMode({
      userTenantId: currentTenant.tenant_id,
      propertyId: property.property_id,
    });

    const errorDetails = (exclusionMode as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't start exclusion mode:", {
        key: "inclusion-error",
        content: (
          <ExpandableSnack
            id="exclusion-error"
            message={"Couldn't start exclusion mode:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("Started exclusion mode", {
        variant: "success",
      });

      refetchDevices();
      refetchManifest();
    }

    setUnpairing(true);

    await new Promise((res) => {
      setTimeout(res, EXCLUSION_MODE_DURATION);
    });

    setUnpairing(false);
  }, [
    beginExclusionMode,
    enqueueSnackbar,
    property.property_id,
    refetchDevices,
    refetchManifest,
    unpairing,
    currentTenant.tenant_id,
  ]);

  /* Function to add SmartStart entry */
  const addSmartstartEntry = useCallback(async (qrCodeString: string) => {
    if (!currentTenant?.tenant_id || !property?.property_id || !deviceToScan) return;

    try {
      let result = await updatePropertyManifestEntry({
        userTenantId: currentTenant.tenant_id,
        propertyId: property.property_id,
        entryId: deviceToScan.manifest_entry_id,
        body: {
          pairing_data: qrCodeString,
        }
      });

      if ('error' in result && result.error) throw result.error;
  
      result = await createSmartstartEntry({
        userTenantId: currentTenant.tenant_id,
        propertyId: property.property_id,
        deviceName: deviceToScan.manifest_entry_id,
        propertyArea: deviceToScan.property_area,
        qrCodeString,
      });

      if ('error' in result && result.error) throw result.error;

      enqueueSnackbar("QR code data sent to gateway", {
        variant: "success",
      });
    } catch (e: any) {
      enqueueSnackbar("Couldn't send code to gateway:", {
        key: "smartstart-error",
        content: (
          <ExpandableSnack
            id="smartstart-error"
            message={"Couldn't send code to gateway:"}
            variant={SnackType.error}
            detail={getErrorMessage(e)}
          />),
      });
    }
  }, [currentTenant.tenant_id, property.property_id, deviceToScan, updatePropertyManifestEntry, createSmartstartEntry, enqueueSnackbar]);

  /* Display Confirmation Screen */
  const renderedConfirmationScreen = useMemo(() => (
    <Box component="div">
      {
        manifestEntries
          .every(e => e.provisioned || (e.device as ManifestDevice)?.sensors
            ?.some(s => s.sensor_category === Category.system))
          ? <AllPaired />
          : <NotAllPaired />
      }
    </Box>
  ), [manifestEntries]);

  /* Displays progress spinner if manifest or devices are not ready*/
  if (!manifestEntries?.length || !devices) {
    return (
      <Container sx={{ mt: 1 }}>
        <Stack direction="row" spacing={2} sx={{ height: '10em' }}>
          <Box
            component="div"
            sx={{
              width: '100%',
              display: 'flex',
            }}
            justifyContent="center"
            alignItems="center"
          >
            {(devices && manifestEntries)
              ? <Typography variant="h4">This home doesn't have any manifest entries</Typography>
              : <CircularProgress />
            }
          </Box>
        </Stack>
      </Container>);
  }


  /* MAIN RENDER */
  return (
    <>
      <Stack alignItems="center" sx={{ width: '100%', padding: 0.5 }}>
        {
          activeStep === steps.length
            ? renderedConfirmationScreen
            : <DevicesToPair
                entries={currentManifestDevices}
                devices={devices || []}
                onPair={setDeviceToLink}
                onUnpair={handleUnpairing}
                onRemove={setDeviceToRemove}
                onAdd={setDeviceToPair}
                onAddSmartstart={setDeviceToScan}
                onReset={setDeviceToReset}
                onManualUnlink={setDeviceToManualUnlink}
                onManualLink={setDeviceToManualLink}
              />
        }
        <MobileStepper
          variant="progress"
          steps={totalSteps + 1}
          position="static"
          activeStep={activeStep}
          sx={{ maxWidth: 400, flexGrow: 1 }}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === totalSteps}
            >
              Next
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />
      </Stack>
      {
        deviceToUnpair &&
        <UnpairingModal
          entry={manifestEntries.find(e => e.manifest_entry_id === deviceToUnpair.manifest_entry_id) ?? deviceToUnpair}
          isUnpairing={unpairing}
          onUnpair={beginUnpairing}
          isOpen={deviceToUnpair !== null}
          onClose={() => setDeviceToRemove(null)}
        />
      }
      {
        deviceToPair &&
        <PairingModal
          entry={manifestEntries.find(e => e.manifest_entry_id === deviceToPair.manifest_entry_id) ?? deviceToPair}
          isPairing={pairing}
          onPair={beginPairing}
          isOpen={deviceToPair !== null}
          onClose={() => setDeviceToPair(null)}
        />
      }
      {
        deviceToScan &&
        <SmartstartDialog
          entry={manifestEntries.find(e => e.manifest_entry_id === deviceToScan.manifest_entry_id) ?? deviceToScan}
          addSmartstartEntry={addSmartstartEntry}
          isOpen={deviceToScan !== null}
          onClose={() => setDeviceToScan(null)}
        />
      }
      {
        deviceToReset &&
        <ResetModal
          entry={deviceToReset}
          isOpen={deviceToReset !== null}
          onClose={() => setDeviceToReset(null)}
        />
      }
      {
        deviceToManualLink &&
        <ManualLinkModal
          entry={deviceToManualLink}
          isOpen={deviceToManualLink !== null}
          onClose={() => setDeviceToManualLink(null)}
        />
      }
      {
        deviceToManualUnlink &&
        <ManualUnlinkModal
          device={deviceToManualUnlink}
          onSubmit={()=> setDeviceToManualUnlink(null)}
        />
      }
      {
        deviceToLink &&
        <DeviceModal
          isOpen={deviceToLink !== null}
          entryArr={deviceToLink ? [deviceToLink[0]] : []}
          onClose={() => setDeviceToLink(null)}
          onSelect={handlePairing}
          sensorType={deviceToLink ? deviceToLink[1].sensor_category : null}
        />
      }
    </>
  );
}

export default ProvisionDevices;
