import { homeSupportCasesApi } from "@features/home-support-cases/api";
import { SupportCase } from "@features/support-case/types";
import { U } from "@lib/utils";


export const allSupportCasesApi = homeSupportCasesApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      queryCases: builder.query<SupportCase[], {
        userTenantId: string,
        propertyId: string,
        all?: boolean,
        tenantId?: string,
        username?: string,
        homeownerId?: string,
        builderId?: string,
        issueSummary?: string,
        manifestEntryId?: string,
        isDeleted?: string,
        resolved?: boolean,
      }>({
        query: ({
          userTenantId,
          propertyId,
          all = false,
          tenantId,
          username,
          homeownerId,
          builderId,
          issueSummary,
          manifestEntryId,
          isDeleted = false,
          resolved,
        }) => {
          const params = {
            all_properties: all,
            is_deleted: isDeleted,
          } as any;
          if (!!tenantId) params.tenant_id = tenantId;
          if (!!username) params.username = username;
          if (!!homeownerId) params.homeowner_id = homeownerId;
          if (!!builderId) params.builder_id = builderId;
          if (!!issueSummary) params.issue_summary = issueSummary;
          if (!!manifestEntryId) params.manifest_entry_id = manifestEntryId;
          if (resolved !== undefined) params.resolved = resolved;

          return ({
            url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/support`,
            params,
          });
        },
      }),
    };
  },
});

export const {
  useCasesQuery,
  useCreateCaseMutation,
  useDeleteCaseMutation,
  useResolveCaseMutation,
  useUpdateCaseMutation,
  useQueryCasesQuery,
} = allSupportCasesApi;