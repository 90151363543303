import { riotApi } from "@features/aws-user/api";
import { PropertyManifestEntry, PropertyManifestEntryCreate, PropertyManifestEntryUpdate } from "@features/home-manifest/types";
import { HTTPMethodType } from '@lib/types';
import { U } from "@lib/utils";

export const homeManifestApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      createManifestEntry: builder.mutation<PropertyManifestEntry, {
        propertyId: string,
        userTenantId: string,
        newEntry: PropertyManifestEntryCreate,
      }>({
        query: ({ propertyId, userTenantId, newEntry }) => ({
          url: `/tenant/${U(userTenantId)}/properties/${U(propertyId)}/manifestentries`,
          method: HTTPMethodType.POST,
          body: newEntry,
        }),
      }),
      updateManifestEntry: builder.mutation<PropertyManifestEntry, {
        propertyId: string,
        userTenantId: string,
        manifestEntryId: string,
        updates: PropertyManifestEntryUpdate,
      }>({
        query: ({ propertyId, userTenantId, manifestEntryId, updates }) => ({
          url: `/tenant/${U(userTenantId)}/properties/${U(propertyId)}/manifestentries/${U(manifestEntryId)}`,
          method: HTTPMethodType.PATCH,
          body: updates,
        }),
      }),
      deleteManifestEntry: builder.mutation<PropertyManifestEntry, {
        propertyId: string,
        userTenantId: string,
        manifestEntryId: string,
      }>({
        query: ({ propertyId, userTenantId, manifestEntryId }) => ({
          url: `/tenant/${U(userTenantId)}/properties/${U(propertyId)}/manifestentries/${U(manifestEntryId)}`,
          method: HTTPMethodType.DELETE,
        }),
      }),
    };
  },
});

export const {
  useCreateManifestEntryMutation,
  useUpdateManifestEntryMutation,
  useDeleteManifestEntryMutation,
} = homeManifestApi;