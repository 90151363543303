import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import ExpandableSnack from '@features/Common/ExpandableSnack';
import { PropertyManifestEntry } from '@features/home-manifest/types';
import { getDeviceName } from '@features/home-status/utils';
import { useGetPropertyManifestQuery } from '@features/home/api';
import { ManifestDevice } from '@features/plan-manifest/types';
import { ApiErrorResponse, SnackType } from '@lib/types';
import { getErrorMessage } from '@lib/utils';
import { LoadingButton } from '@mui/lab';
import { ButtonGroup, Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from 'react';
import { RIOT_BLUE } from "theme";
import { useDeleteManifestEntryMutation } from '../api';


const sortDevices = (a: PropertyManifestEntry, b: PropertyManifestEntry) => {
  const aLabel = getDeviceName(a.device as ManifestDevice).toLocaleLowerCase();
  const bLabel = getDeviceName(b.device as ManifestDevice).toLocaleLowerCase();
  return bLabel > aLabel ? 1 : -1;
}


const DeletePropertyManifestEntry = () => {
  const { enqueueSnackbar } = useSnackbar();

  const home = useAppSelector((state: RootState) => state.property);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const manifestEntries = useMemo(() => home.manifestEntries || [], [home.manifestEntries]);
  const themeColor = useMemo(() => currentTenant?.builder_color || RIOT_BLUE, [currentTenant?.builder_color]);

  const [deleting, setDeleting] = useState<boolean>(false);

  const [selectedDevices, setSelectedDevices] = useState<PropertyManifestEntry[]>([]);

  const filteredEntries = useMemo(() => {
    return manifestEntries;
  }, [manifestEntries]);

  const handleToggleDevice = useCallback((value: string) => {

    if (selectedDevices.some(d => d.manifest_entry_id === value)) {
      setSelectedDevices([
        ...selectedDevices.filter(d => d.manifest_entry_id!== value),
      ].sort(sortDevices));
    } else {
      setSelectedDevices([
        ...selectedDevices,
        manifestEntries.find(e => e.manifest_entry_id === value) as PropertyManifestEntry,
      ].filter(d => !!d.manifest_entry_id).sort(sortDevices));
    }
  }, [manifestEntries, selectedDevices]);

  const [
    deleteManifestEntry,
  ] = useDeleteManifestEntryMutation();

  const {
    refetch: refetchManifest,
  } = useGetPropertyManifestQuery({
    tenantId: currentTenant?.tenant_id || '',
    propertyId: home?.property_id || '',
    includeEntries: true,
  })

  const handleDeleteEntries = useCallback(async () => {
    setDeleting(true);
    await Promise.all(
      selectedDevices.map(d => deleteManifestEntry({
        userTenantId: currentTenant?.tenant_id || '',
        propertyId: home?.property_id || '',
        manifestEntryId: d.manifest_entry_id,
      }))
    ).then(results => {
      results.forEach(r => {
        const errorDetails = (r as ApiErrorResponse)?.error;
        if (errorDetails) {
          enqueueSnackbar("Couldn't delete device:", {
            key: "delete-manifest-entry-error",
            content: (
              <ExpandableSnack
                id="delete-manifest-entry-error"
                message={"Couldn't delete device:"}
                variant={SnackType.error}
                detail={getErrorMessage(errorDetails)}
              />),
          });
        } else {
          refetchManifest();
          setSelectedDevices([]);
          const area = ((r as any).data as PropertyManifestEntry).property_area;
          enqueueSnackbar(`Successfully deleted device from ${area}`, {
            variant: "success",
          });
          setDeleting(false);
        }
      });
    });

  }, [currentTenant?.tenant_id, deleteManifestEntry, enqueueSnackbar, home?.property_id, refetchManifest, selectedDevices]);

  return <Stack spacing={1} alignItems="flex-end">
    <List
      sx={{
        border: `1px solid ${themeColor}`,
        height: '27em',
        width: '100%',
        overflowY: 'auto',
      }}
    >
      {
        filteredEntries.map(ee => (
          <ListItem
            key={`home-entry-${ee.manifest_entry_id}`}
            disablePadding
          >
            <ListItemButton
              role={undefined}
              onClick={() => handleToggleDevice(ee.manifest_entry_id)}
              dense
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedDevices.some(d => d.manifest_entry_id === ee.manifest_entry_id)}
                  tabIndex={-1}
                  disabled={deleting}
                  disableRipple
                  inputProps={{ 'aria-labelledby': `home-entry-${ee.manifest_entry_id}` }}
                />
              </ListItemIcon>
              <ListItemText
                id={`home-entry-${ee.manifest_entry_id}`}
                primary={<Stack>
                  <Typography variant="h6">{getDeviceName(ee.device as ManifestDevice)} in {ee.property_area}</Typography>
                  <Typography variant="caption">({[
                    ee.coordinates?.x_axis,
                    ee.coordinates?.y_axis,
                    ee.coordinates?.z_axis,
                  ].join(', ')})</Typography>
                </Stack>}
              />
            </ListItemButton>
          </ListItem>
        ))
      }
    </List>
    <ButtonGroup>
      <LoadingButton
        variant='outlined'
        color="error"
        loading={deleting}
        disabled={!selectedDevices.length || deleting}
        onClick={handleDeleteEntries}
      >
        Delete selected
      </LoadingButton>
    </ButtonGroup>
  </Stack>;
}

export default DeletePropertyManifestEntry;