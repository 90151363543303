/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPrefs = /* GraphQL */ `
  query GetPrefs {
    getPrefs {
      id
      prefs
    }
  }
`;
