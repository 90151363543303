import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import InventoryProvider from "@features/inventory/components/InventoryProvider";
import DeviceSelector from "@features/plan-manifest/components/DeviceSelector";
import ManifestEntryPosition from "@features/plan-manifest/components/ManifestEntryPosition";
import { Stack, TextField } from "@mui/material";
import { DeviceCoordinates, PropertyManifestEntryUpdate } from "../types";

import { ManifestEntryCreate } from '@features/plan-manifest/types';
import { useCallback, useMemo } from 'react';

type Props = {
  currentEntry: PropertyManifestEntryUpdate;
  onChange: (entry: PropertyManifestEntryUpdate) => void;
};

const PropertyManifestEntryFields = ({ currentEntry, onChange }: Props) => {
  const home = useAppSelector((state: RootState) => state.property);

  const manifestEntries = useMemo(() => home.manifestEntries || [], [home.manifestEntries]);

  const currentProvisionOrder = useMemo(() => manifestEntries
    .reduce((max, a) => max > Number(a.provision_order) || 0 ? max : Number(a.provision_order) || 0,
      Number(manifestEntries?.[0]?.provision_order) || 0), [manifestEntries]);

  const handleSelectDevice = useCallback((deviceId: string|null) => {
    if (!!deviceId) {
      onChange({
        device: deviceId,
      } as PropertyManifestEntryUpdate);
    }
  }, [onChange]);

  const handleChangePosition = useCallback((position: Partial<ManifestEntryCreate> | PropertyManifestEntryUpdate) => {
    onChange({
      property_area: (position as PropertyManifestEntryUpdate).property_area,
      coordinates: {
        x_axis: (position as PropertyManifestEntryUpdate).coordinates?.x_axis || '0',
        y_axis: (position as PropertyManifestEntryUpdate).coordinates?.y_axis || '0',
        z_axis: (position as PropertyManifestEntryUpdate).coordinates?.z_axis || '0',
      } as DeviceCoordinates,
    } as PropertyManifestEntryUpdate);
  }, [onChange]);

  return (
    <Stack spacing={1} sx={{ padding: '10px' }}>
      <TextField
        variant="outlined"
        label="Provision order"
        type="number"
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]+' }}
        disabled
        value={currentEntry.provision_order || String(currentProvisionOrder + 1)}
        size="small"
      />
      <InventoryProvider>
        <DeviceSelector
          onChange={handleSelectDevice}
          selected={currentEntry.device}
          compact
        />
      </InventoryProvider>
      <ManifestEntryPosition
        existingPosition={currentEntry}
        onChange={handleChangePosition}
      />
    </Stack>
    );
}

export default PropertyManifestEntryFields;