import SensorIcon from "@features/inventory/components/SensorIcon";
import { List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { ManifestDevice } from "../types";

type Props = {
  device: ManifestDevice
}

const DeviceSensors = ({ device }: Props) => {
  return (
      <List
        dense
        sx={{
          width: '100%',
          position: 'relative',
          overflow: 'auto',
          maxHeight: 600,
        }}
        subheader={
          <Typography variant="subtitle1" align="center">Sensors</Typography>
        }
      >
        {
          device?.sensors.map(sensor => (
            <ListItemButton
              key={sensor.order}
            >
              <ListItemIcon>
                <SensorIcon sensor={sensor} />
              </ListItemIcon>
              <ListItemText>
                {sensor.friendly_name}
              </ListItemText>
            </ListItemButton>
          ))
        }
      </List>);
}

export default DeviceSensors;