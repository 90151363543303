import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import SystemStatus from '@features/home-status/components/SystemStatus';
import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import HealthChecks from './HealthChecks';

const HomeHealth: FC = () => {
  const property = useAppSelector((state: RootState) => state.property);

  /** MAIN RENDER */
  return (
    <Box
      component="div"
      sx={{
        margin: '1em',
        padding: '2em',
      }}
    >
      <Typography variant="h4" sx={{ mb: 2 }}>
        Health status at {property?.name}
      </Typography>
      <Stack direction="column" spacing={2} sx={{ pt: 6 }}>
        <SystemStatus />
        <HealthChecks />
      </Stack>
    </Box>
  );
}

export default HomeHealth;