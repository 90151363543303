import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { pollingInterval } from "@features/tenant-selector/types";
import { getUnitsByCategoryMeasure } from "@features/unit-settings/utils";
import { Category, Measure, findCategoryLabel, formatCategoryMeasurePair } from "@lib/labels";
import { Box, Button, CircularProgress, Stack } from "@mui/material";
import { useMemo, useState } from "react";
import { useNeighborhoodHistoricalUsageQuery } from "../api";
import NeighborhoodUsageChart from "./NeighborhoodUsageChart";
import { Interval } from "@features/home-analytics/types";

interface Props {
  
}

const NeighborhoodHistoricalUsage = (props: Props) => {

  const neighborhood = useAppSelector((state: RootState) => state.neighborhood.neighborhood);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const userPreferences = useAppSelector((state: RootState) => state.preferences);

  const [usageCategory, setUsageCategory] = useState<Category>(Category.power);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const preferences = useMemo(() => userPreferences.preferences, [userPreferences.preferences]);
  const unitPreferences = useMemo(() => preferences.units || {}, [preferences.units]);

  const preferredUnit = useMemo(() => (
    unitPreferences?.[formatCategoryMeasurePair(usageCategory, Measure.usage_total)]
    || getUnitsByCategoryMeasure(usageCategory, Measure.usage_total)[0]
  ), [unitPreferences, usageCategory]);

  const {
    currentData: usageData,
  } = useNeighborhoodHistoricalUsageQuery({
    category: usageCategory,
    userTenantId: currentTenant?.tenant_id || '',
    neighborhoodId: neighborhood?.neighborhood_id || '',
  }, {
    skip: !currentTenant?.tenant_id || !neighborhood?.neighborhood_id,
    pollingInterval,
  });

  return <Stack direction="column" spacing={2} flex={1}>
    <Stack direction="row" spacing={1}>
      {[
        Category.power,
        Category.water,
      ].map(cat => (
        <Button
          key={`category-button-${cat}`}
          onClick={() => setUsageCategory(cat)}
          variant={usageCategory === cat ? 'contained' : 'outlined'}
          color="info"
          size="small"
          sx={{
            width: '50%'
          }}
        >{findCategoryLabel(cat)}</Button>
      ))}
    </Stack>
    {!usageData || !neighborhood
      ? <Box component="div" sx={{ textAlign: 'center', pt: '9em' }}>
          <CircularProgress />
        </Box>
      : <NeighborhoodUsageChart
          usageCategory={usageCategory}
          usageData={usageData}
          preferredUnit={preferredUnit}
          neighborhood={neighborhood}
        />
    }
  </Stack>
}


export default NeighborhoodHistoricalUsage;