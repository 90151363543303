import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { useQueryCasesQuery } from '@features/all-support-cases/api';
import { pollingInterval } from '@features/tenant-selector/types';
import { TenantType } from '@features/userTenant/types';
import { checkPermission } from '@features/userTenant/utils';
import BoltIcon from '@mui/icons-material/Bolt';
import BugReportIcon from '@mui/icons-material/BugReport';
import EventIcon from '@mui/icons-material/Event';
import EvStationIcon from '@mui/icons-material/EvStation';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import HvacIcon from '@mui/icons-material/Hvac';
import MicrowaveIcon from '@mui/icons-material/Microwave';
import HealthIcon from '@mui/icons-material/MonitorHeartOutlined';
import SensorsIcon from '@mui/icons-material/Sensors';
import StackedLineChartOutlinedIcon from '@mui/icons-material/StackedLineChartOutlined';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { SyntheticEvent, useMemo, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

type Props = {
  hidden: boolean;
}

/** Bottom footer with navigation between Home Views */
const HomeViewFooter = ({ hidden }: Props) => {
  const propertyId = useParams().property_id || '';
  const location = useLocation();

  const [value, setValue] = useState(location.pathname);

  const handleChange = (e: SyntheticEvent<Element, Event>, newValue: any) => {
    if (newValue) {
      setValue(newValue);
    }
  }

  const awsUser = useAppSelector((state: RootState) => state.awsUser);
  const userPermissions = useAppSelector((state: RootState) => state.userTenant.userPermissions);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const isAdmin = useMemo(() => currentTenant?.tenant_type === TenantType.System, [currentTenant?.tenant_type]);
  const username = useMemo(() => awsUser.username || '', [awsUser.username]);

  const {
    currentData: supportCases,
  } = useQueryCasesQuery({
    userTenantId: currentTenant?.tenant_id || '',
    propertyId,
    username,
  }, {
    skip: !currentTenant?.tenant_id || !propertyId || !username,
    pollingInterval,
  });

  const haveCases = useMemo(() => !!supportCases?.length, [supportCases?.length]);


  return (
      <BottomNavigation
        value={value}
        hidden={hidden}
        showLabels
        onChange={handleChange}
        sx={{
          backgroundColor: "#272727",
          height: "4em",
          position: 'fixed',
          bottom: 0,
          left: '4em',
          right: 0,
          zIndex: 15
        }}
      >
        <BottomNavigationAction
          component={Link}
          to={`/homes/${propertyId}/current`}
          value={`/homes/${propertyId}/current`}
          label="Home Status"
          icon={<SensorsIcon />}
          sx={{ color: "#5d5d5d" }}
        />
        <BottomNavigationAction
          component={Link}
          to={`/homes/${propertyId}/oem`}
          value={`/homes/${propertyId}/oem`}
          label="OEM Devices"
          icon={<MicrowaveIcon />}
          sx={{ color: "#5d5d5d" }}
        />
        <BottomNavigationAction
          component={Link}
          to={`/homes/${propertyId}/health`}
          value={`/homes/${propertyId}/health`}
          label="Home Health"
          icon={<HealthIcon />}
          sx={{ color: "#5d5d5d" }}
        />
        <BottomNavigationAction
          component={Link}
          to={`/homes/${propertyId}/details`}
          value={`/homes/${propertyId}/details`}
          label="Home Details"
          icon={<FactCheckIcon />}
          sx={{ color: "#5d5d5d" }}
        />
        <BottomNavigationAction
          component={Link}
          to={`/homes/${propertyId}/evse`}
          value={`/homes/${propertyId}/evse`}
          label="EVSE"
          icon={<EvStationIcon />}
          sx={{ color: "#5d5d5d" }}
        />
        <BottomNavigationAction
          component={Link}
          to={`/homes/${propertyId}/energy-billing`}
          value={`/homes/${propertyId}/energy-billing`}
          label="Energy Usage"
          icon={<HvacIcon />}
          sx={{ color: "#5d5d5d" }}
        />
        <BottomNavigationAction
          component={Link}
          to={`/homes/${propertyId}/usage`}
          value={`/homes/${propertyId}/usage`}
          label="Utility Usage"
          icon={<BoltIcon />}
          sx={{ color: "#5d5d5d" }}
        />
        <BottomNavigationAction
          component={Link}
          to={`/homes/${propertyId}/performance`}
          value={`/homes/${propertyId}/performance`}
          label="Performance"
          icon={<StackedLineChartOutlinedIcon />}
          sx={{ color: "#5d5d5d" }}
        />
        {
          checkPermission(userPermissions, 'manage-properties') &&
          <BottomNavigationAction
            component={Link}
            to={`/homes/${propertyId}/notifications`}
            value={`/homes/${propertyId}/notifications`}
            label="Notifications"
            icon={<EventIcon />}
            sx={{ color: "#5d5d5d" }}
          />
        }
        {
          (isAdmin || haveCases) &&
          <BottomNavigationAction
            component={Link}
            to={`/homes/${propertyId}/support`}
            value={`/homes/${propertyId}/support`}
            label="Support Cases"
            icon={<BugReportIcon />}
            sx={{ color: "#5d5d5d" }}
          />
        }
      </BottomNavigation>
  );
};

export default HomeViewFooter;
