import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { useQueryCasesQuery } from '@features/all-support-cases/api';
import { pollingInterval } from '@features/tenant-selector/types';
import { TenantType } from '@features/userTenant/types';
import { checkPermission } from '@features/userTenant/utils';
import BoltIcon from '@mui/icons-material/Bolt';
import BugReportIcon from '@mui/icons-material/BugReport';
import EventIcon from '@mui/icons-material/Event';
import EvStationIcon from '@mui/icons-material/EvStation';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import HvacIcon from '@mui/icons-material/Hvac';
import MicrowaveIcon from '@mui/icons-material/Microwave';
import HealthIcon from '@mui/icons-material/MonitorHeartOutlined';
import SensorsIcon from '@mui/icons-material/Sensors';
import StackedLineChartOutlinedIcon from '@mui/icons-material/StackedLineChartOutlined';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { SyntheticEvent, useMemo, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

type Props = {
  hidden?: boolean;
}

/** Bottom footer with navigation between Neighborhood Views */
const NeighborhoodViewFooter = (props: Props) => {
  const { hidden } = props;
  const neighborhoodId = useParams().neighborhood_id || '';
  const location = useLocation();

  const [value, setValue] = useState(location.pathname);

  const handleChange = (e: SyntheticEvent<Element, Event>, newValue: any) => {
    if (newValue) {
      setValue(newValue);
    }
  }

  const awsUser = useAppSelector((state: RootState) => state.awsUser);
  const userPermissions = useAppSelector((state: RootState) => state.userTenant.userPermissions);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const isAdmin = useMemo(() => currentTenant?.tenant_type === TenantType.System, [currentTenant?.tenant_type]);

  return (
      <BottomNavigation
        value={value}
        hidden={hidden}
        showLabels
        onChange={handleChange}
        sx={{
          backgroundColor: "#272727",
          height: "4em",
          position: 'fixed',
          bottom: 0,
          left: '4em',
          right: 0,
          zIndex: 15
        }}
      >
        <BottomNavigationAction
          component={Link}
          to={`/neighborhoods/${neighborhoodId}`}
          value={`/neighborhoods/${neighborhoodId}`}
          label="Summary"
          icon={<HomeWorkIcon />}
          sx={{ color: "#5d5d5d" }}
        />
        <BottomNavigationAction
          component={Link}
          to={`/neighborhoods/${neighborhoodId}/usage`}
          value={`/neighborhoods/${neighborhoodId}/usage`}
          label="Utility Usage"
          icon={<BoltIcon />}
          sx={{ color: "#5d5d5d" }}
        />
        <BottomNavigationAction
          component={Link}
          to={`/neighborhoods/${neighborhoodId}/performance`}
          value={`/neighborhoods/${neighborhoodId}/performance`}
          label="Performance"
          icon={<StackedLineChartOutlinedIcon />}
          sx={{ color: "#5d5d5d" }}
        />
        
        {
          checkPermission(userPermissions, 'manage-neighborhoods') &&
          <BottomNavigationAction
            component={Link}
            to={`/neighborhoods/${neighborhoodId}/notifications`}
            value={`/neighborhoods/${neighborhoodId}/notifications`}
            label="Notifications"
            icon={<EventIcon />}
            sx={{ color: "#5d5d5d" }}
          />
        }
        
      </BottomNavigation>
  );
};

export default NeighborhoodViewFooter;
