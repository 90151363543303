import { PropertyManifestEntry } from '@features/home-manifest/types';
import { getDeviceName } from '@features/home-status/utils';
import { ManifestDevice } from '@features/plan-manifest/types';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { DateTime } from 'luxon';
import moment, { Moment } from 'moment';
import { Measure } from './labels';
import { AnalyticsTimeseries } from './types';


export const TYPICAL_PERFORMANCE: Record<string, number> = {
  [Measure.temperature]: 68, // F
  [Measure.humidity]: 35 // %
};


/** Shorthand for encoding variables in URLs */
export const U = encodeURIComponent;


/** Shorthand for encoding a path into the URLs */
export const P = (p: string) => p.split('/').map(encodeURIComponent).join('/');


/**
 * Loads a value from Local Storage
 *
 * @param key unique key into local storage
 * @returns data from storage, or null is none exists
 */
export function loadLocal(key: string): any {
  const data = localStorage.getItem(key);
  return data === null ? null : JSON.parse(data);
}


/**
 * Saves a value into Local Storage
 *
 * @param key unique key into local storage
 * @param data to be stored
 */
export function saveLocal(key: string, data: any) {
  localStorage.setItem(key, JSON.stringify(data));
}


/** Is the object a Function? */
export const isFunction = (obj: any): obj is Function => typeof obj === 'function';

/** Format a timestamp for the API (ISO date without milliseconds) */
export const formatApiTimestamp = (timestamp: Moment) => timestamp.utc().toDate().toISOString().split('.')[0].replace('T', ' ');


/** UTC Timestamp format used by Analytics data */
export const FMT_ANALYTICS = 'yyyy-MM-dd HH:mm:ss';

/**
 * Find the dates that are on or aftert the start and before the end.
 *
 * @param start inclusive start date
 * @param end exclusive end date
 * @returns array of dates
 */
export const rangeOfDays = (start: DateTime, end: DateTime): DateTime[] => {
  const days: DateTime[] = [];

  if (start === end) return days;

  let day = start.startOf('day');
  while (day < end) {
    days.push(day);
    day = day.plus({ days: 1 })
  }

  return days;
}


/**
 * Find the date of the weeks that are on or after the start and before the end.
 *
 * @param start inclusive start date
 * @param end exclusive end date
 * @returns array of dates
 */
export const rangeOfWeeks = (start: DateTime, end: DateTime): DateTime[] => {
  const weeks: DateTime[] = [];

  if (start === end) return weeks;

  let day = start.startOf('week');
  while (day < end) {
    weeks.push(day);
    day = day.plus({ weeks: 1 })
  }

  return weeks;
}


/**
 * Find the date of the months that are on or after the start and before the end.
 *
 * @param start inclusive start date
 * @param end exclusive end date
 * @returns array of dates
 */
export const rangeOfMonths = (start: DateTime, end: DateTime): DateTime[] => {
  const months: DateTime[] = [];

  if (start === end) return months;

  let day = start.startOf('month');
  while (day < end) {
    months.push(day);
    day = day.plus({ months: 1 })
  }

  return months;
}


export const formatLabel = (label: string): string => {
  const locale = window.navigator.language;
  const regex = /[^A-Z]+/gm;

  // all lower-cased (no uppercase letters)
  if (label.match(regex)?.[0] === label) {
    return label[0]?.toLocaleUpperCase(locale) + label.slice(1).replace(/_|-/g, ' ');
  }

  return label;
}

export const getManifestEntryLabel = (entry: PropertyManifestEntry) => {
  const deviceName = getDeviceName(entry.device as ManifestDevice);
  return `${deviceName} in ${formatLabel(entry.property_area)}`;
};

export const getErrorMessage = (
  error: FetchBaseQueryError | SerializedError
) => {
  if ((error as FetchBaseQueryError).data) {
    const { detail } = (error as FetchBaseQueryError).data as any;

    if (detail && typeof detail == 'string') {
      return detail;
    }

    if (detail && Array.isArray(detail)) {
      const message = detail.reduce((acc: any, err: { type: any; msg: any; loc: string[] }) => {
        return [
          acc,
          `${err?.type} ${err?.msg}: ${err?.loc.join('|')}`
        ].join('\n')
      }, '');
      return message;
    }

    return `${(error as FetchBaseQueryError).status}: API error`;
  }

  return (error as SerializedError).message;
}


export const checkIfImageExists = (url: string, callback: (exists: boolean) => void) => {
  const img = new Image();
  img.src = url;


  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };

    img.onerror = () => {
      callback(false);
    };
  }
}

/**
 * This function parses given value to a corresponding Moment object.
 * It accepts value in different formats and if it can't properly parse it,
 * it gives back Moment object for current datetime
 *
 * ex.:
 *   [
 *    null,                           // moment()
 *    1668105926.5677798,             // moment('2022-11-10T13:45:26-05:00')
 *    1668105926                      // moment('2022-11-10T13:45:26-05:00')
 *    1668105926567                   // moment('2022-11-10T13:45:26-05:00')
 *    '',                             // moment()
 *    'not a number',                 // moment()
 *    '1668105926'                    // moment('2022-11-10T13:45:26-05:00')
 *    '1668105926567'                 // moment('2022-11-10T13:45:26-05:00')
 *    '1668105926.567'                // moment('2022-11-10T13:45:26-05:00')
 *    '2022-11-10T13:45:26-05:00'     // moment('2022-11-10T13:45:26-05:00')
 *    '2022-11-10 13:45:26.000000000' // moment('2022-11-10T13:45:26-05:00')
 *  ].forEach(value => console.log(formatDate(value)));
 *
 * @param value - date/time value to parse
 * @returns Moment object for given date/time value,
 * returns Moment object for current time, when value is null or empty string
 */
export const formatDate = (value: number | string | AnalyticsTimeseries | null): Moment => {
  // now
  if (value === null || typeof value === 'object') {
    return moment();
  }
  // epoch integer
  if (typeof value === 'number') {
    // we got time in seconds, let's update it to millis
    if (value.toFixed().length < 13) {
      return moment(value * 1000);
    }
    // milliseconds
    return moment(value);
  }
  // string value
  if (typeof value === 'string') {
    // could be empty
    if (!value.length) {
      return moment();
    }
    // When creating a moment from a string, we first check
    // if the string matches known ISO 8601 formats, we then check
    // if the string matches the RFC 2822 Date time format before dropping to
    // the fall back of new Date(string) if a known format is not found.
    // not a valid date
    if (!moment(value).isValid()) {
      // and not a number
      if (isNaN(parseInt(value))) {
        return moment();
      }
      // it is a number as a string
      // and it's seconds
      if (parseInt(value).toFixed().length < 13) {
        return moment(parseFloat(value) * 1000);
      }
      // its in milliseconds
      return moment(parseFloat(value));
    }
    // valid date
    return moment.utc(value);
  }
  return moment();
}