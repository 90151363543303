import AppTextField from "@features/home/components/AppTextField";
import { FormGroup, Paper, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { User } from "../types";


const UserFields = () => {
  const f = useFormikContext();
  return (
    <Paper sx={{ m: 1, p: 1, minWidth: 500, height: '100%' }}>
      <Typography variant='h6'>User Details</Typography>
      <FormGroup sx={{
        width: '100%',
        margin: '0 0 1% 0',
      }}>
        <AppTextField
          name="username"
          label="Username"
          InputProps={{
            readOnly: !!(f.values as User).created,
          }}
          margin="dense"
          size="small"
          type="text"
          fullWidth
        />
        <AppTextField
          name="email"
          margin="dense"
          size="small"
          label="Email Address"
          required
          type="text"
          fullWidth
        />
        <AppTextField
          margin="dense"
          size="small"
          name="given_name"
          label="First Name"
          type="text"
          fullWidth
        />
        <AppTextField
          margin="dense"
          size="small"
          name="family_name"
          label="Last Name"
          type="text"
          fullWidth
        />
        <AppTextField
          margin="dense"
          size="small"
          name="address"
          label="Address"
          type="text"
          fullWidth
        />
      </FormGroup>
    </Paper>
  );

}

export default UserFields;
