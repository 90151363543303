import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import AdminTable from "@features/Common/AdminTable";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { SnackType } from '@lib/types';
import { getErrorMessage } from "@lib/utils";
import { Backdrop, Box, Button, CircularProgress, Typography } from "@mui/material";
import { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from 'react';
import { useCreateSceneTemplateMutation, useDeleteSceneTemplateMutation, useTemplateInventoryQuery, useUpdateSceneTemplateMutation } from "../api";
import { SceneTemplate, SceneTemplateCreate, SceneTemplateUpdate } from "../types";
import SceneTemplateFields from "./SceneTemplateFields";
import SceneTemplateDialog from "./SceneTemplateDialog";


const SceneTemplateList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const inventory = useAppSelector((state: RootState) => state.sceneTemplates);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const [selectedTemplate, setSelectedTemplate] = useState<SceneTemplate | null>(null);

  const {
    refetch: fetchSceneTemplates,
  } = useTemplateInventoryQuery({
    userTenantId: currentTenant?.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id,
  });

  const [
    createSceneTemplate,
  ] = useCreateSceneTemplateMutation();

  const [
    updateSceneTemplate,
  ] = useUpdateSceneTemplateMutation();

  const [
    deleteSceneTemplate,
  ] = useDeleteSceneTemplateMutation();

  const handleDeleteScenetemplate = useCallback(async (template: SceneTemplate) => {
    const newTemplateResponse = await deleteSceneTemplate({
      userTenantId: currentTenant.tenant_id,
      scenetemplateId: template.scene_template_id,
    });

    if ('error' in newTemplateResponse) {
      enqueueSnackbar("Failed to delete scene template:", {
        key: "delete-scenetemplate-error",
        content: (
          <ExpandableSnack
            id="delete-scenetemplate-error"
            message={"Couldn't delete scene template:"}
            variant={SnackType.error}
            detail={getErrorMessage(newTemplateResponse.error)}
          />),
      });
      return false;
      fetchSceneTemplates();
    } else {
      enqueueSnackbar("Scene template deleted.", {
        variant: 'success'
      });
      fetchSceneTemplates();
    }

    return true;
  }, [currentTenant.tenant_id, deleteSceneTemplate, enqueueSnackbar, fetchSceneTemplates]);

  const handleSaveSceneTemplate = useCallback(async (template: SceneTemplateCreate | SceneTemplateUpdate) => {
    if (!template.scene_template_id) {
      const newTemplateResponse = await createSceneTemplate({
        userTenantId: currentTenant.tenant_id,
        body: template as SceneTemplateCreate,
      });

      if ('error' in newTemplateResponse) {
        enqueueSnackbar("Failed to create scene template:", {
          key: "create-scenetemplate-error",
          content: (
            <ExpandableSnack
              id="create-scenetemplate-error"
              message={"Couldn't create scene template:"}
              variant={SnackType.error}
              detail={getErrorMessage(newTemplateResponse.error)}
            />),
        });
        return false;
      } else {
        enqueueSnackbar("Scene template created.", {
          variant: 'success'
        });
        fetchSceneTemplates();
      }
    } else {
      const updateTemplateResponse = await updateSceneTemplate({
        userTenantId: currentTenant.tenant_id,
        scenetemplateId: template.scene_template_id,
        body: template,
      });

      if ('error' in updateTemplateResponse) {
        enqueueSnackbar("Failed to update scene template:", {
          key: "update-scenetemplate-error",
          content: (
            <ExpandableSnack
              id="update-scenetemplate-error"
              message={"Couldn't update scene template:"}
              variant={SnackType.error}
              detail={getErrorMessage(updateTemplateResponse.error)}
            />),
        });
        return false;
      } else {
        enqueueSnackbar("Scene template updated.", {
          variant: 'success'
        });
        fetchSceneTemplates();
      }
    }

    return true;
  }, [createSceneTemplate, currentTenant.tenant_id, enqueueSnackbar, fetchSceneTemplates, updateSceneTemplate]);

  const handleDialogClose = useCallback((changes?: boolean) => {
    setSelectedTemplate(null);

    if (changes) fetchSceneTemplates();
  }, [fetchSceneTemplates]);

  const renderedTable = useMemo(() => {
    if (userTenant.checkingTenant) return <Backdrop open><CircularProgress /></Backdrop>;

    const options: MUIDataTableOptions = {
      enableNestedDataAccess: '.',
    }

    const columns: MUIDataTableColumn[] = [
      {
        name: '',
        label: '',
        options: {
          sort: false,
          filter: false,
          customBodyRenderLite: (idx) => (
            <Button
              // startIcon={<ManifestIcon />}
              onClick={() => {
                setSelectedTemplate(inventory[idx])
              }}
            >
              Design Scene
            </Button>
          )
        }
      },
      {
        name: 'name',
        label: 'Name',
        options: {}
      },
      {
        name: 'description',
        label: 'Description',
        options: {}
      },
    ];

    return (
      <AdminTable<SceneTemplate & { name: string }>
        title="Available Scene Templates"
        label="Scene Template"
        id_key="scene_template_id"
        name_key="name"
        columns={columns}
        options={options}
        data={inventory}
        makeNew={() => ({
          name: 'New scene template',
          enabled: false,
        })}
        onSave={handleSaveSceneTemplate}
        refresh={fetchSceneTemplates}
        editPermission="write-scenetemplates"
        deletePermission="delete-scenetemplates"
        onDelete={handleDeleteScenetemplate}
      >
        {f => <SceneTemplateFields />}
      </AdminTable>
    );
  }, [fetchSceneTemplates, handleDeleteScenetemplate, handleSaveSceneTemplate, inventory, userTenant.checkingTenant]);

  return (
    <Box
      component="div"
      sx={{
        margin: '1em',
        padding: '2em',
      }}
    >
      <Typography variant="h4">{currentTenant.name}</Typography>
      <Typography variant="h5">Scene Templates</Typography>
      <Box
        component="div"
        sx={{
          mt: '3em',
        }}
      >
        {renderedTable}
        {
          selectedTemplate &&
          <SceneTemplateDialog
            onClose={handleDialogClose}
            open={selectedTemplate !== null}
            sceneTemplate={selectedTemplate}
          />
        }
      </Box>
    </Box>
  );
}

export default SceneTemplateList;