import StreetAddress from "@features/Address/StreetAddress";
import TownAddress from "@features/Address/TownAddress";
import HomesProvider from "@features/all-homes/components/HomesProvider";
import AppTextField from "@features/home/components/AppTextField";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useFormikContext } from "formik";
import moment from "moment";
import { useState } from "react";
import { Contact, PrivacyLevel } from "../types";
import PropertyContact from "./PropertyContact";


type Props = {
  onShare?: (toRemove: string[], toAdd: string[]) => void;
}

const ContactFields = ({ onShare }: Props) => {

  const f = useFormikContext();

  const initial = (f.values as Contact);

  const [accessLevel, setAccessLevel] = useState<string>(
    initial.privacy_level || PrivacyLevel.private,
  );

  /** toggles Contact's privacy level */
  const handleCheckPrivacy = (e: any) => {
    const newAccessLevel = e.target.checked
      ? PrivacyLevel.public
      : PrivacyLevel.private;

      setAccessLevel(newAccessLevel);
    f.setFieldValue('privacy_level', newAccessLevel);
  }

  const handleShareContact = (toRemove: string[], toAdd: string[]) => {
    onShare && onShare(toRemove, toAdd);
    f.setFieldValue('updated', moment());
  }

  return (
    <>
      <AppTextField
        name="name"
        label="Name"
        type="text"
        fullWidth
      />
      <AppTextField
        name="description"
        label="Description"
        type="text"
        fullWidth
      />
      <AppTextField
        name="phone_number"
        label="Phone Number"
        type="text"
        fullWidth
      />
      <AppTextField
        name="website"
        label="Website"
        type="text"
        fullWidth
      />
      <StreetAddress
        width='100%'
        margin='0'
      />
      <TownAddress
        width='100%'
        margin='0'
      />

      <FormControlLabel
        label="Make this contact shareable with others"
        control={(
          <Checkbox
            onChange={handleCheckPrivacy}
            checked={accessLevel === PrivacyLevel.public}
          />
        )}
      />
      {
        accessLevel === PrivacyLevel.public &&
        <HomesProvider>
          <PropertyContact
            contact={initial}
            onShare={handleShareContact}
          />
        </HomesProvider>
      }
    </>
  );
}

export default ContactFields;