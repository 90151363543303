import { riotApi } from "@features/aws-user/api";
import { Plan } from "@features/floorplan/types";
import { U } from "@lib/utils";
import { Permission, Tenant } from "./types";


/** API slice for the tenant-related endpoints */
export const userTenantApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      /** Get current user's default tenant */
      myUserTenant: builder.query<Tenant, void>({
        query: () => ({
          url: `/tenant`,
        }),
      }),
      /** Get tenants visible to me */
      myTenants: builder.query<Tenant[], void>({
        query: () => ({
          url: `/tenants`,
        })
      }),
      /** Get current user's permissions */
      myPermissions: builder.query<Permission[], { userTenantId: string }>({
        query: ({ userTenantId }) => ({
          url: `tenant/${U(userTenantId)}/permissions`
        }),
      }),
      /** Get Tenant plans */
      tenantPlans: builder.query<Plan[], { userTenantId: string, tenantId: string }>({
        query: ({ userTenantId, tenantId }) => ({
          url: `/tenant/${U(userTenantId)}/plans`,
          params: {
            tenant_id: tenantId,
          },
        })
      }),
      /** Get my regions */
      regions: builder.query<any, { userTenantId: string }>({
        query: ({ userTenantId }) => ({
          url: `/tenant/${U(userTenantId)}/regions`,
        }),
      }),
    };
  }
});

export const {
  useMyUserTenantQuery,
  useMyTenantsQuery,
  useMyPermissionsQuery,
  useTenantPlansQuery,
  useRegionsQuery,
} = userTenantApi;
