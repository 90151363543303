import { DEFAULT_ADDRESS } from "@features/home/types";
import { createSlice } from "@reduxjs/toolkit";
import { Home } from "./types";

const initialState: Home = {
  property_id: '',
  is_deleted: false,
  token: {
    token_expiration: '',
    token_value: '',
    updated: '',
  },
  plan_id: '',
  manifest_id: '',
  physical_address: { ...DEFAULT_ADDRESS },
  shipping_address: { ...DEFAULT_ADDRESS },
  region_id: '',
  latitude: '',
  longitude: '',
  builder_id: '',
  owner_id: '',
  devices: [],
  manifestEntries: [],
  planManifestId: '',
  created: 0,
  updated: 0,
  manifest: null,
};

export const homeReducer = createSlice({
  name: 'property',
  initialState,
  reducers: {
    setProperty: (state: any, action: any) => {
      if (action.payload) {
        Object.entries(action.payload)
          .forEach(([key, value]) => state[key] = value);
      } else {
        Object.entries(initialState)
          .forEach(([key, value]) => state[key] = value);
      }
    },
    setDevices: (state: any, action: any) => {
      if (action.payload) {
        state.devices = action.payload;
      }
    },
    setManifest: (state: any, action: any) => {
      if (action.payload) {
        state.manifestEntries = [...action.payload.manifest_entries];
        state.manifest = {
          ...action.payload,
        };
        delete state.manifest.manifest_entries;
      }
    }
  },
  extraReducers: {
    // async thunks here
  }
})

export default homeReducer.reducer;
export const { setProperty, setDevices, setManifest } = homeReducer.actions;