import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { components, formFields } from '@features/aws-user/amplify-ui-config';
import AuthProvider from "@features/aws-user/AuthProvider";
import { CognitoUserAmplify } from '@features/aws-user/types';
import "@lib/config";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";
import { SnackbarProvider } from 'notistack';
import { createRef, StrictMode } from 'react';
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { store } from './app/store';
import './index.css';
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// Standard dismiss button for all Snackbar messages
const snackbar = createRef<SnackbarProvider>();
const dismissSnack = (key: string | number | undefined) => (
  <IconButton onClick={() => snackbar.current?.closeSnackbar(key)} size="small" color="inherit">
    <CloseIcon />
  </IconButton>
);

ReactDOM.render(
  <StrictMode>
    <SnackbarProvider ref={snackbar} maxSnack={3} action={dismissSnack}>
      <Provider store={store}>
        <Authenticator
          hideSignUp
          components={components}
          formFields={formFields}
        >
          {({ user }) => (
            <AuthProvider user={user as CognitoUserAmplify} />
          )}
        </Authenticator>
      </Provider>
    </SnackbarProvider>
  </StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
