import { Tenant } from "@features/userTenant/types";

type EntityBase = {
  readonly created: string | number;
  readonly updated: string | number;
}

export interface SelectedTenant {
  currentTenant: Tenant;
  access: string[];
}

// Interval for RTK data fetching
export const pollingInterval = 60*1000;

export type Access = {
  tenant_id: string;
  roles: string[];
  is_deleted: boolean;
  group_name?: string;
  username: string;
} & EntityBase;