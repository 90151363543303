import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import HomeDetails from '@features/home-details/components/HomeDetails';
import { Box, Typography } from '@mui/material';
  /**
   * Editable details of Home
   */
  const HomeDetailsView = () => {
    const property = useAppSelector((state: RootState) => state.property);
    return (
      <Box
        component="div"
        sx={{
          margin: '1em',
          padding: '2em',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            mb: '3em'
          }}
        >
          Summary for {property.name}
        </Typography>
        <HomeDetails />
      </Box>
    );
  }

  export default HomeDetailsView;