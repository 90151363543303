import { formatApiTimestamp } from "@lib/utils";
import { Card, CardContent, CardHeader, Divider, Paper } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";

import Chart from 'react-apexcharts';
import { Category, Measure, findCategoryLabel, formatCategoryMeasurePair, formatPowerUsage, formatPowerUsageRate } from "@lib/labels";
import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { usePerformanceQuery } from "@features/charts/api";
import { Aggregate, BinSize, ChartType, TrendLines } from "@features/settings/types";
import moment from "moment";
import { formatRawDataForTimeLineChart } from "@features/charts/utils";
import { getUnitsByCategoryMeasure } from "@features/unit-settings/utils";
import { PropertyManifestEntry } from "@features/home-manifest/types";

export interface EvseChartProps {
  evseEntry?: PropertyManifestEntry;
}

const EvseChart: React.FC<EvseChartProps> = function EvseChart(
  props: EvseChartProps,
) {
  const { evseEntry } = props;

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const property = useAppSelector((state: RootState) => state.property);
  const userPreferences = useAppSelector((state: RootState) => state.preferences);
  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const manifestEntries = useMemo(() => property.manifestEntries || [], [property.manifestEntries]);
  const preferences = useMemo(() => userPreferences.preferences, [userPreferences.preferences]);
  const unitPreferences = useMemo(() => preferences.units || {}, [preferences.units]);

  const categoryLabel = useMemo(() => findCategoryLabel(Category.power), []);

  const preferredPowerUnit = useMemo(() => (
    unitPreferences?.[formatCategoryMeasurePair(Category.power, Measure.output_rate)]
    || getUnitsByCategoryMeasure(Category.power, Measure.output_rate)[0]
  ), [unitPreferences]);

  const preferredEnergyUnit = useMemo(() => (
    unitPreferences?.[formatCategoryMeasurePair(Category.power, Measure.output_total)]
    || getUnitsByCategoryMeasure(Category.power, Measure.output_total)[0]
  ), [unitPreferences]);

  const {
    currentData: powerUsageData,
  } = usePerformanceQuery({
    category: Category.power,
    aggregate: Aggregate.avg,
    binSize: BinSize.hour,
    measure: Measure.output_rate,
    start: formatApiTimestamp(moment().startOf('week').add('weeks', -1)),
    end: formatApiTimestamp(moment().endOf('day')),
    propertyId: property.property_id,
    userTenantId: currentTenant.tenant_id,
  }, {
    skip: !currentTenant?.tenant_id || !property?.property_id,
  });

  const {
    currentData: energyUsageData,
  } = usePerformanceQuery({
    category: Category.power,
    aggregate: Aggregate.avg,
    binSize: BinSize.hour,
    measure: Measure.output_total,
    start: formatApiTimestamp(moment().startOf('week').add('weeks', -1)),
    end: formatApiTimestamp(moment().endOf('day')),
    propertyId: property.property_id,
    userTenantId: currentTenant.tenant_id,
  }, {
    skip: !currentTenant?.tenant_id || !property?.property_id,
  });

  const evseDeviceId = useMemo(() => evseEntry?.gateway_device_id ?? '', [evseEntry?.gateway_device_id]);

  /* Filter out rows belonging to devices that are not the EVSE */
  const filteredPowerUsageData: typeof powerUsageData = useMemo(() => powerUsageData?.map(set => {
    const deviceIdColIdx = set.ColumnInfo.findIndex(e => e.toLowerCase() === 'device_id');

    return {
      ...set,
      Rows: set.Rows?.filter((row) => deviceIdColIdx === -1
        ? false
        : (row[deviceIdColIdx].toString() === 'riot_property.riot_evse' || row[deviceIdColIdx].toString().toLowerCase() === evseDeviceId.toLowerCase())
      )
    };
  }), [evseDeviceId, powerUsageData]);

  const filteredEnergyUsageData: typeof energyUsageData = useMemo(() => energyUsageData?.map(set => {
    const deviceIdColIdx = set.ColumnInfo.findIndex(e => e.toLowerCase() === 'device_id');

    return {
      ...set,
      Rows: set.Rows?.filter((row) => deviceIdColIdx === -1
        ? false
        : (row[deviceIdColIdx].toString() === 'riot_property.riot_evse' || row[deviceIdColIdx].toString().toLowerCase() === evseDeviceId.toLowerCase())
      )
    };
  }), [evseDeviceId, energyUsageData]);

  /* Format data for chart */
  const chartPowerUsageData: ApexAxisChartSeries = useMemo(
    () => {
      if (!filteredPowerUsageData?.length) return [];

      return formatRawDataForTimeLineChart([
        ...filteredPowerUsageData,
      ], manifestEntries, [], [], false, TrendLines.none, ChartType.line, Category.power, Measure.output_rate, preferredPowerUnit);
    },
    [manifestEntries, preferredPowerUnit, filteredPowerUsageData],
  );

  const chartEnergyUsageData: ApexAxisChartSeries = useMemo(
    () => {
      if (!filteredEnergyUsageData?.length) return [];

      return formatRawDataForTimeLineChart([
        ...filteredEnergyUsageData,
      ], manifestEntries, [], [], false, TrendLines.none, ChartType.bar, Category.power, Measure.output_total, preferredEnergyUnit);
    },
    [manifestEntries, preferredEnergyUnit, filteredEnergyUsageData],
  );

  const chartData = useMemo(() => ([
    ...chartPowerUsageData,
    ...chartEnergyUsageData,
  ]), [chartEnergyUsageData, chartPowerUsageData]);

  const chartOptions: ApexCharts.ApexOptions = useMemo(() => ({
    chart: {
      id: 'evse-usage-chart',
      stacked: false,
      type: 'line',
      zoom: {
        enabled: false
      },
      animations: {
        enabled: true,
      },
      fontFamily: 'Montserrat',
      selection: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: 'stepline',
      width: 2,
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        }
      },
      yaxis: {
        lines: {
          show: true,
        }
      },
    },
    xaxis: {
      type: 'datetime',
      tickPlacement: 'on',
    },
    yaxis: [
      {
        title: {
          text: `${categoryLabel} Usage Rate`,
        },
        labels: {
          formatter: (value) => formatPowerUsageRate(value, preferredPowerUnit),
        },
        decimalsInFloat: 2,
      },
      {
        title: {
          text: `${categoryLabel} Usage Total`,
        },
        labels: {
          formatter: (value) => formatPowerUsage(value, preferredEnergyUnit),
        },
        opposite: true,
        decimalsInFloat: 2,
      },
    ],
    tooltip: {
      fontFamily: 'Montserrat',
      x: {
        formatter: (e) => `${moment(e).calendar()}`,
      },
      enabled: true,
    },
    legend: {
      show: true,
      fontFamily: 'Montserrat',
    },
    plotOptions: {
      
    },
    dataLabels: {
      enabled: false,
    },
    noData: {
      text: 'No data available',
      style: {
        fontFamily: 'Montserrat',
      }
    }
  }), [categoryLabel, preferredPowerUnit, preferredEnergyUnit]);

  return <Card>
    <CardHeader title="EVSE Power Usage" />
    <Divider />
    <CardContent>
      <Paper component={Chart}
          flex={1}
          series={chartData}
          type="bar"
          options={chartOptions}
          width="100%"
          height="400"
        />
    </CardContent>
  </Card>
}

export default EvseChart;
