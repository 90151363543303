import { Autocomplete, Checkbox, FormControlLabel, Select, Stack, TextField } from "@mui/material";
import { SceneTemplateDeviceCriteria } from "../types";
import { useCallback, useEffect, useMemo } from "react";
import { ALL_CATEGORIES, Category, findCategoryLabel, findMeasureLabel, getMeasuresByCategory } from "@lib/labels";

interface SceneTemplateDeviceCriteriaProps {
  deviceCriteria: SceneTemplateDeviceCriteria;
  onChange: (newCriteria: SceneTemplateDeviceCriteria) => void;
}

export default function SceneTemplateDeviceCriteriaSelector(props: SceneTemplateDeviceCriteriaProps) {
  const { deviceCriteria, onChange} = props;

  const handleChange = useCallback((changes: Partial<SceneTemplateDeviceCriteria>) => {
    onChange({
      ...deviceCriteria,
      ...changes,
    });
  }, [deviceCriteria, onChange]);

  const toggleInAllAreas = useCallback(() => {
    if (deviceCriteria.areas) {
      handleChange({
        ...deviceCriteria,
        areas: null,
      });
    } else {
      handleChange({
        ...deviceCriteria,
        areas: [],
      });
    }
  }, [deviceCriteria, handleChange]);

  const categoryOptions = useMemo(() => ALL_CATEGORIES.map((category) => ({
    label: findCategoryLabel(category),
    value: category,
  })), []);

  const homeAreaOptions = useMemo(() => [
    'kitchen',
    'greatroom',
    'garage',
    'office',
    'external_plumbing',
    'foundation',
  ], []);

  const handleChangeAreas = useCallback((newAreas: (typeof homeAreaOptions[number] | string)[]) => {
    handleChange({
      areas: newAreas,
    });
  }, [handleChange]);

  const measureOptions = useMemo(() => getMeasuresByCategory(deviceCriteria.category ?? null).map((measure) => ({
    label: findMeasureLabel(measure),
    value: measure,
  })), [deviceCriteria.category]);

  /* If measure options do not include current value, select one */
  useEffect(() => {
    if (deviceCriteria.measure && !measureOptions.some((opt) => opt.value === deviceCriteria.measure)) {
      handleChange({
        measure: measureOptions?.[0].value ?? null,
      });
    }
  }, [deviceCriteria.measure, handleChange, measureOptions]);

  return <Stack direction="column" sx={{ width: '100%' }} spacing={1}>
    <Stack direction="row" spacing={1}>
      <Autocomplete
        disablePortal
        options={categoryOptions}
        renderInput={(params) => <TextField {...params} label="Sensor Category" />}
        onChange={(e, v) => handleChange({ category: v?.value })}
        sx={{ flex: '1 1 0' }}
        defaultValue={categoryOptions.find(e => e.value === deviceCriteria.category)}
      />
      <Autocomplete
        disablePortal
        options={measureOptions}
        renderInput={(params) => <TextField {...params} label="Sensor Measure" />}
        onChange={(e, v) => handleChange({ measure: v?.value })}
        sx={{ flex: '1 1 0' }}
        defaultValue={measureOptions.find(e => e.value === deviceCriteria.measure)}
      />
    </Stack>
    <Stack direction="row" spacing={1}>
      <FormControlLabel
        sx={{ flex: '1 1 0' }}
        control={
          <Checkbox
            checked={!deviceCriteria.areas}
            onChange={toggleInAllAreas}
          />
        }
        label="In ALL areas"
      />
      {
        deviceCriteria.areas ? <Autocomplete
          disablePortal
          clearOnBlur
          selectOnFocus
          freeSolo
          multiple
          options={homeAreaOptions}
          renderInput={(params) => <TextField {...params} label="Home areas" />}
          onChange={(e, v) => handleChangeAreas(v)}
          defaultValue={deviceCriteria.areas}
          sx={{ flex: '1 1 0' }}
        /> : null
      }
    </Stack>
  </Stack>
}
