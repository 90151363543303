import { Category, Measure } from "@lib/labels";
import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { createContext, useContext } from "react";
import { getPreferences, updatePreferences } from './thunks';
import { Aggregate, AnalyticsType, BinSize, ChartType, DashboardView, DashboardViewType, Preferences, PreferencesContextType, TrendLines, interval } from "./types";

interface PreferenceState {
  checkingPreferences: boolean;
  preferences: Preferences;
}

const initialState: PreferenceState = {
  checkingPreferences: false,
  preferences: {
    charts: {
      [AnalyticsType.performance]: {
        chartType: ChartType.line,
        binSize: BinSize.month,
        aggregate: Aggregate.none,
        trendLines: TrendLines.none,
        showAverage: false,
        showForecast: false,
        category: Category.air,
        measure: Measure.temperature,
        interval: interval.onemonth,
      },
      [AnalyticsType.usage]: {
        chartType: ChartType.line,
        binSize: BinSize.day,
        aggregate: Aggregate.none,
        trendLines: TrendLines.none,
        showAverage: false,
        showForecast: false,
        category: Category.power,
        measure: Measure.usage_rate,
        interval: interval.onemonth,
      },
    },
    views: {
      [DashboardView.homes]: DashboardViewType.cards,
      [DashboardView.status]: DashboardViewType.cards,
    },
    units: {},
  },
};

/** State slice for preferences  */
export const preferencesReducer = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    setPreferences: (state: any, action: any) => {
      if (action.payload) {
        Object.keys(action.payload).forEach(k => state[k] = action.payload[k]);
      }
    },
    resetPreferences: () => initialState,
  },
  extraReducers: (builder: ActionReducerMapBuilder<PreferenceState>) => {
    builder
      .addCase(getPreferences.pending, (state) => {
        state.checkingPreferences = true;
      })
      .addCase(getPreferences.fulfilled, (state, action) => {
        if (action.payload) {
          state.preferences = action.payload;
          state.checkingPreferences = false;
        } else {
          state.preferences = initialState.preferences;
          state.checkingPreferences = false;
        }
      })
      .addCase(updatePreferences.pending, (state) => {
        state.checkingPreferences = true;
      })
      .addCase(updatePreferences.fulfilled, (state, action) => {
        if (action.payload) {
          state.preferences = action.payload;
          state.checkingPreferences = false;
        } else {
          state = initialState;
        }
      });
  }
});

export const PreferencesContext = createContext<PreferencesContextType>(null!);

export const usePrefs = () => useContext(PreferencesContext);


export default preferencesReducer.reducer;

export const {
  setPreferences,
  resetPreferences,
} = preferencesReducer.actions;