import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { AttentionLevel } from '@features/home-notifications/types';
import { Property } from "@features/home/types";
import { getAttentionLevelColor } from '@features/live-home/utils';
import { checkIfImageExists, U } from '@lib/utils';
import { Card, CardActionArea, CardContent, CardHeader, CardMedia } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { RIOT_BLUE } from 'theme';
import HomeActions from "./HomeActions";
import config from '@lib/config';

type Props = {
  home: Property;
  callback: (home: Property, actionType: string) => void;
};

const HomeCard = ({ home, callback }: Props) => {
  const navigate = useNavigate();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant.builder_color || RIOT_BLUE, [currentTenant.builder_color]);

  const getHomeStatus = useCallback(() => {
    const failures = home.event_summary?.failures;
    const haveFailures = failures && Object.keys(failures).length > 0;
    const unhealtyChecks = Object.values(home.health_summary?.health_checks || {}).filter(e => e.result.unhealthy);
    if (haveFailures || unhealtyChecks.length > 0) {
      return getAttentionLevelColor(AttentionLevel.urgent);
    }
    return themeColor;
  }, [home.event_summary?.failures, home.health_summary?.health_checks, themeColor]);

  const [homeStatus, setHomeStatus] = useState<string>(getHomeStatus());
  const [homeImage, setHomeImage] = useState<string>('/plans/default.png');

  useEffect(() => {
    const status = getHomeStatus();
    if (status !== homeStatus) {
      setHomeStatus(status);
    }
  }, [getHomeStatus, homeStatus]);

  useEffect(() => {
    if (home.image_key && config.ASSETS_BUCKET_URL) {
      const imageUrl = [config.ASSETS_BUCKET_URL, home.image_key].join('/');

      checkIfImageExists(imageUrl, (exists: boolean) => {
        if (exists) setHomeImage(imageUrl);
        else setHomeImage('/plans/default.png');
      });
    } else {
      const imageName = (home.plan_name || 'default').toLowerCase()
      .replace(/\W+/g, ' ').trim().replaceAll(' ', '-');

      checkIfImageExists(`/plans/${imageName}.png`, (exists: boolean) => {
        if (exists) setHomeImage(`/plans/${imageName}.png`);
        else setHomeImage('/plans/default.png');
      });
    }

  }, [home.image_key, home.plan_name]);

  return (
    <Card key={home.property_id} sx={{ m: 2, width: '25em' }}>
      <CardActionArea onClick={() => navigate(`/homes/${U(home.property_id)}`)}>
        <CardHeader
          title={home.name ?? home.physical_address.address_line_1}
          subheader={home.physical_address.city}
          sx={{
            color: homeStatus,
            height: '6em',
            padding: '0.5em',
            '& .MuiCardHeader-content': {
              display: 'flex',
              height: '5em',
              flexDirection: 'column',
              justifyContent: 'space-between',
            },
            '& .MuiTypography-h5': {
              fontSize: '1em',
              fontWeight: '700',
            }
          }}
        />
        <CardMedia
          component="img"
          image={homeImage}
          alt="House Exterior"
          sx={{
            width: '25em',
            height: '20em',
          }}
        />
      </CardActionArea>
      <CardContent sx={{
        padding: '0.5em',
        '&:last-child': {
          paddingBottom: '0.5em',
        }
      }}>
        <HomeActions
          home={home}
          callback={callback}
        />
      </CardContent>
    </Card>
  );
}

export default HomeCard;