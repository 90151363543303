import { riotApi } from "@features/aws-user/api";
import { HTTPMethodType } from "@lib/types";
import { Contact, ContactCreate, ContactUpdate } from "./types";

const safe = encodeURIComponent;

/** API slice for the contacts-related endpoints */
export const contactsApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      /** Get tenant's contacts */
      contacts: builder.query<Contact[], {
        userTenantId: string,
        tenantId: string,
      }>({
        query: ({ userTenantId, tenantId }) => ({
          url: `/tenant/${safe(userTenantId)}/contacts`,
          params: {
            tenant_id: safe(tenantId)
          }
        })
      }),
      /** Create a new contact for current tenant */
      createMyContact: builder.mutation<Contact, {
        userTenantId: string,
        body: ContactCreate,
      }>({
        query: ({ userTenantId, body }) => ({
          url: `tenant/${safe(userTenantId)}/contacts`,
          method: HTTPMethodType.POST,
          body,
        })
      }),
      /** Create a new contact for current tenant */
      createTenantContact: builder.mutation<Contact, {
        userTenantId: string,
        tenantId: string,
        body: ContactCreate,
      }>({
        query: ({ userTenantId, tenantId, body }) => ({
          url: `tenant/${safe(userTenantId)}/tenants/${safe(tenantId)}/contacts`,
          method: HTTPMethodType.POST,
          body,
        })
      }),
      /** Update my contact */
      updateMyContact: builder.mutation<Contact, {
        userTenantId: string,
        contactId: string,
        body: ContactUpdate,
      }>({
        query: ({ userTenantId, body, contactId }) => ({
          url: `tenant/${safe(userTenantId)}/contacts/${safe(contactId)}`,
          method: HTTPMethodType.PATCH,
          body,
        })
      }),
      /** Update other tenant's contact */
      updateTenantContact: builder.mutation<Contact, {
        userTenantId: string,
        tenantId: string,
        contactId: string,
        body: ContactUpdate,
      }>({
        query: ({ userTenantId, tenantId, contactId, body }) => ({
          url: `tenant/${safe(userTenantId)}/tenants/${safe(tenantId)}/contacts/${safe(contactId)}`,
          method: HTTPMethodType.PATCH,
          body,
        }),
      }),
      /** Delete my contact */
      deleteMyContact: builder.mutation<boolean, {
        userTenantId: string,
        contactId: string,
        force: boolean,
      }>({
        query: ({ userTenantId, force = false, contactId }) => ({
          url: `tenant/${safe(userTenantId)}/contacts/${safe(contactId)}`,
          method: HTTPMethodType.DELETE,
          params: {
            force,
          },
        })
      }),
      /** Delete other tenant's contact */
      deleteTenantContact: builder.mutation<boolean, {
        userTenantId: string,
        tenantId: string,
        contactId: string,
        force?: boolean,
      }>({
        query: ({ userTenantId, tenantId, force = false, contactId }) => ({
          url: `tenant/${safe(userTenantId)}/tenants/${safe(tenantId)}/contacts/${safe(contactId)}`,
          method: HTTPMethodType.DELETE,
          params: {
            force,
          },
        })
      }),
    };
  }
});

export const {
  useContactsQuery,
  useCreateMyContactMutation,
  useCreateTenantContactMutation,
  useDeleteMyContactMutation,
  useDeleteTenantContactMutation,
  useUpdateMyContactMutation,
  useUpdateTenantContactMutation,
} = contactsApi;
