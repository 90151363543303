import { Device } from '@features/home/types';
import { Sensor } from '@features/plan-manifest/types';
import { Category, Measure } from '@lib/labels';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export type ReadingPair = {
  device?: Device;
  sensor: Sensor;
  deviceName: string;
  area: string;
  entryId?: string;
}

export type CategoryReadings = {
  [category in Category]?: {
    [measure in Measure]?: ReadingPair;
  }
}

export type RoomReadings = {
  name: string;
  readings: CategoryReadings;
}


export type ReadingDetails = {
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  },
  value: string,
  color?: "action" | "warning" | "info" | "error" | "inherit" | "disabled" | "success" | "primary" | "secondary" | undefined,
}

export enum DeviceStateType {
  BINARY_SWITCH = 'binary_switch',
  ANALOG_SWITCH = 'analog_switch',
  THERMOSTAT_TEMPERATURE = 'thermostat_temperature',
  THERMOSTAT_MODE = 'thermostat_mode'
}

export enum HvacMode {
  OFF = 'off',
  AUTO = 'auto',
  COOL = 'cool',
  DRY = 'dry',
  FAN_ONLY = 'fan_only',
  HEAT_COOL = 'heat_cool',
  HEAT = 'heat',
}

export enum FanMode {
  AUTO = 'Auto low',
  LOW = 'Low',
  CIRCULATION = 'Circulation',
}

export enum PresetMode {
  NONE = 'none',
  HEAT = 'Energy heat',
  COOL = 'Energy cool',
  ECO = 'eco',
}

export type ThermostatTemperatureStateType = {
  temperature?: number;
  targetTempHigh?: number;
  targetTempLow?: number;
};

export type ThermostatModeStateType = {
  hvacMode?: HvacMode;
  fanMode?: FanMode;
  presetMode?: PresetMode;
};


export type BinarySwitchStateType = boolean;
export type AnalogSwitchStateType = number;


/** Type for possible device state values */
export type DeviceStateValue<StateType extends DeviceStateType = DeviceStateType> = (
  StateType extends DeviceStateType.BINARY_SWITCH ? BinarySwitchStateType
  : StateType extends DeviceStateType.ANALOG_SWITCH ? AnalogSwitchStateType
  : StateType extends DeviceStateType.THERMOSTAT_TEMPERATURE ? ThermostatTemperatureStateType
  : StateType extends DeviceStateType.THERMOSTAT_MODE ? ThermostatModeStateType
  : (AnalogSwitchStateType | BinarySwitchStateType | ThermostatTemperatureStateType)
  /* NOTE: Add another ternary branch if a new device state value type is added */
);


type DeviceStateBase = {
  state_type?: DeviceStateType,
  target_value?: any,
  entity_id?: string,
}

export type DeviceState = DeviceStateBase & {
  state_type: DeviceStateType,
  target_value: any,
  entity_id: string,
}

export enum HassDomain {
  SWITCH = 'switch',
  CLIMATE = 'climate',
};

export type DeviceCapability = {
  stateType: DeviceStateType;
  entityId: string;
}
