import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { EnergyManagement, ServicePower, TYPICAL_MAX_SERVICE_POWER, TYPICAL_RESIDENTIAL_VOLTAGE, UtilityManagement } from '@features/all-utility-groups/types';
import { Plan } from '@features/floorplan/types';
import AppTextField from '@features/home/components/AppTextField';
import { ProvisioningRequestForm } from '@features/provisioning-request/types';
import { useTenantPlansQuery } from '@features/userTenant/api';
import { Unit, findUnitsLabel } from '@lib/labels';
import { FormGroup, MenuItem, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';

const UKNOWN_PLAN = {
  name: "Unknown Plan",
  plan_id: '',
  description: "This plan's Id no longer exists",
} as Plan;


const Floorplans = () => {
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const f = useFormikContext();

  const {
    currentData: plans,
    isFetching: isFetchingPlans,
  } = useTenantPlansQuery({
    userTenantId: userTenant?.tenant_id || '',
    tenantId: currentTenant?.tenant_id || '',
  }, {
    skip: !userTenant.tenant_id || !currentTenant.tenant_id,
  });

  return (
    <FormGroup sx={{
      width: '100%',
      margin: 0,
      justifyContent: 'space-between',
    }}>
      {
        plans && !isFetchingPlans &&
        <AppTextField
          name="plan_id"
          label="Plan"
          select
          margin="dense"
          size="small"
          required
          sx={{
            width: '100%',
          }}
          onChange={(e: any) => {
            const pId = e.target.value;
            const plan = (plans || []).find((p: Plan) => p.plan_id === pId) || UKNOWN_PLAN;
            f.setFieldValue('plan.plan_id', pId);
            f.setFieldValue('plan.name', plan.name);
            f.setFieldValue('plan.description', plan.description);
            const utils = {
              energy_management: {
                svc_max_power: {
                  amps: plan.utility_management?.energy_management.svc_max_power.amps || TYPICAL_MAX_SERVICE_POWER,
                  volts: TYPICAL_RESIDENTIAL_VOLTAGE,
                } as ServicePower,
              } as EnergyManagement,
            } as UtilityManagement;
            f.setFieldValue('utility_management', utils);
            // Let Formik do its thing
            f.handleChange(e);
          }}
        >
          {
            plans && plans.map((p: Plan) => (
              <MenuItem key={p.plan_id} value={p.plan_id}>
                {p.name}
              </MenuItem>))
          }
        </AppTextField>
      }
      {
        (f.values as ProvisioningRequestForm)?.plan.plan_id &&
        <Stack spacing={2} marginBottom={5}>
          <Typography variant="h6">{(f.values as ProvisioningRequestForm)?.plan.description}</Typography>
          <AppTextField
            name="utility_management.energy_management.svc_max_power.amps"
            label={`Max Service Power (${findUnitsLabel(Unit.amps)})`}
            type="number"
            fullWidth
            required
            inputProps={{
              step: 10,
            }}
          />
        </Stack>
      }
    </FormGroup>
  );
}

export default Floorplans;