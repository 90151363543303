import { CompareLabel, Interval } from '@features/home-analytics/types';
import { Category, findCategoryLabel } from '@lib/labels';
import { formatLabel } from '@lib/utils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Card, CardContent, CardHeader, Divider, Grid, Menu, MenuItem, Stack } from '@mui/material';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import UsageChart from './UsageChart';
import UsageHighlights from './UsageHighlights';
import UsageReadings from './UsageReadings';


const HomeUtilityMonitoring = () => {
  const [compare, setCompare] = useState<CompareLabel>(CompareLabel.devices);
  const [compareAnchorEl, setCompareAnchorEl] = useState<null | HTMLElement>(null);
  const [interval, setInterval] = useState<Interval>(Interval.MONTH);
  const [category, setCategory] = useState<Category>(Category.power);

  const handleOpenCompareMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    setCompareAnchorEl(event.currentTarget);
  }, []);

  const handleCloseCompareMenu = useCallback(() => {
    setCompareAnchorEl(null);
  }, []);

  const handleSelectCompare = useCallback((label: CompareLabel) => {
    setCompare(label);
    setCompareAnchorEl(null);
  }, []);

  const selectingCompare = useMemo(() => Boolean(compareAnchorEl), [compareAnchorEl]);

  return <Grid container columns={{ xs: 1, md: 2 }} spacing={1} alignItems="center">
    <Grid item xs={1} container columns={{ xs: 1, md: 2 }} sx={{ p: '1em' }} spacing={2}>
      <Grid item xs={2}>
        <Card>
          <CardHeader title="Controls" />
          <Divider />
          <CardContent>
            <Stack spacing={1}>
              <Stack direction="row" spacing={1}>
                {[
                  Category.power,
                  Category.water,
                ].map(cat => (
                  <Button
                    key={`category-button-${cat}`}
                    onClick={() => setCategory(cat)}
                    variant={category === cat ? 'contained' : 'outlined'}
                    color="info"
                    size="small"
                    sx={{
                      width: '50%'
                    }}
                  >{findCategoryLabel(cat)}</Button>
                ))}
              </Stack>
              <Stack direction="row" spacing={1}>
                {
                  [
                    Interval.DAY,
                    Interval.WEEK,
                    Interval.MONTH,
                    Interval.YEAR,
                  ].map(int => (
                    <Button
                      key={`interval-selector-${int}`}
                      onClick={() => setInterval(int)}
                      variant={interval === int ? "contained" : "outlined"}
                      color="info"
                      size="small"
                      sx={{
                        width: '25%'
                      }}
                    >{int}</Button>
                  ))
                }
              </Stack>
              <Stack direction="row" spacing={1}>
                <Button
                  variant="contained"
                  color="info"
                  size="small"
                  onClick={handleOpenCompareMenu}
                  endIcon={<KeyboardArrowDownIcon />}
                  sx={{
                    width: '100%'
                  }}
                >
                  {formatLabel(compare)}
                </Button>
                <Menu
                  open={selectingCompare}
                  onClose={handleCloseCompareMenu}
                  anchorEl={compareAnchorEl}
                >
                {
                  Object.values(CompareLabel).map(label => (
                    <MenuItem
                      component={Button}
                      key={`compare-selector-${label}`}
                      onClick={() => handleSelectCompare(label)}
                      sx={{
                        width: '30em'
                      }}
                    >{formatLabel(label)}</MenuItem>
                  ))
                }
                </Menu>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={2}>
        <UsageReadings category={category} />
      </Grid>
      <Grid item xs={2}>
        <UsageHighlights category={category} />
      </Grid>
    </Grid>
    <Grid item xs={1}>
      <UsageChart interval={interval} compare={compare} category={category} />
    </Grid>
  </Grid>;
}

export default HomeUtilityMonitoring;