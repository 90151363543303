import { formatDate } from "@lib/utils";
import { Paper } from "@mui/material";
import { useCallback, useMemo } from "react";

import Chart from 'react-apexcharts';
import { Neighborhood, NeighborhoodUsageHistory } from "../types";
import { Category, Unit, findCategoryLabel, formatPowerUsage, formatWaterUsage } from "@lib/labels";
import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";

export interface NeighborhoodUsageChartProps {
  neighborhood: Neighborhood;
  usageData: NeighborhoodUsageHistory;
  usageCategory: Category;
  preferredUnit: Unit;
}

const NeighborhoodUsageChart: React.FC<NeighborhoodUsageChartProps> = function NeighborhoodUsageChart(
  props: NeighborhoodUsageChartProps,
) {
  const { usageData, usageCategory, preferredUnit, neighborhood } = props;
  const homes = useAppSelector((state: RootState) => state.homes);

  const categoryLabel = useMemo(() => findCategoryLabel(usageCategory), [usageCategory]);

  const outliers = useMemo(() =>
    Object.entries(usageData.outliers).filter(([, outlierData]) => outlierData.length > 0),
    [usageData.outliers],
  );

  const chartData: ApexAxisChartSeries = useMemo(
    () => {
    if (!usageData) return [];

    return [{
        name: `Total ${categoryLabel} Usage`,
        data: usageData.total.map((row) => ({
          x: formatDate(row.bucket).toDate(),
          y: row.value,
        })),
        type: 'bar',
      },
      ...(
        outliers
          .map(([outlierId, outlierData]) => {
            const outlierHome = homes.find(h => h.property_id === outlierId);

            return {
              name: `Outlier: ${outlierHome?.name || outlierId}`,
              data: outlierData.map((row) => ({
                x: formatDate(row.bucket).toDate(),
                y: row.value,
              })),
              type: 'line',
            }
          })
      )];
    },
    [categoryLabel, homes, outliers, usageData],
  );

  const formatter = useCallback((value: any) => {
      if (usageCategory === Category.power) {
        return `${formatPowerUsage(value, preferredUnit)}`;
      } else if (usageCategory === Category.water) {
        return `${formatWaterUsage(value, preferredUnit)}`;
      }
      return value;
  }, [preferredUnit, usageCategory]);

  const chartOptions: ApexCharts.ApexOptions = useMemo(() => ({
    chart: {
      id: 'neighborhood-usage-chart',
      stacked: false,
      type: 'boxPlot',
      zoom: {
        enabled: false
      },
      animations: {
        enabled: true,
      },
      fontFamily: 'Montserrat',
      selection: {
        enabled: false,
      }
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    title: {
      text: `${neighborhood.name || 'Neighborhood'} ${categoryLabel || 'Utility'} Usage`,
      style: {
        fontFamily: 'Montserrat',
      },
      align: 'center',
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        }
      },
      yaxis: {
        lines: {
          show: true,
        },
        labels: {
          formatter,
        }
      },
    },
    xaxis: {
      type: 'datetime',
      tickPlacement: 'on',
    },
    yaxis: [
    {
      title: {
        text: `Total ${categoryLabel} Usage`,
      },
      labels: {
        formatter,
      },
      opposite: false,
      decimalsInFloat: 2,
    },
    {
      title: {
        text: `${categoryLabel} Usage`,
      },
      labels: {
        formatter,
      },
      opposite: true,
      decimalsInFloat: 2,
    }],
    tooltip: {
      fontFamily: 'Montserrat',
      y: {
        title: {
        },
        formatter,
      },
      enabled: true,
    },
    legend: {
      show: true,
      fontFamily: 'Montserrat',
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
      }
    },
    dataLabels: {
      enabled: false,
    }
  }), [categoryLabel, formatter, neighborhood.name]);

  return <Paper
    component={Chart}
    flex={1}
    series={chartData}
    type="bar"
    options={chartOptions}
    width="100%"
    height="400"
  >
  </Paper>
}

export default NeighborhoodUsageChart;
