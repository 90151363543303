import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { Property } from "@features/home/types";
import { Autocomplete, TextField } from "@mui/material";
import { useCallback, useState } from "react";

type Props = {
  onChange: (homes: string[]) => void;
  initial: string[];
  placeholder?: string;
}

const ChartHomeCompare = ({ initial, onChange, placeholder }: Props) => {
  const homes = useAppSelector((state: RootState) => state.homes);

  const [selected, setSelected] = useState<Property[]>(homes.filter(h => initial.includes(h.property_id)));

  const handleChange = useCallback((
    event: any,
    value: Property[],
  ) => {
    if (value !== null) {
      setSelected(value);
      onChange(value.map(vv => vv.property_id));
    }
  }, [onChange]);

  return (
    <Autocomplete
      fullWidth
      multiple
      options={homes}
      getOptionLabel={(p: Property) => p.name || ''}
      onChange={handleChange}
      isOptionEqualToValue={(a, b) => a.property_id === b.property_id}
      value={selected}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Homes"
          placeholder={placeholder || 'Compare other homes'}
          size="small"
        />
      )}
    />
  );
}

export default ChartHomeCompare;
