import HomesProvider from '@features/all-homes/components/HomesProvider';
import PlansProvider from '@features/all-plans/components/PlansProvider';
import { AnalyticsType } from '@features/settings/types';
import { Stack, Typography } from '@mui/material';
import ChartPreferencesCard from './ChartPreferencesCard';

/**
 * Display Preferences Menu
 */
const ChartsSection = () => {
  return (
    <Stack spacing={2}>
      <Typography variant="h4" textAlign="center">Chart Preferences</Typography>
      <PlansProvider>
        <HomesProvider>
          <Stack
            direction="row"
            justifyContent="space-evenly"
          >
          {
            Array.from(Object.values(AnalyticsType))
              .map((type) => (
                <ChartPreferencesCard
                  key={type}
                  chartType={type}
                />))
          }
          </Stack>
        </HomesProvider>
      </PlansProvider>
    </Stack>);
}

export default ChartsSection;