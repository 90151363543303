import { formatDate } from "@lib/utils";
import { IconButton, Paper, Stack, Tooltip, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { DataGrid, GridColumns, GridValueGetterParams } from "@mui/x-data-grid";
import { useMemo } from "react";
import { GasBill } from "../types";

export interface BillingHistoryProps {
  billingData?: GasBill[];
  handleCreateNewBill: () => void;
  handleDeleteBill: (bill: GasBill) => void;
}

const BillingHistory: React.FC<BillingHistoryProps> = function BillingHistory(
  props: BillingHistoryProps,
) {
  const { billingData, handleCreateNewBill, handleDeleteBill } = props;
  
  const billingDataColumns: GridColumns = useMemo(() => {
    return [
      {
        field: 'id',
        headerName: 'Billing Cycle',
        flex: 0.75,
        renderCell: (params: GridValueGetterParams) => (
          `${
            formatDate(params.row.billing_cycle.start).format('YYYY-MM-DD')
          } to ${
            formatDate(params.row.billing_cycle.end).format('YYYY-MM-DD')
          }`
        ),
        editable: false,
        filterable: false,
        resizable: false,
        sortable: false,
      },
      {
        field: 'therms_used',
        headerName: 'Therms Used',
        type: 'number',
        flex: 0.5,
        editable: false,
        filterable: false,
        resizable: false,
        sortable: false,
      },
      {
        field: 'therm_cost',
        headerName: 'Cost per Therm',
        type: 'number',
        flex: 0.5,
        editable: false,
        filterable: false,
        resizable: false,
        sortable: false,
      },
      {
        field: 'actions',
        headerName: '',
        align: 'center',
        headerAlign: 'center',
        renderHeader: () => (
          <Tooltip title="Create new bill record">
            <IconButton onClick={handleCreateNewBill} color="success">
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        ),
        renderCell: (params) => (
          <Tooltip title="Delete bill">
            <IconButton onClick={() => handleDeleteBill(params.row)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ),
        editable: false,
        filterable: false,
        resizable: false,
        sortable: false,
      },
    ];
  }, [handleCreateNewBill, handleDeleteBill]);

  const billingDataRows = useMemo(() => (billingData || []).map((bd) => ({
    ...bd,
    id: [
      bd.billing_cycle.ms_start,
      bd.billing_cycle.ms_end,
    ].join('-')
  })), [billingData]);

  return <Paper component={Stack} direction="column" flex={1}>
    <Typography variant="h5" sx={{ textAlign: 'center', pt: 1 }}>
      Billing History
    </Typography>
    <Stack direction="row" flex={1}>
      <DataGrid
        autoHeight
        rows={billingDataRows}
        columns={billingDataColumns}
        pageSize={5}
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        disableColumnSelector
      />
    </Stack>
  </Paper>;
}

export default BillingHistory;
