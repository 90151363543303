import { PropertyManifestEntry } from "@features/home-manifest/types";
import { Device } from "@features/home/types";
import { ManifestDevice, Sensor } from "@features/plan-manifest/types";
import { Box, Stack, Typography } from "@mui/material";
import SensorToConfirm from "./SensorToConfirm";

type Props = {
  entry: PropertyManifestEntry;
  devices: Device[];
  onConfirm: (entry: PropertyManifestEntry, sensor: Sensor, device: Device) => void;
  onUnconfirm: (entryId: string) => void;
}

/* Displays Manifest Entry */
const EntryToConfirm = ({
  entry,
  devices,
  onConfirm,
  onUnconfirm,
}: Props) => (
  <Box component="div">
    <Typography variant="h6" sx={{ mb: 1 }}>
      {(entry.device as ManifestDevice).friendly_name}
    </Typography>
    <Stack direction="row" spacing={1}>
      {
        ((entry.device as ManifestDevice).sensors || []).map((ss: Sensor) => (
          <SensorToConfirm
            key={`confirming-${ss.order}-${entry.manifest_entry_id}`}
            entry={entry}
            sensor={ss}
            devices={devices}
            onConfirm={onConfirm}
            onUnconfirm={onUnconfirm}
          />
        ))
      }
    </Stack>
  </Box>
);

export default EntryToConfirm;