import UtilityGroupList from "@features/all-utility-groups/components/UtilityGroupList";
import UtilityGroupsProvider from "@features/all-utility-groups/components/UtilityGroupsProvider";
import Header from "@features/Common/Header";
import { FC } from "react";


const AllUtilityGroups: FC = () => {
  return (
    <UtilityGroupsProvider>
      <Header />
      <UtilityGroupList />
    </UtilityGroupsProvider>
  );
}

export default AllUtilityGroups;