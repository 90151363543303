import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ZipsProvider from "@features/all-zip-codes/components/ZipsProvider";
import { usePrefs } from "@features/settings/slice";
import { Aggregate, AnalyticsType, BinSize, ChartOption, ChartOptionValueType, ChartPreferences, ChartsPreferences, ChartType, interval, TrendLines } from "@features/settings/types";
import { TenantType } from "@features/userTenant/types";
import { Category, findCategoryLabel, findMeasureLabel, Measure } from "@lib/labels";
import { LoadingButton } from "@mui/lab";
import { Button, Card, CardActions, CardContent, CardHeader, Divider, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from 'react';
import ChartAggregation from "./ChartAggregation";
import ChartBins from "./ChartBins";
import ChartCategories from "./ChartCategories";
import ChartHomeCompare from "./ChartHomeCompare";
import ChartIntervals from "./ChartIntervals";
import ChartMeasures from "./ChartMeasures";
import ChartPlanCompare from "./ChartPlanCompare";
import ChartShowHide from "./ChartShowHide";
import ChartTrendLines from "./ChartTrendLines";
import ChartTypes from "./ChartTypes";
import ChartZipCompare from "./ChartZipCompare";

type Props = {
  chartType: AnalyticsType;
}

const ChartPreferencesCard = ({ chartType }: Props) => {
  const prefProvider = usePrefs();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const preferenceState = useAppSelector((state: RootState) => state.preferences);
  const homes = useAppSelector((state: RootState) => state.homes);
  const plans = useAppSelector((state: RootState) => state.plans);

  const preferences = useMemo(() => preferenceState.preferences, [preferenceState.preferences]);
  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const chartPreferences = useMemo(() => preferences?.charts[chartType], [chartType, preferences?.charts]);

  const initialPreferences = useMemo(() => ({
    [ChartOption.category]: chartPreferences?.category || Category.system,
    [ChartOption.measure]: chartPreferences?.measure || Measure.status,
    [ChartOption.interval]: chartPreferences?.interval || interval.oneday,
    [ChartOption.chartType]: chartPreferences?.chartType || ChartType.line,
    [ChartOption.trendLines]: chartPreferences?.trendLines || TrendLines.none,
    [ChartOption.showForecast]: chartPreferences?.showForecast || false,
    [ChartOption.aggregate]: chartPreferences?.aggregate || Aggregate.avg,
    [ChartOption.binSize]: chartPreferences?.binSize || BinSize.hour,
    [ChartOption.compareHomes]: chartPreferences?.compareHomes || [],
    [ChartOption.comparePlans]: chartPreferences?.comparePlans || [],
    [ChartOption.compareZip]: chartPreferences?.compareZip || '',
  } as ChartPreferences), [chartPreferences?.aggregate, chartPreferences?.binSize, chartPreferences?.category, chartPreferences?.chartType, chartPreferences?.compareHomes, chartPreferences?.comparePlans, chartPreferences?.compareZip, chartPreferences?.interval, chartPreferences?.measure, chartPreferences?.showForecast, chartPreferences?.trendLines]);

  const [currentPreferences, setCurrentPreferences] = useState<ChartPreferences>(initialPreferences);

  const currentPlanNames = useMemo(() => plans
    .filter(p => currentPreferences.comparePlans?.includes(p.plan_id as string))
    .map(p => p.name), [currentPreferences.comparePlans, plans]);

  const currentHomeNames = useMemo(() => homes
    .filter(h => currentPreferences.compareHomes?.includes(h.property_id))
    .map(h => h.name || h.physical_address.address_line_1), [currentPreferences.compareHomes, homes]);

  const [editing, setEditing] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const updatePreferences = useCallback((attribute: ChartOption, value: ChartOptionValueType) => {
    setCurrentPreferences({
      ...currentPreferences,
      [attribute]: value,
    } as ChartPreferences);
  }, [currentPreferences]);

  const handleCancel = useCallback(() => {
    setCurrentPreferences(initialPreferences);
    setEditing(false);
  }, [initialPreferences]);

  const handleSavePreferences = useCallback(() => {
    setSaving(true);
    prefProvider.setPrefs({
      ...preferences,
      charts: {
        ...preferences.charts,
        [chartType]: currentPreferences,
      } as ChartsPreferences,
    }, () => prefProvider.getPrefs(() => {
      setSaving(false);
    }));
  }, [chartType, currentPreferences, prefProvider, preferences]);

  useEffect(() => {
    if (!saving) setEditing(false);
  }, [saving]);

  return (
    <Card sx={{
      width: '40em',
    }}>
      <CardHeader title={chartType.toLocaleUpperCase()}/>
      <Divider />
      <CardContent sx={{
        height: '40em',
        overflowY: 'auto',
      }}>
        <Stack spacing={1}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Category</Typography>
            {
              editing
                ? <ChartCategories
                    chart={chartType}
                    initial={currentPreferences.category}
                    onChange={(c: Category) => updatePreferences(ChartOption.category, c)}
                  />
                : <Typography variant="button">{findCategoryLabel(currentPreferences.category)}</Typography>
            }
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Measure</Typography>
            {
              editing
                ? <ChartMeasures
                    initial={currentPreferences.measure}
                    category={currentPreferences.category}
                    onChange={(m: Measure) => updatePreferences(ChartOption.measure, m)}
                  />
                : <Typography variant="button">{findMeasureLabel(currentPreferences.measure)}</Typography>
            }
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Time Interval</Typography>
            {
              editing
                ? <ChartIntervals
                    initial={currentPreferences.interval}
                    onChange={(i: interval) => updatePreferences(ChartOption.interval, i)}
                  />
                : <Typography variant="button">{findMeasureLabel(currentPreferences.interval)}</Typography>
            }
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Chart Type</Typography>
            {
              editing
                ? <ChartTypes
                    initial={currentPreferences.chartType}
                    onChange={(t: ChartType) => updatePreferences(ChartOption.chartType, t)}
                  />
                : <Typography variant="button">{currentPreferences.chartType}</Typography>
            }
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Show Trend Lines</Typography>
            {
              editing
                ? <ChartTrendLines
                    initial={currentPreferences.trendLines}
                    onChange={(t: TrendLines) => updatePreferences(ChartOption.trendLines, t)}
                  />
                : <Typography variant="button">{currentPreferences.trendLines}</Typography>
            }
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Show RIoT Average</Typography>
            {
              editing
                ? <ChartShowHide
                    initial={currentPreferences.showAverage}
                    onChange={(w: boolean) => updatePreferences(ChartOption.showAverage, w)}
                  />
                : <Typography variant="button">{currentPreferences.showAverage ? 'yes' : 'no'}</Typography>
            }
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Show Weather Forecast</Typography>
            {
              editing
                ? <ChartShowHide
                    initial={currentPreferences.showForecast}
                    onChange={(w: boolean) => updatePreferences(ChartOption.showForecast, w)}
                  />
                : <Typography variant="button">{currentPreferences.showForecast ? 'yes' : 'no'}</Typography>
            }
          </Stack>
          <Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Show readings as</Typography>
              {
                editing
                  ? <ChartAggregation
                      initial={currentPreferences.aggregate}
                      onChange={(a: Aggregate) => updatePreferences(ChartOption.aggregate, a)}
                    />
                  : <Typography variant="button">{currentPreferences.aggregate}</Typography>
              }
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                mt: 0,
                ml: 2,
                pt: 0,
            }}>
              <Typography variant="body1">Group readings by</Typography>
              {
                editing
                  ? <ChartBins
                      initial={currentPreferences.binSize}
                      aggregate={currentPreferences.aggregate}
                      onChange={(b: BinSize) => updatePreferences(ChartOption.binSize, b)}
                    />
                  : <Typography variant="button">{currentPreferences.binSize}</Typography>
              }
            </Stack>
          </Stack>
          {
            [TenantType.Builder, TenantType.System].includes(currentTenant?.tenant_type) &&
            <Stack spacing={2}>
              <Divider />
              <Typography variant="h6">Compare</Typography>
              <ZipsProvider>
              {
                editing
                  ? <ChartZipCompare
                      initial={currentPreferences.compareZip || []}
                      onChange={(pp: string[]) => updatePreferences(ChartOption.compareZip, pp)}
                    />
                  : <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{
                        mt: 0,
                        pl: 2,
                      }}>
                      <Typography variant="body1">Zip Code</Typography>
                      <Typography variant="button">{currentPreferences.compareZip}</Typography>
                    </Stack>
                }
              </ZipsProvider>
              {
                [TenantType.Builder, TenantType.System].includes(currentTenant?.tenant_type) &&
                <>
                  {
                    editing
                      ? <ChartPlanCompare
                          initial={currentPreferences.comparePlans || []}
                          onChange={(pp: string[]) => updatePreferences(ChartOption.comparePlans, pp)}
                        />
                      : <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{
                            mt: 0,
                            pl: 2,
                          }}>
                          <Typography variant="body1">Flooplans</Typography>
                          <Stack alignItems="flex-end">
                            {
                              currentPlanNames.map(cpn => (
                                <Typography variant="button" key={`compare-plan-${cpn}`}>{cpn}</Typography>
                              ))
                            }
                          </Stack>
                        </Stack>
                  }
                  {
                    editing
                      ? <ChartHomeCompare
                          initial={currentPreferences.compareHomes || []}
                          onChange={(hh: string[]) => updatePreferences(ChartOption.compareHomes, hh)}
                        />
                      : <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{
                            mt: 0,
                            pl: 2,
                          }}>
                          <Typography variant="body1">Homes</Typography>
                          <Stack alignItems="flex-end">
                            {
                              currentHomeNames.map(chn => (
                                <Typography variant="button" key={`compare-plan-${chn}`}>{chn}</Typography>
                              ))
                            }
                          </Stack>
                        </Stack>
                  }
                </>
              }
            </Stack>
          }
        </Stack>
      </CardContent>
      <Divider />
      <CardActions sx={{ justifyContent: 'flex-end' }}>
      {
          editing &&
          <>
            <LoadingButton
              variant="contained"
              onClick={handleSavePreferences}
              loading={saving}
            >
              Apply
            </LoadingButton>
            <Button onClick={handleCancel}>Cancel</Button>
          </>
        }
        {
          !editing && <Button variant="contained" onClick={() => setEditing(true)}>Edit</Button>
        }
      </CardActions>
    </Card>

  );
}

export default ChartPreferencesCard;