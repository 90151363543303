import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useCallback, useState } from "react";


type Props = {
  code: string;
}

const CodeBlock = ({ code }: Props) => {
  const [copySuccess, setCopySuccess] = useState<boolean>(false);

  const handleCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(code)
      .then(() => {
        setCopySuccess(true);
        const timeout = setTimeout(() => {
          setCopySuccess(false);
          clearTimeout(timeout);
        }, 800);
      });
  }, [code]);

  return (
    <Box
      component="div"
      sx={{
        border: '4px solid grey',
        letterSpacing: 1,
        fontFamily: 'Courier, monospace',
        whiteSpace: 'pre-wrap',
        backgroundColor: 'rgba(5, 5, 5)',
        color: 'white',
        padding: 1,
        margin: 1,
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="top">
        <Typography variant="body2" sx={{
          padding: 1,
        }}>
          {code}
        </Typography>
        <Tooltip
          placement="top"
          arrow
          title={copySuccess ? 'Copied!' : "Copy to clipboard"}
        >
          <span>
            <IconButton onClick={handleCopyToClipboard}>
              <ContentCopyIcon htmlColor='white' />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    </Box>
  );
}

export default CodeBlock;