import { Alert, AlertTitle } from "@mui/material";

/** Message for user if they have no user tenant ID */
const MissingTenant = () => {
  return (
    <Alert sx={{ m: 'auto', p: 2, lineHeight: 1.5 }} severity="error">
      <AlertTitle>Inactive Account</AlertTitle>
      Your user account is not associated with a tenant.<br />
      Please contact RIoT Technology to enable access for your account.
    </Alert>
  );
};

export default MissingTenant;
