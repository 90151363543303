import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { PropertyManifestEntry } from "@features/home-manifest/types";
import CategoryReadingIcon from "@features/home-status/components/CategoryReadingIcon";
import { ReadingPair } from "@features/home-status/types";
import { getDeviceName } from "@features/home-status/utils";
import { ManifestDevice } from "@features/plan-manifest/types";
import { List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { useMemo } from "react";

type Props = {
  entries: PropertyManifestEntry[];
}

const ReadingsList = ({ entries }: Props) => {
  const property = useAppSelector((state: RootState) => state.property);
  const devices = useMemo(() => property.devices, [property.devices]);

  return (
    <List sx={{ width: '30em' }} dense>
      {
        entries
          .reduce((readings, entry) => [
            ...readings,
            ...(entry.device as ManifestDevice).sensors
              ?.map(ss => ({
                device: devices?.find(dd => dd.data.entity_id === entry.sensor_map?.[ss.sensor_id ?? ss.friendly_name]),
                sensor: ss,
                deviceName: getDeviceName(entry.device as ManifestDevice),
                area: entry.property_area,
                entryId: entry.manifest_entry_id,
              } as ReadingPair)),
          ] as ReadingPair[], [] as ReadingPair[])
          .map((s, sidx) => (
            <ListItem key={`device-reading-${sidx}`} >
              <ListItemAvatar sx={{ width: '6em' }}>
                <CategoryReadingIcon
                  category={s.sensor.sensor_category}
                  readings={s}
                  measure={s.sensor.measure_name}
                />
              </ListItemAvatar>
              <ListItemText
                primary={s.sensor?.friendly_name}
                secondary={s.deviceName}
              />
            </ListItem>
          ))
      }
    </List>);
}

export default ReadingsList;