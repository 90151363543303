import RolesProvider from "@features/all-users/components/RolesProvider";
import UsersProvider from "@features/all-users/components/UsersProvider";
import Header from "@features/Common/Header";
import { FC } from "react";
import UserList from "../features/all-users/components/UserList";

/** Wrapper for /users */
const AllUsers: FC = () => {
  return (
    <UsersProvider>
      <RolesProvider>
        <Header />
        <UserList />
      </RolesProvider>
    </UsersProvider>
  );
}
export default AllUsers;
