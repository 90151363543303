
import { createTheme, ThemeOptions } from '@mui/material/styles';

import './fonts/Montserrat-VariableFont_wght.ttf';

/** Blue from RIoT logo */
export const RIOT_BLUE = "rgb(0, 163, 216)";

export const URGENT = "rgb(180, 40, 40)";
export const WARNING = "rgb(200, 120, 40)";
export const INFO = RIOT_BLUE;
export const NOTICE = "#CCCCCC";
export const SUCCESS = '#005701';
const options: ThemeOptions = {
  palette: {
    primary: {
      main: RIOT_BLUE,
    },
    secondary: {
      main: NOTICE,
    },
    warning: {
      main: WARNING,
    },
    error: {
      main: URGENT,
    },
    success: {
      main: SUCCESS,
    },
    info: {
      main: SUCCESS,
    }
  },
};

const theme = createTheme(options);

export default theme;
