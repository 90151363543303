import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ConfirmDialog from "@features/Common/ConfirmDialog";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import DocumentsProvider from "@features/all-documents/components/DocumentsProvider";
import { Plan } from "@features/floorplan/types";
import { useDeletePlanManifestMutation } from "@features/plan-manifest/api";
import { PlanManifest } from "@features/plan-manifest/types";
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import ManifestIcon from '@mui/icons-material/Ballot';
import CloseIcon from '@mui/icons-material/Close';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import {
  CircularProgress,
  Divider,
  IconButton,
  List, ListItemButton,
  ListItemIcon,
  ListItemText, ListSubheader, Paper,
  Stack,
  Typography
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { RIOT_BLUE } from "theme";
import ManifestEditor from "../../plan-manifest/components/ManifestEditor";
import ListControls from "./ListControls";
import NewManifestModal from "./NewManifestModal";

export type PlanEditorProps = {
  plan: Plan;
  manifests: PlanManifest[];
  onChange: () => void;
  onClose: () => void;
}

const PlanEditor = ({
  plan,
  manifests,
  onChange,
  onClose,
 }: PlanEditorProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant.builder_color || RIOT_BLUE, [currentTenant.builder_color]);

  const [selectedManifest, setSelectedManifest] = useState<PlanManifest | null>(null);
  const [creatingPlanManifest, setCreatingPlanManifest] = useState<boolean>(false);
  const [confirmingDeleteManifest, setConfirmingDeleteManifest] = useState<boolean>(false);
  const [deletingManifest, setDeletingManifest] = useState<boolean>(false);

  const [deletePlanManifest] = useDeletePlanManifestMutation();

  useEffect(() => {
    setSelectedManifest(null);
  }, [plan]);

  useEffect(() => {
    if (manifests && selectedManifest) {
      setSelectedManifest(manifests.find(m => m.manifest_id === selectedManifest.manifest_id) || null);
    }
  }, [plan, manifests, selectedManifest]);

  const handleDeleteManifest = useCallback(async () => {
    setDeletingManifest(true);
    if (!selectedManifest) return false;

    const deletedManifest = await deletePlanManifest({
      userTenantId: currentTenant.tenant_id!,
      planId: plan.plan_id!,
      planManifestId: selectedManifest.manifest_id,
    });

    const errorDetails = (deletedManifest as ApiErrorResponse).error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't delete plan manifest:", {
        key: "planmanifest-error",
        content: (
          <ExpandableSnack
            id="planmanifest-error"
            message={"Couldn't delete plan manifest:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
      return false;
    } else {
      enqueueSnackbar("Deleted plan manifest", {
        variant: "success",
      });
      onChange();
      setDeletingManifest(false);
      return true;
    }
  }, [selectedManifest, deletePlanManifest, currentTenant.tenant_id, plan.plan_id, enqueueSnackbar, onChange]);

  if (!plan) return <CircularProgress />;

  return (
    <>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{height: '100%'}}>
        <Paper sx={{ m: 1, p: 1, minWidth: 300, height: '100%' }}>
          <Stack direction="column" justifyContent="space-between" sx={{ height: '100%' }}>
            <List
              sx={{
                width: '100%',
                position: 'relative',
                overflow: 'auto',
                maxHeight: 600,
              }}
              subheader={
                <ListSubheader sx={{ color: themeColor }}>
                  <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
                    <ListItemText>
                      Manifests
                    </ListItemText>
                    <IconButton onClick={() => onClose()}>
                      <CloseIcon />
                    </IconButton>
                  </Stack>

                  <Divider sx={{ borderColor: themeColor }} />
                </ListSubheader>
              }
            >
              {
                manifests.map(manifest => (
                  <ListItemButton
                    key={manifest.manifest_id}
                    onClick={() => setSelectedManifest(manifest)}
                    selected={selectedManifest?.manifest_id === manifest.manifest_id}
                  >
                    <ListItemIcon>
                      <ManifestIcon htmlColor={themeColor} />
                    </ListItemIcon>
                    <ListItemText>
                      {manifest.name || manifest.description || '-'}
                    </ListItemText>
                  </ListItemButton>
                ))
              }
            </List>
            <ListControls
              onAdd={() => setCreatingPlanManifest(true)}
              onDelete={selectedManifest ? () => setConfirmingDeleteManifest(true) : undefined}
              deleteLoading={deletingManifest}
            />
          </Stack>
        </Paper>
        {
          selectedManifest &&
          <DocumentsProvider>
            <DoubleArrowIcon sx={{ color: 'white' }} fontSize="large" />
            <ManifestEditor
              manifest={selectedManifest}
              onChange={onChange}
            />
          </DocumentsProvider>
        }
      </Stack>
      {
        creatingPlanManifest &&
        <NewManifestModal
          plan={plan}
          open={creatingPlanManifest}
          onClose={(changes) => {
            setCreatingPlanManifest(false);

            if (changes) {
              onChange();
            }
          }}
        />
      }
      <ConfirmDialog
        open={confirmingDeleteManifest}
        onClose={() => setConfirmingDeleteManifest(false)}
        onConfirm={handleDeleteManifest}
        title="Confirm deletion"
        initialValues={{
          isConfirmed: false,
        }}
      >
        <Typography variant="body1">
          Are you sure you want to delete
          {' '}
          {selectedManifest?.name || 'this manifest'}
          ?
        </Typography>
      </ConfirmDialog>
    </>
  )
}

export default PlanEditor;
