import { useAppDispatch, useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { pollingInterval } from '@features/tenant-selector/types';
import { FC, useEffect, useMemo } from "react";
import { useHomeOemDevicesQuery } from '../api';
import { setDevices } from '../slice';

const OemDevicesProvider: FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const property = useAppSelector((state: RootState) => state.property);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const {
    currentData: oemDevices,
  } = useHomeOemDevicesQuery({
    userTenantId: currentTenant?.tenant_id || '',
    propertyId: property?.property_id || '',
  }, {
    skip: !currentTenant?.tenant_id || !property?.property_id,
    pollingInterval,
  });

  useEffect(() => {
    if (oemDevices !== undefined) {
      dispatch(setDevices(oemDevices));
    }
  }, [dispatch, oemDevices]);

  return children as JSX.Element;
}

export default OemDevicesProvider;