import { useAppDispatch, useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { FC, ReactElement, useEffect, useMemo } from "react";
import { useWeatherForecastQuery } from '../api';
import { setCurrentWeather, setForecast } from '../slice';

// 15 minutes
const weatherPollingInterval = 15*60*60*1000;

const WeatherProvider: FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const property = useAppSelector((state: RootState) => state.property);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const {
    currentData: weatherData,
  } = useWeatherForecastQuery({
    userTenantId: currentTenant?.tenant_id || '',
    propertyId: property?.property_id || '',
  }, {
    skip: !currentTenant?.tenant_id || !property?.property_id,
    pollingInterval: weatherPollingInterval,
  });

  useEffect(() => {
    if (weatherData) {
      dispatch(setCurrentWeather(weatherData.current));
      dispatch(setForecast(weatherData.forecast));
    }
  }, [dispatch, weatherData]);

  return children as ReactElement;
};

export default WeatherProvider;