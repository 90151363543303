// Reducer Imports //
import buildersReducer from '@features/all-builders/slice';
import contactsReducer from '@features/all-contacts/slice';
import documentsReducer from '@features/all-documents/slice';
import homeownersReducer from '@features/all-homeowners/slice';
import homesReducer from '@features/all-homes/slice';
import neighborhoodsReducer from '@features/all-neighborhoods/slice';
import plansReducer from '@features/all-plans/slice';
import propertyManagersReducer from '@features/all-property-managers/slice';
import usersReducer from '@features/all-users/slice';
import utilGroupsReducer from '@features/all-utility-groups/slice';
import zipCodesReducer from '@features/all-zip-codes/slice';
import { riotApi } from '@features/aws-user/api';
import awsUserReducer from '@features/aws-user/slice';
import logoReducer from '@features/builder-branding/slice';
import utilGroupEnergyReducer from '@features/energy/slice';
import floorplanReducer from '@features/floorplan/slice';
import notificationsReducer from '@features/home-notifications/slice';
import oemDevicesReducer from '@features/home-oem/slice';
import categoryFilterReducer from '@features/home-status/slice';
import supportCasesReducer from '@features/home-support-cases/slice';
import { homeRegApi } from '@features/home/regApi';
import homeReducer from '@features/home/slice';
import inventoryReducer from '@features/inventory/slice';
import neighborhoodReducer from '@features/neighborhood/slice';
import sceneTemplatesReducer from '@features/scene-templates/slice';
import preferencesReducer from '@features/settings/slice';
import supportCaseReducer from '@features/support-case/slice';
import tenantReducer from '@features/tenant-selector/slice';
import userTenantReducer from '@features/userTenant/slice';
import utilGroupReducer from '@features/utility-group/slice';
import weatherReducer from '@features/weather/slice';
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

const allStateReducers = {
  /** state field: reducer handling it */
  awsUser: awsUserReducer,
  builders: buildersReducer,
  displayedCategories: categoryFilterReducer,
  contacts: contactsReducer,
  documents: documentsReducer,
  homeowners: homeownersReducer,
  floorplan: floorplanReducer,
  homes: homesReducer,
  inventory: inventoryReducer,
  logo: logoReducer,
  neighborhoods: neighborhoodsReducer,
  neighborhood: neighborhoodReducer,
  notifications: notificationsReducer,
  oemDevices: oemDevicesReducer,
  plans: plansReducer,
  preferences: preferencesReducer,
  property: homeReducer,
  propertyManagers: propertyManagersReducer,
  sceneTemplates: sceneTemplatesReducer,
  supportCases: supportCasesReducer,
  supportCase: supportCaseReducer,
  tenant: tenantReducer,
  users: usersReducer,
  userTenant: userTenantReducer,
  utilgroups: utilGroupsReducer,
  utilityGroup: utilGroupReducer,
  utilGroupEnergy: utilGroupEnergyReducer,
  weather: weatherReducer,
  zipCodes: zipCodesReducer,
};

const allApiReducers = {
  /** api slice name: api reduser handling it */
  [riotApi.reducerPath]: riotApi.reducer,
  [homeRegApi.reducerPath]: homeRegApi.reducer,
};

export const store = configureStore({
  reducer: {
    ...allStateReducers,
    ...allApiReducers,
  },
  // this piece enables options like polling, fetch-caching, hydrating, etc.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(riotApi.middleware)
      .concat(homeRegApi.middleware),
});

// enable listener behavior for the store
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
