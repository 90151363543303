import { riotApi } from "@features/aws-user/api";
import { ManifestDevice } from "@features/plan-manifest/types";
import { HTTPMethodType } from "@lib/types";
import { U } from "@lib/utils";


export const inventoryApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      createDevice: builder.mutation<ManifestDevice, {
        userTenantId: string,
        body: Partial<ManifestDevice>,
      }>({
        query: ({ userTenantId, body }) => ({
          url: `tenant/${U(userTenantId)}/devices`,
          method: HTTPMethodType.POST,
          body,
        }),
      }),
      updateDevice: builder.mutation<ManifestDevice, {
        userTenantId: string,
        deviceId: string,
        body: Partial<ManifestDevice>,
      }>({
        query: ({ userTenantId, deviceId, body }) => ({
          url: `tenant/${U(userTenantId)}/devices/${U(deviceId)}`,
          method: HTTPMethodType.PATCH,
          body,
        }),
      }),
      inventory: builder.query<ManifestDevice[], {
        userTenantId: string,
      }>({
        query: ({ userTenantId }) => ({
          url: `tenant/${U(userTenantId)}/devices`,
        })
      })
    };
  }
});

export const {
  useCreateDeviceMutation,
  useUpdateDeviceMutation,
  useInventoryQuery,
} = inventoryApi;
