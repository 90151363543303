import OemDeviceList from "./OemDeviceList";
import OemDevicesProvider from "./OemDevicesProvider";


const HomeOem = () => {
  return (
    <OemDevicesProvider>
      <OemDeviceList />
    </OemDevicesProvider>
  );
}

export default HomeOem;