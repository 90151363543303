import { PropertyManifestEntry } from "@features/home-manifest/types";
import { AttentionLevel, NotificationStatus, UserNotification, UserNotificationStack } from "@features/home-notifications/types";
import { ManifestDevice } from "@features/plan-manifest/types";
import { ALL_CATEGORIES, Category, findCategoryLabel } from "@lib/labels";
import { MutableRefObject } from "react";
import { INFO, NOTICE, SUCCESS, URGENT, WARNING } from "theme";

const ALERT_LEVELS = [
  AttentionLevel.urgent,
  AttentionLevel.warning,
];


/**
 *
 * @param notifications
 * @param entries
 * @param area
 * @returns number of active alerts for given home area
 */
export const getAreaCount = (
  notifications: UserNotificationStack[],
  entries: PropertyManifestEntry[],
  area: string,
) => {
  const devices = entries
    .filter(ee => ee.property_area === area)
    .map(ee => ee.gateway_device_id);

  const filteredAlerts = notifications
    .filter(nn => nn.lifecycle_action !== NotificationStatus.dismissed)
    .filter(nn => ALERT_LEVELS.includes(nn.attention_level))
    .filter(aa => devices.includes(aa.device_id));

  return ALERT_LEVELS.reduce((acc, level) => ({
    ...acc,
    [level]: filteredAlerts.filter(aa => aa.attention_level === level).length,
  }), {});
}


/**
 *
 * @param notifications
 * @param entries
 * @returns a map of notification counts per home area
 */
export const getAllAreaCounts = (
  notifications: UserNotificationStack[],
  entries: PropertyManifestEntry[],
) => entries
  .map(ee => ee.property_area)
  .reduce((acc, area: string) => ({
    ...acc,
    [area]: getAreaCount(notifications, entries, area),
  }), {});


/**
 * Gets alerts for a given area
 * @param notifications - all user notifications
 * @param entries - Home's manifest entries
 * @param area - given area
 * @returns
 */
export const getAreaAlerts = (
  notifications: UserNotificationStack[],
  entries: PropertyManifestEntry[],
  area: string,
) => {
  const devices = entries
    .filter(ee => ee.property_area === area)
    .map(ee => ee.gateway_device_id);

  return notifications
    .filter(nn => nn.lifecycle_action !== NotificationStatus.dismissed)
    .filter(nn => ALERT_LEVELS.includes(nn.attention_level))
    .filter(aa => devices.includes(aa.device_id));
}


/**
 *
 * @param notifications
 * @param entries
 * @param category
 * @returns number of active alerts for given category
 */
export const getCategoryCount = (
  notifications: UserNotificationStack[],
  entries: PropertyManifestEntry[],
  category: Category,
) => {

  // filter devices down to our category
  const deviceMatches = entries
    .filter(ee => (ee.device as ManifestDevice).sensors
      ?.map(ss => ss.sensor_category).includes(category));

  // no devices for given category,
  // this means we won't have any notifications for it either
  if (!deviceMatches.length) return 0;

  // map found devices down to appropriate sensors
  const sensors = deviceMatches
    .map(dd => ({
      deviceId: dd.gateway_device_id,
      sensorId: dd.sensor_map?.[
        (dd.device as ManifestDevice).sensors.find(ss => ss.sensor_category === category)?.sensor_id
          || (dd.device as ManifestDevice).sensors.find(ss => ss.sensor_category === category)?.friendly_name
          || ''
      ],
    }));


  const filteredAlerts = notifications
    .filter(nn => nn.lifecycle_action !== NotificationStatus.dismissed)
    .filter(nn => ALERT_LEVELS.includes(nn.attention_level))
    .filter(nn => sensors
      .some(ss => ss.sensorId === nn.entity_id && ss.deviceId === nn.device_id)
    );

  return ALERT_LEVELS.reduce((acc, level) => ({
    ...acc,
    [level]: filteredAlerts.filter(aa => aa.attention_level === level).length,
  }), {});

}


export const getCategoryAlerts = (
  notifications: UserNotificationStack[],
  entries: PropertyManifestEntry[],
  category: Category,
) => {

  // filter devices down to our category
  const deviceMatches = entries
    .filter(ee => (ee.device as ManifestDevice).sensors
      ?.map(ss => ss.sensor_category).includes(category));

  // no devices for given category,
  // this means we won't have any notifications for it either
  if (!deviceMatches.length) return [];

  // map found devices down to appropriate sensors
  const sensors = deviceMatches
    .map(dd => ({
      deviceId: dd.gateway_device_id,
      sensorId: (dd.device as ManifestDevice).sensors.find(ss => ss.sensor_category === category)?.entity_id,
    }));


  return notifications
    .filter(nn => nn.lifecycle_action !== NotificationStatus.dismissed)
    .filter(nn => ALERT_LEVELS.includes(nn.attention_level))
    .filter(nn => sensors.some(ss => ss.sensorId === nn.entity_id && ss.deviceId === nn.device_id));
}


/**
 *
 * @param notifications
 * @param entries
 * @returns a map of notification counts per category
 */
export const getAllCategoryCounts = (
  notifications: UserNotificationStack[],
  entries: PropertyManifestEntry[],
) => ALL_CATEGORIES
  .reduce((acc, cat) => ({
    ...acc,
    [findCategoryLabel(cat)]: getCategoryCount(notifications, entries, cat),
  }), {});


/**
 *
 * @param notifications
 * @param level
 * @returns number of active alerts for given attention level
 */
export const getAttentionLevelCounts = (
  notifications: UserNotification[],
  level: AttentionLevel,
) => notifications
  .filter(nn => nn.lifecycle_action !== NotificationStatus.dismissed)
  .filter(nn => nn.attention_level === level).length;


/**
 *
 * @param notifications
 * @returns a map of notification counts per attention level
 */
export const getAlertCounts = (
  notifications: UserNotification[],
) => ALERT_LEVELS.reduce((acc, level) => ({
  ...acc,
  [level]: getAttentionLevelCounts(notifications, level),
}), {});


/**
 *
 * @param notifications
 * @param level
 * @returns alerts for a given attention level
 */
export const getAttentionLevelAlerts = (
  notifications: UserNotification[],
  level: AttentionLevel,
) => notifications
  .filter(nn => nn.lifecycle_action !== NotificationStatus.dismissed)
  .filter(nn => nn.attention_level === level)


/**
 * Selects a file to use for home area's image
 * @param areaName home area
 * @returns name of file with corresponding image
 */
export const getImage = (areaName: string): string => {
  if (!areaName) return 'blankroom.jpg';
  if (
    areaName.toLowerCase().includes('bath')
    && areaName.toLowerCase().includes('owner')
  ) return 'ownerbath.webp';
  if (areaName.toLowerCase().includes('bathroom0')) return 'ownerbath.webp';
  if (areaName.toLowerCase().includes('bathroom')) return 'bathroom.png';

  if (areaName.toLowerCase().includes('great')) return 'greatroom.webp';
  if (areaName.toLowerCase().includes('bedroom0')) return 'ownersuite.webp'
  if (areaName.toLowerCase().includes('suite')) return 'ownersuite.webp'

  if (areaName.toLowerCase().includes('bedroom1')) return 'bedroom1.jpg';
  if (areaName.toLowerCase().includes('bedroom2')) return 'bedroom2.png';

  if (areaName.toLowerCase().includes('dining')) return 'diningroom.webp';
  if (areaName.toLowerCase().includes('kitchen')) return 'kitchen.jpg';
  if (areaName.toLowerCase().includes('front')) return 'frontdoor.webp';

  return 'placeholder.jpg';
}

/**
 *
 * @param level attention level
 * @returns string value for the color corresponding the attention level
 */
export const getAttentionLevelColor = (level: AttentionLevel | undefined) => {
  switch (level) {
    case AttentionLevel.urgent:
      return URGENT;
    case AttentionLevel.warning:
      return WARNING;
    case AttentionLevel.info:
      return INFO;
    case AttentionLevel.notice:
      return NOTICE;
    default:
      return SUCCESS;
  }
}


const findAncestorByName = (obj: any, name: string): any | undefined => {
  if (obj.name === name) {
    return obj;
  }
  if (!obj.parent) {
    return undefined;
  }
  return findAncestorByName(obj.parent, name);
}


export const getIsDeviceSelected = (
  selection: any[],
  group: MutableRefObject<THREE.Group>,
): boolean => selection
  ?.some(ss => {
    if (ss && group && group.current) {
      const ancestorDevice = findAncestorByName(ss, "device-with-alerts");
      if (!ancestorDevice) return false;
      return group.current.uuid === ancestorDevice.uuid;
    }
    return false;
  });


export const getNotificationStacks = (notifications: UserNotification[]) => notifications
  ?.reduce((acc: UserNotificationStack[], nn, idx) => {
    const match = acc.find(aa => (
      aa.message === nn.message
      && aa.property_area === nn.property_area
      && aa.relates_to === nn.relates_to
      && aa.device_id === nn.device_id
      && aa.entity_id === nn.entity_id
    ));
    if (!match) {
      // don't have this alert stack yet
      acc.push({
        ...nn,
        timestamps: [Number(nn.datetime)],
      } as UserNotificationStack);
    } else {
      if (match.datetime < nn.datetime) {
        // replace found alert with the more recent version of it
        acc.splice(acc.indexOf(match), 1, {
          ...nn,
          timestamps: [
            ...match.timestamps,
            Number(nn.datetime),
          ].sort(),
        });
      }
    }
    return acc;
  }, []);


export const SCENE_SCALE = 0.01;

export const KIOSK_OFFSET = {
  x: 0,
  y: 0,
  z: 0,
};

export const MODEL_ROTATION = {
  x: -Math.PI / 2,
  y: 0,
  z: 0,
};