import { UtilGroup } from "@features/all-utility-groups/types";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  utilityGroup: {} as UtilGroup,
};

export const utilGroupReducer = createSlice({
  name: 'utilityGroup',
  initialState,
  reducers: {
    setUtilityGroup: (state: { utilityGroup: UtilGroup }, action: any) => {
      if (action.payload) {
        state.utilityGroup = action.payload as UtilGroup;
      } else {
        state.utilityGroup = initialState.utilityGroup;
      }
    },
  }
});

export default utilGroupReducer.reducer;

export const {
  setUtilityGroup,
} = utilGroupReducer.actions;