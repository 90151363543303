import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { PropertyManifestEntry } from "@features/home-manifest/types";
import CategoryReadingIcon from "@features/home-status/components/CategoryReadingIcon";
import { ReadingPair } from "@features/home-status/types";
import { ManifestDevice } from "@features/plan-manifest/types";
import { Category, Measure } from "@lib/labels";
import { formatLabel } from "@lib/utils";
import { Stack, Typography } from "@mui/material";
import { useMemo } from "react";


type Props = {
  evseEntry?: PropertyManifestEntry;
}

const TimeInSession = (props: Props) => {
  const { evseEntry } = props;
  const property = useAppSelector((state: RootState) => state.property);

  const devices = useMemo(() => property.devices || [], [property.devices]);

  const durationSensor = useMemo(() => (evseEntry?.device as ManifestDevice)?.sensors
    ?.find(s => s.sensor_category === Category.power && s.measure_name === Measure.duration), [evseEntry?.device]);

  const durationDevice = useMemo(() => devices
    .find(r => r.data.entity_id === evseEntry?.sensor_map?.[durationSensor?.sensor_id ?? durationSensor?.friendly_name ?? '']), [durationSensor?.sensor_id, durationSensor?.friendly_name, devices, evseEntry?.sensor_map]);

  const durationReading = useMemo(() => ({
    device: durationDevice,
    sensor: durationSensor,
    deviceName: formatLabel((evseEntry?.device as ManifestDevice)?.friendly_name
      || (evseEntry?.device as ManifestDevice)?.manufacturer || ''),
    area: formatLabel(evseEntry?.property_area || ''),
    entryId: evseEntry?.manifest_entry_id,
  } as ReadingPair), [durationDevice, durationSensor, evseEntry?.device, evseEntry?.manifest_entry_id, evseEntry?.property_area]);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="h6">Time in Session</Typography>
      <CategoryReadingIcon
        category={Category.power}
        measure={Measure.duration}
        readings={durationReading}
      />
    </Stack>
  );
};

export default TimeInSession;