import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { useUpdateDeviceStateMutation } from '@features/home-status/api';
import { DeviceState, DeviceStateType, ThermostatTemperatureStateType } from '@features/home-status/types';
import { useGetDevicesQuery } from '@features/home/api';
import { ManifestDevice } from "@features/plan-manifest/types";
import { getUnitsByCategoryMeasure } from "@features/unit-settings/utils";
import { convertTemperature, formatCategoryMeasurePair } from "@lib/labels";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { CircularProgress, IconButton } from "@mui/material";
import { useCallback, useMemo, useState } from 'react';
import { THERMOSTAT_CATEGORY, THERMOSTAT_DEFAULT_UNIT, THERMOSTAT_MEASURE, ThermostatAttributes } from '../types';


type Props = {
  entryId: string;
  down?: boolean;
}

const ThermostatTemperatureSetpointAdjust = ({ entryId, down }: Props) => {
  const home = useAppSelector((state: RootState) => state.property);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const userPreferences = useAppSelector((state: RootState) => state.preferences);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const devices = useMemo(() => home.devices || [], [home.devices]);
  const manifestEntries = useMemo(() => home.manifestEntries || [], [home.manifestEntries]);

  const thermostatEntry = useMemo(() => manifestEntries.find(me => me.manifest_entry_id === entryId), [manifestEntries, entryId]);

  const temperatureSetpointSensor = useMemo(() => (thermostatEntry?.device as ManifestDevice).sensors
    .find(s => thermostatEntry?.sensor_map?.[s.sensor_id ?? s.friendly_name]?.split('#')?.[1] === ThermostatAttributes.temperatureSetpoint), [thermostatEntry?.device, thermostatEntry?.sensor_map]);

  const temperatureSetpointDevice = useMemo(() => devices
    .find(r => r.data.entity_id === thermostatEntry?.sensor_map?.[temperatureSetpointSensor?.sensor_id ?? temperatureSetpointSensor?.friendly_name ?? '']), [temperatureSetpointSensor?.sensor_id, temperatureSetpointSensor?.friendly_name, devices, thermostatEntry?.sensor_map]);

  const preferences = useMemo(() => userPreferences.preferences, [userPreferences.preferences]);
  const unitPreferences = useMemo(() => preferences.units || {}, [preferences.units]);
  const preferredUnit = useMemo(() => (
    unitPreferences?.[formatCategoryMeasurePair(THERMOSTAT_CATEGORY, THERMOSTAT_MEASURE)]
    || getUnitsByCategoryMeasure(THERMOSTAT_CATEGORY, THERMOSTAT_MEASURE)[0]
  ), [unitPreferences]);

  const temperatureSetpointValue = useMemo(() => convertTemperature(
    temperatureSetpointDevice?.data.value,
    preferredUnit,
  ), [preferredUnit, temperatureSetpointDevice?.data.value]);

  const entityId = useMemo(() => thermostatEntry?.sensor_map?.[temperatureSetpointSensor?.sensor_id ?? temperatureSetpointSensor?.friendly_name ?? '']?.split('#')?.[0] || '', [temperatureSetpointSensor?.sensor_id, temperatureSetpointSensor?.friendly_name, thermostatEntry?.sensor_map]);

  const [adjusting, setAdjusting] = useState<boolean>(false);

  const [ updateDeviceState ] = useUpdateDeviceStateMutation();

  const {
    refetch,
  } = useGetDevicesQuery({
    tenantId: currentTenant?.tenant_id || '',
    propertyId: home.property_id,
  }, {
    skip: !currentTenant?.tenant_id || !home.property_id,
  });

  const handleAdjustTemperatureSetpoint = useCallback(async () => {
    if (isNaN(Number(temperatureSetpointValue))) return;

    setAdjusting(true);
    const adjustment = down ? -1 : 1;
    const temperature = convertTemperature(temperatureSetpointValue + adjustment, THERMOSTAT_DEFAULT_UNIT);
    await updateDeviceState({
      userTenantId: currentTenant?.tenant_id || '',
      propertyId: home?.property_id || '',
      deviceState: {
        entity_id: entityId,
        state_type: DeviceStateType.THERMOSTAT_TEMPERATURE,
        target_value: {
          temperature,
        } as ThermostatTemperatureStateType,
      } as DeviceState,
    });

    refetch();
    setAdjusting(false);

  }, [currentTenant?.tenant_id, down, entityId, home?.property_id, refetch, temperatureSetpointValue, updateDeviceState]);

  return (
    adjusting
      ? <CircularProgress size={24} />
      : <IconButton onClick={handleAdjustTemperatureSetpoint}>
          {
            down
              ? <RemoveCircleOutlineIcon color="info" />
              : <AddCircleOutlineIcon color="error" />
          }
        </IconButton>
  );
}

export default ThermostatTemperatureSetpointAdjust;