import { Stack, Typography } from "@mui/material";
import ProvisionDevices from "./ProvisionDevices";

const ProvisionDevicesPanel = () => {

  return (
    <Stack alignItems="center">
      <Typography variant="h5">Provision the rest of the devices</Typography>
      <ProvisionDevices />
    </Stack>
  );
}

export default ProvisionDevicesPanel;