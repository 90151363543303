import config from "@lib/config";
import { HTTPMethodType } from '@lib/types';
import { U } from "@lib/utils";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const homeRegApi = createApi({
  reducerPath: 'homeRegApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.REG_API_ENDPOINT,
    prepareHeaders: async (headers) => {
      headers.set('x-api-key', config.REG_API_KEY);
      return headers;
    }
  }),
  endpoints: (builder) => {
    return {
      /** Unregister a property */
      unregister: builder.mutation<void, { propertyId: string, propertyToken: string }>({
        query: ({ propertyId, propertyToken }) => ({
          url: `/unregister/${U(propertyId)}`,
          method: HTTPMethodType.POST,
          params: {
            token: propertyToken,
          },
        })
      }),
    };
  }
});



export const {
  useUnregisterMutation,
 } = homeRegApi;
