import { useAppDispatch, useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { ReactNode, useCallback, useEffect, useMemo } from "react";
import { PreferencesContext, setPreferences } from "../slice";
import { getPreferences, updatePreferences } from "../thunks";
import { Preferences } from "../types";

const PreferencesProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();

  const prefState = useAppSelector((state: RootState) => state.preferences);
  const preferences = useMemo(() => prefState.preferences, [prefState.preferences]);
  const checkingPreferences = useMemo(() => prefState.checkingPreferences, [prefState.checkingPreferences]);

  const getPrefs = useCallback((callback: VoidFunction) => dispatch(getPreferences()).then(callback), [dispatch]);

  const setPrefs = useCallback(async (prefs: Partial<Preferences>, callback: VoidFunction) => {
    await dispatch(updatePreferences(prefs));
    return await getPrefs(callback);
  }, [dispatch, getPrefs]);

  /** Grab preferences from API on load */
  const setup = useCallback(() => {
    dispatch(getPreferences())
      .then((fulfillment) => {
        const current = JSON.stringify(preferences);
        const fetched = JSON.stringify(fulfillment.payload);
        /** dont have preferences yet */
        if (!fetched) {
          // set them in AppSync
          dispatch(updatePreferences(preferences));
        } else if (current !== fetched) {
          // use fetched preferences for app
          setPreferences(fulfillment.payload);
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    setup();
    // only run it first time
  }, [dispatch, setup]);

  if (checkingPreferences) {
    return <>{children}</>
  }

  return (
    <>
    {
      preferences &&
      <PreferencesContext.Provider
        value={{
          getPrefs,
          setPrefs,
          preferences,
        }}
      >
        {children}
      </PreferencesContext.Provider>
    }
    </>
  );

}

export default PreferencesProvider;