import { useAppDispatch, useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import ConfirmDialog from '@features/Common/ConfirmDialog';
import { setHomes } from '@features/all-homes/slice';
import { useUserAccessQuery } from '@features/all-users/api';
import { resetUser } from "@features/aws-user/slice";
import { setLogo } from '@features/builder-branding/slice';
import { resetUserTenant } from '@features/userTenant/slice';
import { Tenant } from '@features/userTenant/types';
import config from '@lib/config';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from '@mui/material';
import { Auth } from 'aws-amplify';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { resetTenant, setAccess, setCurrentTenant } from './slice';
import { pollingInterval } from './types';


const baseUrl = config.ASSETS_BUCKET_URL;

type Props = {
  expanded?: Boolean;
};


const TenantSelector = ({ expanded }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector((state: RootState) => state.awsUser);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const logo = useAppSelector((state: RootState) => state.logo);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const myTenants = useMemo(() => userTenant.myTenants, [userTenant.myTenants]);

  const [anchorEl, setAnchorEl] = useState<Element|null>(null);
  const [confirmSignout, setConfirmSignout] = useState<boolean>(false);


  const handleSignout = useCallback(() => {
    dispatch(resetUser());
    dispatch(resetUserTenant());
    dispatch(resetTenant());
    dispatch(setHomes([]));
    Auth.signOut();
    navigate('/');
  }, [dispatch, navigate]);


  /** set current tenant initially to the first tenant in the list */
  useEffect(() => {
    if (!currentTenant.tenant_id && userTenant?.tenant_id) {
      dispatch(setCurrentTenant(myTenants.find(t => t.tenant_id === userTenant.tenant_id) || myTenants[0]));
    }
  }, [currentTenant.tenant_id, dispatch, myTenants, userTenant?.checkingTenants, userTenant.tenant_id]);


  const handleChangeTenant = useCallback((tenant: Tenant) => {
    if (tenant) {
      dispatch(setCurrentTenant(tenant));
    }
    setAnchorEl(null);
  }, [dispatch]);


  const {
    currentData: userAccess,
  } = useUserAccessQuery({
    userTenantId: userTenant?.tenant_id || '',
    tenantId: currentTenant?.tenant_id || '',
    userId: user.username,
  }, {
    skip: !userTenant?.tenant_id || !currentTenant?.tenant_id || !user.username,
    pollingInterval,
  });

  useEffect(() => {
    if (userAccess) {
      const { roles } = userAccess;
      dispatch(setAccess(roles));
    }
  }, [dispatch, userAccess]);

  /**
   * Find the logo URL.
   * Long term plan is to convert all uploaded logos to pngs or svgs using ImageTracer,
   * but for the time-being, we "guess" the logo's extension.
   */
  const setBuilderLogo = useCallback((id: string|undefined) => {
    // try png first
    fetch(`${baseUrl}/${id}/logo.png`)
      .then(res => {
        if (res.ok) {
          dispatch(setLogo(`${baseUrl}/${id}/logo.png`));
        } else {
          // file not found - fallback to RIoT Logo
          dispatch(setLogo('/logo.svg'));
        }
      })
      .catch(() => {
        // welp let's hope it's a svg file
        fetch(`${baseUrl}/${id}/logo.svg`)
          .then(res => {
            if (res.ok) {
              dispatch(setLogo(`${baseUrl}/${id}/logo.svg`));
            } else {
              // file not found - fallback to RIoT Logo
              dispatch(setLogo('/logo.svg'));
            }
          })
          .catch(() => {
            // eww, they uploaded a jpeg
            fetch(`${baseUrl}/${id}/logo.jpeg`)
              .then(res => {
                if (res.ok) {
                  dispatch(setLogo(`${baseUrl}/${id}/logo.jpeg`));
                } else {
                  // file not found - fallback to RIoT Logo
                  dispatch(setLogo('/logo.svg'));
                }
              })
              .catch(() => {
                // file not found - fallback to RIoT Logo
                dispatch(setLogo('/logo.svg'));
              });
          });
      });
  }, [dispatch]);

  useEffect(() => {
    if (logo.logo !== currentTenant.public_image_key as string) {
      if (!currentTenant.public_image_key) {
        dispatch(setLogo('/logo.svg'));
      } else {
        setBuilderLogo(currentTenant.public_image_key);
      }
    }
  }, [currentTenant.public_image_key, dispatch, logo.logo, setBuilderLogo]);

  return (
    <>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {myTenants.map((tt: Tenant) => (
          <MenuItem
            value={tt?.tenant_id || ''}
            key={tt?.tenant_id || ''}
            onClick={() => handleChangeTenant(tt)}
          >
            {tt.name}
          </MenuItem>
        ))}
        <MenuItem
          key="signout"
          onClick={() => setConfirmSignout(true)}
        >
          Sign out
        </MenuItem>
      </Menu>
      <Tooltip title="Select tenant" arrow placement="right-start">
        <ListItemButton onClick={(e) => setAnchorEl(e.currentTarget)}>
          <ListItemIcon>
            <ManageAccountsIcon />
          </ListItemIcon>
          { expanded && <ListItemText primary="Tenants" /> }
        </ListItemButton>
      </Tooltip>
      <ConfirmDialog title="Confirm Sign Out"
        open={confirmSignout}
        onClose={() => setConfirmSignout(false)}
        onConfirm={() => handleSignout()}
        initialValues={{
          isConfirmed: false,
        }}
      >
        <Typography>
          Are you sure you want to sign out?
        </Typography>
      </ConfirmDialog>
    </>
  );
}



export default TenantSelector;
