import { List, ListItem, ListItemText } from "@mui/material";

type Props = {
  type: 'power';
}

const KioskInstructions = ({ type }: Props) => {
  if (type === 'power')
    return (
      <List sx={{ pl: '2em' }}>
        <ListItem
          key="1"
          disablePadding
        >
          <ListItemText>
            Press and release Power Button.
            If the screen did not turn on, press and hold Power Button for 3+ seconds.
            If the screen still did not turn on, ensure power is flowing to the Kiosk, wait 30min to allow the device to charge and try again.
          </ListItemText>
        </ListItem>

      </List>
    );

  return (<></>);
}

export default KioskInstructions;