import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { ManifestDevice, Sensor } from "@features/plan-manifest/types";
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import Save from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useUpdateDeviceMutation } from "../api";
import SensorEntry from "./SensorEntry";

type Props = {
  device: ManifestDevice;
  onClose: (changes: boolean) => void;
}

// compares two strings ignoring letter case and trailing/starting white space
const equal = (a: string, b: string) => (
  a.trim().toLocaleLowerCase().localeCompare(b.trim().toLocaleLowerCase()) === 0
);


const findSensorByName = (sensors: Sensor[], name: string) => sensors.find(ss => equal(ss.friendly_name, name))


const SensorsModal = ({ device, onClose }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const [loading, setLoading] = useState<boolean>(false);
  const [sensors, setSensors] = useState<Sensor[]>([...device.sensors].sort((a,b) => a.order - b.order));
  const [editing, setEditing] = useState<boolean>(false);

  const [updateDevice] = useUpdateDeviceMutation();

  /** saves list of sensors to the Device definition in cloud */
  const handleSubmit = useCallback(async () => {
    setLoading(true);

    const updatedDevice = await updateDevice({
      userTenantId: currentTenant.tenant_id!,
      deviceId: device.device_id!,
      body: {
        ...device,
        sensors,
      } as Partial<ManifestDevice>,
    });

    const errorDetails = (updatedDevice as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't update sensors:", {
        key: "sensor-error",
        content: (
          <ExpandableSnack
            id="sensor-error"
            message={"Couldn't update sensors:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });

      onClose(false);
      setLoading(false);
    } else {
      enqueueSnackbar("Successfully updated sensors.", {
        variant: "success",
      });
      onClose(true);
      setLoading(false);
    }

  }, [currentTenant.tenant_id, device, enqueueSnackbar, onClose, sensors, updateDevice]);

  /** saves new/edited sensor to local state */
  const handleSave = useCallback((oldSensor: Sensor | null, newSensor: Sensor) => {
    // we changed the name of the existing sensor
    // or we are adding a new sensor:
    if (!oldSensor || (oldSensor && !equal(oldSensor.friendly_name, newSensor.friendly_name))) {
      //check if sensor with the same name already exists:
      if (findSensorByName(sensors, newSensor.friendly_name)) {
        const DETAIL = [
          'Change the Name of the sensor to something different',
          'or delete the sensor with the same name',
          'then try again',
        ].join(', ');

        enqueueSnackbar("Sensor with this name already exists", {
          key: "sensor-name-error",
          content: (
            <ExpandableSnack
              id="sensor-name-error"
              message={"Sensor with this name already exists"}
              variant={SnackType.error}
              detail={DETAIL}
            />),
        });
        return false;
      }
    }

    if (oldSensor) {
      // updating existing sensor
      setSensors([
        ...sensors.filter(ss => !equal(ss.friendly_name, oldSensor.friendly_name)),
        newSensor,
      ].sort((a,b) => a.order - b.order));
    } else {
      // adding a new sensor
      setSensors([
        ...sensors,
        newSensor,
      ].sort((a,b) => a.order - b.order));
    }
    return true;
  }, [enqueueSnackbar, sensors]);

  useEffect(() => {
    setEditing(false);
  }, [sensors]);

  /** deletes sensor from the local state */
  const handleDelete = useCallback((sensor: Sensor) => {
    setSensors(sensors.filter(ss => !equal(ss.friendly_name, sensor.friendly_name)));
  }, [sensors]);

  const tooltipTitle = useMemo(() => {
    if (editing) return 'Please validate or discard your sensor changes before continuing';
    if (loading) return 'Saving is already in progress';
    return 'Save your changes to the device'
  }, [editing, loading]);

  return (
    <Dialog open scroll="paper" fullWidth onClose={() => onClose(false)}>
      <DialogTitle>Sensors included with {device?.friendly_name || 'device'}</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={1} sx={{ mt: 1 }}>
          {
            sensors
              .map((ss, idx) => (
                <SensorEntry
                  key={`sensor-${ss.entity_id}-${idx}`}
                  existingEntry={ss}
                  onSave={(newSensor: Sensor) => handleSave(ss, newSensor)}
                  onDelete={() => handleDelete(ss)}
                  onEdit={() => setEditing(true)}
                />
              ))
          }
          <SensorEntry
            onSave={(newSensor: Sensor) => handleSave(null, newSensor)}
            order={sensors.length}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Tooltip title={tooltipTitle} placement="top" arrow>
          <span>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              loading={loading}
              disabled={editing}
              startIcon={<Save />}
            >
              Save
            </LoadingButton>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
}

export default SensorsModal;