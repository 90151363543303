import { PropertyManifestEntry } from "@features/home-manifest/types";
import { ManifestDevice } from "@features/plan-manifest/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Step, StepLabel, Stepper, Typography } from "@mui/material";
import PairingButton from "../PairingButton";
import { useCallback, useMemo, useState } from "react";
import CodeScanner from "./CodeScanner";
import { label } from "aws-amplify";

type Props = {
  entry: PropertyManifestEntry;
  addSmartstartEntry: (qrCodeString: string) => Promise<void>;
  isOpen: boolean;
  onClose: () => void;
}

const SmartstartDialog = ({
  isOpen,
  onClose,
  entry,
  addSmartstartEntry,
}: Props) => {
  const [qrCodeString, setQrCodeString] = useState<string | null>(null);
  const [confirmedOverwrite, setConfirmedOverwrite] = useState<boolean>(false);
  const [codeSent, setCodeSent] = useState<boolean>(false);

  const handleAddEntry = useCallback(() => {
    if (qrCodeString) {
      addSmartstartEntry(qrCodeString).then(() => {
        setCodeSent(true);
      });
    }
  }, [addSmartstartEntry, qrCodeString]);

  const handleCodeScanned = useCallback((code: unknown) => {
    if (typeof code === 'string') {
      setQrCodeString(code);
    }
  }, []);

  const activeStep = useMemo(() => {
    if (!qrCodeString) return 0;
    if (!codeSent) return 1;
    if (!entry.gateway_device_id) return 2;
    return 3;
  }, [codeSent, entry.gateway_device_id, qrCodeString]);

  const stepHeading = useMemo(() => {
    switch (activeStep) {
      case 0:
      default:
        return 'Scan the QR code';
      case 1:
        return 'Confirm and send code';
      case 2:
        return 'Power on the device';
      case 3:
        return 'Success';
    }
  }, [activeStep]);

  const stepDescription = useMemo(() => {
    switch (activeStep) {
      case 0:
      default:
        return ((entry.pairing_data && !confirmedOverwrite)
          ? 'A code has already been scanned for this device. Please confirm before overwriting.'
          : 'Scan the device\'s QR code to begin provisoning. The code is located either on the device itself or on its packaging.'
        );
      case 1:
        return 'The code has been scanned successfully. Press the button below to transmit its contents to the Gateway.';
      case 2:
        return 'The code has been sent to the gateway. Power on the device, and wait for it to automatically join the network.';
      case 3:
        return 'Pairing is now complete!';
    }
  }, [activeStep, confirmedOverwrite, entry.pairing_data]);

  const handleConfirmOverwrite = useCallback(() => {
    setConfirmedOverwrite(true);
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>Pair {(entry.device as ManifestDevice).friendly_name}</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'stretch', maxWidth: '80vw', maxHeight: '80vh'}}>
        <Stepper activeStep={activeStep} variant="outlined">
          <Step>
            <StepLabel>Scan Code</StepLabel>
          </Step>
          <Step>
            <StepLabel>Send Code to Gateway</StepLabel>
          </Step>
          <Step>
            <StepLabel>Power on Device</StepLabel>
          </Step>
          <Step>
            <StepLabel>Complete</StepLabel>
          </Step>
        </Stepper>
        <Typography variant="h6" textAlign="center" mt={4}>
          {stepHeading}
        </Typography>
        <Typography variant="body1" textAlign="center" m={1}>
          {stepDescription}
        </Typography>
        {
          activeStep < 2
          ? <>
            {
            qrCodeString
            ? <Typography variant="overline" textAlign="justify" m={2} sx={{ lineHeight: 1.15, wordBreak: 'break-all', whiteSpace: 'wrap', textWrap: 'wrap',overflow: 'hidden' }}>
              {qrCodeString}
            </Typography>
            : <>
              {(entry.pairing_data && !confirmedOverwrite)
              ? <Button sx={{ mr: 'auto', ml: 'auto' }} color="warning" variant="contained" onClick={handleConfirmOverwrite}>Yes, overwrite</Button>
              : <CodeScanner onScanned={handleCodeScanned} />
              }
            </>
          }
          </> : null
        }
      </DialogContent>
      <DialogActions>
        {
          activeStep < 2
          ? <Button color="primary" variant="contained" onClick={codeSent ? onClose : handleAddEntry} disabled={!qrCodeString}>
            {codeSent ? 'Close' : 'Send Code'}
          </Button>
          : null
        }
        {
          activeStep === 3
          ? <Button color="primary" onClick={onClose} variant="contained">
            Close
          </Button>
          : <Button color="secondary" onClick={onClose} >
            Cancel
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
}

export default SmartstartDialog;
