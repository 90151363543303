import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { Plan } from "@features/floorplan/types";
import { Autocomplete, TextField } from "@mui/material";
import { useCallback, useState } from "react";


type Props = {
  initial: string[];
  onChange: (plans: string[]) => void;
}

const ChartPlanCompare = ({ initial, onChange }: Props) => {
  const plans = useAppSelector((state: RootState) => state.plans);

  const [selected, setSelected] = useState<Plan[]>(plans.filter(p => initial.includes(p.plan_id as string)));

  const handleChange = useCallback((
    event: any,
    value: Plan[],
  ) => {
    if (value !== null) {
      setSelected(value);
      onChange(value.map(v => v.plan_id as string));
    }
  }, [onChange]);


  return (
    <Autocomplete
      fullWidth
      multiple
      options={plans}
      getOptionLabel={(p: Plan) => p.name || ''}
      onChange={handleChange}
      isOptionEqualToValue={(a, b) => a.plan_id === b.plan_id}
      value={selected}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Floorplans"
          placeholder="Compare floorplans"
          size="small"
        />
      )}
    />
  );
}

export default ChartPlanCompare;
