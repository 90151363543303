import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { ManifestDevice } from "@features/plan-manifest/types";
import { Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { ThermostatAttributes } from "../types";

type Props = {
  entryId: string;
}

const ThermostatCurrentFanMode = ({ entryId }: Props) => {
  const home = useAppSelector((state: RootState) => state.property);

  const devices = useMemo(() => home.devices || [], [home.devices]);
  const manifestEntries = useMemo(() => home.manifestEntries || [], [home.manifestEntries]);
  const thermostatEntry = useMemo(() => manifestEntries.find(me => me.manifest_entry_id === entryId), [manifestEntries, entryId]);

  const currentFanModeSensor = useMemo(() => (thermostatEntry?.device as ManifestDevice).sensors
    .find(s => thermostatEntry?.sensor_map?.[s.sensor_id ?? s.friendly_name]?.split('#')?.[1] === ThermostatAttributes.fanMode), [thermostatEntry?.device, thermostatEntry?.sensor_map]);

  const currentFanModeDevice = useMemo(() => devices
    .find(r => r.data.entity_id === thermostatEntry?.sensor_map?.[currentFanModeSensor?.sensor_id ?? currentFanModeSensor?.friendly_name ?? '']), [currentFanModeSensor?.sensor_id, currentFanModeSensor?.friendly_name, devices, thermostatEntry?.sensor_map]);

  const currentFanModeValue = useMemo(() => currentFanModeDevice?.data.value || '--', [currentFanModeDevice?.data.value]);

  return (
    <Stack alignItems="center">
      <Typography variant="caption">Fan</Typography>
      <Typography variant="body1">{currentFanModeValue.toLocaleString().toLocaleUpperCase()}</Typography>
    </Stack>
  );
}

export default ThermostatCurrentFanMode;