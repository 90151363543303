
import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputAdornment, InputLabel, OutlinedInput, Stack, TextField } from "@mui/material";
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useSnackbar } from "notistack";
import { useCreateGasBillMutation } from "../api";
import { useCallback, useMemo, useState } from "react";
import { GasBill } from "../types";
import moment from "moment";

const NumericInputPattern = '[0-9]*\\\\.?[0-9]*' as const;

type Props = {
  open: boolean;
  onClose: (changes: boolean) => void;
}

const CreateBillDialog = ({ open, onClose }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const property = useAppSelector((state: RootState) => state.property);
  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const [loading, setLoading] = useState<boolean>(false);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [costPerTherm, setCostPerTherm] = useState<string>('');
  const [thermsUsed, setThermsUsed] = useState<string>('');

  const [createBill] = useCreateGasBillMutation();

  const handleSubmit = useCallback(() => {
    if (!startDate || !endDate || Number.isNaN(thermsUsed) || Number.isNaN(costPerTherm)) return;

    setLoading(true);

    createBill({
      propertyId: property.property_id,
      userTenantId: currentTenant.tenant_id,
      gasBill: {
        billing_cycle: {
          start: moment(startDate).format('YYYY-MM-DD'),
          end: moment(endDate).format('YYYY-MM-DD'),
        } as GasBill['billing_cycle'],
        property_id: property.property_id,
        therm_cost: Number(costPerTherm),
        therms_used: Number(thermsUsed),
      }
    }).then((newBill) => {
      if ('error' in newBill) {
        enqueueSnackbar("Couldn't create bill:", {
          key: "create-bill-error",
          content: (
            <ExpandableSnack
              id="create-bill-error"
              message={"Couldn't create bill:"}
              variant={SnackType.error}
              detail={getErrorMessage(newBill.error)}
            />),
        });
      } else {
        enqueueSnackbar("Created new bill.", {
          variant: "success"
        });
  
        onClose(true);
      }
    }).finally(() => setLoading(false))
  }, [costPerTherm, createBill, currentTenant.tenant_id, endDate, enqueueSnackbar, onClose, property.property_id, startDate, thermsUsed]);

  return (
    <Dialog open={open} scroll="paper" fullWidth onClose={onClose}>
      <DialogTitle>Create new bill record</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Stack direction="column">
            <Stack direction="row" justifyContent="space-evenly" alignContent="stretch" p={1} spacing={1}>
              <DatePicker
                label="Bill start date"
                value={startDate}
                onChange={(newStartDate) => setStartDate(newStartDate)}
                renderInput={(params) => <TextField required fullWidth variant="outlined" {...params} />}
              />
              <DatePicker
                label="Bill end date"
                value={endDate}
                onChange={(newEndDate) => setEndDate(newEndDate)}
                renderInput={(params) => <TextField required fullWidth variant="outlined" {...params} />}
              />
            </Stack>
            <Stack direction="row" justifyContent="space-evenly" alignContent="stretch" p={1} spacing={1}>
              <TextField
                variant="outlined"
                label="Total therms used"
                type="number"
                fullWidth
                inputProps={{ min: 0, step: 0.1, inputMode: 'numeric', pattern: NumericInputPattern }}
                required
                value={thermsUsed}
                onChange={(e) => setThermsUsed(e.target.value)}
              />
              <FormControl fullWidth required>
                <InputLabel>Cost per therm</InputLabel>
                <OutlinedInput
                  label="Cost per therm"
                  type="number"
                  inputProps={{ min: 0, step: 0.01, inputMode: 'numeric', pattern: NumericInputPattern }}
                  required
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  value={costPerTherm}
                  onChange={(e) => setCostPerTherm(e.target.value)}
                />
              </FormControl>
              
            </Stack>
          </Stack>

        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          loading={loading}
          disabled={!startDate || !endDate}
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default CreateBillDialog;
