import { AnalyticsType } from '@features/settings/types';
import { Box } from '@mui/material';
import HomeChart from './HomeChart';

/** Performance Chart for a single Property */
const PerformanceChart = () => {
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <HomeChart ctype={AnalyticsType.performance} />
    </Box>
  );
}

export default PerformanceChart;
