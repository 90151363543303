import { Device } from "@features/home/types";
import { Box, List } from "@mui/material";
import UnpairedDevice from "./UnpairedDevice";

type Props = {
  devices: Device[];
  onSelect: (device: Device) => void;
}

/* Displays a list of unpaired devices */
const UnpairedDeviceList = ({ devices, onSelect }: Props) => (
  <Box
    component="div"
    flexGrow={1}
    sx={{
      overflowX: 'hidden',
      overflowY: 'auto',
      textOverflow: 'ellpisis',
    }}
  >
    <List sx={{ height: '100%' }}>
      {
        devices.map((dd: Device, idx) => (
          <UnpairedDevice
            key={`unpaired-dd-${dd.data.device_id}-${dd.data.entity_id}-${idx}`}
            device={dd}
            onClick={() => onSelect(dd)}
          />
        ))
      }
    </List>
  </Box>
);

export default UnpairedDeviceList;