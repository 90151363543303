import Header from "@features/Common/Header";
import PendingHomeList from "@features/home-sale/components/PendingHomes";
import { FC } from "react";

/** Wrapper for /homes */
const PendingHomes: FC = () => {
  return (
    <>
      <Header />
      <PendingHomeList />
    </>
  );
}

export default PendingHomes;