import { User } from "@features/all-users/types";
import { Address, Property } from "@features/home/types";
import { Category, Measure, Unit, ValueType } from "@lib/labels";

export type Permission = {
  readonly name: string;
  readonly friendly_name: string;
  readonly category: string;
  readonly description: string;
  readonly scope: string;
  readonly type: string;
  readonly permissions: {
    id: string;
    enabled: boolean;
  }[];
};

export type UserPermissions = Record<string, boolean>;


export enum TenantType {
  Vendor = 'Vendor',
  Homeowner = 'Homeowner',
  Builder = 'Builder',
  System = 'System',
  PropertyManager = 'PropertyManager',
}

interface TenantBase {
  name?: string;
  description?: string;
  tenant_type?: TenantType;
  physical_address?: Address;
  website?: string;
  phone_number: string;
  child_tenants?: string[];
  allow_builder_access?: boolean;
  builder_color?: string;
  public_image_key?: string;
}

export interface TenantCreate extends TenantBase {
  name: string;
  description: string;
  property_ids: string[];
  tenant_type: TenantType;
  email: string;
}

export interface TenantPropertyChange {
  add_properties?: string[];
  remove_properties?: string[];
}

export interface TenantUpdate extends TenantBase {
  allow_builder_access?: boolean;
  property_ids?: TenantPropertyChange;
}

export interface Tenant extends TenantCreate {
  tenant_id: string;
  created: string | number;
  updated: string | number;
  is_deleted: boolean;
}


export type Sensor = {
  friendly_name: string;
  description?: string;
  entity_id?: string;
  units: Unit;
  sensor_category: Category;
  value_type: ValueType;
  measure_name: Measure;
  order: number;
}

type EntityBase = {
  readonly created: string | number;
  readonly updated: string | number;
}

export type Device = {
  manufacturer: string;
  part_number: string;
  model_number: string;
  discontinued: boolean;
  description?: string;
  friendly_name: string;
  sensors: Sensor[];
  device_id: string;
  is_deleted: string;
} & EntityBase;

export interface TenantState extends Tenant {
  checkingTenant: boolean;
  checkingTenants: boolean;
  properties?: Property[];
  userPermissions: UserPermissions;
  myTenants: Tenant[];
  users: User[];
}

export type GetTenantFunction = (callback: VoidFunction) => void;

export interface TenantContextType {
  gotTenant: boolean;
  setGotTenant: Function;
  getTenant: GetTenantFunction;
  tenant: Tenant;
}
