import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPrefs, setPrefs } from "./api";
import { Preferences } from './types';

enum ThunkNames {
  get = 'preferences/get',
  set = 'preferences/set',
}

export const getPreferences = createAsyncThunk<Preferences|undefined>(
  ThunkNames.get,
  async (): Promise<Preferences|undefined> => {
    const preferences: Preferences|undefined = await getPrefs();
    return preferences;
  }
);

export const updatePreferences = createAsyncThunk<Preferences|undefined, Partial<Preferences>>(
  ThunkNames.set,
  async (body: Partial<Preferences>) => {
    const prefs = await setPrefs(body);
    return prefs;
  }
)