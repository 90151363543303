import { createSlice } from "@reduxjs/toolkit";
import { Role, User } from "./types";

interface UsersSliceState {
  users: User[];
  roles: Role[];
}

const initialState: UsersSliceState = {
  users: [],
  roles: [],
};

/** State slice for current user's tenant */
export const usersReducer = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setMyUsers: (state: UsersSliceState, action: any) => {
      if (action.payload) {
        state.users.splice(0, state.users.length);
        action.payload.forEach((cc: User) => state.users.push(cc));
      }
    },
    setRoles: (state: UsersSliceState, action: any) => {
      if (action.payload) {
        state.roles.splice(0, state.roles.length);
        action.payload.forEach((cc: Role) => state.roles.push(cc));
      }
    }
  }
});

export default usersReducer.reducer;

export const {
  setMyUsers,
  setRoles,
} = usersReducer.actions;