import { Checkbox, FormControlLabel, FormGroup, Stack, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { ChangeEvent } from "react";
import { CommissioningForm } from "../types";
import KioskInstructions from "./KioskInstructions";

const CommissionKiosk = () => {
  const f = useFormikContext();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    f.setFieldValue(name, event.target.checked);
  }

  const { kioskLocated, kioskPowered, kioskOn } = f.values as CommissioningForm;

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={kioskLocated}
            onChange={handleChange}
            name="kioskLocated"
          />
        }
        label={
          <Typography variant="h6">
            1. Locate the Kiosk device within the home.
          </Typography>
        }
      />
      <FormControlLabel
        sx={{
          alignItems: 'start',
        }}
        control={
          <Checkbox
            checked={kioskPowered}
            onChange={handleChange}
            name="kioskPowered"
            disabled={!kioskLocated}
          />
        }
        label={
          <Stack>
            <Typography variant="h6">
              2. Ensure the Kiosk is connected to power outlet via USB cable.
            </Typography>
          </Stack>
        }
      />
      <FormControlLabel
        sx={{
          alignItems: 'start',
        }}
        control={
          <Checkbox
            checked={kioskOn}
            onChange={handleChange}
            name="kioskOn"
            disabled={!kioskLocated || !kioskPowered}
          />
        }
        label={
          <Stack>
            <Typography variant="h6">
              3. Ensure the Kiosk is turned on and displays the login screen.
            </Typography>
            <KioskInstructions type="power" />
          </Stack>
        }
      />
    </FormGroup>
  );
}

export default CommissionKiosk;