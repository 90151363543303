import { Category, Measure } from "@lib/labels";
import AddIcon from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import { Checkbox, Divider, FormControlLabel, IconButton, Stack, TextField } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DeviceStateType, SceneTemplate, SceneTemplateAction, SceneTemplateActionType, SceneTemplateDeviceStateAction, SceneTemplateMultipleAction, SceneTemplateUpdate } from "../types";
import SceneTemplateDeviceCriteriaSelector from "./SceneTemplateDeviceCriteriaSelector";
import SceneTemplateDeviceStateSelector from "./SceneTemplateDeviceStateSelector";

interface SceneTemplateConfigurationEditorProps {
  sceneTemplate: SceneTemplate;
  onChange: (newAction: SceneTemplateUpdate) => void;
}

export default function SceneTemplateConfigurationEditor(props: SceneTemplateConfigurationEditorProps) {
  const { sceneTemplate, onChange } = props;

  const handleChange = useCallback((changes: SceneTemplateUpdate) => {
    onChange(changes);
  }, [onChange]);

  const handleEnableSceneTemplate = useCallback((enabled: boolean) => {
    handleChange({
      enabled,
    })
  }, [handleChange]);

  const handleEnableCooldown = useCallback((enabled: boolean) => {
    handleChange({
      cooldown_time: enabled ? 30000 : null,
    })
  }, [handleChange]);

  const handleChangeCooldown = useCallback((cooldownTime: number) => {
    handleChange({
      cooldown_time: cooldownTime,
    })
  }, [handleChange]);

  return (
    <Stack direction="column" sx={{ flex: '1 1 0' }} spacing={2}>
      <Stack direction="row">
        <FormControlLabel
          sx={{ flex: '1 1 0' }}
          control={
            <Checkbox
              checked={sceneTemplate.enabled}
              onChange={(e, v) => handleEnableSceneTemplate(v)}
            />
          }
          label="Scene is enabled"
        />
      </Stack>
      <Stack direction="row">
        <FormControlLabel
          sx={{ flex: '1 1 0' }}
          control={
            <Checkbox
              checked={typeof sceneTemplate.cooldown_time === 'number'}
              onChange={(e, v) => handleEnableCooldown(v)}
            />
          }
          label="Enable scene cooldown time"
        />
        {
          typeof sceneTemplate.cooldown_time === 'number'
          ? <TextField
              label="Cooldown time (seconds)"
              type="number"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]+', step: 0.001, min: 1 }}
              value={sceneTemplate.cooldown_time / 1000}
              onChange={(e) => handleChangeCooldown(Number(e.target.value) * 1000)}
            />
          : null
        }
      </Stack>
    </Stack>
  );
}
