import { renderLite } from "@features/Common/tables";
import { Property } from "@features/home/types";
import { TenantType } from "@features/userTenant/types";
import { U } from "@lib/utils";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import { MUIDataTableColumn } from "mui-datatables";
import { NavigateFunction } from "react-router-dom";
import { SUCCESS, WARNING } from "theme";
import HomeStatusColumn from "./HomeStatusColumn";
import NotificationCountsColumn from "./NotificationCountsColumn";

export const getColumns = (
  data: Property[],
  tenantType: TenantType,
  navigateFn: NavigateFunction,
): MUIDataTableColumn[] =>  {
  const columns = [
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'physical_address.address_line_1',
      label: 'Street',
      options: {
        filter: false,
      }
    },
    {
      name: 'physical_address.city',
      label: 'City',
    },
    {
      name: 'physical_address.state',
      label: 'State',
    },
    {
      name: 'physical_address.zip_postal_code',
      label: 'ZIP',
    },
    {
      name: 'status',
      label: 'Health',
      options: {
        filter: false,
        download: false,
        searchable: false,
        sort: false,
        customBodyRenderLite: renderLite(
          data as Property[],
          (row: Property) => <HomeStatusColumn home={row} />),
      }
    },
    {
      name: 'notifications',
      label: 'Notifications',
      options: {
        // Disable all the features
        filter: false,
        download: false,
        searchable: false,
        sort: false,
        // Keep the column small
        customBodyRenderLite: renderLite(
          data as Property[],
          (row: Property) => <NotificationCountsColumn home={row} />
        ),
      },
    },
  ];

  if (tenantType === TenantType.System) {
    columns.push({
      name: 'case_count',
      label: 'Support Cases',
      options: {
        // Disable all the features
        filter: false,
        download: false,
        searchable: false,
        sort: false,
        // Keep the column small
        customBodyRenderLite: renderLite(
          data as Property[],
          (row: Property) => (
            <Tooltip arrow placement="top" title="Unresolved support cases">
              <IconButton onClick={() => navigateFn(`/homes/${U(row.property_id)}/support`)}>
                <Avatar sx={{
                  width: 18,
                  height: 18,
                  bgcolor: Number(row.case_count) > 0 ? WARNING : SUCCESS,
                  fontSize: '0.8rem',
                }}>{row.case_count}</Avatar>
              </IconButton>
            </Tooltip>
          )
        ),
      },
    });
  }
  return columns;
}
