import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { AnalyticsType } from "@features/settings/types";
import { ALL_CATEGORIES, Category, findCategoryLabel } from '@lib/labels';
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useCallback, useMemo } from 'react';
import { RIOT_BLUE } from 'theme';

type Props = {
  chart: AnalyticsType;
  initial: Category;
  onChange: (cat: Category) => void;
}

const ChartCategories = ({ chart, initial, onChange }: Props) => {
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant?.builder_color || RIOT_BLUE, [currentTenant?.builder_color]);

  const categoryOptions = useMemo(() => {
    switch (chart) {
      default:
      case AnalyticsType.performance:
        return ALL_CATEGORIES;
      case AnalyticsType.usage:
        return [
          Category.power,
          Category.water,
        ];
    }
  }, [chart]);

  const handleChange = useCallback((e: SelectChangeEvent<Category>) => {
    onChange(e.target.value as Category);
  }, [onChange]);

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <Select
        value={initial}
        onChange={handleChange}
        sx={{
          '&:focus': {
            border: `1px solid ${themeColor}`,
          }
        }}
      >
        {
          categoryOptions.map((oo, idx) => (
            <MenuItem value={oo} key={`cat-option-${idx}`}>
              {findCategoryLabel(oo)}
            </MenuItem>))
        }
      </Select>
    </FormControl>
  );
}

export default ChartCategories;