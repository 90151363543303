import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { Stack, Typography } from '@mui/material';


const Header = () => {
  const logo = useAppSelector((state: RootState) => state.logo.logo);

  return (
    <Stack
      sx={{
        position: 'absolute',
        zIndex: -2,
        right: '2em',
        top: '2em',
      }}
    >
      {
        logo &&
        <>
          <img
            id="logo"
            src={logo}
            height={100}
            width="auto"
            alt="logo"
          />
          {
            !logo.startsWith('/') &&
            <Typography sx={{ fontSize: '8px' }}>
              Powered by RIoT-Technology
            </Typography>
          }

        </>
      }
    </Stack>
  );
};

export default Header;
