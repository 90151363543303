import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import config from "@lib/config";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Card, CardContent, CardHeader, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";

const dashboardUrl = `${config.DASHBOARD_URL}signin`;


const BrandedLogin = () => {

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const logoId = useMemo(() => currentTenant.public_image_key || '', [currentTenant.public_image_key]);
  const logoUrl = useMemo(() => !logoId ? dashboardUrl : `${dashboardUrl}?id=${logoId}`, [logoId]);

  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(logoUrl)
      .then(() => {
        setCopySuccess(true);
        const timeout = setTimeout(() => {
          setCopySuccess(false);
          clearTimeout(timeout);
        }, 800);
      });
  }, [logoUrl]);

  return (
    <Card sx={{
      width: '40em',
      height: '14em',
    }}>
      <CardHeader title="Branded sign in" />
      <CardContent>
        <Typography variant="h6">
          Give URL below to your users to use your logo in the Dashboard Sign In:
        </Typography>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography>
            {logoUrl}
          </Typography>
          <Tooltip
            placement="top"
            arrow
            title={copySuccess ? 'Copied!' : "Copy to clipboard"}
          >
            <span>
              <IconButton onClick={handleCopyToClipboard}>
                <ContentCopyIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default BrandedLogin;