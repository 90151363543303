import { ServicePower } from "@features/all-utility-groups/types";
import { findUnitsLabel, Unit } from "@lib/labels";
import { Typography } from "@mui/material";
import { useMemo } from "react";

type MaterialVariant = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "button" | "overline" | "inherit" | undefined;

type Props = {
  energy: ServicePower,
  variant: MaterialVariant,
}

const EnergyReading = ({ energy, variant }: Props) => {

  const kwt = useMemo(() => (energy?.watts || 0)/1000, [energy?.watts]);
  const amps = useMemo(() => (energy?.amps || 0), [energy?.amps]);

  if (!kwt || !amps) return (
    <Typography variant={variant} fontWeight={700}>None</Typography>
  );

  return (
    <Typography variant={variant} fontWeight={700}>
      {kwt}{findUnitsLabel(Unit.kw)} ({amps}{findUnitsLabel(Unit.amps)})
    </Typography>
  );
}

export default EnergyReading;