import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { useUpdateDeviceStateMutation } from '@features/home-status/api';
import { DeviceState, DeviceStateType, HvacMode, ThermostatModeStateType } from "@features/home-status/types";
import { useGetDevicesQuery } from '@features/home/api';
import { ManifestDevice } from "@features/plan-manifest/types";
import { LoadingButton } from "@mui/lab";
import { Menu, MenuItem } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { RIOT_BLUE } from "theme";
import { DIALOG_Z_INDEX, ThermostatAttributes } from "../types";


type Props = {
  entryId: string;
}

const ThermostatHvacModeMenu = ({ entryId }: Props ) => {
  const home = useAppSelector((state: RootState) => state.property);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant?.builder_color || RIOT_BLUE, [currentTenant?.builder_color]);
  const manifestEntries = useMemo(() => home.manifestEntries || [], [home.manifestEntries]);
  const thermostatEntry = useMemo(() => manifestEntries.find(me => me.manifest_entry_id === entryId), [manifestEntries, entryId]);

  const currentHvacModeSensor = useMemo(() => (thermostatEntry?.device as ManifestDevice).sensors
    .find(s => !thermostatEntry?.sensor_map?.[s.sensor_id ?? s.friendly_name]?.includes('#')), [thermostatEntry?.device, thermostatEntry?.sensor_map]);

  const hvacModeEntityId = useMemo(() => thermostatEntry?.sensor_map?.[currentHvacModeSensor?.friendly_name || '']?.split('#')?.[0] || '', [currentHvacModeSensor?.friendly_name, thermostatEntry?.sensor_map]);

  const devices = useMemo(() => home.devices || [], [home.devices]);
  const hvacModesSensor = useMemo(() => (thermostatEntry?.device as ManifestDevice).sensors
    .find(s => thermostatEntry?.sensor_map?.[s.sensor_id ?? s.friendly_name]?.split('#')?.[1] === ThermostatAttributes.hvacModes), [thermostatEntry?.device, thermostatEntry?.sensor_map]);
  const hvacModesDevice = useMemo(() => devices
    .find(r => r.data.entity_id === thermostatEntry?.sensor_map?.[hvacModesSensor?.sensor_id ?? hvacModesSensor?.friendly_name ?? '']), [devices, hvacModesSensor?.sensor_id, hvacModesSensor?.friendly_name, thermostatEntry?.sensor_map]);
  const hvacModes = useMemo(() => String(hvacModesDevice?.data.value || '')
    .split('$').filter((hm): hm is HvacMode => Object.values(HvacMode).includes(hm as any)), [hvacModesDevice?.data.value]);

  const [saving, setSaving] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const [ updateDeviceState ] = useUpdateDeviceStateMutation();

  const {
    refetch,
  } = useGetDevicesQuery({
    tenantId: currentTenant?.tenant_id || '',
    propertyId: home.property_id,
  }, {
    skip: !currentTenant?.tenant_id || !home.property_id,
  });

  const handleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleChangeHvacMode = useCallback(async (value: HvacMode) => {
    setSaving(true);
    setAnchorEl(null);

    await updateDeviceState({
      userTenantId: currentTenant?.tenant_id || '',
      propertyId: home?.property_id || '',
      deviceState: {
        entity_id: hvacModeEntityId,
        state_type: DeviceStateType.THERMOSTAT_MODE,
        target_value: {
          hvacMode: value,
        } as ThermostatModeStateType,
      } as DeviceState,
    });

    refetch();
    setSaving(false);
  }, [currentTenant?.tenant_id, hvacModeEntityId, home?.property_id, refetch, updateDeviceState]);

  if (hvacModes.length < 1) return <></>;

  return <>
    <LoadingButton
      variant="text"
      onClick={handleOpen}
      id="hvac-mode-button"
      loading={saving}
    >
      Mode
    </LoadingButton>
    <Menu
      aria-labelledby="hvac-mode-button"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{
        zIndex: DIALOG_Z_INDEX+100,
        '& .MuiMenu-list': {
          padding: '4px 0',
          color: themeColor,
        },
      }}
    >
      {
        hvacModes.map((hm, idx) => (
          <MenuItem
            key={`hvac-option-${hm}-${idx}`}
            onClick={() => handleChangeHvacMode(hm)}
            sx={{ textTransform: 'capitalize' }}
          >{hm}</MenuItem>
        ))
      }
    </Menu>
  </>;
}

export default ThermostatHvacModeMenu;