import { useAppDispatch, useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { pollingInterval } from '@features/tenant-selector/types';
import React, { FC, useEffect } from 'react';
import {
  useMyPermissionsQuery,
  useMyTenantsQuery,
  useMyUserTenantQuery
} from './api';
import {
  setMyTenants,
  setMyUserTenant,
  setUserPermissions
} from './slice';

/** Wrapper for Tenant related data */
const UserTenantProvider: FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const awsUser = useAppSelector((store: RootState) => store.awsUser);

  const {
    currentData: tenant,
  } = useMyUserTenantQuery(undefined, {
    skip: !awsUser.idToken,
  });

  const {
    currentData: myTenants,
  } = useMyTenantsQuery(undefined, {
    pollingInterval,
    skip: !awsUser.idToken,
  });

  const {
    currentData: permissions,
  } = useMyPermissionsQuery({
    userTenantId: tenant?.tenant_id || '',
  }, {
    skip: !awsUser.idToken || !tenant?.tenant_id,
    pollingInterval,
  });


  useEffect(() => {
    if (tenant?.tenant_id?.length) {
      dispatch(setMyUserTenant(tenant));
    }
  }, [dispatch, tenant]);

  useEffect(() => {
    if (myTenants) dispatch(setMyTenants(myTenants));
  }, [dispatch, myTenants]);

  useEffect(() => {
    if (permissions) dispatch(setUserPermissions(permissions));
  }, [dispatch, permissions]);

  return children as React.ReactElement;
}


export default UserTenantProvider;