import { PropertyManifestEntry } from "@features/home-manifest/types";
import { Stack, Typography } from "@mui/material";


type Props = {
  evseEntry?: PropertyManifestEntry;
}

const MaxPowerOutput = (props: Props) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="h6">Max Power Output</Typography>
      <Typography variant="caption">10.0 kW</Typography>
    </Stack>
  );
}

export default MaxPowerOutput;