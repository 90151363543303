import Header from "@features/Common/Header";
import SceneTemplateList from "@features/scene-templates/components/SceneTemplateList";
import SceneTemplatesProvider from "@features/scene-templates/components/SceneTemplatesProvider";
import { FC } from "react";


const AllSceneTemplates: FC = () => {
  return (
    <SceneTemplatesProvider>
      <Header />
      <SceneTemplateList />
    </SceneTemplatesProvider>
  );
}

export default AllSceneTemplates;
