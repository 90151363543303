import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { Autocomplete, TextField } from "@mui/material";
import { useCallback } from "react";


type Props = {
  onChange: (zip: string[]) => void;
  initial: string[];
  placeholder?: string;
}

const ChartZipCompare = ({ initial, onChange, placeholder }: Props) => {
  const zipCodes = useAppSelector((state: RootState) => state.zipCodes);


  const handleChange = useCallback((e: any, value: string[]) => {
    onChange(value || []);
  }, [onChange]);

  return (
    <Autocomplete
      fullWidth
      multiple
      options={zipCodes}
      onChange={handleChange}
      value={Array.isArray(initial) ? initial : []}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Zip Codes"
          placeholder={placeholder || 'Compare to a zip average'}
          size="small"
        />
      )}
    />
  );
}

export default ChartZipCompare;
