import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { PropertyManifestEntry } from "@features/home-manifest/types";
import { useGetPropertyManifestQuery } from "@features/home/api";
import { ManifestDevice } from "@features/plan-manifest/types";
import { Category } from "@lib/labels";
import { formatDate } from "@lib/utils";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Alert, Divider, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Stack, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";

const Review = () => {
  const property = useAppSelector((state: RootState) => state.property);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const selectedTenant = useAppSelector((state: RootState) => state.tenant);

  const manifestEntries = useMemo(() => [
    ...property.manifestEntries || [],
  ].sort((a, b) => {
    if (!Boolean(a.provisioned)) return -1;
    if (!Boolean(b.provisioned)) return 1;
    return Number(b.provisioned || 0) - Number(a.provisioned || 0);

  }), [property.manifestEntries]);
  const currentTenant = useMemo(() => selectedTenant.currentTenant || userTenant, [selectedTenant.currentTenant, userTenant]);

  const {
    refetch: refetchManifest,
  } = useGetPropertyManifestQuery({
    tenantId: currentTenant?.tenant_id || '',
    propertyId: property?.property_id || '',
    includeEntries: true,
  }, {
    skip: !currentTenant.tenant_id || !property?.property_id,
  });

  /* Refetch manifest on mount */
  useEffect(() => {
    refetchManifest();
  }, [refetchManifest]);

  /* Formats provisioned timestamp*/
  const getProvisionDateLabel = (entry: PropertyManifestEntry) => {
    if (entry.provisioned) {
      return formatDate(entry.provisioned).fromNow();
    }
    return '';
  }

  /** Displays entry label with area and provisioned timestamp */
  const getDeviceLabel = (entry: PropertyManifestEntry) => {
    const device = entry.device as ManifestDevice;
    const name = device.friendly_name || device?.description || '';
    const area = entry.property_area;
    return `${name} in ${area}`;
  }

  const unpairedDevices = useMemo(() => manifestEntries
    .filter((e => !(e.device as ManifestDevice)?.sensors
      ?.some(s => s.sensor_category === Category.system)))
    .filter((e => !e.provisioned)) || [], [manifestEntries]);

  const unpairedMessage = useMemo(() => [
    'There are',
    unpairedDevices?.length,
    'unpaired devices.',
    'Return to the previous step to continue pairing devices.',
  ].join(' '), [unpairedDevices?.length]);

  return (
    <Stack spacing={2} sx={{ width: '100%'}}>
      {
        unpairedDevices?.length === 0 &&
        <Alert severity="success">
          All devices are paired.
        </Alert>
      }
      {
        unpairedDevices?.length > 0 &&
        <Alert severity="error">
          {unpairedMessage}
        </Alert>
      }
      <Stack  sx={{ height: '32em', overflowY: 'scroll', padding: 1, width: 'inherit' }}>
        <List subheader={
          <ListSubheader sx={{ top: -10 }}>
            <ListItemText disableTypography>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h6">Device</Typography>
                <Typography variant="h6">Provisioned</Typography>
              </Stack>
            </ListItemText>
          </ListSubheader>
        }>
          <Divider />
          {
            manifestEntries
              .filter((e => !(e.device as ManifestDevice)?.sensors
                ?.some(s => s.sensor_category === Category.system)))
              .map((entry) => (
                <ListItem>
                  <ListItemIcon>
                    {
                      Boolean(entry.provisioned)
                      ? <CheckBoxIcon color="success" />
                      : <CheckBoxOutlineBlankIcon color="primary" />
                    }
                  </ListItemIcon>
                  <ListItemText disableTypography>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography>{getDeviceLabel(entry)}</Typography>
                      <Typography>{getProvisionDateLabel(entry)}</Typography>
                    </Stack>
                  </ListItemText>
                </ListItem>
              ))
          }
        </List>
      </Stack>
    </Stack>
  );
}

export default Review;