import { homeSupportCasesApi } from "@features/home-support-cases/api";
import { HTTPMethodType } from "@lib/types";
import { U } from "@lib/utils";
import { SupportCase } from "./types";

export const supportCaseApi = homeSupportCasesApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      supportCase: builder.query<SupportCase, {
        userTenantId: string,
        propertyId: string,
        caseId: string,
        isDeleted?: boolean,
      }>({
        query: ({ userTenantId, propertyId, caseId, isDeleted = false }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/support/${U(caseId)}`,
          params: {
            is_deleted: isDeleted,
          }
        })
      }),
      addNote: builder.mutation<void, {
        userTenantId: string,
        propertyId: string,
        caseId: string,
        note: string,
      }>({
        query: ({ userTenantId, propertyId, caseId, note }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/support/${U(caseId)}/note`,
          method: HTTPMethodType.PATCH,
          params: {
            note,
          }
        }),
      }),
      addManifestEntry: builder.mutation<void, {
        userTenantId: string,
        propertyId: string,
        caseId: string,
        entryId: string,
      }>({
        query: ({ userTenantId, propertyId, caseId, entryId }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/support/${U(caseId)}/manifest`,
          method: HTTPMethodType.PATCH,
          params: {
            entry_id: entryId,
          },
        }),
      }),
      removeManifestEntry: builder.mutation<void, {
        userTenantId: string,
        propertyId: string,
        caseId: string,
        entryId: string,
      }>({
        query: ({ userTenantId, propertyId, caseId, entryId }) => ({
          url: `tenant/${U(userTenantId)}/properties/${U(propertyId)}/support/${U(caseId)}/manifest`,
          method: HTTPMethodType.DELETE,
          params: {
            entry_id: entryId,
          },
        })
      })
    };
  },
});

export const {
  useCasesQuery,
  useCreateCaseMutation,
  useDeleteCaseMutation,
  useResolveCaseMutation,
  useUpdateCaseMutation,
  useSupportCaseQuery,
  useAddNoteMutation,
  useAddManifestEntryMutation,
  useRemoveManifestEntryMutation,
} = supportCaseApi;