/**
 * Utilities for labelling data fields
 */
import { ReadingDetails } from '@features/home-status/types';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import { default as BatteryCharging60, default as BatteryCharging60Icon } from '@mui/icons-material/BatteryCharging60';
import BatteryUnknownIcon from '@mui/icons-material/BatteryUnknown';
import BoltIcon from '@mui/icons-material/Bolt';
import BoyIcon from '@mui/icons-material/Boy';
import Brightness6Icon from '@mui/icons-material/Brightness6';
import BuildIcon from '@mui/icons-material/Build';
import CableIcon from '@mui/icons-material/Cable';
import Co2Icon from '@mui/icons-material/Co2';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import GasMeterIcon from '@mui/icons-material/GasMeter';
import HearingIcon from '@mui/icons-material/Hearing';
import HotTubIcon from '@mui/icons-material/HotTub';
import HvacIcon from '@mui/icons-material/Hvac';
import LensBlurIcon from '@mui/icons-material/LensBlur';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import OpacityIcon from '@mui/icons-material/Opacity';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import SensorDoorIcon from '@mui/icons-material/SensorDoor';
import SpeedIcon from '@mui/icons-material/Speed';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import ThermostatAutoIcon from '@mui/icons-material/ThermostatAuto';
import WavesIcon from '@mui/icons-material/Waves';

export enum Category {
  access = 'category.access',
  air = 'category.air',
  light = 'category.light',
  power = 'category.power',
  soil = 'category.soil',
  water = 'category.water',
  system = 'category.system',
  cable = 'category.cable',
}


/** All known categories */
export const ALL_CATEGORIES = [
  ...Array.from(Object.values(Category)),
];


/** Find the label for a  Device Category */
export const findCategoryLabel = (category: Category | string) => {
  const locale = window.navigator.language;
  const lowercaseValue = (category as string)?.split('.')?.[1]?.toLocaleLowerCase(locale);
  if (!lowercaseValue?.length) return 'unknown category';
  return lowercaseValue[0].toLocaleUpperCase(locale) + lowercaseValue.slice(1);
}


/** Finds category by given label */
export const findCategoryByLabel = (label: string): Category => {
  const locale = window.navigator.language;
  const lowercaseLabel = label.toLocaleLowerCase(locale);
  return ('category.' + lowercaseLabel) as Category;
}


export enum Measure {
  airpressure = 'measure.airpressure',
  co2 = 'measure.co2',
  closed = 'measure.closed',
  duration = 'measure.duration',
  humidity = 'measure.humidity',
  illuminance = 'measure.illuminance',
  level = 'measure.level',
  locked = 'measure.locked',
  loudness = 'measure.loudness',
  moisture = 'measure.moisture',
  motion = 'measure.motion',
  obstruction = 'measure.obstruction',
  recirculation = 'measure.recirculation',
  output_limit = 'measure.output_limit',
  output_rate = 'measure.output_rate',
  output_total = 'measure.output_total',
  pm2_5 = 'measure.pm2_5',
  precipitation = 'measure.precipitation',
  smoke = 'measure.smoke',
  speed = 'measure.speed',
  status = 'measure.status',
  switch = 'measure.switch',
  temperature = 'measure.temperature',
  temperature_setpoint = 'measure.temperature_setpoint',
  usage_rate = 'measure.usage_rate',
  usage_total = 'measure.usage_total',
  voc = 'measure.voc',
}


/** All known measures */
export const ALL_MEASURES: Measure[] = Object.values(Measure);


/** Labels for Device Measures */
export const LABEL_MEASURE: Record<string, string> = {
  [Measure.airpressure]: 'Air Pressure',
  [Measure.co2]: 'Carbon Dioxide',
  [Measure.closed]: 'Window/Door Position',
  [Measure.duration]: 'Duration',
  [Measure.humidity]: 'Relative Humidity',
  [Measure.illuminance]: 'Illuminance',
  [Measure.level]: 'Level',
  [Measure.locked]: 'Lock Status',
  [Measure.loudness]: 'Loudness',
  [Measure.moisture]: 'Moisture',
  [Measure.motion]: 'Detected Motion',
  [Measure.obstruction]: 'Obstruction',
  [Measure.recirculation]: 'Recirculation',
  [Measure.output_limit]: 'Max output',
  [Measure.output_rate]: 'Output rate',
  [Measure.output_total]: 'Total Output',
  [Measure.pm2_5]: 'Particulate Matter (PM2.5)',
  [Measure.precipitation]: 'Precipitation',
  [Measure.smoke]: 'Smoke',
  [Measure.speed]: 'Wind speed',
  [Measure.status]: 'Status',
  [Measure.switch]: 'Switch position',
  [Measure.temperature]: 'Temperature',
  [Measure.temperature_setpoint]: 'Temperature Setpoint',
  [Measure.usage_rate]: 'Usage rate',
  [Measure.usage_total]: 'Accumulated Usage',
  [Measure.voc]: 'Volatile Organic Compounds',
};


/** Find the label for a Device Measure */
export const findMeasureLabel = (measure: string) => LABEL_MEASURE[measure] ?? LABEL_MEASURE[`measure.${measure}`] ?? measure;


export enum Unit {
  amps = 'unit.amps',
  binary = 'unit.binary',
  celcius = 'unit.celcius',
  dba = 'unit.dba',
  hours = 'unit.hours',
  fahrenheit = 'unit.fahrenheit',
  gallons = 'unit.gallons',
  gallons_per_minute = 'unit.gallons_per_minute',
  inches = 'unit.inches',
  kw = 'unit.kw',
  kwh = 'unit.kwh', // data is stored on backend in watt-hours
  liters = 'unit.liters',
  liters_per_minute = 'unit.liters_per_minute',
  lux = 'unit.lux',
  millimeters = 'unit.millimeters',
  mph = 'unit.miles_per_hour',
  mps = 'unit.meters_per_second',
  percent = 'unit.percent',
  ppb = 'unit.ppb',
  ppm = 'unit.ppm',
  ug_per_m3 = 'unit.ug_per_m3',
  volt = 'unit.volt',
  watt = 'unit.watt',
}


/** Labels for measure units */
export const LABEL_UNITS: Record<Unit, string> = {
  [Unit.amps]: 'A',
  [Unit.binary]: '',
  [Unit.celcius]: '°C',
  [Unit.dba]: 'dBA',
  [Unit.hours]: 'hrs',
  [Unit.fahrenheit]: '°F',
  [Unit.gallons]: 'gal',
  [Unit.gallons_per_minute]: 'gpm',
  [Unit.inches]: 'in',
  [Unit.kw]: 'kW',
  [Unit.kwh]: 'kWh',
  [Unit.liters]: 'L',
  [Unit.liters_per_minute]: 'L/min',
  [Unit.lux]: 'Lux',
  [Unit.millimeters]: 'mm',
  [Unit.mph]: 'mph',
  [Unit.mps]: 'm/s',
  [Unit.percent]: '%',
  [Unit.ppb]: 'ppb',
  [Unit.ppm]: 'ppm',
  [Unit.ug_per_m3]: '\u03bcg/m\u00b3',
  [Unit.volt]: 'V',
  [Unit.watt]: 'W',
};


export enum ValueType {
  numeric = 'type.numeric',
  boolean = 'type.boolean',
  string = 'type.string',
  integer = 'type.integer',
}

/** Labels for value types */
export const LABEL_VALUE_TYPES: Record<ValueType, string> = {
  [ValueType.boolean]: 'Boolean',
  [ValueType.numeric]: 'Numeric',
  [ValueType.integer]: 'Integer',
  [ValueType.string]: 'String',
};


/** Find the label for a measure unit */
export const findUnitsLabel = (units: Unit | string | undefined) => {
  if (!units) return '';
  const locale = window.navigator.language;
  const unitValue = (units as string)
    .toLocaleLowerCase(locale)
    .replace('sensorvalueunit', 'unit')
    .replace('gpm', 'gallons_per_minute')
    .replace('none', 'binary');

  return LABEL_UNITS[unitValue as Unit] ?? unitValue;
}

export const findValueTypeLabel = (valueType: ValueType | undefined) => {
  if (!valueType) return 'unknown';
  return LABEL_VALUE_TYPES[valueType];
}

export const getMeasuresByCategory = (category: Category | null): Measure[] => {
  switch (category as Category) {
    default:
      return ALL_MEASURES;
    case Category.access:
      return [
        Measure.closed,
        Measure.locked,
        Measure.motion,
      ];
    case Category.air:
      return [
        Measure.airpressure,
        Measure.co2,
        Measure.humidity,
        Measure.loudness,
        Measure.obstruction,
        Measure.pm2_5,
        Measure.smoke,
        Measure.speed,
        Measure.switch,
        Measure.temperature,
        Measure.temperature_setpoint,
        Measure.voc,
      ];
    case Category.cable:
      return [
        Measure.status,
      ];
    case Category.light:
      return [
        Measure.illuminance,
        Measure.switch,
      ];
    case Category.power:
      return [
        Measure.duration, // session duration
        Measure.level, // charge level
        Measure.output_limit,
        Measure.output_rate,
        Measure.output_total,
        Measure.status, // read-only value
        Measure.switch, // allows user control the state
        Measure.usage_rate,
        Measure.usage_total,
      ];
    case Category.soil:
      return [
        Measure.humidity,
      ];
    case Category.system:
      return [
        Measure.status,
      ];
    case Category.water:
      return [
        Measure.moisture,
        Measure.precipitation,
        Measure.recirculation,
        Measure.switch,
        Measure.usage_rate,
        Measure.usage_total,

      ];
  }
}

export const formatValue = (
  value: string | number | null | undefined | boolean,
  digits?: number,
): string => {
  const numericValue = isNaN(Number(value)) ? 0 : Number(value);
  if (digits !== undefined) return numericValue.toFixed(digits);
  return numericValue.toFixed(numericValue < 100 ? 1 : 0);
};

const TEMPERATURE_THRESHOLD = {
  hot: 80,
  cold: 55,
};

const HUMIDITY_THRESHOLD = {
  low: 35,
  high: 60,
};

const WET_THRESHOLD = 75;

/* Adjusts voltage from 0-10 to percentage */
const convertSoilMoisture = (
  value: number | string | boolean | undefined,
) => {

  const numericValue = isNaN(Number(value)) ? 0 : Number(value);
  return Math.min(1, Math.max(0, (10 - numericValue) / 10)) * 100;
}

export const formatSoilMoisture = (value: number | string | boolean | undefined): string => {
  const convertedValue = convertSoilMoisture(Number(value));
  return (
    isNaN(convertedValue)
      ? '--'
      : [
        formatValue(convertedValue, 0),
        findUnitsLabel(Unit.percent),
      ].join('')
  );
}


export const convertPowerUsage = (
  value: number,
  unit: Unit,
): number => {
  // RIoT System delivers values in Kilowatt Hours
  return isNaN(Number(value)) ? NaN : Number(value);
}

export const formatPowerUsage = (
  value: number,
  unit: Unit,
) => {
  const convertedValue = convertPowerUsage(value, unit);

  return (
    isNaN(convertedValue)
      ? '--'
      : [
        formatValue(convertedValue),
        findUnitsLabel(unit),
      ].join('')
  );
}

export const convertPowerUsageRate = (
  value: number,
  unit: Unit,
): number => {
  // RIoT System delivers values in Watts
  if (unit === Unit.kw) {
    return isNaN(Number(value)) ? NaN : Number(value / 1000.0);
  } else {
    return isNaN(Number(value)) ? NaN : Number(value);
  }
}

export const formatPowerUsageRate = (
  value: number,
  unit: Unit,
) => {
  const convertedValue = convertPowerUsageRate(value, unit);

  return (
    isNaN(convertedValue)
      ? '--'
      : [
        formatValue(convertedValue),
        findUnitsLabel(unit),
      ].join('')
  );
}

export const convertTemperature = (
  value: number | string | boolean | undefined,
  unit: Unit,
): number => {
  // RIoT System delivers values in Fahrenheit
  if (unit === Unit.fahrenheit) {
    return isNaN(Number(value)) ? NaN : Number(value);
  } else {
    return isNaN(Number(value)) ? NaN : ((Number(value) - 32) * 5 / 9);
  }
}

export const formatTemperature = (
  value: number | string | boolean | undefined,
  unit: Unit,
) => {
  const convertedValue = convertTemperature(value, unit);

  return (
    isNaN(convertedValue)
      ? '--'
      : [
        formatValue(convertedValue, 0),
        findUnitsLabel(unit === Unit.celcius ? Unit.celcius : Unit.fahrenheit),
      ].join('')
  );
}

export const convertVolume = (
  value: number | string | boolean | undefined,
  unit: Unit,
): number => {
  // RIoT System delivers values in Gallons
  if (unit === Unit.gallons) {
    return isNaN(Number(value)) ? NaN : Number(value);
  } else if (unit === Unit.liters) {
    return isNaN(Number(value)) ? NaN : (Number(value) * 3.78541);
  }

  return isNaN(Number(value)) ? NaN : Number(value);
}

export const formatWaterUsage = (
  value: number | string | boolean | undefined,
  unit: Unit,
) => {
  const convertedValue = convertVolume(value, unit);
  return (
    isNaN(convertedValue)
      ? '--'
      : [
        formatValue(convertedValue),
        findUnitsLabel(unit === Unit.liters ? Unit.liters : Unit.gallons),
      ].join('')
  );
}

export const formatWaterUsageRate = (
  value: number | string | boolean | undefined,
  unit: Unit,
) => {
  const convertedValue = convertVolume(value, unit);

  return (
    isNaN(convertedValue)
      ? '--'
      : [
        formatValue(convertedValue),
        findUnitsLabel(unit === Unit.liters_per_minute ? Unit.liters_per_minute : Unit.gallons_per_minute),
      ].join('')
  );
}

export const convertWindSpeed = (
  value: number | string | boolean | undefined,
  unit: Unit,
) => {
  if (unit === Unit.mps) {
    return isNaN(Number(value)) ? NaN : Number(value) / 2.237;
  } else {
    // RIoT Weather delivers wind speed in miles per hour, fallback to that
    return isNaN(Number(value)) ? NaN : Number(value);
  }
}

export const formatWindSpeed = (
  value: number | string | boolean | undefined,
  unit: Unit,
) => {
  const convertedValue = convertWindSpeed(value, unit);

  return (
    isNaN(convertedValue)
      ? '--'
      : [
        formatValue(convertedValue, 0),
        findUnitsLabel(unit === Unit.mps ? Unit.mps : Unit.mph),
      ].join('')
  );
}

export const convertPrecipitation = (
  value: number | string | boolean | undefined,
  unit: Unit,
) => {
  if (unit === Unit.millimeters) {
    return isNaN(Number(value)) ? NaN : Number(value) * 25.4;
  } else {
    // RIoT Weather delivers precipitaion in inches, fallback to that
    return isNaN(Number(value)) ? NaN : Number(value);
  }
}

export const formatPrecipitation = (
  value: number | string | boolean | undefined,
  unit: Unit,
) => {
  const convertedValue = convertPrecipitation(value, unit);
  return (
    isNaN(convertedValue)
      ? '--'
      : [
        formatValue(convertedValue),
        findUnitsLabel(unit === Unit.millimeters ? Unit.millimeters : Unit.inches),
      ].join('')
  );
}

export const formatReadingDetails = (
  category: Category | string,
  measure: Measure | string,
  units: Unit,
  value: string | number | boolean | undefined,
): ReadingDetails => {
  switch (category as Category) {
    default:
      return {
        icon: DeviceUnknownIcon,
        value: [
          value || '--',
          findUnitsLabel(units),
        ].join(''),
      } as ReadingDetails;
    case Category.access:
      switch (measure as Measure) {
        default:
        case Measure.closed:
          if (typeof value === 'boolean' && !value) {
            return {
              icon: MeetingRoomIcon,
              color: "warning",
              value: 'open',
            } as ReadingDetails;
          }
          return {
            icon: SensorDoorIcon,
            value: 'shut',
          } as ReadingDetails;
        case Measure.locked:
          if (typeof value === 'boolean' && !value) {
            return {
              icon: LockOpenIcon,
              color: "warning",
              value: 'open',
            } as ReadingDetails;
          }
          return {
            icon: LockIcon,
            value: 'lock',
          } as ReadingDetails;
        case Measure.motion:
          if (typeof value === 'boolean' && !!value) {
            return {
              icon: DirectionsRunIcon,
              color: "warning",
              value: 'yes',
            } as ReadingDetails;
          }
          return {
            icon: BoyIcon,
            value: 'no',
          } as ReadingDetails;
      }
    case Category.air:
      switch (measure) {
        default:
        case Measure.airpressure:
          return {
            icon: HvacIcon,
            value: [
              Number(value).toFixed(0) || '0',
              findUnitsLabel(units),
            ].join(''),
          } as ReadingDetails;
        case Measure.smoke:
          return {
            icon: DeviceUnknownIcon,
            color: !!value ? 'warning' : 'info',
            value: [
              !!value ? 'yes' : 'no',
              findUnitsLabel(units),
            ].join(''),
          } as ReadingDetails;
        case Measure.co2:
          return {
            icon: Co2Icon,
            color: !!value ? 'warning' : 'info',
            value: [
              Number(value).toFixed(0) || '0',
              findUnitsLabel(units),
            ].join(''),
          } as ReadingDetails;
        case Measure.humidity:
          const color = (
            Number(value) > HUMIDITY_THRESHOLD.high
            || Number(value) < HUMIDITY_THRESHOLD.low) ? 'warning' : 'info';
          return {
            icon: OpacityIcon,
            color,
            value: [
              Number(value).toFixed(0),
              findUnitsLabel(units),
            ].join(''),
          } as ReadingDetails;
        case Measure.loudness:
          return {
            icon: HearingIcon,
            value: [
              Number(value).toFixed(0),
              findUnitsLabel(units),
            ].join(''),
          } as ReadingDetails;
        case Measure.obstruction:
          return {
            icon: HvacIcon,
            color: !!value ? 'warning' : 'info',
            value: [
              !!value ? 'yes' : 'no',
              findUnitsLabel(units),
            ].join(''),
          } as ReadingDetails;
        case Measure.pm2_5:
        case Measure.voc:
          return {
            icon: LensBlurIcon,
            value: [
              Number(value).toFixed(0),
              findUnitsLabel(units),
            ].join(''),
          } as ReadingDetails;
        case Measure.temperature:
          if (typeof value == 'number' || typeof value == 'string') {
            if (Number(value) > TEMPERATURE_THRESHOLD.hot) {
              return {
                icon: ThermostatIcon,
                color: "error",
                value: formatTemperature(value, units),
              } as ReadingDetails;
            }
            if (Number(value) < TEMPERATURE_THRESHOLD.cold) {
              return {
                icon: ThermostatIcon,
                color: "info",
                value: formatTemperature(value, units),
              } as ReadingDetails;
            }
            return {
              icon: ThermostatIcon,
              color: "success",
              value: formatTemperature(value, units),
            } as ReadingDetails;
          }
          return {
            icon: ThermostatIcon,
            color: "disabled",
            value: formatTemperature(value, units),
          } as ReadingDetails;
        case Measure.temperature_setpoint:
          if (typeof value == 'number' || typeof value == 'string') {
            return {
              icon: ThermostatAutoIcon,
              color: "info",
              value: formatTemperature(value, units),
            } as ReadingDetails;
          }
          return {
            icon: ThermostatAutoIcon,
            color: "disabled",
            value: formatTemperature(value, units),
          } as ReadingDetails;
      }
    case Category.cable:
      switch (measure) {
        default:
        case Measure.status:
          if (value === undefined || value === '') {
            return {
              icon: ElectricalServicesIcon,
              color: 'disabled',
              value: 'unknown',
            }
          }
          return {
            icon: ElectricalServicesIcon,
            color: Boolean(value) ? 'success' : 'disabled',
            value: Boolean(value) ? 'plugged' : 'unplugged',
          } as ReadingDetails;
      }
    case Category.light:
      switch (measure) {
        default:
        case Measure.switch:
          if (typeof value === 'boolean') {
            if (!value) {
              return {
                icon: LightbulbIcon,
                value: 'off',
              } as ReadingDetails;
            }
            return {
              icon: LightbulbIcon,
              color: "success",
              value: 'on',
            } as ReadingDetails;
          }
          return {
            icon: LightbulbIcon,
            color: "success",
            value: [
              Number(value).toFixed(0) || '0',
              findUnitsLabel(units),
            ].join(''),
          } as ReadingDetails;
        case Measure.illuminance:
          if (typeof value === 'number') {
            return {
              icon: Brightness6Icon,
              value: [
                Number(value).toFixed(0),
                findUnitsLabel(units),
              ].join(''),
            } as ReadingDetails;
          }
          return {
            icon: Brightness6Icon,
            value: [
              value || '--',
              findUnitsLabel(units),
            ].join(''),
          } as ReadingDetails;
      }
    case Category.power:
      switch (measure) {
        default:
        case Measure.duration:
          if (value === undefined || value === '') {
            return {
              icon: AvTimerIcon,
              value: 'unknown',
              color: 'disabled',
            } as ReadingDetails;
          }
          return {
            value: [
              (Number(value)).toFixed(1) || '0',
              findUnitsLabel(units),
            ].join(''),
            icon: AvTimerIcon,
            color: Boolean(value) ? 'success' : 'disabled',
          } as ReadingDetails;
        case Measure.level:
          if (!value) {
            return {
              icon: BatteryUnknownIcon,
              color: 'disabled',
              value: 'unknown',
            } as ReadingDetails;
          }
          return {
            icon: BatteryCharging60,
            color: Number(value) ? 'success' : 'info',
            value: [
              Number(value).toFixed(0) || '0',
              findUnitsLabel(units),
            ].join(''),
          } as ReadingDetails;
        case Measure.output_limit:
          if (value === undefined || value === '') {
            return {
              icon: CableIcon,
              value: 'unknown',
              color: 'disabled',
            } as ReadingDetails;
          }
          return {
            value: [
              (Number(value) / 1000).toFixed(1) || '0',
              findUnitsLabel(Unit.kw),
            ].join(''),
            icon: CableIcon,
            color: Boolean(value) ? 'success' : 'disabled',
          } as ReadingDetails;
        case Measure.output_rate:
          if (value === undefined || value === '') {
            return {
              icon: SpeedIcon,
              value: 'unknown',
              color: 'disabled',
            } as ReadingDetails;
          }
          return {
            value: [
              (Number(value) / 1000).toFixed(1) || '0',
              findUnitsLabel(Unit.kw),
            ].join(''),
            icon: SpeedIcon,
            color: Boolean(value) ? 'success' : 'disabled',
          } as ReadingDetails;
        case Measure.output_total:
          if (value === undefined || value === '') {
            return {
              icon: ElectricMeterIcon,
              value: 'unknown',
              color: 'disabled',
            } as ReadingDetails;
          }
          return {
            value: [
              (Number(value)).toFixed(0) || '0',
              findUnitsLabel(Unit.kwh),
            ].join(''),
            icon: ElectricMeterIcon,
            color: Boolean(value) ? 'success' : 'disabled',
          } as ReadingDetails;
        case Measure.status:
          if (value === undefined || value === '') {
            return {
              icon: BoltIcon,
              value: 'unknown',
              color: 'disabled',
            } as ReadingDetails;
          }
          return {
            icon: BatteryCharging60Icon,
            value: Boolean(value) ? 'charging' : 'idle',
            color: Boolean(value) ? 'success' : 'disabled',
          } as ReadingDetails;
        case Measure.switch:
          if (typeof value === 'boolean') {
            if (!value) {
              return {
                icon: PowerOffIcon,
                value: 'off',
              } as ReadingDetails;
            }
            return {
              icon: PowerIcon,
              color: "success",
              value: 'on',
            } as ReadingDetails;
          }
          return {
            icon: PowerIcon,
            color: "success",
            value: 'on',
          } as ReadingDetails;
        case Measure.usage_rate:
        case Measure.usage_total:
          return {
            icon: ElectricMeterIcon,
            value: [
              Number(value).toFixed(0) || '0',
              findUnitsLabel(units),
            ].join(''),
          } as ReadingDetails;
      }
    case Category.soil:
      switch (measure) {
        default:
        case Measure.humidity:
          if (convertSoilMoisture(Number(value)) < WET_THRESHOLD) {
            return {
              icon: OfflinePinIcon,
              value: formatSoilMoisture(value),
            } as ReadingDetails;
          }
          return {
            icon: OfflinePinIcon,
            color: 'warning',
            value: formatSoilMoisture(value),
          } as ReadingDetails;
      }
    case Category.system:
      return {
        icon: DeveloperBoardIcon,
        value: [
          value || '--',
          findUnitsLabel(units),
        ].join(''),
      } as ReadingDetails;
    case Category.water:
      switch (measure) {
        default:
        case Measure.switch:
          if (typeof value === 'boolean' && !!value) {
            return {
              icon: BuildIcon,
              color: "success",
              value: 'open',
            } as ReadingDetails;
          }
          return {
            icon: BuildIcon,
            color: "success",
            value: 'shut',
          } as ReadingDetails;
        case Measure.recirculation:
          if (typeof value === 'boolean' && !!value) {
            return {
              icon: HotTubIcon,
              color: "success",
              value: 'on',
            } as ReadingDetails;
          }
          return {
            icon: HotTubIcon,
            color: "info",
            value: 'off',
          } as ReadingDetails;
        case Measure.moisture:
          if (typeof value === 'boolean' && !!value) {
            return {
              icon: WavesIcon,
              value: 'wet',
              color: 'warning',
            } as ReadingDetails;
          }
          return {
            icon: OfflinePinIcon,
            value: 'dry',
          } as ReadingDetails;
        case Measure.usage_rate:
          return {
            icon: GasMeterIcon,
            value: formatWaterUsageRate(value, units),
          } as ReadingDetails;
        case Measure.usage_total:
          return {
            icon: GasMeterIcon,
            value: formatWaterUsage(value, units),
          } as ReadingDetails;
      }
  }
}

export const formatCategoryMeasurePair = (
  category: Category,
  measure: Measure,
) => [
  findCategoryLabel(category),
  findMeasureLabel(measure),
].join(' ') as string;


export const convertValue = (
  value: number | string | boolean | undefined,
  category: Category,
  measure: Measure,
  unit: Unit,
): number => {
  const defaultValue = isNaN(Number(value)) ? 0 : Number(value);
  switch (category) {
    default:
      return defaultValue;
    case Category.air:
      switch (measure) {
        default:
          return defaultValue;
        case Measure.temperature:
        case Measure.temperature_setpoint:
          return convertTemperature(value, unit);
      }
    case Category.soil:
      switch (measure) {
        default:
          return defaultValue;
        case Measure.moisture:
          return convertSoilMoisture(value);
      }
    case Category.water:
      switch (measure) {
        default:
          return defaultValue;
        case Measure.usage_rate:
          return convertVolume(value, unit);
        case Measure.usage_total:
          return convertVolume(value, unit);
      }
    case Category.power:
      switch (measure) {
        default:
          return defaultValue;
        case Measure.usage_rate:
        case Measure.output_rate:
          return convertPowerUsageRate(Number(value), unit);
        case Measure.usage_total:
        case Measure.output_total:
          return convertPowerUsage(Number(value), unit);
      }
  }
}