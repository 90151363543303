import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { pollingInterval } from "@features/tenant-selector/types";
import { getUnitsByCategoryMeasure } from "@features/unit-settings/utils";
import { Category, Measure, findCategoryLabel, findMeasureLabel, formatCategoryMeasurePair, getMeasuresByCategory } from "@lib/labels";
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, IconButton, Menu, MenuItem, Paper, Stack, Tooltip } from "@mui/material";
import { useCallback, useMemo, useState, MouseEvent, useEffect } from "react";
import NeighborhoodUsageChart from "./NeighborhoodUsageChart";
import { usePerformanceMultiQuery } from "@features/charts/api";
import { formatApiTimestamp, formatLabel } from "@lib/utils";
import { Aggregate, AnalyticsType, BinSize, ChartType } from "@features/settings/types";
import moment from "moment";
import LineChart from "@features/charts/components/LineChart";
import BoxChart from "@features/charts/components/BoxChart";
import HomesProvider from "@features/all-homes/components/HomesProvider";
import PlansProvider from "@features/all-plans/components/PlansProvider";
import { getChartDimensions } from "@features/charts/utils";
import ViewControls from "@features/charts/components/ViewControls";
import { CompareLabel, Interval } from "@features/home-analytics/types";
import { Link } from "react-router-dom";
import CurrentReadings from "@features/home-analytics/components/CurrentReadings";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Troubleshoot from "@mui/icons-material/Troubleshoot";

interface Props {
  
}

const NeighborhoodPerformance = (props: Props) => {
  const neighborhood = useAppSelector((state: RootState) => state.neighborhood.neighborhood);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const [category, setCategory] = useState<Category>(Category.air);
  const [measures, setMeasures] = useState<Measure[]>(getMeasuresByCategory(category));
  const [measure, setMeasure] = useState<Measure>(Measure.temperature);
  const [interval, setInterval] = useState<Interval>(Interval.MONTH);

  const [measureAnchorEl, setMeasureAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMeasureMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    setMeasureAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMeasureMenu = useCallback(() => {
    setMeasureAnchorEl(null);
  }, []);

  const handleSelectMeasure = useCallback((meas) => {
    setMeasure(meas);
    setMeasureAnchorEl(null);
  }, []);

  const selectingMeasure = useMemo(() => Boolean(measureAnchorEl), [measureAnchorEl]);

  useEffect(() => {
    const newMeasures = getMeasuresByCategory(category);
    setMeasures(newMeasures);
    if (!newMeasures.includes(measure)) {
      setMeasure(newMeasures[0]);
    }
  // recalculate measures when category is changed
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  const intervalMomentUnit = useMemo(() => (({
    [Interval.HOUR]: 'hour',
    [Interval.DAY]: 'day',
    [Interval.WEEK]: 'week',
    [Interval.MONTH]: 'month',
    [Interval.QUARTER]: 'quarter',
    [Interval.YEAR]: 'year',
  } as const)[interval]), [interval]);

  const binSize: BinSize = useMemo(() => (({
    [Interval.HOUR]: BinSize.hour,
    [Interval.DAY]: BinSize.hour,
    [Interval.WEEK]: BinSize.day,
    [Interval.MONTH]: BinSize.day,
    [Interval.QUARTER]: BinSize.day,
    [Interval.YEAR]: BinSize.week,
  } as const)[interval] ?? BinSize.day), [interval]);

  const {
    currentData: rawData,
    refetch: refetchRawData,
  } = usePerformanceMultiQuery({
    start: formatApiTimestamp(moment().startOf('day').subtract(1, intervalMomentUnit ?? 'week')),
    end: formatApiTimestamp(moment().endOf('day')), 
    aggregate: Aggregate.avg,
    measure: measure,
    binSize: binSize,
    groupByProperty: true,
    category: category,
    userTenantId: currentTenant?.tenant_id || '',
    propertyIds: neighborhood?.property_ids ?? [],
  }, {
    skip: !currentTenant?.tenant_id || !neighborhood?.property_ids,
    // pollingInterval,
  });

  const refetchAll = useCallback(() => {
    refetchRawData();
  }, [refetchRawData]);

  return <Stack spacing={1}>
    <Grid container columns={{ sm: 1, lg: 1 }} spacing={1}>
      <Grid item xs={1} lg={1}>
        <Stack spacing={1}>
          <Card>
            <CardContent>
              <Stack spacing={1}>
                <Stack direction="row" spacing={1}>
                  {[
                    Category.air,
                    Category.light,
                  ].map(cat => (
                    <Button
                      key={`category-button-${cat}`}
                      onClick={() => setCategory(cat)}
                      variant={category === cat ? 'contained' : 'outlined'}
                      color="info"
                      size="small"
                      sx={{
                        width: '50%'
                      }}
                    >{findCategoryLabel(cat)}</Button>
                  ))}
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Button
                    variant="contained"
                    color="info"
                    size="small"
                    onClick={handleOpenMeasureMenu}
                    endIcon={<KeyboardArrowDown />}
                    sx={{
                      width: '100%'
                    }}
                  >
                    {findMeasureLabel(measure)}
                  </Button>
                  <Menu
                    open={selectingMeasure}
                    onClose={handleCloseMeasureMenu}
                    anchorEl={measureAnchorEl}
                  >
                  {
                    measures.map(meas => (
                      <MenuItem
                        key={`measure-selector-${meas}`}
                        onClick={() => handleSelectMeasure(meas)}
                        sx={{
                          width: '30em'
                        }}
                      >{findMeasureLabel(meas)}</MenuItem>
                    ))
                  }
                  </Menu>
                </Stack>
                <Stack direction="row" spacing={1}>
                  {
                    [
                      Interval.DAY,
                      Interval.WEEK,
                      Interval.MONTH,
                      Interval.YEAR,
                    ].map(int => (
                      <Button
                        key={`interval-selector-${int}`}
                        onClick={() => setInterval(int)}
                        variant={interval === int ? "contained" : "outlined"}
                        color="info"
                        size="small"
                        sx={{
                          width: '25%'
                        }}
                      >{int}</Button>
                    ))
                  }
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Grid>
      <Grid item xs={2} lg={3}>
        <PlansProvider> 
          <HomesProvider>
            <Paper
              sx={{
                display: 'flex',
                justifyContent: "center",
                width: '100%',
              }}
            >
              {!rawData || !neighborhood
                ? <Box component="div" sx={{ textAlign: 'center', p: '9em' }}>
                    <CircularProgress />
                  </Box>
                : <BoxChart
                    ctype={AnalyticsType.performance}
                    data={rawData}
                    binSize={binSize}
                    category={category}
                    measure={measure}
                  />
              }
            </Paper>
          </HomesProvider>
        </PlansProvider>
      </Grid>
    </Grid>
  </Stack>
}


export default NeighborhoodPerformance;
