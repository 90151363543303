import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { PropertyManifestEntryUpdate } from "@features/home-manifest/types";
import InventoryProvider from "@features/inventory/components/InventoryProvider";
import { useCreatePlanManifestEntryMutation, useUpdatePlanManifestEntryMutation } from "@features/plan-manifest/api";
import DeviceSelector from "@features/plan-manifest/components/DeviceSelector";
import { Coordinates, ManifestEntry, ManifestEntryCreate, PlanManifest } from "@features/plan-manifest/types";
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Link, Stack, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { RIOT_BLUE } from "theme";
import ManifestEntryPosition from "./ManifestEntryPosition";


export type ManifestEntryModalProps = {
  existingEntry?: Partial<ManifestEntry|ManifestEntryCreate>;
  manifest: PlanManifest;
  onClose: (changes: boolean) => void;
};

const ManifestEntryModal = ({ manifest, onClose, existingEntry }: ManifestEntryModalProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant.builder_color || RIOT_BLUE, [currentTenant.builder_color]);

  const [loading, setLoading] = useState<boolean>(false);

  const [provisionOrder, setProvisionOrder] = useState<number>(
    Number(existingEntry?.provision_order ?? (
      Math.max(0, ...manifest.manifest_entries.map(e => Number(e.provision_order))) + 1)) || 0);

      const [position, setPosition] = useState<Partial<ManifestEntry|ManifestEntryCreate>>({
    property_area: existingEntry?.property_area || '',
    coordinates: existingEntry?.coordinates || {
      x_axis: '0',
      y_axis: '0',
      z_axis: '0',
    } as Coordinates,
  } as Partial<ManifestEntry>);

  const [selectedDeviceId, setSelectedDeviceId] = useState<string>((existingEntry as Partial<ManifestEntry>)?.device?.device_id || '');

  const [createPlanManifestEntry] = useCreatePlanManifestEntryMutation();
  const [updatePlanManifestEntry] = useUpdatePlanManifestEntryMutation();

  const handleUpdate = useCallback(async () => {
    if (!existingEntry) return;

    setLoading(true);

    const updatedEntry = await updatePlanManifestEntry({
      userTenantId: currentTenant.tenant_id!,
      planManifestId: manifest.manifest_id,
      planManifestEntryId: (existingEntry as Partial<ManifestEntry>).manifest_entry_id || '',
      body: {
        provision_order: provisionOrder,
        ...position,
        device: selectedDeviceId,
      } as Partial<ManifestEntryCreate>,
    });

    const errorDetails = (updatedEntry as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't update entry:", {
        key: "manifestentry-error",
        content: (
          <ExpandableSnack
            id="manifestentry-error"
            message={"Couldn't update entry:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });

      onClose(false);
      setLoading(false);
    } else {
      enqueueSnackbar("Updated entry", {
        variant: "success",
      });

      onClose(true);
      setLoading(false);
    }
  }, [currentTenant.tenant_id, enqueueSnackbar, existingEntry, manifest.manifest_id, onClose, position, provisionOrder, selectedDeviceId, updatePlanManifestEntry]);


  const handleCreate = useCallback(async () => {
    setLoading(true);

    const newEntry = await createPlanManifestEntry({
      userTenantId: currentTenant.tenant_id!,
      manifestId: manifest.manifest_id,
      body: {
        provision_order: provisionOrder,
        ...position,
        device: selectedDeviceId,
      } as Partial<ManifestEntryCreate>,
    });

    const errorDetails = (newEntry as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't create entry:", {
        key: "manifestentry-error",
        content: (
          <ExpandableSnack
            id="manifestentry-error"
            message={"Couldn't create entry:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });

      onClose(false);
      setLoading(false);
    } else {
      enqueueSnackbar("Created entry", {
        variant: "success",
      });

      onClose(true);
      setLoading(false);
    }
  }, [createPlanManifestEntry, currentTenant.tenant_id, manifest.manifest_id, provisionOrder, position, selectedDeviceId, enqueueSnackbar, onClose]);

  const handleSubmit = useCallback(() => {
    return existingEntry ? handleUpdate() : handleCreate();
  }, [existingEntry, handleCreate, handleUpdate]);


  const handleClose = () => {
    onClose(false);
  }

  const handleChangePosition = useCallback((entry: Partial<ManifestEntry|ManifestEntryCreate>|PropertyManifestEntryUpdate) => {
    setPosition(entry as Partial<ManifestEntry|ManifestEntryCreate>);
  }, []);

  const handleSelectDevice = useCallback((deviceId: string|null) => {
    deviceId && setSelectedDeviceId(deviceId);
  }, []);

  const notReady = useMemo(() => !selectedDeviceId || !position.property_area, [position.property_area, selectedDeviceId]);

  return (
    <Dialog open scroll="paper" fullWidth onClose={handleClose}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">
            {
              existingEntry ? `Edit ${(existingEntry as Partial<ManifestEntry>).device?.friendly_name || 'entry'}` : `Add entry to ${manifest.name || 'manifest'}`
            }
          </Typography>
          <TextField
            variant="outlined"
            label="Provision order"
            type="number"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]+' }}
            required
            value={provisionOrder}
            onChange={(e) => setProvisionOrder(Number(e.target.value) || 0)}
            size="small"
            sx={{
              width: 140,
            }}
          />
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ height: 700 }}>
        <Stack spacing={0.5}>
          <Typography variant="subtitle1">Device</Typography>
          <Box
            component="div"
            sx={{
              padding: 0.5,
              border: `1px solid ${themeColor}`,
              borderRadius: 3,
            }}
          >
            <InventoryProvider>
              <DeviceSelector onChange={handleSelectDevice} selected={selectedDeviceId} />
            </InventoryProvider>
          </Box>
          <Link underline="hover" href="/devices">Add a new device to the library</Link>
          <Typography variant="subtitle1">Position</Typography>
          <Box
            component="div"
            sx={{
              padding: 0.5,
              border: `1px solid ${themeColor}`,
              borderRadius: 3,
            }}
          >
            <ManifestEntryPosition onChange={handleChangePosition} existingPosition={position as Partial<ManifestEntryCreate>} />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={notReady}
          loading={loading}
        >
          {existingEntry ? 'Update entry' : 'Create entry'}
        </LoadingButton>
      </DialogActions>
    </Dialog>);
}

export default ManifestEntryModal;