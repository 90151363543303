import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { AnalyticsType, BinSize, ChartType, TrendLines } from "@features/settings/types";
import { getUnitsByCategoryMeasure } from '@features/unit-settings/utils';
import { Category, Measure, Unit, findUnitsLabel, formatCategoryMeasurePair, formatValue } from "@lib/labels";
import { AnalyticsSet } from '@lib/types';
import { useEffect, useMemo, useState } from "react";
import Chart from "react-apexcharts";
import { ChartDimensions } from '../types';
import { debounce, formatRawDataForTimeLineChart, getChartDimensions, getFallbackChartOptions } from '../utils';
import { percentile } from 'stats-lite';

import moment from 'moment';

type Props = {
  data: AnalyticsSet[];
  ctype: AnalyticsType;
  binSize: BinSize;
  category: Category;
  measure: Measure;
}

const BoxChart = ({ data, ctype, binSize, category, measure }: Props) => {
  const property = useAppSelector((state: RootState) => state.property);
  const userPreferences = useAppSelector((state: RootState) => state.preferences);
  const homes = useAppSelector((state: RootState) => state.homes);
  const plans = useAppSelector((state: RootState) => state.plans);

  const manifestEntries = useMemo(() => property.manifestEntries || [], [property.manifestEntries]);
  const preferences = useMemo(() => userPreferences.preferences, [userPreferences.preferences]);
  const unitPreferences = useMemo(() => preferences.units || {}, [preferences.units]);

  const categoryMeasurePair = useMemo<string>(() => formatCategoryMeasurePair(category, measure), [category, measure]);

  const preferredUnit = useMemo<Unit>(() => {
    const defaultUnit = getUnitsByCategoryMeasure(category, measure)[0];
    return unitPreferences[categoryMeasurePair] || defaultUnit;
  }, [category, categoryMeasurePair, measure, unitPreferences]);

  const chartData = useMemo(() => {
    return formatRawDataForTimeLineChart(
      data,
      manifestEntries,
      plans,
      homes,
      false,
      TrendLines.none,
      'boxPlot',
      category,
      measure,
      preferredUnit,
    );
  }, [data, manifestEntries, plans, homes, category, measure, preferredUnit]);

  const boxChartData: ApexAxisChartSeries = useMemo(() => {
    const now = new Date().getTime();
    const minDate = chartData.reduce<number>((a, cd) => Math.min(a, ...cd.data.map((data) => (data && typeof data === 'object' && 'x' in data && data.x) ?? now)), now);
    const maxDate = chartData.reduce<number>((a, cd) => Math.max(a, ...cd.data.map((data) => (data && typeof data === 'object' && 'x' in data && data.x) ?? now)), now);
    const binSizeMs = ({
      [BinSize.hour]: 1000 * 60 * 60,
      [BinSize.day]: 1000 * 60 * 60 * 24,
      [BinSize.week]: 1000 * 60 * 60 * 24 * 7,
      [BinSize.month]: 1000 * 60 * 60 * 24 * 30,
    } as const)[binSize];

    const rowCount = Math.floor((maxDate - minDate) / binSizeMs) + 1;

    return [{
      name: 'Neighborhood',
      type: 'boxPlot',
      data: Array(rowCount).fill(undefined).map((u, idx) => {
        const rowTimestamp = minDate + (binSizeMs * idx);
        const rowsToAggregate = chartData.flatMap((cd) => {
          const cdd = cd.data;

          if (cdd && Array.isArray(cdd)) {
            return (cdd as any)
              .filter((cddr: any) => {
                if (cddr && typeof cddr === 'object' && 'x' in cddr && 'y' in cddr && typeof cddr.x === 'number' && typeof cddr.y === 'number') {
                  return Math.abs(cddr.x - rowTimestamp) < binSizeMs / 2;
                }

                return false;
              }).map((cddr: any) => cddr.y as number);
          }

          return [];
        });

        return {
          x: moment(new Date(rowTimestamp)).toDate().getTime(),
          y: [
            Math.min(...rowsToAggregate),
            percentile(rowsToAggregate, 0.25),
            percentile(rowsToAggregate, 0.5),
            percentile(rowsToAggregate, 0.75),
            Math.max(...rowsToAggregate),
          ],
        }
      }),
    }];
  }, [binSize, chartData]);
  
  const [chartDimensions, setChartDimensions] = useState<ChartDimensions>(getChartDimensions());

  const chartOptions = useMemo(() => {
    const width = chartData.map(s => {
      if ((s as any)?.name?.toLowerCase().includes('trendline')) {
        return 1;
      }
      return 3;
    }) || [3];

    const markerSize = chartData.map(s => {
      if ((s as any)?.name?.toLowerCase().includes('trendline')) {
        return 0;
      }
      return 0;
    }) || [3];

    const titleText = (() => {
      if (!chartData.length) return 'Loading data...';

      const noDataText = `No data found for ${categoryMeasurePair}`;
      if (!chartData?.length) return noDataText;

      return categoryMeasurePair;
    })();


    return ({
      chart: {
        id: 'home-box-chart',
        stacked: false,
        zoom: {
          enabled: true
        },
        animations: {
          enabled: false,
        },
        type: 'boxPlot',
      },
      stroke: {
        curve: 'smooth',
        width,
      },
      title: {
        text: titleText,
        style: {
          fontFamily: 'Montserrat',
        }
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          }
        },
        yaxis: {
          lines: {
            show: true,
          }
        },
      },
      xaxis: {
        type: 'datetime',
        tickPlacement: 'on',
      },
      yaxis: {
        decimalsInFloat: 0,
        labels: {
          formatter: (value: any) => `${formatValue(value)}${findUnitsLabel(preferredUnit)}`,
        }
      },
      tooltip: {
        fontFamily: 'Montserrat',
        y: {
          formatter: value => `${formatValue(value)}${findUnitsLabel(preferredUnit)}`,
        },
      },
      markers: {
        size: markerSize,
      },
      legend: {
        position: chartDimensions.legend,
        fontFamily: 'Montserrat',
      },
      plotOptions: {
        bar: {
          columnWidth: '50%'
        }
      },
    } as ApexCharts.ApexOptions);
  }, [categoryMeasurePair, chartData, chartDimensions.legend, preferredUnit]);

  useEffect(() => {
    const debouncedResize = debounce(() => setChartDimensions(getChartDimensions()), 1000);
    window.addEventListener('resize', debouncedResize);
    return () => window.removeEventListener('resize', debouncedResize);
  }, []);

  return (
    <Chart
      series={boxChartData}
      options={chartOptions}
      type='boxPlot'
      width={chartDimensions.width}
      height={chartDimensions.height}
    />
  );
};


export default BoxChart;