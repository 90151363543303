import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  floorplanUrl: {
    url: null,
    url_ttl: 0,
  },
}

export const floorplanReducer = createSlice({
  name: 'floorplan',
  initialState,
  reducers: {
    setFloorplanUrl: (state: any, action: any) => {
      if (action.payload) {
        state.floorplanUrl = action.payload;
      }
    },
  }
});

export default floorplanReducer.reducer;

export const { setFloorplanUrl } = floorplanReducer.actions;