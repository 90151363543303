import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import UserFields from "@features/all-users/components/UserFields";
import { User } from "@features/all-users/types";
import AdminTable from "@features/Common/AdminTable";
import ConfirmDialog from "@features/Common/ConfirmDialog";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { useUpdatePropertyMutation } from "@features/home/api";
import HomeFields from "@features/home/components/HomeFields";
import { Property, PROPERTY_SCHEMA } from '@features/home/types';
import { useHomeownerQuery, usePrefetch } from '@features/all-homeowners/api';
import { pollingInterval } from "@features/tenant-selector/types";
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage, U } from "@lib/utils";
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import { Backdrop, Box, CircularProgress, IconButton, Tooltip, Typography } from "@mui/material";
import { FormikHelpers } from "formik";
import { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { usePendingHomesQuery, useSellHomeMutation } from "../api";

const PendingHomeList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const [homeToSell, setHomeToSell] = useState<Property|null>(null);
  const [currentHome, setCurrentHome] = useState<Property | null>(null);
  const [hasPrefetchedAllHomeowners, setHasPrefetchedAllHomeowners] = useState<boolean>(false);

  const {
    refetch,
    currentData: homes,
  } = usePendingHomesQuery({
    userTenantId: currentTenant?.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id,
    pollingInterval,
  });

  const prefetchHomeowner = usePrefetch('homeowner');

  const {
    currentData: homeowner,
    isFetching: isFetchingHomeowner,
  } = useHomeownerQuery({
    userTenantId: currentTenant?.tenant_id || '',
    homeownerId: currentHome?.homeowner_id || '',
  }, {
    skip: !currentTenant?.tenant_id || !currentHome?.homeowner_id,
    pollingInterval: pollingInterval*10*6,
  });


  /** Prefetches homeowners for all pending homes */
  useEffect(() => {
    if (!hasPrefetchedAllHomeowners) {
      if (homes && homes?.length) {
        homes.forEach((home: Property) => {
          prefetchHomeowner({
            userTenantId: currentTenant?.tenant_id || '',
            homeownerId: home?.homeowner_id || '',
          }, { force: true });
        });
      }
    }
    setHasPrefetchedAllHomeowners(true);
  }, [hasPrefetchedAllHomeowners, homes, prefetchHomeowner, currentTenant?.tenant_id]);


  const prefetchNextHomeowner = useCallback((home: Property) => {
    if (currentHome && currentHome.property_id === home.property_id) return;
    setCurrentHome(home);
    prefetchHomeowner({
      userTenantId: currentTenant?.tenant_id || '',
      homeownerId: home?.homeowner_id || '',
    }, { force: true });
  }, [currentHome, prefetchHomeowner, currentTenant?.tenant_id]);

  const [
    updateProperty,
  ] = useUpdatePropertyMutation();

  /** Updates Home */
  const handleUpdateProperty = useCallback(async (pp: Partial<Property>) => {
    const updatedProperty = await updateProperty({
      userTenantId: currentTenant?.tenant_id || '',
      propertyId: pp?.property_id || '',
      body: pp,
    });

    const errorDetails = (updatedProperty as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't update home:", {
        key: "home-error",
        content: (
          <ExpandableSnack
            id="home-error"
            message={"Couldn't update home:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });

      return null;
    } else {
      enqueueSnackbar("Updated home", {
        variant: "success",
      });

      return (updatedProperty as any).data;
    }
  }, [currentTenant?.tenant_id, enqueueSnackbar, updateProperty]);

  const [
    sellHome,
  ] = useSellHomeMutation();


  const handleSellHome = useCallback(async (user: Partial<User>, helpers: FormikHelpers<User>) => {
    helpers.setSubmitting(true);

    const newUser = await sellHome({
      userTenantId: currentTenant?.tenant_id || '',
      propertyId: homeToSell?.property_id || '',
      tenantId: homeToSell?.homeowner_id || '',
      user,
      builderAccess: false,
    });

    helpers.setSubmitting(false);

    const errorDetails = (newUser as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't sell home:", {
        key: "sellhome-error",
        content: (
          <ExpandableSnack
            id="sellhome-error"
            message={"Couldn't sell home:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });

    } else {
      enqueueSnackbar("Home is sold:", {
        key: "sellhome-success",
        content: (
          <ExpandableSnack
            id="sellhome-success"
            message={"Home is sold:"}
            variant={SnackType.success}
            detail={[
              "Congratulations!",
              "The home was successfully sold to its assigned homeowner.",
              "Admin user has been created for homeowner.",
            ].join(' ')}
          />),
      });
      setHomeToSell(null);

      helpers.resetForm();
    }

    refetch();

  }, [currentTenant?.tenant_id, enqueueSnackbar, homeToSell?.homeowner_id, homeToSell?.property_id, refetch, sellHome]);

  const prepareHomeForSale = useCallback((home: Property) => {
    if (!homeowner) return;
    setHomeToSell(home);
  }, [homeowner]);

  /** Display table of pending homes */
  const renderedHomes = useMemo(() => {
    if (userTenant.checkingTenant) return <Backdrop open><CircularProgress /></Backdrop>;
    const options: MUIDataTableOptions = {
      enableNestedDataAccess: '.',
    };

    /** TABLE COLUMN DEFINITION */
    const columns: MUIDataTableColumn[] = [
      {
        name: 'name',
        label: 'Name',
      },
      {
        name: 'physical_address.city',
        label: 'City',
      },
      {
        name: 'physical_address.state',
        label: 'State',
      },

    ];

    return (
      <AdminTable<Property>
        title="RIoT Homes"
        label="RIoT Home"
        id_key="property_id"
        name_key="name"
        columns={columns}
        options={options}
        data={homes || []}
        FormikProps={{
          validationSchema: PROPERTY_SCHEMA
        }}
        makeViewPath={
          (row) => `/homes/${U(row.property_id)}`
        }
        customActions={
          (row) => <>
            <Tooltip title={`Complete Sale`} onOpen={() => prefetchNextHomeowner(row)} placement="top" arrow>
              <IconButton
                onMouseEnter={() => prefetchNextHomeowner(row)}
                onClick={() => prepareHomeForSale(row)}
              >
                <PriceCheckIcon />
              </IconButton>
            </Tooltip>
          </>
        }
        onSave={handleUpdateProperty}
        refresh={() => refetch()}
        editPermission="write-properties"
        deletePermission="delete-properties"
      >
        <HomeFields />
      </AdminTable>
    );
  }, [handleUpdateProperty, homes, prefetchNextHomeowner, prepareHomeForSale, refetch, userTenant.checkingTenant]);

  const userDetails = useMemo(() => {
    if (!homeowner) return {};
    const [firstName, lastName] = homeowner.name.split(' ', 2);
    const address = [
      homeowner.physical_address?.address_line_1,
      homeowner.physical_address?.address_line_1 || undefined,
      homeowner.physical_address?.city,
      homeowner.physical_address?.state,
      homeowner.physical_address?.zip_postal_code,
    ].filter(e => !!e).join(', ')

    return {
      username: `${firstName.toLowerCase()}.${lastName.toLowerCase()}`,
      given_name: firstName,
      family_name: lastName,
      address,
      email: homeowner.email,
    } as Partial<User>;
  }, [homeowner]);

  /** MAIN RENDER */
  return (
    <Box
      component="div"
      sx={{
        margin: '1em',
        padding: '2em',
      }}
    >
      <Typography variant="h4">{userTenant.name}</Typography>
      <Typography variant="h5">RIoT Homes Ready for Sale</Typography>
      <Box
        component="div"
        sx={{
          mt: '3em',
        }}
      >
        {renderedHomes}
        {
          !isFetchingHomeowner && homeToSell &&
          <ConfirmDialog
            title={`Complete sale of ${homeToSell?.name || 'home'}`}
            open={!!homeToSell}
            onClose={() => setHomeToSell(null)}
            onConfirm={handleSellHome}
            initialValues={userDetails}
            positive="Sell"
            negative="Cancel"
          >
            <UserFields />
          </ConfirmDialog>
        }
      </Box>
    </Box>

  );
}

export default PendingHomeList;