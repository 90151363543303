import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { AttentionLevel } from "@features/home-notifications/types";
import { ManifestDevice } from "@features/plan-manifest/types";
import { Category, Measure, Unit } from "@lib/labels";
import { SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import CropSquareIcon from '@mui/icons-material/CropSquare';
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useHealthCheckHomeMutation } from "../api";
import { HealthCheckResult, HealthCheckType } from "../types";
import { getHealthCheckSentiment } from "../utils";
import HealthCheckSummary from "./HealthCheckSummary";

const SubsurfaceMoistureHealth = () => {
  const { enqueueSnackbar } = useSnackbar();

  const property = useAppSelector((state: RootState) => state.property);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const manifestEntries = useMemo(() => property.manifestEntries || [], [property.manifestEntries]);

  const [healthCheckResult, setHealthCheckResult] = useState<HealthCheckResult | null>(null);

  const [
    healthCheckHome
  ] = useHealthCheckHomeMutation();

  useEffect(() => {
    if (currentTenant.tenant_id && property.property_id) {
      healthCheckHome({
        healthCheckType: HealthCheckType.SUBSURFACE_MOISTURE,
        propertyId: property.property_id,
        userTenantId: currentTenant.tenant_id,
      }).then((res) => {
        if ('error' in res) {
          enqueueSnackbar("Failed to perform health check:", {
            key: "sm-hc-error",
            content: (
              <ExpandableSnack
                id="sm-hc-error"
                message={"Couldn't perform health check:"}
                variant={SnackType.error}
                detail={getErrorMessage(res.error)}
              />),
          });
        } else {
          setHealthCheckResult(res.data);
        }
      });
    }
  }, [healthCheckHome, property.property_id, currentTenant.tenant_id, enqueueSnackbar]);

  const involvedEntries = useMemo(
    () => (
      manifestEntries
        ?.filter(ee => (ee.device as ManifestDevice).sensors.some(ss => (
          ss.sensor_category === Category.soil && ss.measure_name === Measure.humidity))
        ).map(ee => ({
          ...ee,
          device: {
            ...(ee.device as ManifestDevice),
            sensors: [
              ...((ee.device as ManifestDevice).sensors || []).map((ees) => ({
                ...ees,
                units: Unit.percent, /* Set units as percent for display purposes */
              }))
            ]
          }
        }))
      ),
      [manifestEntries]
    );

  const status = useMemo(() => {
    switch(getHealthCheckSentiment(healthCheckResult)) {
      default:
      case AttentionLevel.warning:
        return 'Foundation status is unknown. Foundation may be at risk of moisture variance.';
      case AttentionLevel.urgent:
        return 'Abnormal foundation moisture variance detected.';
      case AttentionLevel.info:
        return 'Waiting on healthcheck to complete.';
      case undefined:
        return 'Foundation moisture variance is normal.';
    }
  }, [healthCheckResult]);

  const averageVariancePercent = useMemo(() => {
    const avgMoisture = healthCheckResult?.subsurface_moisture?.average_moisture;
    const stdDev = healthCheckResult?.subsurface_moisture?.typical_stddev_moisture;

    if (typeof avgMoisture === 'number' && typeof stdDev === 'number') {
      return Math.pow(stdDev / avgMoisture, 2) * 100; /* Variance is stddev squared */
    }

    return null;
  }, [
    healthCheckResult?.subsurface_moisture?.average_moisture,
    healthCheckResult?.subsurface_moisture?.typical_stddev_moisture
  ]);

  const substatus = useMemo(() => {
    return [
      'When moisture variance is detected, the home may experience foundation issues.',
      ...(typeof averageVariancePercent === 'number' ? [
        `Average soil moisture variance for this home is ${Math.round(averageVariancePercent)}%.`
      ] : []),
    ].join('\n');
  }, [averageVariancePercent]);

  return (
    <HealthCheckSummary
      icon={<CropSquareIcon sx={{ fontSize: '6em' }} />}
      healthCheckResult={healthCheckResult}
      title="Foundation"
      entries={involvedEntries || []}
      status={status}
      substatus={substatus}
    />
  );
}

export default SubsurfaceMoistureHealth;
