import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { useGetPlanManifestsQuery } from "@features/plan-manifest/api";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Modal } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { SceneTemplate, SceneTemplateCreate, SceneTemplateUpdate } from "../types";
import SceneTemplateEditor from "./SceneTemplateEditor";
import { LoadingButton } from "@mui/lab";
import { useUpdateSceneTemplateMutation } from "../api";
import { useSnackbar } from "notistack";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";


export type SceneTemplateDialogProps = {
  open: boolean;
  onClose: (changes: boolean) => void;
  sceneTemplate: SceneTemplate;
};

const SceneTemplateDialog = ({ sceneTemplate, open, onClose }: SceneTemplateDialogProps) => {
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  
  const [
    updateSceneTemplate,
  ] = useUpdateSceneTemplateMutation();

  const { enqueueSnackbar } = useSnackbar();

  const handleCancel = useCallback(async () => {
    onClose(false);
  }, [onClose]);

  const [newSceneTemplate, setNewSceneTemplate] = useState<SceneTemplate>(sceneTemplate)

  const handleChange = useCallback((update: SceneTemplateUpdate) => {
    setNewSceneTemplate({
      ...newSceneTemplate,
      ...update,
    });
  }, [newSceneTemplate]);

  const handleSave = useCallback(async () => {
    const updateResponse = await updateSceneTemplate({
      userTenantId: currentTenant.tenant_id,
      scenetemplateId: sceneTemplate.scene_template_id,
      body: newSceneTemplate,
    });

    if ('error' in updateResponse) {
      enqueueSnackbar("Failed to save Scene Template:", {
        key: "save-scene-error",
        content: (
          <ExpandableSnack
            id="save-scene-error"
            message={"Couldn't save Scene Template:"}
            variant={SnackType.error}
            detail={getErrorMessage(updateResponse.error)}
          />),
      });
    } else {
      enqueueSnackbar("Saved Scene Template.", {
        variant: 'success',
      });

      onClose(true);
    }
  }, [currentTenant.tenant_id, enqueueSnackbar, newSceneTemplate, onClose, sceneTemplate.scene_template_id, updateSceneTemplate]);
  
  return (
    <Dialog open={open} scroll="paper" fullWidth onClose={onClose}>
      <DialogTitle>Edit Scene Template: {sceneTemplate.name ?? ''}</DialogTitle>
      <DialogContent sx={{ flexDirection: 'column', display: 'flex', alignItems: 'stretch', justifyContent: 'flex-start' }}>
        <SceneTemplateEditor sceneTemplate={newSceneTemplate} onChange={handleChange} handleSave={handleSave} handleCancel={handleCancel} />
      </DialogContent>
    </Dialog>
  );
}

export default SceneTemplateDialog;