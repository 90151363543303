import { ButtonUnstyledProps } from '@mui/core/ButtonUnstyled';
import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/core/ButtonUnstyled';
import { styled } from '@mui/system';

type Props = {
  color: string;
} & ButtonUnstyledProps;

const ColorButton = ({ color, onClick, disabled }: Props) => {

const StyledColorButton = (
  styled(ButtonUnstyled)`
    width: 5em;
    height: auto;
    ml: 1;
    background: ${color};

    &:hover: {
      background: ${color};
    }

    &.${buttonUnstyledClasses.disabled}  {
      background: ${color};
    }
  `);

    return <StyledColorButton onClick={onClick} disabled={disabled} />;

}



export default ColorButton;