import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import TransferList from "@features/Common/TransferList";
import { Property } from "@features/home/types";
import { Contact } from "../types";

type Props = {
  contact: Contact;
  onShare?: (toRemove: string[], toAdd: string[]) => void;
}

const PropertyContact = ({ contact, onShare }: Props) => {
  const homes = useAppSelector((state: RootState) => state.homes);

  /** MAIN RENDER */
  return (
    <TransferList<Property>
      available={homes}
      selected={homes.filter(p => contact.property_ids?.includes(p.property_id))}
      id_key="property_id"
      name_key="name"
      callback={onShare}
      titleLeft="Available Homes"
      titleRight="Share With"
    />
  );
}

export default PropertyContact;