import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import AdminTable from "@features/Common/AdminTable";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { Tenant } from "@features/userTenant/types";
import { checkPermission } from "@features/userTenant/utils";
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import UpdateRolesIcon from '@mui/icons-material/SupervisedUserCircle';
import { Box, CircularProgress, FormGroup, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { MUIDataTableColumn } from "mui-datatables";
import { useSnackbar } from "notistack";
import { FC, useCallback, useMemo, useState } from "react";
import {
  useCreateUserInTenantMutation,
  useDeleteUserMutation,
  useMyUsersQuery,
  useTenantUsersQuery,
  useUpdateUserMutation
} from "../api";
import { USER_SCHEMA, User } from "../types";
import RolesModal from "./RolesModal";
import TenantAutocomplete from "./TenantAutocomplete";
import UserFields from "./UserFields";

const columns: MUIDataTableColumn[] = [
  {
    name: 'username',
    label: 'Username',
  },
  {
    name: 'email',
    label: 'Email Address',
  },
  {
    name: 'given_name',
    label: 'First Name',
  },
  {
    name: 'family_name',
    label: 'Last Name',
  },
  {
    name: 'address',
    label: 'Address',
  },
];

/** Wrapper for /users */
const UserList: FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const users = useAppSelector((state: RootState) => state.users.users);
  const roles = useAppSelector((state: RootState) => state.users.roles);
  const userPermissions = useAppSelector((state: RootState) => state.userTenant.userPermissions);

  const [userToEditRoles, setUserToEditRoles] = useState<User|null>(null);
  const [selectedTenant, setSelectedTenant] = useState<Tenant|null>(null);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const isOtherTenant = useMemo(() => (
    !!userTenant?.tenant_id &&
    !!currentTenant?.tenant_id &&
    userTenant.tenant_id !== currentTenant.tenant_id
  ), [currentTenant.tenant_id, userTenant.tenant_id]);

  const [
    updateUser,
  ] = useUpdateUserMutation();

  const [
    createUserInTenant,
  ] = useCreateUserInTenantMutation();

  const [
    deleteUser,
  ] = useDeleteUserMutation();


  const {
    refetch: refetchMyUsers,
  } = useMyUsersQuery({
    userTenantId: currentTenant?.tenant_id || '',
  }, {
    skip: !userTenant?.tenant_id || isOtherTenant,
  });

  const {
    refetch: refetchTenantUsers,
  } = useTenantUsersQuery({
    userTenantId: userTenant?.tenant_id || '',
    tenantId: currentTenant?.tenant_id || '',
    includeChildren: true,
  }, {
    skip: !userTenant?.tenant_id || isOtherTenant,
  });


  const handleUpdateUser = useCallback(async (body: Partial<User>) => {
    if (!body.created) {
      /** Create new user */
      const newUser = await createUserInTenant({
        userTenantId: currentTenant?.tenant_id || '',
        tenantId: selectedTenant?.tenant_id || '',
        body,
      });
      const errorDetails = (newUser as ApiErrorResponse)?.error;

      if (errorDetails) {
        enqueueSnackbar("Couldn't create user:", {
          key: "user-error",
          content: (
            <ExpandableSnack
              id="user-error"
              message={"Couldn't create user:"}
              variant={SnackType.error}
              detail={getErrorMessage(errorDetails)}
            />),
        });

        return null;
      } else {
        enqueueSnackbar("Created user", {
          variant: "success",
        });
      }

      return (newUser as any).data;

    } else {
      /** Update user */

      const updatedUser = await updateUser({
        userTenantId: currentTenant?.tenant_id || '',
        username: body?.username || '',
        body,
      });

      const errorDetails = (updatedUser as ApiErrorResponse)?.error;

      if (errorDetails) {
        enqueueSnackbar("Couldn't update user:", {
          key: "user-error",
          content: (
            <ExpandableSnack
              id="user-error"
              message={"Couldn't update user:"}
              variant={SnackType.error}
              detail={getErrorMessage(errorDetails)}
            />),
        });

        return null;
      } else {
        enqueueSnackbar("Updated user", {
          variant: "success",
        });

        return (updatedUser as any).data;

      }
    }
  }, [createUserInTenant, currentTenant?.tenant_id, enqueueSnackbar, selectedTenant?.tenant_id, updateUser]);

  const handleDeleteUser = useCallback(async (body: Partial<User>) => {
    const deletedUser = await deleteUser({
      userTenantId: currentTenant?.tenant_id || '',
      username: body?.username || '',
    });

    const errorDetails = (deletedUser as ApiErrorResponse).error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't delete user:", {
        key: "user-error",
        content: (
          <ExpandableSnack
            id="user-error"
            message={"Couldn't delete user:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
      return false;
    } else {
      enqueueSnackbar("Deleted user", {
        variant: "success",
      });
      return true;
    }
  }, [currentTenant?.tenant_id, deleteUser, enqueueSnackbar]);

  const refresh = useCallback(() => {
    if (isOtherTenant) return refetchTenantUsers();
    return refetchMyUsers();
  }, [isOtherTenant, refetchMyUsers, refetchTenantUsers])

  const renderedUserTable = useMemo(() => (
    <AdminTable<User>
      title="Users"
      label="User"
      id_key="username" name_key="username"
      columns={columns}
      data={users}
      FormikProps={{
        validationSchema: USER_SCHEMA
      }}
      makeNew={() => ({
        username: '',
        email: '',
        given_name: '',
        family_name: '',
        address: '',
        is_internal: false,
        enabled: true,
      })}
      onSave={handleUpdateUser}
      onDelete={handleDeleteUser}
      customActions={
        (roles.length > 0 && checkPermission(userPermissions, 'update-access')) ? (user: User) => (
          <>
            <Tooltip title="Update Roles" placement="top" arrow>
              <span>
                <IconButton color="secondary" onClick={() => setUserToEditRoles(user)}>
                  <UpdateRolesIcon />
                </IconButton>
              </span>
            </Tooltip>
          </>
        ) : undefined
      }
      refresh={refresh}
      editPermission="write-users"
      deletePermission="delete-users"
    >
      <Paper sx={{ m: 1, p: 1 }}>
        <FormGroup sx={{
          width: '100%',
          margin: '0 0 1% 0',
        }}>
          <TenantAutocomplete onChange={setSelectedTenant} />
        </FormGroup>
      </Paper>
      <UserFields />
    </AdminTable>
  ), [handleDeleteUser, handleUpdateUser, refresh, roles.length, userPermissions, users]);

  if (userTenant.checkingTenant) return <CircularProgress />;

  return (
    <Box
      component="div"
      sx={{
        margin: '1em',
        padding: '2em',
      }}
    >
      <Typography variant="h4">{currentTenant.name}</Typography>
      <Typography variant="h5">Users</Typography>
      <Box
        component="div"
        sx={{
          mt: '3em',
        }}
      >
        {renderedUserTable}
      </Box>
      {
        userToEditRoles &&
        <RolesModal
          user={userToEditRoles}
          open={Boolean(userToEditRoles)}
          onClose={(changes: any) => {
            setUserToEditRoles(null);

            if (changes) {
              refresh();
            }
          }}
        />
      }
    </Box>
  );
}

export default UserList;