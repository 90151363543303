import StreetAddress from "@features/Address/StreetAddress";
import TownAddress from "@features/Address/TownAddress";
import AppTextField from "@features/home/components/AppTextField";


const BuilderFields = () => (
  <>
    <AppTextField
      name="name"
      label="Name"
      type="text"
      fullWidth
      required
    />
    <AppTextField
      name="email"
      label="Email Address"
      type="text"
      fullWidth
      required
    />
    <AppTextField
      name="description"
      label="Description"
      type="text"
      fullWidth
    />
    <AppTextField
      name="phone_number"
      label="Phone Number"
      type="text"
      fullWidth
    />
    <AppTextField
      name="website"
      label="Website"
      type="text"
      fullWidth
    />
    <StreetAddress />
    <TownAddress />
  </>
);

export default BuilderFields;