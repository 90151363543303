import { riotApi } from "@features/aws-user/api";
import { Plan, PlanCreate, PlanUpdate } from "@features/floorplan/types";
import { HTTPMethodType } from "@lib/types";
import { U } from "@lib/utils";

/** API slice for plan manifest-related endpoints */
export const plansApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      /** Get all the Plans */
      plans: builder.query<Plan[], {
        userTenantId: string,
        tenantId: string,
      }>({
        query: ({ userTenantId, tenantId }) => ({
          url: `tenant/${U(userTenantId)}/plans`,
          method: HTTPMethodType.GET,
          params: {
            tenant_id: U(tenantId),
          }
        }),
      }),

      /** Create plan for current tenant */
      createMyPlan: builder.mutation<Plan, {
        userTenantId: string,
        body: PlanCreate,
      }>({
        query: ({ userTenantId, body }) => ({
          url: `tenant/${U(userTenantId)}/plans`,
          method: HTTPMethodType.POST,
          body,
        }),
      }),

      /** Create plan for another tenant */
      createTenantPlan: builder.mutation<Plan, {
        userTenantId: string,
        tenantId: string,
        body: PlanCreate,
      }>({
        query: ({ userTenantId, tenantId, body }) => ({
          url: `tenant/${U(userTenantId)}/tenants/${U(tenantId)}/plans`,
          method: HTTPMethodType.POST,
          body,
        }),
      }),

      /** Update plan for current tenant */
      updateMyPlan: builder.mutation<Plan, {
        userTenantId: string,
        planId: string,
        body: PlanUpdate,
      }>({
        query: ({ userTenantId, planId, body }) => ({
          url: `tenant/${U(userTenantId)}/plans/${U(planId)}`,
          method: HTTPMethodType.PATCH,
          body,
        }),
      }),

      /** Update plan for another tenant */
      updateTenantPlan: builder.mutation<Plan, {
        userTenantId: string,
        tenantId: string,
        planId: string,
        body: PlanUpdate,
      }>({
        query: ({ userTenantId, tenantId, planId, body }) => ({
          url: `tenant/${U(userTenantId)}/tenants/${U(tenantId)}/plans/${U(planId)}`,
          method: HTTPMethodType.PATCH,
          body,
        }),
      }),

      /** Update plan for current tenant */
      deleteMyPlan: builder.mutation<Plan, {
        userTenantId: string,
        planId: string,
      }>({
        query: ({ userTenantId, planId }) => ({
          url: `tenant/${U(userTenantId)}/plans/${U(planId)}`,
          method: HTTPMethodType.DELETE,
        }),
      }),

      /** Update plan for another tenant */
      deleteTenantPlan: builder.mutation<Plan, {
        userTenantId: string,
        tenantId: string,
        planId: string,
      }>({
        query: ({ userTenantId, tenantId, planId }) => ({
          url: `tenant/${U(userTenantId)}/tenants/${U(tenantId)}/plans/${U(planId)}`,
          method: HTTPMethodType.DELETE,
        }),
      }),
    };
  }
});

export const {
  usePlansQuery,
  useCreateMyPlanMutation,
  useCreateTenantPlanMutation,
  useUpdateMyPlanMutation,
  useUpdateTenantPlanMutation,
  useDeleteMyPlanMutation,
  useDeleteTenantPlanMutation
} = plansApi;
