import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { PropertyManifestEntry } from "@features/home-manifest/types";
import CategoryReadingIcon from "@features/home-status/components/CategoryReadingIcon";
import { ReadingPair } from "@features/home-status/types";
import { ManifestDevice } from "@features/plan-manifest/types";
import { Category, Measure } from "@lib/labels";
import { formatLabel } from "@lib/utils";
import { Stack, Typography } from "@mui/material";
import { useMemo } from "react";


type Props = {
  evseEntry?: PropertyManifestEntry;
}

const AvailablePowerOutput = (props: Props) => {
  const { evseEntry } = props;

  const property = useAppSelector((state: RootState) => state.property);

  const devices = useMemo(() => property.devices || [], [property.devices]);

  const limitSensor = useMemo(() => (evseEntry?.device as ManifestDevice)?.sensors
    .find(s => s.sensor_category === Category.power && s.measure_name === Measure.output_limit), [evseEntry?.device]);

  const limitDevice = useMemo(() => devices
    .find(r => r.data.entity_id === evseEntry?.sensor_map?.[limitSensor?.sensor_id ?? limitSensor?.friendly_name ?? '']), [limitSensor?.sensor_id, limitSensor?.friendly_name, devices, evseEntry?.sensor_map]);

  const limitReading = useMemo(() => ({
    device: limitDevice,
    sensor: limitSensor,
    deviceName: formatLabel((evseEntry?.device as ManifestDevice)?.friendly_name
      || (evseEntry?.device as ManifestDevice)?.manufacturer || ''),
    area: formatLabel(evseEntry?.property_area || ''),
    entryId: evseEntry?.manifest_entry_id,
  } as ReadingPair), [limitDevice, limitSensor, evseEntry?.device, evseEntry?.property_area, evseEntry?.manifest_entry_id]);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="h6">Available Power Output</Typography>
      <CategoryReadingIcon
        category={Category.power}
        measure={Measure.output_limit}
        readings={limitReading}
      />
    </Stack>
  );
}

export default AvailablePowerOutput;