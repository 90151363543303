import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { pollingInterval } from "@features/tenant-selector/types";
import { getUnitsByCategoryMeasure } from "@features/unit-settings/utils";
import { Category, Measure, formatCategoryMeasurePair, formatReadingDetails } from "@lib/labels";
import { CircularProgress, List, ListItem, ListItemIcon, ListItemText, Skeleton, Typography } from "@mui/material";
import { useMemo } from "react";
import { RIOT_BLUE } from "theme";
import { useNeighborhoodCurrentUsageQuery } from "../api";

const CurrentUsage = () => {

  const neighborhood = useAppSelector((state: RootState) => state.neighborhood.neighborhood);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const userPreferences = useAppSelector((state: RootState) => state.preferences);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const preferences = useMemo(() => userPreferences.preferences, [userPreferences.preferences]);
  const unitPreferences = useMemo(() => preferences.units || {}, [preferences.units]);

  const preferredPowerUnit = useMemo(() => (
    unitPreferences?.[formatCategoryMeasurePair(Category.power, Measure.usage_rate)]
    || getUnitsByCategoryMeasure(Category.power, Measure.usage_rate)[0]
  ), [unitPreferences]);

  const preferredWaterUnit = useMemo(() => (
    unitPreferences?.[formatCategoryMeasurePair(Category.water, Measure.usage_rate)]
    || getUnitsByCategoryMeasure(Category.water, Measure.usage_rate)[0]
  ), [unitPreferences]);

  const {
    currentData: powerUsageRate,
  } = useNeighborhoodCurrentUsageQuery({
    category: Category.power,
    userTenantId: currentTenant?.tenant_id || '',
    neighborhoodId: neighborhood?.neighborhood_id || '',
  }, {
    skip: !currentTenant?.tenant_id || !neighborhood?.neighborhood_id,
    pollingInterval,
  });

  const {
    currentData: waterUsageRate,
  } = useNeighborhoodCurrentUsageQuery({
    category: Category.water,
    userTenantId: currentTenant?.tenant_id || '',
    neighborhoodId: neighborhood?.neighborhood_id || '',
  }, {
    skip: !currentTenant?.tenant_id || !neighborhood?.neighborhood_id,
    pollingInterval,
  });

  const powerDetails = useMemo(() => formatReadingDetails(
    Category.power,
    Measure.usage_rate,
    preferredPowerUnit,
    powerUsageRate?.total || 0,
  ), [powerUsageRate?.total, preferredPowerUnit]);

  const waterDetails = useMemo(() => formatReadingDetails(
    Category.water,
    Measure.usage_rate,
    preferredWaterUnit,
    waterUsageRate?.total || 0,
  ), [preferredWaterUnit, waterUsageRate?.total]);

  return (
    <List sx={{ width: 'inherit'}}>
      {powerUsageRate
          ? <ListItem>
            
            <ListItemText>Power Usage</ListItemText>
            <ListItemIcon sx={{ color: RIOT_BLUE }}>
              {powerDetails?.value || '--'}
            </ListItemIcon>
          </ListItem>
          : <ListItem>
            <ListItemText><Typography><Skeleton variant="rectangular" /></Typography></ListItemText>
          </ListItem>
        }
        {waterUsageRate
           ? <ListItem>
              <ListItemText>Water Usage</ListItemText>
              <ListItemIcon sx={{ color: RIOT_BLUE }}>
                {waterDetails?.value || '--'}
              </ListItemIcon>
            </ListItem>
           : <ListItem>
            <ListItemText><Typography><Skeleton variant="rectangular" /></Typography></ListItemText>
           </ListItem>
        }
    </List>
  );
}


export default CurrentUsage;