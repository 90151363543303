/**
 * Chart
 *
 */
import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { Aggregate, AnalyticsType, BinSize } from '@features/settings/types';
import { Category, Measure } from '@lib/labels';
import { AnalyticsSet, SnackType } from '@lib/types';
import { formatApiTimestamp, formatDate, getErrorMessage } from '@lib/utils';
import { CircularProgress, Paper, Stack } from '@mui/material';
import { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAveragePerformanceQuery, usePerformanceMultiQuery, usePerformanceQuery, usePlanPerformanceQuery, useZipPerformanceQuery } from '../api';
import { getChartDimensions, getFallbackChartOptions, getStartDate } from '../utils';
import LineChart from './LineChart';
import ViewControls from './ViewControls';
import PlansProvider from '@features/all-plans/components/PlansProvider';
import HomesProvider from '@features/all-homes/components/HomesProvider';


type Props = {
  ctype: AnalyticsType,
}

/** Chart for a single Property */
const HomeChart = ({ ctype }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const property = useAppSelector((state: RootState) => state.property);
  const userPreferences = useAppSelector((state: RootState) => state.preferences);

  const fallbackOptions = useMemo(() => getFallbackChartOptions(ctype), [ctype]);
  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const category = useMemo<Category>(() => (
    userPreferences.preferences.charts[ctype]?.category || fallbackOptions.category
  ), [fallbackOptions.category, ctype, userPreferences.preferences.charts]);

  const measure = useMemo<Measure>(() => (
    userPreferences.preferences.charts[ctype]?.measure ?? fallbackOptions.measure
  ), [fallbackOptions.measure, ctype, userPreferences.preferences.charts]);

  const startTime = useMemo<Moment>(() => (
    getStartDate(userPreferences.preferences.charts[ctype]?.interval)
  ), [ctype, userPreferences.preferences.charts]);

  const endTime = useMemo<Moment>(() => formatDate(null), []);

  const aggregate = useMemo<Aggregate>(() => (
    userPreferences.preferences.charts[ctype]?.aggregate || fallbackOptions.aggregate
  ), [fallbackOptions.aggregate, ctype, userPreferences.preferences.charts]);

  const binSize = useMemo<BinSize | undefined>(() => (
    userPreferences.preferences.charts[ctype]?.binSize || fallbackOptions.binSize
  ), [fallbackOptions.binSize, ctype, userPreferences.preferences.charts]);

  const compareHomes = useMemo<string[]>(() => (
    userPreferences.preferences.charts[ctype]?.compareHomes || []
  ), [ctype, userPreferences.preferences.charts]);

  const comparePlans = useMemo<string[]>(() => (
    userPreferences.preferences.charts[ctype]?.comparePlans || []
  ), [ctype, userPreferences.preferences.charts]);

  const compareZip = useMemo<string[]>(() => {
    return (
    userPreferences.preferences.charts[ctype]?.compareZip || []
  )}, [ctype, userPreferences.preferences.charts]);

  const showAverage = useMemo<boolean>(() => (
    Boolean(userPreferences.preferences.charts[ctype]?.showAverage) || false
  ), [ctype, userPreferences.preferences.charts]);

  const {
    currentData: rawPerformanceData,
    refetch: refetchRawPerformanceData,
    error: rawPerformanceError,
  } = usePerformanceQuery({
    userTenantId: currentTenant?.tenant_id || '',
    propertyId: property?.property_id || '',
    category,
    measure,
    start: formatApiTimestamp(startTime),
    end: formatApiTimestamp(endTime),
    aggregate,
    binSize,
  }, {
    skip: !currentTenant.tenant_id || !property.property_id || !category,
    pollingInterval: 15000,
  });

  useEffect(() => {
    if (rawPerformanceError) {
      enqueueSnackbar("Couldn't fetch home performance data:", {
        key: "plan-performance-error",
        content: (
          <ExpandableSnack
            id="plan-performance-error"
            message={"Couldn't fetch home performance data:"}
            variant={SnackType.error}
            detail={getErrorMessage(rawPerformanceError)}
          />),
      });
    }
  }, [enqueueSnackbar, rawPerformanceError]);

  const {
    currentData: rawHomeComparePerformanceData,
    refetch: refetchRawHomeComparePerformanceData,
    error: rawHomeComparePerformanceError,
  } = usePerformanceMultiQuery({
    userTenantId: currentTenant?.tenant_id || '',
    propertyIds: compareHomes,
    category,
    measure,
    start: formatApiTimestamp(startTime),
    end: formatApiTimestamp(endTime),
    aggregate: (aggregate && aggregate !== Aggregate.none) ? aggregate : Aggregate.avg,
    binSize: binSize || BinSize.hour,
    groupByProperty: true,
  }, {
    skip: !currentTenant.tenant_id || compareHomes.length < 1 || !category || !measure,
    pollingInterval: 15000,
  });

  useEffect(() => {
    if (rawHomeComparePerformanceError) {
      enqueueSnackbar("Couldn't fetch home performance data:", {
        key: "plan-performance-error",
        content: (
          <ExpandableSnack
            id="plan-performance-error"
            message={"Couldn't fetch home performance data:"}
            variant={SnackType.error}
            detail={getErrorMessage(rawHomeComparePerformanceError)}
          />),
      });
    }
  }, [enqueueSnackbar, rawHomeComparePerformanceError]);

  const {
    currentData: rawZipComparePerformanceData,
    refetch: refetchRawZipComparePerformanceData,
    error: rawZipComparePerformanceError,
  } = useZipPerformanceQuery({
    userTenantId: currentTenant?.tenant_id || '',
    zipCodes: compareZip,
    category,
    measure,
    start: formatApiTimestamp(startTime),
    end: formatApiTimestamp(endTime),
    aggregate: (aggregate && aggregate !== Aggregate.none) ? aggregate : Aggregate.avg,
    binSize: binSize || BinSize.hour,
  }, {
    skip: !currentTenant.tenant_id || !Array.isArray(compareZip) || compareZip.length < 1 || !category || !measure,
    pollingInterval: 15000,
  });

  useEffect(() => {
    if (rawZipComparePerformanceError) {
      enqueueSnackbar("Couldn't fetch zip code performance data:", {
        key: "zip-performance-error",
        content: (
          <ExpandableSnack
            id="zip-performance-error"
            message={"Couldn't fetch zip code performance data:"}
            variant={SnackType.error}
            detail={getErrorMessage(rawZipComparePerformanceError)}
          />),
      });
    }
  }, [enqueueSnackbar, rawZipComparePerformanceError]);

  const {
    currentData: rawPlanPerformanceData,
    refetch: refetchPlanPerformanceData,
    error: rawPlanPerformanceError,
  } = usePlanPerformanceQuery({
    userTenantId: currentTenant?.tenant_id || '',
    planIds: comparePlans,
    category,
    measure,
    start: formatApiTimestamp(startTime),
    end: formatApiTimestamp(endTime),
    aggregate: (aggregate && aggregate !== Aggregate.none) ? aggregate : Aggregate.avg,
    binSize: binSize || BinSize.hour,
  }, {
    skip: !currentTenant.tenant_id || !comparePlans?.length || !category,
    pollingInterval: 15000,
  });

  useEffect(() => {
    if (rawPlanPerformanceError) {
      enqueueSnackbar("Couldn't fetch plan performance data:", {
        key: "plan-usage-error",
        content: (
          <ExpandableSnack
            id="plan-usage-error"
            message={"Couldn't fetch plan performance data:"}
            variant={SnackType.error}
            detail={getErrorMessage(rawPlanPerformanceError)}
          />),
      });
    }
  }, [enqueueSnackbar, rawPlanPerformanceError]);


  const {
    currentData: rawSystemAverageData,
    refetch: refetchRawSystemAverageData,
    error: rawSystemAverageError,
  } = useAveragePerformanceQuery({
    userTenantId: currentTenant?.tenant_id || '',
    category,
    measure,
    start: formatApiTimestamp(startTime),
    end: formatApiTimestamp(endTime),
    aggregate,
    binSize,
  }, {
    skip: !currentTenant.tenant_id || !category || !showAverage,
    pollingInterval: 15000,
  });


  useEffect(() => {
    if (rawSystemAverageError) {
      enqueueSnackbar("Couldn't fetch system average data:", {
        key: "system-average-error",
        content: (
          <ExpandableSnack
            id="system-average-error"
            message={"Couldn't fetch system average data:"}
            variant={SnackType.error}
            detail={getErrorMessage(rawSystemAverageError)}
          />),
      });
    }
  }, [enqueueSnackbar, rawSystemAverageError]);


  const refetchAll = useCallback(() => {
    refetchRawPerformanceData();
    refetchPlanPerformanceData();
    refetchRawHomeComparePerformanceData();
    refetchRawSystemAverageData();
    refetchRawZipComparePerformanceData();
  }, [refetchPlanPerformanceData, refetchRawHomeComparePerformanceData, refetchRawPerformanceData, refetchRawSystemAverageData, refetchRawZipComparePerformanceData]);

  const [rawData, setRawData] = useState<AnalyticsSet[]|null>(null);

  useEffect(() => {
    setRawData([
      ...(rawPerformanceData || []),
      ...(rawPlanPerformanceData || []),
      ...(rawZipComparePerformanceData  || []),
      ...(rawHomeComparePerformanceData || []),
      ...(rawSystemAverageData  || []),
    ]);
  }, [rawHomeComparePerformanceData, rawPerformanceData, rawPlanPerformanceData, rawSystemAverageData, rawZipComparePerformanceData]);

  const ready = useMemo(() => rawData !== null, [rawData]);

  return (
    <PlansProvider> 
      <HomesProvider>
        <Stack sx={{ width: '100%' }}>
          <ViewControls onRefreshClick={refetchAll} chartType={ctype} />
          <Stack direction="row" spacing={2}>
            <Paper
              sx={{
                width:  getChartDimensions().width + 50,
                height:  getChartDimensions().height + 50,
                padding: 2,
                display: 'flex',
                alignItems: "center",
                justifyContent: "center"
              }}
            >
            {
              !ready
                ? <CircularProgress />
                : <LineChart data={rawData || []} ctype={ctype} />
            }
            </Paper>
          </Stack>
        </Stack>
      </HomesProvider>
    </PlansProvider>
  );
}


export default HomeChart;
