import { createSlice } from "@reduxjs/toolkit";
import { SceneTemplate } from "./types";

const initialState: SceneTemplate[] = [];

export const sceneTemplatesReducer = createSlice({
  name: 'sceneTemplates',
  initialState,
  reducers: {
    setSceneTemplates: (state: SceneTemplate[], action: any) => {
      if (action.payload) {
        state.splice(0, state.length);
        action.payload.forEach((md: SceneTemplate) => state.push(md));
      } else {
        state.splice(0, state.length);
      }
    }
  }
});

export default sceneTemplatesReducer.reducer;

export const {
  setSceneTemplates,
} = sceneTemplatesReducer.actions;