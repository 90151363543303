import { riotApi } from "@features/aws-user/api";
import { Tenant, TenantUpdate } from '@features/userTenant/types';
import { HTTPMethodType } from '@lib/types';
import { U } from '@lib/utils';


export const brandingApi = riotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      /** upload logo */
      uploadLogo: builder.mutation<any, { userTenantId: string, fileName: string, file: FormData }>({
        query: ({ userTenantId, fileName, file }) => ({
          url: `/tenant/${U(userTenantId)}/logo/${U(fileName)}`,
          method: HTTPMethodType.PUT,
          body: file,
        }),
      }),
      /** Update primary color for builder branding */
      updateColor: builder.mutation<Tenant, { userTenantId: string, color: string }>({
        query: ({ userTenantId, color }) => ({
          url: `tenant/${U(userTenantId)}/tenants/${U(userTenantId)}`,
          method: HTTPMethodType.PATCH,
          body: {
            builder_color: color,
          } as TenantUpdate,
        }),
      }),
    };
  },
});

export const {
  useUpdateColorMutation,
  useUploadLogoMutation,
} = brandingApi;