import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { UtilGroup } from "@features/all-utility-groups/types";
import EnergyReading from "@features/energy/components/EnergyReading";
import { formatDate } from "@lib/utils";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { useMemo } from "react";


const UtilityGroupSummary = () => {
  const utilGroup = useAppSelector((state: RootState) => state.utilityGroup.utilityGroup as UtilGroup);
  const homes = useAppSelector((state: RootState) => state.homes);

  const utilGroupHomes = useMemo(() => homes
    .filter(h => utilGroup.property_ids?.includes(h.property_id)) || [], [homes, utilGroup.property_ids]);

  const capacity = useMemo(() => utilGroup?.utility_management?.energy_management.svc_max_power, [utilGroup?.utility_management?.energy_management.svc_max_power]);

  if (!utilGroup.group_id) return <CircularProgress />

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">Created</Typography>
        <Typography variant="body1" fontWeight={700}>{formatDate(utilGroup.created).fromNow()}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">Last Updated</Typography>
        <Typography variant="body1" fontWeight={700}>{formatDate(utilGroup.updated).fromNow()}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">Capacity</Typography>
        <EnergyReading variant="body1" energy={capacity!} />
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="top">
        <Typography variant="body1">Homes</Typography>
        <Stack
          alignItems="flex-end"
          sx={{
            overflowY: 'auto',
            padding: 0.3,
          }}
        >
          {
            !utilGroupHomes.length
              ? <Typography variant="body1" fontWeight={700}>None</Typography>
              : utilGroupHomes.map(h => (
                <Typography variant="body1" key={h.property_id} fontWeight={700}>
                  {h.name || h.physical_address.address_line_1}
                </Typography>
            ))
          }
        </Stack>
      </Stack>
    </Stack>
  );
}


export default UtilityGroupSummary;
