import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import AdminTable from "@features/Common/AdminTable";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { DEFAULT_ADDRESS } from "@features/home/types";
import { Tenant, TenantCreate, TenantType, TenantUpdate } from "@features/userTenant/types";
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage } from "@lib/utils";
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { MUIDataTableColumn } from "mui-datatables";
import { useSnackbar } from "notistack";
import { useCallback, useMemo } from "react";
import {
  useBuildersQuery, useCreateBuilderMutation, useDeleteBuilderMutation, useUpdateBuilderMutation
} from "../api";
import BuilderFields from "./BuilderFields";


const columns: MUIDataTableColumn[] = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'email',
    label: 'Email',
  },
  {
    name: 'phone_number',
    label: 'Phone Number',
  },
  {
    name: 'website',
    label: 'Website',
  },
  {
    name: 'description',
    label: 'Description',
  },
];

const BuilderList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const builders = useAppSelector((state: RootState) => state.builders);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const [
    createBuilder
  ] = useCreateBuilderMutation();

  const [
    updateBuilder,
  ] = useUpdateBuilderMutation();

  const [
    deleteBuilder,
  ] = useDeleteBuilderMutation();


  const {
    refetch,
    isFetching: fetchingBuilders,
  } = useBuildersQuery({
    userTenantId: currentTenant?.tenant_id || '',
  }, {
    skip: !currentTenant?.tenant_id,
  });


  /** Handles Save button on New/Edit Builder dialog */
  const handleSaveBuilder = useCallback(async (builder: Partial<Tenant>) => {
    /** New builder */
    if (!builder.tenant_id) {
      const newBuilder = await createBuilder({
        userTenantId: currentTenant?.tenant_id || '',
        body: {
          name: builder.name,
          description: builder.description,
          email: builder.email,
          phone_number: builder.phone_number,
          physical_address: builder.physical_address,
          website: builder.website,
          property_ids: [],
          tenant_type: TenantType.Builder,
        } as TenantCreate,
      });

      const errorDetails = (newBuilder as ApiErrorResponse)?.error;

      if (errorDetails) {
        enqueueSnackbar("Couldn't create builder:", {
          key: "builder-error",
          content: (
            <ExpandableSnack
              id="builder-error"
              message={"Couldn't create builder:"}
              variant={SnackType.error}
              detail={getErrorMessage(errorDetails)}
            />),
        });
      } else {
        enqueueSnackbar("Created builder", {
          variant: "success",
        });
      }

      return (newBuilder as any).data;
    }
    /** Existing builder */
    const updatedBuilder = await updateBuilder({
      userTenantId: currentTenant?.tenant_id || '',
      tenantId: builder.tenant_id,
      body: {
        name: builder.name,
        description: builder.description,
        phone_number: builder.phone_number,
        physical_address: builder.physical_address,
        website: builder.website,

      } as TenantUpdate,
    });

    const errorDetails = (updatedBuilder as ApiErrorResponse)?.error;

    if (errorDetails) {
      enqueueSnackbar("Couldn't update builder:", {
        key: "builder-error",
        content: (
          <ExpandableSnack
            id="builder-error"
            message={"Couldn't update builder:"}
            variant={SnackType.error}
            detail={getErrorMessage(errorDetails)}
          />),
      });
    } else {
      enqueueSnackbar("Updated builder", {
        variant: "success",
      });
    }

    return updatedBuilder;
  }, [createBuilder, currentTenant?.tenant_id, enqueueSnackbar, updateBuilder]);


  const handleDeleteBuilder = useCallback(async (builder: Tenant) => {
    const deletedBuilder = await deleteBuilder({
      userTenantId: currentTenant?.tenant_id || '',
      tenantId: builder?.tenant_id || '',
    }).unwrap();

    if (!deletedBuilder) {
      enqueueSnackbar("Couldn't delete builder:", {
        variant: 'error',
      });
    } else {
      enqueueSnackbar("Deleted Builder", {
        variant: "success",
      });
    }

    return Boolean(deletedBuilder);
  }, [currentTenant?.tenant_id, deleteBuilder, enqueueSnackbar]);


  const renderedBuildersTable = useMemo(() => (
    <AdminTable<Tenant>
      title="Builders"
      label="Builder"
      id_key="tenant_id"
      name_key="name"
      columns={columns}
      data={builders}
      refresh={refetch}
      makeNew={() => ({
        name: '',
        description: '',
        email: '',
        website: '',
        phone_number: '',
        physical_address: DEFAULT_ADDRESS,
      } as TenantCreate)}
      onSave={handleSaveBuilder}
      onDelete={handleDeleteBuilder}
      editPermission={'update-tenants'}
      deletePermission={'delete-tenants'}
    >
      <BuilderFields />
    </AdminTable>
  ), [builders, handleDeleteBuilder, handleSaveBuilder, refetch]);

  const isLoading = useMemo(() => !currentTenant?.tenant_id  || fetchingBuilders, [currentTenant?.tenant_id, fetchingBuilders]);

  return (
    <Box
      component="div"
      sx={{
        margin: '1em',
        padding: '2em',
      }}
    >
      <Typography variant="h4">{currentTenant.name}</Typography>
      <Typography variant="h5">Builders</Typography>
      <Box
        component="div"
        sx={{
          mt: '3em',
        }}
      >
        {isLoading && <Backdrop open><CircularProgress /></Backdrop>}
        {!isLoading && renderedBuildersTable}
      </Box>
    </Box>
  );
}


export default BuilderList;