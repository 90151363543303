import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { PropertyManifestEntry } from "@features/home-manifest/types";
import { formatLabel, getManifestEntryLabel } from "@lib/utils";
import { Autocomplete, List, PopperProps, TextField, Typography } from "@mui/material";
import { useMemo } from 'react';
import { RIOT_BLUE } from 'theme';


type Props = {
  options: PropertyManifestEntry[];
  onChange: (entryId: string) => void;
}

const ManifestEntrySelector = ({ options, onChange }: Props) => {
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant.builder_color || RIOT_BLUE, [currentTenant.builder_color]);

  const sortedOptions = options
    .map(o => ({
      label: getManifestEntryLabel(o),
      id: o.manifest_entry_id,
      area: formatLabel(o.property_area),
    }))
    .sort((a, b) => a.area.localeCompare(b.area))

  return (
    <Autocomplete
      size="small"
      options={sortedOptions}
      groupBy={option => option.area}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          label="Available devices"
          placeholder=""
          sx={{
            color: themeColor,
            '& .MuiFormLabel-root': {
              color: themeColor,
            }
          }}
        />
      )}
      renderGroup={(params) => (
        <List
          disablePadding
          key={params.group}
          subheader={
            <Typography sx={{
              color: themeColor,
              fontWeight: 700,
              padding: '0 1em',
            }}>{params.group}</Typography>
          }
        >
          <List>{params.children}</List>
        </List>
      )}
      componentsProps={{
        popper: {
          sx: {
            border: `1px solid ${themeColor}`,
            borderRadius: 1.3,
          }
        } as Partial<PopperProps>
      }}
      onChange={(e, value) => value?.id && onChange(value.id)}
    />);
}

export default ManifestEntrySelector;