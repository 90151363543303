import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { UtilGroup } from "@features/all-utility-groups/types";
import EnergyManagement from "@features/energy/components/EnergyManagement";
import { Grid, Stack, Typography } from "@mui/material";
import UtilityGroupEditor from "./UtilityGroupEditor";
import UtilityGroupProvider from "./UtilityGroupProvider";


const UtilityGroup = () => {
  const utilGroup = useAppSelector((state: RootState) => state.utilityGroup.utilityGroup as UtilGroup);

  return <UtilityGroupProvider>
    <Stack>
      <Typography variant="h5" sx={{ mb: 10 }}>{utilGroup.group_id}</Typography>
      <Grid container sx={{ width: 'inherit' }} columns={{ xs: 1, md: 2 }} spacing={2}>
        <Grid item xs={1}>
          <UtilityGroupEditor />
        </Grid>
        <Grid item xs={1}>
          <EnergyManagement />
        </Grid>
      </Grid>
    </Stack>
  </UtilityGroupProvider>;
}

export default UtilityGroup;