import { createSlice } from "@reduxjs/toolkit";
import { TenantState, TenantType } from "./types";
import { compileUserPermissions } from "./utils";

const initialState: TenantState = {
  name: '',
  property_ids: [],
  tenant_id: '',
  email: '',
  created: '',
  updated: '',
  is_deleted: false,
  description: '',
  tenant_type: TenantType.Homeowner,
  phone_number: '',
  checkingTenant: true,
  checkingTenants: true,
  userPermissions: {},
  myTenants: [],
  users: [],
}

/** State slice for current user's tenant */
export const userTenantReducer = createSlice({
  name: 'userTenant',
  initialState,
  reducers: {
    setMyUserTenant: (state: any, action: any) => {
      if (action.payload) {
        Object.entries(action.payload)
          .forEach(([key, value]) => state[key] = value);

        state.checkingTenant = false;
        state.checkingTenants = true;
      }
    },
    setMyTenants: (state: any, action: any) => {
      if (action.payload) {
        state.myTenants = [...action.payload];
        state.checkingTenants = false;
      }
    },
    setUserPermissions: (state: any, action: any) => {
      state.userPermissions = compileUserPermissions(action.payload)
    },
    setPlans: (state: any, action: any) => {
      if (action.payload) {
        state.plans = action.payload;
      }
    },
    setMyUsers: (state: any, action: any) => {
      if (action.payload) {
        state.users = action.payload;
      }
    },
    resetUserTenant: (state: any) => {
      state.myTenants = initialState.myTenants;
      state.name = initialState.name;
      state.tenant_id = initialState.tenant_id;
      state.checkingTenant = true;
    },
  }
});

export default userTenantReducer.reducer;

export const {
  setMyUserTenant,
  setMyTenants,
  setMyUsers,
  setUserPermissions,
  resetUserTenant,
} = userTenantReducer.actions;