import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import { useGetDevicesQuery } from "@features/home/api";
import Thermostat from "@features/thermostat/components/Thermostat";
import { getUnitsByCategoryMeasure } from "@features/unit-settings/utils";
import {
  Category,
  findCategoryLabel,
  findMeasureLabel, findUnitsLabel, formatCategoryMeasurePair, formatReadingDetails,
  Measure,
  Unit
} from "@lib/labels";
import { formatDate } from "@lib/utils";
import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Slider, Stack, Tooltip, Typography } from "@mui/material";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { SUCCESS, WARNING } from "theme";
import { useUpdateDeviceStateMutation } from "../api";
import { DeviceState, DeviceStateType, ReadingPair } from "../types";
import { getDeviceCapabilities, THERMOSTAT_RANGE } from "../utils";
import IconWithValue from "./IconWithValue";

/** react-three/drei sets billboard's zIndex based on the camera position,
 *  values appear to be in the 10000000-17000000 range.
 *  This value ensures that Dialog displays over billboard
  */
const DIALOG_Z_INDEX = 20000000;

const CLIMATE_DOMAIN = 'climate';

type Props = {
  category: Category,
  measure: Measure,
  readings?: ReadingPair,
  noTooltip?: boolean;
}

const CategoryReadingIcon = ({ category, measure, readings, noTooltip }: Props): ReactElement => {
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const home = useAppSelector((state: RootState) => state.property);
  const userPreferences = useAppSelector((state: RootState) => state.preferences);

  const entityId = useMemo(() => readings?.device?.data.entity_id || '', [readings?.device?.data.entity_id]);
  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const capabilities = useMemo(() => getDeviceCapabilities(entityId), [entityId]);
  const isThermostat = useMemo(() => readings?.device?.data.entity_id?.startsWith(CLIMATE_DOMAIN), [readings?.device?.data.entity_id]);
  const preferences = useMemo(() => userPreferences.preferences, [userPreferences.preferences]);
  const unitPreferences = useMemo(() => preferences.units || {}, [preferences.units]);

  const preferredUnit = useMemo(() => (
    unitPreferences?.[formatCategoryMeasurePair(category, measure)]
    || getUnitsByCategoryMeasure(category, measure)[0]
  ), [category, measure, unitPreferences]);

  const [showScale, setShowScale] = useState<boolean>(false);
  const [showThermostat, setShowThermostat] = useState<boolean>(false);

  const states = useMemo(() => {
    if (!readings?.device) return '';
    return readings.device?.data.value;
  }, [readings]);

  const [numericValue, setNumericValue] = useState<number>(Number(states));

  const iconTooltip = useMemo(() => {
    const updatedDate = readings?.device?.data.value_updated;
    return (
      <Stack
        spacing={1}
        sx={{
          bgcolor: 'white',
          color: !updatedDate ? WARNING : SUCCESS,
          padding: 0.3
        }}
      >
        <Typography variant="subtitle1">
          {readings?.deviceName || ''}
        </Typography>
        <Typography variant="subtitle2">
          {readings?.sensor?.friendly_name || ''}
        </Typography>
        <Typography variant="caption">
          {[
            `${findCategoryLabel(category || '')}`,
            [
              findMeasureLabel(measure || ''),
              'in',
              readings?.area
            ].join(' '),
          ].join(' ')}
        </Typography>
        <Typography variant="caption">
          {!!updatedDate ? formatDate(updatedDate).fromNow() : 'No readings available!'}
        </Typography>
      </Stack>
    );
  }, [category, measure, readings]);

  const details = useMemo(() => {
    return formatReadingDetails(
      category || '',
      measure || '',
      preferredUnit,
      states,
    );
  }, [category, measure, preferredUnit, states]);

  const value = useMemo(() => readings?.device?.data.value, [readings?.device?.data.value]);

  const [savingState, setSavingState] = useState<boolean>(false);

  const handleCloseModal = useCallback(() => {
    setShowScale(false);
    setShowThermostat(false);
  }, []);

  const {
    refetch,
  } = useGetDevicesQuery({
    tenantId: currentTenant?.tenant_id || '',
    propertyId: home.property_id,
  }, {
    skip: !currentTenant?.tenant_id || !home.property_id,
  });

  const [
    updateDeviceState,
  ] = useUpdateDeviceStateMutation();

  const handleSetNumericValue = useCallback(async () => {
    setSavingState(true);
    await updateDeviceState({
      userTenantId: currentTenant?.tenant_id || '',
      propertyId: home?.property_id || '',
      deviceState: {
        entity_id: entityId,
        state_type: DeviceStateType.BINARY_SWITCH,
        target_value: numericValue,
      } as DeviceState,
    });
    refetch();
    setSavingState(false);

  }, [currentTenant?.tenant_id, entityId, home?.property_id, numericValue, refetch, updateDeviceState]);

  const handleSetBinaryValue = useCallback(async () => {
    setSavingState(true);
    await updateDeviceState({
      userTenantId: currentTenant?.tenant_id || '',
      propertyId: home?.property_id || '',
      deviceState: {
        entity_id: entityId,
        state_type: DeviceStateType.BINARY_SWITCH,
        target_value: !value,
      } as DeviceState,
    });
    refetch();
    setSavingState(false);
  }, [currentTenant?.tenant_id, entityId, home?.property_id, refetch, updateDeviceState, value]);

  const handleChangeNumericValue = useCallback((e, value) => {
    setNumericValue(value);
  }, []);

  const handleClickIcon = useCallback(() => {
    if (isThermostat) {
      setShowThermostat(true);
      return;
    }
    if (!capabilities.length) {
      return;
    }
    if (capabilities.length === 1) {
      const cap = capabilities[0];
      switch (cap.stateType) {
        default:
        case DeviceStateType.BINARY_SWITCH:
          handleSetBinaryValue();
          break;
        case DeviceStateType.ANALOG_SWITCH:
        case DeviceStateType.THERMOSTAT_TEMPERATURE:
          setShowScale(true);
          break;
      }
    }
  }, [capabilities, handleSetBinaryValue, isThermostat]);

  useEffect(() => {
    if (!savingState) setShowScale(false);
  }, [savingState]);


  if (!readings) return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={0.6}
      sx={{
        width: '3em',
        height: '2.2em',
      }}
    >
      {/* empty stack */}
    </Stack>
  );

  return (
    <>
      <Tooltip
        title={iconTooltip}
        placement="top"
        disableFocusListener={noTooltip}
        disableHoverListener={noTooltip}
        disableTouchListener={noTooltip}
        arrow
      >
        <span>
          {
            savingState
              ? <CircularProgress size={24} />
              : <IconWithValue
                  icon={<details.icon color={details.color} />}
                  value={details.value}
                  onClick={handleClickIcon}
                />
          }
        </span>
      </Tooltip>
      {
        showThermostat &&
        <Dialog open={showThermostat} onClose={handleCloseModal} fullWidth sx={{ zIndex: DIALOG_Z_INDEX + 10 }}>
          <DialogTitle>{readings?.deviceName} in {readings?.area}</DialogTitle>
          <DialogContent>
            <Thermostat readings={readings} />
          </DialogContent>
        </Dialog>
      }
      {
        showScale &&
        <Dialog open={showScale} onClose={handleCloseModal} fullWidth sx={{ zIndex: DIALOG_Z_INDEX }}>
          <DialogTitle>Set a new value for {readings?.sensor?.friendly_name || ''} in {readings?.area}</DialogTitle>
          <DialogContent sx={{ height: '10em', p: '0 4em' }}>
            <Slider
              defaultValue={Number(states)}
              min={THERMOSTAT_RANGE.MIN}
              max={THERMOSTAT_RANGE.MAX}
              step={1}
              valueLabelDisplay="on"
              onChange={handleChangeNumericValue}
              marks={[{
                label: `${THERMOSTAT_RANGE.MIN}${findUnitsLabel(readings?.sensor?.units as Unit || Unit.binary)}`,
                value: THERMOSTAT_RANGE.MIN,
              },
              {
                label: `${THERMOSTAT_RANGE.MAX}${findUnitsLabel(readings?.sensor?.units as Unit || Unit.binary)}`,
                value: THERMOSTAT_RANGE.MAX,
              }]}
              sx={{
                mt: '5em',
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal}>Cancel</Button>
            <LoadingButton loading={savingState} onClick={handleSetNumericValue}>Set</LoadingButton>
          </DialogActions>
        </Dialog>
      }
    </>
    );

}

export default CategoryReadingIcon;