import App from "@app/App";
import { useAppDispatch } from "@app/hooks";
import {
  AmplifyProvider
} from "@aws-amplify/ui-react";
import { CognitoUserAmplify } from "@features/aws-user/types";
import { useCallback, useEffect } from "react";
import { setUser } from "./slice";

type Props = {
  user: CognitoUserAmplify;
};

/**
 * A wrapper for authentication.
 */
const  AuthProvider = ({ user }: Props) => {
  const dispatch = useAppDispatch();

  const onLoad = useCallback(() => {
    const token = user.getSignInUserSession()?.getIdToken().getJwtToken();
    dispatch(setUser({
      username: user.username || '',
      attributes: user.attributes || null,
      idToken: token || '',
    }));
  }, [dispatch, user]);

  useEffect(() => {
    onLoad();
  }, [ onLoad, user ]);

  return (
    <AmplifyProvider>
      <App />
    </AmplifyProvider>);
}

export default AuthProvider;