import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import DeviceStatus from './DeviceStatus';
import SystemStatus from './SystemStatus';

/** Current values of sensors in a home */
const HomeStatus: FC = () => {
  const property = useAppSelector((state: RootState) => state.property);

  /** MAIN RENDER */
  return (
    <Box
      component="div"
      sx={{
        margin: '1em',
        padding: '2em',
      }}
    >
      <Typography variant="h4" sx={{ mb: 2 }}>
        Current status at {property?.name}
      </Typography>
      <Stack gap={2}>
        <SystemStatus />
        <DeviceStatus />
      </Stack>
    </Box>
  );
}

export default HomeStatus;