import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import jsqr from 'jsqr';

interface CodeScannerProps {
  onScanned: (code: unknown) => void;
}

export default function CodeScanner(props: CodeScannerProps) {
  const { onScanned } = props;
  const [videoError, setVideoError] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const [cameraStream, setCameraStream] = useState<MediaStream | null>(null);

  /** The video element is not required to be rendered */
  const videoStyle = useMemo(() => (cameraStream ? {
    flex: '1 1 auto',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    margin: '1rem',
  } : { display: 'none' }), [cameraStream]);
  
  const canvasStyle: React.CSSProperties = useMemo(() => ({
    display: 'none',
    flex: '1 1 auto',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    margin: '1rem',
  }), []);

  /* Set video element srcObject when stream changes */
  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      video.srcObject = cameraStream;
      video.play();
    }
  }, [cameraStream]);

  /** Obtains a video stream from the browser. */
  const loadVideo = useCallback(async (): Promise<void> => {
    if (cameraStream) return;

    try {
      const video = videoRef.current;
      if (!video) return;

      const newStream = await navigator.mediaDevices.getUserMedia({
        video: {
        },
        audio: false,
      });

      setCameraStream(newStream);
    } catch (e: unknown) {
      setVideoError(true);
    }
  }, [cameraStream]);

  const cleanupVideo = useCallback(() => {
    if (cameraStream) {
      cameraStream.getTracks().forEach(track => track.stop());
      setCameraStream(null);
    }
  }, [cameraStream]);

  /* Scan for codes on every frame */
  useEffect(() => {
    const videoTick = () => {
      const canvas = canvasRef.current;  
      const video = videoRef.current;

      if (canvas) {
        const canvasContext = canvas.getContext('2d');
  
        if (canvas && video && video.readyState === video.HAVE_ENOUGH_DATA) {
  
          if (canvas && canvasContext) {
            canvas.height = video.videoHeight;
            canvas.width = video.videoWidth;
            canvasContext.drawImage(video, 0, 0, canvas.width, canvas.height);
            
            const imageData = canvasContext.getImageData(0, 0, canvas.width, canvas.height);
            const code = jsqr(imageData.data, imageData.width, imageData.height, {
              inversionAttempts: "dontInvert",
            });
    
            if (code?.data) {
              onScanned(code.data);
            }
          }
        }
      }

      requestAnimationFrame(videoTick);
    };

    const animationFrameHandle = requestAnimationFrame(videoTick);

    return () => cancelAnimationFrame(animationFrameHandle);
  }, [onScanned]);

  /* Obtain a video stream on mount, and clean up afterward */
  useEffect(() => {
    if (!cameraStream) loadVideo();
    return cleanupVideo;
  }, [cameraStream, cleanupVideo, loadVideo]);

  const retryVideo = useCallback(() => {
    cleanupVideo();
    loadVideo();
  }, [cleanupVideo, loadVideo])

  return <Stack direction="column" justifyContent="stretch" mt={2} sx={{ flex: '1 1 auto'}}>
    { videoError ? <Stack direction="column">
      <Typography color="orange" textAlign="center">Unable to use webcam</Typography>
      <Button onClick={retryVideo}>Retry</Button>
    </Stack> : <>
      <video ref={videoRef} style={videoStyle} playsInline />
      {
        cameraStream ? <canvas ref={canvasRef} style={canvasStyle} />
        : <Stack direction="column" alignItems="center">
          <CircularProgress />
          <Typography textAlign="center" sx={{ mt: 4 }}>Waiting for video...</Typography>
        </Stack>
      }
    </> }
  </Stack>
}
