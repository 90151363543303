import { Tenant } from "@features/userTenant/types";
import { createSlice } from "@reduxjs/toolkit";


const initialState: Tenant[] = [];

export const propertyManagersReducer = createSlice({
  name: 'propertyManagers',
  initialState,
  reducers: {
    setPropertyManagers: (state: Tenant[], action: any) => {
      if (action.payload) {
        state.splice(0, state.length);
        action.payload.forEach((cc: Tenant) => state.push(cc));
      } else {
        state.splice(0, state.length);
      }
    },
  }
});

export default propertyManagersReducer.reducer;

export const {
  setPropertyManagers,
} = propertyManagersReducer.actions;