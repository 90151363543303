import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { TenantType } from '@features/userTenant/types';
import ConstructionIcon from '@mui/icons-material/Construction';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tab, Tooltip } from '@mui/material';
import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { Action } from '../types';
import CreatePropertyManifestEntry from './CreatePropertyManifestEntry';
import DeletePropertyManifestEntry from './DeletePropertyManifestEntry';


const ManifestManagementControls = () => {
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [activeAction, setActiveAction] = useState<Action>(Action.create);

  const handleOpenDialog = useCallback(() => {
    setDialogOpen(true);
  }, []);
  const handleCloseDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const handleActionChange = useCallback((e: SyntheticEvent, newAction: Action) => {
    setActiveAction(newAction);
  }, []);

  if (currentTenant.tenant_type !== TenantType.System) return <></>;

  return (<>
      <Tooltip title="Manage home's devices" placement='top' arrow>
        <span>
          <IconButton
            color="primary"
            onClick={handleOpenDialog}
          >
            <ConstructionIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog onClose={handleCloseDialog} open={dialogOpen} fullWidth>
        <DialogTitle>Managing Home Devices</DialogTitle>
        <DialogContent sx={{ height: '36em' }}>
          <TabContext value={activeAction}>
            <TabList onChange={handleActionChange}>
              {Object.values(Action).map(a => (
                <Tab
                  key={a}
                  label={<Tooltip arrow placement="top" title="TODO"><span>{a}</span></Tooltip>}
                  value={a}
                />
              ))}
            </TabList>
            <TabPanel value={Action.create} sx={{ padding: '5px 0px' }}>
              <CreatePropertyManifestEntry />
            </TabPanel>
            <TabPanel value={Action.delete} sx={{ padding: '5px 0px' }}>
              <DeletePropertyManifestEntry />
            </TabPanel>
          </TabContext>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
  </>
  );
}

export default ManifestManagementControls;